import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../../../importer';
import { StyleGroupTokenItem } from '../../../styleitems/common';
import GetItemStyle from '../../../../../../component/manager/getItemStyle';

export default class GridTemplateList extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        super.componentDidMount();
        this.width = ReactDOM.findDOMNode(this).getBoundingClientRect().width - 16;
        this.RCUpdate();
    }
    SelectTemplate(id) {
        this.props.onSelect(id);
    }
    renderCustom() {
        const templateIds = Globals.ProjectManager.Templates.GridTemplates();        
        
        return (
            <SC.FCol f1>
                {
                    this.width && templateIds.map((templateId) => {
                        const template =  Globals.ProjectManager.Templates.Template(templateId);
                        return (
                            <GridTemplateItem 
                                key={templateId} 
                                id={templateId} 
                                template={template}
                                width={this.width}
                                height={this.width * 0.75}
                                onSelect={this.SelectTemplate.bind(this, templateId)}
                            />
                        )
                    })
                }
            </SC.FCol>
        )
    }
}

export class GridTemplateItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        const template = this.props.template;

        const GridData = Utils.JustGet(template, {}, 'value', 'Default', 'value');
        let preview;

        if (GridData) {
            const grid_style = GetItemStyle.GetCssGridStyle(GridData.model, false, GridData.justifyItems, GridData.alignItems, GridData.justifyContent, GridData.alignContent, GridData.rowGap, GridData.columnGap);
            const gridModel = GridData.model;

            let fixedColumns = 0;
            let fixedRows = 0;

            const Columns = Utils.Get(gridModel, null, 'Columns');
            const Rows = Utils.Get(gridModel, null, 'Rows');                

            Utils.ForEach(Columns.Items,(item, i) => {
                if (item.Repeat > 1)
                    fixedColumns += item.Repeat;
                else
                    fixedColumns++; 
            });
            Utils.ForEach(Rows.Items,(item, i) => {
                if (item.Repeat > 1)
                    fixedRows += item.Repeat;
                else
                    fixedRows++;  
            });

            
            const fixedCells = [];
            const fixedCellCount = fixedColumns * fixedRows;

            const style_cell = {
                border : '1px solid #0096ea',
                minWidth : '100%',
                minHeight : '100%'
            }
            
            for (let r = 1; r <= fixedRows; r++) {
                for (let c = 1; c <= fixedColumns; c++) {
                    fixedCells.push(
                        <div 
                            key={r*c} 
                            style={{
                                ...style_cell,
                                gridColumnStart : c,
                                gridColumnSpan : 1,
                                gridRowSpan : 1,
                                gridRowStart : r,
                                display : 'grid'
                            }}
                        >
                            <div style={{
                                backgroundColor : '#0096ea',
                                minWidth : '40%',
                                minHeight : '40%',
                                alignSelf : grid_style.alignItems,
                                justifySelf : grid_style.justifyItems,
                            }} />
                        </div>
                    )
                }
            }

            if (Columns.DynamicColumn) {
                for (let r=1; r<=fixedRows; r++) {
                    fixedCells.push(
                        <div 
                            key={r*1000} 
                            style={{
                                ...style_cell,
                                gridColumnStart : fixedColumns+1,
                                gridColumnSpan : 1,
                                gridRowSpan : 1,
                                gridRowStart : r
                            }}
                        />
                    )
                }                            
            }

            const cells = [];            
            const metacells = Utils.Get(GridData, [], 'Cells');
            Utils.ForEach(metacells,(metaCell, i) => {
                const style = {
                    // backgroundColor : 'rgba(53, 169, 246, 0.55)',
                    // border : '1px solid #35ccea',
                    zIndex : 20,
                    display : 'flex',
                    alignItems : 'center',
                    justifyContent : 'center',
                    color : 'white',
                    fontSize : '18px'                    
                };

                if (metaCell.Type !== 'fixed') {
                    if (metaCell.ColumnSpan)
                        style.gridColumn = `span ${metaCell.ColumnSpan}`;
                    else if (metaCell.Column)
                        style.gridColumn = `${metaCell.Column.Start} / ${metaCell.Column.End}`;
                    if (metaCell.RowSpan)
                        style.gridRow = `span ${metaCell.RowSpan}`;
                    else if (metaCell.Row)
                        style.gridRow = `${metaCell.Row.Start} / ${metaCell.Row.End}`;            
                }
                else {
                    if (metaCell.ColStart && metaCell.ColEnd)
                        style.gridColumn = `${metaCell.ColStart} / ${metaCell.ColEnd}`;
                    if (metaCell.RowStart && metaCell.RowEnd)
                        style.gridRow = `${metaCell.RowStart} / ${metaCell.RowEnd}`;
                }                  

                cells.push(
                    <SC.Div_Flex_Cell
                        key={i}
                        style={style}
                    >
                        {metaCell.name}
                    </SC.Div_Flex_Cell>
                )
            }); 
          
        
            const width = Utils.Get(GridData, 800, 'Size', 'width');
            const height = Utils.Get(GridData, 600, 'Size', 'height');
            const scale_width = (this.props.width || 200) / width;
            const scale_height = (this.props.height || 250) / height;
            let scale = Math.max(scale_width, scale_height);
        
            preview = (
                <div style={Utils.Merge({
                    // backgroundColor : '#35363a',
                    minWidth : `${width}px`,
                    minHeight : `${height}px`,
                    transform : `scale(${scale})`,
                    position : 'relative',
                    transformOrigin : 'top left'
                }, grid_style)}>
                    {cells}
                    <div style={Utils.Merge(grid_style, {
                        position : 'absolute',
                        top:0, left:0, right:0, bottom:0,
                        opacity : 0.6
                    })}>
                        {fixedCells}
                    </div>
                </div>
            )
        }

        if (this.props.justContent)
            return preview;

        return (
            <StyleGroupTokenItem style={{marginBottom : '8px'}} onClick={this.props.onSelect}>
                <SC.FCol style={{padding : '8px', height : '208px'}}>
                    <div style={{marginBottom : '8px'}}>{template.name}</div>
                    {preview}
                </SC.FCol>
            </StyleGroupTokenItem>
        )
    }
}