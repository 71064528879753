import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    AppState,
    AppLayout,
    Globals
} from '../../../../../../importer';

import {GetItemType} from '../index';
import DropOverlay from './dropoverlay';
import DropPlaceholder from './dropplaceholder';
import { onItemShouldUpdate, onItemDragging, onMouseDownOnItem, onItemClick, onItemResized, onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetChildItem } from '../common';
import FlipMove from 'react-flip-move';
import DragDropManager from '../../../../../../components/dragdrop/DragDropManager';
import AbsoluteItemGuide from '../common/absoluteguide';
import ReactResizeDetector from 'react-resize-detector';

export default class FlexDropTarget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.onFlipMoveEnded = this.onFlipMoveEnded.bind(this);
        this.RegisterPlaceholder = this.RegisterPlaceholder.bind(this);
        this.RegisterDropTarget = this.RegisterDropTarget.bind(this);

        this.onChildAction = this.onChildAction.bind(this);

        this.Ref_DropOverlay = React.createRef();
        this.InitializeDropTarget(this.props);
        this.onResize = this.onResize.bind(this);      
        this.onResizeThrottled = Utils.Throttle(this.onResize, 300);  

        this.renderSlot = this.renderSlot.bind(this);
        this.renderSlide = this.renderSlide.bind(this);
        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {
            this.InitializeDropTarget(nextProps);   
            return true;
        }
        if (this.props.ComponentStateId !== nextProps.ComponentStateId)                  
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (this.state.ActiveTarget !== nextState.ActiveTarget)
            return true;

        if (this.state.OverlayActive !== nextState.OverlayActive)
            return true;

        return true;
    }    
    InitializeDropTarget(props) {
        if (props.isDropTarget) {
            if (this.props.IsRootItem && !this.props.IsRootItem.SubComponent || this.props.IsGridChild) {
                this.DropData = {
                    Placeholders : [],
                    DropTargets : {}
                }
            }
        }
        else {
            this.state.OverlayActive = false;
            delete this.DropData;
        }
    }
    onFlipMoveEnded() {
        if (this.props.isDropTarget && DragDropManager.DragData) {
            if (this.state.isDropTarget && (this.props.IsRootItem && !this.props.IsRootItem.SubComponent || this.props.IsGridChild)) {
                // console.log(`Root Is Animated`);            
                this.setState({
                    OverlayActive : true
                })
            }    
            else if (this.props.onRegisterDropTarget) {
                this.props.onRegisterDropTarget(this.props.Id, this.props.ParentId, {
                    Id : this.props.Id,
                    bounds : UIUtils.Utils.GetBounds(this),
                    ref : this,
                    SubComponent : this.props.IsRootItem ? this.props.IsRootItem.SubComponent : false
                })
            }
        }        
    }
    RegisterPlaceholder(ParentId, data) {                
        const placeholders = Utils.Get(this, [], 'DropData', 'Placeholders');
        placeholders.push(data);
    }
    RegisterDropTarget(Id, ParentId, data) {
        const droptargets = Utils.Get(this.DropData.DropTargets, [], ParentId);
        let TargetId = Id;
        if (data.SubComponent && data.SubComponent.Id)
            TargetId = data.SubComponent.Id;
        const existing = Utils.Find(droptargets, (item) => item.Id === TargetId);
        if (existing) {
            existing.ref = data.ref;
            existing.bounds = data.bounds;
        }
        else {
            droptargets.push(data);            
        }
        if (this.state.OverlayActive && this.Ref_DropOverlay.current)
            this.Ref_DropOverlay.current.RefreshDropTargets();
    }
    ActivateTarget(active, callback) {
        this.ActiveTargetLoaded = callback;
        this.DropData = {Placeholders : []};
        this.setState({ActiveTarget : active});
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.isDropTarget && !prevProps.isDropTarget && !(this.props.IsRootItem && !this.props.IsRootItem.SubComponent || this.props.IsGridChild) && !this.state.ActiveTarget) {
            this.props.onRegisterDropTarget && this.props.onRegisterDropTarget(this.props.Id, this.props.ParentId, {
                Id : this.props.Id,
                bounds : UIUtils.Utils.GetBounds(this),
                ref : this,
                SubComponent : this.props.IsRootItem ? this.props.IsRootItem.SubComponent : false,
                zIndex : (this.props.ParentzIndex || 0) + 1
            })
        }
        else if (!prevState.OverlayActive && this.state.OverlayActive) {
            this.Ref_DropOverlay.current && this.Ref_DropOverlay.current.Activate(this.DropData);
        }
        else if (this.state.isDropTarget && !prevState.isDropTarget && this.props.IsRootItem && !this.props.onRegisterDropTarget) {
            setTimeout(() => {
                if (!this.state.OverlayActive) {
                    this.setState({OverlayActive : true})
                }
            }, 210);
        }
    }
    componentDidMount() {
        this.onFlipMoveEnded();
    }
    onChildAction(action) {
        if (action.type === 'AbsoluteGuide') {
            if (action.start) {
                this.ShowAbsoluteGuide = action;    
            }
            else {
                delete this.ShowAbsoluteGuide;
            }            
            this.setState({ShouldUpdate : true});
        }
    }
    GetMaxSize(scaled) {
        let width, height;
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.Viewport) {      
                width = this.props.IsRootItem.Viewport.Width;
                height = this.props.IsRootItem.Viewport.Height;
                const bounds = UIUtils.Utils.GetBounds(this);
                if (this.props.IsRootItem.IsPage)
                    height = 999999;
                return {
                    width : width,
                    height : height,
                    top : bounds.top,
                    left : bounds.left,
                    actualwidth : bounds.width,
                    actualheight : bounds.height
                }
            }
        }
        return this.props.GetParentSize(scaled);
    }  
    onResize(w, h) {
        if (this.unmounted || AppState.DisableItemSelection || this.renderData && this.renderData.props.hidden)
            return;
        try {
            const bounds = UIUtils.Utils.GetBounds(this);
            Globals.ProjectManager.SetComponentPreviewSize(this.props.GetComponentManager().Id, {
                width : bounds.width / AppLayout.Designer.Zoom,
                height : bounds.height / AppLayout.Designer.Zoom
            })   
        } catch (error) {
            console.log(`ASSERT`);   
        }        
    }
    renderContent() {
        const SubItems = this.props.GetComponentManager().GetChildItems(this.props.Id);
        const items = [];

        let isRootTarget = this.props.IsRootItem && !this.props.IsRootItem.SubComponent || this.props.IsGridChild;
        
        let placeholderData;

        if (this.props.isDropTarget) {
            if (isRootTarget || this.state.ActiveTarget) {
                placeholderData = {
                    ParentId : this.props.Id,
                    IsParentRoot : isRootTarget ? true : false,
                    IsPage : this.props.IsRootItem ? this.props.IsRootItem.IsPage : false,
                    SubComponent : this.props.isSubComponentChild ? {
                        GetComponentManager : this.props.GetComponentManager
                    } : false
                };
                items.push(
                    <DropPlaceholder 
                        key='phfirst' 
                        data={{
                            ...placeholderData,
                            index : 0
                        }}         
                        onRegister={this.RegisterPlaceholder}
                    />
                )
            }            
        }

        Utils.ForEach(SubItems, (SubItem, i) => {
            const childProps = {
                GetParentSize : this.props.onGetSize,
                onDragDrop : this.props.onDragDrop,
                first : i === 0,
                last : i === SubItems.length - 1,
                IsGridChild : false,
                onMouseOverChild : this.props.onMouseOverChild,
                isDropTarget : this.props.isDropTarget
            };
            const SubMetaItem = this.props.GetComponentManager().GetMetaItem(SubItem.Id);
            if (SubMetaItem) {
                if (this.props.isDropTarget) {
                    if (this.props.isDropTarget.SourceItemId === SubItem.Id) {
                        childProps.isDropTarget = false;
                        if (!this.props.isDropTarget.Copy)
                            items.splice(i * 2, 1);                    
                    }
                    else {
                        if (SubMetaItem.Type === MetaData.Components.Div.Type || 
                            SubMetaItem.Type === MetaData.Components.Image.Type 
                            || SubMetaItem.Type === MetaData.Components.Component.Type
                            || SubMetaItem.Type === MetaData.Components.Content.Type
                            || SubMetaItem.Type === MetaData.Components.Repeater.Type
                        ) {
                            childProps.onRegisterPlaceholder = this.props.onRegisterPlaceholder || this.RegisterPlaceholder;
                            childProps.onRegisterDropTarget = this.props.onRegisterDropTarget || this.RegisterDropTarget;
                        }                        
                    }
                }
                let ChildItem = onGetChildItem({
                    ParentDesigner : this,
                    ParentProps : this.props,
                    ChildItemId : SubItem.Id,
                    childProps : childProps
                })
                if (ChildItem) {
                    items.push(ChildItem);
                    const itemposition = this.props.GetComponentManager().GetItemRenderProperty(SubItem.Id, 'position');
                    if (itemposition !== 'absolute' && itemposition !== 'fixed') {
                        if (isRootTarget || this.state.ActiveTarget) {
                            if (this.props.isDropTarget && (childProps.isDropTarget || this.props.isDropTarget.Copy)) {
        
                                items.push(
                                    <DropPlaceholder 
                                        key={i} 
                                        data={{
                                            ...placeholderData,
                                            index : i+1,
                                        }}
                                        onRegister={this.RegisterPlaceholder}
                                    />
                                )
                            }
                        }
                    }                  
                }  
            }             
        });

        // items.push(
        //     <div key='zIndex' style={{position : 'absolute', top : 0, left : 0, color : 'red'}}>{this.props.ParentzIndex || 0}</div>
        // )
        
        if (this.props.isSlotContainer && items.length === 0) {
            items.push(
                <SC.DesignItem_Placeholder key={this.props.Id} />
            )
        }
        else if (this.props.isSlideContainer && items.length === 0) {
            items.push(
                <div>EMPTY</div>
            )
        }
        
        return items;
    }
    renderSlot({SlotItemId, SlotItem, ParentzIndex, ContainerStyle}) {               
        const childProps = {
            isSlotContainer : {
                style : ContainerStyle
            },
            GetParentSize : this.props.onGetSize,
            onDragDrop : this.props.onDragDrop,
            IsGridChild : false,
            onMouseOverChild : this.props.onMouseOverChild,
            isDropTarget : this.props.isDropTarget,
            ParentId : this.props.Id,
            ParentzIndex : ParentzIndex
        };
        
        if (this.props.isDropTarget) {
            childProps.onRegisterPlaceholder = this.props.onRegisterPlaceholder || this.RegisterPlaceholder;
            childProps.onRegisterDropTarget = this.props.onRegisterDropTarget || this.RegisterDropTarget;
        }      

        const slotContainer = onGetChildItem({
            ChildItemId : SlotItemId,
            ChildItem : SlotItem,
            ParentDesigner : this,
            ParentProps : this.props,
            childProps : childProps
        });
        
        return slotContainer;
    }
    renderSlide({SlideItemId, SlideItem, ContainerStyle, ParentzIndex}) {
        const childProps = {
            isSlideContainer : {
                style : ContainerStyle
            },
            GetParentSize : this.props.onGetSize,
            onDragDrop : this.props.onDragDrop,
            IsGridChild : false,
            onMouseOverChild : this.props.onMouseOverChild,
            isDropTarget : this.props.isDropTarget,
            ParentId : this.props.Id,
            ParentzIndex : ParentzIndex
        };
        
        if (this.props.isDropTarget) {
            childProps.onRegisterPlaceholder = this.props.onRegisterPlaceholder || this.RegisterPlaceholder;
            childProps.onRegisterDropTarget = this.props.onRegisterDropTarget || this.RegisterDropTarget;
        }      

        const slideContainer = onGetChildItem({
            ChildItemId : SlideItemId,
            ChildItem : SlideItem,
            ParentDesigner : this,
            ParentProps : this.props,
            childProps : childProps
        });
        
        return slideContainer;       
    }
    render() {          
        let DropOverlayItem;
        let isDropOverlayRoot = (this.props.IsRootItem || this.props.IsGridChild) && this.props.isDropTarget && !this.props.onRegisterDropTarget;
        if (this.state.OverlayActive && this.state.isDropTarget) {
            DropOverlayItem = (
                <SC.AbsoluteOverlay>
                    <DropOverlay 
                        ref={this.Ref_DropOverlay} 
                        RootId={this.props.Id}
                        key={'DropOverlay'}
                        GetComponentManager={this.props.GetComponentManager}
                        onEndDrag={this.props.onDragDrop}
                        onMounted={() => {
                            this.setState({
                                DropOverlayMounted : true
                            })
                        }}
                    />
                </SC.AbsoluteOverlay>
            )
        }

        // if (this.state.OverlayActive)
        //     this.props.itemProps.style.outline = '2px dashed red'

        let AbsoluteGuide;
        if (this.ShowAbsoluteGuide) {
            AbsoluteGuide = (
                <AbsoluteItemGuide
                    {...this.ShowAbsoluteGuide}
                />
            )
        }

        let resizeDetector;
        if (this.props.IsRootItem) {
            if (this.props.itemProps.style.width === 'auto' || this.props.itemProps.style.height === 'auto') {
                resizeDetector = (
                    <ReactResizeDetector handleWidth handleHeight onResize={this.onResizeThrottled} />
                )
            }
        }

        return (
            <FlipMove                     
                key={this.props.Id}
                easing='ease'
                duration={this.props.IsRootItem ? 200 : 150}
                {...this.props.itemProps}
                onFinishAll={this.ActiveTargetLoaded || this.onFlipMoveEnded}
            >
                {this.renderContent()}           
                {
                    isDropOverlayRoot && 
                    <SC.AbsoluteOverlay 
                        onMouseEnter={() => {
                            this.setState({isDropTarget : this.props.isDropTarget});
                        }}
                    >
                        {DropOverlayItem}
                    </SC.AbsoluteOverlay>
                }                
                {AbsoluteGuide}
                {resizeDetector}
            </FlipMove>
        )
    }    
}
 