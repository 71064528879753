/* eslint-disable */
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Events from './appstate/Events';
import {Utils} from './toolabs-importer';
  
class ReactBaseComponent extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            ShouldUpdate : false
        };
        this.AddCloseCallback = this.AddCloseCallback.bind(this);
        this.RCUpdate = this.RCUpdate.bind(this);        
        this.RCThrottledUpdate_0_5 = Utils.Throttle(this.RCUpdate, 50);
        this.RCThrottledUpdate_1 = Utils.Throttle(this.RCUpdate, 100);
        this.RCThrottledUpdate_2 = Utils.Throttle(this.RCUpdate, 200);
        this.RCThrottledUpdate_3 = Utils.Throttle(this.RCUpdate, 300);
    }
    componentDidMount() {
        this.Mounted = true;
        if (this.Events) {
            Events.ListenEvents(this, this.Events);
        }

        if (this.UserStatId) {
            setTimeout(() => {
                // AppState.DesignData.UserStats.Start(this.UserStatId);
            }, 1);
        }
    }
    Exit() {

    }
    AddCloseCallback(close) {
        const callbacks = Utils.Get(this, [], 'CloseCallbacks');
        callbacks.push(close);
    }
    componentWillUnmount() {
        this.Mounted = false;
        if (this.Events) {
            this.Events.map((e) => Events.DropListen(e, this));
        }
        const callbacks = Utils.Get(this, [], 'CloseCallbacks');
        Utils.ForEach(callbacks, (close, i) => {
            close();
        });

        if (this.UserStatId) {
            setTimeout(() => {
                // AppState.DesignData.UserStats.End(this.UserStatId);
            }, 1);
        }
    }
    ListenEvents(...Ids) {
        this.Events = Utils.Union(this.Events, Ids);
    }
    RCSetStateValue(Property, Value, onDidUpdate) {
        if (this.state) {
            if (this.Mounted)
            {
                let state = {};
                state[Property] = Value;
                    this.setState(state, onDidUpdate);
            }
        }
        else {
            this.state = {};
            this.state[Property] = Value;
        }
    }
    RCSetState(cb) {
        if (this.Mounted) {
            this.setState((ps) => {
                if (this.Mounted)
                    return cb(ps);
            })
        }
    }    
    RCUpdate() {
        this.RCSetStateValue('ShouldUpdate', true);
    }
    RedirectTo(Path) {
        this.RedirectToPath = Path;
        this.RCUpdate();
    }
    ShouldUpdate(nextProps, nextState) {
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        return false;
    }
    render() {
        this.state.ShouldUpdate = false;
        let redirectContent;
        if (this.RedirectToPath) {
            const ReDirectTo = this.RedirectToPath;
            this.RedirectToPath = null;
            if (this.renderWithRedirect)
                return this.renderWithRedirect(
                    <Redirect push to={ReDirectTo} />    
                )
            return  (
                <Redirect push to={ReDirectTo} />
            );
        }
        if (this.renderCustom) {
            let  rh = null;
            rh = this.renderCustom(redirectContent);
            if (rh === undefined) {
                return null;
            }
            return rh;
        }                    
        return redirectContent;
    }
}

export default ReactBaseComponent;