import React from 'react';
import {
    SC
} from '../../../importer';

const Linear = (props) =>
{
    return (
        <SC.Svg width={props.size || 24} viewBox="0 0 24 24" fill={props.selected ? '#fff' : '#979797'}>
            <path d="M13 17a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m0-4a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m0-4a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m4 3.5a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5m0-4a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5M3 3v2h18V3m-4 13.5a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5M9 17a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m-4-3.5A1.5 1.5 0 0 0 6.5 12 1.5 1.5 0 0 0 5 10.5 1.5 1.5 0 0 0 3.5 12 1.5 1.5 0 0 0 5 13.5m0-4A1.5 1.5 0 0 0 6.5 8 1.5 1.5 0 0 0 5 6.5 1.5 1.5 0 0 0 3.5 8 1.5 1.5 0 0 0 5 9.5M3 21h18v-2H3M9 9a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m0 4a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m-4 4.5A1.5 1.5 0 0 0 6.5 16 1.5 1.5 0 0 0 5 14.5 1.5 1.5 0 0 0 3.5 16 1.5 1.5 0 0 0 5 17.5z"/>
        </SC.Svg>
    );
}

const Radial = (props) =>
{
    return (
        <SC.Svg width={props.size || 24} viewBox="0 0 24 24" fill={props.selected ? '#fff' : '#979797'}>
            <path d="M14 8.5a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 14 8.5m0 4a1.5 1.5 0 0 0-1.5 1.5 1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5M10 17a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0-8.5A1.5 1.5 0 0 0 8.5 10a1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5A1.5 1.5 0 0 0 10 8.5m4 12a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5m0-3.5a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m7-3.5a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5M18 5a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0 4a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0 8a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m-8-.5A1.5 1.5 0 0 0 8.5 14a1.5 1.5 0 0 0 1.5 1.5 1.5 1.5 0 0 0 1.5-1.5 1.5 1.5 0 0 0-1.5-1.5M10 7a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m0-3.5a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5m0 17a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5m-7-7a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5m11-10a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5M14 7a1 1 0 0 0 1-1 1 1 0 0 0-1-1 1 1 0 0 0-1 1 1 1 0 0 0 1 1m7 3.5a.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5M6 5a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1M3 9.5a.5.5 0 0 0-.5.5.5.5 0 0 0 .5.5.5.5 0 0 0 .5-.5.5.5 0 0 0-.5-.5M6 9a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0 8a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1m0-4a1 1 0 0 0-1 1 1 1 0 0 0 1 1 1 1 0 0 0 1-1 1 1 0 0 0-1-1z"/>
        </SC.Svg>
    );
}

const ColorPoint = (props) => {
    return (
        <SC.Svg width={props.size} height={props.size} viewBox="0 0 18 18">
            <defs>
            <path id={props.idb} d="M191 133c3.3 0 6-2.7 6-6s-2.7-6-6-6-6 2.7-6 6 2.7 6 6 6z"/>
            <filter id={props.ida} width="158.3%" height="158.3%" x="-29.2%" y="-29.2%" filterUnits="objectBoundingBox">
                <feMorphology radius="1" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
                <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
                <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"/>
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.427875906 0" in="shadowBlurOuter1"/>
            </filter>
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(-182 -118)">
                <use fill="#000" filter={`url(#${props.ida})`} xlinkHref={`#${props.idb}`}/>
                <use fill={props.color} stroke="#FFF" xlinkHref={`#${props.idb}`}/>
            </g>
        </SC.Svg>
    )
}

const SvgCircle = (props) =>  (
    <SC.Svg width="231" height="231" viewBox="0 0 231 231">
        <defs>
            <circle id="b" cx="115" cy="114" r="109"/>
            <filter id="a" width="104.6%" height="104.6%" x="-2.3%" y="-2.3%" filterUnits="objectBoundingBox">
            <feMorphology radius="2.5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology radius="2.5" in="SourceAlpha" result="shadowInner"/>
            <feOffset in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.266955389 0" in="shadowBlurOuter1"/>
            </filter>
            <path id="d" d="M219.13 113.5h10"/>
            <filter id="c" width="136.4%" height="233.3%" x="-18.2%" y="-66.7%" filterUnits="objectBoundingBox">
            <feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology radius="1" in="SourceAlpha" result="shadowInner"/>
            <feOffset in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.734912817 0" in="shadowBlurOuter1"/>
            </filter>
            <path id="f" d="M1.13 113.5h10"/>
            <filter id="e" width="136.4%" height="233.3%" x="-18.2%" y="-66.7%" filterUnits="objectBoundingBox">
            <feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology radius="1" in="SourceAlpha" result="shadowInner"/>
            <feOffset in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.734912817 0" in="shadowBlurOuter1"/>
            </filter>
            <path id="h" d="M114.13.5v10"/>
            <filter id="g" width="500%" height="140%" x="-200%" y="-20%" filterUnits="objectBoundingBox">
            <feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology radius="1" in="SourceAlpha" result="shadowInner"/>
            <feOffset in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.734912817 0" in="shadowBlurOuter1"/>
            </filter>
            <path id="j" d="M114.13 218.5v10"/>
            <filter id="i" width="500%" height="140%" x="-200%" y="-20%" filterUnits="objectBoundingBox">
            <feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
            <feMorphology radius="1" in="SourceAlpha" result="shadowInner"/>
            <feOffset in="shadowInner" result="shadowInner"/>
            <feComposite in="shadowOffsetOuter1" in2="shadowInner" operator="out" result="shadowOffsetOuter1"/>
            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.734912817 0" in="shadowBlurOuter1"/>
            </filter>
        </defs>
        <g fill="none" fillRule="evenodd">
            <g stroke-dasharray="1,3" transform="translate(.37 1)">
            <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
            <use stroke="#797979" strokeWidth="4" xlinkHref="#b"/>
            </g>
            <g transform="translate(.37 1)">
            <use fill="#000" filter="url(#c)" xlinkHref="#d"/>
            <use stroke="#DADADA" xlinkHref="#d"/>
            </g>
            <g transform="translate(.37 1)">
            <use fill="#000" filter="url(#e)" xlinkHref="#f"/>
            <use stroke="#DADADA" xlinkHref="#f"/>
            </g>
            <g transform="translate(.37 1)">
            <use fill="#000" filter="url(#g)" xlinkHref="#h"/>
            <use stroke="#DADADA" xlinkHref="#h"/>
            </g>
            <g transform="translate(.37 1)">
            <use fill="#000" filter="url(#i)" xlinkHref="#j"/>
            <use stroke="#DADADA" xlinkHref="#j"/>
            </g>
        </g>
    </SC.Svg>
)

export {
    Linear,
    Radial,
    ColorPoint,
    SvgCircle
}
