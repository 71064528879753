import React from 'react';
import {
    SC,
    Globals,
    AppLayout,
    Utils
} from '../../../../../../../importer';

import BorderRadiusEditor from '../../../../left/designsystem/borders/radius';
import { BorderRadiusTokenItem, GetBorderRadiusTokenList, GetBorderRadiusTokenValue } from '../../../../left/designsystem/borders';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectBorderRadius extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW RADIUS';
        this.moduleName = 'BorderRadius';
        this.tokenType = Globals.ProjectManager.Tokens.Types.BorderRadiuses;        
        this.hasCustomTokenList = true;
        this.state.isListView = false;

        this.Load(this.props);

        this.RadiusValues = {};
        
        this.SelectedSide = this.props.side;
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.propName !== nextProps.propName || this.props[this.props.propName] !== nextProps[this.props.propName];
    }
    GetTokens(props) {
        const Radiuses = GetBorderRadiusTokenList();
        this.RadiusItemSize = Radiuses.maxRadiusSize;
        return Radiuses.items;
    }        
    GetNewModel(Id) {
        const item = {
            type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
            name : 'New Radius',
            value : {
                value : 8
            },
            id : this.EditingToken.Id
        };
        Globals.ProjectManager.Tokens.Add({...item, doNotUpdateTheme : true});
        this.FireSelectToken(Id);
        return item;
    }
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();        
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.BorderRadiuses, willDeleteId);
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const item = {
                type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
                name : this.EditingToken.Model.name,
                value : this.EditingToken.Model.value,
                id : this.EditingToken.Id
            };
            Globals.ProjectManager.Tokens.Add(item);
            this.Load();            
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
            return item;
        }        
        else {
        }                
    }   
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});        
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const item = GetBorderRadiusTokenValue(token, this.EditingToken.Id, this.props.StateArray);
        SelectedToken.style = item.style;
        AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
    }
    GetStyleName() {
        return Utils.BorderStyles.propRadius(this.SelectedSide);
    }
    renderTokenList() {        
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {
                    this.tokens.length > 0 && 
                    <SC.FCol style={{fontSize : '11px'}}>                        
                        {
                            this.tokens.map((radius, i) => {
                                return (
                                    <BorderRadiusTokenItem
                                        key={radius.id}
                                        radius={radius}
                                        isListView={this.state.isListView}
                                        size={this.RadiusItemSize}
                                        onSelect={this.SelectToken.bind(this, radius.id)}
                                        selected={radius.id === this.GetSelectedTokenId()}
                                        onPreview={{
                                            onMouseEnter : this.PreviewToken.bind(this, true, radius.id, this.props.propName),
                                            onMouseLeave : this.PreviewToken.bind(this, false, radius.id, this.props.propName)
                                        }}
                                    />   
                                )
                            })
                        }
                    </SC.FCol>
                }     
            </SC.CustomScrollbars>
        )       
    }
    GetSelectedTokenId() {
        if (this.props.propName === 'tokenId')
            return this.props.tokenId;
        return Utils.JustGet(this.RadiusValues, null, this.GetStyleName(), 'TokenId');
    }
    renderTokenEditor() {        
        return (
            <BorderRadiusEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onClose={this.onCancelAddToken}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}                
            />                
        )        
    }
    renderSelectedToken() {
        return (
            <SC.FCol style={{marginBottom : '16px'}}>
                <SC.FRow alc justifyEnd style={{fontSize : '11px', marginBottom : '8px'}}>
                    <SC.LinkText onClick={this.EditToken} style={{marginRight : '16px'}}>Edit Token</SC.LinkText>
                    <SC.LinkText onClick={() => {this.SelectToken();}}>Remove</SC.LinkText>
                </SC.FRow>            
            </SC.FCol>
        )        
    }    
}