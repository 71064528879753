import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    Globals,
    Utils
} from '../../../../../../importer';

import { TokenPanelHeader, GroupTitle, TokenItemBox } from '../../../left/designsystem/common';
import { GetDataApi, GetUserId } from '../../../../../../appstate/AppState';
import UserAvatar from 'react-user-avatar';
import { MemberAvatar } from '../members';
import { TEAM_MESSAGE_TYPES } from '../../../../manager/sessionmanager';


export default class TeamEActivites extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        super.ListenEvents(
            Events.GLOBAL.TEAM.MESSAGES
        );
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadTeam();
    }
    LoadTeam() {
        GetDataApi().get_team_data(Globals.ProjectManager.TeamId).then((result) => {
            this.team = result;
            if (result.members && result.owner)
                result.members.splice(0, 0, result.owner);
            this.setState({loading : false});
        })
    }    
    OnEvent(Event, Params) {
        this.RCUpdate();
    }    
    renderCustom() {

        let messageItems = [];
        if (this.state.showActivities ) {            
            const messages = Globals.ProjectManager.SessionManager.GetMessages();
            messages.map(({message, id, userName, avatar, editor}, i) => {
                messageItems.push(
                    <TeamMessage 
                        key={id} 
                        id={id}
                        message={message}
                        userName={userName}
                        avatar={avatar}
                        editor={editor}
                    />
                )
            })
        }

        return(
            <SC.FCol style={{paddingTop : '8px'}}>
                <GroupTitle 
                    noPadding
                    title='RECENT TEAM ACTIVITY'
                    expandable={{expanded : this.state.showActivities, onExpand : () => {this.setState({showActivities : !this.state.showActivities})}}}
                />  
                {messageItems}                                  
            </SC.FCol>
        );
    }
}

class TeamMessage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }
    render() { 
        const {userName, message, avatar, editor} = this.props;
        let messageItem;
        if (message) {
            if (Utils.IsOneOf(message.type, TEAM_MESSAGE_TYPES.TOKEN_ADDED, TEAM_MESSAGE_TYPES.TOKEN_CHANGED, TEAM_MESSAGE_TYPES.TOKEN_DELETED)) {
                let title;
                if (message.type === TEAM_MESSAGE_TYPES.TOKEN_ADDED) {
                    title = 'Added Token';
                }
                else if (message.type === TEAM_MESSAGE_TYPES.TOKEN_CHANGED) {
                    title = 'Changed Token';
                }
                else if (message.type === TEAM_MESSAGE_TYPES.TOKEN_DELETED) {
                    title = 'Deleted Token';
                }
                messageItem = (
                    <div>{title}</div>
                )
            }            
        }
        return (  
            <SC.HoverParent 
                defaultStyle={{
                    display : 'flex',
                    alignItems : 'center',
                    gridGap : '8px',
                    gridTemplateColumns : 'auto 1fr',
                    padding : '8px',
                    borderRadius : '4px',
                    transition : 'all 0.2s ease',
                    fontSize : '11px'
                }}
                hoverStyle={{
                    backgroundColor : SC.CurrentTheme.theme.back_lighter
                }}
            >
                <MemberAvatar noState avatar={avatar} name={userName} />
                <SC.FCol f1>
                    <SC.TextDivAbbr>{userName}</SC.TextDivAbbr>
                    {messageItem}
                </SC.FCol> 
                {
                    editor === 'figma' && <SC.Icons.Figma size={10}/>
                }               
            </SC.HoverParent>
        );
    }
}