import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 160,
            height : 40
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 40
            },                
            'width': {
                'value': 160
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['readonly', 'disabled', 'focus', 'hover'],        
        readonly : {
            SingleVariation : true,
            name : 'Readonly',
            previewable : true,
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            pseudo : true,
            name : 'Hover'
        },
        focus : {
            SingleVariation : true,
            name : 'Focused',
            pseudo : true
        }
    };

    Component.Handlers = {
    };

    Component.Models = {
        List : {
            'value': {
                dataType: 'string',
                name: 'Value',
                value : ''
            },
            'placeholder': {
                dataType: 'string',
                name: 'Placeholder',
                value : ''
            }
        },
        Order: ['value', 'placeholder']
    };

    MetaItem.Events = [         
    ];
    
    Utils.MergeTo(MetaItem, {        
        Generic : {
            Type : GenericType
        },
        Models : {
            Default: {
                Default: {
                    value : {
                        Id : 'value'
                    },
                    placeholder : {
                        Id : 'placeholder'
                    }
                }
            }            
        },
        Property: {
            Default: {
                Default: {
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 4
                    },
                    borderStyle: {
                        value: 'solid'
                    },
                    borderWidth: {
                        value: 1
                    },
                    fontSize: {
                        value: 14
                    },
                    width : {
                        value : 200
                    },
                    height: {
                        value: 80
                    },
                    paddingLeft: {
                        value: 8
                    },
                    paddingRight: {
                        value: 8
                    },
                    color : {
                        value : 'unset'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    },
                    color: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                },
                focus: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                }
            }
        }
    });

    return Component;
}