import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_TextInput extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {      
        const value = Utils.JustGet(this.renderData.props, '', 'value');
        const placeholder = Utils.JustGet(this.renderData.props, '', 'placeholder');

        return (
            <input 
                style={style} 
                {...props} 
                value={value}
                placeholder={placeholder}
            />
        )
    }
}