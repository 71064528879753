import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState
} from '../../importer';

class Input_Name extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.value = Utils.UseNull(this.props.value, '');
        this.onChange = this.onChange.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.autoFocus)
            setTimeout(() => {
                this.Ref_Input && this.Ref_Input.focus();
                if (this.props.autoSelect)
                    this.Ref_Input && this.Ref_Input.select();
            }, 10);
            
    }    
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== this.props.value && nextProps.value !== this.value)
        {
            this.value = nextProps.value;
        }
        if (nextProps.focused && !this.props.focused) {
            this.Ref_Input.focus();
        }
        if (!nextProps.showError && this.props.showError && this.Error)
            delete this.Error;
        return true;
    }
    Focus() {
        this.Ref_Input && this.Ref_Input.focus();
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {
        e.stopPropagation();
        if (this.props.onSubmit && (e.charCode === 13 || e.keyCode === 13)) {
            this.props.onSubmit();
        }
        else if (this.props.onCancel && e.keyCode === 27) {
            this.props.onCancel();
        }
        
        return true;
    }
    onFocus(e) {
        AppState.Designer.SetEditingText('input_name');;
        this.props.styleProps && this.props.styleProps.onFocus && this.props.styleProps.onFocus(e);
    }
    onBlur(e) {
        AppState.Designer.EditingText = false;
        if (this.props.validateOnBlur) {
            this.props.validateOnBlur().then((result) => {
                if (result.error) {                    
                    this.Error = result.error;
                    this.value = this.Error.validValue;                       
                    this.RCUpdate();
                    return;
                }
                this.onBlurEnd(e);                
            })
        }
        else
            this.onBlurEnd(e);        
    }
    onBlurEnd(e) {
        if (this.Error) {
            if (this.Error.validValue !== this.value) {         
                this.value = this.Error.validValue;                       
                this.props.onChange && this.props.onChange(this.value);
                delete this.Error; 
                this.RCUpdate();
            }
        }
        this.props.styleProps && this.props.styleProps.onBlur && this.props.styleProps.onBlur(e);
    }
    onChange(e) {        
        this.Validate(e.target.value);
        this.value = e.target.value;
        this.RCUpdate();
        this.props.onChange && this.props.onChange(this.value);
    }    
    Validate(value, display) {
        delete this.Error;
        if (this.props.required && !Utils.IsNotNullOrEmpty(value)) {
            this.Error = {
                error : true,
                message : 'Name required',
                validValue : this.value
            };
        }
        else if (this.props.onValidate) {
            const result = this.props.onValidate(value);
            if (result && result.error) {
                this.Error = result;
                this.Error.validValue = this.value;
            }
        }
        if (display) {
            this.RCUpdate();
        }
        return !this.Error;
    }
    SetError(error) {        
        this.Error = error;
    }
    ClearError() {
        if (this.Error) {
            delete this.Error;
            this.RCUpdate();
        }
    }
    GetValue() {
        return this.value;
    }
    SetValue(v) {
        this.value = v;
        this.RCUpdate();
    }    
    renderCustom() {
        let InputType = this.props.InputType;
        if (!InputType)
            InputType = SC.Input_Generic;
        let label = null;
        if (this.props.label) {
            let LabelType = this.props.LabelType;
            let styleLabel;
            if (!LabelType) {
                LabelType = SC.FRow;
                styleLabel = {fontSize : '12px', color : '#8e8e8e', fontWeight : 400};
            }
            label = (
                <LabelType {...styleLabel}>
                    {this.props.label}
                </LabelType>
            )
        }
        return (
            <div style={{width : '100%', ...this.props.style, position : 'relative'}}>                
                {this.Error && SC.RenderValueError(this.Error, this.Error.message)}                
                <div style={{overflow:'hidden'}}>
                    {label}
                    <InputType 
                        ref={(r) => this.Ref_Input = r} 
                        {...this.props.styleProps}
                        onBlur={this.onBlur}
                        value={this.value || ''} 
                        onChange={this.onChange}
                        error={this.Error}          
                        onKeyDown={this.OnKeyDown} 
                        onKeyUp={this.OnKeyUp}
                        onFocus={this.onFocus}
                    />
                </div>
            </div>
        );
    }
}

export default Input_Name;