import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// import './index.css';
// import App from './beta/apps/studio';
// ReactDOM.render(<App />, document.getElementById('root'));

// import AdminView from './beta/apps/admin';
// import './index.css';
// ReactDOM.render(<AdminView />, document.getElementById('root'));

window.ToolabsInMemoryDb = true;
import WebsiteDemo from './beta/apps/websitedemo';
import './index_demo.css';
ReactDOM.render(<WebsiteDemo />, document.getElementById('root'));

// import TestView from './test';
// import './beta/App.css';
// ReactDOM.render(<TestView />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
