import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Strings,
    Links,
    Utils,
    UIUtils,
    AppLayout,
    MetaData
} from '../../../../importer';

export class DeviceSelectionButtons extends React.Component {
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.deviceType !== this.props.deviceType || !Utils.IsEqual(nextProps.hasAllDevices, this.props.hasAllDevices) || this.props.themeId !== nextProps.themeId)
            return true;
        return false;
    }
    SetDeviceType(deviceType) {
        if (this.props.deviceType === deviceType)
            this.props.onToggleResponsive();
        else
            this.props.onSelectDevice(deviceType);
    }
    render() {
        let allDevicesItem;
        let isResponsive = true;
        if (this.props.hasAllDevices) {
            isResponsive = !this.props.hasAllDevices.selected;
            allDevicesItem = (
                <SC.FRow f1 style={{paddingLeft : '4px', borderRight : SC.CurrentTheme.theme.border_onlight_line}}>
                    <SC.Icons.Icon_Button 
                        hasFill 
                        fill='#797979'
                        selected={!isResponsive ? SC.CurrentTheme.theme.color_brand : null} 
                        style={{cursor : 'pointer'}}
                        onClick={this.props.onToggleResponsive}
                        title='All Device Sizes'
                    >
                        <SC.Icons.Icon_Device_All />
                    </SC.Icons.Icon_Button>       
                </SC.FRow>                
            )
        }
        return (
            <SC.FRow f1>
                {allDevicesItem}       
                <SC.FRow f1 alc style={{justifyContent : 'space-evenly'}}>
                    {
                        DEVICE_BUTTONS.map((device, i) => {
                            if (this.props.hideLargest && device.Id === 'Default')
                                return null;
                            const selected = isResponsive && this.props.deviceType === device.Id;
                            return (
                                <SC.Icons.Icon_Button 
                                    key={i}
                                    hasFill 
                                    selected={selected ? SC.CurrentTheme.theme.color_brand : null} 
                                    style={{paddingLeft : device.padding, paddingRight : device.padding, paddingTop : device.paddingTop, cursor : 'pointer'}}
                                    onClick={this.SetDeviceType.bind(this, device.Id)}                                 
                                >
                                    <device.IconType />
                                </SC.Icons.Icon_Button>        
                            )
                        })   
                    }
                </SC.FRow>                         
            </SC.FRow>
        )
    }
}

export const Devices = {
    Default  :{
        Id : 'Default',
        full : true,
        title : 'All Device Sizes',
        Default : {
            width : 1920,
            height : 800
        }
    },
    Large : {
        Id : 'Large',
        Default : {
            width : 1440,
            height : 800
        }
    },
    Medium : {
        Id : 'Medium',
        Default : {
            width : 1024,
            height : 800
        }
    },
    Small : {
        Id : 'Small',
        Default : {
            width : 768,
            height : 800
        }
    },
    xSmall : {
        Id : 'xSmall',
        Default : {
            width : 425,
            height : 800
        },
        Preests : [
            { id : 'iPhone X', w : 375, h : 812},
            { id : 'iPhone 6/7/8 Plus', w : 414, h : 736},
            { id : 'iPhone 6/7/8', w : 375, h : 667},
            { id : 'iPhone 5', w : 320, h : 568}
        ]
    }
}

export const DEVICE_BUTTONS = [
    {Id : Devices.xSmall.Id, IconType : SC.Icons.Icon_Device_xSmall, padding : '2px', paddingTop : '2px'},
    {Id : Devices.Small.Id, IconType : SC.Icons.Icon_Device_Small, padding : '2px', paddingTop : '1px'},
    {Id : Devices.Medium.Id, IconType : SC.Icons.Icon_Device_Medium, padding : '4px', paddingTop : 0},
    {Id : Devices.Large.Id, IconType : SC.Icons.Icon_Device_Large, padding : '4px', paddingTop : 0},
    {Id : Devices.Default.Id, IconType : SC.Icons.Icon_Device_xLarge, padding : '4px', paddingTop : 0}                
];