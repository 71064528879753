import React from 'react';
import ReactDOM from 'react-dom';
import ReactResizeDetector from 'react-resize-detector';
import ToolabsReactBase from '../../ReactBase';
import {Utils, MetaData} from '../../importer';

var MapBoxWrapper;

var currentLocation = null;
class Widget_MapBox extends React.Component
{
    constructor(props) {
        super(props);        
        this.state =  {
            Loaded : false
        };
        this.Load = this.Load.bind(this);
        this.onResize = this.onResize.bind(this);
        this.Ref_Map = React.createRef();
    }
    componentWillUnmount() {
        this.state.Mounted = false;
    }
    componentDidMount() {        
        MapBoxWrapper = window.mapboxgl;

        if (!MapBoxWrapper) {
            const script = document.createElement("script");
            script.src = "https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.js";
            script.async = true;
            script.onload = this.HeaderLoaded.bind(this, true);
            document.head.appendChild(script);

            var sheet = document.createElement('link');
            sheet.rel = 'stylesheet';
            sheet.href = `https://api.mapbox.com/mapbox-gl-js/v0.49.0/mapbox-gl.css`;
            sheet.type = 'text/css';
            sheet.onload = this.HeaderLoaded.bind(this, false, true);
            document.head.appendChild(sheet);            
        }
        else
            this.Load();
       
    }
    HeaderLoaded(scriptLoaded, styleLoaded) {
        if (scriptLoaded) {
            this.scriptLoaded = true;
        }
        if (styleLoaded)
            this.styleLoaded = true;
        if (this.scriptLoaded && this.styleLoaded) {
            MapBoxWrapper = window.mapboxgl;
            
            if (MapBoxWrapper) {
                MapBoxWrapper.accessToken = 'pk.eyJ1IjoiYXBwbGluaXN0IiwiYSI6ImNqbDBwa2dlNzE3N2MzcXFwbW11emd1Y3kifQ.3lWPxT2CpCvdTP5Unp25Gg';
                this.Load();
            }                    
        }
    }
    onResize(w, h) {
        if (this.MapInstance)                                                                                                                                                                                                                                                                                                                                           
            this.MapInstance.resize();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'mapTheme', 'mapzoom', 'latitute', 'longitude', 'mapinteractive')) {
            this.Load(nextProps);
            return true;
        }
        return false;
    }
    Load(props = this.props) {
        if (this.Ref_Map && MapBoxWrapper) {       
            this.MapInstance = new MapBoxWrapper.Map({
                container: ReactDOM.findDOMNode(this.Ref_Map.current),
                style: props.mapTheme || 'mapbox://styles/mapbox/streets-v10',
                zoom: props.mapzoom || 13,
                center: [props.latitude || -122.420679, props.longitude || 37.772537],
                interactive : Utils.UseNull(props.mapinteractive, true)
            });
        }
    }
   
    
    render() {        
       
        return (
            <div style={{...this.props.style, overflow : 'hidden', width : '100%', height: '100%'}} >
                <div style={{width : '100%', height:'100%'}} ref={this.Ref_Map} />
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />                
            </div>
        )
    }
}

export default Widget_MapBox;
