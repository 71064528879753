import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Globals
} from '../../../importer';

import styled, {css} from 'styled-components';

import {ColorPoint, SvgCircle} from './icons';

class GradientEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.DragPoint = this.DragPoint.bind(this);
        this.DragEnd = this.DragEnd.bind(this);

        this.DragPointStop = this.DragPointStop.bind(this);
        this.DragEndStop = this.DragEndStop.bind(this);

        this.Id = Utils.Id();
        this.Load(this.props.linear, this.props.colors, this.props.gradientType);

        this.onSave = this.onSave.bind(this);
        this.AddColor = this.AddColor.bind(this);
        this.DeleteColor = this.DeleteColor.bind(this);
        this.onChangeStop = this.onChangeStop.bind(this);

        this.UptdateGradientCss = this.UptdateGradientCss.bind(this);
    }
    UptdateGradientCss() {
        this.props.gradientValue.value = Utils.GetGradientCss(this.props.colors, this.props.gradientType, this.props.linear.angle, Globals.ProjectManager.Tokens.ValueOfId);
        this.RCUpdate();
        if (this.props.onChangeGradientCss)
            this.props.onChangeGradientCss(this.props.gradientValue.value);
    }
    DeleteColor() {
        if (this.SelectedPoint) {
            this.props.colors.splice(this.SelectedPoint-1, 1);
            this.UptdateGradientCss();
        }
    }
    AddColor(e) {
        if (e) {

            const {p1, p2} = this.props.linear;
            const refsvg = this.Ref_Svg
            if (!this.pt) {
                if (refsvg)
                    this.pt = refsvg.createSVGPoint();
                else
                    return;
            }
            this.pt.x = e.clientX;
            this.pt.y = e.clientY;

            const loc = this.pt.matrixTransform(refsvg.getScreenCTM().inverse());

            const x = loc.x;
            const y = loc.y;

            var p = closestPolyLinePoint(x, y,
                p1.sx, p1.sy, p2.sx, p2.sy
            );
            if (p) {
                var xDist = p.x - p1.sx;
                var yDist = p.y - p1.sy;
                // console.log(xDist);
                var dist = Math.sqrt(xDist * xDist + yDist * yDist);
                this.props.colors.push({
                    stop : dist / 285 * 100,
                    color : 'black'
                });
                this.SelectedPoint = this.props.colors.length;
                this.StopPointHandled = true;
                this.UptdateGradientCss();
                this.props.onChangeCurve(this.props.linear, this.props.colors);
            }
            return;

        }
    }            
    onChangeStop(e) {
        if (this.props.colors[this.SelectedPoint-1]) {
            this.props.colors[this.SelectedPoint-1].stop = Utils.ToNumber(e.target.value);
            this.UptdateGradientCss();
        }
    }
    DragStart(point) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        this.StopPointHandled = true;
        document.addEventListener('mouseup', this.DragEnd);
        document.addEventListener('mousemove', this.DragPoint);
        this.Dragging = this.props.linear['p'+(point+1)];
        this.UptdateGradientCss();
    }
    DragEnd(e) {
        this.StopPointHandled = true;
        document.removeEventListener('mouseup', this.DragEnd);
        document.removeEventListener('mousemove', this.DragPoint);
        this.UptdateGradientCss();
        this.onSave();
        this.Dragging = null;        
    }
    DragPoint(e) {
        const {p1, p2} = this.props.linear;
        this.StopPointHandled = true;

        const DN = ReactDOM.findDOMNode(this);
        const Bounds = DN.getBoundingClientRect();
        const x = e.clientX - Bounds.left;
        const y = e.clientY - Bounds.top;
        const r = (Bounds.right - Bounds.left) / 2;

        const angle = Math.atan2(y-r, x - r);
        if (this.Dragging === p2) {
            p1.x = r + r * Math.cos(angle);
            p1.y = r * Math.sin(angle) + r;
            const angleDegree = angle * 180 / Math.PI;
            p2.x = r + r * Math.cos((angleDegree + 180) * Math.PI / 180);
            p2.y = r +  r * Math.sin((angleDegree + 180) * Math.PI / 180);
            this.props.linear.angle = (Math.abs(angleDegree + 630 ) % 360);
        }
        else if (this.Dragging === p1) {
            p2.x = r + r * Math.cos(angle);
            p2.y = r * Math.sin(angle) + 200;
            const angleDegree = angle * 180 / Math.PI;
            p1.x = r + r * Math.cos((angleDegree + 180) * Math.PI / 180);
            p1.y = r +  r * Math.sin((angleDegree + 180) * Math.PI / 180);
            this.props.linear.angle = (Math.abs(angleDegree + 810) % 360);
        }
        this.props.onChangingAngle(this.props.linear.angle);
        this.UptdateGradientCss();
    }

    DragStartStopPoint(point, e) {
        e && e.stopPropagation();
        this.StopPointHandled = true;
        document.addEventListener('mouseup', this.DragEndStop);
        document.addEventListener('mousemove', this.DragPointStop);
        this.DraggingStopPoint = point;
        this.SelectedPoint = this.DraggingStopPoint + 1;
        this.LastStart = {
            x : e.clientX,
            y : e.clientY
        };
        this.UptdateGradientCss();
    }
    DragEndStop(e) {
        e && e.stopPropagation();
        this.StopPointHandled = true;
        document.removeEventListener('mouseup', this.DragEndStop);
        document.removeEventListener('mousemove', this.DragPointStop);

        if (this.LastStart && this.LastStart.x === e.clientX && this.LastStart.y === e.clientY) {
            this.SelectedPoint = this.DraggingStopPoint+1;
            this.UptdateGradientCss();
        }
        else {
            this.DraggingStopPoint = null;
            this.UptdateGradientCss();
            this.props.onChangeCurve(this.props.linear, this.props.colors);
        }
    }
    DragPointStop(e) {
        this.StopPointHandled = true;
        const {p1, p2} = this.props.linear;
        const refsvg = this.Ref_Svg
        if (!this.pt) {
            if (refsvg)
                this.pt = refsvg.createSVGPoint();
            else
                return;
        }
        this.pt.x = e.clientX;
        this.pt.y = e.clientY;

        const loc = this.pt.matrixTransform(refsvg.getScreenCTM().inverse());

        const x = loc.x;
        const y = loc.y;

        var p = closestPolyLinePoint(x, y,
            p1.sx, p1.sy, p2.sx, p2.sy
        );
        if (p) {
            const point = this.props.colors[this.DraggingStopPoint];
            var xDist = p.x - p1.sx + 30;
            var yDist = p.y - p1.sy + 30;
            // console.log(xDist);
            var dist = Math.sqrt(xDist * xDist + yDist * yDist);
            point.stop = dist / 230 * 100;
            this.UptdateGradientCss();
            this.props.onChangeCurve(this.props.linear, this.props.colors);
        }

    }

    Load(path, colors, gradientType) {                
        
    }
    onSave() {
        this.props.onChangeCurve(this.props.linear, this.props.colors);
    }
    componentDidMount() {
        super.componentDidMount();
        const DN = ReactDOM.findDOMNode(this);
        this.Bounds = DN.getBoundingClientRect();
        this.Radius = this.Bounds.height / 2;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!nextProps.editing) {
            this.ColorPickerVisible = false;
            this.SelectedPoint = 0;
        }
        else if (!Utils.IsEqual(this.props.linear, nextProps.linear) || !Utils.IsEqual(this.props.colors, nextProps.colors) || nextProps.gradientType !== this.props.gradientType)
            this.Load(nextProps.linear, nextProps.colors, nextProps.gradientType);
        return true;
    }
    SetSelectedPoint(p, e) {
        e && e.stopPropagation();
        this.SelectedPoint = p;
        this.RCUpdate();
    }

    renderCustom() {
        const sortedColor = Utils.Sort(this.props.colors, (c) => {return c.stop})
        let width = 220;
        let editor = null;
        let angle = 45;
        if (this.props.editing) {
            width = 152;
        }

        let previewStyle = {
            width : this.props.editing ? (this.props.circle ? '242px' : '152px') : '254px',
            height : this.props.editing ? (this.props.circle ? '242px' : '152px') : '254px',
            background : this.props.gradientValue.value
        };
        if (this.props.circle)
            previewStyle.borderRadius = '50%';

        let colorPoint = {};
        if (this.props.editing && this.SelectedPoint > 0 && this.props.colors[this.SelectedPoint-1]) {
            colorPoint = this.props.colors[this.SelectedPoint-1];
            if (colorPoint.colorId)  {
                colorPoint.color = Globals.ProjectManager.Tokens.ValueOfId(colorPoint.colorId);
            }
        }

        return (
            <SC.FCol alc f1 style={{alignSelf : 'strech'}}>               
                <SC.GridBackground dark={!SC.CurrentTheme.theme.isLight} style={{display : 'flex', flexDirection : 'column', alignItems : 'center', position:'relative', width : '270px', height : '280px'}} onClick={
                    (e) => {
                        if (this.StopPointHandled)
                            this.StopPointHandled = false;
                        else {
                            this.SelectedPoint = null;
                            this.RCUpdate();
                        }
                    }
                }>                    
                    <SC.Div_Flex_Cell fw fh style={{position:'relative'}}>                        
                        <div style={previewStyle} />
                        {
                            (this.props.editing) ?
                            <EditorBox style={{transform : `rotate(${this.props.linear.angle}deg)`, zIndex : 10000 }} >
                                <div style={{
                                        position : 'absolute',
                                        left : '127px',
                                        top : 12,
                                        cursor : 'move'
                                    }}
                                    onMouseDown={this.DragStart.bind(this, 0)}
                                >
                                    <SC.Svg width="16" height="10" viewBox="0 0 16 10">
                                        <defs>
                                            <filter id="arr_a" width="121.4%" height="133.3%" x="-10.7%" y="-16.7%" filterUnits="objectBoundingBox">
                                            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
                                            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.761209239 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                            <feMerge>
                                                <feMergeNode in="shadowMatrixOuter1"/>
                                                <feMergeNode in="SourceGraphic"/>
                                            </feMerge>
                                            </filter>
                                        </defs>
                                        <g fill="#FFF" fillRule="evenodd" filter="url(#arr_a)" transform="rotate(-180 7.5 4.5)">
                                            <path fillRule="nonzero" d="M13.8 4.3L9.67.05s1.65 1.62 1.65 4.22c0 2.6-1.65 4.27-1.65 4.27L13.8 4.3zM.23 4.3L4.36.05S2.7 1.67 2.7 4.27c0 2.6 1.66 4.27 1.66 4.27L.23 4.3z"/>
                                            <path d="M2 3.65h10V5H2z"/>
                                        </g>
                                    </SC.Svg>
                                </div>
                                <div style={{
                                        position : 'absolute',
                                        left : '127px',
                                        bottom : 12,
                                        cursor : 'move'
                                    }}
                                    onMouseDown={this.DragStart.bind(this, 1)}
                                >
                                    <SC.Svg width="16" height="10" viewBox="0 0 16 10">
                                        <defs>
                                            <filter id="arr_a" width="121.4%" height="133.3%" x="-10.7%" y="-16.7%" filterUnits="objectBoundingBox">
                                            <feOffset in="SourceAlpha" result="shadowOffsetOuter1"/>
                                            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.761209239 0" in="shadowBlurOuter1" result="shadowMatrixOuter1"/>
                                            <feMerge>
                                                <feMergeNode in="shadowMatrixOuter1"/>
                                                <feMergeNode in="SourceGraphic"/>
                                            </feMerge>
                                            </filter>
                                        </defs>
                                        <g fill="#FFF" fillRule="evenodd" filter="url(#arr_a)" transform="rotate(-180 7.5 4.5)">
                                            <path fillRule="nonzero" d="M13.8 4.3L9.67.05s1.65 1.62 1.65 4.22c0 2.6-1.65 4.27-1.65 4.27L13.8 4.3zM.23 4.3L4.36.05S2.7 1.67 2.7 4.27c0 2.6 1.66 4.27 1.66 4.27L.23 4.3z"/>
                                            <path d="M2 3.65h10V5H2z"/>
                                        </g>
                                    </SC.Svg>
                                </div>
                                <SC.Div_Flex_Cell fw fh >
                                    <svg ref={
                                        (r) => {
                                            this.Ref_Svg = r;
                                            }
                                        } width="245" height="245" viewBox="0 0 6 245" >
                                        <defs>
                                            <path id="li_b" d="M.06 3h240v2H.06z"/>
                                            <filter id="li_a" width="102.5%" height="400%" x="-1.2%" y="-150%" filterUnits="objectBoundingBox">
                                            <feMorphology radius=".5" operator="dilate" in="SourceAlpha" result="shadowSpreadOuter1"/>
                                            <feOffset in="shadowSpreadOuter1" result="shadowOffsetOuter1"/>
                                            <feGaussianBlur stdDeviation=".5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
                                            <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.234601449 0" in="shadowBlurOuter1"/>
                                            </filter>
                                        </defs>
                                        <g fill="none" fillRule="evenodd" transform="rotate(90 2.5 4.5)" style={{cursor:`url(${process.env.PUBLIC_URL}/assets/arrows.png), auto`}} onClick={this.AddColor}>
                                            <use fill="#000" filter="url(#li_a)" xlinkHref="#li_b"/>
                                            <use fill="#FFF" xlinkHref="#li_b"/>
                                        </g>
                                    </svg>
                                    {
                                        this.props.colors.map((color, i) => {
                                            return (
                                                <StopPoint
                                                    key={i}
                                                    linear={this.props.linear}
                                                    stop={color}
                                                    index={i}
                                                    onDragStart={this.DragStartStopPoint.bind(this, i)}
                                                    dragging={this.DraggingStopPoint === i}
                                                    selected={this.SelectedPoint === i+1}
                                                    onSelect={this.SetSelectedPoint.bind(this, i+1)}
                                                />
                                            )
                                        })
                                    }
                                </SC.Div_Flex_Cell>
                            </EditorBox> : null
                        }
                        {
                            this.props.editing && !this.props.circle ?
                            <Editor key={0} /> : null
                        }
                    </SC.Div_Flex_Cell>

                </SC.GridBackground>
            </SC.FCol>            
        )
    }
}

export default GradientEditor;

const EditorBox = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    bottom : 0;
`;


const Box_Offset = styled.div`
    background-color : ${props => props.theme.back};
    border-radius : 2px;
    border : ${props => props.theme.border_ondark};
    width : 42px;
    height : 24px;
    font-size : 10px;
    display : flex;
    flex-direction : row;
    justify-content : space-around;
    align-items : center;
    padding-left : 4px;
    padding-right : 4px;
    box-sizing : border-box;
`;
const Input_Offset = styled.input`
    font-size : 10px;
    font-weight : 500;
    border : none;
    width : 100%;
    background : transparent;
    text-align : right;
    padding-right : 4px;
`;

const lineLength = (x, y, x0, y0) => {
    return Math.sqrt((x -= x0) * x + (y -= y0) * y);
}

const dotLineLength = (x, y, x0, y0, x1, y1, o) => {

    if(o && !(o = function(x, y, x0, y0, x1, y1){
        if(!(x1 - x0)) return {x: x0, y: y};
        else if(!(y1 - y0)) return {x: x, y: y0};
        var left, tg = -1 / ((y1 - y0) / (x1 - x0));
        return {x: left = (x1 * (x * tg - y + y0) + x0 * (x * - tg + y - y1)) / (tg * (x1 - x0) + y0 - y1), y: tg * left - tg * x + y};
    }(x, y, x0, y0, x1, y1), o.x >= Math.min(x0, x1) && o.x <= Math.max(x0, x1) && o.y >= Math.min(y0, y1) && o.y <= Math.max(y0, y1))){
        var l1 = lineLength(x, y, x0, y0), l2 = lineLength(x, y, x1, y1);
        return l1 > l2 ? l2 : l1;
    }
    else {
        var a = y0 - y1, b = x1 - x0, c = x0 * y1 - y0 * x1;
        return Math.abs(a * x + b * y + c) / Math.sqrt(a * a + b * b);
    }
};
const closestPolyLinePoint = function(px, py, x0, y0, x1, y1){

    for(var args = [].slice.call(arguments, 0), lines = []; args.length > 4; lines[lines.length] = {y1: args.pop(), x1: args.pop(), y0: args.pop(), x0: args.pop()});
    if(!lines.length)
        return {x: px, y: py};
    for(var l, i = lines.length - 1, o = lines[i],
        lower = {i: i, l: dotLineLength(px,    py, o.x0, o.y0, o.x1, o.y1, 1)};
        i--; lower.l > (l = dotLineLength(px, py,
        (o = lines[i]).x0, o.y0, o.x1, o.y1, 1)) && (lower = {i: i, l: l}));
    py < Math.min((o = lines[lower.i]).y0, o.y1) ? py = Math.min(o.y0, o.y1)
        : py > Math.max(o.y0, o.y1) && (py = Math.max(o.y0, o.y1));
    px < Math.min(o.x0, o.x1) ? px = Math.min(o.x0, o.x1)
        : px > Math.max(o.x0, o.x1) && (px = Math.max(o.x0, o.x1));
    Math.abs(o.x0 - o.x1) < Math.abs(o.y0 - o.y1) ?
        px = (py * (o.x0 - o.x1) - o.x0 * o.y1 + o.y0 * o.x1) / (o.y0 - o.y1)
        : py = (px * (o.y0 - o.y1) - o.y0 * o.x1 + o.x0 * o.y1) / (o.x0 - o.x1);
    return {x: px, y: py};
};



class StopPoint extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Id_a = Utils.Id();
        this.Id_b = Utils.Id();
        AppState.onUpdateStop = () => this.RCUpdate();
    }
    renderCustom() {
        const {linear, stop} = this.props;

        var xDist = Math.abs(linear.p2.sx - linear.p1.sx);
        var yDist = Math.abs(linear.p2.sy - linear.p1.sy + 50);

        let x = linear.p1.sx + xDist * stop.stop / 100 - (this.props.selected ? 2 : 0);
        let y = linear.p1.sy - yDist * stop.stop / 100 - (this.props.selected ? 2 : 0) - 20;

        return (
            <div style={{position : 'absolute', top:`${y}px`, left : `${x}px`}} onMouseDown={this.props.onDragStart} >
                <ColorPoint size={this.props.selected ? 18 : 13} ida={this.Id_a} idb={this.Id_b} color={stop.color} />
            </div>
        );
    }
}
class Editor extends ReactBaseComponent
{

    renderCustom() {
        const style = {
            display : 'flex',
            alignItems : 'center',
            justifyContent : 'center'
        };
        return (
            <EditorBox style={style}>
                <SvgCircle />
            </EditorBox>
        )
    }
}
