import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';
import { motion } from 'framer-motion';
import Flatbush from 'flatbush';
import DragDropManager from '../../../../../../../components/dragdrop/DragDropManager';
import { COLOR_HANDLE, COLOR_HANDLE_SUBCOMPONENT } from '../../../../../panzoom/selectedItemDesigner/spacer';

export default class DropOverlay extends React.Component {
    constructor(props) {
        super(props);

        this.onMouseMove = this.onMouseMove.bind(this);
        this.CheckTarget = this.CheckTarget.bind(this);
        this.CheckTargetThrottled = Utils.Throttle(this.CheckTarget, 200);
        this.ActivateTarget = this.ActivateTarget.bind(this);
        this.DropItem = this.DropItem.bind(this);

        AppLayout.Refs.DropOverlay = this;

        this.state = {  }
    }
    componentWillUnmount() {        
        AppLayout.Refs.DropOverlay = null;
    }
    Activate(DropData, CheckTarget) {
        if (this.mounted) {
            this.bounds = UIUtils.Utils.GetBounds(this);
            this.DropData = DropData;        
    
            if (!this.DropData)
                return;
                
            const rootPlaceholders = this.DropData.Placeholders;
            if (!rootPlaceholders)
                return;
    
            if (DragDropManager.DragData && DragDropManager.DragData.DragItem.SourceRef && !DragDropManager.DragData.Copy) {
                this.SourceBounds = UIUtils.Utils.GetBounds(DragDropManager.DragData.DragItem.SourceRef);
                this.state.isOverSelf = UIUtils.Utils.IsOverBounds({x : this.lastX, y : this.lastY}, this.SourceBounds);
            }
            
            if (rootPlaceholders.length > 0) {
                this.targets = new Flatbush(rootPlaceholders.length);    
    
                Utils.ForEach(rootPlaceholders , (placeholder, i) => {            
                    const bounds = UIUtils.Utils.GetBounds(placeholder.ref);
                    placeholder.bounds = bounds;
                    this.targets.add(bounds.left, bounds.top, bounds.left + bounds.width, bounds.top + bounds.height);
                });    
        
                this.targets.finish();
                this.CheckTarget(this.lastX, this.lastY);
            }
            
            this.setState({loaded : true})  
        }  
        else {
            this.DropData = DropData;
        }
    }
    RefreshDropTargets() {
        this.setState({currentId : null});
    }
    componentDidMount() {
        this.mounted = true;        
        if (!this.state.loaded && this.DropData)  {
            this.Activate(this.DropData);
        }
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.currentId !== nextState.currentId) {
            return true;
        }
        if (this.state.loaded !== nextState.loaded) {
            return true;
        }        

        if (this.state.isOverSelf !== nextState.isOverSelf)
            return true;
            
        if (this.state.isSuspended !== nextState.isSuspended)
            return true;

        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.isOverSelf && !this.state.isOverSelf) {
            this.CheckTarget(this.lastX, this.lastY);
        }
    }
    onMouseMove(e) {
        this.lastX = e.clientX;
        this.lastY = e.clientY;
        // console.log(`Move ${this.lastX}:${this.lastY}`);
        if (this.state.isOverSelf)
            return;
        this.state.loaded && this.CheckTargetThrottled(this.lastX, this.lastY);
    }    
    CheckTarget(x, y) {     
        // console.log(`Check Target ${x}:${y}`);
        if (this.SubTargets) {
            const nearestTarget = this.SubTargets.neighbors(x, y, 1);    
            if (nearestTarget.length > 0) {     
                let newPlaceholder;
                let ParentTargets = this.ParentSubTargets || this.targets;
                if (ParentTargets) {
                    const nearestParent = ParentTargets.neighbors(x, y, 1, 8);                
                    if (nearestParent.length > 0) {
                        const index = nearestParent[0];
                        const DropTarget = Utils.Get(this, {}, 'SubDropTargets', this.CurrentTargetId);
                        if (this.ParentSubTargets) {
                            const ParentDropTarget = Utils.Get(this, {}, 'SubDropTargets', DropTarget.ParentId);
                            newPlaceholder = ParentDropTarget.placeholders[index];
                        }
                        else {
                            newPlaceholder = this.DropData.Placeholders[index];
                        }
                    }
                }
                
                if (!newPlaceholder) {
                    const index = nearestTarget[0];
                    const DropTarget = Utils.Get(this, {}, 'SubDropTargets', this.CurrentTargetId);
                    if (DropTarget) {
                        newPlaceholder = DropTarget.placeholders[index];                        
                    }
                }

                if (newPlaceholder) {
                    if (this.currentPlaceholder) {
                        if (this.currentPlaceholder.Id === newPlaceholder.Id)
                            return;
                        this.currentPlaceholder.ref.Toggle(false);
                    }
                    this.currentPlaceholder = newPlaceholder;                        
                    this.currentPlaceholder.ref.Toggle(!this.state.isOverSelf);
                    this.setState({currentId : this.currentPlaceholder.Id});
                    return;
                }                                
            }
        }
        if (this.targets && this.DropData) {
            const nearestTarget = this.targets.neighbors(x, y, 1);
            if (nearestTarget.length > 0) {            
                const index = nearestTarget[0];
                const currentPlaceholder = this.DropData.Placeholders[index];
                if (this.currentPlaceholder) {
                    if (this.currentPlaceholder.Id === currentPlaceholder.Id)
                        return;
                    this.currentPlaceholder.ref.Toggle(false);
                }
                this.currentPlaceholder = currentPlaceholder;                        
    
                setTimeout(() => {
                    if (!this.unmounted && this.currentPlaceholder) {
                        this.currentPlaceholder.ref.Toggle(!this.state.isOverSelf);
                        this.setState({currentId : this.currentPlaceholder.Id})        
                    }                
                }, 100);
                
            }
        }
        
    }
    ActivateTarget(active, TargetId, ParentTargetId, placeholders) {
        const DropTargets = Utils.Get(this, {}, 'SubDropTargets');
        this.SubTargets = null;
        this.ParentSubTargets = null;

        const LoadTarget = (CurrenetTargetId, TargetData) => {
            this.CurrentTargetId = CurrenetTargetId;
            DropTargets[TargetId] = TargetData;
            if (TargetData.placeholders.length > 0) {
                const checkbounds = [];                
                Utils.ForEach(TargetData.placeholders, (placeholder, i) => {
                    if (placeholder.ref && !placeholder.ref.unmounted) {
                        const bounds = UIUtils.Utils.GetBounds(placeholder.ref);                    
                        placeholder.bounds = bounds;
                        checkbounds.push(bounds);
                        placeholder.ref.ToggleVisible(true)
                    }                    
                });
                if (checkbounds.length > 0) {
                    this.SubTargets = new Flatbush(checkbounds.length);
                    checkbounds.map((bounds) => {
                        this.SubTargets.add(bounds.left, bounds.top, bounds.left + bounds.width, bounds.top + bounds.height);
                    })
                    this.SubTargets.finish();
                }                
            }
            if (TargetData.ParentId && DropTargets[TargetData.ParentId]) {
                const ParentTargetData = DropTargets[TargetData.ParentId];
                if (ParentTargetData.placeholders.length > 0) {
                    const checkbounds = [];                
                    Utils.ForEach(ParentTargetData.placeholders, (placeholder, i) => {
                        if (placeholder.ref && !placeholder.ref.unmounted) {
                            const bounds = UIUtils.Utils.GetBounds(placeholder.ref);                    
                            placeholder.bounds = bounds;
                            checkbounds.push(bounds);
                            placeholder.ref.ToggleVisible(true)
                        }                    
                    });
                    if (checkbounds.length > 0) {
                        this.ParentSubTargets = new Flatbush(checkbounds.length);
                        checkbounds.map((bounds) => {
                            this.ParentSubTargets.add(bounds.left, bounds.top, bounds.left + bounds.width, bounds.top + bounds.height);
                        })
                        this.ParentSubTargets.finish();
                    }                
                }
            }
        }

        if (active) {
            LoadTarget(TargetId, {
                ParentId : ParentTargetId,
                placeholders : placeholders
            });
        }
        else {
            if (DropTargets[TargetId] && DropTargets[TargetId].ParentId) {                
                LoadTarget(DropTargets[TargetId].ParentId, DropTargets[DropTargets[TargetId].ParentId])                
            }
        }
        this.CheckTarget(this.lastX, this.lastY);       
    }
    DropItem(e) {
        e && e.stopPropagation();
        this.setState({loaded : false});

        if (!this.state.isOverSelf && this.currentPlaceholder && this.currentPlaceholder.ref) {
            this.props.onEndDrag({
                drop : true,
                data : this.currentPlaceholder.data
            });
        }
        else {
            this.props.onEndDrag({
                drop : false
            });
        }        
    }
    MouseOverDragItem(isOver) {        
        if (this.currentPlaceholder)
            this.currentPlaceholder.ref.Toggle(!isOver);
        this.setState({isOverSelf : isOver});
    }
    Suspend(isSuspended) {
        if (this.currentPlaceholder)
            this.currentPlaceholder.ref.Toggle(!isSuspended);
        this.setState({isSuspended : isSuspended});
    }
    render() { 
        this.state.ShouldUpdate = false;
        
        return (
            <SC.AbsoluteOverlay 
                style={{
                    // backgroundColor : 'rgba(0,0,0,0.4)'
                }}
                onMouseLeave={this.onMouseLeaveOverlay}
                onMouseUp={this.DropItem}
                onMouseMove={this.onMouseMove}
                onMouseOver={this.onMouseMove}
            >
                {
                    this.state.loaded && this.currentPlaceholder && this.currentPlaceholder.ref && !this.state.isSuspended &&
                    <motion.div
                        initial={false}
                        animate={{
                            top : (this.currentPlaceholder.bounds.top - this.bounds.top -2) / AppLayout.Designer.Zoom,
                            left : (this.currentPlaceholder.bounds.left - this.bounds.left - 2) / AppLayout.Designer.Zoom,
                            // right : ((this.currentPlaceholder.left - this.bounds.left) / AppLayout.Designer.Zoom) + (this.currentPlaceholder.width / AppLayout.Designer.Zoom),
                            // bottom : ((this.currentPlaceholder.top - this.bounds.top) / AppLayout.Designer.Zoom) + (this.currentPlaceholder.height / AppLayout.Designer.Zoom),
                            width : (this.currentPlaceholder.bounds.width + 4) / AppLayout.Designer.Zoom,
                            height : (this.currentPlaceholder.bounds.height + 4) / AppLayout.Designer.Zoom
                        }}
                        style={{
                            position : 'absolute',
                            backgroundColor : '#ff5ae3',
                            opacity : this.state.isOverSelf ? 0 : 0.3,
                            pointerEvents : 'none'
                            // width : Utils.px(this.currentPlaceholder.width / AppLayout.Designer.Zoom),
                            // height : Utils.px(this.currentPlaceholder.height / AppLayout.Designer.Zoom)
                        }}
                        transition={{
                            duration : 0.1
                        }}
                    >

                    </motion.div>
                }                
                {
                    this.state.loaded && this.SourceBounds && 
                    <div style={{
                        position : 'absolute',
                        top : Utils.px((this.SourceBounds.top - this.bounds.top) / AppLayout.Designer.Zoom),
                        left : Utils.px((this.SourceBounds.left - this.bounds.left) / AppLayout.Designer.Zoom),
                        width : Utils.px(this.SourceBounds.width / AppLayout.Designer.Zoom),
                        height : Utils.px(this.SourceBounds.height / AppLayout.Designer.Zoom),
                        // backgroundColor : this.state.isOverSelf ? 'red' :  'blue',
                        opacity : 0.5,
                        pointerEvents : 'all',
                        zIndex : 99999999
                    }} 
                        onMouseOver={this.MouseOverDragItem.bind(this, true)}
                        onMouseOut={this.MouseOverDragItem.bind(this, false)}
                    />
                }
                {
                   this.state.loaded && this.DropData && this.DropData.DropTargets[this.props.RootId] && this.DropData.DropTargets[this.props.RootId].map((DropTarget, i) => {
                        return (
                            <SubDropTarget 
                                DropTarget={DropTarget} 
                                key={i} 
                                offsetTop={this.bounds.top}
                                offsetLeft={this.bounds.left}
                                DropTargets={this.DropData.DropTargets}
                                onActivateChildPlaceholders={this.ActivateTarget}
                            />
                        )                        
                    })
                }      
            </SC.AbsoluteOverlay>
        );
    }
}

class SubDropTarget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }       
    }
    componentWillUnmount() {
        this.unmounted = true;
        if (this.state.active)
            this.props.DropTarget.ref.ActivateTarget(false);
    }
    ActivateTarget(active) {
        const {DropTarget} = this.props;
        if (active) {
            this.willbeActive = true;
            setTimeout(() => {
                if (this.willbeActive && !this.unmounted) {
                    DropTarget.ref.ActivateTarget(true, () => {
                        this.props.DropTargets && this.props.DropTargets[DropTarget.Id] && this.props.DropTargets[DropTarget.Id].map((ChildTarget, i) => {
                            ChildTarget.bounds = UIUtils.Utils.GetBounds(ChildTarget.ref);
                        });  
                        if (this.willbeActive) {
                            this.willbeActive = false;
                            const placeholders = Utils.Get(DropTarget.ref, [], 'DropData', 'Placeholders');
                            this.props.onActivateChildPlaceholders(true, DropTarget.Id, this.props.ParentId, placeholders);
                            this.setState({active : active});
                        }
                        else {
                            DropTarget.ref.ActivateTarget(false);
                        }                        
                    })                    
                }
            }, DropTarget.SubComponent ? 200 : 50);            
        }     
        else {
            this.willbeActive = false;
            if (this.state.active) {
                const placeholders = Utils.Get(DropTarget.ref, [], 'DropData', 'Placeholders');
                this.props.onActivateChildPlaceholders(false, DropTarget.Id, this.props.ParentId, placeholders);
                DropTarget.ref.ActivateTarget(false);
                this.setState({active : false});                
            }            
        }   
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.active !== nextState.active) {
            return true;
        }
        return false;
    }
    render() { 
        const {DropTarget} = this.props;
        return (  
            
            <div key={DropTarget.Id} 
                style={{
                    position : 'absolute',
                    top : Utils.px((DropTarget.bounds.top - this.props.offsetTop) / AppLayout.Designer.Zoom),
                    left : Utils.px((DropTarget.bounds.left - this.props.offsetLeft) / AppLayout.Designer.Zoom),
                    width : Utils.px(DropTarget.bounds.width / AppLayout.Designer.Zoom),
                    height : Utils.px(DropTarget.bounds.height / AppLayout.Designer.Zoom),
                    outline : '1px dashed',
                    outlineColor : DropTarget.SubComponent ? COLOR_HANDLE_SUBCOMPONENT : COLOR_HANDLE,
                    boxSizing : 'border-box',
                    zIndex : DropTarget.zIndex,
                    // outline : this.state.active ? '4px solid red' : '4px solid blue'
                    // backgroundColor : 'blue',
                    // opacity : 0.5
                }}                
                onMouseEnter={this.ActivateTarget.bind(this, true)}
                onMouseLeave={this.ActivateTarget.bind(this, false)}
            >
                {
                    this.state.active && this.props.DropTargets && this.props.DropTargets[DropTarget.Id] && this.props.DropTargets[DropTarget.Id].map((ChildTarget, i) => {
                        return (
                            <SubDropTarget 
                                DropTarget={ChildTarget} 
                                key={i} 
                                offsetTop={DropTarget.bounds.top}
                                offsetLeft={DropTarget.bounds.left}
                                DropTargets={this.props.DropTargets}
                                onActivateChildPlaceholders={this.props.onActivateChildPlaceholders}
                                ParentId={DropTarget.Id}
                            />
                        )  
                    })
                }
            </div>
        );
    }
}
