import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import styled from 'styled-components';
import ChromaJs from 'chroma-js';
import { TokenTypes } from '../../../../views/project/manager/tokens';

import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';

import { SelectionItemBox } from './newstyleitem';

export default class LocalStyleTokenItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        };

    }
    render() {
        const {styleItem, type} = this.props;
        if (type === TokenTypes.COLOR) {
            return (
                <SelectionItemBox
                    jsb alc                
                >
                    <FigmaColorBox  round color={styleItem.rgba} />
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.Shadows) {
            return (
                <SelectionItemBox
                    jsb alc                
                >
                    <div 
                        style={{
                            width : '20px',
                            height : '20px',
                            margin : '8px',
                            marginRight : '4px',
                            borderRadius : '2px',
                            backgroundColor : '#fafafa',
                            boxShadow : styleItem.css
                        }}
                    />
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                </SelectionItemBox>
            )
        }        
        else if (type === TokenTypes.TextPatterns) {
            return (
                <SelectionItemBox
                    jsb alc                
                >
                    <div 
                        style={{
                            width : '20px',
                            height : '20px',
                            margin : '8px',
                            marginRight : '4px',
                            borderRadius : '2px',
                            backgroundColor : '#fafafa',
                        }}
                    />
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                </SelectionItemBox>
            )
        }
        return (
            <div>{styleItem.name}</div>
        )
    }
}