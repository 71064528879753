import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import Radium from 'radium';

class Generic_Checkbox extends BaseItem
{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);       
    }
    onChange(e) {
        const isChecked = Utils.IsTrue(e.target.checked);
        this.props.onSetModelValue('Checked', isChecked);
    }
    onFocus(e) {
        if (window.AppState) {
            window.AppState.Designer.SetEditingText(true);;
        }
        this.props.onSetState({
            MergeWithCurrentState : true,
            Refersh : true,
            Variations : {
                System : 'active'
            }
        })
    }
    onBlur(e) {
        if (window.AppState) {
            window.AppState.Designer.EditingText = false;
        }
        this.props.onUnsetState({
            MergeWithCurrentState : true,
            Refersh : true,
            Variations : {
                System : 'active'
            }
        })
    }
    renderItem({style, props, events, rest}) {

        const isChecked = Utils.IsTrue(this.props.onGetModelValue('Checked'));
        const style_check = {            
            
        };
        style_check.stroke = Utils.JustGet(props.GenericOptions, '#fff', 'checkFill');
        style_check.strokeWidth = Utils.JustGet(props.GenericOptions, 1, 'checkStrokeWidth');
        style_check.strokeWidth = Utils.JustGet(props.GenericOptions, 1, 'checkStrokeWidth');
        const roundCap =  Utils.JustGet(props.GenericOptions, false, 'roundCap');
        if (roundCap) {
            style_check.strokeLinecap = 'round';
            style_check.strokeLinejoin = 'round';
        }
        const checkSize = Utils.JustGet(props.GenericOptions, 100, 'checkSize');
        style_check.width = Utils.px(checkSize, '%');
        style_check.height = Utils.px(checkSize, '%');

        return (
            <label style={{...style, position : style.position || 'relative', display : 'inline-block', verticalAlign : 'middle', boxSizing : 'border-box'}} {...events}>
                <input type='checkbox' 
                    style={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        clippath: 'inset(50%)',
                        height: '1px',
                        margin: '-1px',
                        overflow: 'hidden',
                        padding: 0,
                        position: 'absolute',
                        whiteSpace: 'nowrap',
                        width: '1px'
                    }} 
                    checked={isChecked} 
                    {...rest} 
                    onChange={this.onChange} 
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    disabled={this.props.IsStateActive('disabled', 'disabled')}
                    key='CheckInput'
                />
                <div style={{position : 'absolute', top:0, left:0, right:0, bottom:0, display : 'flex', alignItems : 'center', justifyContent : 'center'}}>
                    <svg viewBox="0 0 24 24" style={style_check}>
                        <path fill="none" d="M6,11.3 L10.3,16 L18,6.2" />
                    </svg>       
                </div>
            </label>
        )
    }
}

export default Radium(Generic_Checkbox);