import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals
} from '../../../../../../../importer';

import {GroupTitle, TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName} from '../../common';

import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { PresetCurve } from './bezierEditor/presetcurve';
import EaseTokenEditor from './item';
import { StatefulTokenListItemName, StatefulTokenMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';

export default class MotionEasings extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        
        this.state.isListView = Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'DesignSystem', 'MotionEasings', 'ListView');        
        this.Load(this.props);

        if (Utils.IsNotNullOrEmpty(this.props.filterText)) {
            this.SearchToken(this.props.filterText);
        }

        this.Ref_PatternEdit = React.createRef();
        this.AddPattern = this.AddPattern.bind(this);
        this.onEditPattern = this.onEditPattern.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onClonePattern = this.onClonePattern.bind(this);
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);

        AppLayout.Refs.DesignSystem.EaseCurves = this;

        this.onSortPattern = this.onSortPattern.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextProps);
        }
        return true;
    }
    Load(props) {       
        this.patternItems = GetEaseCurveTokenList();
    }    
    Reload() {
        this.Load();
        this.RCUpdate();
    }
    SearchToken(filter, DoNotUpdate) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.patternItems, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        if (!DoNotUpdate) {
            this.RenderId = Utils.Id();
            this.RCThrottledUpdate_1();
        }        
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'DesignSystem', 'MotionEasings', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    onSortPattern(oldIndex, newIndex) {
        Utils.ChangePlace(this.patternItems, oldIndex, newIndex);
        Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.Motion, oldIndex, newIndex);        
        this.RCUpdate();
    }
    AddPattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternId = Utils.Id();
        this.EditPatternModel = {
            name : 'New Ease Curve',
            value : {
                Default : {
                    value : {
                        x1 : 0.42, y1 : 0, x2 :0.58, y2 : 1
                    }
                }
            }
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : () => {
                return (
                    <EaseTokenEditor 
                        ref={this.Ref_PatternEdit}
                        newModel={this.EditPatternModel}
                        onCancelAddPattern={this.onCancelAddPattern}
                        onSubmitNewPattern={this.onSubmitNewPattern}
                        offline
                    />
                )
            }
        })
    }
    onEditPattern(id) {        
        if (!this.props.hasEditGrant) {
            return;
        }

        this.EditPatternId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <EaseTokenEditor 
                        ref={this.Ref_PatternEdit}
                        id={id}
                        onCancelAddPattern={this.onSubmitNewPattern}
                        onSubmitNewPattern={this.onSubmitNewPattern}
                        onDeletePattern={this.onDeletePattern}
                        onClonePattern={this.onClonePattern}
                        GlobalStateId={props.GlobalStateId}
                    />
                )
            }
        })
    }    
    onDeletePattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Motion, this.EditPatternId)) {
            this.Load(this.props);
            this.onCancelAddPattern();
        }
    }
    onClonePattern() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditPatternId, Globals.ProjectManager.Tokens.Types.Motion);
        this.Load(this.props);
        this.onEditPattern(cloneId);
        // this.props.onUpdate();
    }
    onCancelAddPattern() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            this.patternItems.push(AddNewEaseCurveToken(this.EditPatternModel, this.EditPatternId));
            this.WillScrollTo = this.EditPatternId;
            this.selectedId = this.EditPatternId;
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditPatternId);            
            
            if (token) {
                const localpattern = Utils.Find(this.patternItems, (item) => {return item.id === this.EditPatternId});
                localpattern.path = Globals.ProjectManager.Tokens.ValueOfId(this.EditPatternId);
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
            }            
        }
                
        this.onCancelAddPattern();
    }    

    renderCustom() {
        
        const patternItems = [];        

        Utils.ForEach(this.patternItems, (pattern, i) => {            
            !pattern.filtered && patternItems.push(                    
                <SortableListItem
                    key={pattern.id}
                    draggableId={pattern.id}
                    index={i}
                    ItemBoxType={EasePattern}
                    BoxProps={{
                        listView : this.state.isListView,
                        pattern : pattern,
                        onClick : this.onEditPattern.bind(this, pattern.id)
                    }}                
                />  
            )
        });   
        return (
            <SC.FCol style={{paddingBottom : '4px', marginTop : '8px'}}>
                <GroupTitle 
                    title='Easing Curves' 
                    subGroup
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.AddPattern} : null} 
                    style={{paddingLeft : '12px'}}
                    addOn={(
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}                            
                            compact
                        />
                    )}
                />
                <SortableList
                    style={this.state.isListView ? {
                        paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'
                    } : {
                        display : 'grid',
                        gridGap : '4px',
                        gridTemplateColumns : 'repeat(auto-fill, minmax(56px, 1fr))',
                        gridAutoRows : 'auto',
                        padding : '8px',
                        transition : 'all 0.2s ease'
                    }}
                    onSort={this.onSortPattern}
                >
                    {patternItems}
                </SortableList>
            </SC.FCol>
        )
    }
}

export const EasePattern = ({pattern, listView, notDraggable, sortableProps = {}, style, ...rest}) => {
    const style_box = listView ? {padding : '4px', paddingLeft : '6px', ...SC.Styles.Flex.RowAlcJsb} : {
        
    };

    if (rest.selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }

    if (!listView) {
        return (
            <TokenItemBox {...rest} {...sortableProps.handleProps} style={style_box} title={pattern.name}>                
                <StatefulTokenMarker
                    style={{
                        position : 'absolute',
                        top : '4px',
                        left : '4px'
                    }}
                    {...pattern.valueState}
                />
                <PresetCurve path={pattern.path} size={48} selected />
            </TokenItemBox>            
        )
    }

    return (
        <TokenItemBox {...rest} style={{...style, ...style_box}}>
            <SC.FRow f1 alc overflowHidden>
                <StatefulTokenListItemName 
                    name={pattern.name}
                    sortableProps={notDraggable ? null : sortableProps}
                    ValueState={pattern.valueState}
                />               
            </SC.FRow>                
            <PresetCurve path={pattern.path} size={48} selected />
        </TokenItemBox>
    )
}

export const GetEaseCurveTokenList = (StateArray) => {            
    const tokenids = Globals.ProjectManager.Tokens.Motion();
    return GetEaseCurveTokenListOfIds(tokenids, StateArray);
}

export const GetEaseCurveTokenListOfIds = (tokenids, StateArray) => {            
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetEaseCurveTokenItem(id, StateArray));
    });  
    return items;
}

export const GetEaseCurveTokenItem = (id, StateArray) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return null;
    
    const info = {};
    const path = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info});
    const result = {
        id : id,
        name : token.name,
        path : path,
        valueState : info.ValueState
    };

    if (!path) {
        result.path = {
            x1 : 0.42, y1 : 0, x2 :0.58, y2 : 1
        }
    }

    return result;
}

export const AddNewEaseCurveToken = (model, id) => {
    const paths = Globals.ProjectManager.Tokens.ValueOf({model : model});
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Motion,
        name : model.name,
        value : paths,
        id : id
    })
    return {
        id : id,
        name : model.name,
        path : paths
    }
}
