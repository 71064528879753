import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    MetaData    
} from '../../../../../../../../../importer';

import RadioGroup from '../../../../../../../../../components/editors/radiogroup';
import NumberInput from '../../../../../../../../../components/editors/input_number';
import ItemSizer from './itemsizer';
import GridRowColumnEditor from './RowColumnEditor';
import Switch from '../../../../../../../../../components/editors/Switch';
import {SortableList, SortableListItem} from '../../../../../../../../../components/SortableContainer';
import { CollectionPlus, StyleGroupTitle as GroupTitle, PropertyLabel, Seperator } from '../../../common';
import { InfoPanel, InfoLine } from '../../../../../../../../../components/info';


class TemplateDimension extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.AddColumn = this.AddColumn.bind(this);
        this.DeleteColumn = this.DeleteColumn.bind(this);
        this.CloneColumn = this.CloneColumn.bind(this);
        this.onSortColumns = this.onSortColumns.bind(this);
        this.SaveModel = this.SaveModel.bind(this);
    }
    SaveModel() {
        this.props.SaveModel();
        this.RCUpdate();
    }
    GetMetaColumns() {
        return Utils.Get(this.props.model, [], this.props.templateName, 'Items');      
    }
    AddColumn() {
        const columns = this.GetMetaColumns();
        columns.push({
            Id : Utils.Id(),
            Single : {
                size : {
                    unit : 'fr',
                    value : 1
                }
            }            
        });        
        
        this.SaveModel();
    }
    CloneColumn(i) {
        const columns = this.GetMetaColumns();
        const newcol = Utils.DeepClone(columns[i]);
        newcol.Id = Utils.Id();
        columns.splice(i, 0, newcol);
        this.SaveModel();
    }    
    DeleteColumn(index) {
        const columns = this.GetMetaColumns();
        columns.splice(index, 1);
        this.SaveModel();
    }
    onSortColumns(oldIndex, newIndex) {
        const columns = this.GetMetaColumns();
        Utils.ChangePlace(columns, oldIndex, newIndex);        
        this.SaveModel();
    }    
    getCurrentSize(type, index) {
        return 100;
    }

    renderCustom() {
        const columns = this.GetMetaColumns();

        let DynamicColumn = null;
        let isOnlyFixedColumns = true;        

        if (this.props.templateName === 'Columns') {
            Utils.ForEach(columns, (col, i) => {
                const size = Utils.JustGet(col, null, 'Single', 'size');
                if (size) {
                    if (size.minMax) {
                        const unit_min = Utils.JustGet(size.minMax, null, 'min', 'unit');
                        const unit_max = Utils.JustGet(size.minMax, null, 'max', 'unit');
                        if (unit_min === 'fr' || unit_max === 'fr') {
                            isOnlyFixedColumns = false;
                            return false;
                        }
                    }
                    else {
                        const unit = Utils.JustGet(size, null, 'unit');
                        if (unit === 'fr') {
                            isOnlyFixedColumns = false;
                            return false;
                        }        
                    }
                }
            });

            if (isOnlyFixedColumns)
                DynamicColumn = Utils.JustGet(this.props.model, null, 'Columns', 'DynamicColumn');
        }

        return (
            <SC.FCol>
                <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                    <GroupTitle>{this.props.headerLabel}</GroupTitle>
                    <CollectionPlus onAdd={this.AddColumn} title={`Add Grid ${this.props.singleLabel}`}/>
                </SC.FRow>
                <SortableList
                    onSort={this.onSortColumns}
                >
                    {
                        columns.map((col, i) => {
                            return (
                                <SortableListItem
                                    key={col.Id}
                                    draggableId={col.Id}
                                    index={i}
                                    ItemBoxType={GridRowColumnEditor}
                                    BoxProps={{
                                        data : col,
                                        index : i,
                                        onDelete : this.DeleteColumn,
                                        onClone : this.CloneColumn,
                                        hasAuto : true,
                                        auto : this.props.auto,
                                        label : 'Column',
                                        onSave : this.SaveModel,
                                        getCurrentSize : this.getCurrentSize.bind(this, this.props.singleLabel, i)
                                    }}                
                                />                                
                            )
                        })
                    }
                </SortableList>  
                {
                    this.props.auto ? 
                    <InfoPanel success style={{marginTop:0, marginBottom : 0, fontSize : '11px'}}>
                        Any items out of grid [columns] x [rows] range will be placed into auto generated columns and rows.
                    </InfoPanel> : null
                }
                {
                    this.props.templateName === 'Columns' && (this.props.advancedOptions || DynamicColumn) ? 
                    <SC.FCol>
                        <Seperator />
                        <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                            <PropertyLabel >Append Dynamic Columns</PropertyLabel>
                            <Switch 
                                small
                                value={DynamicColumn ? true : false}
                                readOnly={!isOnlyFixedColumns}
                                onChange={(val) => {
                                    if (val)
                                        Utils.Set(this.props.model, {
                                            type : 'autoFill',
                                            minWidth : {
                                                unit : 'px',
                                                value : 100
                                            },
                                            maxWidth : {
                                                unit : 'fr',
                                                value : 1
                                            }
                                        }, 'Columns', 'DynamicColumn');    
                                    else {
                                        Utils.UnSet(this.props.model, 'Columns', 'DynamicColumn');
                                    }                                    
                                    this.SaveModel();
                                }}
                            />
                        </SC.FRow>                    
                        {
                            DynamicColumn ? 
                            <InfoPanel success={isOnlyFixedColumns} style={{fontSize : '11px'}}>
                                info about dynamic columns
                            </InfoPanel> :
                            <InfoPanel style={{marginBottom : 0, fontSize : '11px'}} success={isOnlyFixedColumns}>
                                Dynamic columns can only be appended if none of the preceeding columns have <span style={{color : 'white'}}>fr (fragment)</span> size
                            </InfoPanel>                            
                        }
                        {
                            DynamicColumn ? 
                            <SC.FCol style={{}}>
                                <RadioGroup 
                                    small
                                    items={[
                                        {id : 'autoFill', label : 'Auto Fill'},
                                        {id : 'autoFit', label : 'Auto Fit'}
                                    ]}
                                    value={DynamicColumn.type}
                                    onSelect={(autoVal) => {
                                        Utils.Set(this.props.model, autoVal, 'Columns', 'DynamicColumn', 'type');
                                        this.SaveModel();
                                    }}
                                />    
                                <SC.FRow alc>
                                    <SC.FCol f1>
                                        Dynamic Cell Count
                                    </SC.FCol>
                                    <SC.FCol>
                                        <NumberInput
                                            spinnded
                                            value={Utils.Get(DynamicColumn, 1, 'CellCount')}
                                            numeralPositiveOnly
                                            onChange={(count) => {
                                                DynamicColumn.CellCount = count;
                                                this.SaveModel();
                                            }}
                                            style={{
                                                textAlign : 'right',
                                                fontSize : '14px',
                                                alignSelf : 'center',
                                                width : '30px'
                                            }}
                                        />
                                    </SC.FCol>
                                </SC.FRow>
                                <SC.FCol>
                                    <SC.FRow alc jsb mt={8}>
                                        <div style={{flex : 1}}>Min. Width</div>
                                        <ItemSizer
                                            index={0}
                                            justSize
                                            noGridFragment
                                            value={Utils.Get(DynamicColumn, {unit : 'px', value : 100}, 'minWidth')}
                                            onSave={this.SaveModel}
                                            getCurrentSize={this.props.getCurrentSize}
                                        />                                  
                                    </SC.FRow>
                                    <SC.FRow alc jsb mt={8}>
                                        <div style={{flex : 1}}>Max. Width</div>
                                        <ItemSizer
                                            index={0}
                                            justSize
                                            value={Utils.Get(DynamicColumn, {unit : 'px', value : 100}, 'maxWidth')}
                                            onSave={this.SaveModel}
                                            getCurrentSize={this.props.getCurrentSize}
                                        />                                  
                                    </SC.FRow>
                                </SC.FCol>                                                            
                            </SC.FCol> : null
                        }
                    </SC.FCol> : null
                }                             
            </SC.FCol>  
        )
    }    
}

export default TemplateDimension;