import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    ReactPureComponent
} from '../../../../../../../../../importer';

import styled, {css} from 'styled-components';
import { SmallButtonGroup, PropertyLabel, StyleGroupTokenItem, StyleItemPopup, StyleGroupTitle } from '../../../common';
import { AnimatePresence } from 'framer-motion';

const FlexValues = MetaData.Properties.FlexValues;
const Flex = MetaData.Properties.layout.values.Flex;

export default class GridJustifyContent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.ToggleContent = this.ToggleContent.bind(this);
        this.ChangeJustifyContent = this.ChangeJustifyContent.bind(this);
        this.ChangeAlignContent = this.ChangeAlignContent.bind(this);
    }
    ChangeJustifyContent(justifyContent) {
        this.props.onChange('justifyContent', justifyContent);
    }
    ChangeAlignContent(alignContent) {
        this.props.onChange('alignContent', alignContent);
    }    
    ToggleContent(e) {
        e && e.stopPropagation();
        
        if (!this.IsClosingPopup) {
            this.setState({
                ContentVisible : !this.state.ContentVisible
            })
        }
            
        this.IsClosingPopup = false;
    }    
    renderCustom() {

        const {justifyContent, alignContent} = this.props;
        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <SC.FCol style={{position : 'relative'}}>
                    <StyleGroupTokenItem >
                        <SC.FRow alc jsb style={{paddingLeft : '8px'}} onClick={this.ToggleContent}>
                            <PropertyLabel>Content Alignment</PropertyLabel>    
                            <AlignmentOption                              
                                selected
                                alignContent={alignContent}
                                justifyContent={justifyContent}
                                all
                                style={{width : '32px', height : '32px'}}
                            />
                        </SC.FRow>                           
                    </StyleGroupTokenItem>
                    {
                        this.state.ContentVisible &&
                        <StyleItemPopup show={this.state.ContentVisible} onClose={() => {
                                this.IsClosingPopup = true;
                                this.setState({ContentVisible : false});
                                setTimeout(() => {
                                    this.IsClosingPopup = false;                                
                                }, 200); 

                            }}>
                            <SC.FCol style={{padding  :'8px'}}>
                                <SC.FCol jsb>
                                    <StyleGroupTitle>HORIZONTAL</StyleGroupTitle>
                                    <div style={{display : 'grid', gridTemplateColumns : 'repeat(7, 1fr)', gridTemplateRows : '32px', gridGap : '4px', marginTop : '8px'}}>
                                        <ContentAlignment                                                         
                                            value={justifyContent}
                                            onChange={this.ChangeJustifyContent}
                                            alignContent={alignContent}
                                        />
                                    </div>                    
                                </SC.FCol>
                                <SC.FCol jsb style={{marginTop : '8px'}}>
                                    <StyleGroupTitle>VERTICAL</StyleGroupTitle>
                                    <div style={{display : 'grid', gridTemplateColumns : 'repeat(7, 1fr)', gridTemplateRows : '32px', gridGap : '4px', marginTop : '8px'}}>
                                        <ContentAlignment                                                         
                                            value={alignContent}
                                            onChange={this.ChangeAlignContent}
                                            vertical
                                            justifyContent={justifyContent}
                                        />
                                    </div>                    
                                </SC.FCol>
                            </SC.FCol>
                        </StyleItemPopup>
                    }            
                </SC.FCol>                
            </SC.FCol>
        )        
    }
}

export const FlexBoxActive = styled.div`
    height : 36px;
    width : 36px;
    padding : 1px;
    box-sizing : border-box;
    border :${props => props.theme.border_seperator};
    border-radius : 2px;
    cursor : pointer;
    transition : all 0.3s ease;
    background-color : ${props => props.selected ? props.theme.back : 'unset'};
    &:hover {
        background-color : ${props => props.theme.buttongroup_back_hover};
    }
`;

const ContentAlignment = (props) => {
    const alignValues = [
        'start',        
        'center',
        'end',
        'stretch',
        'space-between',
        'space-around',        
        'space-evenly'
    ];    

    return alignValues.map((jv, i) => {
        return (
            <AlignmentOption
                value={jv}
                onClick={() => {
                    props.onChange(jv);
                }}
                selected={jv === props.value}
                alignContent={props.alignContent}
                justifyContent={props.justifyContent}
                vertical={props.vertical}
            />            
        )
    })
};


export const AlignmentOption = ({onClick, value, selected, vertical, all, justifyContent, alignContent, style, style_grid, title}) => {    
    return (
        <SmallButtonGroup
            first
            last
            noSvg
            hasBorders                
            onClick={onClick}
            title={title || MetaData.Properties.FlexLabels[value]}
            style={{width : '100%', height : '100%', padding : '4px', ...style}}
            selected={selected}
        >
            <div style={{
                display : 'grid',
                width : '100%',
                height : '100%',
                gridTemplateColumns : (!vertical && value === 'stretch' || justifyContent === 'stretch') ? '1fr 1fr' : '5px 5px',
                gridTemplateRows : (vertical && value === 'stretch' || alignContent === 'stretch') ? '1fr 1fr' : '5px 5px',
                gridGap : '1px',
                justifyContent : (vertical || all) ? justifyContent : value,
                alignContent : (vertical && !all) ? value : alignContent,
                ...style_grid
            }}>
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
                <div style={{backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060', opacity : selected ? 0.75 : 1}} />
            </div>
        </SmallButtonGroup>
    )
}