import React, { forwardRef } from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    MetaData,
    Globals,
    AppLayout
} from '../../../../../../../importer';
import { ImageBox } from '../../../../left/designsystem/images';
import { PropertyLabel, StyleGroupTokenItem, StyleGroup, SmallButtonGroup, StyleGroupTitle, ToggleButton, OptionalStyleGroupHeader } from '../common';
import VariableBinder, { RefreshMockupValueButton } from '../variable';
import InputSlider from '../../../../../../../components/editors/input_slider';
import { PanelHeaderAddButton } from '../../../../left/designsystem/common';

export default class ItemImage extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectModel = this.SelectModel.bind(this);
        this.SetModelBinding = this.SetModelBinding.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        
        this.isModel = this.props.modelId ? true : false;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'Id', 'ManagerId', 'GlobalStateId', 'GlobalState', 'imageToken', 'modelId')) {
            if (this.props.Id !== nextProps.Id)
                this.isModel = nextProps.modelId ? true : false;
            return true;   
        }
            
        return super.ShouldUpdate(nextProps, nextState);
    }
    RemoveTokenBinding() {
        this.props.SetTokenBinding('backgroundImage', this.props.Id, null, [], true);
        this.isModel = false;
        this.props.onBindToModel({
            PropertyName : 'backgroundImage',
            ModelId : null
        });        

    }
    SelectModel() {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'IMAGE',
            dataType : MetaData.DataTypes.image,
            onSelect : this.SetModelBinding,
            tokenId : this.props.imageToken.TokenId
        });
    }
    SetModelBinding(modelId) {
        if (modelId)
            this.isModel = true;
        if (this.props.imageToken.TokenId && modelId) {
            this.props.SetTokenBinding('backgroundImage', this.props.Id, null, [], true);
        }
        this.props.onBindToModel({
            PropertyName : 'backgroundImage',
            ModelId : modelId
        });        
    }
    
    ToggleImageSource(isModel) {
        if (this.isModel === isModel) {
            return;
        }

        if (!this.isModel) {
            this.SelectModel();
        }
        else {
            this.props.onSelectImage(null, (tokenId) => {
                if (!tokenId) {
                    
                }
                else {
                    if (this.props.modelId) {
                        this.props.onBindToModel({
                            PropertyName : 'backgroundImage',
                            ModelId : null
                        })
                    }                    
                    this.isModel = false;
                    this.props.SetTokenBinding('backgroundImage', this.props.Id, tokenId);
                }
            });
        }
        this.RCUpdate();
    }
    RefreshModelMockup(modelId) {
        const model = this.props.GetComponentManager().Models.GetModel(modelId);
        const value = Globals.ProjectManager.Mockups.GetRandomValue(model.DataId);
        this.props.GetComponentManager().Models.SetValue(modelId, value);
        this.RCUpdate();
    }
    ChangeAttachment(value, oldValue) {
        this.props.onSetPropertyValues([{
            PropertyName : 'attachment',
            Value : value === oldValue ? null : value,
            Delete : value === oldValue
        }]);
        this.RCUpdate();
    }
    renderCustom() {        

        let isEmpty = true, imageProperties;
        if (this.isModel) {
            isEmpty = this.props.modelId ? false : true;            
        }
        else {
            if (this.props.imageToken.TokenId) {          
                isEmpty = false;
            }                     
        }
        
        if (!isEmpty) {
            this.willAdd = false;
            const MetaItem = this.props.GetMetaItem();
            const imageProps = {};
            imageProps.positionX = this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'positionX', DefaultValue : 'center'});
            imageProps.positionY = this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'positionY', DefaultValue : 'center'});
            imageProps.sizeType = this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'backgroundSize', DefaultValue : 'cover'});
    
            imageProps.customXprops = {};
            imageProps.customYprops = {};
            if (imageProps.positionX === 'custom') {
                imageProps.customXprops = {
                    value : this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'customPositionX', DefaultValue : 0}),
                    modelId : null
                }
            }
            if (imageProps.positionY === 'custom') {
                imageProps.customYprops = {
                    value : this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'customPositionY', DefaultValue : 0}),
                    modelId : null
                }
            }
    
            if (imageProps.sizeType === 'custom') {
                imageProps.customSizePropsWidth = {
                    value : this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'backgroundWidth', DefaultValue : 'auto'}),
                    modelId : this.props.GetComponentManager().GetItemStyleModel({MetaItem : MetaItem, Id : this.props.Id, PropertyName : 'backgroundWidth'}).ModelId
                }
                imageProps.customSizePropsHeight = {
                    value : this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'backgroundHeight', DefaultValue : 'auto'}),
                    modelId : this.props.GetComponentManager().GetItemStyleModel({MetaItem : MetaItem, Id : this.props.Id, PropertyName : 'backgroundHeight'}).ModelId
                }
            }

            const attachment = this.props.GetComponentManager().GetItemPropertyValue({MetaItem : MetaItem, PropertyName : 'attachment'});

            imageProperties = (
                <React.Fragment>
                    <ImageSizeAndPositionEditor 
                        {...imageProps}
                        onSetPropertyValues={this.props.onSetPropertyValues}
                        onSetPropModel={this.props.onSetPropModel}
                        onUpdate={this.RCUpdate}
                    />
                    <SC.FRow jsb alc style={{marginTop : '8px', paddingLeft : '4px'}}>
                        <PropertyLabel>Attachment</PropertyLabel>
                        <SC.FRow>                                            
                            {
                                ['scroll', 'fixed', 'local'].map((value, i) => {
                                    const selected = value === attachment;
                                    return (
                                        <ToggleButton 
                                            key={value}
                                            style={{marginRight : i < 2 ? '4px' : 0}} 
                                            selected={selected}
                                            onClick={this.ChangeAttachment.bind(this, value, attachment)} 
                                        >
                                            {value}
                                        </ToggleButton>
                                    )
                                })
                            }
                        </SC.FRow>
                    </SC.FRow> 
                </React.Fragment>                
            )
        }
        

        return (
            <StyleGroup empty={isEmpty} key={isEmpty ? 'noImage' : 'image'}>
                <ImageSourceSelectoer 
                    title={this.props.title}
                    isModel={this.isModel}
                    isToken={!this.isModel}
                    modelId={this.props.modelId}
                    onSelectModel={this.SelectModel}
                    tokenId={this.props.imageToken.TokenId}
                    onSelectImage={this.props.onSelectImage}
                    onTokenSource={this.ToggleImageSource.bind(this, false)}
                    onVariableSource={this.ToggleImageSource.bind(this, true)}
                    optional={this.props.optional}
                    onAdd={this.props.optional && !this.willAdd ? () => {this.willAdd = true; this.RCUpdate();} : false}
                    willAdd={this.willAdd}
                    GetComponentManager={this.props.GetComponentManager}
                    onRefreshMockup={this.RefreshModelMockup.bind(this, this.props.modelId)}
                    multiple={this.props.multiple}
                    onRemove={this.RemoveTokenBinding}
                />                
               {imageProperties}
            </StyleGroup>
        )
    }
}

export const ImageSourceSelectoer = (props) => {
    let isEmpty = true, imageProperties, valueName, imageItem;
    if (props.isModel) {
        const model = props.GetComponentManager().Models.GetModel(props.modelId);
        let refreshMockup;
        if (model) {
            valueName = model.name;
            isEmpty = false;

            if (model.DataId) {
                refreshMockup = (
                    <RefreshMockupValueButton onRefresh={props.onRefreshMockup} />
                )
            }
        }            

        imageItem = (
            <SC.FRow alc jsb style={{marginTop : '8px', marginBottom : '4px'}}>
                <StyleGroupTokenItem 
                    style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px'}}
                    onClick={props.onSelectModel}
                >
                    <PropertyLabel 
                        hasValue={!isEmpty}
                        style={{flex : 1}}
                    >
                        {valueName || 'Select Image Variable'}
                    </PropertyLabel>                    
                </StyleGroupTokenItem>
                {refreshMockup}
            </SC.FRow>                
        )
    }
    else if (props.isToken) {
        if (props.tokenId) {
            const tokenModel = Globals.ProjectManager.Tokens.Token(props.tokenId);
            if (tokenModel)
                valueName = tokenModel.name;            
            isEmpty = false;
        }         
        if (!props.optional || !isEmpty || props.willAdd) {
            imageItem = (
                <StyleGroupTokenItem 
                    style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px', marginTop : '8px', marginBottom : isEmpty ? 0 : '4px'}}
                    onClick={props.onSelectImage}
                >
                    <PropertyLabel 
                        hasValue={!isEmpty}
                        style={{flex : 1}}
                    >
                        {valueName || 'Select Image Token'}
                    </PropertyLabel>                    
                </StyleGroupTokenItem>
            )
        }
        
    }

    if (imageItem) {
        imageItem = (
            <SC.FCol style={{marginTop : '8px'}}>
                <SC.FRow alc jsb>
                    <PropertyLabel>Image Source</PropertyLabel>
                    <SC.FRow justifyEnd>
                        <ToggleButton 
                            style={{marginRight : '4px'}} 
                            selected={props.isToken}
                            onClick={props.onTokenSource}
                        >
                            Token
                        </ToggleButton>
                        <ToggleButton selected={props.isModel} onClick={props.onVariableSource}>Variable</ToggleButton>
                    </SC.FRow>
                </SC.FRow>                
                {imageItem}
            </SC.FCol>
        )
    }

    let header;

    if ((isEmpty || props.multiple) && props.onAdd) {
        header = (
            <OptionalStyleGroupHeader title={props.title} onClick={props.onAdd} />
        )
    }
    else {
        header = (
            <SC.FRow alc jsb>
                <StyleGroupTitle>{props.title}</StyleGroupTitle>       
                {
                    props.optional && 
                    <SC.Icons.Icon_Button hasFill hasCursor  onClick={props.onRemove} style={{marginLeft : '8px'}}>
                        <SC.Icons.Icon_Delete size={16} />
                    </SC.Icons.Icon_Button>
                }                          
            </SC.FRow>
        )
    }
    return (
        <React.Fragment>
            {header}
            {imageItem}
        </React.Fragment>
    )
}


export const ImageTokenSelectItem = (props) => {
    let mockupRefresh;
    if (props.onRefreshMockup) {
        mockupRefresh = (
            <SC.Icons.Icon_Button 
                hasFill 
                style={{marginLeft : '8px', cursor : 'pointer'}} 
                title='Generate New Mockup Value'
                onClick={props.onRefreshMockup}
            >
                <SC.Icons.Icon_Refresh 
                    size={20}                            
                />
            </SC.Icons.Icon_Button>
        )
    }
    return (
        <SC.FRow style={props.style}>
            <StyleGroupTokenItem 
                style={{flex : 1, padding : '8px', paddingTop : '4px', paddingBottom : '4px'}}
                onClick={props.onSelectToken}
            >
                {
                    props.label ? 
                    <SC.FCol overflowHidden f1 style={{justifyContent : 'space-evenly'}}>
                        <SC.TextDivAbbr style={{flex : 1, fontWeight : 400}}>
                            {props.label || 'Icon'}
                        </SC.TextDivAbbr>
                        <SC.TextDivAbbr style={{marginTop : props.isEmpty ? 0 : '8px', fontSize : '11px'}}>
                            {props.name}
                        </SC.TextDivAbbr>
                    </SC.FCol> : 
                    <PropertyLabel 
                        hasValue={props.hasValue}
                        menuOptions={{
                            onShowValueMenu : props.onShowValueMenu,
                            propName : 'opacity'    
                        }}
                        style={{flex : 1}}
                    >
                            {props.name || 'Select Image'}
                    </PropertyLabel>
                }
                
            </StyleGroupTokenItem>   
            <VariableBinder 
                modelId={props.modelId}
                onSelect={props.onSelectModel}
            />
            {mockupRefresh}
        </SC.FRow>
    )
}

export class ImageSizeAndPositionEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);
    }
    ChangeSizeType(value) {        
        const ChangeProps = [
            {
                PropertyName : 'backgroundSize',
                Value : value
            }
        ];
        
        if (value !== 'custom') {
            ChangeProps.push({
                PropertyName : 'backgroundWidth',
                Delete : true
            });
            ChangeProps.push({
                PropertyName : 'backgroundHeight',
                Delete : true
            });
            ChangeProps.push({
                PropertyName : 'backgroundWidth',
                Delete : true,
                Model : true
            });
            ChangeProps.push({
                PropertyName : 'backgroundHeight',
                Delete : true,
                Model : true
            });
        }
        this.props.onSetPropertyValues(ChangeProps);
        this.props.onUpdate();
    }
    ChangeAlignment(prop, value, oldValue) {
        const changes = [
            {
                PropertyName : prop,
                Value : value
            }, 
            {
                PropertyName : prop === 'positionX' ?  'customPositionX' : 'customPositionY',
                Delete : true
            }
        ];
        if (value === 'custom') {
            let customValue = 50;
            if (oldValue === 'left' || oldValue === 'top')
                customValue = 0;
            else if (oldValue === 'right' || oldValue === 'bottom')
                customValue = 100;

            changes.push({
                PropertyName : prop === 'positionX' ?  'customPositionX' : 'customPositionY',
                Value : customValue
            })
        }
        this.props.onSetPropertyValues(changes);
        this.props.onUpdate();
    }
    SelectPositionModel(direction, modelId) {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'POSITION',
            dataType : MetaData.DataTypes.number,
            onSelect : (bindModelId) => {
                this.props.onSetPropModel({
                    PropertyName : direction,
                    ModelId : bindModelId
                });
            }
        });
    }
    onChangePositionValue(direction, value) {
        this.props.onSetPropertyValues([
            {
                PropertyName : direction,
                Value : value,
                DoNotLog : this.ChangeLogged
            }
        ]);
        this.ChangeLogged = false;
        this.props.onUpdate();
    }
    onChangingPositionValue(direction, value) {
        this.props.onSetPropertyValues([{
            PropertyName : direction,
            Value : value,
            DoNotLog : this.ChangeLogged,
            Offline : true
        }]);
        this.ChangeLogged = true;
        this.props.onUpdate();
    }    
    renderCustom() { 
        const {sizeType, customSizePropsWidth, customSizePropsHeight} = this.props;            

        let customSizeOptionsWidth = {}, customSizeOptionsHeight = {};
        if (sizeType === 'custom') {
            customSizeOptionsWidth = {
                onSelectModel : this.SelectPositionModel.bind(this, 'backgroundWidth'),
                onChangeValue : this.onChangePositionValue.bind(this, 'backgroundWidth'),
                onChangingValue : this.onChangingPositionValue.bind(this, 'backgroundWidth'),          
                onSettoAuto : this.onChangePositionValue.bind(this, 'backgroundWidth', 'auto'),
                max : 200
            }
            customSizeOptionsHeight = {
                onSelectModel : this.SelectPositionModel.bind(this, 'backgroundHeight'),
                onChangeValue : this.onChangePositionValue.bind(this, 'backgroundHeight'),
                onChangingValue : this.onChangingPositionValue.bind(this, 'backgroundHeight'),
                onSettoAuto : this.onChangePositionValue.bind(this, 'backgroundHeight', 'auto'),
                max : 200
            }
        }

        return ( 
            <SC.FCol style={this.props.style}>
                <PropertyLabel style={{paddingLeft : '4px'}}>Size</PropertyLabel>
                <div style={{display : 'grid', gridGap : '4px', gridTemplateColumns : 'repeat(4, 1fr)'}}>
                    {
                        ['auto', 'cover', 'contain', 'custom'].map((value, i) => {
                            const selected = sizeType === value;
                            return (
                                <SmallButtonGroup hasBorders first last noSvg style={{width : 'unset'}} key={i} onClick={this.ChangeSizeType.bind(this, value)} selected={selected}>
                                    <div style={{color : selected ? SC.CurrentTheme.theme.color_brand_text : 'unset', fontSize : '11px', ...SC.Styles.FontStyles.Monospace}}>{value}</div>
                                </SmallButtonGroup>
                            )
                        })
                    }
                </div>
                {
                    sizeType === 'custom' && 
                    <React.Fragment>
                        <ImageSlider {...customSizePropsWidth} {...customSizeOptionsWidth} label={(<div style={{width : '24px'}}>W</div>)}/>
                        <ImageSlider {...customSizePropsHeight} {...customSizeOptionsHeight} label={(<div style={{width : '24px'}}>H</div>)} />
                    </React.Fragment>                    
                }
                <ImagePositionEditor 
                    {...this.props}
                />                 
            </SC.FCol>
        );
    }
}

export class ImagePositionEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.ChangeAlignment = this.ChangeAlignment.bind(this);
    }    
    ChangeAlignment(prop, value, oldValue) {
        const changes = [
            {
                PropertyName : prop,
                Value : value
            }, 
            {
                PropertyName : prop === 'positionX' ?  'customPositionX' : 'customPositionY',
                Delete : true
            }
        ];
        if (value === 'custom') {
            let customValue = 50;
            if (oldValue === 'left' || oldValue === 'top')
                customValue = 0;
            else if (oldValue === 'right' || oldValue === 'bottom')
                customValue = 100;

            changes.push({
                PropertyName : prop === 'positionX' ?  'customPositionX' : 'customPositionY',
                Value : customValue
            })
        }
        this.props.onSetPropertyValues(changes);
        this.props.onUpdate();
    }
    SelectPositionModel(direction, modelId) {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'POSITION',
            dataType : MetaData.DataTypes.number,
            onSelect : (bindModelId) => {
                this.props.onSetPropModel({
                    PropertyName : direction,
                    ModelId : bindModelId
                });
            }
        });
    }
    onChangePositionValue(direction, value) {
        this.props.onSetPropertyValues([
            {
                PropertyName : direction,
                Value : value,
                DoNotLog : this.ChangeLogged
            }
        ]);
        this.ChangeLogged = false;
        this.props.onUpdate();
    }
    onChangingPositionValue(direction, value) {
        this.props.onSetPropertyValues([{
            PropertyName : direction,
            Value : value,
            DoNotLog : this.ChangeLogged,
            Offline : true
        }]);
        this.ChangeLogged = true;
        this.props.onUpdate();
    }    
    renderCustom() { 
        const {positionX, positionY, customXprops, customYprops, labelX, labelY} = this.props;

        let customXoptions = {};
        let customYoptions = {};
        if (positionX === 'custom') {
            customXoptions = {
                onSelectModel : this.props.noVariables ? null : this.SelectPositionModel.bind(this, 'customPositionX'),
                onChangeValue : this.onChangePositionValue.bind(this, 'customPositionX'),
                onChangingValue : this.onChangingPositionValue.bind(this, 'customPositionX'),
                onSettoAuto : this.onChangePositionValue.bind(this, 'customPositionX', 'center'),
            }
        }
        if (positionY === 'custom') {
            customYoptions = {
                onSelectModel : this.props.noVariables ? null : this.SelectPositionModel.bind(this, 'customPositionY'),
                onChangeValue : this.onChangePositionValue.bind(this, 'customPositionY'),
                onChangingValue : this.onChangingPositionValue.bind(this, 'customPositionY'),
                onSettoAuto : this.onChangePositionValue.bind(this, 'customPositionY', 'center'),
            }
        }
        
        return ( 
            <React.Fragment>              
                <SC.FRow jsb alc style={{paddingLeft : '4px', marginTop : '8px'}}>
                    <PropertyLabel>{labelX || 'Horizontal Pos.'}</PropertyLabel>
                    <SC.FRow>                                            
                        {
                            ['left', 'center', 'right'].map((horizontal, i) => {
                                const selected = horizontal === positionX;
                                return (
                                    <SmallButtonGroup 
                                        hasBorders  
                                        first last noSvg style={{width : 'unset'}} key={i} 
                                        onClick={this.ChangeAlignment.bind(this, 'positionX', horizontal, positionX)} 
                                        selected={selected}
                                        style={{...SC.Styles.Flex.Row, padding : '2px', marginRight : '2px', justifyContent : horizontal === 'left' ? 'flex-start' : (horizontal === 'right' ? 'flex-end' : 'center')}}
                                    >
                                        <div style={{
                                            width : '10px', 
                                            height : '10px', 
                                            borderRadius : '2px', 
                                            backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060',
                                            alignSelf : positionY === 'top' ? 'flex-start' : (positionY === 'bottom' ? 'flex-end' : 'center')
                                        }} />
                                    </SmallButtonGroup>
                                )
                            })
                        }
                        <SmallButtonGroup hasBorders first last noSvg style={{width : '62px', marginLeft : '2px'}} onClick={positionX === 'custom' ? null : this.ChangeAlignment.bind(this, 'positionX', 'custom', positionX)} selected={positionX === 'custom'}>
                            <div style={{color : positionX === 'custom' ? SC.CurrentTheme.theme.color_brand_text : 'unset', fontSize : '11px', ...SC.Styles.FontStyles.Monospace}}>custom</div>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>
                {
                    positionX === 'custom' && 
                    <ImageSlider {...customXprops} {...customXoptions} />
                }
                <ImageVerticalPosition 
                    labelY={labelY}
                    positionX={positionX}
                    positionY={positionY}
                    onChange={this.ChangeAlignment}
                    customYprops={customYprops}
                    customYoptions={customYoptions}
                    style={{marginTop : '8px', paddingLeft : '4px'}}
                />
                {/* <SC.FRow jsb alc style={{marginTop : '8px', paddingLeft : '4px'}}>
                    <PropertyLabel>{labelY || 'Vertical Pos.'}</PropertyLabel>
                    <SC.FRow>                                            
                        {
                            ['top', 'center', 'bottom'].map((vertical, i) => {
                                const selected = vertical === positionY;
                                return (
                                    <SmallButtonGroup 
                                        hasBorders  
                                        first last noSvg style={{width : 'unset'}} key={i+4} 
                                        onClick={this.ChangeAlignment.bind(this, 'positionY', vertical, positionY)} 
                                        selected={selected}
                                        style={{...SC.Styles.Flex.Column, marginRight : '2px', justifyContent : vertical === 'top' ? 'flex-start' : (vertical === 'bottom' ? 'flex-end' : 'center')}}
                                    >
                                        <div style={{
                                            width : '10px', 
                                            height : '10px', 
                                            borderRadius : '2px', 
                                            backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : '#606060',
                                            alignSelf : positionX === 'left' ? 'flex-start' : (positionX === 'right' ? 'flex-end' : 'center')
                                        }} />
                                    </SmallButtonGroup>
                                )
                            })
                        }
                        <SmallButtonGroup hasBorders first last noSvg style={{width : '62px', marginLeft : '2px'}} onClick={this.ChangeAlignment.bind(this, 'positionY', 'custom', positionY)} selected={positionY === 'custom'}>
                            <div style={{color : positionY === 'custom' ? SC.CurrentTheme.theme.color_brand_text : 'unset', fontSize : '11px', ...SC.Styles.FontStyles.Monospace}}>custom</div>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>      
                {
                    positionY === 'custom' && 
                    <ImageSlider {...customYprops} {...customYoptions} />
                }           */}
                
            </React.Fragment>
        );
    }
}

export class ImageVerticalPosition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    ChangeAlignment(prop, value, oldValue) {
        this.props.onChange(prop, value, oldValue);
    }
    render() { 
        const {labelY, positionX, positionY, customYprops, customYoptions} = this.props;
        return (
            <React.Fragment>
                <SC.FRow jsb alc style={this.props.style}>
                    <PropertyLabel>{labelY || 'Vertical Pos.'}</PropertyLabel>
                    <SC.FRow>                                            
                        {
                            ['top', 'center', 'bottom'].map((vertical, i) => {
                                const selected = vertical === positionY;
                                return (
                                    <SmallButtonGroup 
                                        hasBorders  
                                        first last noSvg style={{width : 'unset'}} key={i+4} 
                                        onClick={this.ChangeAlignment.bind(this, 'positionY', vertical, positionY)} 
                                        selected={selected}
                                        style={{...SC.Styles.Flex.Column, marginRight : '2px', justifyContent : vertical === 'top' ? 'flex-start' : (vertical === 'bottom' ? 'flex-end' : 'center')}}
                                    >
                                        <div style={{
                                            width : '10px', 
                                            height : '10px', 
                                            borderRadius : '2px', 
                                            backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : SC.CurrentTheme.theme.icon,
                                            alignSelf : positionX === 'left' ? 'flex-start' : (positionX === 'right' ? 'flex-end' : 'center')
                                        }} />
                                    </SmallButtonGroup>
                                )
                            })
                        }
                        <SmallButtonGroup hasBorders first last noSvg style={{width : '62px', marginLeft : '2px'}} onClick={this.ChangeAlignment.bind(this, 'positionY', 'custom', positionY)} selected={positionY === 'custom'}>
                            <div style={{color : positionY === 'custom' ? SC.CurrentTheme.theme.color_brand_text : 'unset', fontSize : '11px', ...SC.Styles.FontStyles.Monospace}}>custom</div>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>      
                {
                    positionY === 'custom' && 
                    <ImageSlider {...customYprops} {...customYoptions} plainSlider={this.props.plainSlider} />
                }  
            </React.Fragment>
        )
    }
}

const ImageSlider = ({value, modelId, onSelectModel,  onChangeValue, onChangingValue, label, onSettoAuto, max, plainSlider}) => {
    const content = (
        <SC.FRow f1 alc style={{marginLeft : '8px'}}>
            {label}
            <InputSlider 
                horizontal 
                small 
                reverse 
                postFix='%'
                value={value}
                min={0}
                max={max ||100}
                onChange={onChangeValue}
                onChanging={onChangingValue}
                inputStyle={{fontSize : '12px'}}
                themeId={AppLayout.ThemeId}
            />                    
        </SC.FRow> 
    )
    if (plainSlider)
        return content;
    return (
        <SC.FRow style={{marginTop : '4px'}}>
            <StyleGroupTokenItem style={{flex : 1, paddingRight : '8px', display : 'flex', alignItems : 'center', cursor : 'default'}}>
                {content}                                
            </StyleGroupTokenItem>          
            {
                onSelectModel && 
                <VariableBinder 
                    modelId={modelId}
                    onSelect={onSelectModel}
                />
            }            
        </SC.FRow>
    )
}

