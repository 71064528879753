import React from 'react';
import ReactBase from '../ReactBase';
import {Utils, MetaData} from '../importer';
import GetItemType from './index';

export default class SubComponent extends ReactBase
{
    constructor(props) {
        super(props);

        this.ComponentStateArray = ['Default'];

        this.IsStateActive = this.IsStateActive.bind(this);
        this.ToState = this.ToState.bind(this);
        this.UnsetState = this.UnsetState.bind(this);

        this.GetPseudoStates = this.GetPseudoStates.bind(this);
        
        this.GetCurrentModelValue = this.GetCurrentModelValue.bind(this);             
        this.SetCurrentModelValue = this.SetCurrentModelValue.bind(this);             
        this.RegisterConsumerToModel = this.RegisterConsumerToModel.bind(this);

        this.Load(this.props);
    }
    Load(props) {
        props.GetComponentMetaData(props.Id).then((Result) => {
            this.MetaData = Result;   
            if (this.MetaData) {
                this.StateVariations = Utils.JustGet(props, {}, 'Variations');
                this.LoadModels(props);
                this.LoadState(props);                                 
                super.RCSetStateValue('Loaded', true);
            }            
        });        
    }
    LoadState(props) {
        if (!this.MetaData)
            return;

        this.ComponentState = Utils.States.BuildStateLabelFromVariations({
            States : this.MetaData.States,
            StateOrders : this.MetaData.StateOrder,
            Variations : this.StateVariations            
        })
        this.ComponentStateArray = Utils.States.BuildStateArrayFromStateLabel({StateLabel : this.ComponentState});
        this.StyleState = Utils.States.BuildComponentStyleStateFromReversedArrays({
            GlobalState : props.GlobalVariations,
            ComponentState : this.ComponentState
        });
        this.ReverseStyleState = Utils.Reverse(Utils.DeepClone(this.StyleState));

        this.RootItemPseudoState = [];
        if (this.MetaData.States && this.MetaData.StateOrder) {
            Utils.ForEach(this.MetaData.StateOrder,( StateId, i) => {
                const StateModel = this.MetaData.States[StateId];
                if (StateModel) {
                    
                    if (StateModel.SingleVariation) {
                        if (StateModel.pseudo) {
                            this.RootItemPseudoState.push(StateId);
                        }
                    }
                    else if (StateModel.Variations) {
                        Utils.ForEach(StateModel.Variations, (Variation, StateId) => {
                            if (Variation && Variation.pseudo) {
                                this.RootItemPseudoState.push(StateId);
                            }
                        });
                    }  
                }
            });  
        }   

        this.StateId = Utils.Id();
    }
    GetPseudoStates() {
        return this.RootItemPseudoState;
    }
    LoadModels(props) {
        this.ModelValues = {};
        Utils.ForEach(props.Models, (ModelValue, ModelId) => {
            this.ModelValues[ModelId] = ModelValue;
        });
        this.MetaData && Utils.ForEach(this.MetaData.Models, (Model, ModelId) => {
            let value = Model.value;

            const ModelPropName = Utils.ToCamelCase(Model.Name);
            if (Utils.Has(props, ModelPropName)) {
                value = props[ModelPropName];
            }
            else {
                if (props.Models && Utils.IsNotNullOrEmpty(props.Models[ModelId])) {
                    value = props.Models[ModelId];
                }
                else {
                    if (Model.TokenId) {
                        value = props.Theme[Model.TokenId];
                        if (Model.dataType === 'image') {
                            value = {
                                url : value
                            }
                        }
                    }
                }      
            }
            
                              
            if (Model.stateVariations) {
                Utils.Set(this, true, 'ModelStateBindings', ModelId);

                if (value) {
                    this.ToState({Variations : Model.stateVariations});
                }
                else
                    this.UnsetState({Variations : Model.stateVariations});
            }
            this.ModelValues[ModelId] = value;
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.Id !== this.props.Id || nextProps.StateId !== this.props.StateId) {
            this.state.Loaded = false;
            this.Load(nextProps);
            return false;
        }
        let willUpdate = false;

        if (this.state.Loaded !== nextState.Loaded) {
            return true;
        }
        if (!Utils.IsEqual(this.props.Variations, nextProps.Variations)) {
            this.StateVariations = Utils.JustGet(nextProps, {}, 'Variations');
            this.LoadState(nextProps);  
            willUpdate = true; 
        }
        else if (this.props.GlobalVariations !== nextProps.GlobalVariations) {
            this.LoadState(nextProps);
            willUpdate = true;
        }

        if (nextProps.ThemeId !== this.props.ThemeId) {
            this.LoadState(nextProps);
            willUpdate = true;
        }

        if (!Utils.IsEqual(this.props.Models, nextProps.Models)) {
            this.LoadModels(nextProps);  
            willUpdate = true; 
        }
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate) {
            willUpdate = true;
        }

        return willUpdate;
    }
    GetCurrentModelValue(id, defaultvalue) {
        // const model = Utils.JustGet(this.MetaData.Models, null, id);
        let value = Utils.Get(this.ModelValues, defaultvalue, id);
        
        // if (model) {
        //     if (model.dataType === 'size') {
        //         const numberValue = Utils.ToNumber(value);
        //         if (Utils.IsNumber(numberValue))
        //             value = Utils.px(numberValue);
        //     }
        // }
        return value;
    }
    SetCurrentModelValue(id, value) {
        Utils.Set(this, value, 'ModelValues', id);
        if (this.MetaData && this.ModelStateBindings && this.ModelStateBindings[id]) {
            const model = this.MetaData.Models[id];
            if (model) {
                if (value) {                
                    this.ToState({Variations : model.stateVariations, MergeWithCurrentState : true})
                }
                else {
                    this.UnsetState({Variations : model.stateVariations, MergeWithCurrentState : true});
                }   
            }            
        }
        if (this.props.onModelChanged) {
            this.props.onModelChanged(id, value);
            this.RCUpdate();
        }
        else    
            this.RCUpdate();
    }
    IsStateActive(StateId, VariationId) {
        return Utils.JustGet(this.StateVariations, null, StateId) === VariationId;
    }
    UnsetState({Variations, MergeWithCurrentState, GlobalState, Refersh}) {        
        if (Variations) {                            
            const NewStateVariations = Utils.DeepClone(this.StateVariations) || {};
            Utils.ForEach(Variations,( VarId, StateId) => {                            
                if (VarId)
                    delete NewStateVariations[StateId];
            });
            if (!Utils.IsEqual(this.StateVariations, NewStateVariations)) {
                this.StateVariations = NewStateVariations;
                this.LoadState(this.props);
                if (Refersh)
                    this.RCUpdate();
            }                           
        } 
    }
    ToState({Variations, MergeWithCurrentState, GlobalState, Refersh}) {
        let NewStateVariations = {};
        if (MergeWithCurrentState)
            NewStateVariations = Utils.DeepClone(this.StateVariations) || {};
        
        Utils.ForEach(Variations,( VarId, StateId) => {
            if (VarId)
                NewStateVariations[StateId] = VarId;    
            else
                delete NewStateVariations[StateId];
        });
        if (!Utils.IsEqual(this.StateVariations, NewStateVariations)) {
            this.StateVariations = NewStateVariations;
            this.LoadState(this.props);
            if (Refersh)
                this.RCUpdate();
        }        
    }    
    RegisterConsumerToModel(ModelId, Consumer) {
        const consumers = Utils.Get(this, [], 'ModelConsumers', ModelId);
        const exists = Utils.FindIndex(consumers, (item) => {return item.id === Consumer.id});
        if (exists < 0)
            consumers.push(Consumer);
    }
    renderCustom() {
        if (!this.state.Loaded) {
            return null;
        }

        if (!this.MetaData)
            return null;
            
        const MetaRootItem = this.MetaData.RootItem;
        if (!MetaRootItem)
            return null;
            
        const RootItemType = GetItemType(MetaRootItem);
        let OuterStyle = Utils.Merge(this.props.style, {
            
        });
        
        const isFixedWidth = Utils.JustGet(MetaRootItem, false, 'Props', 'Static', 'Default', 'Default', 'fixedWidth');
        const isFixedHeight = Utils.JustGet(MetaRootItem, false, 'Props', 'Static', 'Default', 'Default', 'fixedHeight');
        
        if (isFixedWidth) // && !this.props.AutoScale)
            delete OuterStyle.width;
        else if (!OuterStyle.width && !this.props.AutoScale && !this.props.Design)
            OuterStyle.width = 'auto';
            
        // console.log(`Preview : ${this.props.AutoScale ? 'true' : 'false'}, Width : ${OuterStyle.width}`);

        if (isFixedHeight) // && !this.props.AutoScale)
            delete OuterStyle.height; 
        else if (!OuterStyle.height && !this.props.AutoScale && !this.props.Design)
            OuterStyle.height = 'auto';       
        
        if (RootItemType) {
            return (
                <RootItemType
                    key={MetaRootItem.Id}
                    RootItem={{
                        fixedWidth : isFixedWidth,
                        fixedHeight : isFixedHeight,
                        onGetPseudoStates : this.GetPseudoStates,
                        transitions : this.props.transitions
                    }}                    
                    MetaItem={MetaRootItem}
                    OuterStyle={OuterStyle}
                    StateId={this.StateId}
                    ThemeId={this.props.ThemeId}
                    Theme={this.props.Theme}
                    Models={Utils.DeepClone(this.ModelValues)}
                    StyleState={this.StyleState}                    
                    ReverseStyleState={this.ReverseStyleState}
                    GlobalVariations={this.props.GlobalVariations}
                    GetComponentMetaData={this.props.GetComponentMetaData}

                    onGetModelValue={this.GetCurrentModelValue}
                    onSetModelValue={this.SetCurrentModelValue}
                    IsStateActive={this.IsStateActive}
                    onRegisterToModel={this.RegisterConsumerToModel}
                    onSetState={this.ToState}
                    onUnsetState={this.UnsetState}
                    renderSlot={this.props.renderSlot}
                    renderCollectionItem={this.props.renderCollectionItem}
                    slides={this.props.slides}
                />              
            )
        }
        return (
            <div style={{
                backgroundColor : 'blue',
                padding  :'16px'
            }}>
                Sub Component {this.props.Id}
            </div>
        )
    }
}