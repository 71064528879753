import React from 'react';
import ReactDOM from 'react-dom';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils} from '../../../importer';
import { GetSubItems } from '../../Container';
import Radium from 'radium';


class Generic_Dropdown extends BaseItem
{
    constructor(props) {
        super(props);

        this.Toggle = this.Toggle.bind(this);
    }    
    componentDidMount() {
        super.componentDidMount();

        (this.props.document || document).addEventListener('click', this.handleOutsideClick.bind(this));
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        (this.props.document || document).removeEventListener('click', this.handleOutsideClick.bind(this));
    }
    handleOutsideClick(e) {
        if (this.state.Open && e.target.id !== this.props.MetaItem.Id) {
            this.Toggle();
        }        
    }    
    Toggle() {
        const isOpen = !this.state.Open;
        this.RCSetStateValue('Open', isOpen);
        
        this.props.onSetState({
            MergeWithCurrentState : true,
            Variations : {
                Open : isOpen ? 'Open' : null
            }
        })
    }
    renderItem({style, props, events, rest}) {                
        let content;
        
        if (!content) {
            content = props.text;        
            if (props.truncate) {
                style.textOverflow = 'ellipsis';
                style.overflow = 'hidden';
                style.whiteSpace = 'nowrap'; 

                delete style.display;
                delete style.flexDirection;
                delete style.alignItems;
                delete style.justifyContent;
            }
        }            

        let contentitem;
        if (!style.width)
            style.width = '100%';

        if (this.state.Open) {

            let style_content;
            const {MetaItem} = this.props;
            const ContentId = Utils.JustGet(MetaItem.Generic, null, 'ContentId');
            let ContentItems;
            if (ContentId) {
                const MetaItemContent = Utils.Find(MetaItem.SubItems, (Item) => {return Item.Id === ContentId});
                if (MetaItemContent) {

                    ContentItems = GetSubItems([MetaItemContent], this.props, {
                        getDataRowValue : this.props.getDataRowValue,
                        index : this.props.index,
                        document : this.props.document,
                        OuterStyle : {
                            position : 'absolute',
                            top : '100%',
                            left : 0,        
                            minWidth : '100%'
                        }
                    }, this.RootStateId);
                    BuildItemStyles
                    // const ContentStlye = BuildItemStyles(MetaItemContent, this.props);
                    // if (ContentStlye) {
                    //     style_content = ContentStlye.style;
                    // }
                }
            }

            contentitem = ContentItems;
        }

        const {margin, marginTop, marginBottom, marginLeft, marginRight, ...style_button} = style;

        return (
            <div style={{
                position : 'relative',
                margin : margin,
                marginBottom : marginBottom,
                marginTop : marginTop,
                marginLeft : marginLeft,
                marginRight : marginRight
            }} >
                <button
                    id={this.props.MetaItem.Id} 
                    ref={(r) => this.Ref_Instance = r}
                    style={{
                        fontFamily : 'unset',
                        fontSize : 'unset',
                        ...style_button
                    }}
                    {...events}
                    {...rest}
                    disabled={this.props.IsStateActive('disabled', 'disabled')}
                    onClick={this.Toggle}
                >
                    {content}
                </button>
                {contentitem}
            </div>
        )
    }
}

export default Radium(Generic_Dropdown);