import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    UIUtils
} from '../../../importer';
import { DefaultMediaSizes } from '.';

export default  class SystemStateManager {
    constructor(Manager) {
        this.State = 'Default';
        this.CurrentStateVariation = 'Default';
        this.Manager = Manager;
    }

    Get() {
        const GlobalStates = this.Manager.DataManager.Get({}, Strings.STATES);
        let System_MediaSize = Utils.JustGet(GlobalStates, null, 'System_MediaSize');
        if (!System_MediaSize) {
            const Orders = Utils.Get(GlobalStates, [], 'Order');
            if (Orders.indexOf('System_MediaSize') !== 0)
                Orders.splice(0, 0, 'System_MediaSize');
            Utils.Set(GlobalStates, {
                SystemState : true,
                CustomSelector : true,
                name : 'Media Size',
                Variations : {
                    xSmall : {
                        name : 'x-Small'
                    },
                    small : {
                        name : 'Small'
                    },
                    medium : {
                        name : 'Medium'
                    },
                    large : {
                        name : 'Large'
                    },
                    default : {
                        name : 'x-Large'
                    }
                }
            }, 'System_MediaSize');
            this.Manager.DataManager.Set(GlobalStates, Strings.STATES);
        }
        else 
            System_MediaSize.CustomSelector = true;

        return GlobalStates;
    }
    GetOrder() {
        return Utils.Get(this.Get(), [], Strings.ORDER);
    }
    States() {
        return this.Get();
    }
    Delete(id) {
        const States = this.Get();
        Utils.RemoveEquals(States.Order, id);
        this.Manager.DataManager.Set(States.Order, Strings.STATES, Strings.ORDER);
        if (States[id]) {
            const State = States[id];
            this.Manager.DataManager.Delete(Strings.STATES, id);
            // Delete Style State Values

            const tokens = this.Manager.Tokens.List();
            Utils.ForEach(tokens, (token, tokenId) => {
                Utils.ForEach(token.value, (statevalue, tokenstate) => {
                    if (tokenstate.indexOf(id) > -1) {
                        this.Manager.DataManager.Delete('tokens', 'list', tokenId, 'value', tokenstate);
                    }                
                });
            });

            const components = Utils.JustGet(this.Manager.Data.Data, {}, 'Components', 'List')
            Utils.ForEach(components, (component, componentId) => {
                component && Utils.ForEach(component.MetaItems, (MetaItem, MetaItemId) => {
                    MetaItem && Utils.ForEach(MetaItem.Tokens, (ComponentStates, GlobalState) => {
                        Utils.ForEach(ComponentStates, (StateProps, ComponentState) => {
                            if (GlobalState.indexOf(id) > -1) {
                                Utils.ForEach(StateProps, (PropValue, PropName) => {
                                    if (PropValue && PropValue.RelationId) {
                                        this.Manager.RelationManager.DeleteRelationId(PropValue.RelationId, true);
                                    }
                                });
                                this.Manager.DataManager.Delete('Components', 'List', componentId, 'MetaItems', MetaItemId, 'Tokens', GlobalState);
                            }                
                        });
                    });

                    MetaItem && Utils.ForEach(MetaItem.Property, (ComponentStates, GlobalState) => {
                        if (GlobalState.indexOf(id) > -1) {                           
                            this.Manager.DataManager.Delete('Components', 'List', componentId, 'MetaItems', MetaItemId, 'Property', GlobalState);
                        }
                    });
                });
            });

            if (this.State == State.name)
                this.SetState(Strings.DEFAULT);
        }
    }
    AddState(name) {
        const States = this.Get();
        // todo : check unique name
        const Id = Utils.Id();
        const state = {
            name: name,
            SingleVariation: true
        };
        this.Manager.DataManager.Set(state, Strings.STATES, Id);
        let Order = Utils.Get(States, [], Strings.ORDER);
        Order.push(Id);
        this.Manager.DataManager.Set(Order, Strings.STATES, Strings.ORDER);
        return Id;
    }
    AddStateVariation(stateid, name) {
        const stateModel = this.GetState(stateid);
        const Variations = Utils.Get(stateModel, null, 'Variations') || {};
        const Ordered = Utils.Get(Variations, [], 'Order');
        const Id = Utils.Id();
        Ordered.push(Id);
        Variations.Order = Ordered;
        Variations[Id] = {name : name};
        stateModel.Variations = Variations;
        stateModel.SingleVariation = false;
        this.UpdateState(stateid, stateModel);
        return Id;
    }
    DeleteStateVariation(stateid, variationId) {
        const stateModel = this.GetState(stateid);
        Utils.RemoveEquals(stateModel.Variations.Order, variationId);        
        delete stateModel.Variations[variationId];
        if (stateModel.Variations.Order.length === 0)
            stateModel.SingleVariation = true;
        this.UpdateState(stateid, stateModel);
    }
    GetState(id) {
        return this.Manager.DataManager.Get({}, Strings.STATES, id);
    }
    UpdateState(id, state) {
        this.Manager.DataManager.Set(state, Strings.STATES, id);
    }
    UpdateStateProp(Id, Prop, Value) {
        this.Manager.DataManager.Set(Value, Strings.STATES, Id, Prop);
    }
    ChangeOrder(oldIndex, newIndex) {        
        const list = this.Get();
        Utils.ChangePlace(list.Order, oldIndex + 1, newIndex + 1);
        this.Manager.DataManager.Set(list.Order, Strings.STATES, Strings.ORDER);
    }
    ChangeVariationOrder(stateid, oldIndex, newIndex) {        
        const stateModel = this.GetState(stateid);
        const Variations = Utils.Get(stateModel, null, 'Variations') || {};
        const Ordered = Utils.Get(Variations, [], 'Order');
        Utils.ChangePlace(Ordered, oldIndex, newIndex);
        this.UpdateState(stateid, stateModel);
    }
    SetSelectedVariation(StateId, VariationId) {
        if (VariationId)
            Utils.Set(this, VariationId, Strings.STATEVARIATION, StateId);
        else
            Utils.UnSet(this, Strings.STATEVARIATION, StateId);

        this.BuildStateArray();

        Events.BCE(Events.GLOBAL.STATE_CHANGED);        
    }
    SetState(State) {
        this.State = State;        
    }
    SetToDefault(silent) {
        Utils.UnSet(this, Strings.STATEVARIATION);
        this.CurrentStateVariation = this.CurrentState();
        this.BuildStateArray();
        Events.BCE(Events.GLOBAL.STATE_CHANGED, this.StateVariation);             
    }
    ToState(Variations) {
        this[Strings.STATEVARIATION] = {};
        if (Variations) {            
            Utils.ForEach(Variations, (VariationId, StateId) => {                
                const state = this.Manager.DataManager.Get(null, Strings.STATES, StateId)
                if (state && VariationId)
                    Utils.Set(this, VariationId, Strings.STATEVARIATION, StateId);
                else
                    Utils.UnSet(this, Strings.STATEVARIATION, StateId);
            });            
        }
        this.BuildStateArray();
        Events.BCE(Events.GLOBAL.STATE_CHANGED, this.GlobalStateArray);
    }
    CurrentState(MergeWidth) {
        // let State = 'Default';
        // const StateVariation = Utils.Merge(this.StateVariation, MergeWidth);
        // let notEmpty = true, notEmptyCustom = false;
        // if (StateVariation.System_MediaSize) {
        //     State = '';
        //     notEmpty = false;                        
            
        //     this.Manager.DeviceType = StateVariation.System_MediaSize === 'Default' ? 'Default' : StateVariation.System_MediaSize;
        // }
        // else 
        //     this.Manager.DeviceType = 'Default';

        // this.NonMediaState = '';

        // if (StateVariation) {
        //     const States = this.Get();
        //     const StateOrder = Utils.Get(States, [], 'Order');
            
        //     Utils.ForEach(StateOrder, (StateId) => {
        //         if (StateVariation[StateId]) {
        //             if (States[StateId].ResetOthers)
        //                 State = '';
        //             State += `${notEmpty ? ',' : ''}${StateVariation[StateId]}`;
                    
        //             if (StateId !== 'System_MediaSize') {
        //                 this.NonMediaState = `${notEmptyCustom ? ',' : ''}${StateVariation[StateId]}`;
        //                 notEmptyCustom = true;
        //             }

        //             notEmpty = true;
        //         }
        //     })
        // }       

        // if (!Utils.IsNotNullOrEmpty(State))
        //     State = 'Default';
        
        // if (AppState.MainComponentManager) {
        //     if (AppState.MainComponentManager.PrototypeViewId) {
        //         AppState.MainComponentManager.SetState(
        //             State,
        //             AppState.MainComponentManager.ComponentState
        //         );
        //     }
        // }
        // return State;
        
        const StateVariation = Utils.Merge(this.StateVariation, MergeWidth);
        const result = Utils.States.BuildSystemStateLabelFromVariations({
            States : this.Get(),
            StateOrder :  Utils.Get(this.Get(), [], 'Order'),
            StateVariation : StateVariation
        })
        
        if (StateVariation.System_MediaSize) {            
            this.Manager.DeviceType = StateVariation.System_MediaSize === 'Default' ? 'Default' : StateVariation.System_MediaSize;
        }
        else 
            this.Manager.DeviceType = 'Default';

        this.NonMediaState = result.NonMediaState;
        
        if (AppState.MainComponentManager) {
            if (AppState.MainComponentManager.PrototypeViewId) {
                AppState.MainComponentManager.SetState(
                    result.State,
                    AppState.MainComponentManager.ComponentState
                );
            }
        }
        return result.State;
    }
    BuildStateArray() {
        const currentState = this.CurrentState();
        this.BuildStateArrayFrom(currentState);                        
        this.CurrentStateVariation = this.CurrentState();
        this.Manager.UpdateTokenValues({});
    }
    BuildStateArrayFrom(currentState) {
        this.Manager.CurrentState = currentState;
        
        this.Manager.StyleState = [];

        const Result = Utils.States.GenerateStateArray({GlobalStateLabel : currentState});
        this.Manager.StyleState = Result.GlobalStateArray;

        this.Manager.ReversedStyleState = Utils.Reverse(Utils.DeepClone(this.Manager.StyleState));      
    }
    GetSelectedVariation() {
        return Utils.Get(this, Strings.DEFAULT, Strings.STATEVARIATION);
    }

    // Responsive Media States
    GetResponsiveBreakpoints() {
        let breakpoints = this.Manager.DataManager.Get(null, 'Breakpoints');
        if (!breakpoints) {
            breakpoints = [
                {
                    id : 'xSmall',
                    name : 'xSmall',
                    width : 425,
                },
                {
                    id : 'Small',
                    name : 'Small',
                    width : 768,
                },
                {
                    id : 'Medium',
                    name : 'Medium',
                    width : 1024,
                },
                {
                    id : 'Large',
                    name : 'Large',
                    width : 1440,
                },
            ];

            this.Manager.DataManager.Set(breakpoints, 'Breakpoints');
        }

        return breakpoints;
    }
    SetResponsiveBreakpoints(breakpoints) {
        this.Manager.DataManager.Set(breakpoints, 'Breakpoints');
    }
    GetBreakpointDevice(width) {
        const breakpoints = this.GetResponsiveBreakpoints();
        let breakpointId = 'Default';
        Utils.ForEach(breakpoints, (breakpoint, ) => {
            if (!breakpoint.disabled && width < breakpoint.width) {
                breakpointId = breakpoint.id;
                return false;
            }                
        });
        return breakpointId;
    }
}
