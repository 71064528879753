import React from 'react';
import ReactDOM from 'react-dom';

import {
    AppState,
    Utils,
    Strings,
    Globals,
    UIUtils,
    AppLayout,
    MetaData
} from '../../../../../../importer';
import DragDropManager from '../../../../../../components/dragdrop/DragDropManager';
import {GetItemType} from '../index';
import { COLOR_HANDLE } from '../../../../panzoom/selectedItemDesigner/spacer';
import { GridChildStyleNames } from '../../../../panels/right/iteminspector/styleitems/layout';
import { LOG_TYPES } from '../../../../../../appstate/historymanager/base';

export const onConstructItem = function(that, props) {

    that.onMouseDown = onMouseDownOnItem.bind(that);
    that.onItemClick = onItemClick.bind(that);
    that.GetItemSize = onGetItemSize.bind(that);
    that.onItemResized = onItemResized.bind(that);
    that.RefreshItem = onRefreshItem.bind(that);

    that.InstanceId = Utils.Id();

    that.isSelected = Utils.Includes(props.SelectedItems, props.Id);

    that.onMouseOver = () => {
        if (that.isSelected || DragDropManager.DragData || that.props.Preview || that.state.Editing || AppState.DisableItemSelection || AppState.Designer.EditingText) {
            if (DragDropManager.DragData && DragDropManager.DragData.TokenValue) {
                // console.log(`On Token  : ${that.props.Id}`);
                return;
            }
            else
                return;
        }            
        
        that.willBeMouseOver = true;
        setTimeout(() => {
            if (that.willBeMouseOver) {
                that.willBeMouseOver = false;
                that.ToggleHover(true);
                that.props.onMouseOverChild && that.props.onMouseOverChild(true);
            }
        }, 100);
        
    }
    that.onMouseOut = () => {
        if (DragDropManager.DragData || that.props.Preview)
            return;
        that.willBeMouseOver = false;
        if (that.isMouseOver) {
            that.ToggleHover(false);            
        }        
        that.props.onMouseOverChild && that.props.onMouseOverChild(false);
    }
    that.onMouseOverChild = (isOver) => {
        that.isMouseOverChild = isOver;
        that.ToggleHover(false);        
    }
    that.ToggleHover = (visible) => {
        if (that.unmounted)
            return;
        that.isMouseOver = visible;
        if (AppLayout.Refs.SelectionDesigner) {
            if (visible && !that.isMouseOverChild) {
                AppLayout.Refs.SelectionDesigner.RegisterItem(that.props.Id, that);
            }
            else if (!that.isSelected) {
                AppLayout.Refs.SelectionDesigner.UnRegisterItem(that.props.Id, that);
            }
        }                     
    }  
}

export const onGetItemSize = function(scaled) {
    if (this.props.IsRootItem && !this.props.IsRootItem.SubComponent)
        return this.GetMaxSize();
    const bounds = UIUtils.Utils.GetBounds(this);
    return {
        width : bounds.width / (scaled ? AppLayout.Designer.Zoom : 1),
        height : bounds.height / (scaled ? AppLayout.Designer.Zoom : 1),
        top : bounds.top,
        left : bounds.left,
        actualwidth : bounds.width,
        actualheight : bounds.height,
        isAutoWidth : Utils.JustGet(this.renderData, '', 'style', 'width') === 'auto',
        isAutoHeight : Utils.JustGet(this.renderData, '', 'style', 'height') === 'auto',
    }
}

export const onRefreshItem = function() {
    this.UpdateStyle(this.props);
    this.setState({ShouldUpdate : true});
}

export const onMouseDownOnItem = function(e) {
    if (e.button === 1 || AppState.Designer.DisableDragDrop)
        return;    

    if (this.props.isSlotContainer)
        return;

    if (this.props.isSubComponentChild) {
        if (!this.props.GetComponentManager().Editing) {
            e.stopPropagation();
            this.props.isSubComponentChild.onMouseDownOnItem(e);
            return;
        }
    }
    this.mouseDownOnElement = {
        sx : e.clientX,
        sy : e.clientY
    };        

    if (this.props.IsRootItem && !this.props.IsRootItem.SubComponent)
        return;
    if (this.props.Preview)
        return;

    if (AppState.Designer.EditingText) {
        e.stopPropagation();
        if (this.state.Editing)
            return;
    }
        

    e.stopPropagation();        
    
    this.onItemDragging = onItemDragging.bind(this);
    document.addEventListener('mousemove', this.onItemDragging); 
}

export const onItemClick = function (e) {
    if (this.props.isSubComponentChild) {
        if (!this.props.GetComponentManager().Editing) {
            e.stopPropagation();
            this.props.isSubComponentChild.onItemClick(e);
            return;
        }
    }

    if (this.mouseDownOnElement) {
        delete this.mouseDownOnElement;
        document.removeEventListener('mousemove', this.onItemDragging);
        delete this.onItemDragging;
    }
    else
        return;
    if (e.button === 1)
        return;
    if (AppState.DisableItemSelection)
        return;
    // if (this.props.Preview)
    //     return;
    if (DragDropManager.DragData)
        return;
    if (this.state.Editing) {
        e.stopPropagation();
        return;
    }
        
    e.stopPropagation();

    this.props.onSelect(this.props.Id, true, e.shiftKey);
    return false;
}


export const onItemDragging = function(e) {
    if (this.mouseDownOnElement && this.props.onDragDrop) {
        // this.props.onMouseOverChild && this.props.onMouseOverChild(true);
        const {sx, sy} = this.mouseDownOnElement;
        if (!this.mouseDownOnElement.dragStarted) {
            const maxDiff = Math.max(Math.abs(e.clientX - sx), Math.abs(e.clientY - sy));
            if (maxDiff > 7) {
                this.mouseDownOnElement.dragStarted = true;                                

                if (this.renderData.style.position === 'absolute' || this.renderData.style.position === 'fixed') {                    
                    const DN = ReactDOM.findDOMNode(this);
                    const bounds_parent = this.props.GetParentSize();
                    const bounds = DN.getBoundingClientRect();
                    const info = {
                        left : (bounds.left - bounds_parent.left) / AppLayout.Designer.Zoom,
                        top : (bounds.top - bounds_parent.top) / AppLayout.Designer.Zoom,
                        sx : sx,
                        sy : sy,
                        DN : DN,
                        width : bounds.width / AppLayout.Designer.Zoom,
                        height : bounds.height / AppLayout.Designer.Zoom
                    };
                    DN.style.transition = 'none';
                    AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.Hide();
                    this.onDragAbsoluteItem = onDragAbsoluteItem.bind(this, info);
                    this.onDragAbsoluteEnded = onDragAbsoluteEnded.bind(this, info);
                    this.infoAbsolutePosition = {
                        type : 'AbsoluteGuide',
                        start : true,
                        top : info.top,
                        left : info.left,
                        width : bounds.width / AppLayout.Designer.Zoom,
                        height : bounds.height / AppLayout.Designer.Zoom,
                        parentHeight : bounds_parent.actualheight / AppLayout.Designer.Zoom,
                        parentWidth : bounds_parent.actualwidth / AppLayout.Designer.Zoom,
                    }
                    document.addEventListener('mouseup', this.onDragAbsoluteEnded);
                    document.addEventListener('mousemove', this.onDragAbsoluteItem);
                    AppState.DisableItemSelection = true;

                    this.props.onChildAction && this.props.onChildAction(this.infoAbsolutePosition);
                }
                else {
                    let GridChildProps;
                    if (this.props.IsGridChild) {
                        GridChildProps = {
                            
                        };
                        GridChildStyleNames.map((prop) => {
                            GridChildProps[prop] = this.renderData.style[prop]
                        })
                    }
                    DragDropManager.StartDragItem({
                        Item : {
                            Type : Strings.CHILDITEM,
                            Item : {
                                MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id)
                            },
                            Id : this.props.Id,
                            ParentId : this.props.ParentId,
                            OwnerId : this.props.OwnerId,
                            SourceRef : this,
                            GridChildProps : GridChildProps
                        },
                        x : e.clientX,
                        y : e.clientY,
                        Copy : e.altKey,                    
                        GetComponentManager : this.props.GetComponentManager
                    });           
                    this.isMouseOver = false;
                    if (!this.isSelected)
                        AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.UnRegisterItem(this.props.Id, this);     
                    this.onItemDragEnded = onItemDragEnded.bind(this);
                    document.addEventListener('mouseup', this.onItemDragEnded);
                    this.isDragging = true;
                    this.setState({ShouldUpdate : true});
                    this.props.onDragDrop({start : true, SourceItemId : this.props.Id, Copy : e.altKey});
                }                
            }            
        }
        else {
            DragDropManager.MoveDragItem(e.clientX, e.clientY); 
        }
    }
}

export const onItemDragEnded = function(e) {
    document.removeEventListener('mouseup', this.onItemDragEnded);
    delete this.onItemDragEnded;    
    
    e && e.stopPropagation();

    delete this.mouseDownOnElement;
    document.removeEventListener('mousemove', this.onItemDragging);
    delete this.onItemDragging;

    if (DragDropManager.DragData && DragDropManager.DragData.OnArtBoard && !DragDropManager.DragData.Copy) {                        
        this.isDragging = false;
        !this.unmounted && this.setState({ShouldUpdate : true});
        this.props.onDragDrop({delete : {
            Id : this.props.Id
        }});
    }
    else {
        this.isDragging = false;        
        !this.unmounted && this.setState({ShouldUpdate : true});
        this.props.onDragDrop({delete : {
            cancel : true
        }});        
    }
}

const onDragAbsoluteEnded = function(info, e) {
    document.removeEventListener('mouseup', this.onDragAbsoluteEnded);
    document.removeEventListener('mousemove', this.onDragAbsoluteItem);
    delete this.onDragAbsoluteEnded;    
    delete this.onDragAbsoluteItem;
    this.infoAbsolutePosition.start = false;
    this.props.onChildAction && this.props.onChildAction(this.infoAbsolutePosition);
    delete this.infoAbsolutePosition;

    e && e.stopPropagation();

    info.dx = (e.clientX - info.sx) / AppLayout.Designer.Zoom;
    info.dy = (e.clientY - info.sy) / AppLayout.Designer.Zoom;
    info.top = info.top + info.dy;
    info.left = info.left + info.dx;

    this.props.GetComponentManager().GetDesignManager().Log({
        Target : LOG_TYPES.SET_POSITION,
        Id : this.props.Id,
        Desc : `Change Position`
    });

    ['top', 'left'].map((prop, i) => {
        this.props.GetComponentManager().GetDesignManager().SetItemPropertyValue({
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            Id : this.props.Id,
            PropertyName : prop,
            Value : info[prop],
            DoNotLog : true
        });
    })
    AppState.DisableItemSelection = false;
    this.isDragging = false;        
    !this.unmounted && this.setState({ShouldUpdate : true});

    AppLayout.Refs.Inspectors.PositionRefs.top && AppLayout.Refs.Inspectors.PositionRefs.top.current && AppLayout.Refs.Inspectors.PositionRefs.top.current.SetChangingValue({ended : true});
    AppLayout.Refs.Inspectors.PositionRefs.left && AppLayout.Refs.Inspectors.PositionRefs.left.current && AppLayout.Refs.Inspectors.PositionRefs.left.current.SetChangingValue({ended : true});
}

const onDragAbsoluteItem = function(info, e) {
    info.dx = (e.clientX - info.sx) / AppLayout.Designer.Zoom;
    info.dy = (e.clientY - info.sy) / AppLayout.Designer.Zoom;
    const top = info.top + info.dy;
    const left = info.left + info.dx;
    info.DN.style.top = Utils.px(top);
    info.DN.style.left = Utils.px(left);
    
    AppLayout.Refs.AbsoluteItemGuide && AppLayout.Refs.AbsoluteItemGuide.UpdatePosition({
        top : top, 
        left : left,
        width : info.width,
        height : info.height
    });

    AppLayout.Refs.Inspectors.PositionRefs.top && AppLayout.Refs.Inspectors.PositionRefs.top.current && AppLayout.Refs.Inspectors.PositionRefs.top.current.SetChangingValue({value : top, unit : 'px'});
    AppLayout.Refs.Inspectors.PositionRefs.left && AppLayout.Refs.Inspectors.PositionRefs.left.current && AppLayout.Refs.Inspectors.PositionRefs.left.current.SetChangingValue({value : left, unit : 'px'});
}

export const onItemDidMount = function() {
    this.isSelected = Utils.Includes(this.props.SelectedItems, this.props.Id);
    if (this.isSelected)
        AppLayout.Refs.SelectionDesigner.RegisterItem(this.props.Id, this);
    if (!this.props.Preview) {
        this.props.GetComponentManager().GetDesignManager().Register_ItemDesigner(this.props.Id, this);
    }
}
export const onItemWillUnMount = function() {
    this.unmounted = true;
    if (!this.props.Preview)
        this.props.GetComponentManager().GetDesignManager().UnRegister_ItemDesigner(this.props.Id, this);
    if (this.isSelected || this.isMouseOver)
        AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.UnRegisterItem(this.props.Id, this);
}
export const onItemDidUpdate = function(prevProps, prevState) {
    this.state.ShouldUpdate = false;
    if (!AppState.Designer.EditingText && prevProps.ComponentStateId !== this.props.ComponentStateId && !AppState.Designer.DisableDragDrop) {
        if (this.props.IsRootItem && !this.props.IsRootItem.SubComponent) {
            if (this.isSelected)
                AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.Show();
        }
        setTimeout(() => {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.Show();
        }, 200);
    }
    else if (this.isSelected) {
        AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.UpdateItemBox(this.props.Id);
        setTimeout(() => {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.UpdateItemBox(this.props.Id);
        }, 200);
    }
    if (!this.props.Preview && prevProps.Preview) {
        this.props.GetComponentManager().GetDesignManager().Register_ItemDesigner(this.props.Id, this);
    }
}

export const onItemShouldUpdate = function(nextProps, nextState) {
    this.wasSelected = this.isSelected;
    if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
        return true;   
    
    if (this.isDragging) {
        if (this.props.DropData && !nextProps.DropData && !DragDropManager.DragData) {
            this.isDragging = false;            
            if (this.mouseDownOnElement) {
                delete this.mouseDownOnElement;
                document.removeEventListener('mousemove', this.onItemDragging);
                delete this.onItemDragging;
            }  

            return true;
        }                
        else 
        if (!this.props.DropData && nextProps.DropData) {
            return true;
        }
    }       
    if (this.props.Preview !== nextProps.Preview) {
        if (nextProps.Preview) {
            nextProps.GetComponentManager().GetDesignManager().UnRegister_ItemDesigner(this.props.Id, this);
        }
        else {
            nextProps.GetComponentManager().GetDesignManager().Register_ItemDesigner(this.props.Id, this);
        }
    }
    if (this.props.GlobalThemeId !== nextProps.GlobalThemeId && Globals.ProjectManager.CurrentTheme.ChangingTokens) {
        if (this.renderData && this.renderData.renderedTokens) {
            Utils.ForEach(Globals.ProjectManager.CurrentTheme.ChangingTokens, (Token, i) => {
                Utils.ForEach(this.renderData.renderedTokens, (tokenId, propName) => {
                    if (Token.Id === tokenId) {
                        if (Utils.BorderStyles.isBorderName(propName)) {
                            const propStyle = propName;
                            const propSize = propName.replace('Style', 'Width');
                            this.renderData.style[propStyle] = Token.style;
                            this.renderData.style[propSize] = Token.size;
                        }
                        else if (propName === 'transform') {
                            this.renderData.style.transform = Token.value.transform;
                            this.renderData.style.transformOrigin = Token.value.transformOrigin;
                        }
                        else if (propName === 'transform') {
                            this.renderData.style.transform = Token.value.transform;
                            this.renderData.style.transformOrigin = Token.value.transformOrigin;
                        }
                        else if (propName === 'filter') {
                            this.renderData.style.filter = Token.value;
                        }
                        else if (propName === 'textPattern') {
                            const style = Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, nextProps.GetComponentManager().GlobalState, Token.Id);
                            this.renderData.style = {...this.renderData.style, ...style};
                        }
                        else if (Utils.BorderStyles.isSpacingName(propName)) {
                            const style = Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, nextProps.GetComponentManager().GlobalState, Token.Id);
                            // this.renderData.style = {...this.renderData.style, ...style};
                            this.renderData.style[propName] = style;
                        }
                        else if (propName === 'backgroundImage') {
                            this.renderData.style[propName] = Utils.url(Token.value);
                        }
                        else if (propName === 'icon') {
                            this.renderData.props.icon = Token.value;
                        }
                        else if (propName === 'textContent') {
                            this.renderData.props.textContent = Token.value;
                        }
                        else {
                            this.renderData.style[propName] = Token.value;
                        }
                    }
                });
            });
            
        }
        if (false && this.renderData && this.renderData.tokens) {
            Utils.ForEach(Globals.ProjectManager.CurrentTheme.ChangingTokens, (Token, i) => {
                Utils.ForEach(nextProps.GetComponentManager().StateArray, (StyleState, ) => {
                    const stateTokens = Utils.JustGet(this.renderData.tokens, null, StyleState.Global + StyleState.Component);
                    if (stateTokens) {
                        if (stateTokens[Token.Id]) {
                            Utils.ForEach(stateTokens[Token.Id], (i, propName) => {
                                if (Utils.BorderStyles.isBorderName(propName)) {
                                    const propStyle = propName;
                                    const propSize = propName.replace('Style', 'Width');
                                    this.renderData.style[propStyle] = Token.style;
                                    this.renderData.style[propSize] = Token.size;
                                }
                                else if (propName === 'transform') {
                                    this.renderData.style.transform = Token.value.transform;
                                    this.renderData.style.transformOrigin = Token.value.transformOrigin;
                                }
                                else if (propName === 'transform') {
                                    this.renderData.style.transform = Token.value.transform;
                                    this.renderData.style.transformOrigin = Token.value.transformOrigin;
                                }
                                else if (propName === 'filter') {
                                    this.renderData.style.filter = Token.value;
                                }
                                else if (propName === 'textPattern') {
                                    const style = Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, nextProps.GetComponentManager().GlobalState, Token.Id);
                                    this.renderData.style = {...this.renderData.style, ...style};
                                }
                                else if (Utils.BorderStyles.isSpacingName(propName)) {
                                    const style = Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, nextProps.GetComponentManager().GlobalState, Token.Id, 'value');
                                    // this.renderData.style = {...this.renderData.style, ...style};
                                    this.renderData.style[propName] = style;
                                }
                                else if (propName === 'backgroundImage') {
                                    this.renderData.style[propName] = Utils.url(Token.value);
                                }
                                else if (propName === 'icon') {
                                    this.renderData.props.icon = Token.value;
                                }
                                else {
                                    this.renderData.style[propName] = Token.value;
                                }
                                
                            });   
                        }
                    }
                });                        
            });
            
        }
        return true;
    }
    if (!Utils.IsEqual(this.props.SelectedItems, nextProps.SelectedItems)) {
        this.isSelected = Utils.Includes(nextProps.SelectedItems, nextProps.Id);
        if (this.isSelected && (!this.wasSelected && !nextProps.Preview ||  this.props.Preview && !nextProps.Preview))
            AppLayout.Refs.SelectionDesigner.RegisterItem(this.props.Id, this);
        else if (!this.isSelected && this.wasSelected && !this.isMouseOver)
            AppLayout.Refs.SelectionDesigner.UnRegisterItem(this.props.Id, this);                
        
        if (this.isSelected && !this.wasSelected && Globals.WillFocusToSelectedItem && AppLayout.Refs.PanzoomCanvas) {
            const bounds = UIUtils.Utils.GetBounds(this);
            if (bounds)
                AppLayout.Refs.PanzoomCanvas.FocusBounds({x : bounds.left, y : bounds.top, width : bounds.width, height : bounds.height});
        }
        if (this.props.IsRootItem)
            this.SelectionId = Utils.Id();

        return true;
    }
    if (this.props.ComponentStateId !== nextProps.ComponentStateId) {
        if (this.isSelected && this.props.IsRootItem && !this.props.IsRootItem.SubComponent && !this.isGridEditing) {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.Hide();
        }
        this.UpdateStyle(nextProps);
        return true;
    }

    if (this.props.Preview !== nextProps.Preview) {
        return true;
    }
    if (this.props.DisableRender !== nextProps.DisableRender)
        return true;

    if (this.props.SelectionId !== nextProps.SelectionId)
        return true;

    return false;
}


export const onItemResized = function ({isWidth, width, isHeight, height, margin, padding}) {
    const result = {};
    if (margin || padding) {

    }
    else {
        let IsRootItem;
        let maxWidth, maxHeight;
        if (this.props.IsRootItem) {
            IsRootItem = true;
            if (this.props.IsRootItem.Viewport) {                
                maxWidth = this.props.IsRootItem.Viewport.Width;
                maxHeight = this.props.IsRootItem.Viewport.Height;            
            }
            else if (this.props.IsRootItem.SubComponent) {
                const maxSize = this.GetMaxSize(true);
                maxWidth = maxSize.width;
                maxHeight = maxSize.height;    
            }

            if (this.props.GenericType) {
                if (this.props.GetComponentManager().IsDefaultState()) {
                    Globals.ProjectManager.SetComponentPreviewSize(this.props.GetComponentManager().Id, {
                        width : width,
                        height : height
                    })   
                }                
            }
        }
        else {
            const maxSize = this.GetMaxSize(true);
            maxWidth = maxSize.isAutoWidth ? 9999 : maxSize.width;
            maxHeight = maxSize.isAutoHeight ? 9999 : maxSize.height;
        }
        if (isWidth) {
            if (width > maxWidth || (maxWidth - width < 5)) {
                result.isFullWidth = true;                        
            }
        }
        if (isHeight) {
            if (height > maxHeight.Height || (maxHeight - height < 5))
                result.isFullHeight = true;
        }
        this.props.GetComponentManager().SetItemSize({
            Id : this.props.Id,
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            isWidth : isWidth,
            width : width,
            isFullWidth : result.isFullWidth,
            isFullHeight : result.isFullHeight,
            isHeight : isHeight,
            height : height,
            IsRootItem : IsRootItem
        });  

        return result;
    }
}

export const onGetChildItem = ({ChildItemId, ChildItem, ParentDesigner, ParentProps, childProps}) => {
    const SubMetaItem = ChildItem || ParentDesigner.props.GetComponentManager().GetMetaItem(ChildItemId);
    if (SubMetaItem) {
        const ChildItemType = GetItemType(SubMetaItem.Type);                

        const UseChildProps = {
            ...ParentProps,
            isSlotContainer : false,
            isSlideContainer : false,
            IsRootItem : false,
            Id : ChildItemId,
            GetParentSize : ParentDesigner.GetItemSize,
            ParentId : ParentDesigner.props.Id,
            IsGridChild : false,
            onMouseOverChild : ParentDesigner.onMouseOverChild,
            ...childProps,
            isSubComponentChild : ParentDesigner.props.isSubComponentChild,
            MetaItem : SubMetaItem,
            renderSlot : ParentDesigner.props.renderSlot || ParentDesigner.renderSlot,
            ParentzIndex : (childProps ? childProps.ParentzIndex : 0) || ((ParentDesigner.props.ParentzIndex || 0) + 1),
            SelectionId : ParentDesigner.SelectionId || ParentDesigner.props.SelectionId,
            onChildAction : ParentDesigner.onChildAction
        };

        if (SubMetaItem.Type === Strings.COMPONENT) {
            UseChildProps.renderSlotContent = ParentDesigner.renderSlot;
            UseChildProps.renderSlidecontent = ParentDesigner.renderSlide;            
        }

        if (ParentDesigner.props.isDropTarget) {
            if (ParentDesigner.props.isDropTarget.SourceItemId === ChildItemId) {
                UseChildProps.isDropTarget = false;
            }
            else {
                if (SubMetaItem.Type === MetaData.Components.Div.Type || 
                    SubMetaItem.Type === MetaData.Components.Image.Type 
                    || SubMetaItem.Type === MetaData.Components.Component.Type
                    || SubMetaItem.Type === MetaData.Components.Content.Type
                ) {
                    UseChildProps.onRegisterPlaceholder = ParentDesigner.props.onRegisterPlaceholder || ParentDesigner.RegisterPlaceholder;
                    UseChildProps.onRegisterDropTarget = ParentDesigner.props.onRegisterDropTarget || ParentDesigner.RegisterDropTarget;
                    if (childProps && childProps.IsGridChild) {
                        UseChildProps.onRegisterPlaceholder = null;
                        UseChildProps.onRegisterDropTarget = null; //ParentDesigner.RegisterDropTarget || ParentDesigner.props.onRegisterDropTarget;
                    }
                }  
            }
        }

        if (ParentDesigner.props.IsRootItem && ParentDesigner.props.IsRootItem.SubComponent)
            UseChildProps.isSubComponentChild = ParentDesigner.props.isSubComponentChild;

        return (
            <ChildItemType 
                {...UseChildProps}  
                key={ChildItemId}                                              
            />
        ) 
    }                    
}

export const onGetItemProps = ({designer, props, style}) => {
    const itemprops = {        
        onMouseDown : designer.onMouseDown,
        onMouseUp : designer.onItemClick,
        onMouseOver : designer.onMouseOver,
        onMouseOut : designer.onMouseOut,
        ...props
    };

    if (style) {
        itemprops.style = style;
        if (designer.props.isSubComponentChild && designer.props.Preview) {
            style.pointerEvents = 'none';
        }
    }

    if (designer.props.Preview) {
        if (!designer.props.IsRootItem) {
            itemprops.onMouseDown = null;
            itemprops.onMouseUp = null;
            itemprops.onMouseOver = null;
            itemprops.onMouseOut = null;
        }
        else if (designer.props.IsRootItem.SubComponent) {
            itemprops.isSubComponentChild = designer.props.isSubComponentChild;    
            itemprops.onMouseOver = designer.props.IsRootItem.SubComponent.onMouseOver;
            itemprops.onMouseOut = designer.props.IsRootItem.SubComponent.onMouseOut;
            itemprops.onMouseOverChild = designer.props.IsRootItem.SubComponent.onMouseOverChild;
        }
    }

    return itemprops;
}

export const onGetDomProps = ({designer, props, style}) => {
    const domprops = onGetItemProps({designer : designer, props : props, style : style});
    delete domprops.isSubComponentChild;
    delete domprops.onMouseOverChild;
    return domprops;
}   