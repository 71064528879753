import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Strings,
    Globals
} from '../../../importer';
import styled from 'styled-components';
import { TokenItemBox } from '../../../views/project/panels/left/designsystem/common';
import {ProjectCardBox} from '../../../views/projectlist/project-card';
import TextInput from '../../../components/editors/textinput';
import { FormatRGB } from '../../../components/editors/color';
import ChromaJs from 'chroma-js';
import Switch from '../../../components/editors/Switch';
import { Seperator } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { SetFontTypeFace } from '../../../views/project/panels/left/designsystem/typography';
import { CalculateScaleForValue } from '../../../views/project/manager/tokens';

export default class NewFigmaProject extends React.Component {
    constructor(props) {
        super(props);

        this.ToggleLocalStyles = this.ToggleLocalStyles.bind(this);        

        this.state = {
            name : Utils.UseNullOrEmpty(this.props.name),
            createLocalStyles : true
        }

        this.ParseLocalStyles = this.ParseLocalStyles.bind(this);        
    }
    componentDidMount() {
        
    }
    CreateProject() {
        this.setState({creating : true}, () => {
            AppState.Data.Board.New(this.state.name, this.props.teamId).then((result) => {
                if (result) {
                    const projectId = result.id;
                    Globals.ProjectManager.Load({ProjectId : projectId, DoNotInitialize : true} ).then((result) => {
                        Utils.Set(this.props.GetPluginApi().FigmaProjectsIds, true, projectId);
    
                        this.props.GetPluginApi().FigmaFonts = {};

                        this.props.GetPluginApi().GetLocalStyles((styles) => {                        
                            this.ParseLocalStyles(styles);
    
                            if (this.FigmaStyles) {
                                const token_stylemappings = {};
                                if (this.FigmaStyles.colors) {
                                    Utils.ForEach(this.FigmaStyles.colors, (color, i) => {
                                        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Color(color);
                                        token_stylemappings[tokenId] = {
                                            styles : [color.fid],
                                            type : 'colors'
                                        };
                                    });                            
                                }
        
                                if (this.FigmaStyles.gradients) {
                                    Utils.ForEach(this.FigmaStyles.gradients, (gradient, i) => {
                                        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Gradient(gradient);
                                        token_stylemappings[tokenId] = {
                                            styles : [gradient.fid],
                                            type : 'gradients'
                                        };
                                    });                            
                                }
        
                                if (this.FigmaStyles.shadows) {
                                    Utils.ForEach(this.FigmaStyles.shadows, (shadow, i) => {
                                        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Shadow(shadow);
                                        
                                        token_stylemappings[tokenId] = {
                                            styles : [shadow.fid],
                                            type : 'shadows'
                                        };
                                    });                            
                                }
        
                                const FigmaFonts = this.props.GetPluginApi().FigmaFonts;
                                const fontIds = {};
                                let fontCount = 0;
                                if (FigmaFonts) {
                                    Utils.ForEach(FigmaFonts, (figmafont, fontFamily) => {
                                        if (figmafont && figmafont.variants) {
                                            Utils.ForEach(figmafont.variants, (id, variant) => {
                                                let useFontId;
                                                if (fontCount === 0)
                                                    useFontId = 'DefaultFont';
                                                else if (fontCount === 1)
                                                    useFontId = 'SecondaryFont';
        
                                                fontCount++;
                                                useFontId = SetFontTypeFace(useFontId, {
                                                    provider : Strings.FONT_GOOGLE,
                                                    family : fontFamily,
                                                    variant : variant.toString(),
                                                    url : `https://fonts.googleapis.com/css?family=${fontFamily}:${variant}`                                            
                                                }, false, true);
                                                fontIds[fontFamily + variant] = useFontId;
                                            });
                                        }
                                    });
                                }
        
                                if (this.FigmaStyles.texts) {                            
                                    Utils.ForEach(this.FigmaStyles.texts, (text, i) => {
                                        const result = {scale : 0, diff : 0};
                                        const {scale, diff} = CalculateScaleForValue({result : result, baseValue : 16, ratio : 1.2, targetValue : text.fontSize});                       
                                        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Text(text, fontIds[text.fontFamily + text.fontWeight]);
                                        token_stylemappings[tokenId] = {
                                            styles : [text.fid],
                                            type : 'Fonts'
                                        };
                                    });                            
                                }
        
                                this.props.GetPluginApi().PostMessage({
                                    type : 'BindStylesToTokens',
                                    data : {
                                        projectId : Globals.ProjectManager.Id,
                                        bindings : token_stylemappings
                                    }
                                })
                            }
                            this.props.onSubmit(projectId);
                        });                                           
                    });                
                }
            });
        });        
    }
    ToggleLocalStyles() {
        if (!this.state.createLocalStyles) {
            this.props.GetPluginApi().GetLocalStyles(this.ParseLocalStyles);            
        }      
        else {
            delete this.FigmaStyles;
        }
        this.setState({createLocalStyles : !this.state.createLocalStyles});
    }
    ParseLocalStyles(styles) {
        // console.log(JSON.stringify(styles, null, 2));
        this.FigmaStyles = {            
        };
               
        if (styles.colors) {
            const colors = Utils.Get(this.FigmaStyles, [], 'colors');
            Utils.ForEach(styles.colors, (figmaColor, i) => {
                colors.push( this.props.GetPluginApi().ParseFigmaLocalSyle_Color(figmaColor));
            });
        };
        if (styles.gradients) {
            const gradients = Utils.Get(this.FigmaStyles, [], 'gradients');
            Utils.ForEach(styles.gradients, (figmaGradient, i) => {                
                gradients.push(this.props.GetPluginApi().ParseFigmaLocalSyle_Gradient(figmaGradient));       
            });
        };
        if (styles.texts) {
            const texts = Utils.Get(this.FigmaStyles, [], 'texts');
            const fonts = Utils.Get(this.FigmaStyles, {}, 'fonts');
            Utils.ForEach(styles.texts, (figmaText, i) => {                           
                texts.push(this.props.GetPluginApi().ParseFigmaLocalSyle_TextStyle(figmaText, fonts));
            });

            this.props.GetPluginApi().LoadFigmaStyleFonts(fonts);
        };

        if (styles.shadows) {
            const shadows = Utils.Get(this.FigmaStyles, [], 'shadows');
            Utils.ForEach(styles.shadows, (figmaEffect, i) => {                
                shadows.push(this.props.GetPluginApi().ParseFigmaLocalSyle_Shadow(figmaEffect));              
            });
        }
    }
    render() { 
        this.state.ShouldUpdate = false;

        if (this.state.creating) {
            return (
                <SC.FCol fw fh alc >
                    <SC.FCol alc style={{lineHeight : '24px', marginBottom : '24px', marginTop : '48px', fontSize : '14px'}}>                                                
                        <div>Parsing Figma Styles</div>
                        <div>and</div>
                        <div>Creating Tokens</div>                 
                    </SC.FCol>                    
                    <SC.Loading_Icon />
                </SC.FCol>
            )
        }

        return (
            <React.Fragment>
                <SC.FCol f1 alc style={{
                    padding : '16px'
                }}>
                    <div>Project Name</div>
                    <TextInput 
                        style={{
                            fontSize : '14px',
                            height : '34px',
                            marginTop : '16px',
                            paddingLeft : '8px',
                            paddingRight : '8px'
                        }}
                        autoFocus
                        value={this.state.name}
                        onChange={(name) => {this.setState({name : name})}}
                        onSubmit={this.CreateProject.bind(this)}
                    />
                    <SC.FRow alc justifyEnd style={{marginTop : '16px'}}>
                        {
                            !this.props.firstProject && 
                            <SC.Buttons.RoundButton small style={{width : '100px', marginRight : '4px', alignSelf : 'flex-end'}} onClick={this.props.onCancel}>
                                Cancel
                            </SC.Buttons.RoundButton>
                        }                        
                        <SC.Buttons.RoundButton small primary style={{width : this.props.firstProject ? '180px' : '100px', marginLeft : '4px', alignSelf : 'flex-end'}} onClick={this.CreateProject.bind(this)}>
                            {this.props.firstProject ? 'Get Started' : 'Create'}
                        </SC.Buttons.RoundButton>
                    </SC.FRow>                                    
                </SC.FCol>
            </React.Fragment>
        )
    }
}
 
const SelectionItemBox = styled(SC.FRow)`    
    padding : 4px;
    padding-right : 8px;
    margin-bottom : 4px;
    &:hover {
        background-color : ${props => props.theme.back_lighter};
    }
`;
