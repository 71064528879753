import { Utils } from "../../../../../toolabs-importer";

export const ComponentListInstances = {};

export const UpdateComponentLists = () => {
    Utils.ForEach(ComponentListInstances, (Instance, InstanceId) => {
        Instance.Reload();
    });
}

export default ComponentListInstances;