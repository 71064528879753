import Utils from "../../../toolabs-utils";
import Styles, { CommonStyles, TextStyles } from '../../styles';
import Props, { CommonProperties } from '../../props';
import Events, { CommonEvents } from '../../events';
import Initializers from './initialize';

const AllStyles = Utils.Concat(CommonStyles, TextStyles, [
    Styles.overflow.name,
    Styles.gridColumnGap.name,
    Styles.gridRowGap.name,
    Styles.fill.name
]);

const AllProps = Utils.Concat(CommonProperties, [
    Props.layout.name,
    Props.boxShadow.name,
    Props.MetaImage.name,
    Props.TokenOptions.name,
    Props.count.name,
    Props.Data.name,
    Props.RepeatType.name,
    Props.boxShadow.name,
    Props.MetaImage.name,
    Props.text.name,
    Props.textShadow.name,
    Props.truncate.name,
    Props.value.name,
    Props.InputType.name,
    Props.placeholder.name,
    Props.MetaSvg.name,
    Props.Component.name,
    Props.Provider.name,
    Props.MetaIcon.name,
    Props.WidgetOptions.name,
    Props.GenericOptions.name
]);

const GenericComponents = {
    Type: 'Generic',
    Styles: AllStyles,
    Props: AllProps,
    Events: [],
    Text: {
        Type: 'Text',
        Styles : CommonStyles.concat(
            TextStyles
        ),
        Props : CommonProperties.concat(
            [            
                Props.text.name,
                Props.textShadow.name,
                Props.truncate.name,
                Props.GenericOptions.name
            ]
        ),
        Events : CommonEvents.concat(
            [
                
            ]
        ),
        Name: 'Text',
        Generic : true
    },
    Button: {
        Type: 'Button',
        Styles : CommonStyles.concat(
            TextStyles
        ),
        Props : CommonProperties.concat(
            [            
                Props.text.name,
                Props.boxShadow.name,
                Props.truncate.name,
                Props.MetaImage.name,
                Props.GenericOptions.name
            ]
        ),
        Events : CommonEvents.concat(
            [
                
            ]
        ),
        Name: 'Button',
        Generic : true
    },
    Input: {
        Type: 'Input',
        Styles : CommonStyles.concat(
            TextStyles
        ),
        Props : CommonProperties.concat(
            [            
                Props.value.name,
                Props.placeholder.name,
                Props.boxShadow.name,
                Props.MetaImage.name,
                Props.GenericOptions.name
            ]
        ),
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Text Input',
        Generic : true
    },
    Textarea: {
        Type: 'Textarea',
        Styles : CommonStyles.concat(
            TextStyles
        ),
        Props : CommonProperties.concat(
            [            
                Props.value.name,
                Props.placeholder.name,
                Props.boxShadow.name,
                Props.MetaImage.name,
                Props.GenericOptions.name
            ]
        ),
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Textarea',
        Generic : true
    },
    DateInput: {
        Type: 'DateInput',
        Styles : CommonStyles.concat(
            TextStyles
        ),
        Props : CommonProperties.concat(
            [            
                Props.value.name,
                Props.placeholder.name,
                Props.boxShadow.name,
                Props.MetaImage.name,
                Props.GenericOptions.name
            ]
        ),
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Date & Time Input',
        Generic : true
    },
    Dropdown: {
        Type: 'Dropdown',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Dropdown',
        Generic : true
    },
    Select: {
        Type: 'Select',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Select',
        Generic : true
    },
    Checkbox: {
        Type: 'Checkbox',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Checkbox',
        Generic : true
    },
    Toggle: {
        Type: 'Toggle',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Switch',
        Generic : true
    },
    RadioGroup: {
        Type: 'RadioGroup',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Radio Group',
        Generic : true
    },
    RangeInput: {
        Type: 'RangeInput',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
                Events.change.name
            ]
        ),
        Name: 'Range Input',
        Generic : true
    },
    ProgressBar: {
        Type: 'ProgressBar',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            []
        ),
        Name: 'Progress Bar',
        Generic : true
    },
    Carousel: {
        Type: 'Carousel',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
            ]
        ),
        Name: 'Carousel',
        Generic : true
    },
    Tab: {
        Type: 'Tab',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
            ]
        ),
        Name: 'Tab',
        Generic : true
    },
    Accordion: {
        Type: 'Accordion',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
            ]
        ),
        Name: 'Accordion',
        Generic : true
    },
    Table: {
        Type: 'Table',
        Styles: AllStyles,
        Props: AllProps,
        Events : CommonEvents.concat(
            [
            ]
        ),
        Name: 'Table',
        Generic : true
    },
    InitializeComponent(MetaItem, GenericType) {
        return Initializers(MetaItem, GenericType, this[GenericType].Name);
    }
}

export default GenericComponents;