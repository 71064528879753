import React from 'react';
import ReactDOM from 'react-dom';

import {
    AppState,
    Utils,
    Strings,
    Events,
    MetaData,
    SC,
    AppLayout
} from '../../../../../../importer';
import { motion } from 'framer-motion';
import { COLOR_HANDLE_SUBCOMPONENT } from '../../../../panzoom/selectedItemDesigner/spacer';

export default class AbsoluteItemGuide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            top : this.props.top,
            left : this.props.left,
            width : this.props.width,
            height : this.props.height
        };

        AppLayout.Refs.AbsoluteItemGuide = this;
    }
    componentWillUnmount() {
        AppLayout.Refs.AbsoluteItemGuide = null;
    }
    UpdatePosition({top, left, width, height}) {
        this.setState({
            top : top,
            left : left,
            width : width,
            height : height
        })
    }
    render() { 
        return (  
            <SC.AbsoluteOverlay
                style={{
                    border : '1px solid blue',
                    color : COLOR_HANDLE_SUBCOMPONENT,
                    fontSize : '10px'
                }}
            >
                <motion.div
                    style={{
                        position : 'absolute',
                        top : 0,
                        left : 0,       
                        borderRight : '1px solid',
                        borderColor : COLOR_HANDLE_SUBCOMPONENT
                    }}
                    initial={false}
                    transition={{
                        duration : 0.01
                    }}
                    animate={{
                        width : this.state.left + (this.state.width / 2),
                        height : this.state.top,
                    }}
                >
                    <div style={{
                        position : 'absolute',
                        top : '-4px', right : 0,
                        transform : 'translate(50%, -100%)'
                    }}>
                        {Utils.ToInteger(this.state.top)}
                    </div>
                </motion.div>
                <motion.div
                    style={{
                        position : 'absolute',
                        left : 0, bottom : 0,                        
                        borderRight : '1px solid',
                        borderColor : COLOR_HANDLE_SUBCOMPONENT
                    }}
                    initial={false}
                    transition={{
                        duration : 0.01
                    }}
                    animate={{
                        top : this.state.top + this.state.height,
                        width : this.state.left + (this.state.width / 2),
                    }}
                >
                     <div style={{
                        position : 'absolute',
                        bottom : '-4px', right : 0,
                        transform : 'translate(50%, 100%)'
                    }}>
                        {Utils.ToInteger(this.props.parentHeight - this.state.top - this.state.height)}
                    </div>
                </motion.div>
                <motion.div
                    style={{
                        position : 'absolute',
                        top : 0, left : 0,                        
                        borderBottom : '1px solid',
                        borderColor : COLOR_HANDLE_SUBCOMPONENT
                    }}
                    initial={false}
                    transition={{
                        duration : 0.01
                    }}
                    animate={{
                        height : this.state.top + (this.state.height / 2),
                        width : this.state.left,
                    }}
                >
                     <div style={{
                        position : 'absolute',
                        bottom : 0, left : '-4px',
                        transform : 'translate(-100%, 50%)'
                    }}>
                        {Utils.ToInteger(this.state.left)}
                    </div>
                </motion.div>
                <motion.div
                    style={{
                        position : 'absolute',
                        top : 0, 
                        right : 0,                        
                        borderBottom : '1px solid',
                        borderColor : COLOR_HANDLE_SUBCOMPONENT
                    }}
                    initial={false}
                    transition={{
                        duration : 0.01
                    }}
                    animate={{
                        height : this.state.top + (this.state.height / 2),
                        left : this.state.left + this.state.width,
                    }}
                >
                    <div style={{
                        position : 'absolute',
                        bottom : 0, right : '-4px',
                        transform : 'translate(100%, 50%)'
                    }}>
                        {Utils.ToInteger(this.props.parentWidth - this.state.left - this.state.width)}
                    </div>
                </motion.div>
            </SC.AbsoluteOverlay>
        );
    }
}
 