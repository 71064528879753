import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals,
    MetaData
} from '../../../importer';

import InputSlider from '../input_slider';
import {LabelDegree} from '../transform/transformItemEditor';
import Switch from '../Switch';
import { Seperator } from '../../../views/project/panels/right/iteminspector/styleitems/common';

export default class FilterEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        this.AnimationKey = Utils.Id();
        this.Ref_PreviewBox = React.createRef();

        this.filterStyle = Utils.GetFilterCss(Utils.JustGet(this.props.model, {}, 'items'), MetaData.Properties.filter);
    }    
    UpdateFilters(filters) {
        this.filterStyle = Utils.GetFilterCss(Utils.JustGet(this.props.model, {}, 'items'), MetaData.Properties.filter);
        if (this.props.offline) {
            this.props.model.items = filters;
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : {
                    items : filters
                },
                type : Globals.ProjectManager.Tokens.Types.Filters
            });
            this.props.model.items = filters;
        }
              
        this.props.onUpdate(this.filterStyle);
        this.RCUpdate();       
    }        
    onChangeValue(prop, value) {
        Utils.Set(this.props.model, value, 'items', prop, 'value');
        this.UpdateFilters(this.props.model.items);
    }
    onChangingValue(prop, value) {
        Utils.Set(this.props.model, value, 'items', prop, 'value');
        this.filterStyle = Utils.GetFilterCss(Utils.JustGet(this.props.model, {}, 'items'), MetaData.Properties.filter);
        this.props.onUpdate(this.filterStyle);        
    }
    ToggleValue(prop, defaultValue, value) {
        if (!value) {
            Utils.UnSet(this.props.model, 'items', prop);
            this.UpdateFilters(this.props.model.items);
        }
        else {
            Utils.Set(this.props.model, defaultValue, 'items', prop, 'value');
            this.RCUpdate();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.filterStyle = Utils.GetFilterCss(Utils.JustGet(nextProps.model, {}, 'items'), MetaData.Properties.filter);
        }
        return true;
    }
    renderCustom() {
        const {model} = this.props;
        const filters = model.items;

        const filterItems = [];
        
        const props = MetaData.Properties.filter.propsList;
        props.map((prop, i) => {
            let defaultValue = 0;
            if (prop === 'saturate' || prop === 'brightness' || prop === 'contrast' || prop === 'opacity')
                defaultValue = 100;
            const props = {
                max : 100,
                postFix : '%',
                value : Utils.JustGet(filters, null, prop, 'value'),
                hasValue : null
            }
            props.hasValue = Utils.IsNotNull(props.value);

            if (Utils.IsNull(props.value)) {
                props.value = defaultValue;
            }
            if (prop === 'blur') {
                props.max = 10;
                props.postFix = 'px';
            }
            else if (prop === 'hue-rotate') {
                props.max = 360;
                props.postFix = LabelDegree;
            }
            else if (prop === 'saturate') {
                props.ref = this.LastNodeRef;
            }

            filterItems.push(
                <SC.FCol>
                    <Seperator />
                    <SC.FRow alc jsb>
                        <div>{MetaData.Properties.filter.subProps[prop].label}</div>    
                        <Switch round value={props.hasValue} onChange={this.ToggleValue.bind(this, prop, defaultValue)} />
                    </SC.FRow>
                    {
                        props.hasValue && 
                        <InputSlider 
                            horizontal 
                            small 
                            reverse                                                         
                            min={0}
                            {...props}
                            allowOverflow
                            onChange={this.onChangeValue.bind(this, prop)}
                            onChanging={this.onChangingValue.bind(this, prop)}
                            inputStyle={{fontSize : '12px', marginLeft : '-8px'}}
                            style={{marginTop : '8px'}}
                        />
                    }
                                        
                </SC.FCol>
            )
        })

        return (
            <SC.FCol f1 style={this.props.style}>                      
                <SC.GridBackground dark={!SC.CurrentTheme.theme.isLight} style={{height : '180px', ...SC.Styles.Flex.Cell, marginBottom : '8px'}}>
                    <div                        
                        style={{width : '60px', height : '60px', borderRadius : '4px', backgroundColor : 'rgba(184, 109, 240)', position : 'relative',
                        filter : this.filterStyle
                        }}>                                         
                    </div>                    
                </SC.GridBackground>                                                                
                <SC.CustomScrollbars>
                    <SC.FCol fw fh style={{
                       
                    }}>
                        {filterItems}
                    </SC.FCol>
                </SC.CustomScrollbars>
            </SC.FCol>
        );	
    }
}