import {
    AppState,
    Events,
    Strings,
    Utils,
    Globals
} from '../../../../importer';
import MetaData from '../../../../../toolabs-modules/toolabs-metaui';

const GridManager = {    
    GetGridSize(size, defUnit, scale) {
        if (!size)
            return null;
        if (size && size.minMax) {
            return `minmax(${this.GetGridSize(size.minMax.min, 'px', scale)}, ${this.GetGridSize(size.minMax.max, 'px', scale)}) `;
        }
        if (size && size.value === 'auto')
            return 'auto ';
        let value = size.value;
        const unit = size.unit || defUnit || 'px';
        if (unit === 'px' && scale && scale !== 1)
            value = size.value * scale;
        return Utils.px(Utils.ToInteger(value), unit) ;
    },
    GetGridItems(model, grid_style, modelName, templateName, scale) {
        const columnsModel = Utils.Get(model, {}, modelName);
        const metaColumns = Utils.Get(columnsModel, [], 'Items');
        let gridTemplateColumns = '';
        Utils.ForEach(metaColumns,(col, i) => {
            if (col.Single && !col.hidden) {
                const size = this.GetGridSize(col.Single.size, 'px', scale);
                if (col.Repeat > 1) {                                
                    gridTemplateColumns += ` repeat(${col.Repeat}, ${size}) `;
                }
                else 
                    gridTemplateColumns += ' ' + size;                
            }            
        });

        if (columnsModel.DynamicColumn) {
            if (gridTemplateColumns.indexOf('fr') < 0) {
                const size_Min = this.GetGridSize(Utils.JustGet(columnsModel.DynamicColumn, {unit:{unit : 'px'}, value :  200}, 'minWidth'));
                const size_Max = this.GetGridSize(Utils.JustGet(columnsModel.DynamicColumn, {unit:{unit : 'fr'}, value :  1}, 'maxWidth'));
                gridTemplateColumns += ` repeat(${columnsModel.DynamicColumn.type === 'autoFit' ? 'auto-fit' : 'auto-fill'}, minmax(${size_Min}, ${size_Max})) `;
            }            
        }

        grid_style[templateName] = gridTemplateColumns;
    },
    MergeItems(items) {
        const mergedItems = [];
        let lastItem;
        let lastSize;
        Utils.ForEach(items, (item, i) => {
            item.size = this.GetGridSize(item.model.Single.size);
            if (lastItem) {
                if (lastSize === item.size) {
                    lastItem.Repeat = (lastItem.Repeat || 0 ) + 1;
                    lastSize = item.size;
                }
                else {
                    lastItem = null;
                }
            }
            if (!lastItem) {
                lastItem = item.model;
                lastSize = item.size;
                lastItem.Repeat = 1;
                mergedItems.push(lastItem);
            }
        });

        return mergedItems;
    },
    GetCssGridStyle(GridModel, isPlaceHolder, JustifyItems, AlignItems, justifyContent, alignContent, rowGap, columnGap ) {
        const grid_style = {
            display : 'grid',
        }                
        if (!GridModel)
            return grid_style;

        this.GetGridItems(GridModel, grid_style, 'Columns', 'gridTemplateColumns');
        this.GetGridItems(GridModel, grid_style, 'Rows', 'gridTemplateRows');
        this.GetGridItems(GridModel, grid_style, 'AutoColumns', 'gridAutoColumns');
        this.GetGridItems(GridModel, grid_style, 'AutoRows', 'gridAutoRows');

        if (!isPlaceHolder) {
            if (JustifyItems)
                grid_style.justifyItems = MetaData.Properties.layout.values.Flex.convertValue(JustifyItems);
            if (AlignItems)
                grid_style.alignItems = MetaData.Properties.layout.values.Flex.convertValue(AlignItems);            
        }        
        if (rowGap) 
            grid_style.gridRowGap = Utils.px(rowGap);
        if (columnGap) 
            grid_style.gridColumnGap = Utils.px(columnGap);
        if (justifyContent)
            grid_style.justifyContent = justifyContent;
        if (alignContent)
            grid_style.alignContent = alignContent;          
        return grid_style;
    }
};

export default GridManager;