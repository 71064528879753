import React from 'react';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import { GenericTable_SubTypeIds } from '../../../../toolabs-metaui/components/generic/initialize/table';

class Generic_Table extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();     

    }
    
    renderItem({style, props, events, rest}) {                   
        const { MetaItem } = this.props;

        const options = {};
        GenericTable_SubTypeIds.map((SubType) => {
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);         
            if (SubTypeId) {
                const MetaItemSubType = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === SubTypeId });
                if (MetaItemSubType) {
                    options[SubType.Id] = BuildItemStyles(MetaItemSubType, this.props) || {};
                }
            }
        })       
        const style_header = Utils.JustGet(options, {}, 'Header', 'style');
        const style_headerColumn = Utils.JustGet(options, {}, 'HeaderColumn', 'style');
        const style_body = Utils.JustGet(options, {}, 'Body', 'style');
        const style_column = Utils.JustGet(options, {}, 'Column', 'style');
        const style_row = Utils.JustGet(options, {}, 'Row', 'style');
        const style_rowEvens = Utils.JustGet(options, {}, 'RowEvens', 'style');
        const style_rowOdds = Utils.JustGet(options, {}, 'RowOdds', 'style');
        
		return (
            <div style={{...style}}>
                <table style={{width : '100%'}}>
                    <thead style={{...style_header, marginBottom : '8px'}}>
                        <tr>
                            <th style={{...style_headerColumn}}>COL 1</th>
                            <th style={{...style_headerColumn}}>COL 2</th>
                            <th style={{...style_headerColumn}}>COL 3</th>
                        </tr>
                    </thead>
                    <tbody style={{...style_body}}>
                        {
                            [1, 2, 3, 4, 5].map((rowid, i) => {
                                const even_odd_style = (i%2) === 0 ? style_rowEvens : style_rowOdds;
                                return (
                                    <tr key={rowid} style={{...style_row, ...even_odd_style}}>
                                        <td style={{...style_column}}>{rowid}.A</td>
                                        <td style={{...style_column}}>{rowid}.B</td>
                                        <td style={{...style_column}}>{rowid}.C</td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}

export default Generic_Table;
