import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import styled from 'styled-components';
import { StyleGroupTokenItem, StyleGroup, StyleGroupTitle, OptionalStyleGroupHeader, ToggleButton } from '../common';
import { GetBorderRadiusTokenValue } from '../../../../left/designsystem/borders';
import { IconButtonBox } from '../../../../../../../components/AddCancelBar';
import { PanelHeaderAddButton } from '../../../../left/designsystem/common';
import { SpacePattern } from '../../../../left/designsystem/spacing/patterns';

export default class PaddingMargins extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectSpaceSide = this.SelectSpaceSide.bind(this);
        this.SelectedSide = '';
        this.SidesWithMargin = {};
        this.SidesWithPadding = {};
        this.SelectSpace = this.SelectSpace.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.onAddBorder = this.onAddBorder.bind(this);

        this.SelectedSide = Utils.UseNullOrEmpty(this.props.SelectedSide, '');

        this.LoadTokenProps(this.props);
    }
    onAddBorder() {
        this.SelectSpace();
    }
    SelectSpaceSide(side, multiple) {
        if (multiple) {
            const SelectedSides = Utils.Get(this, [], 'SelectedSides');
            if (this.SelectedSide !== '')
                SelectedSides.push(this.SelectedSide);
            SelectedSides.push(side);
        }
        else 
            delete this.SelectedSides;

        this.SelectedSide = side;
        
        this.LoadTokenProps(this.props);
        this.showEditor = true;
        this.SelectedSide = side;
        this.RCUpdate();
        this.props.onSideChanged && this.props.onSideChanged(side);
    }
    RemoveTokenBinding() {        
        this.props.onRemoveStyle(this.propPadding, this.props.Id, null, [], true);
        this.props.onRemoveStyle(this.propMargin, this.props.Id, null);
    }
    LoadTokenProps(props) {
        this.hasNoBorder = true;
        Utils.BorderStyles.Sides.map((side, i) => {
            const propPadding = Utils.BorderStyles.propPadding(side);
            const propMargin = Utils.BorderStyles.propMargin(side);
            const useSideName = Utils.UseNullOrEmpty(side, 'All');            
            this.SidesWithMargin[useSideName] = Utils.JustGet(props, null, propMargin, 'TokenId');
            this.SidesWithPadding[useSideName] = Utils.JustGet(props, null, propPadding, 'TokenId');

            if (this.SidesWithPadding[useSideName] || this.SidesWithMargin[useSideName])
                this.hasNoBorder = false;
        });
        this.propPadding = Utils.BorderStyles.propPadding(this.SelectedSide);
        this.propMargin = Utils.BorderStyles.propMargin(this.SelectedSide);
        this.tokenId_padding = Utils.JustGet(props, null, this.propPadding, 'TokenId');
        this.tokenId_margin = Utils.JustGet(props, null, this.propMargin, 'TokenId');
    }
    shouldComponentUpdate(nextProps, nextState) {      
          
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.SelectedSide = '';
            this.showEditor = false;
            this.LoadTokenProps(nextProps);
            return true;
        }
        
        const tokenId_padding = Utils.JustGet(nextProps, null, this.propPadding, 'TokenId');
        const tokenId_margin = Utils.JustGet(nextProps, null, this.propMargin, 'TokenId');

        if (this.hasNoBorder && (tokenId_padding || tokenId_margin))
            this.hasNoBorder = false;

        if (tokenId_padding !== this.tokenId_padding ||tokenId_margin !== this.tokenId_margin) {
            this.tokenId_padding = tokenId_padding;
            this.tokenId_margin = tokenId_margin;
            return true;
        }

        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'GlobalStateId', 'GlobalState'))
            return true;     
               
        return super.ShouldUpdate(nextProps, nextState);
    }
    SelectSpace(isPadding) {
        this.props.onSelectSpacing(isPadding, this.SelectedSide);
    }
    renderCustom() {        
        const token_padding = {

        };
        const token_margin = {

        };

        let isEmpty = true;
        
        if (this.tokenId_padding) {
            const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(this.tokenId_padding);
            if (tokenModel) {
                token_padding.value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, this.props.StateArray);
                token_padding.unit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(tokenModel, null, this.props.StateArray);
                token_padding.name = tokenModel.name;
                token_padding.pattern = tokenModel;
                isEmpty = false;
            }            
        }
        if (this.tokenId_margin) {
            const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(this.tokenId_margin);
            if (tokenModel) {
                token_margin.value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, this.props.StateArray);
                token_margin.unit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(tokenModel, null, this.props.StateArray);
                token_margin.name = tokenModel.name;
                token_margin.pattern = tokenModel;
                isEmpty = false;
            }            
        }      
        
        let header, content;
        if (this.hasNoBorder && !this.showEditor) {
            header = (
                <OptionalStyleGroupHeader title={this.props.title} onClick={this.onAddBorder} />
            )
        }
        else {
            header = (
                <SC.FRow alc jsb>
                    <StyleGroupTitle>{this.props.title}</StyleGroupTitle>
                    <SC.FRow alc style={{fontSize : '11px', height : '18px'}}>                        
                        {Utils.UseNullOrEmpty(this.SelectedSide, 'All Sides')}
                        <SC.Icons.Icon_Button hasFill hasCursor  onClick={this.RemoveTokenBinding} style={{marginLeft : '8px'}}>
                            <SC.Icons.Icon_Delete size={16} />
                        </SC.Icons.Icon_Button>
                    </SC.FRow>
                </SC.FRow>
            )
            // content = (
            //     <SC.FRow style={{marginTop : this.props.justContent ? 0 : '8px'}}>
            //         <SC.FCol alc justifyCenter>
            //             <SpacingSideSelector
            //                 onSelectSide={this.SelectSpaceSide}
            //                 sidesWithMargin={this.SidesWithMargin}
            //                 sidesWithPadding={this.SidesWithPadding}
            //                 side={this.SelectedSide}
            //                 multiple={this.SelectedSides}
            //             >

            //             </SpacingSideSelector>
            //         </SC.FCol>
            //         <SC.FCol f1 overflowHidden justifyCenter={this.props.noPadding || this.props.noMargin}>
            //             {!this.props.noPadding && <SpaceItemBox onClick={this.SelectSpace.bind(this, true)} title='PADDING' name={token_padding.name} value={token_padding.value} unit={token_padding.unit} style={this.props.noMargin ? {height : '34px', flex : 'unset'} : {marginBottom : '4px'}} />}
            //             {!this.props.noMargin && <SpaceItemBox onClick={this.SelectSpace.bind(this, false)} title='MARGIN' name={token_margin.name} value={token_margin.value} unit={token_margin.unit} style={this.props.noPadding ? {height : '34px', flex : 'unset'}:{}}/>    }
            //         </SC.FCol>
                    
            //     </SC.FRow>
            // )

            content = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : 'repeat(5, 1fr)',
                    gridGap  :'4px',
                    marginTop : '8px'
                }}>
                    {
                        [1,2,3,4,5].map((i) => {
                            return (
                                <ToggleButton selected={i === 1} style={{
                                    flex : 1,
                                    // border : '1px solid',
                                    // borderTopColor : (i === 2 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#2e2e2e',
                                    // borderBottomColor : (i === 3 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#2e2e2e',
                                    // borderLeftColor : (i === 4 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#2e2e2e',
                                    // borderRightColor : (i === 5 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#2e2e2e',
                                    position : 'relative',
                                    ...SC.Styles.FontStyles.Monospace, fontSize : '11px',
                                    ...SC.Styles.Flex.Cell
                                }}>
                                    <div style={{}}>
                                        {i === 1 ? '16' : 0}
                                    </div>
                                    {
                                        (i === 1 || i === 2 || i === 3) && 
                                        <div style={{
                                            position : 'absolute',
                                            top : '2px', bottom : '2px', pointerEvents : 'none',
                                            left : '50%', width : '12px', transform : 'translateX(-50%)',
                                            borderTop : (i === 1 || i === 2) ? '1px solid' : 'none',
                                            borderBottom : (i === 1 || i === 3) ? '1px solid' : 'none',
                                            borderColor : SC.CurrentTheme.theme.color_brand
                                        }}
                                        />
                                    }
                                    {
                                        (i === 1 || i === 4 || i === 5) && 
                                        <div style={{
                                            position : 'absolute',
                                            left : '2px', right : '2px', pointerEvents : 'none',
                                            top : '50%', height : '12px', transform : 'translateY(-50%)',
                                            borderLeft : (i === 1 || i === 4) ? '1px solid' : 'none',
                                            borderRight : (i === 1 || i === 5) ? '1px solid' : 'none',
                                            borderColor : SC.CurrentTheme.theme.color_brand
                                        }}
                                        />
                                    }
                                    
                                    {/* <div 
                                        style={{
                                            width : '14px', height : '14px',
                                            marginLeft : '8px',
                                            boxSizing : 'border-box',
                                            border : '1px solid',
                                            borderTopColor : (i === 2 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#606060',
                                            borderBottomColor : (i === 3 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#606060',
                                            borderLeftColor : (i === 4 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#606060',
                                            borderRightColor : (i === 5 || i == 1) ? SC.CurrentTheme.theme.color_brand : '#606060',
                                        }} 
                                    /> */}
                                </ToggleButton>
                            )
                        })
                    }
                </div>
            )
        }
        
        if (this.props.justContent)
            return content;

        return (
            <StyleGroup empty={this.hasNoBorder} key={this.hasNoBorder ? 'noSpacing' : 'spacing'}>
                {header}
                {content}
            </StyleGroup>
        )
    }
}

export class SpacingSideSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    SelectSide(side, e) {
        this.props.onSelectSide(side, e.shiftKey);
    }
    onOnverSide(side, show) {
        this.setState({
            OverSide : show ? side : null
        })
    }
    render() { 
        
        const style_border = {
            borderTopColor : SC.CurrentTheme.theme.border_editor_default,
            borderLeftColor : SC.CurrentTheme.theme.border_editor_default,
            borderRightColor : SC.CurrentTheme.theme.border_editor_default,
            borderBottomColor : SC.CurrentTheme.theme.border_editor_default
        }
        const bordersideItems = [];
        ['Top', 'Left', 'Right', 'Bottom'].map((side) => {
            const hasMargin = this.props.sidesWithMargin[side];
            const hasPadding = this.props.sidesWithPadding[side];
            const hasValue = hasMargin || hasPadding;
            const isOVerSide = this.state.OverSide === side;
            if (hasValue) {
                const stylename = Utils.BorderStyles.propColor(side);
                style_border[stylename] = SC.CurrentTheme.theme.border_editor_styled;
            }
            let isSelected = this.props.side === side || isOVerSide || this.props.multiple && this.props.multiple.indexOf(side) >= 0;
            if (isSelected) {
                const stylename = Utils.BorderStyles.propColor(side);
                style_border[stylename] = SC.CurrentTheme.theme.border_editor_selected;
            }
                
            bordersideItems.push((
                <BorderSide 
                    key={side}
                    margin
                    top={side === 'Top'}
                    left={side === 'Left'}
                    right={side === 'Right'}
                    bottom={side === 'Bottom'}
                    hasMargin={hasMargin}
                    hasPadding={hasPadding}
                    selected={isSelected} 
                    onClick={this.SelectSide.bind(this, side)} 
                    onMouseEnter={this.onOnverSide.bind(this, side, true)}
                    onMouseLeave={this.onOnverSide.bind(this, side, false)}
                />
            ));
        });
        return (  
            <div style={{position : 'relative', width : '70px', height : '70px', marginRight : '8px'}}>
                {bordersideItems}                
                <div style={{
                    position : 'absolute',
                    top : '4px',
                    left : '4px',
                    right : '4px',
                    bottom : '4px',
                    border : '1px solid',
                    borderRadius : '2px',
                    pointerEvents : 'none',
                    transition : 'all 0.2s ease',
                    ...style_border
                }} 
                />
                <BorderAllSides 
                    selected={this.props.side === ''}
                    hasValue={this.props.sidesWithMargin.All || this.props.sidesWithPadding.All}
                    onClick={this.SelectSide.bind(this, '')} 
                />                
            </div>
        );
    }
}

const BorderSide = ({top, left, right, bottom, hasMargin, hasPadding, selected, margin, ...props}) => {
    let style = {display : 'flex', justifyContent : 'space-between', alignItems : 'stretch'};
    let style_space = {};
    if (top) {
        style = {
            position : 'absolute',
            top : '-2px',
            height : '13px',
            left : '50%',
            transform : 'translateX(-50%)',
            flexDirection : 'column',
            width : '50%'
        }
        style_space.height = '4px';
    }
    else if (bottom) {
        style = {
            position : 'absolute',
            bottom : '-2px',
            height : '13px',
            left : '50%',
            transform : 'translateX(-50%)',
            flexDirection : 'column-reverse',
            width : '50%'
        };
        style_space.height = '4px';
    }
    else if (left) {
        style = {
            position : 'absolute',
            left : '-2px',
            width : '13px',
            top : '50%',
            transform : 'translateY(-50%)',
            flexDirection : 'row',
            height : '50%'
        }
        style_space.width = '4px';
    }
    else if (right) {
        style = {
            position : 'absolute',
            right : '-2px',
            width : '13px',
            top : '50%',
            transform : 'translateY(-50%)',
            flexDirection : 'row-reverse',
            height : '50%'
        }
        style_space.width = '4px';
    }

    return (
        <div 
            style={{
                display : 'flex', justifyContent : 'space-between', alignItems : 'stretch', ...style,
                cursor : 'pointer'
            }}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        >
            <BorderSideBox 
                style={style_space}
                hasValue={hasMargin}
                selected={selected}                
            />
            <BorderSideBox 
                style={style_space}
                hasValue={hasPadding}
                selected={selected}
            />
        </div>
    )  
}

const BorderAllSides = styled.div`
    position : absolute;
    top : 20px;
    left : 20px;
    right : 20px;
    bottom : 20px;
    border : 2px solid;
    border-color : ${props => props.selected ? props.theme.border_editor_selected : (props.hasValue ? props.theme.border_editor_styled : SC.CurrentTheme.theme.border_editor_default)};
    border-radius : 2px;
    cursor : pointer;
    z-index : 1000;
    transition : border-color 0.2s ease;
    &: hover{
        border-color : ${props => props.theme.border_editor_selected};
    }
`;

const BorderSideBox = styled.div`
    background-color : ${props => props.selected ? props.theme.border_editor_selected : (props.hasValue ? props.theme.border_editor_styled : SC.CurrentTheme.theme.border_editor_default)};
    border-radius : 1px;
    zIndex : 1;
    cursor : pointer;
    transition : background-color 0.2s ease;
    &: hover{
        background-color : ${props => props.theme.border_editor_selected};
    }
`;
 
export const SpaceItemBox = ({onClick, title, name, value, unit, style}) => {
    return (
        <StyleGroupTokenItem onClick={onClick} style={{...SC.Styles.Flex.Row, flex : 1, alignItems : 'center', justifyContent : 'space-between', paddingLeft : '8px', paddingRight : '8px', ...style, overflow : 'hidden'}}>
            <StyleGroupTitle>{title}</StyleGroupTitle>
            <div style={{...SC.Styles.FontStyles.Monospace}}>{Utils.px(value, unit)}</div>
        </StyleGroupTokenItem>
    )
}