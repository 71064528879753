
import { Utils } from '../../../../toolabs-importer';
import Strings from '../../../../appstate/Strings';
import { Globals } from '../../../../importer';

class RelationManager {
    constructor(manager) {

        this.Manager = manager;

        this.Types = {
            COMPONENT : Strings.COMPONENT,
            VIEW : Strings.VIEW,
            LAYOUT : Strings.LAYOUT,
            TOKEN : Strings.TOKEN,
            METAITEM : Strings.METAITEM,
            ELEMENT : Strings.ELEMENT,
            EVENT : Strings.EVENTS,
            ACTION : Strings.ACTIONS,
            CONDITION : Strings.CONDITIONS,
            VARIABLE : Strings.VARIABLES
        };
        this.SourceTypes = {

        };
        this.TargetTypes = {
    
        };
        this.RelationTypes = {
    
        };
    }

    Get() {
        if (this.Manager)
            return this.Manager.DataManager.Get({}, 'Relations');
    }
    AddRelation(OwnerId, ConsumerId, TargetId, Options, Type) {
        if (!TargetId)
            return;                            

        let OwnerType = null;
        if (Options) {
            OwnerType = Options.OwnerType;            
        }
        const Relations = this.Get();        
        const RelationId = Utils.Id();
        let Consumers = Utils.Get(Relations, [], 'Consumers', OwnerId, ConsumerId);
        Consumers.push(RelationId);
        if (!Array.isArray(Consumers))
            Consumers = [];
        let Targets = Utils.Get(Relations, [], 'Targets', TargetId);
        if (!Array.isArray(Targets))
            Targets = [];
        Targets.push(RelationId);
        const Relation = Utils.MergeTo({
            OwnerId : OwnerId,
            ConsumerId : ConsumerId,
            TargetId : TargetId
        }, Options);

        this.Manager.DataManager.Set(Relation, 'Relations', 'Data', RelationId);
        this.Manager.DataManager.Set(Consumers, 'Relations', 'Consumers', OwnerId, ConsumerId);
        this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', TargetId);

        if (OwnerType === this.Types.COMPONENT) {
            const Log = Globals.ProjectManager.HistoryManager.GetLastLog();
            if (Log) {
                if (!Log.Relations) 
                    Log.Relations = [];
                Log.Relations.push({
                    Id : RelationId,
                    Type : 'Add',
                    Data : Utils.DeepClone(Relation)
                });
            }
        }

        return RelationId;
    }
    GetRelation(RelationId) {
        return this.Manager.DataManager.Get(null, 'Relations', 'Data', RelationId);
    }
    GetConsumers(TargetId) {
        return this.Manager.DataManager.Get(null, 'Relations', 'Targets', TargetId);
    }
    HasRelation(TargetId) {
        const Consumers = this.Manager.DataManager.Get(null, 'Relations', 'Targets', TargetId);
        if (Consumers) {
            if (Array.isArray(Consumers)) {
                return Consumers.length > 0;
            }            
        }
        return false;
    }
    DeleteOwner(OwnerId) {
        if (OwnerId) {
            const Relations = this.Get();
            const Consumers = Utils.Get(Relations, null, 'Consumers', OwnerId);
            if (Consumers) {
                const UpdateTargets = {};
                const RemoveRelations = {};
                Utils.ForEach(Consumers, (Consumer, ConsumerId) => {
                    if (Array.isArray(Consumer)) {
                        Utils.ForEach(Consumer, (TargetItem) => {
                            let RelationId = TargetItem;
                            if (Utils.IsObject(TargetItem)) {
                                RelationId = TargetItem.Target;
                            }
                            if (RelationId) {
                                RemoveRelations[RelationId] = true;
                                const Relation = Utils.Get(Relations, null, 'Data', RelationId);
                                if (Relation && Relation.TargetId) {
                                    const Targets = Utils.Get(Relations, [], 'Targets', Relation.TargetId);
                                    if (Targets) {
                                        Utils.RemoveEquals(Targets, RelationId);
                                        UpdateTargets[Relation.TargetId] = Targets;
                                    }
                                }
                            }                                
                        });
                    }
                });            
                this.Manager.DataManager.Delete('Relations', 'Consumers', OwnerId);
                Utils.ForEach(RemoveRelations,(val, RelId) => {
                    this.Manager.DataManager.Delete('Relations', 'Data', RelId);
                });
                Utils.ForEach(UpdateTargets,(Target, TargetId) => {
                    if (Target.length > 0)
                    this.Manager.DataManager.Set(Target, 'Relations', 'Targets', TargetId);
                    else
                    this.Manager.DataManager.Delete('Relations', 'Targets', TargetId);
                });
            }
        }        
    }
    CloneOwner(SourceId, NewId) {
        const SourceConsumers = this.Manager.DataManager.Get([], 'Relations', 'Consumers', SourceId) || [];
        if (SourceConsumers) {                                    
            Utils.ForEach(SourceConsumers,(SourceRelations, ConsumerId) => {
                const NewConsumers = [];
                Utils.ForEach(SourceRelations,(RelationId, i) => {                
                    const Relation = this.Manager.DataManager.Get(null, 'Relations', 'Data', RelationId);
                    if (Relation) {
                        const NewRelation = Utils.DeepClone(Relation);
                        const NewRelationId = Utils.Id();
                        NewConsumers.push(NewRelationId);
                        NewRelation.OwnerId = NewId;                    
                        this.Manager.DataManager.Set(NewRelation, 'Relations', 'Data', NewRelationId);
                        const Targets = this.Manager.DataManager.Get([], 'Relations', 'Targets', Relation.TargetId);
                        Targets.push(NewRelationId);
                        this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', Relation.TargetId);
                    } 
                });
                this.Manager.DataManager.Set(NewConsumers, 'Relations', 'Consumers', NewId, ConsumerId);
            });
        }
    }
    CloneConsumer(OwnerId, SourceId, NewId) {
        const SourceRelations = this.Manager.DataManager.Get([], 'Relations', 'Consumers', OwnerId, SourceId) || [];
        const RelationMap = {};
        if (SourceRelations) {
            const NewRelationIds = [];            
            Utils.ForEach(SourceRelations, (RelationId, i) => {                
                const Relation = this.Manager.DataManager.Get(null, 'Relations', 'Data', RelationId);
                if (Relation) {
                    const NewRelation = Utils.DeepClone(Relation);
                    const NewRelationId = Utils.Id();
                    NewRelationIds.push(NewRelationId);
                    NewRelation.ConsumerId = NewId;                    
                    this.Manager.DataManager.Set(NewRelation, 'Relations', 'Data', NewRelationId);
                    const Targets = this.Manager.DataManager.Get([], 'Relations', 'Targets', Relation.TargetId);
                    Targets.push(NewRelationId);
                    RelationMap[RelationId] = NewRelationId;
                    this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', Relation.TargetId);

                    const Log = Globals.ProjectManager.HistoryManager.GetLastLog();
                    if (Log) {
                        if (!Log.Relations) 
                            Log.Relations = [];
                        Log.Relations.push({
                            Id : NewRelationId,
                            Type : 'Add',
                            Data : Utils.DeepClone(NewRelation)
                        });
                    }
                }                
            });
            this.Manager.DataManager.Set(NewRelationIds, 'Relations', 'Consumers', OwnerId, NewId);
        }
        return RelationMap;
    }
    DeleteConsumer(OwnerId, ConsumerId) {
        const Relations = this.Get();
        let LogTargets = null;
        if (OwnerId || Relations) {
            const Consumers = Utils.Get(Relations, null, 'Consumers', OwnerId, ConsumerId);
            if (!Consumers)
                return;
            if (Array.isArray(Consumers)) {
                Utils.ForEach(Consumers,(RelationId) => {
                    const Relation = Utils.Get(Relations, null, 'Data', RelationId);
                    if (Relation && Relation.TargetId) {
                        const Targets = Utils.Get(Relations, [], 'Targets', Relation.TargetId);
                        if (Targets) {
                            Utils.RemoveEquals(Targets, RelationId);
                            if (Targets.length > 0)
                                this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', Relation.TargetId);
                            else
                                this.Manager.DataManager.Delete('Relations', 'Targets', Relation.TargetId);
                        }  
                    }
                    this.Manager.DataManager.Delete('Relations', 'Data', RelationId);

                    if (Relation && Relation.OwnerType === this.Types.COMPONENT) {
                        const Log = Globals.ProjectManager.HistoryManager.GetLastLog();
                        if (Log) {
                            if (!Log.Relations) 
                                Log.Relations = [];
                            Log.Relations.push({
                                Id : RelationId,
                                Type : 'Delete',
                                Data : Utils.DeepClone(Relation)
                            });
                        }
                    }
                });
            }
            this.Manager.DataManager.Delete('Relations', 'Consumers', OwnerId, ConsumerId);
        }
    }
    DeleteStateRelations(ComponentId, StateId) {
        const Relations = this.Get();
        const ComponentRelations = Utils.Get(Relations, null, 'Consumers', ComponentId);
        if (ComponentRelations) {
            const DeleteRelationIds = [];
            const DeleteTargetIds = [];
            Utils.ForEach(ComponentRelations,(ItemRelations, ItemId) => {
                const NewItemRelations = [];
                Utils.ForEach(ItemRelations,(RelationId, i) => {
                    const Relation = Utils.Get(Relations, null, 'Data', RelationId);
                    let removed = false;
                    if (Relation && Relation.State) {
                        if (Relation.State.indexOf(StateId) > -1) {
                            DeleteRelationIds.push(RelationId);
                            removed = true;
                        }                        
                    }        
                    if (!removed)
                        NewItemRelations.push(RelationId);
                    else {
                        if (DeleteTargetIds.indexOf(Relation.TargetId) < 0)
                            DeleteTargetIds.push(Relation.TargetId);
                    }
                });
                if (NewItemRelations.length > 0)
                    this.Manager.DataManager.Set(NewItemRelations, 'Relations', 'Consumers', ComponentId, ItemId);
                else
                    this.Manager.DataManager.Delete('Relations', 'Consumers', ComponentId, ItemId);                    
            });

            Utils.ForEach(DeleteTargetIds,(TargetId, i) => {
                const Targets = Utils.Get(Relations, [], 'Targets', TargetId);
                if (Targets) {
                    Utils.ForEach(DeleteRelationIds,(RelId, i) => {
                        Utils.RemoveEquals(Targets, RelId);    
                    });
                    
                    if (Targets.length > 0)
                        this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', TargetId);
                    else
                        this.Manager.DataManager.Delete('Relations', 'Targets', TargetId);
                }
            });

            Utils.ForEach(DeleteRelationIds,(RelId, i) => {
                this.Manager.DataManager.Delete('Relations', 'Data', RelId);
            });
        }
    }
    DeleteRelationId(RelationId) {
        if (RelationId) {
            const Relations = this.Get();
            const Relation = Utils.Get(Relations, null, 'Data', RelationId);
            if (Relation) {
                const Consumers = Utils.Get(Relations, [], 'Consumers', Relation.OwnerId, Relation.ConsumerId);
                if (Consumers) {
                    Utils.RemoveEquals(Consumers, RelationId);
                    if (Consumers.length > 0)
                        this.Manager.DataManager.Set(Consumers, 'Relations', 'Consumers', Relation.OwnerId, Relation.ConsumerId);
                    else
                        this.Manager.DataManager.Delete('Relations', 'Consumers', Relation.OwnerId, Relation.ConsumerId);
                }
                
                const Targets = Utils.Get(Relations, [], 'Targets', Relation.TargetId);
                if (Targets) {
                    Utils.RemoveEquals(Targets, RelationId);
                    if (Targets.length > 0)
                        this.Manager.DataManager.Set(Targets, 'Relations', 'Targets', Relation.TargetId);
                    else
                        this.Manager.DataManager.Delete('Relations', 'Targets', Relation.TargetId);
                }                
                
                this.Manager.DataManager.Delete('Relations', 'Data', RelationId);

                if (Relation.OwnerType === this.Types.COMPONENT) {
                    const Log = Globals.ProjectManager.HistoryManager.GetLastLog();
                    if (Log) {
                        if (!Log.Relations) 
                            Log.Relations = [];
                        Log.Relations.push({
                            Id : RelationId,
                            Type : 'Delete',
                            Data : Utils.DeepClone(Relation)
                        });
                    }
                }
            }
        }        
    }
    DeleteRelation(OwnerId, ConsumerId, TargetId, Options) {
        const Relations = this.Get();
        if (OwnerId) {
            if (this.Manager.Id === OwnerId) {
                const Log = Globals.ProjectManager.HistoryManager.GetLastLog();
                if (Log) {
                    if (!Log.Relation) {
                        Log.Relation = {
                            Consumer : Utils.DeepClone(this.Manager.DataManager.Get(null, 'Relations', 'Consumers', OwnerId)),
                            TargetId : TargetId,
                            Target : Utils.DeepClone(this.Manager.DataManager.Get(null, 'Relations', 'Targets', TargetId, 'Consumers', OwnerId))
                        };
                    }
                }
            }
            const Consumers = Utils.Get(Relations, null, 'Targets', TargetId, 'Consumers');
            if (Consumers) {                  
                if (Consumers[OwnerId]) {
                    let deleted = false;
                    if (Options) {
                        if (Options.TargetType === this.Types.ACTION) {
                            deleted = true;
                            const Consumer = Consumers[OwnerId][ConsumerId];                        
                            if (Consumer) {
                                Utils.RemoveEquals(Consumer.Events, Options.handlerName);
                                if (Consumer.Events) {
                                    if (Consumer.Events.length === 0) {
                                        this.Manager.DataManager.Delete('Relations', 'Targets', TargetId, 'Consumers', OwnerId, ConsumerId);
                                    }
                                    else {
                                        this.Manager.DataManager.Set(Consumer.Events, 'Relations', 'Targets', TargetId, 'Consumers', OwnerId, ConsumerId, 'Events');
                                    }
                                }
                            }                                        
                        }
                    }
                    if (!deleted)
                        this.Manager.DataManager.Delete('Relations', 'Targets', TargetId, 'Consumers', OwnerId, ConsumerId);                                        

                    let deleteConsumer = false;
                    if (Object.keys(Consumers[OwnerId]).length === 0)
                        deleteConsumer = true;
                    else if (Object.keys(Consumers[OwnerId]).length === 1 && Object.keys(Consumers[OwnerId])[0] === 'OwnerType')
                        deleteConsumer = true;
                    if (deleteConsumer) {
                        delete Consumers[OwnerId];
                        this.Manager.DataManager.Delete('Relations', 'Targets', TargetId, 'Consumers', OwnerId);
                    }                        
                }
                const Consumer = Utils.Get(Relations, null, 'Consumers', OwnerId, ConsumerId);
                if (Consumer) {
                    if (Array.isArray(Consumer)) {
                        if (Options) {
                            if (Options.TargetType === this.Types.ACTION) {
                                Utils.Remove(Consumer, (item) => {
                                    return item.Target === TargetId && item.event === Options.handlerName;
                                })
                            }
                        }
                        else 
                            Utils.RemoveEquals(Consumer, TargetId);
                        if (Consumer.length === 0) {
                            this.Manager.DataManager.Delete('Relations', 'Consumers', OwnerId);
                        }
                        else {
                            this.Manager.DataManager.Set(Relations.Consumers[OwnerId][ConsumerId], 'Relations', 'Consumers', OwnerId, ConsumerId);
                        }
                    }
                }            
            }
        }
        else {
            this.Manager.DataManager.Delete('Relations', 'Targets', TargetId, 'Consumers', ConsumerId);

            const Consumer = Utils.Get(Relations, null, 'Consumers', ConsumerId);
            if (Consumer) {
                if (Array.isArray(Consumer)) {
                    Utils.RemoveEquals(Consumer, TargetId);
                    if (Consumer.length === 0) {
                        this.Manager.DataManager.Delete('Relations', 'Consumers', ConsumerId);
                    }
                    else {
                        this.Manager.DataManager.Set(Relations.Consumers[ConsumerId], 'Relations', 'Consumers', ConsumerId);
                    }
                }
            }
        }
    }
}

export default RelationManager;