import React from 'react';
import ReactDOM from 'react-dom';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import styled from 'styled-components';
import ChromaJs from 'chroma-js';
import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { SetFontTypeFace } from '../../../../views/project/panels/left/designsystem/typography';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss, GetShadowTokenItem } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel, FigmaIcon_Style, FigmaIcon_Edit, Figma_PopupItem, FigmaStyleMenuPopup, FigmaIcon_DetachStyle } from '../../common';
import { GetColorTokenItem, GetcolorTokenValue, GetGradientTokenItem, GetColorTokenList } from '../../../../views/project/panels/left/designsystem/colors';
import { Seperator, PopupArrow } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import { TokenGroup, EditableName } from '../../../../views/project/panels/left/designsystem/common'
import NodeNameEditor from '../../../../views/project/panels/left/designsystem/common/nameeditor';
import TextInput from '../../../../components/editors/textinput';
import { SelectionItemBox, NodeCount, FigmaSelectionActions, FigmaSelection_CommonActions } from './newstyleitem';
import { OutsideCloseDotMenuContainer } from '../../../../views/project/panels/left/common';
import TextValue from '../../../../components/editors/textvalue';
import Switch from '../../../../components/editors/Switch';

export default class NewTokenStyleItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        };

        this.ShowNewTokenOption = this.ShowNewTokenOption.bind(this);

        this.onChangingTextContent = this.onChangingTextContent.bind(this);
        this.UpdateTextContentsThrottled = Utils.Throttle(this.UpdateNodeTexts, 500);
    }

    ShowNewTokenOption(e) {
        // if (e.button !== 2)
        //     return;
        this.setState({showToolbar : true})
    }
    SelectToken(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onSelectToken();
    }
    EditToken(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onEditToken && this.props.onEditToken();
    }
    DetachToken(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onDetachToken && this.props.onDetachToken();
    }
    RenameToken(e) {
        e.stopPropagation();
        this.setState({showToolbar : false, editingName : true});
    }
    onNameChange(name) {
        this.props.styleItem.name = name;
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.tokenId, name);
        this.setState({editingName : false});
    }
    SelectItems(select, e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onSelectItems(select);
    }
    DeleteItems(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onDeleteItems();
    }
    ZoomToItems(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onZoomToItems();
    }

    renderName(styleItem) {
        if (this.props.empty) {
            return (
                <SC.TextDivAbbr style={{flex : 1}}>{this.props.emptyTitle}</SC.TextDivAbbr>
            );
        }
        
        if (this.state.editingName){
            return (
                <TextInput 
                    placeholder='Token Name'
                    value={styleItem.name}
                    onChange={this.onNameChange.bind(this)}
                    onSubmit={this.onNameChange.bind(this)}
                    autoFocus
                    style={{
                        width : '120px',
                        fontSize : '11px',
                        marginRight : '8px',
                        flex : 1
                    }}
                />
            )
        }
        return (
            <SC.TextDivAbbr style={{flex : 1, marginRight : '16px'}}>{styleItem.name}</SC.TextDivAbbr>
        );
    }
    onChangingTextContent(value) {
        this.props.styleItem.value = value;
        this.UpdateTextContentsThrottled();
    }
    UpdateNodeTexts() {
        Globals.ProjectManager.Tokens.SetValue({id : this.props.tokenId, value : this.props.styleItem.value});

        Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
            Id : this.props.tokenId,
            Type : Globals.ProjectManager.Tokens.Types.ContentTexts,
            value : this.props.styleItem.value
        }]);
        // this.props.GetPluginApi().UpdateNodeTexts(this.props.styleItem.nodes, this.props.styleItem.value);
    }
    render() {
        const {type, styleItem, empty, count} = this.props;
        let content, toolbar;

        if (this.state.showToolbar) {
            toolbar = (
                <FigmaStyleMenuPopup onClose={() => {
                    this.setState({showToolbar : false})
                }}>
                    {
                        this.props.empty ?
                        <Figma_PopupItem onClick={this.SelectToken.bind(this)}>
                            <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                            <div style={{marginLeft : '10px'}}>Link to Token</div>
                        </Figma_PopupItem> : 
                        <React.Fragment>
                            <Figma_PopupItem onClick={this.SelectToken.bind(this)}>
                                <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                                <div style={{marginLeft : '10px'}}>Link to Another Token</div>
                            </Figma_PopupItem>
                            {
                                this.props.onDetachToken &&
                                <Figma_PopupItem onClick={this.DetachToken.bind(this)}>
                                    <FigmaIcon_DetachStyle style={{marginLeft : '2px', marginRight : '-2px', paddingTop : '4px', paddingBottom : '4px'}} />
                                    <div style={{marginLeft : '10px'}}>Detach Token</div>
                                </Figma_PopupItem>
                            }
                            <Figma_PopupItem onClick={this.EditToken.bind(this)} disabled={!this.props.hasEditGrant}>
                                <FigmaIcon_Edit style={{marginLeft : '2px', paddingTop : '4px', paddingBottom : '4px'}} />
                                <div style={{marginLeft : '10px'}}>Edit Token</div>
                            </Figma_PopupItem>
                            <Figma_PopupItem onClick={this.RenameToken.bind(this)} disabled={!this.props.hasEditGrant}>
                                <SC.Icons.Icon_Edit fill='#000' size={16}/>
                                <div style={{marginLeft : '10px'}}>Rename Token</div>
                            </Figma_PopupItem>
                        </React.Fragment>
                    }                        
                    <Seperator style={{marginTop : '4px', marginBottom : '4px'}} />
                    <FigmaSelection_CommonActions 
                        onSelect={this.SelectItems.bind(this, true)}
                        onDeSelect={this.SelectItems.bind(this, false)}
                        onZoom={this.ZoomToItems.bind(this)}
                        onDelete={this.DeleteItems.bind(this)}
                    />
                </FigmaStyleMenuPopup>
            );
        }

        if (type === TokenTypes.COLOR || type === TokenTypes.Gradients) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <FigmaColorBox 
                            style={styleItem.preview}
                            round
                            isAliase={styleItem.isAliase}
                        />
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.Shadows) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <div 
                            style={{
                                width : '20px',
                                height : '20px',
                                margin : '8px',
                                marginRight : '4px',
                                borderRadius : '2px',
                                ...styleItem.preview
                            }}
                        />
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.BorderRadiuses) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <div 
                            style={{
                               fontWeight : 'bold'
                            }}
                        >
                            {styleItem.style}
                        </div>
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.Borders) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <div 
                            style={{
                               fontWeight : 'bold'
                            }}
                        >
                            {styleItem.style}
                        </div>
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.TextPatterns) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    <div 
                        style={{                                
                            ...styleItem.preview,
                            lineHeight : '1.2em'
                            // fontSize : '14px',
                            // lineHeight : 'auto'
                        }}
                    >
                        Ag
                    </div>
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.ContentTexts) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                    style={{
                        flexDirection : 'column',
                        alignItems : 'stretch'
                    }}
                >
                    <SC.FRow alc>
                        <NodeCount active={this.state.showToolbar} title='Number of layers with this content'>{count}</NodeCount>
                        {this.renderName(styleItem)}    
                    </SC.FRow>                            
                    <TextValue 
                        multiline
                        value={styleItem.value}
                        boxStyle={{
                            marginTop : '8px'
                        }}
                        style={{
                            fontSize : '11px'
                        }}
                        onChanging={this.onChangingTextContent}
                        onMouseDown={(e) => {
                            e.stopPropagation();
                        }}
                    />          
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.Booleans) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <Switch value={styleItem.value} round />
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.SpacePatterns) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <div>
                            
                        </div>
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.FigmaAutoLayouts) {
            content = (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    {this.renderName(styleItem)}
                    {
                        !this.props.empty &&
                        <div>
                            
                        </div>
                    }                    
                    {toolbar}
                </SelectionItemBox>
            )
        }

        return (
            <React.Fragment>
                {content}                
            </React.Fragment>
        )
    }
}