import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { FlexDirectionPaths } from '../../../../views/project/panels/right/iteminspector/styleitems/layout';
import { FigmaLogo } from '../..';

// views/project/panels/right/iteminspector/selectToken';

export default class LocalStyleTokenMapItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() {
        const {item} = this.props;
        let content;

        const CopyStyle_FromToken = (
            <SC.Icons.Icon_ButtonBox dark={!this.props.header} hasFill hasCursor 
                style={{marginRight : '4px', marginLeft : '2px'}}                 
            >
                <SC.Icons.Arrow_Back expanded style={{paddingBottom : 0}}/>
            </SC.Icons.Icon_ButtonBox>
        )
        const props_fromToken = {
            alc : true,
            title : this.props.header ? 'Update All Figma Styles from Tokens' : 'Update Figma Style',
            onClick : this.props.onUpdateFigma
        }
        const CopyStyle_FromFigma = (
            <SC.Icons.Icon_ButtonBox dark={!this.props.header} hasFill hasCursor 
                style={{marginLeft : '4px', marginRight : '2px'}}                 
            >
                <SC.Icons.Arrow_Back />
            </SC.Icons.Icon_ButtonBox>
        );        
        const props_fromFigma = {
            alc : true,
            title : this.props.header ? 'Update All Tokens from Figma' : 'Update Token from Figma',
            onClick : this.props.onUpdateToken
        }


        if (this.props.header) {
            let title = 'COLORS';
            if (this.props.type === TokenTypes.Gradients)
                title = 'GRADIENTS';
            else if (this.props.type === TokenTypes.Shadows)
                title = 'SHADOWS';
            else if (this.props.type === TokenTypes.TextPatterns)
                title = 'TEXT STYLES';
            else if (this.props.type === 'Names')
                title = 'TOKEN NAMES';

            return (
                <SC.FRow f1 alc jsb
                    style={{
                        padding : '4px',
                        paddingRight : '10px',
                        ...this.props.style
                    }}
                >
                    <SC.TextDivAbbr style={{flex : 1,}}>{title}</SC.TextDivAbbr>
                    <SC.FRow alc>
                        <SC.FRow {...props_fromToken}>
                            <div>Tokens</div>
                            {CopyStyle_FromToken}
                        </SC.FRow>                        
                        <SC.FRow {...props_fromFigma}>
                            {CopyStyle_FromFigma}
                            <SC.Div_Flex_Cell style={{minWidth : '24px'}}>
                                <FigmaLogo />                                 
                            </SC.Div_Flex_Cell>          
                        </SC.FRow>                        
                    </SC.FRow>
                </SC.FRow>
            )
        }
        else {
            const style_modified = {color : 'red'};

            if (this.props.type === TokenTypes.COLOR || this.props.type === TokenTypes.Gradients) {
                let value_token, value_figma;
                if (this.props.type === TokenTypes.COLOR) {
                    value_token = item.token.color;
                    value_figma = item.figma.color;
                }
                else {
                    value_token = item.token.css;
                    value_figma = item.figma.css;
                }
                return (
                    <SC.FRow f1 alc jsb 
                        style={{
                            padding : '4px',
                            paddingLeft : '10px',
                            paddingRight : '10px',
                            borderBottom : '1px solid',
                            borderColor : SC.CurrentTheme.theme.back,
                            ...style_modified
                        }}
                    >
                        <SC.TextDivAbbr style={{flex : 1}}>{item.token.name}</SC.TextDivAbbr>
                        <SC.FRow alc>
                            <SC.FRow {...props_fromToken}>
                                <FigmaColorBox  color={value_token} />                                
                                {CopyStyle_FromToken}
                            </SC.FRow>
                            <SC.FRow {...props_fromFigma}>
                                {CopyStyle_FromFigma}
                                <FigmaColorBox round color={value_figma}/>    
                            </SC.FRow>
                        </SC.FRow>
                    </SC.FRow>
                )
            }
            else if (this.props.type === TokenTypes.Shadows) {               
                return (
                    <SC.FRow f1 alc jsb 
                        style={{
                            padding : '14px',
                            paddingLeft : '10px',
                            paddingRight : '10px',
                            borderBottom : '1px solid',
                            borderColor : SC.CurrentTheme.theme.back,
                            ...style_modified
                        }}
                    >
                        <SC.TextDivAbbr style={{flex : 1}}>{item.token.name}</SC.TextDivAbbr>
                        <SC.FRow alc style={{width : '120px', boxSizing : 'border-box', paddingLeft : '10px', paddingRight : '4px'}}>
                            <SC.FRow {...props_fromToken}>
                                <div 
                                    style={{
                                        boxShadow : item.token.css,
                                        width : '20px',
                                        height : '20px',
                                        borderRadius : '2px',
                                        marginRight : '9px'
                                    }}
                                />
                                {CopyStyle_FromToken}
                            </SC.FRow>
                            
                            <SC.FRow {...props_fromFigma}>
                                {CopyStyle_FromFigma}
                                <div 
                                    style={{
                                        boxShadow : item.figma.css,
                                        width : '20px',
                                        height : '20px',
                                        borderRadius : '2px'
                                    }}
                                />
                            </SC.FRow>                            
                        </SC.FRow>
                    </SC.FRow>
                )
            }
            else if (this.props.type === TokenTypes.TextPatterns) {               
                const style_family = Utils.JustGet(item.token.textFigmaStyle.fontName, '', 'family') !== item.figma.figmaText.fontFamily ? {color : 'red'} : {};
                const style_fontSize = (item.token.textFigmaStyle.fontSize !== item.figma.figmaText.fontSize) ? {color : 'red'} : {};
                const style_lineHeight = (item.token.textFigmaStyle.lineHeight !== item.figma.figmaText.lineHeight) ? {color : 'red'} : {};
                const figmsLetterSpacing = Utils.px(item.figma.figmaText.letterSpacing, item.figma.figmaText.letterSpacingUnit);
                const style_letterSpacing = (item.token.textStyle.letterSpacing !== figmsLetterSpacing) ? {color : 'red'} : {};

                return (
                    <SC.FCol f1
                        style={{
                            padding : '14px',
                            paddingLeft : '10px',
                            paddingRight : '10px',
                            borderBottom : '1px solid',
                            borderColor : SC.CurrentTheme.theme.back
                        }}
                    >
                        <SC.FRow alc jsb>
                            <SC.TextDivAbbr style={{flex : 1}}>{item.token.name}</SC.TextDivAbbr>    
                            <SC.FRow alc style={{width : '120px'}}>
                                <SC.FRow {...props_fromToken}>
                                    <div 
                                        style={{
                                            width : '20px',
                                            height : '20px',
                                            borderRadius : '2px',
                                            marginRight : '9px',
                                            flex : 1,
                                            ...item.token.textStyle,
                                            fontSize : '18px',
                                            lineHeight : 'unset'
                                        }}
                                    >
                                        Ag
                                    </div>
                                    {CopyStyle_FromToken}
                                </SC.FRow>
                                <SC.FRow {...props_fromFigma}>
                                    {CopyStyle_FromFigma}
                                    <div 
                                        style={{
                                            flex : 1,
                                            fontSize : Utils.px(item.figma.figmaText.fontSize),
                                            fontFamily : item.figma.figmaText.fontFamily,
                                            fontWeight : item.figma.figmaText.fontWeight,
                                            lineHeight : Utils.px(item.figma.figmaText.lineHeight),
                                            letterSpacing : Utils.px(item.figma.figmaText.letterSpacing, item.figma.figmaText.letterSpacingUnit),
                                            marginLeft : '8px',
                                            fontSize : '18px',
                                            lineHeight : 'unset'
                                        }}
                                    >
                                        Ag
                                    </div>
                                </SC.FRow>                                
                            </SC.FRow>
                        </SC.FRow>                        
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : '1fr 60px 60px',
                            gridGap : '8px',
                            justifyItems : 'start',
                            alignItems : 'baseline',
                            marginTop : '8px'
                        }}>
                            {
                                Utils.JustGet(item.token.textStyle, '', 'fontFamily') !== item.figma.figmaText.fontFamily &&
                                <React.Fragment>
                                    <div style={style_family}>Font Family</div>
                                    <div style={{justifySelf : 'end', ...style_family}}>{Utils.JustGet(item.token.textStyle, '', 'fontFamily')}</div>
                                    <div style={style_family}>{item.figma.figmaText.fontFamily}</div>
                                </React.Fragment>
                            }
                            {
                                Utils.JustGet(item.token.textStyle, '', 'fontWeight') !== item.figma.figmaText.fontWeight && 
                                <React.Fragment>
                                    <div style={style_family}>Font Weight</div>
                                    <div style={{justifySelf : 'end', ...style_family}}>{Utils.JustGet(item.token.textStyle, '', 'fontWeight')}</div>
                                    <div style={style_family}>{item.figma.figmaText.fontWeight}</div>
                                </React.Fragment>
                            }
                            {
                                item.token.textFigmaStyle.fontSize !== item.figma.figmaText.fontSize && 
                                <React.Fragment>
                                    <div style={style_fontSize}>Font Size</div>
                                    <div style={{justifySelf : 'end', ...style_fontSize}}>{item.token.textFigmaStyle.fontSize}</div>
                                    <div style={style_fontSize}>{item.figma.figmaText.fontSize}</div>
                                </React.Fragment>
                            }
                            {
                                item.token.textFigmaStyle.lineHeight !== item.figma.figmaText.lineHeight && 
                                <React.Fragment>
                                    <div style={style_lineHeight}>Line Height</div>
                                    <div style={{justifySelf : 'end', ...style_lineHeight}}>{item.token.textFigmaStyle.lineHeight}</div>
                                    <div style={style_lineHeight}>{item.figma.figmaText.lineHeight}</div>
                                </React.Fragment>
                            }
                            {
                                item.token.textStyle.letterSpacing !== figmsLetterSpacing && 
                                <React.Fragment>
                                    <div style={style_letterSpacing}>Letter Spacing</div>
                                    <div style={{justifySelf : 'end', ...style_letterSpacing}}>{item.token.textStyle.letterSpacing}</div>
                                    <div style={style_letterSpacing}>{figmsLetterSpacing}</div>       
                                </React.Fragment>
                            }
                        </div>                                               
                    </SC.FCol>
                )
            }
        }
        return null;
    }   
}



export const FigmaColorBox = ({color, isAliase, style, round}) => {
    return (
        <SC.GridBackground 
            small 
            dark={!SC.CurrentTheme.theme.isLight} 
            style={{
                // height : '100%', 
                boxSizing : 'border-box',
                borderRadius : round ? '99px' : '2px', 
                cursor : 'pointer',                        
                border : SC.CurrentTheme.theme.border_seperator,
                borderWidth : '2px',
                position : 'relative'
            }}
        >
            <div 
                style={{background : color, ...style, zIndex : 2, minWidth : round ? '20px' : '28px', minHeight : '20px', height : '100%', borderRadius : round ? '99px' : '2px'}} 
            />
            {
                isAliase &&
                <div style={{
                    position : 'absolute',
                    top : '-4px', left : '-20px'
                }}>
                    <SC.Icons.Arrow_Back expanded />
                </div>
            }
        </SC.GridBackground>
    )
}