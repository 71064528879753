import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Loading,
    UIUtils,
    Strings
} from '../../../../../../importer';
import styled, {css} from 'styled-components';
import PlayButton from '../motion/easing/playbutton';


let lastplaying = null;


class PlaySound extends React.Component {
    constructor(props) {
        super(props);

        this.onMouseEnter = this.onMouseEnter.bind(this);
        this.onMouseLeave = this.onMouseLeave.bind(this);
    }
    onMouseEnter(e) {
        this.mouseover = true;
        setTimeout(() => {
            if (this.mouseover)
                this.props.onPlay();
        }, 100);
    }
    onMouseLeave(e) {
        this.mouseover = false;
    }
    render() {
        const props = this.props;

        if (props.playButton) {
            return (
                <SC.Div_Flex_Cell>
                    <PlayButton empty={props.empty} onClick={props.onPlay} playing={props.playing} />
                </SC.Div_Flex_Cell>
            )
        }
        
        return (
            <div onClick={props.onClick || props.onPlay} style={{...props.style, cursor : 'pointer', padding : '8px', paddingTop : '4px', paddingBottom : '4px', borderRadius : '2px', backgroundColor : SC.CurrentTheme.theme.back, border : SC.CurrentTheme.theme.border_seperator }} title='Click to play'>
                <SC.FRow jsb alc>
                    <div>{props.name}</div>
                    <SC.Icons.Icon_Sound small={!props.xsmall} xsmall={props.xsmall} playing={props.playing} />
                </SC.FRow>
            </div>
        )
    }
}


export const HoC_SoundPlayer = (WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.Play = this.Play.bind(this);
            this.Stop = this.Stop.bind(this);
            this.REf_Sound = React.createRef();
            this.state = {};
            this.onMouseEnter = this.onMouseEnter.bind(this);
            this.onMouseLeave = this.onMouseLeave.bind(this);
        }
        Play(e) { 
            e && e.stopPropagation();
            e && e.preventDefault();

            if (lastplaying === this)
                return;
            if (lastplaying) {
                lastplaying.Stop();
            }
            lastplaying = this;
            if (this.state.Loaded) {
                this.REf_Sound.current && this.REf_Sound.current.play();
            }
            else {
                this.setState({Loaded : true}, () => {
                    this.REf_Sound.current && this.REf_Sound.current.play();
                });
            }
            
            this.props.onUpdate && this.props.onUpdate(true);
        }
        Stop() {        
            this.REf_Sound && this.REf_Sound.current && this.REf_Sound.current.pause();     
            this.onEnded();
        }
        onEnded() {
            lastplaying = null;        
            this.props.onUpdate && this.props.onUpdate(false);
            this.setState({Loaded : false});
        }
        componentDidUpdate(prevProps, prevState) {
            if (!prevState.Loaded && this.state.Loaded && this.REf_Sound && this.REf_Sound.current)
                this.REf_Sound.current.play();
        }
        onMouseEnter(e) {
            this.mouseover = true;
            setTimeout(() => {
                if (this.mouseover) {
                    this.Play();
                }                    
            }, 200);
        }
        onMouseLeave(e) {
            this.mouseover = false;
        }
        render(){            
            return (
                <div onClick={this.Play}>
                    {
                    this.state.Loaded ? <audio ref={this.REf_Sound} 
                        onEnded={this.onEnded.bind(this)}
                        src={this.props.sound || process.env.PUBLIC_URL + `/assets/sounds/${this.props.type}/${this.props.fileName}.m4a`} preload="metadata" type="audio/mp3"/> : null
                    }
                    <WrappedComponent {...this.props} onPlay={this.Play} onStop={this.Stop} playing={this.state.Loaded} />
                </div>
            );
        }
    }
}

export default HoC_SoundPlayer(PlaySound);