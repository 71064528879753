import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import { GetSubItems } from '../../Container';
import Radium from 'radium';

class Generic_Selectbox extends BaseItem
{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
    }    

    onChange(value, e) {
        const modelid = Utils.JustGet(this.StateStatics, null, 'variables_current', 'value');
        if (modelid) 
            this.props.onSetModelValue(modelid, value);        
    }
    renderItem({style, props, events, rest}) {
        let value = Utils.IsTrue(props.value);
        
        let options = [
            {value : '1', label : 'Option 1'},
            {value : '2', label : 'Option 2'},
            {value : '3', label : 'Option 3'},
            {value : '4', label : 'Option 4'}
        ]
        

        return (
            <select 
                ref={(r) => this.Ref_Instance = r}
                style={{
                    fontFamily : 'unset',
                    fontSize : 'unset',
                    ...style
                }} 
                value={value} 
                placeholder={props.placeholder}                 
                type='checkbox' 
                onChange={this.onChange} 
                events={events}                                
                {...rest}
                readOnly={this.props.IsStateActive('readonly', 'readonly')}
                disabled={this.props.IsStateActive('disabled', 'disabled')}
            >
                {
                    options.map((option, i) => {
                        return (
                            <option key={i} value={option.value}>{option.label || option.value}</option>
                        )
                    })
                }
            </select>
        );  
    }
}

export default Radium(Generic_Selectbox);