import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Strings,
    Globals
} from '../../../../../importer';

import { MetaData, Toolabs_Engine } from '../../../../../toolabs-importer';
import styled from 'styled-components';
import DragSource from '../../../../../components/dragdrop/HoCDragSource';
import {TokenGroup, GroupTitle, TokenPanelHeader, EditableName} from '../designsystem/common';
import { ListCardView } from '../../../../../components/light_dark_bar';
// import ComponentItem from './item';
import { LeftScrollPanel } from '../common';
import { motion, AnimatePresence } from 'framer-motion';
import NewComponentWizard from './wizard';
import { Seperator } from '../../right/iteminspector/styleitems/common';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { GetGroupedTokenListWithGroups } from '../designsystem/BaseTokenList';
import { ComponentListInstances } from './listInstances';


class DesignSystemComponentsPanel extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isListView : Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'Components', 'ListView'),
            isGroupView : Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'Components', 'GroupView'),
        }

        this.GetComponentMetaData = this.GetComponentMetaData.bind(this);
        this.OnDragEnd = this.OnDragEnd.bind(this);
        this.AddComponent = this.AddComponent.bind(this);
        this.AddGroup = this.AddGroup.bind(this);
        this.CancelWizard = this.CancelWizard.bind(this);
        this.Select = this.Select.bind(this);

        this.Search = this.Search.bind(this);
        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.onDragEndItem = this.onDragEndItem.bind(this);
        this.ToggleGroupView = this.ToggleGroupView.bind(this);
        this.state.expanded = !this.props.expanable || Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Components', 'Expanded');

        this.Id = Utils.Id();
        ComponentListInstances[this.Id] = this;
        this.Load();

        AppLayout.Refs.DesignSystem.Components = this;
    }
    componentWillUnmount() {

        delete ComponentListInstances[this.Id];
    }
    Load() {        
        const folders = Globals.ProjectManager.GetComponentGroups();
        const components = Globals.ProjectManager.GetComponentModels();
        this.allComponents = [];
        Utils.ForEach(components, (item, id) => {
            let skip = false;
            if (this.props.ExcludeComponents && this.props.ExcludeComponents.indexOf(id) > -1)
                skip = true;

            if (!skip) {
                this.allComponents.push({
                    id : id,
                    name : item.name
                })
            }            
        });
        const willAddToDefault = [];
        this.folders = GetGroupedTokenListWithGroups(folders, this.allComponents, willAddToDefault);        

        if (willAddToDefault.length > 0) {
            Globals.ProjectManager.AddComponentsToGroup({groupid : 'Default', components : willAddToDefault});
        }
    }
    onToggleExpand() {
        const expanded = !this.state.expanded;
        Globals.ProjectManager.Options.Set(expanded, 'LeftPanel', 'DesignSystem', 'Components', 'Expanded');
        this.setState({
            expanded : expanded
        })
    }  
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'Components', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    AddComponent() {
        if (this.props.onAdd)
            this.props.onAdd();
        else
            this.setState({newComponent : true})
    }
    CancelWizard() {
        this.setState({newComponent : false})
    }
    OnDragEnd() {
        setTimeout(() => {
            this.props.onClose && this.props.onClose();
        }, 50);        
    }
    renderWizard() {
        return (            
            <NewComponentWizard 
                onClose={this.CancelWizard}
                onAdded={(Id) => {
                    this.NewComponentId = Id;
                    this.setState({newComponent : false});
                }}
                OnDragEnd={this.OnDragEnd}
            />
        )
    }
    Search(filter) {
        this.setState({filter : filter})
    }
    Select(id) {
        this.props.onSelect && this.props.onSelect(id);
    }
    ToggleGroupView() {
        Globals.ProjectManager.Options.Set(!this.state.isGroupView, 'LeftPanel', 'Components', 'GroupView');
        this.setState({
            isGroupView : !this.state.isGroupView
        });
        this.UpdateInstances();
    }
    UpdateInstances() {
        Utils.ForEach(ComponentListInstances, (Instance, InstanceId) => {
            if (InstanceId !== this.Id) {
                Instance.Reload();
            }
        });
    }
    Reload() {
        this.Load();
        this.setState({
            isListView : Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'Components', 'ListView'),
            isGroupView : Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'Components', 'GroupView'),
        })        
    }
    AddGroup() {
        const name = 'New Group';
        const groupid = Globals.ProjectManager.AddComponentGroup({name : name})                
        this.folders.push({
            id : groupid,
            name : name,
            tokens : []
        })

        this.RCUpdate();
    }
    onDragEndItem(result) {
        const { source, destination } = result;
        if (!destination) {
            return;
        }
        if (result.type === 'FOLDERS') {
            Globals.ProjectManager.ChangeOrderOfGroups(source.index, destination.index);
        }
        else {
            if (source.droppableId === destination.droppableId) {
                Globals.ProjectManager.ChangeFolderComponentOrder(destination.droppableId, destination.droppableId, source.index, destination.index);                
            } 
            else {
                Globals.ProjectManager.ChangeFolderComponentOrder(source.droppableId, destination.droppableId, source.index, destination.index);
            }     
        }
        this.Load();
        this.RCUpdate();
        this.UpdateInstances();
    }
    RCUpdate() {
        this.setState({ShouldUpdate : true});
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    GetComponentMetaData(Id) {
        return Globals.ProjectManager.Publisher.Component(Id);
    }
    render() { 
        let newComponentItem;
        let items = [];

        let filterText = this.props.filterText || this.state.filter;        
        if (Utils.IsNotNullOrEmpty(filterText))
            filterText = filterText.toUpperCase();

        let isEmpty = true;

        if (this.state.newComponent) {
            
        }
        else {            
            if (this.NewComponentId) {
                newComponentItem = (
                    <SC.FCol>
                        <ComponentItem 
                            componentId={this.NewComponentId}
                            item={{name : 'New Component'}}
                            listView={this.state.isListView}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            AutoScale
                            Preview
                            width={300}
                        />
                        <Seperator />
                    </SC.FCol>
                )   
            }            

            const ComponentModels = Globals.ProjectManager.GetComponentModels();
            
            Utils.ForEach(ComponentModels, (item, id) => {
                isEmpty = false;
                let filtered = false;
                if (filterText) {
                    if (item.name.toUpperCase().indexOf(filterText) < 0) {                    
                        filtered = true;
                    }
                }
                if (!filtered && this.props.ExcludeComponents && this.props.ExcludeComponents.indexOf(id) > -1) {
                    filtered = true;
                }
                if (!filtered) {
                    items.push(
                        <ComponentItem 
                            key={id}
                            id={id}                 
                            name={item.name}
                            Store={this}
                            onScrollToNode={this.ScrollToNode}
                            onDeleted={this.onComponentDeleted}
                            listView={this.state.isListView && !this.props.justItems}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            OnDragEnd={this.OnDragEnd}
                            compact
                            draggable={!this.props.selectable}
                            onSelect={this.Select.bind(this, id)}
                            notSortable={!this.state.isGroupView}
                        />
                    )
                }                               
            });
        }

        if (this.props.justItems) {
            if (this.props.renderEmpty && isEmpty)
                return this.props.renderEmpty();
            return items;
        }
        
        
        if (this.state.isGroupView) {
            const folderItems = [];
            Utils.ForEach(this.folders, (folder, i) => {
                folderItems.push(
                    <ComponentFolder 
                        index={i}
                        key={folder.id}
                        folder={folder}
                        filterText={filterText}
                        isListView={this.state.isListView}
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        draggable={!this.props.selectable}
                        onSelect={this.Select}
                        Store={this}
                        ExcludeComponents={this.props.ExcludeComponents}
                    />
                )
            });

            items = (
                <DragDropContext
                    onDragEnd={this.onDragEndItem}
                >                        
                    <Droppable 
                        droppableId='Folders'
                        type='FOLDERS'
                    >
                        {
                            provided => (
                                <div
                                    {...provided.droppableProps} 
                                    ref={provided.innerRef} 
                                    style={{
                                        ...provided.droppableProps.style,
                                        ...SC.Styles.Flex.Column
                                    }}
                                >
                                    {
                                        folderItems 
                                    }
                                    {provided.placeholder}
                                </div>
                            )
                        }    
                    </Droppable>                    
                </DragDropContext>
            )

        }
        else {

        }

        const header = (
            <GroupTitle 
                title='COMPONENTS' 
                hideTitle={this.props.designsystem}
                hasAdd={AppLayout.Refs.PanzoomCanvas ? {onAdd : this.state.isGroupView ? this.AddGroup : this.AddComponent, groupView : this.state.isGroupView} : false}
                hasGroup={{onToggle : this.ToggleGroupView, selected : this.state.isGroupView}}
                closable={!this.props.justContent || this.props.closable}
                expandable={this.props.expandable ? {
                    onExpand : this.onToggleExpand,
                    expanded : this.state.expanded
                } : false}
                onClose={this.props.onClose}
                hasSearch={{
                    onSearch : this.Search,
                    style : {marginLeft : '8px', marginRight : '8px', marginTop : '4px'}
                }}
                addOn={(
                    <ListCardView 
                        onListView={this.SetListMode.bind(this, true)}
                        onCardView={this.SetListMode.bind(this, false)}
                        isListView={this.state.isListView}
                        isCardView={!this.state.isListView}
                        style={{marginRight : '4px'}}
                        compact
                    />
                )}                    
            />  
        );

        const wizardItem = (
            <AnimatePresence>
                {
                    this.state.newComponent && 
                    <motion.div 
                        style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                        initial={{opacity : 0.7, x : 24}}
                        animate={{opacity : 1, x : 0}}
                        exit={{opacity : 0, x : 24}}
                        transition={{duration : 0.2}}
                    >                                                 
                        {this.renderWizard()}
                    </motion.div>
                }
            </AnimatePresence>
        )

        if (this.props.justContent) {
            return (
                <React.Fragment>
                    {this.props.children}
                    {header}
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : 'repeat( auto-fit, minmax(160px, 1fr) )',
                        gridAutoRows : 'auto',
                        padding : this.state.expanded ? '8px' : 0,
                        paddingBottom : '8px',
                        paddingBottom : 0,
                    }}>
                        {(this.state.expanded || !this.props.expandable) && items}
                    </div>                        
                    {wizardItem}
                </React.Fragment>
            )
        }
        if (this.props.designsystem) {
            return (
                <React.Fragment>
                    {this.props.children}
                    {header}
                    <LeftScrollPanel>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : '1fr',
                            gridAutoRows : 'auto',
                            padding : this.state.expanded ? '8px' : 0,
                            paddingTop : 0,
                            paddingBottom : 0,
                        }}>
                            {(this.state.expanded || !this.props.expandable) && items}
                        </div>
                    </LeftScrollPanel>
                                            
                    {wizardItem}
                </React.Fragment>
            )
        }        

        return (            
            <SC.FCol f1         
                style={{
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    position : 'relative'
                }}       
            >
                {header}      
                <SC.FCol fw fh
                    style={{
                        backgroundColor : SC.CurrentTheme.theme.back
                    }}       
                >
                    <LeftScrollPanel>                        
                        {newComponentItem}
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : '1fr',
                            gridAutoRows : 'auto',
                            gridGap : '8px',
                            padding : '8px',
                            paddingBottom : 0,
                        }}>
                            {items}
                        </div>                        
                    </LeftScrollPanel> 
                </SC.FCol>                            
                {wizardItem}
            </SC.FCol>
        )        
    }
}
 
export default DesignSystemComponentsPanel;


class ComponentFolder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            expanded : !this.props.folder.collapsed
        }

        this.AddPage = this.AddPage.bind(this);        
        this.SaveName = this.SaveName.bind(this);        
        this.ToggleExpand = this.ToggleExpand.bind(this);
    }    
    SaveName(name) {
        this.props.folder.title = name;
        Globals.ProjectManager.ChangeGroupProp({id : this.props.folder.id, name : 'name', value : name});
        this.Update();
    }
    AddPage() {
       
    }          
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    Update() {
        this.setState({ShouldUpdate : true})
    }
    ToggleExpand() {
        Globals.ProjectManager.ChangeGroupProp({id : this.props.folder.id, name : 'collapsed', value : this.state.expanded});
        this.setState({expanded : !this.state.expanded})
    }
    Select(id) {
        this.props.onSelect(id);
    }
    render() { 
        let itemIndex = 0;

        const items = [];

        this.state.expanded && this.props.folder.tokens && this.props.folder.tokens.map(({id, name}, i) => {
            let filtered = false;
            if (this.props.filterText) {
                if (name.toUpperCase().indexOf(this.props.filterText) < 0) {                    
                    filtered = true;
                }
            }
            if (!filtered && this.props.ExcludeComponents && this.props.ExcludeComponents.indexOf(id) > -1) {
                filtered = true;
            }
            if (!filtered) {                                                        
                items.push(
                    <ComponentFolderItem
                        index={itemIndex}
                        key={id}
                        id={id}
                        name={name}
                        listView={this.props.isListView}
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        draggable={this.props.draggable}
                        onSelect={this.props.draggable ? null : this.Select.bind(this, id)}
                        Store={this.props.Store}
                    />
                );
                itemIndex += 1;
            }
        })
        if (this.props.filterText) {
            if (items.length === 0) 
                return null;
        }
        return (  
            <Draggable key={this.props.folder.id}  draggableId={this.props.folder.id} index={this.props.index}>                
                {
                    (provided, snapshot) => (
                        <div {...provided.draggableProps} style={{...provided.draggableProps.style, ...SC.Styles.Flex.Column, marginBottom : '8px',                            
                        }} ref={provided.innerRef}>
                            <SC.HoverParent defaultStyle={{marginBottom : '-2px', ...SC.Styles.Flex.RowAlcJsb, position : 'relative'}}>
                                <SC.HoverChild defaultStyle={{  ...SC.Styles.Flex.Cell, cursor : 'move', position : 'absolute'}}>
                                    <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, cursor:'move', height : 'unset', marginLeft : 0, border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                        <SC.Icons.DragIcon xsmall />
                                    </SC.DragBox>
                                </SC.HoverChild>
                                <FolderName onClick={this.ToggleExpand}>
                                    <SC.Icons.Icon_Button hasFill hasCursor>
                                        <SC.Icons.Icon_Arrow_Filled expanded={this.state.expanded} size={20} />
                                    </SC.Icons.Icon_Button>
                                    <EditableName 
                                        value={this.props.folder.name} 
                                        onSave={this.SaveName} 
                                        notDeletable
                                        style={{fontSize : '12px', fontWeight : 400, height : '24px', ...SC.Styles.Flex.RowAlcJsb}}
                                    /> 
                                </FolderName>
                                
                                <SC.HoverChild>
                                    <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '8px', marginRight : '4px'}} onClick={this.props.onDeleteFolder}>
                                        <SC.Icons.Icon_Delete size={16} />
                                    </SC.Icons.Icon_Button>
                                </SC.HoverChild>
                            </SC.HoverParent>
                            <Droppable 
                                droppableId={this.props.folder.id}
                                type={'COMPONENTS'}
                            >
                                {
                                    (provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps} 
                                            ref={provided.innerRef} 
                                            style={{
                                                ...provided.droppableProps.style,
                                                minHeight : (snapshot.isDraggingOver || this.state.expanded) ? '16px' : 0,
                                                border : '1px dashed',                                                    
                                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',
                                                backgroundColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.back_lightest : 'transparent'
                                            }}
                                        >
                                            {items}  
                                            {provided.placeholder}
                                        </div>
                                    )
                                }
                            </Droppable>                          
                        </div>
                    )
                }
            </Draggable>          
        );
    }
}
 
class ComponentFolderItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SaveName = this.SaveName.bind(this);
        this.DeletePage = this.DeletePage.bind(this);        
    }
    SaveName(name) {
        this.props.folder.title = name;
        this.props.onSaveName(name);
        this.Update();
    }
    Update() {
        this.setState({ShouldUpdate : true})
    }
    DeletePage(e) {
        e.stopPropagation();
        this.props.onDeletePage();
    }   
    render() { 
        return (  
            <Draggable key={this.props.id}  draggableId={this.props.id} index={this.props.index}>
                {
                    (provided, snapshot) => {
                        return (
                            <div {...provided.draggableProps} style={{...provided.draggableProps.style}} ref={provided.innerRef}>
                                <ComponentItem {...this.props} dragHandleProps={provided.dragHandleProps} Store={this.props.Store} />
                            </div>                                                       
                        )                                          
                    }
                }
            </Draggable>   
            
        );
    }
}

const ComponentItem = (props) => {
    const style_name = {};
    if (props.notSortable)
        style_name.transform = 'none';
    return (
        <DraggableComponentItemBox 
            style={{
                ...SC.Styles.Flex.Column,
                alignItems : 'center',
                marginTop : '4px',
                cursor : props.draggable ? 'move' : 'pointer',
                padding : '8px'
            }}
            DragItem={props.draggable ? {
                Type: Strings.TOOLBARITEM,
                Item: {
                    MetaItem : {
                        Type: MetaData.Components.Component.Type,
                        Id: props.id
                    }
                }
            } : null}
            onClick={props.onSelect} 
        >       
            <div style={{flex : 1, display: 'flex', minHeight : '28px', maxHeight: '28px', alignSelf : 'stretch', ...SC.Styles.Flex.RowAlcJsb, margin : '-8px', marginBottom : props.listView ? '-8px' :'8px', position : 'relative'}}>
                {
                    props.dragHandleProps &&
                    <ComponentItemHover {...props.dragHandleProps} style={{zIndex : 10, ...props.dragHandleProps.style, width : '28px', ...SC.Styles.Flex.Cell, position : 'absolute', top : 0, left : 0, bottom : 0}} >
                        <SC.DragBox  first style={{cursor : 'inherit', height : 'unset', marginLeft : '4px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                            <SC.Icons.DragIcon xsmall style={{cursor:'inherit'}}/>
                        </SC.DragBox>
                    </ComponentItemHover> 
                }                
                <ComponentItemName style={style_name}>
                    {props.name}
                </ComponentItemName>                                        
            </div>
            {
                !props.listView && 
                <Toolabs_Engine.Component
                    Id={props.id}
                    Store={props.Store}
                    width={200}
                    height={60}
                    Preview
                    Disabled
                    AutoScale
                    ThemeId={Globals.ProjectManager.CurrentTheme.ThemeId}
                    ActiveTheme={{...Globals.ProjectManager.CurrentTheme, Theme : Utils.JustGet(Globals.ProjectManager.CurrentTheme.Theme, {}, Globals.ProjectManager.CurrentState)}}
                    GlobalVariations={props.GlobalState}
                    PreviewTokens={props.PreviewTokens}
                    backgroundColor={props.backgroundColor}                                                            
                />
            }                                    
        </DraggableComponentItemBox> 
    )
}


export const ComponentItemBox = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    padding : 4px;
    align-items : center;
    overflow : hidden;       
    transition : all 0.3s ease;
    border-radius : 2px;
    font-size : 11px;
    border : ${props => props.theme.isLight ? '1px solid #f0f0f000' : props.theme.border_seperator};
    background-color : ${props => props.theme.back_lighter};
    padding : 4px;
    cursor : move;
    &: hover{
        border : ${props => props.theme.isLight ? props.theme.border_ondark : props.theme.border_seperator};
        background-color : ${props => props.theme.back_lightest};
        color : ${props => props.theme.font_hover};
    }
`;

const ComponentItemHover = styled.div`
    opacity : 0;
    transition : opacity 0.2s ease;
    transition-delay : 0.1s;
    ${ComponentItemBox}:hover &{
        opacity : 1;
    }
`;

const ComponentItemName = styled.div`
    padding-left : 8px;
    transition : transform 0.2s ease;
    transition-delay : 0.1s;
    ${ComponentItemBox}:hover &{
        transform : translateX(16px);
    }
`;

const FolderName = styled.div`
    transition : transform 0.2s ease;
    transition-delay : 0.1s;
    transform : translateX(0px);
    overflow: hidden;
    display : flex;
    ${SC.HoverParent}:hover &{
        transform : translateX(12px);
    }
`;

export const DraggableComponentItemBox = DragSource(ComponentItemBox);