import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;
    
    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 200,
            height : 40
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 40
            },                
            'width': {
                'value': 200
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['System', 'disabled', 'hover'],        
        System : {
            name : 'System State',
            Variations : {
                Order : ['focus', 'active'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            name : 'Hover',
            pseudo : true
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            'Value': {
                dataType: 'number',
                name: 'Value',
                value : 0
            },
            min : {
                dataType: 'number',
                name: 'Min',
                value : 0
            },
            max : {
                dataType: 'number',
                name: 'Max',
                value : 100
            },
            step : {
                dataType: 'number',
                name: 'Step',
                value : 1
            }
        },
        Order: ['Value', 'min', 'max', 'step']
    };

    MetaItem.Events = [ 
        
    ];
    
    Component.ThumbId = Utils.Id();

    Utils.MergeTo(MetaItem, {
        Generic : {
            Type : GenericType,
            SubType : 'TrackBar',
            ThumbId : Component.ThumbId
        },
        Models : {
            Default : {
                Default : {
                    value: {
                        Id: 'Value'
                    },
                    min: {
                        Id: 'min'
                    },
                    max: {
                        Id: 'max'
                    }
                    ,
                    step: {
                        Id: 'step'
                    }
                }
            }
        },
        Property: {
            Default: {
                Default: {
                    alignItems: {
                        value: 'flexCenter'
                    },
                    layout: {
                        value: 'Flex'
                    },
                    fixedHeight : {
                        value : true
                    },                    
                    fixedHeight : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    width : {
                        value : 200
                    },
                    height: {
                        value: 2
                    },
                    position : {
                        value : 'relative'
                    }
                },
                active: {
                    backgroundColor: {
                        value: 'rgba(24, 180, 255, 1)'
                    }
                },
                disabled: {
                    backgroundColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },        
        SubItems : [{
            Id : Component.ThumbId
        }]        
    });

    Component.MetaItems[Component.ThumbId] = {        
        ParentId: RootId,
        Generic : {
            Type : GenericType,
            SubType : 'Thumb'
        },
        Property: {
            Default: {    
                Default: {
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderWidth : {
                        value : 2
                    },
                    borderStyle : {
                        value : 'solid'
                    },
                    borderColor : {
                        value : 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 99
                    },
                    height: {
                        value: 18
                    },
                    width: {
                        value: 18
                    }
                },
                active : {                
                    borderColor : {
                        value : 'rgba(24, 180, 255, 1)'
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },        
        Type: 'Div'
    };
    return Component;
}