import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import AppLayout from '../../../../../../../appstate/AppLayout';
import BaseSelectToken from '../BaseSelectToken';
import { AddNewFigmaLayoutToken, FigmaLayoutListItem, GetFigmaLayoutTokenList } from '../../../../../../../apps/figmaplugin/designsystem/autolayouts';
import FigmaLayoutTokenEditor from '../../../../../../../apps/figmaplugin/designsystem/autolayouts/item';
import { AddNewBoolean, BooleanListItem, GetBooleanTokenList } from '../../../../left/designsystem/booleans';
import BooleanEditor from '../../../../left/designsystem/booleans/item';

export default class SelectBooleanToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW BOOLEAN';
        this.moduleName = 'booleans';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Booleans;        
        this.hasListMode = false;

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetBooleanTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Boolean'
        };
        AddNewBoolean(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const transformItem = AddNewBoolean(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.Booleans && AppLayout.Refs.DesignSystem.Booleans.Reload();
            return transformItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Booleans, willDeleteId);
    } 
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const transform = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
    }
    GetStyleName() {
        return 'tokenId';
    }
    renderTokenList() {
        const items = [];

        Utils.ForEach(this.tokens, (token, i) => {
            items.push(
                <BooleanListItem
                    key={token.id}
                    notDraggable
                    name={token.name}
                    value={token.value}
                    valueState={token.valueState}
                    onSelect={this.SelectToken.bind(this, token.id)}
                    selected={token.id === this.GetSelectedTokenId()}
                />
            )
        });

        return (
            <SC.FCol>
                {items}
            </SC.FCol>
        )
    }
    renderTokenEditor() {
        return (
            <BooleanEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
                defaultValue={true}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.tokenId;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
