import MetaData from '../../../../toolabs-metaui';
import Utils from '../../../../toolabs-utils';

import Initialize_Text from './text';
import Initialize_Button from './button';
import Initialize_TextInput from './textinput';
import Initialize_DateInput from './dateinput';
import Initialize_Textarea from './textarea';
import Initialize_Checkbox from './checkbox';
import Initialize_Toggle from './toggle';
import Initialize_RangeInput from './rangeinput';
import Initialize_ProgressBar from './progressbar';
import Initialize_RadioGroup from './radiogroup';
import Initialize_SelectBox from './selectbox';
import Initialize_DropDown from './dropdown';
import Initialize_Table from './table';
import Initialize_Carousel from './carousel';
import Initialize_Tab from './tabs';
import Initialize_Accordion from './accordion';

export default (MetaItem, GenericType, Name) => {
    MetaItem.Type = GenericType;
    
    if (GenericType === MetaData.Components.Generic.Button.Type)
        return Initialize_Button(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Text.Type)
        return Initialize_Text(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Input.Type)
        return Initialize_TextInput(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Textarea.Type)
        return Initialize_Textarea(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.DateInput.Type)
        return Initialize_DateInput(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Checkbox.Type)
        return Initialize_Checkbox(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Toggle.Type)
        return Initialize_Toggle(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.RangeInput.Type)
        return Initialize_RangeInput(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.ProgressBar.Type)
        return Initialize_ProgressBar(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.RadioGroup.Type)
        return Initialize_RadioGroup(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Select.Type)
        return Initialize_SelectBox(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Dropdown.Type)
        return Initialize_DropDown(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Table.Type)
        return Initialize_Table(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Carousel.Type)
        return Initialize_Carousel(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Tab.Type)
        return Initialize_Tab(MetaItem, GenericType, Name);
    else if (GenericType === MetaData.Components.Generic.Accordion.Type)
        return Initialize_Accordion(MetaItem, GenericType, Name);
    
    const RootId = Utils.Id();
    
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        Default: {
            "Designboard": {
                "type": "grid"
            },
            "height": {
                "Unit": {
                    "unit": "px"
                },
                "value": 64
            },                
            "width": {
                "Unit": {
                    "unit": "px"
                },
                "value": 258
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;
    return Component;
}