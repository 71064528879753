import ReactDOM from 'react-dom';
import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { TokenTypes } from '../../../../views/project/manager/tokens';
import ChromaJs from 'chroma-js';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss, GetShadowTokenItem, GetShadowTokenList } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel, Figma_PopupItem, FigmaIcon_Style } from '../../common';
import { GetColorTokenItem, GetColorTokenList, GetGradientTokenItem } from '../../../../views/project/panels/left/designsystem/colors';
import { Seperator } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import { TokenGroup } from '../../../../views/project/panels/left/designsystem/common';
import { OutsideCloseDotMenuContainer } from '../../../../views/project/panels/left/common';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { GetTextPatternList } from '../../../../views/project/panels/left/designsystem/typography/patterns';
import { GetUniqueTextTokenStyle, GetUniqueFigmaTextStyle } from '../../api';
import { GetTextContentTokenList } from '../../../../views/project/panels/left/designsystem/textContent';
import LocalStyleTokenItem from './localStyleTokens';
import { GetTokenAliaseList } from '../../../../views/project/panels/left/designsystem/aliases';
import { GetFigmaTextStylePreview } from '../selection';
import Switch from '../../../../components/editors/Switch';
import { FigmaPluginOptions } from '../../options';

// views/project/panels/right/iteminspector/selectToken';
const styleTypes = ['colors', 'gradients', 'shadows', 'texts']

export default class UnboundFigmaLocalStyles extends ReactBasicComponent {
    constructor(props) {
        super(props);

        this.state.loading = true;

        this.BindTokens = this.BindTokens.bind(this);
        
    }    
    componentDidMount() {
        this.Load();
    }
    Load() {
        this.LocalStyleTokenMaps = {
            colors : [],
            gradients : [],
            shadows : [],
            texts : []
        };
        const PluginApi = this.props.GetPluginApi();
        PluginApi.GetLocalStyles((styles) => {            
            if (styles) {
                const bindings = {};
                Utils.ForEach(PluginApi.FigmaTokenMap, (tokenData, tokenId) => {
                    if (Globals.ProjectManager.Data.Data.tokens.list[tokenId]) {
                        if (tokenData && tokenData.styles && tokenData.styles.length > 0) {
                            bindings[tokenData.styles[0]] = {tokenId : tokenId};
                        }
                    }                    
                });
                if (styles.colors) {
                    styles.colors.map((localStyle) => {
                        const styleItem = PluginApi.ParseFigmaLocalSyle_Color(localStyle);
                        if (bindings[localStyle.sid] && bindings[localStyle.sid].tokenId) {
                            styleItem.tokenId = bindings[localStyle.sid].tokenId;
                        }
                        else {
                            styleItem.figma = localStyle;
                            this.LocalStyleTokenMaps.colors.push(styleItem);
                        }                            
                    })
                }

                if (styles.shadows) {
                    styles.shadows.map((localStyle) => {
                        const styleItem = PluginApi.ParseFigmaLocalSyle_Shadow(localStyle);
                        if (bindings[localStyle.sid] && bindings[localStyle.sid].tokenId) {
                            styleItem.tokenId = bindings[localStyle.sid].tokenId;
                        }
                        else {
                            styleItem.figma = localStyle;
                            this.LocalStyleTokenMaps.shadows.push(styleItem);
                        }                            
                    })
                }

                if (styles.texts) {
                    styles.texts.map((localStyle) => {
                        const styleItem = PluginApi.ParseFigmaLocalSyle_TextStyle(localStyle);
                        if (bindings[localStyle.sid] && bindings[localStyle.sid].tokenId) {
                            styleItem.tokenId = bindings[localStyle.sid].tokenId;
                        }
                        else {
                            styleItem.figma = localStyle;
                            styleItem.preview = GetFigmaTextStylePreview(styleItem);
                            this.LocalStyleTokenMaps.texts.push(styleItem);
                        }                            
                    })
                }
            }

            this.setState({loading : false});
        })
    }

    onToggleExpand(prop, value) {
        this[prop] = !this[prop];
        this.RCUpdate();
    }

    CreateNewTokenForStyle(type, figmaStyle, willBind) {
        let tokenId = this.props.GetPluginApi().CreateNewTokenFromStyle(figmaStyle.figma);;

        if (willBind && tokenId) {
            let styleList;
            if (type === TokenTypes.COLOR) {                
                styleList = this.LocalStyleTokenMaps.colors;
            }
            else if (type === TokenTypes.Shadows) {
                styleList = this.LocalStyleTokenMaps.shadows;
            }
            else if (type === TokenTypes.TextPatterns) {
                styleList = this.LocalStyleTokenMaps.texts;
            }

            if (styleList)
                Utils.Remove(styleList, (item) => {return item.fid === figmaStyle.fid});

            this.BindTokens([{styleId : figmaStyle.fid, tokenId : tokenId}]);
            return;
        }
        return tokenId;

        // let styleList;
        // const styleItem = {
        //     name : figmaStyle.name,
        //     value : figmaStyle.rgba
        // };
        // if (type === TokenTypes.COLOR) {
        //     if (figmaStyle.type === TokenTypes.Gradients) {            
        //         tokenId = Globals.ProjectManager.Tokens.Add({
        //             type : Globals.ProjectManager.Tokens.Types.Gradients,
        //             name : Utils.UseNullOrEmpty(styleItem.name, 'New Gradient'),
        //             value : this.props.GetPluginApi().ConvertFigmaGradientValue(styleItem)
        //         });   
        //     }
        //     else {
        //         tokenId = Globals.ProjectManager.Tokens.Colors.Add({
        //             name : Utils.UseNullOrEmpty(styleItem.name, ChromaJs(styleItem.value).hex()),
        //             value : styleItem.value
        //         });
        //     }

        //     styleList = this.LocalStyleTokenMaps.colors;
        // }
        // else if (type === TokenTypes.Shadows) {
        //     styleList = this.LocalStyleTokenMaps.shadows;
        //     tokenIds = this.props.GetPluginApi().CreateTokenFromFigma_Shadow(figmaStyle);
        // }

        // if (willBind && tokenId) {
        //     if (styleList)
        //         Utils.Remove(styleList, (item) => {return item.fid === figmaStyle.fid});
        //     this.BindTokens([{styleId : figmaStyle.fid, tokenId : tokenId}]);
        //     return;
        // }
        // return tokenId;
    }
    CreateNewTokenForStyles(type) {

        this.setState({showTokenizeMenu : false});

        const LocalStyleTokenMaps = this.LocalStyleTokenMaps;
        const bindings = [];
        let styles;
        if (type === TokenTypes.COLOR) {                       
            styles = LocalStyleTokenMaps.colors;            
        }
        else if (type === TokenTypes.Shadows) {                       
            styles = LocalStyleTokenMaps.shadows;            
        }
        else if (type === TokenTypes.TextPatterns) {                       
            styles = LocalStyleTokenMaps.texts;            
        }

        styles.map((figmaStyle) => {
            let tokenId = this.CreateNewTokenForStyle(type, figmaStyle, false); 
            if (tokenId) {
                bindings.push({
                    styleId : figmaStyle.fid,
                    tokenId : tokenId
                })
            }
        })
        if (bindings.length > 0) {

            bindings.map(({styleId}) => {
                Utils.Remove(styles, (item) => {return item.fid === styleId});
            })
            this.BindTokens(bindings);
        }
    }
    LinkStylesToExistingTokens(type, checkWith) {
        this.setState({showTokenizeMenu : false});

        const LocalStyleTokenMaps = this.LocalStyleTokenMaps;
        const bindings = [];
        if (type === TokenTypes.COLOR) {            
            const tokensNames = {};
            const tokensValues = {}
            
            const tokens_color = GetColorTokenList();                        
            Utils.ForEach(tokens_color, (colorToken, ) => {
                tokensNames[colorToken.name] = colorToken.id;
                tokensValues[ChromaJs(colorToken.value).hex()] = colorToken.id;
            });

            const colorAliases = GetTokenAliaseList(TokenTypes.COLOR);
            Utils.ForEach(colorAliases, (colorAliase, ) => {
                if (!tokensNames[colorAliase.name])
                    tokensNames[colorAliase.name] = colorAliase.id;
            });

            LocalStyleTokenMaps.colors.map((figmaStyle) => {
                let tokenId;
                if (checkWith === 'value') {
                    if (ChromaJs.valid(figmaStyle.rgba)) {
                        tokenId = tokensValues[ChromaJs(figmaStyle.rgba).hex()];
                    }
                }
                else {
                    tokenId = tokensNames[figmaStyle.name];
                }
                if (tokenId) {
                    bindings.push({
                        styleId : figmaStyle.fid,
                        tokenId : tokenId
                    })
                }
            })
        }
        else if (type === TokenTypes.Shadows) {            
            const tokensNames = {};
            const tokensValues = {}
            
            const tokens_shadow = GetShadowTokenList();                        
            Utils.ForEach(tokens_shadow.All, (shadowToken, ) => {
                tokensNames[shadowToken.name] = shadowToken.id;
                tokensValues[shadowToken.value] = shadowToken.id;
            });

            const shadowAliases = GetTokenAliaseList(TokenTypes.Shadows);
            Utils.ForEach(shadowAliases, (shadowAliase, ) => {
                if (!tokensNames[shadowAliase.name])
                    tokensNames[shadowAliase.name] = shadowAliase.id;
            });

            LocalStyleTokenMaps.shadows.map((figmaStyle) => {
                let tokenId;
                if (checkWith === 'value') {
                    tokenId = tokensValues[figmaStyle.css];
                }
                else {
                    tokenId = tokensNames[figmaStyle.name];
                }
                if (tokenId) {
                    bindings.push({
                        styleId : figmaStyle.fid,
                        tokenId : tokenId
                    })
                }
            })
        }
        else if (type === TokenTypes.TextPatterns) {            
            const tokensNames = {};
            const tokensValues = {}
            
            const tokens = GetTextPatternList();                        
            Utils.ForEach(tokens, (token, ) => {
                tokensNames[token.name] = token.id;
                const tokenStyleId = GetUniqueTextTokenStyle(token.pattern);
                tokensValues[tokenStyleId] = token.id;
            });

            
            LocalStyleTokenMaps.texts.map((figmaStyle) => {
                let tokenId;
                if (checkWith === 'value') {
                    const figmaText = this.props.GetPluginApi().ParseFigmaLocalSyle_TextStyle(figmaStyle.figma);
                    const figmaStyleId = GetUniqueFigmaTextStyle(figmaText);

                    tokenId = tokensValues[figmaStyleId];
                }
                else {
                    tokenId = tokensNames[figmaStyle.name];
                }
                if (tokenId) {
                    bindings.push({
                        styleId : figmaStyle.fid,
                        tokenId : tokenId
                    })
                }
            })
        }

        if (bindings.length > 0) {
            Utils.ForEach(bindings, ({styleId}, ) => {
                if (type === TokenTypes.COLOR)
                    Utils.Remove(LocalStyleTokenMaps.colors, (item) => {return item.fid === styleId});
                else if (type === TokenTypes.Shadows)
                    Utils.Remove(LocalStyleTokenMaps.shadows, (item) => {return item.fid === styleId});
                else if (type === TokenTypes.TextPatterns)
                    Utils.Remove(LocalStyleTokenMaps.texts, (item) => {return item.fid === styleId});
            });
            this.BindTokens(bindings);
        }
    }
    BindTokens(bindings) {
        this.props.GetPluginApi().BindTokensToSLocaltyles(bindings, () => {
            let total = 0;
            styleTypes.map((styleType) => {
                total += this.LocalStyleTokenMaps[styleType].length;
            })
            if (total === 0) {
                if (this.props.onContinue) {
                    this.props.onContinue();
                    return;
                }                
            }
            this.RCUpdate();
        }); 
    }
    SelectTokenFor(type, figmaStyle) {
        this.setState({showTokenizeMenu : false}, () => {
            if (type === TokenTypes.COLOR) {
                this.props.RefToolbar.current.ShowPanel({
                    type : AppState.ItemTypes.BOARD.COLOR,
                    title : (
                        <SC.FRow alc jsb f1>
                            <div style={{marginRight : '8px'}}>TOKEN FOR </div>
                            <FigmaColorBox round color={figmaStyle.rgba} />
                            <SC.TextDivAbbr>{figmaStyle.name}</SC.TextDivAbbr>
                        </SC.FRow>                        
                    ),
                    forAliase : false,
                    onSelect : (newTokenId) => {
                        if (newTokenId) {
                            Utils.Remove(this.LocalStyleTokenMaps.colors, (item) => {return item.fid === figmaStyle.fid});
    
                            this.BindTokens([{styleId : figmaStyle.fid, tokenId : newTokenId}]);
                        }
                    },
                    onClosed : () => {
        
                    },
                    onPreviewNewToken : () => {
                        
                    }
                })
            }
            else if (type === TokenTypes.Shadows) {
                this.props.RefToolbar.current.ShowPanel({
                    type : AppState.ItemTypes.BOARD.SHADOW,
                    title : (
                        <SC.FRow alc jsb f1>
                            <div style={{marginRight : '8px'}}>TOKEN FOR </div>
                            <div 
                                style={{
                                    width : '20px',
                                    height : '20px',
                                    margin : '8px',
                                    marginRight : '4px',
                                    borderRadius : '2px',
                                    backgroundColor : '#fafafa',
                                    boxShadow : figmaStyle.css
                                }}
                            />
                            <SC.TextDivAbbr>{figmaStyle.name}</SC.TextDivAbbr>
                        </SC.FRow>                        
                    ),
                    forAliase : false,
                    onSelect : (newTokenId) => {
                        if (newTokenId) {
                            Utils.Remove(this.LocalStyleTokenMaps.shadows, (item) => {return item.fid === figmaStyle.fid});
    
                            this.BindTokens([{styleId : figmaStyle.fid, tokenId : newTokenId}]);
                        }
                    },
                    onClosed : () => {
        
                    },
                    onPreviewNewToken : () => {
                        
                    }
                })
            }
            else if (type === TokenTypes.TextPatterns) {
                this.props.RefToolbar.current.ShowPanel({
                    type : AppState.ItemTypes.BOARD.PATTERN_TEXT,
                    title : (
                        <SC.FRow alc jsb f1>
                            <div style={{marginRight : '8px'}}>TOKEN FOR </div>
                            <div 
                                style={{                                
                                    ...figmaStyle.preview,
                                    fontSize : '14px',
                                }}
                            >
                                Ag
                            </div>
                            <SC.TextDivAbbr>{figmaStyle.name}</SC.TextDivAbbr>
                        </SC.FRow>                        
                    ),
                    forAliase : false,
                    onSelect : (newTokenId) => {
                        if (newTokenId) {
                            Utils.Remove(this.LocalStyleTokenMaps.texts, (item) => {return item.fid === figmaStyle.fid});
    
                            this.BindTokens([{styleId : figmaStyle.fid, tokenId : newTokenId}]);
                        }
                    },
                    onClosed : () => {
        
                    },
                    onPreviewNewToken : () => {
                        
                    }
                })
            }
        })        
    }
    renderCustom() {

        if (this.state.loading) {
            return (
                <SC.Div_Flex_Cell f1>
                    <SC.Loading_Icon />
                </SC.Div_Flex_Cell>                
            )
        }

        let existingToolbar;            

        const LocalStyleTokenMaps = this.LocalStyleTokenMaps;

        if (this.state.showTokenizeMenu) {
            existingToolbar = (
                <OutsideCloseDotMenuContainer onClose={() => {
                        this.setState({showTokenizeMenu : false})
                    }}
                    style={{
                        left : 'unset',
                        right : 0,
                        top : '100%',
                        width : 'auto',
                        minWidth : '160px',
                        paddingTop : '4px',
                        paddingBottom : '4px',
                        marginTop : '-4px',
                        maxWidth : 'calc(100vw - 20px)'
                    }}
                >
                    <Figma_PopupItem onClick={this.CreateNewTokenForStyles.bind(this, this.state.showTokenizeMenu)}>
                        <SC.Icons.Icon_Button hasFill style={{marginLeft : '-4px', paddingTop : '4px', paddingBottom : '4px'}}>
                            <SC.Icons.Icon_Plus />
                        </SC.Icons.Icon_Button>
                        <div style={{marginLeft : '4px'}}>Create & Link to New Tokens</div>
                    </Figma_PopupItem>
                    <Figma_PopupItem onClick={this.LinkStylesToExistingTokens.bind(this, this.state.showTokenizeMenu, 'value')}>
                        <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                        <div style={{marginLeft : '10px'}}>Link to Tokens with Same Value</div>
                    </Figma_PopupItem>
                    <Figma_PopupItem onClick={this.LinkStylesToExistingTokens.bind(this, this.state.showTokenizeMenu, 'name')}>
                        <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                        <div style={{marginLeft : '10px'}}>Link to Tokens with Same Name</div>
                    </Figma_PopupItem>                      
                </OutsideCloseDotMenuContainer>
            )
        }

        let header;

        if (this.props.loading) {
            header = (
                <React.Fragment>
                    <SC.FCol style={{
                        backgroundColor : 'rgba(255, 235, 0, 0.54)',
                        margin : '10px',
                        padding : '16px',                    
                        textAlign : 'center',
                        lineHeight : '1.5em'
                    }}>
                        Following Figma styles do not have DSM Tokens linked to them. You can link them to tokens either by mathcing value or name, create new tokens or by manually selecting the token.
                        <Seperator />
                        <SC.FRow alc jsb style={{textAlign : 'left'}}>
                            <SC.FCol>
                                <div>Don't make this check on project load again.</div>    
                                <div>You can change this option from Settings panel later.</div>
                            </SC.FCol>                        
                            <Switch round value={!FigmaPluginOptions.checkStyleTokensonLoad.value} onChange={() => {
                                FigmaPluginOptions.checkStyleTokensonLoad.value = !FigmaPluginOptions.checkStyleTokensonLoad.value;
                                this.props.onSetOption(FigmaPluginOptions.checkStyleTokensonLoad.name, FigmaPluginOptions.checkStyleTokensonLoad.value);
                            }} />
                        </SC.FRow>
                        
                    </SC.FCol>
                    <SC.Buttons.RoundButton xsmall style={{
                        width : 'auto', marginLeft : 'auto', marginRight : 'auto', marginBottom : '8px', paddingLeft : '12px', paddingRight : '12px'
                    }} onClick={this.props.onContinue}>
                        Continue Without Creating Tokens
                    </SC.Buttons.RoundButton>
                </React.Fragment>
            )
        }
        else {
            header = (
                <SC.FCol style={{
                    backgroundColor : 'rgba(255, 235, 0, 0.54)',
                    margin : '10px',
                    padding : '16px',
                    alignItems : 'center',
                    textAlign : 'center',
                    lineHeight : '1.5em'
                }}>
                    No Figma object selected. Listing Figma Styles which are not bound to DSM Tokens.
                </SC.FCol>
            )
        }

        return (
            <SC.FCol style={{fontSize : '11px', fontWeight : 400}}>
                {/* <SC.Buttons.RoundButton onClick={() => {this.props.GetPluginApi().GenerateStaticPageOfState()}} xsmall style={{marginRight : 'auto', marginLeft : 'auto', marginTop : '16px'}}>Generate Static Page</SC.Buttons.RoundButton>                              */}
                {header}    
                {
                    LocalStyleTokenMaps && LocalStyleTokenMaps.colors.length > 0 &&
                    <React.Fragment>
                        <TokenGroup
                            title='COLOR STYLES'
                            expandable={{expanded : !this.colorStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'colorStylesCollapsed')}}
                            addOn={this.colorStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>                                    
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={() => {this.setState({showTokenizeMenu : TokenTypes.COLOR})}}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct styles'>{colorStyles.length}</NodeCount>     */}
                                    {this.state.showTokenizeMenu && this.state.showTokenizeMenu === TokenTypes.COLOR && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.colorStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>                                    
                                    {
                                        LocalStyleTokenMaps.colors.map((figmaStyle) => {
                                            return (
                                                <LocalStyleTokenItem 
                                                    type={TokenTypes.COLOR}
                                                    key={figmaStyle.fid} 
                                                    styleItem={figmaStyle} 
                                                    onSelectToken={this.SelectTokenFor.bind(this, TokenTypes.COLOR, figmaStyle)}
                                                    onNewToken={this.CreateNewTokenForStyle.bind(this, TokenTypes.COLOR, figmaStyle, true)}
                                                />
                                            )
                                        })
                                    }
                                </SC.FCol>
                            }
                        </TokenGroup>
                    </React.Fragment>
                }
                {
                    LocalStyleTokenMaps && LocalStyleTokenMaps.shadows.length > 0 &&
                    <React.Fragment>
                        <TokenGroup
                            title='SHADOW STYLES'
                            expandable={{expanded : !this.shadowStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'shadowStylesCollapsed')}}
                            addOn={this.shadowStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>                                    
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={() => {this.setState({showTokenizeMenu : TokenTypes.Shadows})}}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {this.state.showTokenizeMenu && this.state.showTokenizeMenu === TokenTypes.Shadows && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.shadowStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>                                    
                                    {
                                        LocalStyleTokenMaps.shadows.map((figmaStyle) => {
                                            return (
                                                <LocalStyleTokenItem 
                                                    type={TokenTypes.Shadows}
                                                    key={figmaStyle.fid} 
                                                    styleItem={figmaStyle} 
                                                    onSelectToken={this.SelectTokenFor.bind(this, TokenTypes.Shadows, figmaStyle)}
                                                    onNewToken={this.CreateNewTokenForStyle.bind(this, TokenTypes.Shadows, figmaStyle, true)}
                                                />
                                            )
                                        })
                                    }
                                </SC.FCol>
                            }
                        </TokenGroup>
                    </React.Fragment>
                }
                {
                    LocalStyleTokenMaps && LocalStyleTokenMaps.texts.length > 0 &&
                    <React.Fragment>
                        <TokenGroup
                            title='TEXT STYLES'
                            expandable={{expanded : !this.textStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'textStylesCollapsed')}}
                            addOn={this.textStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>                                    
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={() => {this.setState({showTokenizeMenu : TokenTypes.TextPatterns})}}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {this.state.showTokenizeMenu && this.state.showTokenizeMenu === TokenTypes.TextPatterns && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.textStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>                                    
                                    {
                                        LocalStyleTokenMaps.texts.map((figmaStyle) => {
                                            return (
                                                <LocalStyleTokenItem 
                                                    type={TokenTypes.TextPatterns}
                                                    key={figmaStyle.fid} 
                                                    styleItem={figmaStyle} 
                                                    onSelectToken={this.SelectTokenFor.bind(this, TokenTypes.TextPatterns, figmaStyle)}
                                                    onNewToken={this.CreateNewTokenForStyle.bind(this, TokenTypes.TextPatterns, figmaStyle, true)}
                                                />
                                            )
                                        })
                                    }
                                </SC.FCol>
                            }
                        </TokenGroup>
                    </React.Fragment>
                }
            </SC.FCol>
            
        )
    }
}