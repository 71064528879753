import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    UIUtils
} from '../../../importer';
import { FigmaHeader } from '../../figmaplugin/main';
import FigmaDesignSystem from '../../figmaplugin/designsystem';
import FigmaPluginApi from '../../figmaplugin/api';
import { motion, AnimatePresence } from 'framer-motion';
import DemoDataMap from '../data/dataMap';
import { LoadMockupFields } from '../../../views/project/panels/left/toolbar/mockupdata';
import MockupInitializer from '../../../views/project/panels/left/toolbar/mockupdata/initializer';
import ToolbarItem from '../../../views/project/panels/left/toolbar/UIElements/element';
import { UIElementGrid } from '../../../views/project/panels/left/toolbar/UIElements';
import { GetColorTokenList } from '../../../views/project/panels/left/designsystem/colors';

const MOCKUPFIELD_PORTRAIT = 'pxWlgTyr';
const MOCKUPFIELD_NAME = 'qBYZRbFKN';
const MOCKUPFIELD_JOB = 'n6oUkQjI';

export default class FigmaDemo extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.FimaMenuToggled = this.FimaMenuToggled.bind(this);        
        this.Ref_SystesPanel = React.createRef();
        this.Ref_DesignSystem = React.createRef();
        this.Ref_TourGuide = React.createRef();

        this.HandleMessage = this.HandleMessage.bind(this);            
        this.AnimateThemeSelection = this.AnimateThemeSelection.bind(this);
        this.AnimateContentMockup = this.AnimateContentMockup.bind(this);
        this.AnimateSelection = this.AnimateSelection.bind(this);
        

        this.renderMockup = this.renderMockup.bind(this);        
        this.Ref_Mockup_Name = React.createRef();
        this.Ref_Mockup_Job = React.createRef();
        this.Ref_Mockup_Portrait = React.createRef();

        this.PluginApi = new FigmaPluginApi();

        this.PluginApi.FigmaStyleTokens = {            
        };
        const tokens_color = Globals.ProjectManager.Tokens.Colors.Order();
        Utils.ForEach(tokens_color, (tokenId, ) => {
            this.PluginApi.FigmaStyleTokens[tokenId] = {tokenId : tokenId};
        });

        const tokens_text = Globals.ProjectManager.Tokens.TypePatterns.Get();
        Utils.ForEach(tokens_text, (tokenId, ) => {
            this.PluginApi.FigmaStyleTokens[tokenId] = {tokenId : tokenId};
        });
        

        this.GetPluginApi = this.GetPluginApi.bind(this);
        this.GetOptions = this.GetOptions.bind(this);
        this.SetOption = this.SetOption.bind(this);

        this.state.viewMode = 'tokens';
        
        super.ListenEvents(
            Events.GLOBAL.THEME.CHANGED
        );

    }
    FimaMenuToggled() {
        this.setState({showFigmaMenu : !this.state.showFigmaMenu});
    }
    
    OnEvent(Event, Params) {
        if (Event === Events.GLOBAL.THEME.CHANGED) {        
            AppLayout.ThemeId = Utils.Id();            
            this.props.onUpdateTheme && this.props.onUpdateTheme();
            this.RCUpdate();
        }
    }
    GetPluginApi() {
        return this.PluginApi;
    }
    GetSelection() {
        
    }
    GetOptions() {
        if (!this.Options) {
            this.Options = {
                SetOption : this.SetOption,
                selectionInfoClosed : true
            };        
        }

        return this.Options;
    }
    SetOption(key, value) {        
        Utils.Set(this.GetOptions(), value, key);
        const {SetOption, ...options} = this.GetOptions();
        this.PluginApi.SetOption({key : 'UserSettings', value : options});
    }
    
    componentDidUpdate(prevProps, prevState) {
        window.Globals = Globals;
        window.AppLayout = AppLayout;     
    }
    HandleMessage(data) {
        if (data.type === 'AnimateThemeSelection') {
            this.AnimateThemeSelection();
        }  
        else if (data.type === 'AnimateContentMockup') {
            this.AnimateContentMockup(!data.animated);
        }  
        else if (data.type === 'AnimateSelection') {
            if (this.isAnimatingContentMockup) {
                this.onAnimatingContentMockupEnded = () => {
                    delete this.onAnimatingContentMockupEnded;
                    this.AnimateSelection();
                }
            }
            else            
                this.AnimateSelection();
        }  
        else if (data.type === 'SelectionChanged') {       
            if (this.isAnimatingContentMockup) {
                this.onAnimatingContentMockupEnded = () => {
                    delete this.onAnimatingContentMockupEnded;
                    this.setState({viewMode : 'selection', selectionId : Utils.Id()});
                }
            }
            else            
                this.setState({viewMode : 'selection', selectionId : Utils.Id()});            
        }
        else if (data.type === 'GetSelectionResponse' || data.type === 'GetLocalStyles_Response') {
            this.PluginApi.HandleMessage({
                type : data.type,
                data : data
            })
        }
    }
    DelayExecute(delay = 100) {
        return new Promise(res => setTimeout(res, delay));
    }
    async AnimateThemeSelection() {
        if (this.state.viewMode !== 'tokens') {
            this.setState({viewMode : 'tokens'});
            await this.DelayExecute(400);
        }          
        if (this.Ref_SystesPanel.current) {            
            if (!this.state.showThemeSelector) {
                this.setState({showThemeSelector : true});
                await this.DelayExecute(400);
            }

            const DN = ReactDOM.findDOMNode(this.Ref_SystesPanel.current);
            if (DN) {
                const bounds = DN.getBoundingClientRect();
                this.Ref_TourGuide.current.GoTo({left : bounds.left + 8, top : bounds.top + 4, width : bounds.width - 16, height : bounds.height - 20});
                await this.DelayExecute(400);
            }                        
        }
        Globals.ProjectManager.States.SetSelectedVariation(DemoDataMap.States.Dark, DemoDataMap.States.Dark );
        await this.DelayExecute(1000);
        Globals.ProjectManager.States.SetSelectedVariation(DemoDataMap.States.Dark, null );

        await this.DelayExecute(2000);
        this.Ref_TourGuide.current.Stop();

    }
    async AnimateContentMockup(firstTime) {
        if (this.state.viewMode !== 'mockup') {
            this.setState({viewMode : 'mockup'});
            await this.DelayExecute(400);

            if (firstTime) {
                this.isAnimatingContentMockup = true;

                if (this.Ref_Mockup_Portrait.current) {
                    const bounds = ReactDOM.findDOMNode(this.Ref_Mockup_Portrait.current).getBoundingClientRect();
                    this.Ref_TourGuide.current.GoTo({left : bounds.left, top : bounds.top, width : bounds.width, height : bounds.height});
                    this.SelectMockupField(MOCKUPFIELD_PORTRAIT, 'image');
                    await this.DelayExecute(1300);
                }
    
                if (this.Ref_Mockup_Name.current) {
                    const bounds = ReactDOM.findDOMNode(this.Ref_Mockup_Name.current).getBoundingClientRect();
                    this.Ref_TourGuide.current.GoTo({left : bounds.left, top : bounds.top, width : bounds.width, height : bounds.height});
                    this.SelectMockupField(MOCKUPFIELD_NAME, 'title');
                    await this.DelayExecute(1300);
                }
    
                if (this.Ref_Mockup_Job.current) {
                    const bounds = ReactDOM.findDOMNode(this.Ref_Mockup_Job.current).getBoundingClientRect();
                    this.Ref_TourGuide.current.GoTo({left : bounds.left, top : bounds.top, width : bounds.width, height : bounds.height});
                    this.SelectMockupField(MOCKUPFIELD_JOB, 'subtitle');
                    await this.DelayExecute(1300);
                }
                
                this.Ref_TourGuide.current.Stop();

                this.isAnimatingContentMockup = false;

                if (this.onAnimatingContentMockupEnded) {
                    this.onAnimatingContentMockupEnded();
                }
            }            
        }        

    }
    async AnimateSelection() {
        if (this.state.viewMode !== 'selection') {
            this.setState({viewMode : 'selection'});
            await this.DelayExecute(400);
            
            if (this.Ref_SystesPanel.current) {            
                if (!this.state.showThemeSelector) {
                    this.setState({showThemeSelector : true});
                    await this.DelayExecute(400);
                }                    
            }
            Globals.ProjectManager.States.SetSelectedVariation(DemoDataMap.States.Dark, DemoDataMap.States.Dark );
            await this.DelayExecute(2000);
            Globals.ProjectManager.States.SetSelectedVariation(DemoDataMap.States.Dark, null );
        }
    }
    renderMockup() {
        if (!this.mockupDataItems) {
            this.mockupDataItems = LoadMockupFields({});
        }
        return (
            <SC.FCol>
                {this.mockupDataItems.length === 0 && !this.isInitialized && <MockupInitializer onInitialized={() => {this.isInitialized = true; this.mockupDataItems = LoadMockupFields({}); this.RCUpdate();}} />}
                {
                    this.mockupDataItems.map((collectionItem) => {     
                        let addNewText;                        
                        return (
                            <SC.FCol>
                                <SC.FRow alc jsb style={{padding : '8px', paddingLeft : '10px', paddingBottom : 0}}>
                                    <div> {Utils.UseNullOrEmpty(collectionItem.name, 'Collection')}</div>                                    
                                </SC.FRow>
                                
                                <UIElementGrid>
                                    {
                                        collectionItem.fields.map((field) => {
                                            let ref;
                                            if (field.Name === 'Name')
                                                ref = this.Ref_Mockup_Name;
                                            else if (field.Name === 'Job')
                                                ref = this.Ref_Mockup_Job;
                                            else if (field.Name === 'Portrait')
                                                ref = this.Ref_Mockup_Portrait;

                                            return (
                                                <ToolbarItem 
                                                    ref={ref}
                                                    key={field.FieldId}
                                                    title={field.Name}
                                                    IconType={field.IconType}
                                                    forSelect={{
                                                    }}                                                        
                                                    onClick={this.SelectMockupField.bind(this, field.FieldId, false)}
                                                />
                                            )
                                        })
                                    }
                                    {addNewText}
                                </UIElementGrid>                                    
                            </SC.FCol>
                        )
                    })
                }
            </SC.FCol>
        )
    }
    SelectMockupField(fieldId, animated) {
        const field = Globals.ProjectManager.Mockups.Field(fieldId);
        if (field) {
            const nodeMockupValues = [{}, {}, {}, {}];            
            nodeMockupValues.map((nodeData) => {
                if (field.dataType === 'string') {
                    nodeData.text = Globals.ProjectManager.Mockups.GetRandomValue(fieldId);                    
                }
                else if (field.dataType === 'image') {
                    const url = Globals.ProjectManager.Mockups.GetRandomValue(fieldId).url;
                    nodeData.url = url;       
                }
            })
            window.parent.postMessage({
                toolabsMessage : {
                    type : 'SetMockupValues',
                    data : nodeMockupValues,
                    animated : animated
                }
            }, '*');
        } 
    }
    renderCustom() {    
        return (
            <SC.FCol f1>
                <FigmaHeader onToggle={this.FimaMenuToggled} open={this.state.showFigmaMenu} />
                <FigmaDesignSystem 
                    {...this.props}
                    onUpdateTheme={this.props.onUpdateTheme}
                    ref={this.Ref_DesignSystem}
                    Ref_SystesPanel={this.Ref_SystesPanel}
                    GetPluginApi={this.GetPluginApi}
                    GetOptions={this.GetOptions}
                    viewMode={this.state.viewMode}
                    onChangeViewMode={(viewMode) => {
                        this.setState({
                            viewMode : viewMode
                        });
                    }}
                    showThemeSelector={this.state.showThemeSelector}
                    onToggleThemeSelector={(checked) => {                                        
                        this.setState({
                            showThemeSelector : checked
                        });
                    }}
                    tokenMode={this.state.tokenMode}
                    onTokenMode={(mode) => {
                        this.setState({
                            tokenMode : mode
                        });
                    }}
                    selectionId={this.state.selectionId}
                    renderMockup={this.renderMockup}
                    demoMode
                />
                <GuideOverlay 
                    ref={this.Ref_TourGuide}
                />             
            </SC.FCol>
        )
    }
}

export class GuideOverlay extends React.Component {
    constructor(props) {
        super(props);
        
        const {top, left, width, height} = this.props;

        this.state = {  
            top : top,
            left : left,
            width : width,
            height : height
        }
    }
    GoTo({top, left, width, height}) {
        this.setState({
            active : true,
            top : top,
            left : left,
            width : width,
            height : height
        })
    }
    Stop() {
        this.setState({
            active : false
        })
    }
    render() { 
        if (!this.state.active)
            return null;

        const {top, left, width, height} = this.state;
        return (
            <React.Fragment>
                <AnimatePresence>
                    <motion.div style={{
                        ...SC.Styles.Absolute,
                        borderRadius : '4px',
                        pointerEvents : 'none',
                        boxShadow : '0 0 0 1000px #00000047'
                    }} 
                        initial={{
                            opacity : 0, top : 0, left : 0, width : 0, height : 0
                        }}
                        animate={{
                            opacity : 1,
                            left : left,
                            width : width,
                            top : top,
                            height : height
                        }}
                        exit={{
                            opacity : 0, top : 0, left : 0, width : 0, height : 0
                        }}
                    />
                </AnimatePresence>
                <div style={{
                        position : 'absolute',
                        top : 0, left : 0, right : 0,                    
                        height : Utils.px(top),
                        zIndex : 100000, pointerEvents : 'all'
                    }} 
                />
                <div style={{
                    position : 'absolute',
                    top : Utils.px(top + height), left : 0, right : 0,                    
                    bottom : 0,
                    zIndex : 100000, pointerEvents : 'all'
                }} 
                />
                <div style={{
                    position : 'absolute',
                    top : Utils.px(top), left : 0, width : Utils.px(left), height : Utils.px(height),
                    zIndex : 100000, pointerEvents : 'all'
                    }} 
                />
                <div style={{
                    position : 'absolute',
                    top : Utils.px(top), right : 0, left :Utils.px(left + width), height : Utils.px(height),
                    zIndex : 100000, pointerEvents : 'all'
                    }}
                />                
            </React.Fragment>            
        )
    }
}