import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';

import Containers from './container';
import Grids from './grid';
import Texts from './text';
import Icons from './icon';
import { AnimatePresence, motion } from 'framer-motion';
import GridTemplateEditor from './grid/templateEditor';

export default class DefaultStyles extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.ShowOverlay = this.ShowOverlay.bind(this);
    }
    ShowOverlay(overlay) {
        this.Overlay = overlay;
        this.RCUpdate();
    }
    renderCustom() {
        const {children, ...rest} = this.props;
        const props = {
            ...rest,
            onShowOverlay : this.ShowOverlay
        }
        let overlay;

        if (this.Overlay) {
            if (this.Overlay.type === Globals.ProjectManager.Templates.Types.Grid) {
                overlay = (
                    <GridTemplateEditor 
                        {...this.props}
                        id={this.Overlay.id} 
                        onClose={() => this.ShowOverlay(null)}
                    />
                )
            }
        }

        return (
            <React.Fragment>
                {this.props.children}       
                <SC.FCol fw fh style={{position : 'relative', fontSize : '11px'}}>
                    <SC.CustomScrollbars>
                        <Containers {...props}/>
                        <Grids {...props}/>
                        <Texts {...props} />
                        <Icons {...props} last/>
                    </SC.CustomScrollbars>    
                    <AnimatePresence>
                        {
                            overlay && 
                            <motion.div 
                                style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                                initial={{opacity : 0.7, x : -24}}
                                animate={{opacity : 1, x : 0}}
                                exit={{opacity : 0, x : -24}}
                                transition={{duration : 0.1}}
                            >
                                {overlay} 
                            </motion.div>
                        }
                    </AnimatePresence>
                </SC.FCol>
                
            </React.Fragment>
        )
    }
}