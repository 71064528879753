import React, { useState, useMemo } from 'react'
import { createEditor, Node } from 'slate'
import { Slate, Editable, withReact } from 'slate-react'
import { withHistory } from 'slate-history'

import {
    Utils
} from '../../importer';

export const PlainSlateTextParser = {
    deserialize(text) {
        const textValue = Utils.UseNullOrEmpty(text, '');
        return textValue.split('\n').map(line => {
            return {
                children: [{ text: line }],
            }
        });
    },
    serialize(value) {
        return (
            value
                // Return the string content of each paragraph in the value's children.
                .map(n => Node.string(n))
                // Join them all with line breaks denoting paragraphs.
                .join('\n')
        );
    }
}

const PlainTextEditor = ({text, onChange, ...rest}) => {
  const [value, setValue] = useState(PlainSlateTextParser.deserialize(text));
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  return (
    <Slate editor={editor} value={value} 
        onChange={value => {
            setValue(value);
            onChange && onChange(PlainSlateTextParser.serialize(value));
        }}
    >
      <Editable {...rest} />
    </Slate>
  )
}

export default PlainTextEditor;