import React from 'react';
import {
    ReactBaseComponent,
    SC
} from '../../../../../../../../../importer';

import NumberInput from '../../../../../../../../../components/editors/input_number';
import { PropertyLabel } from '../../../common';
import SizeEditor from '../../../size';

const MinMax = ({onToggleMinMax, selected}) => {
    return (
        <SC.Icons.Icon_Button hasFill title='Min/Max Size' 
            style={{cursor : 'pointer', marginRight : '4px', marginLeft : '4px'}} onClick={onToggleMinMax} fill={selected ? SC.CurrentTheme.theme.color_brand : null} >
            <SC.Svg width="18" viewBox="0 0 24 24">
                <path  d="M17.2928932,12 L6.70710678,12 L9.85355339,15.1464466 C10.0488155,15.3417088 10.0488155,15.6582912 9.85355339,15.8535534 C9.65829124,16.0488155 9.34170876,16.0488155 9.14644661,15.8535534 L5.14644661,11.8535534 C4.95118446,11.6582912 4.95118446,11.3417088 5.14644661,11.1464466 L9.14644661,7.14644661 C9.34170876,6.95118446 9.65829124,6.95118446 9.85355339,7.14644661 C10.0488155,7.34170876 10.0488155,7.65829124 9.85355339,7.85355339 L6.70710678,11 L17.2928932,11 L14.1464466,7.85355339 C13.9511845,7.65829124 13.9511845,7.34170876 14.1464466,7.14644661 C14.3417088,6.95118446 14.6582912,6.95118446 14.8535534,7.14644661 L18.8535534,11.1464466 C19.0488155,11.3417088 19.0488155,11.6582912 18.8535534,11.8535534 L14.8535534,15.8535534 C14.6582912,16.0488155 14.3417088,16.0488155 14.1464466,15.8535534 C13.9511845,15.6582912 13.9511845,15.3417088 14.1464466,15.1464466 L17.2928932,12 Z M20,3.5 C20,3.22385763 20.2238576,3 20.5,3 C20.7761424,3 21,3.22385763 21,3.5 L21,19.5 C21,19.7761424 20.7761424,20 20.5,20 C20.2238576,20 20,19.7761424 20,19.5 L20,3.5 Z M3,3.5 C3,3.22385763 3.22385763,3 3.5,3 C3.77614237,3 4,3.22385763 4,3.5 L4,19.5 C4,19.7761424 3.77614237,20 3.5,20 C3.22385763,20 3,19.7761424 3,19.5 L3,3.5 Z"/>
            </SC.Svg>
        </SC.Icons.Icon_Button>
    )
}

export default class GridItemSizer extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.Delete = this.Delete.bind(this);
        this.Clone = this.Clone.bind(this);
        this.ToggleMinMax = this.ToggleMinMax.bind(this);
    }
    Clone() {
        this.props.onClone(this.props.index);
    }
    Delete() {
        this.props.onDelete(this.props.index)
    }
    ChangeSizeOf(obj, value, unit) {
        obj.value = value;
        obj.unit = unit
        this.props.onSave();;
    }
    ChangeOnlySizeValueOf(obj, size) {
        obj.value = size;
        this.props.onSave();
        this.RCUpdate();
    }    
    ChangeSizeValueOf(obj, size) {
        if (size.unit !== obj.unit) {
            if (size.unit === 'fr' && obj.unit === 'px' || size.value > 10) {
                size.value = 1;
            }
            else if (size.unit === 'px') {
                size.value = this.props.getCurrentSize();    
            }
        }
        obj.value = size.value;
        obj.unit = size.unit;
        this.props.onSave();
        this.RCUpdate();
    }    
    ChangeSizeUnitOf(obj, u) {
        obj.unit = u;
        this.props.onSave();
    }
    ToggleMinMax() {
        if (!this.props.value.minMax) {
            const currentSize = this.props.getCurrentSize();
            this.props.value.minMax = {
                min : {
                    value : currentSize,
                    unit : 'px'
                },
                max : {
                    value : 1,
                    unit : 'fr'
                }
            }
        }
        else {
            delete this.props.value.minMax;
        }        
        this.props.onSave();
    }
    renderCustom() {
        if (this.props.spinned) {
            return (
                <NumberInput
                    spinnded
                    value={this.props.value.value}
                    numeralPositiveOnly
                    onChange={this.ChangeOnlySizeValueOf.bind(this, this.props.value)} 
                    style={{
                        textAlign : 'right',
                        fontSize : '12px',
                        alignSelf : 'center',
                        width : '30px',
                    }}
                    boxStyle={{
                        height : '24px'
                    }}
                />
            )
        }
        const Sizer = (
            <SizeEditor                 
                hasGridFragment={!this.props.noGridFragment}
                hasAuto={this.props.hasAuto}
                value={this.props.value.value} 
                unit={this.props.value.unit} 
                onChange={this.ChangeSizeValueOf.bind(this, this.props.value)} 
                onChanging={this.ChangeSizeValueOf.bind(this, this.props.value)} 
                hasSpinner
                style_box={{width : '68px'}}
            />
        );

        if (this.props.justSize)
            return Sizer;

        if (this.props.gapSize) {
            return (
                <SC.FRow f1 alc jsb>
                    <PropertyLabel >{this.props.gapSize}</PropertyLabel>                    
                    {Sizer}
                </SC.FRow>
            )
        }
        
        let columnSizer = null;
        if (this.props.value.minMax) {
            columnSizer = (
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr auto 1fr',
                        alignItems : 'center',
                        fontSize : '11px',
                        justifyItems : 'center',
                        flex : 1
                    }}
                >
                    <div>min</div>
                    <div />
                    <div>max</div>
                    <SizeEditor 
                        hasAuto={this.props.hasAuto}
                        value={this.props.value.minMax.min.value} 
                        unit={this.props.value.minMax.min.unit} 
                        onChange={this.ChangeSizeValueOf.bind(this, this.props.value.minMax.min)} 
                        onChanging={this.ChangeSizeValueOf.bind(this, this.props.value.minMax.min)} 
                        style_box={{width : '68px'}}
                    />
                    <MinMax onToggleMinMax={this.ToggleMinMax} />
                    <SizeEditor 
                        hasGridFragment
                        hasAuto={this.props.hasAuto}
                        value={this.props.value.minMax.max.value} 
                        unit={this.props.value.minMax.max.unit} 
                        onChange={this.ChangeSizeValueOf.bind(this, this.props.value.minMax.max)} 
                        onChanging={this.ChangeSizeValueOf.bind(this, this.props.value.minMax.max)} 
                        hasSpinner
                        style_box={{width : '68px'}}
                    />
                </div>
            )
        }
        else {
            columnSizer = (
                <SC.FRow jsb alc>
                    {Sizer}
                    <MinMax onToggleMinMax={this.ToggleMinMax} />
                </SC.FRow>   
            )
        }
        
        return (
            <SC.FRow alc f1>
                {columnSizer}
            </SC.FRow>
        );
    }
}