import ReactDOM from 'react-dom';
import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppLayout,
    Events,
    MetaData,
    Globals,
    Strings
} from '../../../../importer';

import { motion, useMotionValue } from 'framer-motion';
import { TokenGroup, TokenPanelHeader, ItemNameEditor, TokenItemBox } from '../../../../views/project/panels/left/designsystem/common';
import { LeftScrollPanel } from '../../../../views/project/panels/left/common';
import { StyleGroupTitle, ToggleButton, Seperator, SmallButtonGroup } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import NumberInput  from '../../../../components/editors/input_number';
import { FigmaIcon_Layout_Padding, FigmaIcon_Layout_Spacing } from '../../common';
import Switch from '../../../../components/editors/Switch';
import { FlexDirectionPaths } from '../../../../views/project/panels/right/iteminspector/styleitems/layout';
import { SpaceSelectorWidget, SpaceSelectorPopup } from '../../../../views/project/panels/right/iteminspector/styleitems/paddingAndMargin';
import { FlexBoxOption } from '../../../../views/project/panels/right/iteminspector/styleitems/layout/flex';

const FlexValues = MetaData.Properties.FlexValues;

export default class FigmaAutoLayoutItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onFigmaLayoutChanged = this.onFigmaLayoutChanged.bind(this);
        
        this.Ref_Name = React.createRef();

        this.SidesWithValue = {};
        this.SelectSpace = this.SelectSpace.bind(this);
        this.ClosePopup = this.ClosePopup.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.SetTokenBinding = this.SetTokenBinding.bind(this);

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
        }
        else {
            this.Load(this.props.id);            
        }
    }
    Load(id) {
        if (!this.props.newModel) {
            this.token = Globals.ProjectManager.Tokens.Token(id);
        }
            
        this.EditingModel = {
            horizontalPadding : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'horizontalPadding', defaultValue : 10}),
            verticalPadding : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'verticalPadding', defaultValue : 10}),
            spacing : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'spacing', defaultValue : 10}),
            vertical : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'vertical', defaultValue : false}),
            fixed : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'fixed', defaultValue : false}),
            SpaceTokenId : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'SpaceTokenId'}),
            justify : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'justify'}),
            align : Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : 'align'}),
        };        
        if (this.EditingModel.SpaceTokenId) {
            const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(this.EditingModel.SpaceTokenId);            
            
            if (tokenModel) {
                this.EditingModel.SpaceToken = {
                    value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, this.props.StateArray),
                    unit : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(tokenModel, null, this.props.StateArray),
                    name : tokenModel.name,
                    pattern : tokenModel
                }
            }  
        }

        this.SidesWithValue = {};
        this.propNames = [];
        Utils.BorderStyles.Sides.map((side, i) => {            
            const propValue =  Utils.BorderStyles.propPadding(side);
            this.propNames.push(propValue);
            const useSideName = Utils.UseNullOrEmpty(side, 'All');            
            this.SidesWithValue[useSideName] = Globals.ProjectManager.Tokens.ValueOf({model : this.token, name : propValue});

            if (this.SidesWithValue[useSideName]) {                
                this.hasNoSpacing = false;
                const tokenId = this.SidesWithValue[useSideName];
                const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(tokenId);
                this.SidesWithValue[useSideName] = {
                    propName : propValue,
                    TokenId : tokenId
                }
                
                if (tokenModel) {
                    this.SidesWithValue[useSideName].value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, this.props.StateArray);
                    this.SidesWithValue[useSideName].unit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(tokenModel, null, this.props.StateArray);
                    this.SidesWithValue[useSideName].name = tokenModel.name;
                    this.SidesWithValue[useSideName].pattern = tokenModel;
                }    
            }
        });  
        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.GlobalState !== this.props.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!this.props.newModel) {
                this.Load(nextProps.id);
                if (!this.token) {
                    this.props.onCancel();
                    return false;
                }                
            }
        }
        if (this.props.id !== nextProps.id)
            this.Load(nextProps.id);
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts);
    }        
    onFigmaLayoutChanged() {      
        if (this.props.newModel)
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel);
        else {            
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts                    
                }
            ]);
        }
            
        this.RCThrottledUpdate_1();
    }    

    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    SetProp(prop, value) {
        this.EditingModel[prop] = value;
        if (this.props.id) {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id, 
                value : value,
                name : prop,
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
        }
        else {
            Globals.ProjectManager.Tokens.SetValueOf({
                model : this.token,
                value : value,
                name : prop,
                state : this.props.isNew ? Strings.DEFAULT : this.props.GlobalState
            });
            this.Load();
        }
        this.onFigmaLayoutChanged();
    }
    SelectSpace(type, side) {
        this.showEditor = type;
        if (!this.IsClosingPopup) {
            const title = side.toUpperCase() + ' ' + (type === 'padding' ? 'PADDING' : 'SPACING');
            if (this.ShowSpaceSelector) {
                if (this.ShowSpaceSelector.side === side) {
                    this.ClosePopup();
                }
                else {
                    this.ShowSpaceSelector.side = side;
                    this.ShowSpaceSelector.title = title;
                    this.ShowSpaceSelector.doNotClose = true;
                }                
            }
            else {
                const bounds = UIUtils.Utils.GetBounds(this);                
                this.ShowSpaceSelector = {
                    side : side,
                    title : title,
                    maxHeight : bounds.height - 200,
                    toBottom : true
                };
            }            
            
            this.RCUpdate();
        }
            
        this.IsClosingPopup = false;
        
    }
    ClosePopup() {
        this.IsClosingPopup = true;
        delete this.ShowSpaceSelector;
        this.RCUpdate();
        setTimeout(() => {
            this.IsClosingPopup = false;                                
        }, 200); 
    }   
    RemoveTokenBinding(side) {         
        if (side === 'single') {
            this.SetProp('SpaceTokenId', null);
        }
        else {
            Utils.ForEach(this.SidesWithValue, (value, key) => {
                if (value && value.TokenId) {
                    this.SetProp(value.propName, null);
                }            
            });
        }        
        this.Load();
    }
    SetTokenBinding(tokenId) {
        if (this.ShowSpaceSelector.side === 'single') {
            if (this.EditingModel.SpaceTokenId === tokenId)
                this.EditingModel.SpaceTokenId = null;
            else
                this.EditingModel.SpaceTokenId = tokenId;
            this.SetProp('SpaceTokenId', this.EditingModel.SpaceTokenId);
        }
        else {
            const currentTokenId = Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId');
            const propName = Utils.BorderStyles.propPadding(this.ShowSpaceSelector.side);
            this.SetProp(propName, currentTokenId === tokenId ? null : tokenId);
        }        
        this.Load();
    }
    renderCustom() {            
        let justifyValue = Utils.JustGet(this.EditingModel, 'center', 'justify');
        let alignValue = Utils.JustGet(this.EditingModel, 'center', 'align');

        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 1fr',
                    gridGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center'
                }}>
                    <StyleGroupTitle >Direction</StyleGroupTitle>
                    <SC.FRow justifyEnd>
                        {
                            ['row', 'column'].map((direction, i) => {
                                const selected = (this.EditingModel.vertical  ? 1 : 0) === i;
                                return (
                                    <SmallButtonGroup 
                                        key={i}
                                        hasBorders size={20} 
                                        first={i===0}
                                        last={i===3}
                                        onClick={selected ? null : this.SetProp.bind(this, 'vertical', i === 1)} 
                                        selected={selected} 
                                        title={`Flex ${MetaData.Properties.FlexLabels[direction]}`}
                                        style_svg={{transform : `rotate(${i*90}deg)`}}
                                        style={{width : '30px', height : '28px', marginRight : i === 0 ? '4px' : 0}}
                                    >
                                        <FlexDirectionPaths selected={selected} />
                                    </SmallButtonGroup>
                                )                                        
                            })
                        }
                    </SC.FRow>
                    <StyleGroupTitle >Justify</StyleGroupTitle>
                    <SC.FRow alc justifyEnd>
                        {
                            [
                                FlexValues.FLEXSTART,
                                FlexValues.FLEXCENTER,                                
                                FlexValues.FLEXEND,
                                FlexValues.FLEXSPACEBETWEEN
                            ].map((jv, i) => {
                                return (
                                    <SmallButtonGroup
                                        key={jv}
                                        first={i===0}
                                        last={i===5}
                                        noSvg
                                        hasBorders                
                                        onClick={this.SetProp.bind(this, 'justify', jv)}
                                        title={MetaData.Properties.FlexLabels[jv]}
                                        style={{width : '30px', height : '28px', padding : '2px', marginRight : i < 5 ? '1px' : 0}}
                                        selected={jv === justifyValue}
                                    >
                                        <FlexBoxOption
                                            fd={this.EditingModel.vertical ? 'column' : 'row'}
                                            jct={jv}
                                            alit={alignValue}
                                            selected={jv === justifyValue}                                                            
                                            vertical={this.EditingModel.vertical}
                                        />
                                    </SmallButtonGroup>
                                )
                            })
                        }
                    </SC.FRow>
                    <StyleGroupTitle >Align</StyleGroupTitle>
                    <SC.FRow alc justifyEnd>
                        {
                            [
                                FlexValues.FLEXSTART,
                                FlexValues.FLEXCENTER,
                                FlexValues.FLEXEND
                            ].map((alit, i) => {
                                return (
                                    <SmallButtonGroup
                                        key={alit}
                                        first={i===0}
                                        last={i===3}
                                        noSvg
                                        hasBorders                
                                        onClick={this.SetProp.bind(this, 'align', alit)}
                                        title={MetaData.Properties.FlexLabels[alit]}
                                        style={{width : '30px', height : '28px', padding : '2px', marginRight : i < 3 ? '1px' : 0}}
                                        selected={alit === alignValue}
                                    >
                                        <FlexBoxOption
                                            fd={this.EditingModel.vertical ? 'column' : 'row'}
                                            jct={justifyValue}
                                            alit={alit}
                                            selected={alit === alignValue}                                                            
                                            vertical={this.EditingModel.vertical}
                                            forAlignment
                                        />
                                    </SmallButtonGroup>
                                )
                            })
                        }
                    </SC.FRow>
                    {
                        justifyValue !== 'space-between' && 
                        <SpaceSelectorWidget 
                            singleSide
                            showEditor={this.showEditor === 'spacing'}
                            title='Spacing Between Items'
                            onRemove={this.RemoveTokenBinding.bind(this, 'single')}
                            onSelectSpace={this.SelectSpace.bind(this, 'spacing')}
                            value={this.EditingModel.SpaceToken}
                            ShowSpaceSelector={this.ShowSpaceSelector}
                            // isOver={this.ShowSpaceSelector ? this.canCloseSelector.bind(this, this.ShowSpaceSelector.side) : null}
                            onClose={this.ClosePopup}
                            onSetTokenBinding={this.SetTokenBinding}
                            onPreviewToken={this.props.onPreviewToken}
                            tokenId={this.EditingModel.SpaceTokenId}
                            getPropName={() => {return 'spacing'}}
                            style={{padding : 0, gridColumnStart : 'span 2', marginTop : '8px'}}
                            styleHeader={{marginBottom : '8px'}}
                        />
                    }
                    <SpaceSelectorWidget 
                        showEditor={this.showEditor === 'padding'}
                        title='Paddings'
                        onRemove={this.RemoveTokenBinding.bind(this, 'padding')}
                        onSelectSpace={this.SelectSpace.bind(this, 'padding')}
                        hasTop={this.SidesWithValue.Top}
                        hasLeft={this.SidesWithValue.Left}
                        hasAll={this.SidesWithValue.All}
                        hasRight={this.SidesWithValue.Right}
                        hasBottom={this.SidesWithValue.Bottom}
                        ShowSpaceSelector={this.ShowSpaceSelector}
                        // isOver={this.ShowSpaceSelector ? this.canCloseSelector.bind(this, this.ShowSpaceSelector.side) : null}
                        onClose={this.ClosePopup}
                        onSetTokenBinding={this.SetTokenBinding}
                        onPreviewToken={this.props.onPreviewToken}
                        tokenId={this.ShowSpaceSelector ? Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId') : null}
                        getPropName={Utils.BorderStyles.propPadding}
                        style={{padding : 0, gridColumnStart : 'span 2', marginTop : '8px'}}
                        styleHeader={{marginBottom : '8px'}}
                    />
                                     
                    {/* <SpaceSelectorWidget 
                        showEditor={this.showEditor === 'spacing'}
                        title='Item Spacing'
                        onRemove={this.RemoveTokenBinding}
                        onSelectSpace={this.SelectSpace.bind(this, 'spacing')}
                        hasTop={this.SidesWithValue.Top}
                        hasLeft={this.SidesWithValue.Left}
                        hasAll={this.SidesWithValue.All}
                        hasRight={this.SidesWithValue.Right}
                        hasBottom={this.SidesWithValue.Bottom}
                        ShowSpaceSelector={this.ShowSpaceSelector}
                        // isOver={this.ShowSpaceSelector ? this.canCloseSelector.bind(this, this.ShowSpaceSelector.side) : null}
                        onClose={this.ClosePopup}
                        onSetTokenBinding={this.SetTokenBinding}
                        onPreviewToken={this.props.onPreviewToken}
                        tokenId={this.ShowSpaceSelector ? Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId') : null}
                        getPropName={Utils.BorderStyles.propPadding}
                        style={{padding : 0, gridColumnStart : 'span 2', marginTop : '8px'}}
                    /> */}
                </div>
            </SC.FCol>
        );
        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr auto auto',
                    gridGap : '4px',
                    gridRowGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center',
                    paddingBottom : '4px'
                }}>
                    <StyleGroupTitle >Direction</StyleGroupTitle>
                    <ToggleButton selected={!this.EditingModel.vertical} onClick={this.SetProp.bind(this, 'vertical', false)}>Horizontal</ToggleButton>
                    <ToggleButton selected={this.EditingModel.vertical} onClick={this.SetProp.bind(this, 'vertical', true)}>Vertical</ToggleButton>
                    <StyleGroupTitle >{this.EditingModel.vertical ? 'Width' : 'Height'}</StyleGroupTitle>
                    <ToggleButton selected={!this.EditingModel.fixed} onClick={this.SetProp.bind(this, 'fixed', false)}>Auto</ToggleButton>
                    <ToggleButton selected={this.EditingModel.fixed} onClick={this.SetProp.bind(this, 'fixed', true)}>Fixed</ToggleButton>
                </div>
                <Seperator />
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr auto auto',
                    gridGap : '4px',
                    gridRowGap : '8px',
                    // marginTop : '16px',
                    alignItems : 'center'
                }}>
                    <StyleGroupTitle >Horizontal Padding</StyleGroupTitle>
                    <FigmaIcon_Layout_Padding />
                    <LayoutInput  value={this.EditingModel.horizontalPadding} onChange={this.SetProp.bind(this, 'horizontalPadding')}/>
                    <StyleGroupTitle >Vertical Padding</StyleGroupTitle>
                    <FigmaIcon_Layout_Padding style={{transform : 'rotate(90deg)'}} />
                    <LayoutInput  value={this.EditingModel.verticalPadding} onChange={this.SetProp.bind(this, 'verticalPadding')} />
                    <StyleGroupTitle >Spacing Between Items</StyleGroupTitle>
                    <FigmaIcon_Layout_Spacing style={{justifySelf : 'center'}} />
                    <LayoutInput value={this.EditingModel.spacing} onChange={this.SetProp.bind(this, 'spacing')} />
                </div>
            </SC.FCol>
        )
    }
}

const LayoutInput = ({value, onChange, onChanging}) => {
    return (
        <NumberInput
            value={value} 
            onChange={onChange}
            onChanging={onChanging}
            numeralPositiveOnly
            boxStyle={{
                width : '44px',
                height : '24px',
                marginLeft: '4px',
                backgroundColor : SC.CurrentTheme.theme.input_back,
                opacity : 1
            }}
            style={{                                    
                width : '100%',
                textAlign : 'center',
                fontSize : '12px',                
                padding : 0,
                ...SC.Styles.FontStyles.Monospace
            }}
        />
    )
}

 