import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    UIUtils
} from '../../../../importer';
import VisibilitySensor from 'react-visibility-sensor';
import {Grid, PreviewBox, QuickBrown, FontSelection_Preview} from './common';

import FontStyleSheetManager from '../../../../db/FontStyleSheetManager';
import { FontLoader } from '../../../../toolabs-importer';


export default class GoogleFonts extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    
        this.UpdateScale(this.props);

        this.FontList = [];
        this.Ref_Scroll = React.createRef();
    }
    UpdateScale(props) {
        this.fontSize = Math.max(8, 80 * (props.scale || 0.5));
        this.gridCellWidth = Math.max(200, 800 * (props.scale || 0.5));
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadFonts();           
    }
    LoadFonts() {
        this.FontList = [];
        FontStyleSheetManager.GetFonts({sort : this.props.sorting}).then(
            (List) => {
                // this.FontList = List;                
                List.map((font, i) => {
                    
                    const variants = [];
                    if (font.variants) {
                        font.variants.map((variant, i) => {
                            this.FontList.push({
                                id : Utils.Id() + this.FontList.length,
                                font : font,
                                variant : variant
                            });
                        });
                    }
                });
                this.LastIndex = 0;
                this.LoadedList = [];
                this.LoadPage();
            }
        )
    }
    LoadPage() {
        let added = 0;
        
        let filter;
        if (this.props.FilterText)
            filter = this.props.FilterText.toUpperCase();
        while (added < 20) {
            if (this.LastIndex < this.FontList.length) {
                const font = this.FontList[this.LastIndex];
                this.LastIndex++;
                let willAdd = true;
                if (this.props.categories) {
                    if (this.props.categories[font.font.category])
                        willAdd = false;
                }
                if (willAdd) {
                    if (filter) {
                        const name = `${font.font.category} ${font.font.family} ${font.variant}`.toUpperCase();
                        if (name.indexOf(filter) < 0) {
                            willAdd = false;
                        }
                    }
                    if (willAdd) {
                        this.LoadedList.push(font);
                        added++;
                    }
                }                
            }
            else
                break;
        }
        if (added > 0) {            
            this.LoaderId = Utils.Id();
        }
        else {
            this.LoaderId = null;
        }
                
        setTimeout(() => {
            this.RCUpdate();
        }, 1);        
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
         if (nextProps.scale !== this.props.scale) {
             this.UpdateScale(nextProps);
         }
         return true;
    }
    componentDidUpdate(prevProps, prevState) {
        if (Utils.HasAnyChange(prevProps, this.props, 'FilterText', 'categories')) {
            this.LastIndex = 0;
            this.LoadedList = [];
            this.Ref_Scroll && this.Ref_Scroll.current && this.Ref_Scroll.current.scrollTop(0);
            this.LoadPage();
        }
        if (prevProps.sorting !== this.props.sorting) {
            this.LoadFonts();
        }        
    }
    renderCustom() {
        if (this.LoadedList) {
            const fonts = [];
            Utils.ForEach(this.LoadedList, (font, i) => {
                const fontItem = (
                    <FontPreview_Variant
                        font={font.font}
                        key={font.id}
                        variant={font.variant}
                        onSelectFont={this.props.onSelectFont}
                        PreviewText={this.props.previewText}
                        dark={this.props.dark}
                        visible
                        fontSize={this.fontSize}
                        onPreview={this.props.onPreview}
                    />
                )                
                if (i === this.LoadedList.length - 1 && this.LoaderId) {
                    fonts.push(
                        <VisibilitySensor
                            key={this.LoaderId}
                            partialVisibility={true}
                            scrollCheck={true}
                            scrollDelay={200}
                        >
                            {
                                ({isVisible}) => {                                    
                                    if (isVisible && !font.visible) {
                                        this.LoadPage();
                                    }
                                    return <div />;
                                }                        
                            }
                        </VisibilitySensor>
                    )
                }
                
                fonts.push(fontItem);
            });
            return fonts;
        }

        return (
            <SC.Div_Flex_Cell fw fh>
                <SC.Loading_Icon />
            </SC.Div_Flex_Cell>
        )
    }
}



class FontPreview_Variant extends React.Component
{
    constructor(props) {
        super(props);
        this.SelectFont = this.SelectFont.bind(this);

        this.state = {};
    }
    componentDidMount() {
        this.Load();
    }
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.Loaded !== nextState.Loaded || this.props.fontSize !== nextProps.fontSize || this.props.dark !== nextProps.dark || this.props.PreviewText !== nextProps.PreviewText)
            return true;
        return false;
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.visible && this.props.visible && !this.state.Loaded)
            this.Load();
    }
    Load() {
        setTimeout(() => {
            FontStyleSheetManager.LoadTempFont(this.props.font.family, this.props.variant, `https://fonts.googleapis.com/css?family=${this.props.font.family}:${this.props.variant}`).then((id) => {
                this.FontLinkId = id;
                this.setState({Loaded : true});
            })
        }, 0);
    }
    componentWillUnmount() {
        if (this.FontLinkId && !this.willNotUnload) {
            FontStyleSheetManager.UnloadFont(this.FontLinkId, this.props.font.family, this.props.variant);            
        }
    }
    SelectFont() {
        this.willNotUnload = true;
        const {font} = this.props;
        this.props.onSelectFont({
            provider : Strings.FONT_GOOGLE,
            category : font.category,
            family : font.family,
            variant : this.props.variant,
            url : `https://fonts.googleapis.com/css?family=${this.props.font.family}:${this.props.variant}`
        });
    }
    Preview(active) {
        if (this.props.onPreview) {
            const {font} = this.props;
            if (active) {
                this.props.onPreview({
                    provider : Strings.FONT_GOOGLE,
                    category : font.category,
                    family : font.family,
                    variant : this.props.variant,
                    url : `https://fonts.googleapis.com/css?family=${this.props.font.family}:${this.props.variant}`
                });
            }
            else
                this.props.onPreview();
        }                    
    }
    render() {
        const {font} = this.props;
        let content;

        if (!this.state.Loaded) {
            content = (
                <FontSelection_Preview dark={this.props.dark}>
                    Loading {font.variant}
                </FontSelection_Preview>
            );
        }
        else {
            let variant = this.props.variant;
            const style = {
                fontFamily : font.family
            };
            if (variant && variant.indexOf('italic') > -1) {
                style.fontStyle = 'italic';
                variant = variant.replace('italic', '');
            }

            if (variant !== 'regular')
                style.fontWeight = variant;

            content = (
                <FontSelection_Preview dark={this.props.dark} style={{fontSize : Utils.px(this.props.fontSize)}}>
                    <span style={style}>
                        {this.props.PreviewText || QuickBrown}
                    </span>
                </FontSelection_Preview>
            )
        }

        return (
            <PreviewBox
                name={`${font.family} ${this.props.variant}`.toUpperCase()}
                SelectFont={this.SelectFont}
                onMouseEnter={this.props.onPreview ? this.Preview.bind(this, true) : null}
                onMouseLeave={this.props.onPreview ? this.Preview.bind(this, false) : null}
                {...this.props}
            >
                {content}
            </PreviewBox>
        )
    }
}
