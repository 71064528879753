import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';

// views/project/panels/right/iteminspector/selectToken';

export default class FigmaDSToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    ShowPanel(props) {
        this.setState({
            ShowPanelProps : props
        })
    }
    render() { 
        let content_panel;
        if (this.state.ShowPanelProps) {
            content_panel = (
                <motion.div 
                    style={{
                        ...SC.Styles.Absolute, 
                        zIndex : 999999999, 
                        boxSizing : 'border-box',
                        borderRight : SC.CurrentTheme.theme.border_ondark,
                        borderLeft : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back,
                    }}
                    initial={{opacity : 0.7, x : -24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : -24}}
                    transition={{duration : 0.1}}
                >
                    <SC.FCol fw fh>
                        <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                            <SelectToken                                         
                                onClose={this.CloseTokenSelector}
                                onPreviewToken={this.PreviewToken}
                                GlobalState={this.props.GlobalState}
                                GlobalStateId={this.props.GlobalStateId}
                                themeId={this.props.themeId}
                                onSetPropertyValues={this.SetItemPropertyValues}
                                onBindToModel={this.BindToModel}
                                GetComponentManager={this.GetComponentManager}
                                GetMetaItem={this.GetMetaItem}
                                onClose={() => {
                                    this.setState({
                                        ShowPanelProps : null
                                    })
                                }}
                                onClosed={() => {                                            
                                }}
                                {...this.state.ShowPanelProps}
                            />
                        </SC.FCol>                    
                    </SC.FCol>
                </motion.div>                
            )
        }
        return (
            <AnimatePresence>
                {content_panel}
            </AnimatePresence> 
        )
    }
}
 
