import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Events,
    MetaData
} from '../../../../../../../../importer';

import { SmallButtonGroup } from '../../common';
import { LOG_TYPES } from '../../../../../../../../appstate/historymanager/base';
import ComponentDesignManager from '../../../../../../component/manager';
import { TextAlignLines } from '../../text';


const FlexValues = MetaData.Properties.FlexValues;
const Flex = MetaData.Properties.layout.values.Flex;

export const GridCellBox = ({direction, value, onClick, style,  grouped, boxed, stylebutton, defaultValue}) => {
    const style_button = {minWidth : '30px', minHeight : '28px', width : '30px', height : '28px', marginRight : '1px', ...stylebutton};
    
    let selectedIndex = -1;
    if ('flex-start' === value || value === FlexValues.FLEXSTART || (!value && 'flex-start' === (defaultValue || 'stretch')))
        selectedIndex = 0;
    else if ('center' === value || value === FlexValues.FLEXCENTER || (!value && 'center' === (defaultValue || 'stretch')) )
        selectedIndex = 1;
    else if ('flex-end' === value || value === FlexValues.FLEXEND || (!value && 'flex-end' === (defaultValue || 'stretch')))
        selectedIndex = 2;
    else if ('stretch' === value || value === FlexValues.FLEXSTRETCH || (!value && 'stretch' === (defaultValue || 'stretch')))
        selectedIndex = 3;

    let vertical = direction === 'column';
    
    return (
        <SC.FRow jsb f1 style={style}>
            <SmallButtonGroup  
                first
                grouped={grouped}
                noSvg 
                style={style_button} 
                hasBorders
                selected={selectedIndex === 0 }
                onClick={(e) => {e.stopPropagation(); onClick(FlexValues.FLEXSTART)}}
            >
                <TextAlignLines boxed={boxed} vertical={vertical} selected={selectedIndex === 0} align='flex-start' />
            </SmallButtonGroup>
            <SmallButtonGroup 
                middle={grouped}
                noSvg style={style_button} 
                hasBorders
                selected={selectedIndex === 1}
                onClick={(e) => {e.stopPropagation(); onClick(FlexValues.FLEXCENTER)}}
            >
                <TextAlignLines boxed={boxed} vertical={vertical} selected={selectedIndex === 1} align='center' />
            </SmallButtonGroup>
            <SmallButtonGroup 
                middle={grouped}
                noSvg style={style_button} 
                selected={selectedIndex === 2}
                hasBorders
                onClick={(e) => {e.stopPropagation(); onClick(FlexValues.FLEXEND)}}
            >
                <TextAlignLines boxed={boxed} vertical={vertical} selected={selectedIndex === 2} align='flex-end' />
            </SmallButtonGroup>
            <SmallButtonGroup 
                last
                grouped={grouped}
                noSvg style={style_button} last 
                selected={selectedIndex === 3}
                hasBorders
                onClick={(e) => {e.stopPropagation(); onClick(FlexValues.FLEXSTRETCH)}}
            >
                <TextAlignLines boxed={boxed} vertical={vertical} selected={selectedIndex === 3} align='stretch' />
            </SmallButtonGroup>            
        </SC.FRow>    
    )   
};