import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Loading,
    Events,
    UIUtils
} from '../../../importer';

import styled from 'styled-components';


const Box_Angle = styled.div`
    background-color : ${props => props.theme.back};
    border : ${props => props.theme.border_ondark};
    width : 72px;
    height : 30px;
    font-size : 12px;
    display : flex;
    flex-direction : row;
    justify-content : space-around;
    align-items : center;
    padding-left : 4px;
    padding-right : 4px;
    box-sizing : border-box;
    border-radius : 2px;
`;
const Input_Angle = styled.input`
    font-size : 16px;
    font-weight : 500;
    border : none;
    width : 50%;
    background : transparent;
    text-align : right;
    padding-right : 6px;
`;

export default class GradientAngle extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            angle : this.props.angle || 0
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.angle !== nextProps.angle) {
            this.state.angle = nextProps.angle;
            return true;
        }
        if (this.state.angle !== nextState.angle)
            return true
        return false;
    }
    UpdateAngle(angle) {
        this.setState({angle : angle});
    }
    render() {
        return (
            <Box_Angle>
                <Input_Angle value={Utils.ToInteger(this.state.angle) || 0} onChange={this.props.onChange} onKeyUp={(e) => e.stopPropagation()} />
                <span>deg</span>
            </Box_Angle>
        )
    }
}
