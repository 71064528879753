module.exports = {
  "metadata": {
    "version": {
      "current": 1010102,
      "desc": {
        "1": "Inplace Component Edit"
      },
      "history": [
        {
          "version": 1010101,
          "desc": {
            "1": "Before Inplace Component Edit"
          }
        }
      ]
    }
  },
  "build": {
    "no": 1281,
    "date": "Fri Nov 02 2018 15:31:28 GMT+0300 (GMT+03:00)"
  }
}