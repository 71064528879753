const DemoProjectMap = {
    ProjectId : '-M9bRKB_F5D9PhdepBJl',
    States : {
        Dark : 'gFZPAFWg0',
        Language : 'ZlviJOece',
        Spanish : 'Esbyi4fxoC',
        German : '6fzRv-se12'
    },
    Tokens : {
        Colors : {
            Primary : 'HxoeUlRi81s',
            Primary_200 : 'X4kWgBicSpJ',
            Primary_300 : 'hqVtsOXd-hj',
            Primary_400 : 'LhjT3tqqjki',            
            Primary_600 : 'cR-4hT9e6iy',
            Primary_700 : 'yi9IW3FGgsB',
            Primary_800 : 'oX171vJFWzg',
            Gray : 'U9wPkNTnk68',
            Gray_200 : 'efCAQpM3b8e',
            Gray_300 : 'SfHL8sgE8vl',
            Gray_400 : 'PmdWJekMHkh',            
            Gray_600 : '0N7Rc_v63cW',
            Gray_700 : 'wpqaGEHmM-Z',
            Gray_800 : 'vghEhoqSIFv',
            White : 'uQ_w0EftGp3',

            BackgroundColor : 'd1zSEmeexq35',
            TextColor : 'O5ElceE4doRt',
        },
        TimePatterns : {
            "x-fast" : '27Rs1glBzOr',
            'fast' : 'xkqhskRa0G1',
            'normal' : 'mfXsjVYM6wx',
            'slow' : '8XpCyb-a5C9',
            'x-slow' : 'AXaWJLjQj5x'
        },
        SpacePatterns : {
            "xx-small" : 'fpFsA6t0-tV',
            'x-small' : '4sJjDV1Gskb',
            'small' : 'ktM1mOAJHxr',
            'regular' : 'PooQhSCnTIw',
            'large' : 'AeHm7jdmDpy',
            'x-large' : 'xihIdq7nc3I',
            'xx-large' : 'deQ1xAsBskX'
        },
        TextPatterns : {
            d1 : 'o_RK593CYO',
            d2 : 'vJOKtYgb0l',
            h1 : 'VMVYOx7tvi',
            h2 : 'OjAraNwJIcQ',
            h3 : 'S3YTLLAwgxZ',
        },
        Shadows : {
            Elevate1 : '7KbevDEuN'
        },
        Eases : {
            in : 'Sm3LMDxr3RkJ',
            out : '6Y868L2vMj4M',
            inout : 't9uzQ_RQTeU3'
        }
    }
};

export default DemoProjectMap;