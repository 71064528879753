import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Events,
    AppState,
    MetaData,
    Utils,
    Strings,
    ReactPureComponent
} from '../../../../../../../importer';

// import VariablePicker from './variablePicker';
const VariablePicker = () => <div>Picker</div>

export default class VariableBinder extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }    
    
    renderCustom() {   
        let fill = SC.CurrentTheme.theme.icon;
        // if (this.props.modelId)
        //     fill = '#808284';
        const style = Utils.Merge({
            // border : '1px solid ' + fill,
            // width : '20px',
            // height : '20px',
            boxSizing : 'border-box',
            // paddingRight: '1px',
            cursor : 'pointer',
            marginLeft : '6px'
        }, this.props.style);
        
        let picker;

        if (this.showPicker) {
            picker = (
                <VariablePicker 
                    isClosing={this.isClosing}
                    position={this.showPicker}
                    pickerId={this.showPicker.id}
                    onClose={this.CloseSizeTokenPicker}
                    dataType={this.props.dataType}
                    selectedId={this.props.modelId}
                    onSelectModel={this.props.onSelectModel}
                    value={this.props.value}
                    unit={this.props.unit}
                    onGetValue={this.props.onGetValue}
                />
            )
        }

        return (
            <SC.Div_Flex_Cell 
                style={style}
                title='Variable'
                onClick={this.props.onSelect}
            >
                <VariableIcon 
                    size={15}
                    selected={this.props.modelId ? true : false}
                    circleFill={this.props.modelId ? SC.CurrentTheme.theme.icon : "none"} 
                    circleStroke={this.props.modelId ? 'none' : fill}
                    fill={fill}
                />                
                {picker}
            </SC.Div_Flex_Cell>
        )       
    }
}

export const VariableIcon = (props) => {
    return (
        <SC.Icons.Icon_Button hasCursor hasStroke stroke={props.selected ? SC.CurrentTheme.theme.color_brand : '#7b7b7b'}>
            <SC.Svg width="13" height="19" viewBox="0 0 13 19">   
                <g transform="translate(3 6)" fill="none" strokeWidth="1">
                    <circle cx="3.5" cy="3.5" r="3.5" stroke="none"></circle>
                    <circle cx="3.5" cy="3.5" r="3" fill="none"></circle>
                </g>
                <path d="M212,160.5h-3.5v5" transform="translate(-208 -160)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path>
                <path d="M220.313,160.5h3.5v5" transform="translate(-211.313 -160)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path>
                <path d="M208.5,178.5v5H212" transform="translate(-208 -165)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path>
                <path d="M220,183.5h3.5v-4.95" transform="translate(-211 -165)" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1"></path>
            </SC.Svg>
        </SC.Icons.Icon_Button>        
    );
}

export const RefreshMockupValueButton = (props) => {
    return (
        <SC.Icons.Icon_Button 
            hasFill 
            style={{marginLeft : '8px', cursor : 'pointer'}} 
            title='Generate New Mockup Value'
            onClick={props.onRefresh}
        >
            <SC.Icons.Icon_Refresh 
                size={20}                            
            />
        </SC.Icons.Icon_Button>
    )
}