import {
    Utils
} from '../../../../../importer';

import Container from './container';
import Text from './text';
import Icon from './icon';
import Image from './image';
import Widget from './widgets';
import Slot from './slot';
import Repeater from './repeater';
import SubComponent from './subcomponent.js'
import GenericComponent from './generics';
import MetaData from '../../../../../../toolabs-modules/toolabs-metaui';


export const GetItemType = (ItemType, Draggable, GenericType) => {    
    if (GenericType) {
        const IsSubType = Utils.JustGet(GenericType, null, 'SubType');
        const RootType = Utils.JustGet(GenericType, null, 'Type');
        if (!IsSubType || RootType === ItemType)
            return GenericComponent;
    }
    if (ItemType === MetaData.Components.Text.Type)
        return Text;    
    else if (ItemType === MetaData.Components.SvgIcon.Type)
        return Icon;
    else if (ItemType === MetaData.Components.Image.Type)
        return Image;
    else if (ItemType === MetaData.Components.Widget.Type)
        return Widget;
    else if (ItemType === MetaData.Components.Content.Type)
        return Slot;
    else if (ItemType === MetaData.Components.Repeater.Type)
        return Repeater;
    else if (ItemType === MetaData.Components.Component.Type)
        return SubComponent;


    return Container;
}
