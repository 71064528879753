import React from 'react';
import ReactDOM from 'react-dom';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../importer';
import styled from 'styled-components';
import { TokenItemBox, TokenGroup, TokenPanelHeader, GroupTitle } from '../../../views/project/panels/left/designsystem/common';

import Colors from '../../../views/project/panels/left/designsystem/colors';
import Typography from '../../../views/project/panels/left/designsystem/typography';
import FontStyleEditor from '../../../views/project/panels/left/designsystem/typography/fonts/item';
import Shadows from '../../../views/project/panels/left/designsystem/shadows';
import Spacings from '../../../views/project/panels/left/designsystem/spacing';
import Borders from '../../../views/project/panels/left/designsystem/borders';
import MotionSystem from '../../../views/project/panels/left/designsystem/motion';
import Transforms from '../../../views/project/panels/left/designsystem/transforms';
import Filters from '../../../views/project/panels/left/designsystem/filters';
import Images from '../../../views/project/panels/left/designsystem/images';
import Iconography from '../../../views/project/panels/left/designsystem/icons';
import Sounds from '../../../views/project/panels/left/designsystem/sounds';
import TextContents from '../../../views/project/panels/left/designsystem/textContent';
import Booleans from '../../../views/project/panels/left/designsystem/booleans';
import TokenAliases from '../../../views/project/panels/left/designsystem/aliases';
import FigmaAutoLayouts from './autolayouts';

import FigmaDSToolbar, { FigmaSystemStates } from './toolbar';
import { LeftScrollPanel, OutsideCloseDotMenuContainer } from '../../../views/project/panels/left/common';
import { motion, AnimatePresence  } from 'framer-motion';

import DropDownSelect from '../../../components/editors/enum_dropdown';
import Switch from '../../../components/editors/Switch';

import ChromaJs from 'chroma-js';
import { TokenTypes } from '../../../views/project/manager/tokens';

import FigmaSelection from './selection';
import FigmaTokenUsage from './tokenUsage';
import MockupDataPanel from '../../../views/project/panels/left/toolbar/mockupdata';
import FigmaComponents from './components';
import { FigmaInfoPanel, FigmaGoToolabsItem, Figma_PopupItem, FigmaIcon_Edit, FigmaIcon_Attach, FigmaStyleMenuPopup } from '../common';
import { SearchInput } from '../../../views/project/searchbar';

export default class FigmaDesignSystem extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        this.state.GlobalStateId = Utils.Id();
        this.state.GlobalThemeId = Utils.Id();
        this.state.autoCheckChanges = true;

        this.GlobalFigmaState = this.state.GlobalStateId;

        this.onChangeTokenType = this.onChangeTokenType.bind(this);
        this.renderTokenHeader = this.renderTokenHeader.bind(this);
        this.renderDropdownItem = this.renderDropdownItem.bind(this);

        this.ToggleSerach = this.ToggleSerach.bind(this);

        this.onPanelOverlay = this.onPanelOverlay.bind(this);        

        this.Ref_Scroll = React.createRef();
        this.RefToolbar = this.props.Ref_Toolbar;
        
        this.TokenType = Globals.ProjectManager.Options.Get('all', 'LeftPanel', 'DesignSystem', 'TokenType');   

        this.HasEditGrant = Globals.ProjectManager.CheckGrant_EditSystemStates();

        super.ListenEvents(
            Events.GLOBAL.STATE_CHANGED,
            Events.GLOBAL.TOKENS_CHANGED,            
            Events.GLOBAL.TOKEN_VALUE_CHANGING,
            Events.GLOBAL.TOKEN_NAME_CHANGED,
            Events.DESIGNER.HISTORY.LIST,
            Events.DESIGNER.COMPONENT.STATE.MANAGER,
            Events.DESIGNER.COMPONENT.HISTORY.CHANGED,
            Events.GLOBAL.TOKENS_CHANGED_FROMWEB
        );

        this.state.mappingsExpanded = true;

        this.ShowTokenSyncer = this.ShowTokenSyncer.bind(this);        
                
        this.UpdateChangeStyles = this.UpdateChangeStyles.bind(this);
        this.UpdateNodeTokenMaps = this.UpdateNodeTokenMaps.bind(this);
        

        this.SelectColorToken = this.SelectColorToken.bind(this);
        this.ApplyColorToken = this.ApplyColorToken.bind(this);
        
        this.SelectColorTokenAlias = this.SelectColorTokenAlias.bind(this);
        this.ApplyColorTokenAlias = this.ApplyColorTokenAlias.bind(this);
        
        this.SelectShadowTokenAlias = this.SelectShadowTokenAlias.bind(this);        
        this.ApplyShadowTokenAlias = this.ApplyShadowTokenAlias.bind(this);        

        this.SelectSpaceTokenAlias = this.SelectSpaceTokenAlias.bind(this);        

        this.SelectRadiusTokenAlias = this.SelectRadiusTokenAlias.bind(this);
        this.ApplyRadiusTokenAlias = this.ApplyRadiusTokenAlias.bind(this);        

        this.SelectBorderTokenAlias = this.SelectBorderTokenAlias.bind(this);        
        this.ApplyBorderTokenAlias = this.ApplyBorderTokenAlias.bind(this);        
        
        this.SelectShadowToken = this.SelectShadowToken.bind(this);        
        this.ApplyShadowToken = this.ApplyShadowToken.bind(this);
        
        this.SelectTextPattern = this.SelectTextPattern.bind(this);
        this.ApplyTextPattern = this.ApplyTextPattern.bind(this);

        this.SelectSpacePattern = this.SelectSpacePattern.bind(this);
        
        this.SelectTextContent = this.SelectTextContent.bind(this);        
        this.ApplyTextContent = this.ApplyTextContent.bind(this);        

        this.SelectBoolean = this.SelectBoolean.bind(this);        
        this.ApplyBoolean = this.ApplyBoolean.bind(this);

        this.SelectLayout = this.SelectLayout.bind(this);        
        this.ApplyLayout = this.ApplyLayout.bind(this);
        
        this.SelectCornerRadius = this.SelectCornerRadius.bind(this);
        this.ApplyCornerRadius = this.ApplyCornerRadius.bind(this);
        
        this.SelectBorder = this.SelectBorder.bind(this);
        this.ApplyBorder = this.ApplyBorder.bind(this);
        
        
        this.SelectMokcupField = this.SelectMokcupField.bind(this);
        this.SelectIcon = this.SelectIcon.bind(this);
        this.ApplyIcon = this.ApplyIcon.bind(this);        
        
        this.EditMockups = this.EditMockups.bind(this);
        
        this.SelectImageToken = this.SelectImageToken.bind(this);        
        this.ApplyImageToken = this.ApplyImageToken.bind(this);
        
        this.ImportSvg = this.ImportSvg.bind(this);        
    }   
    SelectColorToken(tokenId, isGradient, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.COLOR,
            id : tokenId, 
            isGradient : isGradient,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Colors.EditColor(tokenId, isGradient);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Colors.Delete(tokenId)) {
                    AppLayout.Refs.DesignSystem.Colors.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(tokenId, deleteStyle);
                }                
            },
            onApply : ({stroke}) => {
                if (stroke)
                    this.props.GetPluginApi().BindStrokeToken(tokenId);
                else
                    this.props.GetPluginApi().BindColorToken(tokenId);
            }
        }})            
    } 
    ApplyColorToken(tokenId, isGradient, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindColorToken(tokenId);
        else
            this.SelectColorToken(tokenId, isGradient, e);
    }
    ApplyColorTokenAlias(aliase, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindColorToken(aliase.id);
        else
            this.SelectColorTokenAlias(aliase, e);
    }
    SelectColorTokenAlias(aliase, e) {
        const token = Globals.ProjectManager.Tokens.Token(aliase.id);
        const isGradient = token.type === TokenTypes.Gradients;
        e && e.preventDefault();
        e  && this.setState({showTokenActions : {
            type : TokenTypes.COLOR,
            id : aliase.id, 
            isGradient : isGradient,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.ColorAliases.EditAliaseValue(aliase);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Aliases.Delete({id : aliase.id, type : TokenTypes.COLOR})) {
                    AppLayout.Refs.DesignSystem.ColorAliases.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(aliase.id, deleteStyle);
                }                
            },
            onApply : ({stroke}) => {
                if (stroke)
                    this.props.GetPluginApi().BindStrokeToken(aliase.id);
                else
                    this.props.GetPluginApi().BindColorToken(aliase.id);
            }
        }})
    }
    ApplyShadowToken(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindShadowToken(tokenId);
        else
            this.SelectShadowToken(tokenId, e)
    }
    SelectShadowToken(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.Shadows,
            id : tokenId,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Shadows.EditShadow(tokenId);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.Shadows, tokenId)) {
                    AppLayout.Refs.DesignSystem.Shadows.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(tokenId, deleteStyle);
                }                
            },
            onApply : () => {
                this.props.GetPluginApi().BindShadowToken(tokenId);
            }
        }})    
    }
    
    ApplyShadowTokenAlias(aliase, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindShadowToken(aliase.id);
        else 
            this.SelectShadowTokenAlias(aliase, e)
    }
    SelectShadowTokenAlias(aliase, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.Shadows,
            id : aliase.id, 
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.ShadowAliases.EditAliaseValue(aliase);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Aliases.Delete({id : aliase.id, type : TokenTypes.Spaci})) {
                    AppLayout.Refs.DesignSystem.ShadowAliases.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(aliase.id, deleteStyle);
                }                
            },
            onApply : () => {
                this.props.GetPluginApi().BindShadowToken(aliase.id);
            }
        }}) 
    }
    
    SelectSpaceTokenAlias(aliase, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.SpacePatterns,
            id : aliase.id, 
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.SpaceAliases.EditAliaseValue(aliase);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Aliases.Delete({id : aliase.id, type : TokenTypes.Spacings})) {
                    AppLayout.Refs.DesignSystem.SpaceAliases.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(aliase.id, deleteStyle);
                }                
            },
            onApply : ({width, height}) => {
                this.props.GetPluginApi().BindSpaceToken(aliase.id, width, height);
            }
        }}) 
    }

    ApplyRadiusTokenAlias(aliase, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindCornerRadiusToken(aliase.id);
        else
            this.SelectRadiusTokenAlias(aliase, e);
    }
    SelectRadiusTokenAlias(aliase, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.BorderRadiuses,
            id : aliase.id, 
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.RadiusAliases.EditAliaseValue(aliase);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Aliases.Delete({id : aliase.id, type : TokenTypes.BorderRadiuses})) {
                    AppLayout.Refs.DesignSystem.RadiusAliases.Reload();    
                }              
                
                this.props.GetPluginApi().DeleteStyleOfToken(aliase.id);
            },
            onApply : () => {
                this.props.GetPluginApi().BindCornerRadiusToken(aliase.id);
            }
        }}) 
    }
    ApplyBorderTokenAlias(aliase, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindBorderToken(aliase.id);
        else
            this.SelectBorderTokenAlias(aliase, e);
    }
    SelectBorderTokenAlias(aliase, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.Borders,
            id : aliase.id, 
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.BordersAliases.EditAliaseValue(aliase);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Aliases.Delete({id : aliase.id, type : TokenTypes.Borders})) {
                    AppLayout.Refs.DesignSystem.BordersAliases.Reload();    
                }                
                this.props.GetPluginApi().DeleteStyleOfToken(aliase.id);
            },
            onApply : () => {
                this.props.GetPluginApi().BindBorderToken(aliase.id);
            }
        }}) 
    }
    ApplyIcon(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindIconToken(tokenId);
        else
            this.SelectIcon(tokenId, e);    
    }
    SelectIcon(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.Icons,
            id : tokenId,
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Icons.EditIcon(tokenId);
            },
            onApply : () => {                    
                this.props.GetPluginApi().BindIconToken(tokenId);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.Icons, tokenId)) {
                    AppLayout.Refs.DesignSystem.Icons.Reload();    
                }
                this.props.GetPluginApi().DeleteStyleOfToken(tokenId);
            }
        }})      
    }

    ApplyTextPattern(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindTextStyleToken(tokenId);
        else
            this.SelectTextPattern(tokenId, e);
    }
    SelectTextPattern(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.TextPatterns,
            id : tokenId,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.TextPatterns.EditPattern(tokenId);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.TextPatterns, tokenId)) {
                    AppLayout.Refs.DesignSystem.TextPatterns.RCUpdate();    

                    this.props.GetPluginApi().DeleteStyleOfToken(tokenId, deleteStyle);
                }                
            },
            onApply : () => {
                this.props.GetPluginApi().BindTextStyleToken(tokenId);
            }
        }})     
    }

    SelectSpacePattern(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.SpacePatterns,
            noStyle : true,
            id : tokenId,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Spacing.EditPattern(tokenId);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.SpacePatterns, tokenId)) {
                    AppLayout.Refs.DesignSystem.Spacing.RCUpdate();                       
                }     
                this.props.GetPluginApi().DeleteStyleOfToken(tokenId);           
            },
            onApply : ({width, height}) => {
                this.props.GetPluginApi().BindSpaceToken(tokenId, width, height);
            }
        }})     
    }

    ApplyTextContent(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindTextContentToken(tokenId);
        else
            this.SelectTextContent(tokenId, e);
    }
    SelectTextContent(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.TextPatterns,
            id : tokenId,
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.TextContents.EditTextContent(tokenId);
            },
            onApply : () => {
                this.props.GetPluginApi().BindTextContentToken(tokenId);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.ContentTexts, tokenId)) {
                    AppLayout.Refs.DesignSystem.TextContents.Reload();    
                }
                this.props.GetPluginApi().DeleteStyleOfToken(tokenId);
            }
        }})            
    }
    
    ApplyBoolean(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindBooleanToken(tokenId);
        else
            this.SelectBoolean(tokenId, e);
    }
    SelectBoolean(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.TextPatterns,
            id : tokenId,
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Booleans.EditBoolean(tokenId);
            },
            onApply : () => {
                this.props.GetPluginApi().BindBooleanToken(tokenId);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.Booleans, tokenId)) {
                    AppLayout.Refs.DesignSystem.Booleans.Reload();    
                }
                this.props.GetPluginApi().DeleteStyleOfToken(tokenId);
            }
        }})            
    }

    ApplyLayout(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindLayoutToken(tokenId);
        else
            this.SelectLayout(tokenId, e);
    }
    SelectLayout(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.FigmaAutoLayouts,
            id : tokenId,
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.FigmaLayouts.EditLayout(tokenId);
            },
            onApply : () => {
                this.props.GetPluginApi().BindLayoutToken(tokenId);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.FigmaAutoLayouts, tokenId)) {
                    AppLayout.Refs.DesignSystem.FigmaLayouts.Reload();    
                }
                this.props.GetPluginApi().DeleteStyleOfToken(tokenId);
            }
        }})            
    }

    ApplyCornerRadius(token, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindCornerRadiusToken(token.id);
        else
            this.SelectCornerRadius(token, e);
    }
    SelectCornerRadius(token, e) {
        if (e) {
            e.preventDefault();
            this.setState({showTokenActions : {
                type : TokenTypes.BorderRadiuses,
                id : token.id,
                y : e.clientY,
                noStyle : true,
                onEdit : () => {
                    AppLayout.Refs.DesignSystem.Borders.EditBorderRadius(token);
                },
                onApply : () => {
                    this.props.GetPluginApi().BindCornerRadiusToken(token.id);
                },
                onDelete : () => {
                    if (Globals.ProjectManager.Tokens.Delete(TokenTypes.BorderRadiuses, token.id)) {
                        AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();        
                    }     
                    this.props.GetPluginApi().DeleteStyleOfToken(token.id);           
                },
            }})
        }         
    }
    ApplyBorder(token, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindBorderToken(token.id);
        else
            this.SelectBorder(token, e);
    }
    SelectBorder(token, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.Borders,
            id : token.id,
            y : e.clientY,
            noStyle : true,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Borders.EditBorder(token);
            },
            onApply : () => {
                this.props.GetPluginApi().BindBorderToken(token.id);
            },
            onDelete : () => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.Borders, token.id)) {
                    AppLayout.Refs.DesignSystem.Borders.Reload();        
                }     
                this.props.GetPluginApi().DeleteStyleOfToken(token.id);           
            },
        }}) 
    }
    ApplyImageToken(tokenId, e) {
        if (this.props.tokenApplyMode)
            this.props.GetPluginApi().BindImageToken(tokenId);
        else
            this.SelectImageToken(tokenId, e);
    }
    SelectImageToken(tokenId, e) {
        e && e.preventDefault();
        e && this.setState({showTokenActions : {
            type : TokenTypes.TextPatterns,
            id : tokenId,
            y : e.clientY,
            onEdit : () => {
                AppLayout.Refs.DesignSystem.Images.EditImage(tokenId);
            },
            onApply : () => {
                this.props.GetPluginApi().BindImageToken(tokenId);
            },
            onDelete : (deleteStyle) => {
                if (Globals.ProjectManager.Tokens.Delete(TokenTypes.Images, tokenId)) {
                    AppLayout.Refs.DesignSystem.Images && AppLayout.Refs.DesignSystem.Images.Reload();    

                    this.props.GetPluginApi().DeleteStyleOfToken(tokenId, deleteStyle);
                }                
            },
        }})
    }
    EditMockups(id) {
        window.open( 'https://app.toolabs.com/#/board/' + this.props.projectId + '/realcontent/' + id, "_blank"); 
    }
    SelectMokcupField(fieldId) {
        if (fieldId) {
            this.props.GetPluginApi().GetSelection(() => {
                const nodes = this.props.GetPluginApi().SelectedNodes;
                const field = Globals.ProjectManager.Mockups.Field(fieldId);
                let isSuccessful = false;
                if (nodes && nodes.length > 0) {
                    const nodeMockupValues = [];
                    nodes.map((nodeData) => {
                        if (field.dataType === 'string' || field.dataType === 'number') {
                            if (nodeData.type === 'TEXT') {
                                nodeMockupValues.push({
                                    id : nodeData.id,
                                    value : Utils.UseNullOrEmpty(Globals.ProjectManager.Mockups.GetRandomValue(fieldId), '').toString()
                                })
                            }
                        }
                        if (field.dataType === 'boolean') {
                            nodeMockupValues.push({
                                id : nodeData.id,
                                value : Utils.IsTrue(Globals.ProjectManager.Mockups.GetRandomValue(fieldId)),
                                type : field.dataType
                            })
                        }
                        else if (field.dataType === 'image') {
                            const value = Globals.ProjectManager.Mockups.GetRandomValue(fieldId);
                            if (value) {
                                const url = Globals.ProjectManager.Mockups.GetRandomValue(fieldId).url;
                                isSuccessful = true;
                                this.props.GetPluginApi().ConvertImageToArray(url).then((imagedata) => {                            
                                    this.props.GetPluginApi().PostMessage({
                                        type : 'SetMockupValues',
                                        data : {
                                            projectId : Globals.ProjectManager.Id,
                                            nodes : [{
                                                id : nodeData.id,
                                                value : imagedata
                                            }]
                                        }
                                    });
                                })
                            }                                                    
                        }
                    })

                    if (nodeMockupValues.length > 0) {
                        isSuccessful = true;
                        this.props.GetPluginApi().PostMessage({
                            type : 'SetMockupValues',
                            data : {
                                projectId : Globals.ProjectManager.Id,
                                nodes : nodeMockupValues
                            }
                        });
                    }
                }
                if (!isSuccessful) {
                    let type = field.dataType === 'image' ? 'an image' : 'a text'
                    this.props.GetPluginApi().SendNotification('Please select ' + type + ' layer!')
                }
            })                            
        }        
    }
    ImportSvg() {
        this.props.GetPluginApi().GetSelection(() => {
            const nodes = this.props.GetPluginApi().SelectedNodes;
            if (nodes && nodes.length > 0) {
                this.props.GetPluginApi().Callbac_ImportSvg = ({items}) => {
                    items && Utils.ForEach(items, ({id, name, svg}, ) => {
                        try {
                            const svgstring = String.fromCharCode.apply(null, svg);
                            const tokenvalue = {
                                svg : svgstring,
                                provider : Strings.FIGMA
                            };
                            Globals.ProjectManager.Tokens.Add({
                                type : Globals.ProjectManager.Tokens.Types.Icons,
                                id : Utils.Id(),
                                name : name,
                                value : tokenvalue                
                            });      
                        } catch (error) {
                            
                        }                    
                    });
                    
                    this.setState({GlobalStateId : Utils.Id()})
                }
                this.props.GetPluginApi().PostMessage({
                    type : 'ImportSvg'
                })
            }  
        })        
    }
    OnEvent(Event, Params) {
        
        if (Event === Events.GLOBAL.TOKEN_VALUE_CHANGING) {
            const [Tokens] = Params;

            Globals.ProjectManager.CurrentTheme.ChangingTokens = Tokens;

            const data = {};
            let willUpdateThemeId = false;

            Utils.ForEach(Tokens, ({value, Id, Type, DoNotUpdateFigma}, i) => {
                if (Type === Globals.ProjectManager.Tokens.Types.COLOR)
                    Utils.Set(Globals.ProjectManager.CurrentTheme.Theme, value, Globals.ProjectManager.CurrentState, Id);
                else if (Type === Globals.ProjectManager.Tokens.Types.Spacings) {
                    willUpdateThemeId = true;
                }
                if (!DoNotUpdateFigma) {
                    const tokenStyleBinding = Utils.JustGet(this.props.GetPluginApi().FigmaTokenMap, null, Id);
                    if (tokenStyleBinding) {
                        if (Type === Globals.ProjectManager.Tokens.Types.ContentTexts) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.Borders) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.Spacings) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.Booleans) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (Type === Globals.ProjectManager.Tokens.Types.Icons) {
                            if (tokenStyleBinding.nodes && tokenStyleBinding.nodes.length > 0) {
                                Utils.Set(data, tokenStyleBinding.nodes, Id, 'nodes');
                            }
                        }
                        else if (tokenStyleBinding.styles && tokenStyleBinding.styles.length > 0) {                        
                            Utils.Set(data, tokenStyleBinding.styles, Id, 'styles');
                        }                    
                    }
                }                                
            });
            let ThemeStateId = this.state.ThemeStateId;
            if (Utils.Keys(data).length > 0) {
                this.props.GetPluginApi().UpdateFigmaStyles(data);
            }
            else if (willUpdateThemeId) {
                ThemeStateId = Utils.Id();
            }

            if (Globals.ProjectManager && Globals.ProjectManager.CurrentTheme) {
                Globals.ProjectManager.CurrentTheme.ThemeId = this.GlobalThemeId;
            }
            this.setState({
                GlobalStateId : Utils.Id(),
                GlobalThemeId : Utils.Id(),
                ThemeStateId : ThemeStateId
            });

            this.props.onUpdateTheme && this.props.onUpdateTheme();
        }
        else if (Event === Events.GLOBAL.TOKEN_NAME_CHANGED) {
            const [Tokens] = Params;
            if (Tokens && Array.isArray(Tokens) && Tokens.length > 0) {
                this.props.GetPluginApi().UpdateTokenNames(Tokens);
            }
        }
        else if (Event === Events.GLOBAL.STATE_CHANGED) {
            const Id = Utils.Id();
            // console.log(`GlobalStateId : ` + Id);
            this.setState({
                GlobalStateId : Id,
                GlobalThemeId : Id,
                ThemeStateId : Id
            });          
            
            if (Globals.ProjectManager.SessionManager && !AppLayout.AppSource.Figma.SuspendStateChange) {
                console.log(`BroadCast States : ${JSON.stringify(Globals.ProjectManager.States.StateVariation)}`);
                Globals.ProjectManager.SessionManager.BroadcastMessage({
                    figma : true,
                    fileId : AppLayout.AppSource.Figma.FileId,
                    StateChanged : true,                    
                    GlobalState : Globals.ProjectManager.States.StateVariation
                })
            }

            this.props.onUpdateTheme && this.props.onUpdateTheme();
        }
        else if (Event === Events.GLOBAL.TOKENS_CHANGED || Event === Events.DESIGNER.COMPONENT.HISTORY.CHANGED) {
            Globals.ProjectManager.UpdateTokenValues();                
            this.setState({GlobalStateId : Utils.Id(), ThemeStateId : Utils.Id()});
            this.props.onUpdateTheme && this.props.onUpdateTheme();
            return;
        }
        else if (Event === Events.DESIGNER.COMPONENT.STATE.MANAGER) {
            [this.rightPanelProps.showComponentStateManager] = Params;
            this.rightPanelProps.showComponentStateManager = !this.rightPanelProps.showComponentStateManager;
        }
        else if (Event === Events.DESIGNER.HISTORY.LIST) {
            this.ShowHistoryPanel = Params[0] === Events.PARAMS.SHOW;
            this.RCUpdate();
        }       
        else if (Event === Events.GLOBAL.TOKENS_CHANGED_FROMWEB) {
            this.props.GetPluginApi().GetFigmaStyleTokenMap(({tokenMap}) => {
                // console.log(`CB - GET : StateTokenMap `);
                this.props.GetPluginApi().UpdateFigmaStyles(tokenMap, () => {
                    AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(false);
                });                
            });
        } 
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();

        AppLayout.Refs.Ref_FigmaDesignSystem = this;
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.Ref_FigmaDesignSystem = null;
    }
    UpdateChangeStyles() {
        return new Promise((resolve) => {
            let UseBindings = this.props.GetPluginApi().CurrentBindings;
            // if (this.props.GetOptions().autoUpdateTokens && UseBindings && UseBindings.total > 0 && this.state.changes && this.state.changes.loaded) {
            if (UseBindings && UseBindings.total > 0 && this.state.changes && this.state.changes.loaded) {
                // console.log(`Changes : UpdateTokenFromFigmaThrottled`);
                AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(true);
                this.props.GetPluginApi().UpdateTokenFromFigmaThrottled('all', 'all').then(() => {
                    AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(false);
                    if (this.currentViewMode === 'tokens') {
                        this.state.GlobalStateId = Utils.Id();
                        this.state.GlobalThemeId = this.state.GlobalStateId;
                        this.setState({ShouldUpdate : true}, () => {
                            resolve();
                        })
                    }
                })                
                return;
            }
            if (this.WillUpdateSelection && this.currentViewMode === 'selection') {
                this.WillUpdateSelection = false;
                this.setState({FigmaStateId : this.state.GlobalStateId}, () => {
                    resolve();
                });
                return;
            }            
    
            let loaded;
            if (this.state.changes && this.state.changes.loaded)
                loaded = true;
    
            this.setState({
                changes : {
                    loaded : UseBindings.total === 0,
                    new : UseBindings.total_new,
                    modified : UseBindings.total_modified,
                    total : UseBindings.total
                }
            }, () => {
                resolve();
            })
        });
        
    }
    ShowTokenSyncer() {
        this.syncingTokens = true;
        setTimeout(() => {
            if (!this.syncingTokens) {
                this.setState({tokenSyncerVisible : false});
            }
            else 
                this.syncingTokens = false;
        }, 1000);
        this.setState({
            tokenSyncerVisible : true
        }, () => {
            this.props.GetPluginApi().UpdateNodeTokenMaps(() => {
                if (!this.syncingTokens) {
                    this.setState({tokenSyncerVisible : false});
                }
                else
                    this.syncingTokens = false;
            });
        })
    }
    UpdateNodeTokenMaps() {
        
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.ThemeStateId !== this.state.ThemeStateId) {
            this.WillUpdateSelection = true;
            // console.log(`ThemeStateId Changed`);
            AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(true);
            this.props.GetPluginApi().GetFigmaStyleTokenMap(({tokenMap}) => {
                // console.log(`CB - GET : StateTokenMap `);
                this.props.GetPluginApi().UpdateFigmaStyles(tokenMap, () => {
                    AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(false);
                });                
            });
        }

        if (this.props.semanticTokenName !== prevProps.semanticTokenName) {
            if (this.props.GetPluginApi().FigmaTokenMap) {
                const names = [];
            
                Utils.ForEach(this.props.GetPluginApi().FigmaTokenMap, (tokenData, tokenId) => {
                    const token = Globals.ProjectManager.Tokens.Token(tokenId);
                    if (token) {
                        names.push({id : tokenId, name : token.name});
                    }                    
                });
                if (names.length > 0) {
                    this.props.GetPluginApi().UpdateTokenNames(names);
                } 
            }
            
        }
    }    
    onChangeTokenType(type) {
        this.TokenType = type;
        // this.Ref_Scroll.current && this.Ref_Scroll.current.scrollTop(0);
        Globals.ProjectManager.Options.Set(type, 'LeftPanel', 'DesignSystem', 'TokenType');
        this.RCUpdate();
    }
    renderTokenHeader(label) {
        return (
            <GroupTitle title={label} style={{textTransform : 'uppercase', padding : 0}} />
        )
    }
    renderDropdownItem(item) {        
        return (
            <SC.GroupLabel style={{textTransform : 'uppercase', padding : 0, fontSize : '11px'}}>{item.label}</SC.GroupLabel>
        )
    }
    ToggleSerach() {
        this.setState({showSearchBar : !this.state.showSearchBar});
    }
    onPanelOverlay(options) {
        if (options.close) {
            delete this.HasPanelOverlay;
            this.RCUpdate();
            return;
        }
        this.HasPanelOverlay = options;
        this.RCUpdate();
    }
    renderCustom() {         
        const props = {
            GlobalState : Globals.ProjectManager.States.CurrentStateVariation,
            GlobalStateId : this.state.GlobalStateId,
            GlobalThemeId : this.state.GlobalThemeId,
            themeId : this.props.themeId,
            onPanelOverlay : this.onPanelOverlay,
            onShowConsumers : this.onShowConsumers,
            onUpdate : this.RCUpdate,
            RefToolbar : this.RefToolbar,
            showSearchBar : this.state.showSearchBar,
            filterText : this.state.filterText,
        }

        const header = this.state.showSearchBar ? 
            (
                <div style={{padding : '10px'}}>
                    <SearchInput 
                        small full style={{minHeight : '32px'}}
                        hasFullClose
                        autoFocus
                        SearchHint='Search tokens...'
                        FilterText={this.state.filterText}
                        CancelSearch={() => {
                            this.setState({showSearchBar : false, filterText : ''})
                        }}
                        Search={(value) => {
                            this.setState({filterText : value})
                        }}
                    />
                </div>
            ) : (
            <div style={{display : 'grid', gridTemplateColumns : '1fr 32px 32px', padding : '10px', alignItems : 'stretch'}}>
                <DropDownSelect 
                    items={[
                        {id : 'all', label : 'All Tokens'},
                        {id : 'aliases', label : 'Semantic Token Aliases'},                        
                        {id : Globals.ProjectManager.Tokens.Types.COLOR, label : 'Colors'},
                        {id : Globals.ProjectManager.Tokens.Types.Shadows, label : 'Shadows'},
                        {id : Globals.ProjectManager.Tokens.Types.Fonts, label : 'Typography'},                            
                        {id : Globals.ProjectManager.Tokens.Types.Spacings, label : 'Spacing'},
                        {id : Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts, label : 'Auto Layouts'},
                        {id : Globals.ProjectManager.Tokens.Types.Borders, label : 'Borders & Radii'},                                
                        {id : Globals.ProjectManager.Tokens.Types.Icons, label : 'Iconography'},
                        {id : Globals.ProjectManager.Tokens.Types.Images, label : 'Images'},
                        {id : Globals.ProjectManager.Tokens.Types.Transitions, label : 'Motion'},
                        {id : Globals.ProjectManager.Tokens.Types.Transforms, label : 'Transforms'},
                        {id : Globals.ProjectManager.Tokens.Types.Filters, label : 'Filters'},
                        {id : Globals.ProjectManager.Tokens.Types.Sounds, label : 'Sounds'},
                        {id : Globals.ProjectManager.Tokens.Types.Booleans, label : 'Visibility Booleans'},
                        {id : Globals.ProjectManager.Tokens.Types.ContentTexts, label : 'Text Contents'},
                    ]}
                    fullwidth
                    popupStyle={{minWidth : 'unset', left : '8px', right : '8px', marginTop : '-4px'}}
                    autoHeight
                    noUpwardPopup
                    largeText
                    popupItemStyle={{fontWeight : 500, padding : '8px', paddingLeft : '8px'}}
                    value={this.TokenType}
                    onChange={this.onChangeTokenType}
                    boxStyle={{paddingLeft : '8px', paddingRight : '4px', borderRadius : '4px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                    style={{minHeight : '32px', width : 'unset'}}
                    renderLabelItem={this.renderTokenHeader}
                    renderItem={this.renderDropdownItem}                    
                />
                <SC.Icons.Icon_ButtonBox hasFill onClick={this.ToggleSerach} style={{cursor : 'pointer', marginLeft : '4px'}} title={'Toggle Search Box'} >
                    <SC.Icons.Icon_Search size={14} />
                </SC.Icons.Icon_ButtonBox>
                <SC.Icons.Icon_ButtonBox hasCursor hasFill  title='Sync Token & Figma bindings' onClick={this.ShowTokenSyncer}>
                    <SC.Icons.Icon_Refresh size={20} />
                </SC.Icons.Icon_ButtonBox>
            </div>
        );

        let panelOverlay;

        if (this.HasPanelOverlay) {
            if (this.HasPanelOverlay.render)
                panelOverlay = this.HasPanelOverlay.render({...this.props, ...props});
            else {        
                if (this.HasPanelOverlay.type === 'EditFont') {
                    panelOverlay = (
                        <SC.FCol fw fh>
                            <TokenPanelHeader title='EDIT TYPEFACE' hasAddCancel onClose={this.HasPanelOverlay.onClose} onCancel={this.HasPanelOverlay.onCancel} onAdd={this.HasPanelOverlay.onAdd} onDelete={this.HasPanelOverlay.onDelete} />
                            <LeftScrollPanel>
                                <FontStyleEditor 
                                    id={this.HasPanelOverlay.id}
                                    onSelectFont={this.HasPanelOverlay.onSelectFont}
                                    isDefaultFont={this.HasPanelOverlay.isDefaultFont}
                                    GlobalStateId={this.state.GlobalStateId}
                                    onClose={this.HasPanelOverlay.onClose}
                                />
                            </LeftScrollPanel>                        
                        </SC.FCol>
                    )
                }
            }
        }
        props.singleView = this.TokenType !== 'all' || this.TokenType === 'DefaultStyles';
        props.noScrolls = true;

        let commonHeader;
        if (props.singleView) {
            // props.children = header;
            commonHeader = header;
        }
        else {
            commonHeader = header;
        }

        let changedInfo, selectionInfo;
        const changes = this.state.changes || {};
        
        let contentDirection = 0;
        if (this.currentViewMode !== this.props.viewMode) {
            if (this.props.viewMode === 'tokens')
                contentDirection = -1;
            else if (this.props.viewMode === 'mockup') {
                if (this.currentViewMode === 'tokens')
                    contentDirection = 1;
                else
                    contentDirection = -1;
            }
            else if (this.props.viewMode === 'components') {
                if (this.currentViewMode === 'selection')
                    contentDirection = -1;
                else
                    contentDirection = 1;
            }       
            else 
                contentDirection = 1;     
        }
        this.currentViewMode = this.props.viewMode;

        if (changes && changes.total > 0) {
            changedInfo = (
                <SC.Div_Flex_Cell style={{
                    borderRadius : '2px',
                    backgroundColor : '#ec407a',
                    color : '#fff',
                    width : 'auto',
                    paddingLeft : '4px',
                    paddingRight : '4px',
                    minWidth : '24px',
                    boxSizing : 'border-box',
                    height : '18px',
                    marginLeft : '4px',
                    marginRight : '-7px'

                }}>{changes.total}</SC.Div_Flex_Cell>
            )
        }           

        if (this.props.GetPluginApi().SelectedNodeCount > 0) {
            selectionInfo = (
                <SC.Div_Flex_Cell style={{
                    borderRadius : '2px',
                    backgroundColor : '#0288d1',
                    color : '#fff',
                    width : 'auto',
                    paddingLeft : '4px',
                    paddingRight : '4px',
                    minWidth : '24px',
                    boxSizing : 'border-box',
                    height : '18px',
                    marginLeft : '4px',
                    marginRight : '-7px'

                }}>{this.props.GetPluginApi().SelectedNodeCount}</SC.Div_Flex_Cell>
            )
        }

        let content;
        
        if (this.currentViewMode === 'tokens') {
            content = (
                <SC.FCol f1         
                    style={{
                        backgroundColor : SC.CurrentTheme.theme.back,
                        paddingTop : props.singleView ? 0 : '4px',
                        // height : '100%',
                        boxSizing : 'border-box',
                        position : 'relative'
                    }}       
                >      
                    {
                        this.TokenType === 'aliases' &&
                        <TokenAliases 
                            {...props} 
                            width={this.props.width} 
                            onSelectColor={this.ApplyColorTokenAlias}
                            onShowMenuColor={this.SelectColorTokenAlias}
                            onSelectShadow={this.ApplyShadowTokenAlias}        
                            onShowMenuShadow={this.SelectShadowTokenAlias}
                            onSelectRadius={this.ApplyRadiusTokenAlias}        
                            onShowMenuRadius={this.SelectRadiusTokenAlias}
                            onSelectBorder={this.ApplyBorderTokenAlias}        
                            onShowMenuBorder={this.SelectBorderTokenAlias}
                            onSelectSpace={this.SelectSpaceTokenAlias}        
                            onShowMenuSpace={this.SelectSpaceTokenAlias}
                            noScroll
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.COLOR) && 
                        <Colors 
                            {...props} 
                            onSelect={this.ApplyColorToken} 
                            onShowMenu={this.SelectColorToken} 
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Shadows) && 
                        <Shadows 
                            {...props} 
                            onSelect={this.ApplyShadowToken}
                            onShowMenu={this.SelectShadowToken} 
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Fonts) && 
                        <Typography 
                            {...props} 
                            selectFontsInPanel 
                            onSelectPattern={this.ApplyTextPattern}
                            onShowMenu={this.SelectTextPattern} 
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Spacings) && 
                        <Spacings  
                            {...props} 
                            onSelectPattern={this.SelectSpacePattern}
                            onShowMenu={this.SelectSpacePattern} 
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Borders) && 
                        <Borders 
                            {...props} 
                            onSelectRadius={this.ApplyCornerRadius}
                            onShowMenuRadius={this.SelectCornerRadius}
                            onSelectBorder={this.ApplyBorder}
                            onShowMenuBorder={this.SelectBorder}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Icons) && 
                        <Iconography 
                            {...props} 
                            hasInfoMessage={selectionInfo ? true : false}
                            infoMessage={(
                                <React.Fragment>
                                    {
                                        selectionInfo && 
                                        <SC.Div_Flex_Cell>
                                            <SC.Buttons.RoundButton xsmall onClick={this.ImportSvg} style={{
                                                margin : '8px'
                                            }}>
                                                Import Selected Items as Svg
                                            </SC.Buttons.RoundButton>
                                        </SC.Div_Flex_Cell>
                                    }
                                </React.Fragment>
                            )}
                            onSelect={this.ApplyIcon}
                            onShowMenu={this.SelectIcon}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Images) && 
                        <Images 
                            {...props} 
                            onSelect={this.ApplyImageToken}
                            onShowMenu={this.SelectImageToken}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transitions) && 
                        <MotionSystem {...props} />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transforms) && 
                        <Transforms 
                            {...props} 
                            infoMessage={(
                                <TokenCannotApplytoFigma>
                                    Transforms can't be applied to Figma items!
                                </TokenCannotApplytoFigma>
                            )}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Filters) && 
                        <Filters 
                            {...props} 
                            infoMessage={(
                                <TokenCannotApplytoFigma>
                                    Filters can't be applied to Figma items!
                                </TokenCannotApplytoFigma>
                            )}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Sounds) && 
                        <Sounds 
                            {...props} 
                            infoMessage={(
                                <TokenCannotApplytoFigma>
                                    Sounds can't be applied to Figma items!
                                </TokenCannotApplytoFigma>
                            )}
                        />
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Booleans) && 
                        <Booleans {...props} 
                            onSelect={this.ApplyBoolean} 
                            onShowMenu={this.SelectBoolean} 
                            title='VISIBILITY BOOLEANS'
                        />
                    } 
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts) && 
                        <FigmaAutoLayouts {...props} 
                            onSelect={this.ApplyLayout} 
                            onShowMenu={this.SelectLayout} 
                        />                        
                    }
                    {
                        (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.ContentTexts) && 
                        <TextContents last {...props} 
                            onSelect={this.ApplyTextContent} 
                            onShowMenu={this.SelectTextContent} 
                        />
                    }                                        
                </SC.FCol>
            )
            if (!props.singleView) {
                // content = (
                //     <LeftScrollPanel ScrollRef={this.Ref_Scroll}>
                //         {content}       
                //     </LeftScrollPanel>   
                // )
            } 
        } 
        else if (this.currentViewMode === 'components') {
            content = (
                <FigmaComponents 
                    {...this.props}
                    RefToolbar={this.RefToolbar}
                    GetOptions={this.props.GetOptions}
                    GlobalStateId={this.state.GlobalStateId}
                />
            )
        }
        else if (this.currentViewMode === 'mockup') {
            if (this.props.renderMockup) {
                content = this.props.renderMockup();
            }
            else {
                content = (
                    <SC.FCol f1>
                        <MockupDataPanel 
                            GlobalState={props.GlobalState}
                            GlobalStateId={props.GlobalStateId}
                            themeId={this.props.themeId}
                            justContent
                            forSelect
                            onSelect={this.SelectMokcupField}
                            onEdit={this.EditMockups}                            
                        />                        
                    </SC.FCol>
                )
            }
        }
        else if (this.currentViewMode === 'selection') {
            content = (
                <FigmaSelection 
                    {...this.props}
                    RefToolbar={this.RefToolbar}
                    GetOptions={this.props.GetOptions}
                    GlobalStateId={this.state.GlobalStateId}
                    onPanelOverlay={this.onPanelOverlay}
                    hasEditGrant={this.HasEditGrant}
                />
            )
        }
        
        if (this.props.tokenMode === 'usage' && this.state.usageOfTokenId) {        
            panelOverlay = (
                panelOverlay = (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title='TOKEN USAGE' hasAddCancel onClose={() => this.setState({usageOfTokenId : null})} onCancel={() => this.setState({usageOfTokenId : null})} />
                        <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                            <LeftScrollPanel>
                                <FigmaTokenUsage 
                                    id={this.state.usageOfTokenId}
                                    onClose={() => this.setState({usageOfTokenId : null})}
                                />
                            </LeftScrollPanel>
                        </SC.FCol>                                                
                    </SC.FCol>
                )
            )
        }

        let tokenMenu;

        if (this.state.showTokenActions) {
            const hasSelectedLayers = this.props.GetPluginApi().SelectedNodeCount > 0;
            let applyMenuItem;
            if (hasSelectedLayers) {
                if (this.state.showTokenActions.type === TokenTypes.COLOR) {
                    applyMenuItem = (
                        <React.Fragment>
                            <Figma_PopupItem onClick={(e) => {
                                this.state.showTokenActions.onApply({stroke : false});
                                this.setState({showTokenActions  : false});
                            }}>
                                <FigmaIcon_Attach style={{marginLeft : '5px', paddingTop : '4px', paddingBottom : '4px'}} />
                                <div style={{marginLeft : '13px'}}>Apply to Selected Fills</div>
                            </Figma_PopupItem>
                            <Figma_PopupItem onClick={(e) => {
                                this.state.showTokenActions.onApply({stroke : true});
                                this.setState({showTokenActions  : false});
                            }}>
                                <FigmaIcon_Attach style={{marginLeft : '5px', paddingTop : '4px', paddingBottom : '4px'}} />
                                <div style={{marginLeft : '13px'}}>Apply to Selected Strokes</div>
                            </Figma_PopupItem>
                        </React.Fragment>
                    )
                }
                else if (this.state.showTokenActions.type === TokenTypes.SpacePatterns) {
                    applyMenuItem = (
                        <React.Fragment>
                            <SC.FRow alc style={{paddingRight : '4px'}}>
                                <FigmaIcon_Attach style={{marginLeft : '14px', paddingTop : '4px', paddingBottom : '4px',}} />
                                <SC.TextDivAbbr style={{flex : 1, whiteSpace : 'nowrap', marginLeft : '12px', fontSize : '11px'}}>Apply Size To</SC.TextDivAbbr>
                                <Figma_PopupItem onClick={(e) => {
                                    this.state.showTokenActions.onApply({width : true});
                                    this.setState({showTokenActions  : false});
                                }}>
                                    Width                          
                                </Figma_PopupItem>
                                <Figma_PopupItem onClick={(e) => {
                                    this.state.showTokenActions.onApply({height : true});
                                    this.setState({showTokenActions  : false});
                                }} style={{borderLeft : SC.CurrentTheme.theme.border_seperator, borderRight : SC.CurrentTheme.theme.border_seperator}}>
                                    Height                  
                                </Figma_PopupItem>
                                <Figma_PopupItem onClick={(e) => {
                                    this.state.showTokenActions.onApply({width : true, height : true});
                                    this.setState({showTokenActions  : false});
                                }}>
                                    Both          
                                </Figma_PopupItem>
                            </SC.FRow>                                                        
                        </React.Fragment>
                    )
                }
                else {
                    applyMenuItem = (
                        <Figma_PopupItem onClick={(e) => {
                            this.state.showTokenActions.onApply();
                            this.setState({showTokenActions  : false});
                        }}>
                            <FigmaIcon_Attach style={{marginLeft : '5px', paddingTop : '4px', paddingBottom : '4px'}} />
                            <div style={{marginLeft : '13px'}}>Apply to Selected Layers</div>
                        </Figma_PopupItem>
                    );
                }
            }
            

            tokenMenu = (
                <SC.AbsoluteOverlay style={{position : 'fixed', zIndex : 99999, cursor : 'default', ...SC.Styles.Flex.Cell}}>
                    <FigmaStyleMenuPopup
                        top={this.state.showTokenActions.y || 100}
                        style={{
                            left : '50%',
                            transform : 'translateX(-50%)',
                            width : 'auto',
                            minWidth : '160px',
                            right : 'unset',
                            marginTop : '8px',
                            paddingTop : '4px',
                            paddingBottom : '4px',
                            maxWidth : 'calc(100% - 20px)'
                        }}
                        onClose={() => {
                            this.setState({showTokenActions : false})
                        }}
                    >
                        {applyMenuItem}
                        {applyMenuItem ? <div style={{borderTop : SC.CurrentTheme.theme.border_ondark, marginTop : '4px', marginBottom : '4px'}} /> : null}
                        <Figma_PopupItem 
                            onClick={(e) => {
                            this.state.showTokenActions.onEdit();
                            this.setState({showTokenActions  : false});
                        }}>
                            <FigmaIcon_Edit style={{marginLeft : '2px', paddingTop : '4px', paddingBottom : '4px'}} />
                            <div style={{marginLeft : '10px'}}>Edit Token</div>
                        </Figma_PopupItem>
                        <Figma_PopupItem onClick={(e) => {
                                this.state.showTokenActions.onDelete();
                                this.setState({showTokenActions  : false});
                        }}>
                            <SC.Icons.Icon_Delete style={{marginLeft : '2px', paddingTop : '4px', paddingBottom : '4px'}} />
                            <div style={{marginLeft : '10px'}}>Delete Token</div>
                        </Figma_PopupItem>
                        {
                            !this.state.showTokenActions.noStyle && 
                            <Figma_PopupItem onClick={(e) => {
                                this.state.showTokenActions.onDelete(true);
                                this.setState({showTokenActions  : false});
                            }}>
                                <SC.Icons.Icon_Delete style={{marginLeft : '2px', paddingTop : '4px', paddingBottom : '4px'}} />
                                <div style={{marginLeft : '10px'}}>Delete Token and Mark Figma Style with ✗</div>
                            </Figma_PopupItem>
                        }  
                    </FigmaStyleMenuPopup>
                </SC.AbsoluteOverlay>
            )

            // tokenMenu = (
            //     <SC.AbsoluteOverlay style={{position : 'fixed', zIndex : 99999, cursor : 'default', ...SC.Styles.Flex.Cell}}>
            //         <OutsideCloseDotMenuContainer 
            //             onClose={() => {
            //                 this.setState({showTokenActions : false})
            //             }}
            //             x='-50%'
            //             style={{
            //                 left : '50%',
            //                 transform : 'translateX(-50%)',
            //                 width : 'auto',
            //                 minWidth : '160px',
            //                 right : 'unset',
            //                 marginTop : '-8px',
            //                 top : Utils.px(this.state.showTokenActions.y || 100),
            //                 paddingTop : '4px',
            //                 paddingBottom : '4px',
            //                 maxWidth : 'calc(100% - 20px)'
            //             }}
            //         >
                                                                      
            //         </OutsideCloseDotMenuContainer>
            //     </SC.AbsoluteOverlay> 
            // )
        }

        return (  
            <React.Fragment>
                <SC.FCol f1 style={{
                    position : 'relative'
                }}>    
                    <FigmaSystemStates 
                        showThemeSelector={this.props.showThemeSelector}
                        Ref_SystesPanel={this.props.Ref_SystesPanel}
                        onToggleThemeSelector={this.props.onToggleThemeSelector}
                        themeId={this.props.themeId}
                        GlobalState={Globals.ProjectManager.States.CurrentStateVariation}
                        hasEditGrant={this.HasEditGrant}
                        inPanel={this.props.demoMode}
                    />        
                    <SC.FCol f1 style={{
                        position : 'relative'
                    }}>
                        <SC.FRow style={{paddingLeft : '10px', paddingRight : '4px', borderBottom : SC.CurrentTheme.theme.border_seperator}}>
                            <TabButton selected={this.currentViewMode === 'tokens'} onClick={() => {this.props.onChangeViewMode('tokens')}}>Tokens</TabButton>
                            <TabButton selected={this.currentViewMode === 'mockup'} onClick={() => {this.props.onChangeViewMode('mockup')}}>Content</TabButton>
                            {AppLayout.isDevelopment && <TabButton selected={this.currentViewMode === 'components'} onClick={() => {this.props.onChangeViewMode('components')}}>Components</TabButton>}
                            <TabButton selected={this.currentViewMode === 'selection'} onClick={() => {this.props.onChangeViewMode('selection')}}>
                                <SC.TextDivAbbr>Selection</SC.TextDivAbbr>
                                {selectionInfo}
                            </TabButton>                    
                        </SC.FRow> 
                        {
                            this.currentViewMode === 'tokens' &&  commonHeader
                        }      
                        <LeftScrollPanel ScrollRef={this.Ref_Scroll} big>                                                                                    
                            <motion.div
                                key={this.currentViewMode}
                                initial={{x : contentDirection * 100}}
                                animate={{x : 0}}
                                transition={{
                                    duration : 0.2
                                }}
                            >
                                {content}
                            </motion.div>                 
                        </LeftScrollPanel>
                        <AnimatePresence>
                            {
                                (this.HasPanelOverlay || this.state.usageOfTokenId) && 
                                <motion.div 
                                    style={{...SC.Styles.Absolute, zIndex : 99, top : '-4px', backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'grid', gridTemplateRows : '1fr'}}
                                    initial={{opacity : 0.7, x : -24}}
                                    animate={{opacity : 1, x : 0}}
                                    exit={{opacity : 0, x : -24}}
                                    transition={{duration : 0.2}}
                                >
                                    {/* <FigmaSystemStates
                                        GlobalState={Globals.ProjectManager.States.CurrentStateVariation} 
                                        showThemeSelector={this.props.showThemeSelector}
                                        Ref_SystesPanel={this.props.Ref_InnerSystesPanel}
                                        onToggleThemeSelector={this.props.onToggleThemeSelector}
                                        themeId={this.props.themeId}
                                        inPanel
                                    />   */}
                                    {panelOverlay}
                                </motion.div>
                            }
                        </AnimatePresence>                                        
                        <FigmaDSToolbar 
                            ref={this.RefToolbar} 
                            showThemeSelector={this.props.showThemeSelector}
                            onToggleThemeSelector={this.props.onToggleThemeSelector}
                            themeId={this.props.themeId}
                            GlobalState={Globals.ProjectManager.States.CurrentStateVariation}
                            GlobalStateId={this.state.GlobalStateId}
                            GlobalThemeId={this.state.GlobalThemeId}
                            themeId={this.props.themeId}
                        />
                    </SC.FCol>                       
                    {this.state.showTokenActions && tokenMenu} 
                    <AnimatePresence>
                        {
                            this.state.tokenSyncerVisible &&                             
                            <motion.div  
                                initial={{opacity : 0.4}}
                                animate={{opacity : 1}}
                                exit={{opacity : 0}}
                                style={{
                                    ...SC.Styles.Absolute,
                                    backgroundColor : 'rgba(0,0,0,0.2)',
                                    zIndex : 999999999,
                                    ...SC.Styles.Flex.Cell
                                }} 
                            >
                                <SC.Loading_Icon style={{color : 'rgb(77, 138, 181)'}} />
                            </motion.div>
                        }
                    </AnimatePresence>                                        
                </SC.FCol>                
            </React.Fragment>
        );
    }
}
 

const TabButton = styled.div`
    background-color : ${props => props.selected ? '#2c2c2c' : 'transparent'};
    color : ${props => props.selected ? '#fff' : props.theme.font};
    font-weight : 600;
    border-top-left-radius : 4px;
    border-top-right-radius : 4px;    
    padding : 2px;
    padding-left : 10px;
    padding-right : 10px;
    min-height : 28px;
    font-size : 11px;
    transition : all 0.2s ease;
    cursor : pointer;
    display : flex;
    align-items : center;
    justify-content : space-around;
    box-sizing : border-box;
    overflow : hidden;
    &:hover {
        
    }
`;

const TokenCannotApplytoFigma = (props) => (
    <div style={{marginLeft : '12px', marginRight : '12px', padding : '10px', color : '#000', backgroundColor : 'rgba(255, 235, 0, 0.54)', borderRadius : '2px'}}>
        {props.children}
    </div>
)

const CheckAndUpdate = ({title, onUpdate}) => {
    return (
        <FigmaInfoPanel style={{marginLeft : '12px', marginRight : '12px', lineHeight : '18px', marginBottom : 0}}>
            Some Figma objects cloned from an object which is attached to a {title} token might need to be manually updated.                    
            <SC.Buttons.RoundButton xsmall onClick={onUpdate} style={{marginTop : '10px', marginLeft : 'auto', marginRight : 'auto'}}>Click to Update</SC.Buttons.RoundButton>
        </FigmaInfoPanel>
    )
}
 
