import React from 'react';
import ReactDOM from 'react-dom';
import UIUtils from './Utils';
import Utils from '../toolabs-utils';

const DragHandle = (Wrapped) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.Dragging = {
                TranslateX : 0,
                TranslateY : 0
            };
            this.state = {
                Active : false
            };
            this.OnMouseMove = this.OnMouseMove.bind(this);
            this.onMouseDown = this.onMouseDown.bind(this);
            this.onMouseUp = this.onMouseUp.bind(this);
            this.OnKeyUp = this.OnKeyUp.bind(this);
        }
        GetDocument() {
            return this.props.document || document;
        }
        OnKeyUp(e) {
            if (this.Dragging && this.state.Active) {
                if (!this.Dragging.KeyboardOffset)
                    this.Dragging.KeyboardOffset = 0;
                const oldOffset = this.Dragging.KeyboardOffset;
                let change = 0;
                if (e.keyCode === Utils.KeyCodes.UP)
                    change = 1;
                else if (e.keyCode === Utils.KeyCodes.DOWN)
                    change = -1;                
                this.Dragging.KeyboardOffset += change;

                this.Dragging.difX += change;
                this.Dragging.difY += change;
                this.Dragging.x += change;
                this.Dragging.y += change;
                this.Dragging.TranslateX += change;
                this.Dragging.TranslateY += change;

                this.props.OnDrag && this.props.OnDrag(this.Dragging);
            }
        }
        OnMouseMove(e) {            
            if (this.Dragging && this.state.Active) {
                const Dragging = this.Dragging;
                const offset = Utils.Get(Dragging, 0, 'KeyboardOffset');
                Dragging.difX = e.clientX + offset - Dragging.x;
                Dragging.difY = e.clientY + offset - Dragging.y;
                Dragging.x = e.clientX + offset;
                Dragging.y = e.clientY + offset;
                Dragging.ShiftKey = e.shiftKey;
                Dragging.TranslateX = Utils.ToInteger(Dragging.TranslateX + Dragging.difX);
                Dragging.TranslateY = Utils.ToInteger(Dragging.TranslateY + Dragging.difY);
                
                if (!this.Dragging.Initiated) {
                    this.Dragging.Initiated = true;
                    this.props.OnResizeStarted && this.props.OnResizeStarted(this.props.id, e, this.Dragging);
                }                    
                if ((Dragging.difX !== 0 || Dragging.difY !== 0) && this.props.OnDrag)
                    this.props.OnDrag(this.Dragging);

            }
        }
        onMouseDown(e, options) {
            if (!this.Mounted)
                return;
            
            // if (UIUtils.IsOver({x : e.clientX, y : e.clientY }, this)) 
            {                
                this.GetDocument().addEventListener('mousemove', this.OnMouseMove);
                this.GetDocument().addEventListener('mouseup', this.onMouseUp);
                this.props.document && document.addEventListener('mouseup', this.onMouseUp);
                this.GetDocument().addEventListener('keyup', this.OnKeyUp);
                this.props.document && document.addEventListener('keyup', this.OnKeyUp);

                console.log('Down On Handle : preventDefault');
                e.preventDefault();
                e.stopPropagation();
                this.Dragging = Utils.Merge({
                    Initiated : false,
                    x : e.clientX,
                    y : e.clientY,
                    start_x : e.clientX,
                    start_y : e.clientY,
                    TranslateX : 0,
                    TranslateY : 0,
                    Value : Number(Utils.UseNull(this.props.value, 0)),
                    ShiftKey : e.shiftKey
                }, options);

                this.setState({
                    Active : true
                });
            }
        }
        onMouseUp(e) {            
            if (this.state.Active) {
                e.preventDefault();
                e.stopPropagation();
                this.props.OnDragEnd && this.props.OnDragEnd(this.Dragging);
                if (this.Mounted) {
                    this.setState(() => {
                        return {
                            Active : false
                        };
                    });
                    this.props.OnResizeEnded && this.props.OnResizeEnded(e, this.Dragging);
                }                                
                this.GetDocument().removeEventListener('mousemove', this.OnMouseMove);
                this.GetDocument().removeEventListener('mouseup', this.onMouseUp);
                this.props.document && document.removeEventListener('mouseup', this.onMouseUp);
                this.GetDocument().removeEventListener('keyup', this.OnKeyUp);
                this.props.document && document.removeEventListener('keyup', this.OnKeyUp);
            }
        }
        componentDidMount() {
            this.Mounted = true;
            // window.addEventListener('mousedown', this.onMouseDown);
        }
        componentWillUnmount() {
            this.Mounted = false;
            this.props.OnDragEnd && this.props.OnDragEnd(this.Dragging, true);
            this.GetDocument().removeEventListener('mousedown', this.onMouseDown);
            this.GetDocument().removeEventListener('mousemove', this.OnMouseMove);
            this.props.document && document.removeEventListener('mousemove', this.OnMouseMove);
            this.GetDocument().removeEventListener('mouseup', this.onMouseUp);
            this.GetDocument().removeEventListener('keyup', this.OnKeyUp);
            this.props.document && document.removeEventListener('keyup', this.OnKeyUp);
        }
        render() {            
            const {Dragging, OnDragEnd, OnDrag, ...props} = this.props;
            return (
                <Wrapped {...props} onMouseDown={this.onMouseDown} Dragging={this.state.Active} />
            );
        }
    }
};

export default DragHandle;
