import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    UIUtils,
    Utils,
    Globals
} from '../../../../../../../../importer';

import styled from 'styled-components';

const StyledControlPoint = styled.ellipse`    
    &:hover {
        stroke : #CECECE;
        rx : 5.5;
        ry : 5.5;
    }        
`;

const ControlPoint = (props) => {
    return (
        <g onMouseDown={props.onDragStart} cursor='pointer'>
        <StyledControlPoint fill={SC.CurrentTheme.theme.color_brand} stroke={props.dragging ? '#CECECE' : '#454545'} rx={props.dragging ? 5.5 : 3.9} ry={props.dragging ? 5.5 : 3.9} cx={props.x} cy={props.y}  />
        {
            (!props.active ||props.dragging) ? null : 
            <circle cx={props.x} cy={props.y} stroke='white' strokeWidth="1">
                <animate attributeName="r" begin="400ms" dur="2s" from={4} to={8} repeatCount="indefinite"/>  
                <animate attributeName="opacity" begin="400ms" dur="2s" from={0.8} to={0} repeatCount="indefinite"/>  
                <animate attributeName="stroke-width" begin="400ms" dur="2s" from={1} to={3} repeatCount="indefinite"/>  
            </circle>
        }            
        </g>
    )
}
const ControlLine = (props) => {
    return (
        <line {...props} strokeWidth={props.strokeWidth} stroke={props.handleline}  onMouseDown={props.onDragStart} style={{zIndex : 10000}} />
    )
}

const w = 156;
const s = 39;

class BezierCurve extends React.Component
{
    constructor(props) {
        super(props);

        this.DragPoint = this.DragPoint.bind(this);
        this.GetCurve = this.GetCurve.bind(this);
        this.AnimatePath = this.AnimatePath.bind(this);
        this.DragEnd = this.DragEnd.bind(this);
        this.state = {ShouldUpdate : false};

        this.Play = this.Play.bind(this);

        this.offset_y = 0;
        this.offset_x = 0;
        this.width = 156;
        this.width = 156;
        this.size = 39;
        

        this.Model = this.DefaultModel();   
        this.SetValue(this.props.value);     
        
    }
    Play() {
        
    }
    DefaultModel() {
        const s1x = 20.5536481;
        const s1y = 150.450431;
        const s2x = 165.446352;
        const s2y = 5.54956897;

        return {
            s1 : {
                x : 26,
                y : 280,
            },
            s2 : {
                x : 234,
                y : 72
            },
            c1 : {
                x : 40,
                y : 110
            },
            c2 : {
                x : 125,
                y : 45
            }
        };
    }
    DragStart(point) {        
        document.addEventListener('mouseup', this.DragEnd);
        document.addEventListener('mousemove', this.DragPoint);
        this.Dragging = this.Model['c'+point];
        this.RCUpdate();
    }
    DragEnd(e) {        
        document.removeEventListener('mouseup', this.DragEnd);
        document.removeEventListener('mousemove', this.DragPoint);
        this.Dragging = null;
        this.RCUpdate();
        if (this.props.onChange) {                        
            this.props.onChange(this.GetCurve());            
        }
    }
    RCUpdate() {
        this.setState({ShouldUpdate : true});
    }
    componentWillReceiveProps(nextProps) {
        if (!Utils.IsEqual(nextProps.value, this.value)) {
            this.SetValue(nextProps.value);
        }
    }
    SetValue(value) {
        const {c1, c2, s1, s2} = this.Model;        
        if (!value)
            value = {x1 : 0.3, y1 : 0.3, x2 : 0.7, y2 : 0.7};
        this.value = Utils.DeepClone(value);
        if (this.value) {
            const {x1,x2,y1,y2} = this.value;
            c1.x = x1 * (s2.x-s1.x) + s1.x;
            c1.y = y1 * (s2.y-s1.y) + s1.y;
            c2.x = x2 * (s2.x - s1.x) + s1.x;
            c2.y = -1 * (y2 * (s1.y - s2.y) - s1.y);
        }
    }
    DragPoint(e) {
        const {c1, c2, s1, s2} = this.Model;
        const refsvg = this.props.GetSvgRef();
        if (!this.pt) {            
            if (refsvg)
                this.pt = refsvg.createSVGPoint();
            else
                return;
        }
        this.pt.x = e.clientX; 
        this.pt.y = e.clientY;
        
        const loc = this.pt.matrixTransform(refsvg.getScreenCTM().inverse());
        
        const x = loc.x;
        const y = loc.y;
        if (this.Dragging === c1) {            
            c1.y = y;
            c1.x = x;
            if (c1.x < s1.x)
                c1.x = s1.x;
            if (c1.x < s1.x)
                c1.x = s1.x;
            // if (c1.y > 340)
            //     c1.y = 340;
            
            this.RCUpdate();
        }
        else if (this.Dragging === c2) {
            if (x <= (s2.x))
                c2.x = x;
            c2.y = y;
            // if (c2.y < 7)
            //     c2.y = 0;
            if (c2.x > s2.x)
                c2.x = s2.x;
            this.RCUpdate();
        }        
        this.value = this.GetCurve();
        if (this.props.onChanging) {                        
            this.props.onChanging(this.value);            
        }
    }
    GetCurve() {
        const {c1, c2, s1, s2} = this.Model;
        return {
            x1 : ((c1.x - s1.x) / (s2.x - s1.x)).toFixed(2),
            y1 : ((c1.y-s1.y) / (s2.y - s1.y)).toFixed(2),
            x2 : ((c2.x - s1.x) / (s2.x - s1.x)).toFixed(2),
            y2 : ((s1.y - c2.y) / (s1.y-s2.y)).toFixed(2)
        };
    }
    AnimatePath(time) {
        // this.Animate = time;
        // this.RCUpdate();
    }
    render() {
        this.state.ShouldUpdate = false;
        const {c1, c2, s1, s2} = this.Model;        
        const r = 6;
        let c1x = c1.x;
        let c1y = c1.y;
        let c2x = c2.x;
        let c2y = c2.y;
        if (c1y > (s1.y)) {
            c1y = s1.y + 2 * (c1y-s1.y);
        }
        if (c2y < (s2.y))
            c2y = s2.y + 2 * (c2y - s2.y);
        let cubicpath = `M${s1.x},${s1.y} C${c1x},${c1y} ${c2x},${c2y} ${s2.x},${s2.y}`;
        let vb_width = w;
        let vb_height = w;        

        const useColor = this.props.theme;
        
        return (
            <g>
                <line x1={s1.x} y1={s1.y} x2={s2.x} y2={s2.y} stroke={useColor.grid} strokeWidth="1" />
                <ellipse cx={s1.x} cy={s1.y} fill="#404449" stroke={SC.CurrentTheme.theme.color_brand} strokeWidth="2" rx="3.5" ry="3.5"/>
                <ellipse cx={s2.x} cy={s2.y} fill="#404449" stroke={SC.CurrentTheme.theme.color_brand} strokeWidth="2" rx="3.5" ry="3.5"/>                                                                                
                <path d={cubicpath} stroke={useColor.curve} strokeWidth="2" style={{transition : 'd 0.1s ease'}}/>
                <ControlLine handleline={useColor.handleline} x1={s1.x} y1={s1.y} x2={c1.x} y2={c1.y} onDragStart={this.props.readonly ? null :  () => this.DragStart(1)} />
                <ControlLine handleline={useColor.handleline} x1={s2.x} y1={s2.y} x2={c2.x} y2={c2.y}  onDragStart={this.props.readonly ? null : () => this.DragStart(2)} />
                <ControlPoint active={this.props.active} x={c1.x} y={c1.y}  onDragStart={this.props.readonly ? null : () => this.DragStart(1)} dragging={this.Dragging === c1} />
                <ControlPoint active={this.props.active} x={c2.x} y={c2.y}  onDragStart={this.props.readonly ? null : () => this.DragStart(2)} dragging={this.Dragging === c2} />                            
            </g>
        )
    }
}

class BezierCurveMain extends React.Component
{
    render() {
        const useColor = this.props.theme;
        
        return (
            <svg ref={(r) => this.RefSvg = r}  xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 260 352" style={{overflow : 'visible'}}>
                <defs>
                    <path id="b" d="M0 0h220v220H0z"/>
                    <circle id="c" cx="26" cy="322" r="7"/>
                </defs>
                <g fill="none" fillRule="evenodd">
                    <path stroke={useColor.grid_stroke} fill={useColor.back} d="M.5.5h259v351H.5z"/>
                    <g transform="translate(20 66)">
                        <path fill={useColor.back} stroke={useColor.grid_stroke} d="M.5.5h219v219H.5z"/>
                    </g>
                    <path stroke={useColor.grid_stroke} d="M75.7 68v216.4m55-216.4v216.4m55-216.4v216.4m-164.3-162h218m-218 55h218m-218 55h218" strokeLinecap="square"/>                       
                    <text fill="#777"  fontSize="10" fontWeight="500" letterSpacing="4">
                        <tspan x="112" y="299">TIME</tspan>
                    </text>
                    <text fill="#777" transform="rotate(-90 12 176.5)"  fontSize="10" fontWeight="500" letterSpacing="4">
                        <tspan x="-28" y="180">PROGRESS</tspan>
                    </text>  
                    {
                        !this.props.empty ? 
                        <BezierCurve                                 
                            GetSvgRef={() => {return this.RefSvg}} 
                            {...this.props}
                            value={Utils.DeepClone(this.props.value)}
                        /> : null
                    }                         
                </g>
            </svg>
        )
    }
}


export default BezierCurveMain;