import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    MetaData,
    Globals
} from '../../../../../../importer';
import styled from 'styled-components';
import { ItemNameEditor, TokenItemBox } from '../common';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import SizeEditor from '../../../right/iteminspector/styleitems/size';

export default class BorderTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
            this.EditingModel.name = 'Solid 1';
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.EditingModel = {
                name : this.token.name,
                value : Utils.DeepClone(Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), {}))
            }
        }
        const valueName = Utils.Capitalize(Utils.Get(this.EditingModel, 'solid', 'value', 'style')) + ' ' + Utils.Get(this.EditingModel, 1, 'value', 'value');
        this.isValueName = this.EditingModel.name === valueName;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!nextProps.newModel) {
                this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
                if (!this.token) {
                    this.props.onClose();
                    return false;
                }
                this.EditingModel = {
                    name : this.token.name,
                    value : Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), {})
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Borders);
    }        
    
    SaveName(name) {
        this.isValueName = false; 
        this.EditingModel.name = name;               
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    onChangeSize(prop, size) {
        if (!this.props.newModel && !this.ChangeLogged) {
            this.ChangeLogged = true;
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Border Width'});
        }
        Utils.Set(this.EditingModel, size.value, 'value', 'value');
        Utils.Set(this.EditingModel, size.unit, 'value', 'Unit');
        if (!this.props.newModel) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : this.EditingModel.value, type : Globals.ProjectManager.Tokens.Types.Borders});
            
            const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
            const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');
            const borderStyle = Utils.Get(this.EditingModel, 'solid', 'value', 'style');

            const changingTokens =  [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Borders,
                style : borderStyle,
                size : Utils.px(width, unit)

            }];

            if (this.token.aliases) {
                this.token.aliases.map((aliaseId) => {
                    changingTokens.push({
                        Id : aliaseId,
                        Type : Globals.ProjectManager.Tokens.Types.Borders,
                        style : borderStyle,
                        size : Utils.px(width, unit)
                    })
                })
            }

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
        }
        else {
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel.value);            
        }
        if (this.isValueName) {
            this.EditingModel.name = Utils.Capitalize(Utils.Get(this.EditingModel, 'solid', 'value', 'style')) + ' ' + Utils.Get(this.EditingModel, 1, 'value', 'value');
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : this.EditingModel.name} );
        }
        this.RCUpdate();
    }    
    SelectStyle(id) {
        if (!this.props.newModel && !this.ChangeLogged) {
            this.ChangeLogged = true;
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Border Style'});
        }

        Utils.Set(this.EditingModel, id, 'value', 'style');
        if (!this.props.newModel) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : this.EditingModel.value, type : Globals.ProjectManager.Tokens.Types.Borders});

            const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
            const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');
            const borderStyle = Utils.Get(this.EditingModel, 'solid', 'value', 'style');
            
            const changingTokens =  [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Borders,
                style : borderStyle,
                size : Utils.px(width, unit)

            }];

            if (this.token.aliases) {
                this.token.aliases.map((aliaseId) => {
                    changingTokens.push({
                        Id : aliaseId,
                        Type : Globals.ProjectManager.Tokens.Types.Borders,
                        style : borderStyle,
                        size : Utils.px(width, unit)
                    })
                })
            }

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
        }
        else {
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel.value);
        }
        if (this.isValueName) {
            this.EditingModel.name = Utils.Capitalize(Utils.Get(this.EditingModel, 'solid', 'value', 'style')) + ' ' + Utils.Get(this.EditingModel, 1, 'value', 'value');
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : this.EditingModel.name} );
        }
        this.RCUpdate();
    }
    renderCustom() {
        
        const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
        const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');
        const borderStyle = Utils.Get(this.EditingModel, 'solid', 'value', 'style');
        
        const borderStyles = [];
        Utils.ForEach(MetaData.Styles.borderStyle.values,(border, name) => {
            const selected = border.Id === (borderStyle || 'solid');
            borderStyles.push(
                <TokenItemBox
                    key={border.Id}
                    style={{borderLeft : selected ? SC.CurrentTheme.theme.border_brand :  SC.CurrentTheme.theme.border_seperator, ...SC.Styles.Flex.RowAlcJsb, paddingRight : '8px'}}
                    onClick={this.SelectStyle.bind(this, border.Id)}
                >
                    {border.Label}
                    <BorderStylePreview border={border.Id} />
                </TokenItemBox>
            );
        });

        return (
            <SC.FCol fw fh style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.EditingModel.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.EditingModel}
                />
                <SC.FRow alc jsb style={{marginTop : '8px', marginBottom : '8px'}}>
                    <div>Width</div>
                    <SizeEditor 
                        autoFocus
                        autoSelect
                        value={width}
                        unit={unit}
                        onChange={this.onChangeSize.bind(this, 'customSize')}
                        onChanging={this.onChangeSize.bind(this, 'customSize')}
                    />
                </SC.FRow>   
                <SC.FCol>
                    <div style={{
                        borderTopWidth : Utils.px(width, unit),
                        borderTopStyle : borderStyle || 'solid',
                        borderTopColor : SC.CurrentTheme.theme.color_brand,
                        height : '5px',
                        width : '100%',
                        marginBottom : '8px',
                        boxSizing : 'border-box'
                    }} />                        
                </SC.FCol>   
                {borderStyles} 
            </SC.FCol>
        )
    }
}

let borderStyles;

const GetBorderStyles = () => {
    if (!borderStyles) {
        borderStyles = [];
        Utils.ForEach(MetaData.Styles.borderStyle.values,(border, name) => {
            borderStyles.push({
                id : border.Id,
                label : border.Label,
                renderItem : () => {
                    return (
                        <SC.FRow key={border.Id} alc>
                            <SC.FCol f1>
                                {border.Label}
                            </SC.FCol>
                            <SC.Div_Flex_Cell f1>
                                <BorderStylePreview border={border.Id} />
                            </SC.Div_Flex_Cell>
                        </SC.FRow>
                    )
                }
            });
        });
    }
    return borderStyles;
}

export const BorderStyleSelector = (props) => {
    return (
        <DropDownSelect 
            autoHeight
            items={GetBorderStyles()} 
            xsmall={props.xsmall}
            left={-80}
            hasBorder
            value={props.value} 
            onChange={props.onChange}
            onClosed={props.onClosed}
            onHoverItem={props.onHoverItem}
            style={{
                // border : '1px solid rgb(71, 71, 71)',
                borderRadius: '2px',
                maxWidth : '52px',
                minWidth : '52px',
                boxSizing : 'border-box',
                ...props.style
            }}
            // renderLabelItem={(label, id) => {
            //     return (
            //         <div style={{width : '100%', height : '10px', marginRight : '8px', border : `2px ${id}`, borderColor : SC.CurrentTheme.theme.color_brand }} />
            //     )
            // }}
        />
    )
}

const BorderStylePreview = styled.div`
    height : 24px;
    width : 48px;
    border : 1px ${props => props.border} ${props => props.theme.color_brand};
`;