import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Globals,
    Strings
} from '../../../../../../../../importer';
import styled, {css} from 'styled-components';

import FontStyleSheetManager from '../../../../../../../../db/FontStyleSheetManager';

export default class FontFamilyRender extends ReactBaseComponent {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        super.componentDidMount();        
        this.LoadFont();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps && nextProps.font) {
            if (nextProps.upperCase !== this.props.upperCase || nextProps.lowerCase !== this.props.lowerCase) {
                this.UpdateText = true;
            }
            if (nextProps.font.provider === Strings.FONT_GOOGLE) {
                if (!Utils.IsEqual(nextProps.font, this.props.font) || nextProps.Id !== this.props.Id) {
                    this.state.Loaded = false;
                    this.LoadFont(nextProps);
                }
            }    
            else if (nextProps.font.provider === Strings.FONT_WEBSAE) { 
                if (!Utils.IsEqual(nextProps.font, this.props.font) || nextProps.Id !== this.props.Id || nextProps.weight !== this.props.weight) {
                    this.state.Loaded = false;
                    this.LoadFont(nextProps);
                }
            }
            else if (nextProps.font.provider === Strings.CUSTOM) {
                const OldId = Utils.Get(nextProps.font, null, 'fontId');
                const NewId = Utils.Get(this.props.font, null, 'fontId');
                if (OldId !== NewId) {
                    this.state.Loaded = false;
                    this.LoadFont(nextProps);
                }
            }
        }        
        else
            this.state.Loaded = false;
        
        return true;
    }
    LoadFont(props = this.props) {
        // if (!this.props.new) 
        {
            const font = props.font;
            if (font && font.family && font.provider === Strings.FONT_GOOGLE) {
                setTimeout(()=> {
                    FontStyleSheetManager.Load(font.family, font.variant, font.url).then(() => {
                        this.RCSetStateValue('Loaded', true);
                    })
                })                                                        
            }
            else {
                if (font && font.fontId) {
                    Globals.ProjectManager.Tokens.LoadCustomFont(font.fontId).then(() => {
                        this.RCSetStateValue('Loaded', true);
                    })
                }
                else {
                    this.RCSetStateValue('Loaded', true);
                }
            }
        } 
    }
    LoadFontText(font) {
        if (this.Ref_Text1) {
            this.UpdateText = false;
            let DN = ReactDOM.findDOMNode(this.Ref_Text1);
            if (DN) {
                var bbox = DN.getBBox(); 
                if (bbox) {
                    this.ViewBox1 = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
                    this.RCUpdate();
                }
            }
            DN = ReactDOM.findDOMNode(this.Ref_Text2);
            if (DN) {
                var bbox = DN.getBBox(); 
                if (bbox) {
                    this.ViewBox2 = `${bbox.x} ${bbox.y} ${bbox.width} ${bbox.height}`;
                    this.RCUpdate();
                }
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        super.componentDidUpdate && super.componentDidUpdate(prevProps, prevState);
        if (this.props.font && (this.state.Loaded && !prevState.Loaded || this.UpdateText)) {
            this.LoadFontText(this.props.font);
        }        
    }

    renderCustom() {
        const empty = !Utils.IsNotNullOrEmpty(Utils.Get(this.props.font, '', 'family'));
        if (empty)
        {
            return (
                <FontBox fullWidth={this.props.fullWidth} empty onClick={this.props.SelectFont} error={this.props.error} >
                    <SC.Icons.Font_Icon empty size={47}  error={this.props.error} />
                    {SC.RenderValueError(this.props.error, 'Select Font')}
                </FontBox>
            )
        }
        const font = this.props.font || {};        
        const style = {
            fontSize : '24px',
            fontFamily : font.family
        };
        
        let variant = font.variant;
        if (variant && variant.indexOf('italic') > -1) {
            style.fontStyle = 'italic';
            variant = variant.replace('italic', '');
        }
        let textFamily = font.family;
        if (font.provider === Strings.FONT_WEBSAE)
            textFamily = font.name;
            
        if (variant !== 'regular')
            style.fontWeight = variant;        
        let textVariant = variant;
        if (font.provider === Strings.FONT_WEBSAE) {
            textVariant = font.weight ||'normal';
            style.fontWeight = font.weight ||'normal';
        }
        else if (font.provider === Strings.CUSTOM) {
            style.fontFamily = Utils.ToPascalCase(font.family) + Utils.UseNullOrEmpty(font.style, 'Regular');
        }

        if (this.props.upperCase) {
            textFamily = textFamily.toUpperCase();
            textVariant = textVariant &&  textVariant.toString().toUpperCase();
        }
        else if (this.props.lowerCase) {
            textFamily = textFamily.toLowerCase();
            textVariant = textVariant && textVariant.toString().toLowerCase();
        }
        let fill_back = this.props.dark ? '#1e1e1e' :  (SC.CurrentTheme.theme.isLight ? SC.CurrentTheme.theme.back_light : '#D1D1D1');
        style.fill = this.props.dark ? '#D1D1D1' : '#1e1e1e';
        return (
            <FontBox fullWidth={this.props.fullWidth} style={{backgroundColor : fill_back}} error={this.props.error} height={this.props.height} onClick={this.props.SelectFont}>
                <SC.FCol pa={8} style={{position : 'absolute', top : 0, left : 0, right : 0, bottom : 0}} >
                    <SC.Svg width='100%' height = '60%' viewBox={this.ViewBox1} >
                        <text {...style} ref={(r) => this.Ref_Text1 = r} >
                            {textFamily}
                        </text>
                    </SC.Svg>               
                    {
                        textVariant ? 
                        <SC.Svg width='100%' height = '40%' viewBox={this.ViewBox2} >
                            <text {...style} ref={(r) => this.Ref_Text2 = r} >
                                {textVariant}
                            </text>
                        </SC.Svg> : null
                    }                         
                </SC.FCol>                
                {
                    !this.state.Loaded && !this.props.new ? 
                    <SC.Div_Flex_Cell style={{
                        position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, backgroundColor : 'rgba(0,0,0,0.5)'
                    }}>
                        <SC.Loading_Icon justIcon/>
                    </SC.Div_Flex_Cell> : null
                }          
            </FontBox>                
        )
    }
}

const FontBox = styled.div`
    position : relative;
    cursor : pointer;
    width : 100%;
    height : ${props => props.height || 140}px;
    border-radius : 2px;
    background-color : ${props => props.empty ? props.theme.color_content_back : (props.dark ? 'black' : 'white')};     
    display : flex;
    justify-content : center;
    align-item : center;
`;