import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../importer';

import styled, {css} from 'styled-components';
import { motion } from 'framer-motion';

export const LeftScrollPanel = (props) => {
    return (
        <SC.CustomScrollbars ScrollRef={props.ScrollRef} {...props} />
    )    
}

export const DotMenu = ({onClick, onClose, isOpen, children, fill, style, buttonStyle}) => {
    return (
        <SC.Icons.Icon_ButtonBox hasFill hasCursor fill={fill} onClick={(e) => e.stopPropagation()} onMouseDown={isOpen ? null : onClick} style={{position : 'relative', pointerEvents : 'all', ...buttonStyle}} selected={isOpen ? '#39769a' : null} >
            <SC.Icons.Icon_DotMenu size={18} />
            {
                isOpen && 
                <div style={{...style, position : 'absolute', top : '100%', right : 0}}>
                    <OutsideCloseDotMenuContainer onClose={onClose || onClick}>
                        {children}
                    </OutsideCloseDotMenuContainer>    
                </div>                
            }
        </SC.Icons.Icon_ButtonBox>
    )
}

export const DotMenuContainerBox = (props) => {
    return (
        <motion.div 
            style={{
                position : 'absolute',
                top : '100%',
                right : 0,
                zIndex : 100000,
                border : SC.CurrentTheme.theme.border_popup,
                borderRadius : '2px',
                boxSizing : 'border-box',
                backgroundColor : props.light ? SC.CurrentTheme.theme.dropdown_back : SC.CurrentTheme.theme.dropdown_back_dark,
                boxShadow : SC.CurrentTheme.theme.popup_shadow,  
                paddingTop : '4px',
                paddingBottom : '4px',
                ...props.style              
            }}
            initial={{x : props.x || 0, opacity : 0.7, y : -10}}
            animate={{x : props.x || 0, opacity : 1, y : 0}}
            exit={{x : props.x || 0, opacity : 0, y : -10}}
            transition={{duration : 0.2}}
        >
            {props.children}
        </motion.div>
    )
}

export const OutsideCloseDotMenuContainer = UIUtils.OutSideClickHandler(DotMenuContainerBox);

export const StyleInputName = styled.input`
    font-size : 12px;
    padding : 4px;
    padding-left : 0;
    line-height : 16px;
    border : none;
    background-color : transparent;
    border-bottom : ${props => props.theme.border_ondark}
    width : 100%;
    box-sizing : border-box;
    text-align : left;
    ${SC.TransitionHoverOff}
    &:focus {
        border-bottom : 1px solid ${props => props.theme.color_brand};
    }
    ${
    props => {
        if (props.error)
            return css`
                ${SC.TransitionHoverOn}
                border-bottom : 0.5px solid ${props => props.theme.card_input_error_border_color};
            `;
    }
    }
    font-style : ${props => Utils.IsNotNullOrEmpty(props.value) ? 'normal' : 'italic'};
`;