import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';
import { GetColorTokenList, GetGradientTokenList, ColorTokenListItem, GetColorTokenItem } from '../colors';
import { GetShadowTokenList, ShadowListItem, GetShadowTokenItem } from '../shadows';
import { GetBorderStyleTokenList, GetBorderRadiusTokenList, BorderStyleTokenItem, BorderRadiusTokenItem, GetBorderStyleTokenValue, GetBorderRadiusTokenValue } from '../borders';
import { GetTransformTokenList, TransformListItem } from '../transforms';
import { GetFilterTokenList, FilterListItem } from '../filters';
import { GetTextContentTokenList, TextContentListItem } from '../textContent';
import { GetIconTokenList, IconListItem } from '../icons';
import { GetFontItemList, FontSelectItem } from '../typography/fonts';
import { GetTextPatternList, TextPattern } from '../typography/patterns';
import { GetDurationPatternList, DurationPattern } from '../motion/patterns';
import { GetEaseCurveTokenList, EasePattern } from '../motion/easing';
import { GetTransitionTokenList, TransitionListItem } from '../motion/transitions';
import { IconBox } from '../icons/item';
import { TokenPanelHeader } from '../common';
import Switch from '../../../../../../components/editors/Switch';
import { SearchInput } from '../../../../searchbar';
import { ColorTokenAliaseListItem, ShadowTokenAliaseListItem, BorderTokenAliaseListItem, RadiusTokenAliaseListItem, SpacePatternAliaseItem, GetTokenAliaseList } from '../aliases';
import { GetSpacePatternItem, GetSpacingPatternList, SpacePattern } from '../spacing/patterns';

const TokenTypes = Globals.ProjectManager.Tokens.Types;

export default class MultipleTokenSelector extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);
    }
    onSearch(filter) {
        this.setState({FilterText : filter});
    }
    CanceSearch() {
        this.setState({FilterText : ''});
    }
    ToggleSelectedToken(tokenId, selectedTokens, selected) {
        if (selected)
            selectedTokens.push(tokenId);
        else
            Utils.RemoveEquals(selectedTokens, tokenId);
        
        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedTokens);
        this.RCUpdate();
    }
    ToggleSelectedAliase(tokenId, selectedTokens, selected) {
        if (selected)
            selectedTokens.push(tokenId);
        else
            Utils.RemoveEquals(selectedTokens, tokenId);
        
        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedTokens);
        this.RCUpdate();
    }
    SelectAll(select, tokens) {
        if (!tokens)
            return;
            
        const {selectedTokens}  = this.props;
        
        let filterText;
        if (Utils.IsNotNullOrEmpty(this.state.FilterText)) {
            filterText = this.state.FilterText.toUpperCase();
        }

        tokens.map(({id, name}) => {
            let isFiltered = false;
            if (filterText) {
                if (name && name.toUpperCase().indexOf(filterText) < 0) {                    
                    isFiltered = true;
                }
            }
            if (!isFiltered) {
                if (select) {
                    if (selectedTokens.indexOf(id) < 0)
                        selectedTokens.push(id);
                }
                else {
                    Utils.RemoveEquals(selectedTokens, id);
                }
            }                
        })

        this.props.onSelectionChanged && this.props.onSelectionChanged(selectedTokens);
        this.RCUpdate();
    }
    renderCustom() {
        const {selectedTokens, tokenType, subType}  = this.props;
        const tokenItems = [];

        let tokens;
        if (this.props.isAliase) {
            tokens = GetTokenAliaseList(tokenType);
            
            let filterText;
            if (Utils.IsNotNullOrEmpty(this.state.FilterText)) {
                filterText = this.state.FilterText.toUpperCase();
            }
            Utils.ForEach(tokens, (aliase, i) => {
                let isFiltered;

                if (filterText) {
                    if (aliase.name.toUpperCase().indexOf(filterText) < 0) {                    
                        isFiltered = true;
                    }
                }

                if (!isFiltered) {
                    const isSelected = selectedTokens.indexOf(aliase.id) > -1;
                    const onClick = this.ToggleSelectedAliase.bind(this, aliase.id, selectedTokens, !isSelected);
                    tokenItems.push(
                        <Switch key={i} checkEdit value={isSelected} onChange={onClick} />
                    );
                            
                    if (tokenType === TokenTypes.COLOR) {

                        if (!aliase.tokenItem) {
                            aliase.tokenItem = GetColorTokenItem({
                                id : aliase.tokenId,
                                token : Globals.ProjectManager.Tokens.Token(aliase.tokenId)                    
                            })
                        }

                        tokenItems.push(
                            <ColorTokenAliaseListItem 
                                key={aliase.id}
                                aliase={aliase}
                                onSelect={onClick}
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Gradients) {
                        tokenItems.push(
                            <ColorTokenListItem 
                                key={aliase.id}
                                color={aliase}
                                onSelect={onClick}
                                gradient
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Shadows) {
                        if (!aliase.tokenItem) {
                            aliase.tokenItem = GetShadowTokenItem({
                                id : aliase.tokenId,
                                token : Globals.ProjectManager.Tokens.Token(aliase.tokenId)                    
                            })
                        }

                        tokenItems.push(
                            <ShadowTokenAliaseListItem 
                                key={aliase.id}
                                aliase={aliase}
                                onSelect={onClick}
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Borders) {
                        if (!aliase.tokenItem) {
                            aliase.tokenItem = GetBorderStyleTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                        }

                        tokenItems.push(
                            <BorderTokenAliaseListItem 
                                key={aliase.id}
                                aliase={aliase}
                                onSelect={onClick}
                            />
                        )
                    } 
                    else if (tokenType === TokenTypes.BorderRadiuses) {
                        if (!aliase.tokenItem) {
                            aliase.tokenItem = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(aliase.tokenId), aliase.tokenId);
                        }

                        tokenItems.push(
                            <RadiusTokenAliaseListItem 
                                key={aliase.id}
                                aliase={aliase}
                                onSelect={onClick}
                            />
                        )
                    } 
                    else if (tokenType === TokenTypes.Spacings) {
                        if (!aliase.tokenItem) {
                            aliase.tokenItem = GetSpacePatternItem(aliase.tokenId);
                        }

                        tokenItems.push(
                            <SpacePatternAliaseItem 
                                key={aliase.id}
                                aliase={aliase}
                                onSelect={onClick}
                            />
                        )
                    } 
                }                                          
            });
        }
        else {
            if (tokenType === TokenTypes.COLOR) {
                tokens = GetColorTokenList();
            }
            else if (tokenType === TokenTypes.Gradients) {
                tokens = GetGradientTokenList();
            }
            else if (tokenType === TokenTypes.Shadows) {
                tokens = GetShadowTokenList().All;
            }
            else if (tokenType === TokenTypes.Borders) {
                tokens = GetBorderStyleTokenList();
            }
            else if (tokenType === TokenTypes.BorderRadiuses) {
                tokens = GetBorderRadiusTokenList().items;
            }
            else if (tokenType === TokenTypes.Transforms) {
                tokens = GetTransformTokenList();
            }
            else if (tokenType === TokenTypes.Filters) {
                tokens = GetFilterTokenList();
            }
            else if (tokenType === TokenTypes.ContentTexts) {
                tokens = GetTextContentTokenList();
            }
            else if (tokenType === TokenTypes.Icons) {
                tokens = GetIconTokenList();
            }
            else if (tokenType === TokenTypes.Spacings) {
                tokens = GetSpacingPatternList();
            }
            else if (tokenType === TokenTypes.Fonts) {
                if (subType === 'fonts') {
                    tokens = GetFontItemList();
                }
                else if (subType === 'scale') {
    
                }
                else {
                    tokens = GetTextPatternList();
                }                
            }
            else if (tokenType === TokenTypes.Motion) {
                if (subType === 'durations') {
                    tokens = GetDurationPatternList();
                }
                else if (subType === 'ease') {
                    tokens = GetEaseCurveTokenList();
                }
                else if (subType === 'transitions') {
                    tokens = GetTransitionTokenList();
                }                
            }
    
            let filterText;
            if (Utils.IsNotNullOrEmpty(this.state.FilterText)) {
                filterText = this.state.FilterText.toUpperCase();
            }
            Utils.ForEach(tokens, (token, i) => {
                let isFiltered;
    
                if (filterText) {
                    if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                        isFiltered = true;
                    }
                }
    
                if (!isFiltered) {
                    const isSelected = selectedTokens.indexOf(token.id) > -1;
                    const onClick = this.ToggleSelectedToken.bind(this, token.id, selectedTokens, !isSelected);
                    tokenItems.push(
                        <Switch key={i} checkEdit value={isSelected} onChange={onClick} />
                    );
                        
    
    
                    if (tokenType === TokenTypes.COLOR) {
                        tokenItems.push(
                            <ColorTokenListItem 
                                key={token.id}
                                color={token}
                                onSelect={onClick}
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Gradients) {
                        tokenItems.push(
                            <ColorTokenListItem 
                                key={token.id}
                                color={token}
                                onSelect={onClick}
                                gradient
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Shadows) {
                        tokenItems.push(
                            <ShadowListItem 
                                key={token.id}
                                shadow={token}
                                onSelect={onClick}
                                textShadow={token.textShadow}
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Borders) {
                        tokenItems.push(
                            <BorderStyleTokenItem 
                                key={token.id}
                                border={token}
                                onSelect={onClick}
                                isListView
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.BorderRadiuses) {
                        tokenItems.push(
                            <BorderRadiusTokenItem 
                                key={token.id}
                                radius={token}
                                onSelect={onClick}
                                isListView
                            />
                        ) 
                    } 
                    else if (tokenType === TokenTypes.Transforms) {
                        tokenItems.push(
                            <TransformListItem 
                                key={token.id}
                                transform={token}
                                onSelect={onClick}
                            />
                        ) 
                    }        
                    else if (tokenType === TokenTypes.Filters) {
                        tokenItems.push(
                            <FilterListItem 
                                key={token.id}
                                filter={token}
                                onSelect={onClick}
                            />
                        ) 
                    }   
                    else if (tokenType === TokenTypes.ContentTexts) {
                        tokenItems.push(
                            <TextContentListItem 
                                key={token.id}
                                filter={token}
                                onSelect={onClick}
                                name={token.name}
                                value={token.value}
                            />
                        ) 
                    }  
                    else if (tokenType === TokenTypes.Icons) {
                        const icon_style = { width : '60px', height : '60px' }
    
                        let iconitem; 
                        if (Utils.IsNotNullOrEmpty(token.url)) {
                            iconitem = (
                                <IconBox                         
                                    url={token.url}
                                    style={{...icon_style}}
                                    title={token.name}                        
                                />
                            )                     
                        }    
                        else if (token.paths) {
                            iconitem = (
                                <IconBox                        
                                    style={{...icon_style}}
                                    title={token.name}
                                >
                                    <SC.Icons_Preview compact  xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='80%' dark={SC.CurrentTheme.theme.isLight} >
                                        {token.paths}
                                    </SC.Icons_Preview>    
                                </IconBox>
                            )
                        }   
    
                        tokenItems.push(
                            <IconListItem 
                                key={token.id}
                                onSelect={onClick}
                                name={token.name}
                                url={token.url}
                                icon={iconitem}
                                onClick={onClick}
                            />
                        )
                    }
                    else if (tokenType === TokenTypes.Spacings) {
                        tokenItems.push(
                            <SpacePattern 
                                key={token.id}
                                pattern={token.pattern}
                                spaceValue={token.spaceValue}
                                spaceUnit={token.spaceUnit}
                                onClick={onClick} 
                                listView
                            />
                        )
                    }
                    else if (tokenType === TokenTypes.Fonts) {
                        if (subType === 'fonts') {
                            tokenItems.push(
                                <FontSelectItem
                                    key={token.id}
                                    font={token.font}
                                    onClick={onClick}
                                    name={token.name}
                                />
                            )
                        }
                        else if (subType === 'scale') {
        
                        }
                        else {
                            tokenItems.push(
                                <TextPattern
                                    key={token.id}
                                    pattern={token.pattern}
                                    onClick={onClick}
                                    name={token.name}
                                    listView
                                />
                            )
                        }                         
                    }  
                    else if (tokenType === TokenTypes.Motion) {
                        if (subType === 'durations') {
                            tokenItems.push(
                                <DurationPattern
                                    key={token.id}
                                    pattern={token.pattern}
                                    timeValue={token.timeValue}
                                    onClick={onClick}
                                    name={token.name}
                                />
                            )
                        }
                        else if (subType === 'ease') {
                            tokenItems.push(
                                <EasePattern
                                    key={token.id}
                                    pattern={token}
                                    onClick={onClick}
                                    name={token.name}
                                    listView
                                />
                            )
                        }
                        else if (subType === 'transitions') {
                            tokenItems.push(
                                <TransitionListItem
                                    key={token.id}
                                    transition={token}
                                    onClick={onClick}
                                    name={token.name}
                                    listView
                                />
                            )
                        }                         
                    }  
                }                                          
            });
        }
        
        
        return (
            <React.Fragment>
                <div style={{padding : '8px', paddingBottom : 0}}>
                    <SearchInput
                        FilterText={this.state.FilterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                        small
                        full                            
                    /> 
                </div> 
                <SC.FRow alc jsb style={{padding : '10px'}}>
                    <SC.LinkText onClick={this.SelectAll.bind(this, true, tokens)}>Select All</SC.LinkText>
                    <SC.LinkText onClick={this.SelectAll.bind(this, false, tokens)}>Deselect All</SC.LinkText>
                </SC.FRow>
                <SC.CustomScrollbars>
                    <div style={{
                        padding : '8px',
                        paddingTop : 0,
                        display : 'grid',
                        gridTemplateColumns : 'auto 1fr',
                        alignItems : 'center',
                        gridColumnGap : '8px'
                    }}>
                        {tokenItems}
                    </div>
                </SC.CustomScrollbars>
            </React.Fragment>            
        )
    }
}