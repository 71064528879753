/* eslint-disable */
import React, {Component} from 'react';
import { Utils } from './importer';
  
class ReactBasicComponent extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            ShouldUpdate : false
        };
        this.RCUpdate = this.RCUpdate.bind(this);        
    }    
    RCUpdate() {
        this.setState({ShouldUpdate : true});
    }    
    ShouldUpdate(nextProps, nextState) {
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        if (Utils.HasAnyChange(this.props, nextProps, 'themeId', 'GlobalState', 'GlobalStateId', 'GlobalThemeId'))
            return true;
        return false;
    }
    render() {
        this.state.ShouldUpdate = false;
        if (this.renderCustom) {
            let  rh = null;
            rh = this.renderCustom();
            if (rh === undefined) {
                return null;
            }
            return rh;
        }                    
        return null;
    }
}

export default ReactBasicComponent;