import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../importer';

import styled, {css} from 'styled-components';
import {TokenItemBox, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { LeftScrollPanel } from '../../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import ShadowTokenEditor from './item';
import BaseTokenList from '../BaseTokenList';
import { StatefulTokenListItemName } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Shadows extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'SHADOWS';
        this.moduleName = 'Shadows';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Shadows;
        
        this.Ref_ShadowEdit = React.createRef();
        this.onEditShadow = this.onEditShadow.bind(this);
        this.EditShadow = this.EditShadow.bind(this);
        this.onDeleteShadow = this.onDeleteShadow.bind(this);
        this.onCloneShadow = this.onCloneShadow.bind(this);
        this.onCancelAddShadow = this.onCancelAddShadow.bind(this);
        this.onSubmitNewShadow = this.onSubmitNewShadow.bind(this);

        this.onSortShadow = this.onSortShadow.bind(this);

        AppLayout.Refs.DesignSystem.Shadows = this;

        super.LoadOptions();
        
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.props);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Shadows = null;
    }
    Load(props) {
        const result = GetShadowTokenList();
        this.BoxShadows = result.BoxShadows;
        this.TextShadows = result.TextShadows;    
    }
    SearchToken(filter) {
        let filterText;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.BoxShadows, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        Utils.ForEach(this.TextShadows, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }                
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (this.props.showSearchBar !== nextProps.showSearchBar)
            return true;
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextProps);
            return true;
        }
        if (Utils.HasAnyChange(this.state, nextState, 'isListView', 'ShouldUpdate'))
            return true;
        if (this.props.singleView !== nextProps.singleView) {
            if (nextProps.singleView && !this.expanded) {
                this.expanded = true;
                this.Load(nextProps);
            }            
            return true;
        }
        if (this.props.themeId !== nextProps.themeId)
            return true;
        return false;
    }
    onSortShadow(textShadow, oldIndex, newIndex) {
        Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.Shadows, oldIndex, newIndex);        
        if (textShadow) {
            Utils.ChangePlace(this.TextShadows, oldIndex, newIndex);
        }
        else
            Utils.ChangePlace(this.BoxShadows, oldIndex, newIndex);
        this.RCUpdate();
    }        
    AddToken() {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.IsNew = true;
        this.EditShadowId = Utils.Id();
        this.EditShadowModel = {
            name : 'New Shadow',
            values : []
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            notBackClosable
                            title='NEW SHADOW' 
                            hasAddCancel 
                            onClose={this.onCancelAddShadow} 
                            onCancel={this.onCancelAddShadow} 
                            onAdd={this.onSubmitNewShadow} 
                        />
                        <LeftScrollPanel>
                            <ShadowTokenEditor 
                                ref={this.Ref_ShadowEdit}
                                newModel={this.EditShadowModel}
                                GlobalState={props.GlobalState}
                                RefToolbar={this.props.RefToolbar}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    onEditShadow(id, e) {
        if (this.props.onSelect) {
            this.props.onSelect(id, e);
            return;
        }
        this.EditShadow(id);
    }
    onShowMenu(id, e) {
        this.props.onShowMenu && this.props.onShowMenu(id, e);
    }
    EditShadow(id) {        
        
        this.EditShadowId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT SHADOW' 
                            hasAddCancel 
                            onClose={this.onSubmitNewShadow} 
                            onCancel={this.onCancelAddShadow} 
                            onAdd={this.onSubmitNewShadow} 
                            onDelete={this.onDeleteShadow} 
                            onClone={this.onCloneShadow}
                        />
                        <LeftScrollPanel>
                            <ShadowTokenEditor 
                                ref={this.Ref_ShadowEdit}
                                id={id}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddShadow}
                                RefToolbar={this.props.RefToolbar}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneShadow() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditShadowId, Globals.ProjectManager.Tokens.Types.Shadows);
        this.Load(this.props);
        this.EditShadow(cloneId);        
    }
    onDeleteShadow() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Shadows, this.EditShadowId)) {
            this.Load(this.props);
            this.onCancelAddShadow();
        }
    }
    onCancelAddShadow() {
        delete this.IsNew;
        delete this.EditShadowModel;
        delete this.EditShadowId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewShadow() {
        if (this.IsNew) {
            if (this.EditShadowModel.values) {
                Utils.ForEach(this.EditShadowModel.values, (shadowItem, ) => {
                    if (shadowItem.colorId) {
                        shadowItem.ColorRelationId = Globals.ProjectManager.Tokens.BindTokenToToken({
                            SourceTokenId : this.EditShadowId,
                            ConsumerId : shadowItem.id,
                            TargetTokenId : shadowItem.colorId
                        });
                    }
                });
            }
            const shadowItem = AddNewShadowToken(this.EditShadowModel, this.EditShadowId);
            
            if (this.EditShadowModel.textShadow) {
                this.TextShadows.push(shadowItem)
            }
            else {
                this.BoxShadows.push(shadowItem)
            }                    

            this.WillScrollTo = this.EditShadowId;
            this.selectedId = this.EditShadowId;

            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditShadowId);
            if (token) {
                const localpattern = Utils.Find(token.textShadow ? this.TextShadows : this.BoxShadows, (item) => {return item.id === this.EditShadowId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        this.onCancelAddShadow();
    }    
    renderContent() {

        let content;

        if (this.expanded) {
            
            const boxShadows = [];
            const textShadows = [];
            if (this.state.isListView) {
                Utils.ForEach(this.BoxShadows, (shadow, i) => {
                    !shadow.filtered && boxShadows.push(
                        <SortableListItem
                            key={shadow.id}
                            draggableId={shadow.id}
                            index={i}
                            ItemBoxType={ShadowListItem}
                            BoxProps={{
                                shadow : shadow,
                                onSelect : this.onEditShadow.bind(this, shadow.id),
                                onMenu : this.onShowMenu.bind(this, shadow.id)
                            }}                
                        />        
                    )
                });

                Utils.ForEach(this.TextShadows, (shadow, i) => {
                    !shadow.filtered && textShadows.push(
                        <SortableListItem
                            key={shadow.id}
                            draggableId={shadow.id}
                            index={i}
                            ItemBoxType={ShadowListItem}
                            BoxProps={{
                                shadow : shadow,
                                textShadow : true,
                                onSelect : this.onEditShadow.bind(this, shadow.id),
                                onMenu : this.onShowMenu.bind(this, shadow.id)
                            }}                
                        />                                                  
                    )
                });

                content = (
                    <SC.FCol>
                        <SortableList
                            style={{paddingLeft : '10px', paddingRight : '10px', marginTop : '6px'}}
                            onSort={this.onSortShadow.bind(this, false)}
                        >
                            {boxShadows}
                        </SortableList>
                        {
                            this.TextShadows.length > 0 &&
                            <SortableList
                                style={{paddingLeft : '10px', paddingRight : '10px', marginTop : '6px', paddingBottom : 0}}
                                onSort={this.onSortShadow.bind(this, true)}
                            >
                                {textShadows}
                            </SortableList>
                        }
                    </SC.FCol>
                )
            }
            else {               
                Utils.ForEach(this.BoxShadows, (shadow, i) => {
                    !shadow.filtered && boxShadows.push(
                        <ShadowCardItem
                            shadow={shadow}
                            key={shadow.id} 
                            onClick={this.onEditShadow.bind(this, shadow.id)}
                            onMenu={this.onShowMenu.bind(this, shadow.id)}
                        />
                    )
                });

                Utils.ForEach(this.TextShadows, (shadow, i) => {
                    !shadow.filtered && textShadows.push(
                        <ShadowCardItem
                            textShadow
                            shadow={shadow}
                            key={shadow.id} 
                            onClick={this.onEditShadow.bind(this, shadow.id)}
                            onMenu={this.onShowMenu.bind(this, shadow.id)}
                        />
                    )
                });
                
                content = (
                    <SC.FRow style={{padding : '10px', paddingTop : '4px', flexWrap : 'wrap'}}>
                        {boxShadows}
                        {textShadows}
                    </SC.FRow>
                )
            }
            
        }        

        return content;
    }
}

export const AddNewShadowToken = (model, id) => {
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.Shadows,
        name : model.name,
        textShadow : model.textShadow,
        value : {
            values : model.values
        },
        id : id
    })
    let shadowvalue = Utils.GetShadowCss(model.values, model.textShadow, Globals.ProjectManager.Tokens.ValueOfId); 
    return {
        id : id,
        name : model.name,
        value : shadowvalue
    };
}

export const ShadowCardItem = ({shadow, textShadow, onClick, onMenu, onPreview, selected, noMargin, small, style, ...rest}) => {
    return (
        <ShadowPreviewBox 
            dark={shadow.dark} 
            small 
            style={{minWidth : '60px', maxWidth : '60px', minHeight : small ? '40px' : '60px', margin : noMargin ? 0 : '2px', overflow : 'hidden'}} 
            onClick={onClick} 
            onContextMenu={onMenu}
            {...onPreview}
            title={shadow.name}
            selected={selected}
            {...rest}
        >
            {
                textShadow ? 
                <div style={{
                    textShadow : shadow.value,
                    fontSize : '32px',
                    color : shadow.dark ? '#2d2e32' : '#fff',
                    fontWeight : 500
                }}>
                    Aa
                </div> : 
                <div style={{
                    boxShadow : shadow.value,
                    width : '100px',
                    height : '100px',
                    backgroundColor : shadow.dark ? '#2d2e32' : '#fff',
                    borderRadius : '4px',
                    position : 'absolute',
                    transform : 'scale(0.3)'
                }} />
            }                       
        </ShadowPreviewBox>
    )
}

export const ShadowListItem = ({onSelect, onMenu, notDraggable, sortableProps, name, shadow, textShadow, onPreview, selected, style, ...rest}) => {
    const style_box = {
        ...style,
        padding : '0px', paddingLeft : '6px',
        ...SC.Styles.Flex.RowAlcJsb,
        position : 'relative'
    }
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }

    return (
        <TokenItemBox onClick={onSelect} onContextMenu={onMenu} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>               
                <StatefulTokenListItemName 
                    name={name || shadow.name}
                    sortableProps={sortableProps}
                    ValueState={shadow.ValueState}
                />
            </SC.FRow> 
            <ShadowCardItem
                shadow={shadow}
                textShadow={textShadow}
                key={shadow.id} 
                notDraggable={notDraggable}
            />
        </TokenItemBox>
    )
}


export const GetShadowTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Shadows();
    return GetShadowTokenListOfIds(tokenids, StateArray);
}

export const GetShadowTokenListOfIds = (tokenids, StateArray) => {    
    const tokens = Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Shadows);
    const result = {
        BoxShadows : [],
        TextShadows  :[],
        All : []
    }

    Utils.ForEach(tokenids, (id, i) => {
        const token = tokens[id];
        if (token) {
            const tokenitem = GetShadowTokenItem({token : token, id : id, statearray : StateArray});
            if (token.textShadow) {
                result.TextShadows.push(tokenitem);
            }
            else {
                result.BoxShadows.push(tokenitem)
            }

            result.All.push(tokenitem);
        }
    });  
    return result;
}

export const GetShadowTokenBoxCss = (tokenId) => {
    const shadows = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOfId(tokenId), [], 'values');
    return Utils.GetShadowCss(shadows, false, 
        (colorId) => {
            return Globals.ProjectManager.Tokens.ValueOfId(colorId);
        }
    );
}

export const GetShadowTokenItem = ({token, id, statearray}) => {
    let useToken = token;
    if (!token)
        useToken = Globals.ProjectManager.Tokens.Token(id);

    const valueInfo = {};
    const shadows = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : useToken, statearray : statearray, info : valueInfo}), [], 'values');
    const dark = Globals.ProjectManager.Tokens.ValueOf({model : useToken, name : 'dark', statearray : statearray});
    let shadowvalue = Utils.GetShadowCss(shadows, useToken.textShadow, 
        (tokenId) => {
            return Globals.ProjectManager.Tokens.ValueOfId(tokenId, statearray);
        }
    ); 
    if (useToken.textShadow) {
        return {
            id : id,
            name : useToken.name,
            value : shadowvalue,
            dark : dark,
            textShadow : useToken.textShadow,
            ValueState : valueInfo.ValueState
        };
    }
    else {
        return {
            id : id,
            name : useToken.name,
            value : shadowvalue,
            dark : dark,
            ValueState : valueInfo.ValueState
        };
    }               
}

export const ShadowPreviewBox = styled.div`
    position : relative;
    display : flex;
    align-items : center;
    justify-content : center;
    flex : 1;
    border-radius : 2px;
    margin-bottom : 8px;
    box-sizing : border-box;
    cursor : pointer;
    transition : all 0.2s ease;
    background-clip : content-box;
    border : ${props => props.theme.border_ondark};
    ${SC.CssGridBackground}    
    ${
        props => {
            if (props.selected) {
                return css`
                    border : ${props => props.theme.border_brand};
                    padding : 2px;
                `;
            }
        }
    }
`;