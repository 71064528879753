import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../../importer';
import InputSlider from '../../../components/editors/input_slider';
import {FigmaInfoPanel} from '../common';
import { AccountMenuItem } from '../../../views/header/account';
import Switch from '../../../components/editors/Switch';
import { Seperator } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { LoadZipper } from '../../../views/project/publish/steps';

export default class FigmaOptions extends ReactBasicComponent {
    constructor(props) {
        super(props);
        this.state = { 
            newProject : true
        }        
    }
    onResizePlugin(prop, value) {
        this.props.onResizePlugin(prop, value);        
    }
    SetOption(prop, value) {
        Utils.Set(FigmaPluginOptions, value, prop, 'value');
        this.props.onSetOption(prop, value);
        this.RCUpdate();
    }
    DownloadDebugData() {

        this.props.GetPluginApi().GetDebugData((debugData) => {
            const data = {
                projectId : this.props.projectId,
                debug : debugData
            };
    
    
            const data_string = JSON.stringify(data, null, 2);
            const fileName = 'ToolabsFigmaDebugData.zip';
            
        
    
            LoadZipper(true).then(({jsZip, jsZipUtils, FileSaver}) => {            
                
                var zip = new jsZip();
                zip.file('Tokens.json', data_string)
                zip.generateAsync({type:"blob"})
                .then((blob) => {
    
                    FileSaver.saveAs(blob, fileName);
                });         
            });
        })

        return;

        
    }    
    renderCustom() {
        const style_option = {
            display : 'grid',
            gridTemplateColumns : '1fr auto',
            gridGap : '8px'
        };
        const style_optionPanel = {
            backgroundColor : '#e8f8ff',
            color : '#000',
            borderRadius : '2px',
            position : 'relative',
            lineHeight : '16px',
            margin : '10px', 
            marginBottom : 0, 
            padding : '12px'
        }
        return (
            <SC.FCol>
                
                <FigmaInfoPanel style={{margin : '10px', marginBottom : 0}}>
                    <SC.FCol style={{maxWidth : '260px', width : '100%'}}>
                        <div style={{marginBottom : '8px'}}>Plugin Size</div>
                        <SC.FRow alc jsb>
                            <div style={{width : '60px'}}>Width</div>
                            <InputSlider   horizontal reverse min={300} max={500} value={Math.max(240, this.props.width)} onChange={this.onResizePlugin.bind(this, 'width')} onChanging={this.onResizePlugin.bind(this, 'width')} />
                        </SC.FRow>
                        <SC.FRow alc jsb style={{marginTop : '8px'}}>
                            <div style={{width : '60px'}}>Height</div>
                            <InputSlider horizontal reverse min={600} max={1200} value={Math.max(400, this.props.height)} onChange={this.onResizePlugin.bind(this, 'height')} onChanging={this.onResizePlugin.bind(this, 'height')} />
                        </SC.FRow>    
                    </SC.FCol>
                </FigmaInfoPanel>
                <div style={style_optionPanel}>
                    <div style={style_option}>
                        <div>Check Figma styles unlinked to tokens on project load</div>
                        <Switch round value={Utils.JustGet(this.props.options, true, FigmaPluginOptions.checkStyleTokensonLoad.name)} onChange={this.SetOption.bind(this, FigmaPluginOptions.checkStyleTokensonLoad.name)}/>    
                    </div>
                </div>
                <div style={style_optionPanel}>
                    <div style={style_option}>
                        <div>Create New Tokens Automatically</div>
                        <Switch round value={Utils.JustGet(this.props.options, true, FigmaPluginOptions.autoCreateTokens.name)} onChange={this.SetOption.bind(this, FigmaPluginOptions.autoCreateTokens.name)}/>    
                    </div>
                    <Seperator /> 
                    <div style={{
                        opacity : 0.8
                    }}>
                        When this options is ON, if you create a new Figma style, a new token will be created and linked to that style. Otherwise, you can manually link those styles to tokens from Selection Tab.
                    </div>                    
                </div>
                <div style={style_optionPanel}>
                    <div style={style_option}>
                        <div>Clicking Tokens will apply the style </div>
                        <Switch round value={Utils.JustGet(this.props.options, false, FigmaPluginOptions.tokenEditMode.name)} onChange={this.SetOption.bind(this, FigmaPluginOptions.tokenEditMode.name)}/>    
                    </div>
                    <Seperator />
                    <div style={{
                        opacity : 0.8
                    }}>
                        When this options is ON, clicking a token item in Tokens panel will apply the token to selected Figma objects, and will show other options as context menu on right click.
                    </div>                    
                </div>
                <div style={style_optionPanel}>
                    <div style={style_option}>
                        <div>Use Token Name as Figma Style Names for Semantic Tokens </div>
                        <Switch round value={Utils.JustGet(this.props.options, false, FigmaPluginOptions.semanticTokenName.name)} onChange={this.SetOption.bind(this, FigmaPluginOptions.semanticTokenName.name)}/>    
                    </div>
                </div>
                {
                    <SC.Buttons.RoundButton onClick={this.DownloadDebugData.bind(this)} xsmall style={{marginRight : 'auto', marginLeft : 'auto', marginTop : '16px'}}>Download Plugin Debug Data</SC.Buttons.RoundButton>
                }   
                
            </SC.FCol>                        
        )
    }
}

export const FigmaPluginOptions = {
    autoCreateTokens : {
        name : 'autoCreateTokens',
        value : true
    },
    checkStyleTokensonLoad : {
        name : 'checkStyleTokensonLoad',
        value : true
    },
    tokenEditMode : {
        name : 'tokenEditMode',
        value : false
    },
    semanticTokenName : {
        name : 'semanticTokenName',
        value : false
    },
}