import React from 'react';
import ReactDOM from 'react-dom';
import DragDropManager from './DragDropManager';
import {
    AppState,
    UIUtils,
    Utils,
    Events
} from '../../importer';

const DragSource = (Wrapped) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {};
            this.onMouseDown = this.onMouseDown.bind(this);
            this.onMouseUp = this.onMouseUp.bind(this);
            this.onMouseMove = this.onMouseMove.bind(this);
            this.StartDrag = this.StartDrag.bind(this);
        }
        componentDidMount() {
            this.Mounted = true;
            // window.addEventListener('mousedown', this.onMouseDown);
        }
        componentWillUnmount() {
            this.Mounted = false;
            // document.removeEventListener('mousedown', this.onMouseDown);
        }
        GetDocument() {
            if (this.props.onGetDocument)
                return this.props.onGetDocument();
            return this.props.document || document;
        }
        onMouseDown(e) {            
            if (this.props.Preview)
                return;
            if (e.ctrlKey)
                return;
                
            if (AppState.Designer.EditingLayoutItem || AppState.Designer.DisableDragDrop)   
                return;
            
            if (e && e.button !== 0)    
                return;            
            e.stopPropagation();
            e.preventDefault();
            
            if (!this.Mounted)
                return;
            this.DragStarted = false;
            this.MouseDown = true;
            document.addEventListener('mousemove', this.onMouseMove);
            document.addEventListener('mouseup', this.onMouseUp);
            const useDocument = this.GetDocument();
            if (useDocument !== document) {
                useDocument.addEventListener('mousemove', this.onMouseMove);
                useDocument.addEventListener('mouseup', this.onMouseUp);
            }            

            this.DragStart_x = e.clientX;
            this.DragStart_y = e.clientY;
            this.Shift = e.shiftKey;            
            const DN = ReactDOM.findDOMNode(this);            
         
            if (this.props.asyncBeforeDrag) {
                this.waitingAsyncBeforeDrag = true;
                this.props.asyncBeforeDrag(this.props.DragItem).then((result) => {
                    this.waitingAsyncBeforeDrag = false;                    
                })
            }

            if (this.props.gridChild) {
                if (this.props.onGridChildStartDrag)
                    this.props.onGridChildStartDrag(this.props.Id, this.props.MetaItem, {x : e.clientX, y : e.clientY});
                const isAltKey = e.altKey;
                setTimeout(() => {
                    if (this.MouseDown) 
                        this.StartDrag(isAltKey, this.DragStart_x, this.DragStart_y);
                }, 300);                
                
            }                
        }
        StartDrag(altKey, x, y) {
            if (!this.state.Dragging && this.MouseDown) {
                this.DragStarted = true;
                this.Copy = altKey;
                this.setState((ps) => {
                    return {
                        Dragging : true
                    }
                })                            
                DragDropManager.StartDragItem(
                    {
                        Item : this.props.DragItem || {Type : 'Unknown'}, 
                        sx : this.DragStart_x, 
                        sy : this.DragStart_y, 
                        x : x, 
                        y : y, 
                        Copy : altKey, 
                        GetComponentManager : this.props.GetComponentManager
                    }
                )
                DragDropManager.MoveDragItem(x, y);                                                        
                this.props.OnDragItemStart && this.props.OnDragItemStart();
            }  
        }
        onMouseMove(e) {      
            if (this.props.RootItem)        
                return;
            if (this.waitingAsyncBeforeDrag)     
                return;
            let x = e.clientX;
            let y = e.clientY;            
            const that = this;            
            // console.log(`DragSource.Move : [${e.clientX}, ${e.clientY}] => [${x}, ${y}]`);
            if (!this.DragStarted && this.DragStart_x !== undefined && this.DragStart_y !== undefined) {
                if (Math.abs(this.DragStart_x - e.clientX) > 4 ||  Math.abs(this.DragStart_y - e.clientY) > 4) {                    
                    const isAltKey = e.altKey;                                     
                    setTimeout(() => {  
                        this.StartDrag(isAltKey, x, y); 
                    }, 10);
                }                            
            }
            else {
                DragDropManager.MoveDragItem(x, y);                
            }
                
        }
        onMouseUp(e) {         
            // if (this.props.document)
            //     return;
            if (e && e.button !== 0)    
                return;
            e && e.stopPropagation();
            this.MouseDown = false;

            document.removeEventListener('mouseup', this.onMouseUp);
            document.removeEventListener('mousemove', this.onMouseMove);            
            const useDocument = this.GetDocument();
            if (useDocument !== document) {
                useDocument.removeEventListener('mouseup', this.onMouseUp);
                useDocument.removeEventListener('mousemove', this.onMouseMove);            
            }            

            if (this.Mounted) {
                if (this.state.Dragging) {
                    this.props.OnDragEnd && this.props.OnDragEnd();
                    DragDropManager.EndDragItem(e.clientX, e.clientY);                    
                    
                    const x_up = e.clientX;
                    const y_up = e.clientY;
                    setTimeout(() => {
                        if (Math.abs(this.DragStart_x - x_up) < 5 &&  Math.abs(this.DragStart_y - y_up) < 5) {                    
                            if (this.props.OnItemClick)
                                this.props.OnItemClick(this.props.Id, e.shiftKey);
                        }    
                    }, 200);
                    
                    this.Mounted && setTimeout(() => {
                        this.Mounted && this.setState({Dragging : false});
                    }, 300);   
   
                }   
                else {
                    if (this.props.OnItemClick) {
                        // if (this.Ref_DragItem.current && this.Ref_DragItem.current.Manager && this.Ref_DragItem.current.Manager.Editing) {
                        //     // this.props.OnItemClick(this.Ref_DragItem.current.Manager.RootId, e.shiftKey);
                        // }
                        // else
                            this.props.OnItemClick(this.props.Id, e.shiftKey);
                    }
                }             
            }            
            else {
                if (this.state.Dragging)
                    DragDropManager.EndDragItem(e.clientX, e.clientY);
                this.state.Dragging = false;
            }
        }

        render() {
            const {forwardedRef, NotDragSource, ...props} = this.props;
            const isDragging = this.state.Dragging || this.props.Dragging;
            // console.log(`HOC [${this.props.Id}] Dragging ? ${isDragging}`);
            return (
                <Wrapped 
                    onMouseDown={this.onMouseDown} 
                    onMouseUp={this.onMouseUp}
                    {...props} 
                    Dragging={isDragging} 
                    IsDragSource={!NotDragSource}
                    Copy={this.Copy || this.props.Copy} 
                    NoMouseEvents={this.state.Dragging || this.props.Dragging || this.props.NoMouseEvents}
                />
            )
        }
    }
};

export default DragSource;
