import React from 'react';
import {
    ReactBaseComponent,
    Events,
    AppState,
    SC,
    Utils,
    UIUtils
} from '../../importer';
import styled, { css } from 'styled-components';

const Panel = styled.div`
    position : relative;
    width : 100%';
`;

const Notification = styled.div`
    position : absolute;
    top : 0;
    left : 0;
    right : 0;
    height : 47px;
    box-sizing : border-box;
    z-index : ${props => props.theme.zindex_max + 1};
    background-color : ${props => props.theme.back};
    border : 1px solid ${props => props.theme.color_notification[props.type]};
    display : flex;
`;

const IconBox = styled.div`
    background-color : ${props => props.theme.color_notification[props.type]};
    border-top-left-radius : 4px;
    width : 30px;
    display : flex;
    align-items : center;
    justify-content : center;
`;

const Panel_Alert = styled.div`
    max-width : ${props => props.compact ? 'calc(100% - 16px)' : '582px;'};
    min-width : ${props => props.compact ? 'unset' : '450px'};
    min-height : 280px;
    max-height : 100%;
    background-color : ${props => props.theme.back};
    border : ${props => props.theme.border_ondark};
    border-radius : 4px;
    box-shadow : 0px 2px 30px 1px rgb(32,33,36);
    display : flex;
    flex-direction : column;
    align-items : stretch;
`
const Alert_Header = styled.div`
    border-top-left-radius : 4px;
    border-bottom : 1px solid ${props => props.theme.color_notification[props.type]};
    min-height : 40px;
    display : flex;
    justify-content : space-between;
    text-transform : capitalize;
    font-size : 14px;
`

const Alert_MessageHeader = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    font-size : 18px;
    font-weight : 500;
`
const Alert_Message = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    flex : 1;
    font-size : 16px;
    padding : 16px 40px 16px 40px;
    text-align : center;
`;

const Query_Button = styled.button`
height : 32px;
box-sizing: border-box;
padding-left: 15px;
padding-right: 15px;
min-width : 110px;
margin-left : 15px;
margin-top : 15px;
font-size: 14px;
font-weight: 700;
border : 1px solid ${props => props.negative? '#ff5252' : (props.positive ? props.theme.color_brand : '#98999c')};
border-radius : 4px;
background-color : ${props => props.negative? '#ff5252' : (props.positive ? props.theme.color_brand : '#98999c')};
color: #27282B;
display : flex;
justify-content : center;
align-items : center;
cursor : pointer;
&:hover {
    background-color : ${props => props.negative? '#df6262' : (props.positive ? "#79ADB3" : '#C2BFBF')};
}
`;

class NotificiationBar extends ReactBaseComponent {
    constructor(props) {
        super(props);
        super.ListenEvents(
            Events.GLOBAL.NOTIFICATION.NOTIFY,
            Events.GLOBAL.NOTIFICATION.ALERT,
            Events.GLOBAL.NOTIFICATION.YESNO,
            Events.GLOBAL.NOTIFICATION.YESNOCANCEL,
            Events.GLOBAL.NOTIFICATION.DELETE
        );
        this.Close = this.Close.bind(this);
        this.Notifications = [];
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }
    OnEvent(Event, Params) {
        const [MessageType, Options, ...Rest] = Params;
        const message = {
            Id: Utils.Id(),
            Type: MessageType,
            Options: Options || {}
        };

        if (Event === Events.GLOBAL.NOTIFICATION.NOTIFY) {
            if (message.Options.autoClose) {
                setTimeout(() => {
                    if (this.Notifications.length > 0) {
                        this.Notifications.splice(this.Notifications.length - 1, 1);
                        this.RCUpdate();
                    }
                }, Math.max(message.Options.autoClose, 2500));
            }
            if (message.Options.closeOthers)
                this.Notifications = [];
            this.Notifications.push(message);
        }
        else if (Event === Events.GLOBAL.NOTIFICATION.ALERT || Event === Events.GLOBAL.NOTIFICATION.YESNO || Event === Events.GLOBAL.NOTIFICATION.YESNOCANCEL || Event === Events.GLOBAL.NOTIFICATION.DELETE) {
            let Buttons = null;
            if (Event === Events.GLOBAL.NOTIFICATION.YESNO) {
                const [cb] = Rest;
                Buttons = (
                    <SC.FRow justifyCenter>
                        <Query_Button positive={!message.Options.DefaultIsNo} onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.YES) }}>YES</Query_Button>
                        <Query_Button positive={message.Options.DefaultIsNo} onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.NO) }}>NO</Query_Button>
                    </SC.FRow>
                )
            }
            else if (Event === Events.GLOBAL.NOTIFICATION.YESNOCANCEL) {
                const [cb] = Rest;
                Buttons = (
                    <div style={{display : 'grid', gridGap : '16px', gridTemplateColumns : 'repeat(auto-fill, minmax(80px, 1fr))'}}>
                        <Query_Button style={{margin : 0}} positive={!message.Options.DefaultIsNo} onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.YES) }}>YES</Query_Button>
                        <Query_Button style={{margin : 0}} positive={message.Options.DefaultIsNo} onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.NO) }}>NO</Query_Button>
                        <Query_Button style={{margin : 0}} onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) }}>CANCEL</Query_Button>
                    </div>
                )
            }
            else if (Event === Events.GLOBAL.NOTIFICATION.DELETE) {
                const [cb] = Rest;
                if(!message.Options.contentIcon)
                {
                    message.Options.contentIcon = SC.Icons.Icon_Delete_Warning;
                }
                Buttons = (
                    <SC.FRow justifyCenter>
                        <Query_Button onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) }}>CANCEL</Query_Button>
                        <Query_Button negative onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.DELETE) }}>DELETE</Query_Button>
                    </SC.FRow>
                )
            }
            else {
                const [cb] = Rest;
                Buttons = (
                    <SC.FRow justifyCenter>
                        <Query_Button positive onClick={() => { this.Close(); cb && cb(Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) }}>Okay</Query_Button>
                    </SC.FRow>
                )
            }
            const IconType = SC.Icons.ErrorIcons[message.Type];
            const ContentIcon = message.Options.contentIcon;

            this.Proxy = AppState.OverlayManager.Show(
                <SC.Div_Full_Center>
                    <Panel_Alert type={message.Type} compact={this.props.compact}>
                        <Alert_Header type={message.Type}>
                            <IconBox type={message.Type}>
                                <IconType />
                            </IconBox>
                            <SC.FRow f1 pl={16} jsb alc>
                                <SC.Div_Flex_Cell>
                                    {message.Options.title ? message.Options.title : message.Type}
                                </SC.Div_Flex_Cell>
                                {
                                    !this.props.compact && 
                                    <SC.Icons.Icon_Button hasFill>
                                        <SC.Div_Flex_Cell cursorPointer pr={8} pl={8} onClick={this.Close}>
                                            <SC.Icons.Icon_Close />
                                        </SC.Div_Flex_Cell>
                                    </SC.Icons.Icon_Button>
                                }                                
                            </SC.FRow>
                        </Alert_Header>
                        <Alert_Message>
                            {
                                ContentIcon &&
                                <SC.Div_Flex_Cell pt={10} pb={10}>
                                    <ContentIcon />
                                </SC.Div_Flex_Cell>
                            }
                            {
                                message.Options.messageHeader &&
                                <SC.Div_Flex_Cell pt={10} pb={10}>
                                    <Alert_MessageHeader>
                                        {message.Options.messageHeader}
                                    </Alert_MessageHeader>
                                </SC.Div_Flex_Cell>
                            }
                            <SC.Div_Flex_Cell style={{paddingTop  :'10px', paddingBottom : '24px', lineHeight : '24px'}} >
                                {message.Options.message}
                            </SC.Div_Flex_Cell>
                            {Buttons}
                        </Alert_Message>
                    </Panel_Alert>               
                </SC.Div_Full_Center>
            );
        }
        this.RCUpdate();
    }
    Close(e) {
        e && e.stopPropagation();
        if (this.Proxy) {
            AppState.OverlayManager.Close(this.Proxy);
            delete this.Proxy;
        }
        else if (this.Notifications.length > 0) {
            this.Notifications.splice(this.Notifications.length - 1, 1);            
        }
        this.RCUpdate();
    }
    renderCustom() {
        let item = null;
        let IconType = null;
        if (this.Notifications.length > 0) {
            item = this.Notifications[this.Notifications.length - 1];
            IconType = SC.Icons.ErrorIcons[item.Type];
            if (!IconType)
                IconType = SC.Icons.ErrorIcons.error;
        }
        if (item) {
            return (
                <Panel>
                    <Notification type={item.Type}>
                        <IconBox type={item.Type}>
                            <IconType />
                        </IconBox>
                        <SC.FRow f1 alc jsb pl={16}>
                            <SC.FRow>
                                {item.Options.message}
                            </SC.FRow>
                            {
                                !this.props.compact && 
                                <SC.Icons.Icon_Button hasFill>
                                    <SC.Div_Flex_Cell cursorPointer pr={8} pl={8} onClick={this.Close}>
                                        <SC.Icons.Icon_Close />
                                    </SC.Div_Flex_Cell>
                                </SC.Icons.Icon_Button>
                            }                            
                        </SC.FRow>
                    </Notification>
                </Panel>
            )
        }
        return null;
    }
}

export default NotificiationBar;
