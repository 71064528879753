import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    MetaData,
    Globals
} from '../../../../../../../importer';
import { StyleGroupTokenItem, SmallButtonGroup, StyleToken_Color, INSPECTOR_ITEM_MARGIN } from '../common';
import { FontFamilyPreview } from '../../../../left/designsystem/typography/fonts';

export default class TextStyles extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SetFontStyle = this.SetFontStyle.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'Id', 'ManagerId', 'GlobalStateId', 'GlobalState', 'color', 'gradient', 'textPattern', ...TextItemStyleNames))
            return true;
            
        return super.ShouldUpdate(nextProps, nextState);
    }    
    SetFontStyle(prop, value) {
        if (Utils.JustGet(this.props[prop], null, 'Value') === value) {            
            this.props.onSetPropertyValues([{
                PropertyName : prop,
                Delete : true
            }]);
        }
        else {
            this.props.onSetPropertyValues([{
                PropertyName : prop,
                Value : value
            }]);
        }        
    }
    renderCustom() {        
        let textAlign = Utils.JustGet(this.props.textAlign, null, 'Value');
        let fontStyle = Utils.JustGet(this.props.fontStyle, null, 'Value');
        let textDecoration = Utils.JustGet(this.props.textDecoration, null, 'Value');
        let textTransform = Utils.JustGet(this.props.textTransform, null, 'Value');        

        return (
            <TextStyleEditors 
                RenderId={this.props.RenderId}
                textPatternId={this.props.textPattern.TokenId}
                colorId={this.props.color.TokenId}
                textAlign={textAlign}
                fontStyle={fontStyle}
                isUnderline={textDecoration === 'underline'}
                isOverline={textDecoration === 'line-through'}
                isLowerCase={textTransform === 'lowercase'}
                isUpperCase={textTransform === 'uppercase'}
                onSelectTextPattern={this.props.onSelectTextPattern}
                onSelectColor={this.props.onSelectColor}
                onSetFontStyle={this.SetFontStyle}
                onBindToModel={this.props.onBindToModel}
                themeId={this.props.themeId}
                GlobalStateId={this.props.GlobalStateId}
            />
        )       
    }
}

export const TextItemStyleNames = ['textAlign', 'fontStyle', 'textDecoration', 'textTransform'];

export class TextStyleEditors extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
        this.SelectColorModel = this.SelectColorModel.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'textAlign', 'fontStyle', 'isUnderline', 'isOverline', 'colorId', 'textPatternId', 'StateArray', 'themeId', 'GlobalStateId'))
            return true;
        return false;
    }
    SetFontStyle(prop, value) {
        this.props.onSetFontStyle(prop, value);
    }
    SelectColorModel() {
        Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_MODEL, {
            title : 'COLOR',
            dataType : MetaData.DataTypes.color,
            onSelect : (modelId) => {
                this.props.onBindToModel({
                    PropertyName : 'color',
                    ModelId : modelId
                });
            }
        });
    }
    render() { 
        const {textAlign, fontStyle, isUnderline, isOverline, isLowerCase, isUpperCase, colorId, textPatternId} = this.props;

        let hasText = false;
        let style_pattern = {};
        let font;
        const token_text = {
            name : 'TYPOGRAPHY'
        };
        if (textPatternId) {            
            const tokenModel = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(textPatternId);
            if (tokenModel) {
                hasText = true;
                style_pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(tokenModel, null, null, this.props.StateArray) || {};
                token_text.name = tokenModel.name;
                const fontid = Globals.ProjectManager.Tokens.ValueOf({model : tokenModel, name : 'fontId', statearray : this.props.StateArray});    
                font = Globals.ProjectManager.Tokens.FontValue(fontid || 'DefaultFont', this.props.StateArray);
            }            
        }

        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <StyleGroupTokenItem onClick={this.props.onSelectTextPattern} 
                    style={{
                        marginBottom : INSPECTOR_ITEM_MARGIN, paddingLeft : '8px', paddingRight : '8px', height : '30px',
                        ...SC.Styles.Flex.RowAlcJsb
                    }}>
                        {hasText && <FontFamilyPreview font={font} style={{...style_pattern, fontSize : '14px', lineHeight : 'unset'}} text={token_text.name} /> }
                        {!hasText && token_text.name}
                        {hasText && <div style={{...SC.Styles.FontStyles.Monospace, fontSize : '14px'}}>{style_pattern.fontSize}</div>}                    
                </StyleGroupTokenItem>
                <SC.FRow jsb alc style={{flexWrap : 'wrap'}}>
                    <SC.FRow>
                        <SmallButtonGroup hasBorders noSvg first grouped onClick={this.SetFontStyle.bind(this, 'textAlign', 'left')} selected={textAlign === 'left'} title='Left align' style={{width : '27px', marginRight : '1px'}}>
                            <TextAlignLines selected={textAlign === 'left'} align='flex-start' />
                        </SmallButtonGroup>
                        <SmallButtonGroup hasBorders noSvg middle onClick={this.SetFontStyle.bind(this, 'textAlign', 'center')} selected={textAlign === 'center'} title='Left align' style={{width : '27px', marginRight : '1px'}}>
                            <TextAlignLines selected={textAlign === 'center'} align='center' />
                        </SmallButtonGroup>
                        <SmallButtonGroup hasBorders noSvg middle onClick={this.SetFontStyle.bind(this, 'textAlign', 'right')} selected={textAlign === 'right'} title='Left align' style={{width : '27px', marginRight : '1px'}}>
                            <TextAlignLines selected={textAlign === 'right'} align='flex-end' />
                        </SmallButtonGroup>
                        <SmallButtonGroup hasBorders noSvg last grouped onClick={this.SetFontStyle.bind(this, 'textAlign', 'justify')} selected={textAlign === 'justify'} title='Left align' style={{width : '27px', marginRight : '1px'}}>
                            <TextAlignLines selected={textAlign === 'justify'} align='stretch' />
                        </SmallButtonGroup>                        
                    </SC.FRow>
                    <SC.FRow>
                        <SmallButtonGroup noSvg hasBorders first onClick={this.SetFontStyle.bind(this, 'fontStyle', 'italic')} selected={fontStyle === 'italic'} title='Top' style={{width : '27px', marginRight : '1px'}}>
                            <span style={{fontStyle : 'italic', fontFamily : 'serif', fontWeight : 'bold', color : fontStyle === 'italic' ? SC.CurrentTheme.theme.color_brand_text : 'unset'}}>I</span>
                        </SmallButtonGroup>                        
                    </SC.FRow>
                    <SC.FRow>
                        <SmallButtonGroup hasBorders first grouped selected={isUnderline} onClick={this.SetFontStyle.bind(this, 'textDecoration', 'underline')} selected={isUnderline} title='Underline' style={{width : '27px', marginRight : '1px'}}>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path fill={ isUnderline ? SC.CurrentTheme.theme.color_brand : null} d="M12 17a6 6 0 0 0 6-6V3h-2.5v8a3.5 3.5 0 0 1-7 0V3H6v8a6 6 0 0 0 6 6zm-7 2v2h14v-2H5z"/>
                        </SmallButtonGroup>
                        <SmallButtonGroup hasBorders last grouped selected={isOverline} onClick={this.SetFontStyle.bind(this, 'textDecoration', 'line-through')} selected={isOverline} title='Overline' style={{width : '27px'}}>
                            <path fill="none" d="M0 0h24v24H0z"/>
                            <path fill={ isOverline ? SC.CurrentTheme.theme.color_brand : null} d="M10 19h4v-3h-4v3zM5 4v3h5v3h4V7h5V4H5zM3 14h18v-2H3v2z"/>
                        </SmallButtonGroup>
                    </SC.FRow>
                    <SC.FRow>
                        <SmallButtonGroup noSvg hasBorders first grouped selected={isLowerCase} onClick={this.SetFontStyle.bind(this, 'textTransform', 'lowercase')} selected={isLowerCase} title='Lowercase' style={{width : '27px', marginRight : '1px'}}>
                            <span style={{color : isLowerCase ? SC.CurrentTheme.theme.color_brand_text : 'unset'}}>aa</span>
                        </SmallButtonGroup>
                        <SmallButtonGroup noSvg hasBorders last grouped selected={isUpperCase} onClick={this.SetFontStyle.bind(this, 'textTransform', 'uppercase')} selected={isUpperCase} title='Uppercase' style={{width : '27px'}}>
                            <span style={{color : isUpperCase ? SC.CurrentTheme.theme.color_brand_text : 'unset'}}>AA</span>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>    
                <StyleToken_Color 
                    tokenId={colorId} 
                    onSelect={this.props.onSelectColor} 
                    label='Text Color'
                    forText={{}}
                    onSelectModel={this.SelectColorModel}
                />                 
            </SC.FCol>
            
        );
    }
}

export const TextAlignLines = ({selected, align, style, boxed, vertical, style_center}) => {
    let style_lines, style_box;
    if (vertical) {
        style_lines = {minHeight : '14px', width : '1px'};
        style_box = {minHeight : '8px', width : boxed ? '5px' : '1px'};
    }
    else {
        style_lines = {minWidth : '14px', height : '1px'};
        style_box = {minWidth : '8px', height : boxed ? '5px' : '1px'};
    }
    const lines = (
        <React.Fragment>
            <div style={{...style_lines, borderRadius : '2px', backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : SC.CurrentTheme.theme.icon}} />
            <div style={{...style_box, borderRadius : boxed ? '1px' : '2px', backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : SC.CurrentTheme.theme.icon, ...style_center}} />
            <div style={{...style_lines, borderRadius : '2px', backgroundColor : selected ? SC.CurrentTheme.theme.color_brand : SC.CurrentTheme.theme.icon}} />
        </React.Fragment>
    )
    if (vertical) {
        return (
            <SC.FRow  jsb style={{width : '14px', height : '11px', alignItems : align, ...style}}>
                {lines}
            </SC.FRow>
        )
    }
    return (
        <SC.FCol  jsb style={{width : '14px', height : '11px', alignItems : align, ...style}}>
            {lines}
        </SC.FCol>
    )
}