import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../importer';
import { motion } from "framer-motion";
import { TokenPanelHeader, TokenGalleryView } from '../../../left/designsystem/common';

import SelectComponent from './component';
import SelectColor from './color';
import ColorEditor from './color/editor';
import SelectTextPattern from './textPattern';
import EditTextStyle from './textPattern/editor';
import SelectTextContent from './textContent';
import EditTextContent from './textContent/editor';
import SelectShadow from './shadow';
import ShadowEditor from './shadow/editor'
import SelectImage from './image';
import SelectIcon from './icon';
import SelectBorderStyle from './borderStyle';
import SelectBorderRadius from './borderRadius';
import BorderStyleEditor from '../../../left/designsystem/borders/border';
import BorderRadiusEditor from '../../../left/designsystem/borders/radius';
import SelectSpacing from './spacing';
import SelectTransform from './transform';
import SelectTransition from './motion/transition';
import SelectEaseCurve from './motion/easecurve';
import SelectDuration from './motion/duration';
import SelectFilter from './filter';
import SelectDataModel from './data'
import MockupDataModels from '../../../left/toolbar/mockupdata';
import MockupFieldValueList from '../../../left/toolbar/mockupdata/fieldValueSelector';
import GridDesigner from './grid';
import UnsplashImageGallery from '../../../../selectors/image/unsplash';
import { SpacePatternList } from '../styleitems/paddingAndMargin';
import TokenGroupMembers from './groupMembers';
import SelectFigmaAutoLayout from './figma/autoLayouts';
import FigmaLayoutTokenEditor from '../../../../../../apps/figmaplugin/designsystem/autolayouts/item';
import BooleanEditor from '../../../left/designsystem/booleans/item';
import SelectBooleanToken from './booleans';
import FontSelectPanel from '../../../left/designsystem/typography/fonts/selector';

export default class SelectToken extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        if (this.props.onRegisterClose) {
            this.props.onRegisterClose(() => {
                this.props.onClose();
            })
        }
    }
    renderCustom() {
        const {type, ...rest} = this.props;

        let content;
        if (type === AppState.ItemTypes.BOARD.SEMANTICTOKEN) {
            if (rest.justEditor) {
                content = (
                    <ColorEditor {...rest} />
                )
            }
            else {
                content = (
                    <SelectColor 
                        {...rest}
                    />
                )
            }            
        }
        else if (type === AppState.ItemTypes.BOARD.COLOR) {
            if (rest.justEditor) {
                content = (
                    <ColorEditor {...rest} />
                )
            }
            else {
                content = (
                    <SelectColor 
                        {...rest}
                    />
                )
            }            
        }
        else if (type === AppState.ItemTypes.BOARD.PATTERN_TEXT) {
            if (rest.justEditor) {
                content = (
                    <EditTextStyle {...rest} />
                )
            }
            else {
                content = (
                    <SelectTextPattern 
                        {...rest}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.FONT) {
            content = (
                <FontSelectPanel {...rest} />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.CONTENT_TEXT) {
            if (rest.justEditor) {
                content = (
                    <EditTextContent {...rest} />
                )
            }
            else {
                content = (
                    <SelectTextContent 
                        {...rest}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.SHADOW) {
            if (rest.justEditor) {
                content = (
                    <ShadowEditor {...rest} />
                )
            }
            else {
                content = (
                    <SelectShadow 
                        {...rest}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.IMAGE) {
            content = (
                <SelectImage
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.ICON) {
            content = (
                <SelectIcon
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.BORDER_STYLE) {
            if (rest.justEditor) {
                content = (
                    <RenderEditorPanel title={this.props.title} onClose={this.props.onClose} {...rest}>
                        <BorderStyleEditor 
                            {...rest}
                            id={this.props.tokenId}                        
                        />
                    </RenderEditorPanel>                    
                )
            }
            else {
                content = (
                    <SelectBorderStyle 
                        {...rest}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.BORDER_RADIUS) {
            if (rest.justEditor) {
                content = (
                    <RenderEditorPanel title={this.props.title} onClose={this.props.onClose} {...rest}>
                        <BorderRadiusEditor 
                            {...rest}
                            id={this.props.tokenId}                        
                        />
                    </RenderEditorPanel>
                )
            }
            else {
                content = (
                    <SelectBorderRadius 
                        {...rest}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.COMPONENT) {
            content = (
                <SelectComponent
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.GRID) {
            content = (
                <GridDesigner
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.TRANSFORM) {
            content = (
                <SelectTransform
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.TRANSITION) {
            content = (
                <SelectTransition
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.EASECURVE) {
            content = (
                <SelectEaseCurve
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.DURATION) {
            content = (
                <SelectDuration
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.FILTER) {
            content = (
                <SelectFilter
                    {...rest}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.SPACING) {
            content = (
                <SpacePatternList
                    {...rest}
                    onTokenSelected={(id) => {
                        this.props.onSelect(id);
                        this.props.onClose && this.props.onClose();
                    }}
                />
            )
        }
        else if (type === AppState.ItemTypes.BOARD.FIGMA_AUTOLAYOUT) {
            if (rest.justEditor) {
                content = (
                    <RenderEditorPanel title='EDIT AUTO LAYOUT TOKEN' onClose={this.props.onClose} {...rest}>
                        <FigmaLayoutTokenEditor {...rest} />
                    </RenderEditorPanel>
                )
            }
            else {
                content = (
                    <SelectFigmaAutoLayout
                        {...rest}
                        onTokenSelected={(id) => {
                            this.props.onSelect(id);
                            this.props.onClose && this.props.onClose();
                        }}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.BOOLEAN) {
            if (rest.justEditor) {
                content = (
                    <RenderEditorPanel title='EDIT BOOLEAN TOKEN' onClose={this.props.onClose} {...rest}>
                        <BooleanEditor {...rest} />
                    </RenderEditorPanel>
                )
            }
            else {
                content = (
                    <SelectBooleanToken
                        {...rest}
                        onTokenSelected={(id) => {
                            this.props.onSelect(id);
                            this.props.onClose && this.props.onClose();
                        }}
                    />
                )
            }
        }
        else if (type === AppState.ItemTypes.BOARD.DATA) {
            content = (
                <MockupDataModels 
                    {...rest}
                    forSelect
                />
            )
        }
        else if (type === 'Unsplash') {
            content = (
                <TokenGalleryView 
                    title='UNSPLASH GALLERY' 
                    onClose={this.props.onClose}
                    onRightPanel={this.props.onRightPanel}
                >
                    <UnsplashImageGallery 
                        noInfo
                        onSelect={this.props.onSelect}
                        onPreviewChange={this.props.onPreview}
                    />
                </TokenGalleryView>
            )
        }
        else if (type === 'MockupField') {
            content = (
                <MockupFieldValueList 
                    {...rest}
                />
            )
        }
        else if (type === 'GroupMembers') {
            content = (
                <TokenGroupMembers {...rest} />
            )
        }
        return (
            <SC.FCol fw fh>
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                    {content}                    
                </SC.FCol>
            </SC.FCol>
        )
    }
}

const RenderEditorPanel = ({title, onClose, ...props}) => {
    return (
        <motion.div 
            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
            initial={{opacity : 0.7, x : 24}}
            animate={{opacity : 1, x : 0}}
            exit={{opacity : 0, x : 24}}
            transition={{duration : 0.2}}
        >
            <TokenPanelHeader 
                title={title || 'RADIUS'} 
                onClose={onClose} 
                style={{padding : '2px'}}
            />                           
            <SC.CustomScrollbars>
                {props.children}
            </SC.CustomScrollbars>
        </motion.div>
    )
}