import {Utils} from '../importer';
import BaseItem from './BaseItem';
import {MetaData} from '../importer';
import SubComponentItem from './SubComponentItem';
import Slot from './Slot';
import Container from './Container';
// import Repeater from './Repeater';
import Text from './Text';
// import Button from './Button';
// import Input from './Input';
import Image from './Image';
import SvgIcon from './SvgIcon';
// import Layout from './Layout';
import Widget from './Widgets';
// import TokenWidget from './Tokens';
import GenericComponent from './Generics';

const ItemType = (MetaItem) => {
    const Type = MetaItem.Type;
    if (MetaItem.Generic) {
        const IsSubType = Utils.JustGet(MetaItem.Generic, null, 'SubType');
        const RootType = Utils.JustGet(MetaItem.Generic, null, 'Type');
        if (!IsSubType || RootType === MetaItem.Type)
            return GenericComponent;
    }
    
    if (Type === MetaData.Components.Div.Type)
        return Container;
    // else if (Type === MetaData.Components.Layout.Type)
    //     return Layout;
    else if (Type === MetaData.Components.Content.Type)
        return Slot;
    // else if (Type === MetaData.Components.Repeater.Type)
    //     return Repeater;
    else if (Type === MetaData.Components.Text.Type)
        return Text;
    else if (Type === MetaData.Components.Image.Type)
        return Image;
    else if (Type === MetaData.Components.SvgIcon.Type)
        return SvgIcon;
    // else if (Type === MetaData.Components.Button.Type)
    //     return Button;
    // else if (Type === MetaData.Components.Input.Type)
    //     return Input;
    // // else if (Type === MetaData.Components.Svg.Type)
    // //     return SvgItem;
    else if (Type === MetaData.Components.Widget.Type)
        return Widget;
    else if (Type === MetaData.Components.Generic.Type)
        return GenericComponent;
    // else if (Type === MetaData.Components.Token.Type)
    //     return TokenWidget;
    else if (Type === MetaData.Components.Component.Type)
        return SubComponentItem; 
     
    return BaseItem;
};

export default ItemType;