import React from 'react';
import ReactGA from 'react-ga';
import '../../App.css';
import { Route, Redirect, IndexRoute, Link, HashRouter, Switch } from 'react-router-dom';
import ShortId from 'shortid';
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import ChromaJs from 'chroma-js';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../importer';
  
import SignIn from './auth';  
import ProjectList from './projectlist';
import FigmaProject from './project';
import packageJSON from '../../../../package.json';

import FimgaOptions, {FigmaPluginOptions} from './options';
import FigmaPluginApi from './api';
import ToolabsLogo from '../../views/logo/brand_logo';
import {FigmaMenu} from './common';
import { GuideOverlay } from '../websitedemo/figma';
import { GetDataApi } from '../../appstate/AppState';
import { LeftScrollPanel } from '../../views/project/panels/left/common';
import TeamPanel from '../../views/project/panels/right/team';

Utils.Id = () => {
    return ShortId();
}
Utils.platform = Utils.Get(window.navigator, '', 'platform');

window.Utils = Utils;

let ErrorBoundary = null;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
} else {
    Bugsnag.start({
        apiKey: '770f428c0bea121e1dff1c8b27bd2f65',
        plugins: [new BugsnagPluginReact()],
        appVersion: packageJSON.config.studio.version,
        onError: function (event) {
            if (AppState && AppState.User) {
                event.setUser(AppState.User.uid, AppState.User.email, AppState.User.displayName)
            }
            if (Globals && Globals.ProjectManager) {
                event.addMetadata('project', {
                id: Globals.ProjectManager.Id,
              });
            }
          }
      });
    ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);
    // Sentry.configureScope(function(scope) {
    //     scope.setTag("studio_version", "30.05.2020");
    // });
}

export default class App extends ReactBaseComponent {
    constructor(props) {
        super(props);
        AppLayout.ThemeId = Utils.Id();
        AppLayout.AppSource.Figma = {

        };

        this.PluginApi = new FigmaPluginApi();
        this.GetPluginApi = this.GetPluginApi.bind(this);

        this.Ref_TourGuide = React.createRef();
        
        super.ListenEvents(
            Events.SESSION,
            Events.GLOBAL.THEME.CHANGED
        );
        AppLayout.Theme.Current = 'Light';

        this.state.width = 300;
        this.state.height = 800;

        this.ToggleLightDarkMode = this.ToggleLightDarkMode.bind(this);
        this.onResizePlugin = this.onResizePlugin.bind(this);
        this.ResizePlugin_Throttled = Utils.Throttle(this.ResizePlugin, 200);

        this.FimaMenuToggled = this.FimaMenuToggled.bind(this);        

        this.DeleteProject = this.DeleteProject.bind(this);        
        this.DuplicateProject = this.DuplicateProject.bind(this);        

        this.HandleFigmaMessage = this.HandleFigmaMessage.bind(this);            
        window.addEventListener('message', this.HandleFigmaMessage);

        this.GetOptions = this.GetOptions.bind(this);
        this.SetOption = this.SetOption.bind(this);

        this.state.viewMode = 'tokens';
        this.state.loading = true;

        window.Globals = Globals;
        // this.state.showSettings = true;
        // this.WillLoadProjectId = 'EZ7ZWOOUP';
    }
    GetPluginApi() {
        return this.PluginApi;
    }
    GetOptions() {
        if (!this.Options) {
            this.Options = {
                SetOption : this.SetOption
            };        
        }

        return this.Options;
    }
    SetOption(key, value) {        
        Utils.Set(this.GetOptions(), value, key);
        const {SetOption, ...options} = this.GetOptions();
        this.PluginApi.SetOption({key : 'UserSettings', value : options});
        this.RCUpdate();
    }
    ToggleLightDarkMode() {
        if (SC.CurrentTheme.theme.isLight) 
            AppLayout.Theme.Current = AppLayout.Theme.Dark;
        else
            AppLayout.Theme.Current = AppLayout.Theme.Light; 
        
        Events.BCE(Events.GLOBAL.THEME.CHANGED);
    }    
    componentDidMount() {
        super.componentDidMount();
        if (AppState.Loaded)
            this.Load();    

        // this.Ref_TourGuide.current.GoTo({left : 40, top : 100, width : 200, height : 300});
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        Events.DropListenAll(this);
    }
    OnEvent(Event, Params) {
        if (Event === Events.SESSION) {
            this.Loaded = true;
            [this.LoggedIn] = Params;

            this.PluginApi.PostMessage({type : 'Load'});

            GetDataApi().api.listen_figma_pluginversion_alternate && GetDataApi().api.listen_figma_pluginversion_alternate((version) => {
                AppState.Versions.Figma.DSM = version;
                if (AppState.Versions.Figma.DSM !== AppState.Versions.Figma.Plugin) {
                    this.RCUpdate();
                }
            });

            setTimeout(() => {
                if (this.state.loading) {
                    this.setState({loading : false});
                }
            }, 1000);

            if (this.WillLoadProjectId && this.LoggedIn) {
                this.LoadProject(this.WillLoadProjectId);
                delete this.WillLoadProjectId;
            }
            else
                this.RCUpdate();

        }
        else if (Event === Events.GLOBAL.THEME.CHANGED) {        
            AppLayout.ThemeId = Utils.Id();            
            this.RCUpdate();
        }
    }   
    LoadProject(BoardId) {                
        this.setState({
            projectId : BoardId,
            loading : false
        })        
    }
    HandleFigmaMessage(e) {
        if (e && e.data && e.data.figmaPlugin) {            
            // console.log(e.data.figmaPlugin);
            const data = e.data.figmaPlugin;
            if (data.type === 'Load_Response') {
                if (data.data) {
                    const {options, projects} = data.data;
                    const {LastProjectId, GlobalState, ShowThemeSelector, ViewMode, TokenMode, width, height, UserSettings, figmaName} = options;
                    this.Options = UserSettings || {};
                    this.FigmaFileName = figmaName;
                    this.Options.SetOption = this.SetOption;
                    this.PluginApi.FigmaProjectsIds = projects || {};

                    Utils.ForEach(FigmaPluginOptions, (option, prop) => {
                        FigmaPluginOptions[prop].value = Utils.JustGet(this.Options, option.value, option.name);
                    });                    
                    
                    this.state.viewMode = ViewMode || 'tokens';
                    this.state.showThemeSelector = Utils.IsTrue(ShowThemeSelector);
                    this.setState({width : width || 300, height : height || 800});
                    if (LastProjectId && (!this.state.projectId || this.state.projectId !== LastProjectId)) {
                        this.WillSetToGlobalState = GlobalState;
                        if (this.Loaded && this.LoggedIn)
                            this.LoadProject(LastProjectId);
                        else 
                            this.WillLoadProjectId = LastProjectId;
                    }
                }
                else {
                    this.setState({loading : false})
                }
            }
            else if (data.type === 'GetLocalStyles_Response') {
                this.PluginApi.HandleMessage(data);
            } 
            else if (data.type === 'GetStylesTokenMapValues_Response') {
                this.PluginApi.HandleMessage(data);
            }
            else if (data.type === 'NodeSelected') {                                
                if (data.data && Array.isArray(data.data.nodes))
                    this.PluginApi.SelectedNodeCount = data.data.nodes.length;
                else
                    this.PluginApi.SelectedNodeCount = data.data.count;
                this.setState({selectionId : Utils.Id()})
            }
            else {
                this.PluginApi.HandleMessage(data);                
            }
        }
        
    }
    DeleteProject() {
        if (this.state.projectId) {
            AppState.Data.Board.Delete(this.state.projectId).then((result) => {
                this.PluginApi.PostMessage({
                    type : 'DeleteProject',
                    data : {
                        projectId : this.state.projectId
                    }
                });
                this.setState({projectId : null});                
            })
        }
    }
    DuplicateProject() {
        if (this.state.projectId) {
            AppState.Data.Board.Clone(this.state.projectId).then((result) => {
                if (result && result.id) {
                    const id = result.id;
                    this.setState({projectId : id});
                }
                
            })
        }
    }
    onResizePlugin(prop, value) {
        const state = {};
        state[prop] = value;
        this.setState(state);
        this.ResizePlugin_Throttled(this.state.width, this.state.height);
    }
    ResizePlugin(width, height) {
        this.GetPluginApi().Resize({width : width, height : height});
    }
    FimaMenuToggled() {
        this.setState({showFigmaMenu : !this.state.showFigmaMenu});
    }
    renderCustom() {

        // if (this.Loaded)
        //     return (
        //         <TestView />
        //     );

        let content, header, settingsOverlay;

        if (!this.Loaded || this.state.loading) {
            content = (
                <SC.Div_Full_Center>
                    <SC.Loading_Icon />
                </SC.Div_Full_Center>
            )
        }
        else {
            if (!this.LoggedIn) {
                content = (
                    <SignIn 
                        appSource='figma'
                        onAuthenticated={() => {
                            // console.log(`Logged In`);
                            // this.LoggedIn = true;
                            // this.RCUpdate();
                        }}
                    />
                )
            }
            else {
                if (this.state.showSettings) {
                    settingsOverlay = (
                        <SC.AbsoluteOverlay style={{zIndex : 999999, backgroundColor : SC.CurrentTheme.theme.back}}>
                            <SC.FCol fw fh>
                                <FigmaHeader 
                                    onToggle={this.FimaMenuToggled} 
                                    open={this.state.showFigmaMenu}      
                                    onSettings={() => {this.setState({showSettings : true})}}                       
                                    settings
                                    onCloseSettings={() => {this.setState({showSettings : false})}}                       
                                    title='Plugin Settings'
                                />
                                <LeftScrollPanel>
                                    <FimgaOptions 
                                        width={this.state.width}
                                        height={this.state.height}
                                        onResizePlugin={this.onResizePlugin}
                                        onSetOption={this.SetOption}
                                        options={this.Options}
                                        projectId={this.state.projectId}
                                        GetPluginApi={this.GetPluginApi}
                                    />               
                                </LeftScrollPanel>              
                            </SC.FCol>
                        </SC.AbsoluteOverlay>
                    )
                }
                if (this.state.projectId) {
                    if (this.state.showTeamPanel) {
                        settingsOverlay = (
                            <SC.AbsoluteOverlay style={{zIndex : 999999, backgroundColor : SC.CurrentTheme.theme.back}}>
                                <SC.FCol fw fh>
                                    <FigmaHeader 
                                        onToggle={this.FimaMenuToggled} 
                                        open={this.state.showFigmaMenu}      
                                        onSettings={() => {this.setState({showSettings : true})}}                       
                                        settings
                                        onCloseSettings={() => {this.setState({showTeamPanel : false})}}                       
                                        title='Team'
                                    />
                                    <TeamPanel 
                                        noHeader
                                    />
                                </SC.FCol>
                            </SC.AbsoluteOverlay>
                        )
                    }
                    
                    content = (
                        <FigmaProject                             
                            projectId={this.state.projectId}
                            firstTimeInThisFile={!this.PluginApi.FigmaProjectsIds[this.state.projectId]}
                            onClose={() => {
                                this.PluginApi.SetOption({key : 'LastProjectId', value : null});
                                this.setState({projectId : null}); 
                            }}
                            onDelete={this.DeleteProject}
                            onDuplicate={this.DuplicateProject}
                            themeId={AppLayout.ThemeId}
                            GetPluginApi={this.GetPluginApi}
                            viewMode={this.state.viewMode}
                            onSetOption={this.SetOption}
                            onChangeViewMode={(viewMode) => {
                                this.PluginApi.SetOption({key : 'ViewMode', value : viewMode});
                                this.setState({
                                    viewMode : viewMode
                                });
                            }}                                    
                            showThemeSelector={this.state.showThemeSelector}
                            onToggleThemeSelector={(checked) => {                                        
                                this.PluginApi.SetOption({key : 'ShowThemeSelector', value : checked});
                                this.setState({
                                    showThemeSelector : checked
                                });
                            }}
                            tokenApplyMode={FigmaPluginOptions.tokenEditMode.value}
                            semanticTokenName={FigmaPluginOptions.semanticTokenName.value}
                            selectionId={this.state.selectionId}
                            GetOptions={this.GetOptions}
                            onSettings={() => {this.setState({showSettings : true})}}  
                            onTeamPanel={() => {this.setState({showTeamPanel : true})}}
                            onSelectProject={this.LoadProject.bind(this)}
                        />
                    )  
                }
                else {
                    content = (
                        <SC.FCol f1>                            
                            <ProjectList 
                                onSelect={this.LoadProject.bind(this)}
                                themeId={AppLayout.ThemeId}
                                GetPluginApi={this.GetPluginApi}                            
                                name={this.FigmaFileName}
                                isMenuOpen={this.state.showFigmaMenu}
                            />
                        </SC.FCol>                        
                    )
                    header = (
                        <FigmaHeader 
                            onToggle={this.FimaMenuToggled} 
                            open={this.state.showFigmaMenu}      
                            onSettings={() => {this.setState({showSettings : true})}}                       
                        />
                    )
                }                                
            }
        }
        if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            return (
                <React.Fragment>
                    {header}
                    {content}
                    <GuideOverlay 
                        ref={this.Ref_TourGuide}
                    />
                    {settingsOverlay}
                </React.Fragment>
            );
        }
        return (
            <ErrorBoundary>
                <React.Fragment>
                    {header}
                    {content}
                    <GuideOverlay 
                        ref={this.Ref_TourGuide}
                    />
                    {settingsOverlay}
                </React.Fragment>
            </ErrorBoundary>
        )
        
    }
}

export const FigmaHeader = (props) => (
    <SC.FRow alc jsb style={{
        borderBottom : SC.CurrentTheme.theme.border_seperator,
        padding : '4px',
        paddingRight : '8px',
        backgroundColor : '#2c2c2c',
        color : '#fff',
        fontWeight : 500,
        height : '38px',
        boxSizing : 'border-box',
        overflow : 'hidden'
    }}>
        {
            props.settings && 
            <SC.BackClosableTitle fill='#fff' hoverFill='#fff'
                style={{overflow : 'hidden'}}
                titleStyle={{overflow : 'hidden'}}
                title={props.title}
                onClick={props.onCloseSettings}
            />
        }
        {
            !props.settings && 
            <React.Fragment>
                <ToolabsLogo size={90} />
                <SC.TextDivAbbr style={{flex : 1, textAlign : 'right', marginLeft : '16px', marginRight : '8px'}}>
                    {!props.noName && AppState.User.displayName}
                </SC.TextDivAbbr>
            </React.Fragment>
        }        
        <SC.FRow>
            <FigmaMenu 
                onToggle={props.onToggle} 
                onSettings={props.onSettings}
                open={props.open} top='50px' 
                notifyVersion={AppState.Versions.Figma.DSM && AppState.Versions.Figma.DSM !== AppState.Versions.Figma.Plugin}
            />
        </SC.FRow>                                
    </SC.FRow>
)

