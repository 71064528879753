import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Strings,
    Loading,
    Events,
    Globals
} from '../../../../../../importer';
import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenItemBox, TokenGalleryView, DropFileArea, DropAreaContainer, TokenPanelHeader } from '../common';
import Switch from '../../../../../../components/editors/Switch';


export default class BooleanEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.Ref_Name = React.createRef();
        this.onChangeValue = this.onChangeValue.bind(this);


        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.tokenvalue = Utils.IsTrue(this.props.defaultValue);
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.tokenvalue = Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : this.token}));
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId || this.props.id !== nextProps.id) {
            if (!nextProps.newModel) {
                this.token = Globals.ProjectManager.Tokens.Token(nextProps.id);
                if (!this.token) {
                    this.props.onCancelAddToken();
                    return false;
                }
                this.tokenvalue = Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : this.token}));
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Booleans);
    }        
    
    SaveName(name) {
        if (this.props.newModel) {
            this.props.newModel.name = name;
            this.nameChanged = true;
        }
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }    
    SaveValue() {
        if (this.props.newModel) {
            this.props.newModel.value = this.tokenvalue;       
        }
        else {            
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue
            })
        }        
    }
    onChangeValue(value) {
        if (this.props.newModel) {
            this.props.newModel.value = value;
            this.props.onPreviewChange && this.props.onPreviewChange(value);
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : value});
            Events.BroadcastThrottle(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Booleans,
                value : value

            }]);
        }  
        this.tokenvalue = value;
        this.RCUpdate();
    }    
    renderCustom() {        
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '10px', boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <TokenItemBox style={{marginTop : '16px', ...SC.Styles.Flex.RowAlcJsb}}>
                    <div>Value</div>
                    <Switch round onChange={this.onChangeValue} value={Utils.IsTrue(this.tokenvalue)} />
                </TokenItemBox>
            </SC.FCol>
        )
    }
}
