const Strings = {
    DEFAULT : 'Default',
    PROPERTY : 'Property',
    VALUE : 'value',
    UNIT : 'Unit',
    STYLES : 'Styles',
    PROPS : 'Props',
    COMPONENT : 'Component',
    STATES : 'States'
};

export default Strings;