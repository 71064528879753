import ReactDOM from 'react-dom';
import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';


// views/project/panels/right/iteminspector/selectToken';

export default class FigmaComponents extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.ImportComponent = this.ImportComponent.bind(this);
        
    }
    ImportComponent() {
        this.props.GetPluginApi().Callbac_ImportComponent = ({components}) => {
            components && Utils.ForEach(components, ({id, name, svg}, ) => {
                try {
                    const svgstring = String.fromCharCode.apply(null, svg);
                    const tokenvalue = {
                        data : `data:image/svg+xml;base64,${window.btoa(svgstring)}`,
                        provider : Strings.CUSTOM
                    };
                    Globals.ProjectManager.Tokens.Add({
                        type : Globals.ProjectManager.Tokens.Types.Icons,
                        id : Utils.Id(),
                        name : name,
                        value : tokenvalue                
                    });      
                } catch (error) {
                    
                }                    
            });
            
        }
        this.props.GetPluginApi().PostMessage({
            type : 'Component_Import'
        })
    }
    renderCustom() {
        return (
            <SC.FCol f1 style={{padding : '10px'}}>
                <SC.Div_Flex_Cell>
                    <SC.Buttons.RoundButton xsmall onClick={this.ImportComponent} style={{
                        margin : '8px'
                    }}>
                        Import Selected Component
                    </SC.Buttons.RoundButton>
                </SC.Div_Flex_Cell>
                <div>{this.props.GetPluginApi().SelectedNodeCount}</div>
            </SC.FCol>
        )
    }
}