import Utils from '../../../../toolabs-utils';
import MetaData from '../../..';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 120,
            height : 32
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 32
            },                
            'width': {
                'value': 120
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['Open', 'System', 'disabled', 'hover'],
        System : {
            name : 'System State',
            Variations : {
                Order : ['active', 'focus'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        Open : {
            SingleVariation : true,
            name : 'Open',
            previewable : true,
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            pseudo : true,
            name : 'Hover'
        },
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            '8ejo9_1Cs': {
                dataType: 'string',
                name: 'Title',
                value: 'Label'
            }
        },
        Order:  ['8ejo9_1Cs']
    };        

    Component.ContentId = Utils.Id();
    
    Utils.MergeTo(MetaItem, {        
        Generic : {
            Type : GenericType,
            ContentId : Component.ContentId
        },
        Models : {
            Default : {
                Default : {
                    text: {
                        Id: '8ejo9_1Cs'
                    },
                }
            }
        },
        Property: {
            Default: {
                Default: {
                    alignItems: {
                        value: MetaData.Properties.FlexValues.FLEXCENTER
                    },
                    flexDirection: {
                        value: 'column'
                    },
                    justifyContent: {
                        value: MetaData.Properties.FlexValues.FLEXSPACEAROUND
                    },
                    display: {
                        value: 'flex'
                    },
                    fixedHeight : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderColor: {
                        value: 'rgba(110, 110, 110, 1)'
                    },
                    borderRadius: {
                        value: 4
                    },
                    borderStyle: {
                        value: 'solid'
                    },
                    borderWidth: {
                        value: 1
                    },
                    color: {
                        value: 'unset'
                    },
                    fontSize: {
                        value: 14
                    },
                    width : {
                        value : 200
                    },
                    height: {
                        value: 32
                    }
                },
                hover: {
                    backgroundColor: {
                        value: 'rgba(255, 255, 255, 1)'
                    }
                },
                active: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    },
                    color: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                },
                focus: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                }
            }
        },
        SubItems : [
            {Id : Component.ContentId}
        ]
    });

    Component.MetaItems[Component.ContentId] = {        
        ParentId: RootId,
        Generic : {
            Type : GenericType,
            SubType : 'Content'
        },
        Property: {
            Default: {    
                Default: {
                    alignItems: {
                        value: "center"
                    },
                    alignSelf: {
                        value: "stretch"
                    },
                    flexDirection: {
                        value: "row"
                    },
                    justifyContent: {
                        value: "space-around"
                    },
                    display: {
                        value: "flex"
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderWidth : {
                        value : 2
                    },
                    borderStyle : {
                        value : 'solid'
                    },
                    borderColor : {
                        value : 'rgba(110, 110, 110, 1)'
                    },
                    width: {
                        value : 100,
                        Unit : '%'
                    },
                    minHeight: {
                        value: 100
                    }
                }         
            }
        },
        Type: 'Content'
    };

    return Component;
}