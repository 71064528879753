import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import styled from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { SetFontTypeFace } from '../../../../views/project/panels/left/designsystem/typography';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel } from '../../common';
// views/project/panels/right/iteminspector/selectToken';

export default class FigmaTokenUsage extends ReactBasicComponent {
    constructor(props) {
        super(props);
    }

    renderCustom() {
        return (
            <SC.FCol style={{padding : '10px'}}>
                Token Usage of {this.props.id}
            </SC.FCol>
        )
    }
}