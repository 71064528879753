import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import Strings from './strings';
import ConvertValueDef from './convertvalue';
import PublishMetaItem from './metaitem';
import Publish_Component from './component';
import Publish_Prototype from './prototype';
import Publish_Theme from './theme';
import Publish_Document_Template  from './document';


const PublishSvgItem = function(MetaItem, Id, PublishContext) {
    let ConvertedItem = {
        Type : MetaItem.Type,
        Id : Id,
        Styles : {
            Default : {

            }
        }        
    };
    
    let State = Strings.DEFAULT;
    // Properties
    const ItemStateProperties = Utils.Get(MetaItem, null, Strings.PROPERTY, State);
    if (ItemStateProperties) {        
        Utils.ForEach(ItemStateProperties, (PropObject, PropName) => {
            let Value = ConvertValueDef({type : 'Svg'}, PropObject, PublishContext);
            Utils.Set(ConvertedItem, Value, Strings.PROPS, State, PropName); 
        });               
    }

    if (MetaItem.SubItems) {
        Utils.ForEach(MetaItem.SubItems, (SubItemId, i) => {
            const MetaSubItem = PublishContext.MetaItems[SubItemId];
            if (MetaSubItem) {
                const ConvertedSubItem = PublishSvgItem(MetaSubItem, SubItemId, PublishContext);
                if (!ConvertedItem.SubItems)
                    ConvertedItem.SubItems = [];
                ConvertedItem.SubItems.push(ConvertedSubItem);
            }            
        })
    }

    return ConvertedItem;
};







const Publish_Board = function(Model, Data, Elements, ExportIcons, Platform) {        

    const PublishContext = {
        Platform : Platform
    };

    let ConvertedBoard = {
        Name : Model && Model.Name,
        Id : Model && Model._id,
        Theme : Publish_Theme(Model, Data, PublishContext)
    };

    return ConvertedBoard;
     

    // Element Templates 
    ConvertedBoard.Elements = {};
    if (Data.Elements) {
        Utils.ForEach(Data.Elements.data,(metaElement, id) => {
            if (metaElement.MetaItem) {
                const ConvertedElement = PublishMetaItem(metaElement.MetaItem, id, PublishContext);
                ConvertedBoard.Elements[id] = ConvertedElement;
            }
        });
    }    
    
    // Animations
    Utils.ForEach(Data.Animations, (Animation, Id) => {
        Utils.Set(ConvertedBoard, Animation, 'Animations', Id );
    });    

    const BoardStyles = Utils.Get(Data, null, 'Variables');
    if (BoardStyles && BoardStyles.List) {
        Utils.ForEach(BoardStyles.List, (Variable, Id) => {
            if (Variable.type === 'Sizes') {
                Utils.Set(ConvertedBoard, MetaData.DataTypes.size, Strings.STYLES, Id, 'type');
                const Value = {
                    value : Variable.value
                };
                if (Variable.Unit) {
                    if (Variable.Unit.type === 'Custom') {
                        Value.unit = Variable.Unit;
                    }
                    else {
                        Value.unit = Variable.Unit.unit || 'px';
                    }                            
                }   
                else {
                    // TODO : is px?
                }
                Utils.Set(ConvertedBoard, 
                    Value
                    , Strings.STYLES, Id, Strings.VALUE);
            }
            else {
                Utils.Set(ConvertedBoard, Variable.value, Strings.STYLES, Id, Strings.VALUE);
            }
            Utils.Set(ConvertedBoard, Variable.name, Strings.STYLES, Id, 'Name');
            if (Variable.Themes) {
                Utils.ForEach(Variable.Themes,(value, themeId) => {
                    let StateValue = value;
                    if (Variable.type === 'Sizes') {
                        StateValue = {
                            value : value && value.value
                        };
                        if (value && value.Unit) {
                            if (value.Unit.type === 'Custom') {
                                StateValue.unit = value.Unit;
                            }
                            else {
                                StateValue.unit = value.Unit.unit || 'px';
                            } 
                        }
                    }
                    Utils.Set(ConvertedBoard, StateValue, Strings.STYLES, Id, 'Theme', themeId);
                });
            }
        })
    }

    const BoardUnits = Utils.Get(Data, null, 'Units');
    if (BoardUnits) {
        Utils.ForEach(BoardUnits,(Unit, i) => {
            Utils.Set(ConvertedBoard, Unit.value, 'Units', Unit.id, Strings.VALUE);
            Utils.Set(ConvertedBoard, Unit.Unit, 'Units', Unit.id, Strings.UNIT);
            if (Unit.Themes) {
                Utils.ForEach(Unit.Themes,(ThemeValue, ThemeId) => {
                    Utils.Set(ConvertedBoard, ThemeValue.value, 'Units', Unit.id, ThemeId, Strings.VALUE);
                    Utils.Set(ConvertedBoard, ThemeValue.Unit, 'Units', Unit.id, ThemeId, Strings.UNIT);
                });
            }
        });
    }

    const BoardFonts = Utils.Get(Data, null, 'Assets', 'List', 'Fonts');
    if (BoardFonts) {
        Utils.ForEach(BoardFonts,(MetaFont, FontId) => {
            Utils.Set(ConvertedBoard, MetaFont.font, 'Fonts', FontId);        
            if (MetaFont.Themes) {
                Utils.ForEach(MetaFont.Themes,(value, themeId) => {
                    Utils.Set(ConvertedBoard, value, 'Fonts', FontId, 'Theme', themeId);
                });
            }    
        });
    }



    const StringDefs = Utils.Get(Data, {}, 'Assets', 'ContentItems');
    if (StringDefs.Values) {
        Utils.Set(ConvertedBoard, StringDefs.Values, 'Strings');   
    }
    

    // States
    const States = Utils.Get(Data, {}, Strings.STATES);
    if (States) {
        Utils.ForEach(States.Order, (Id) => {
            const State = States[Id];
            if (State.Variations) {
                const VariationIds = Utils.Get(State, null, 'Variations', 'Order');
                if (VariationIds) {
                    Utils.Set(ConvertedBoard, State.name, Strings.STATES, Id, 'Name');
                    Utils.ForEach(VariationIds, (vid) => {
                        Utils.Set(ConvertedBoard, Utils.Get(State, '', 'Variations', vid, 'name'), Strings.STATES, Id, 'Variations', vid);
                    })
                }
            }    
            else {
                // Single Variation State
                Utils.Set(ConvertedBoard, State.name, Strings.STATES, Id, 'Name');
                Utils.Set(ConvertedBoard, Id, Strings.STATES, Id, 'SingleVariation');
            }        
        });
    }

    // Assets
    if (Data.Assets && Data.Assets.List) {
        if (Data.Assets.List.Images) {
            Utils.ForEach(Data.Assets.List.Images, (Image, id) => {
                Utils.Set(ConvertedBoard, {
                    Url : Image.Url
                }, 'Images', id );

                if (Image.Themes) {
                    Utils.ForEach(Image.Themes,(value, themeId) => {
                        Utils.Set(ConvertedBoard, {
                            Url : value.Url
                        }, 'Images', id, 'Theme', themeId );
                    });
                }
            });
        }

        if (Data.Assets.List.Icons) {
            Utils.ForEach(Data.Assets.List.Icons, (Icon, id) => {
                Utils.Set(ConvertedBoard, Icon.MetaIcon, 'Icons', id );

                if (Icon.Themes) {
                    Utils.ForEach(Icon.Themes,(value, themeId) => {
                        Utils.Set(ConvertedBoard, {
                            value
                        }, 'Icons', id, 'Theme', themeId );
                    });
                }
            });
        }

        if (Data.Assets.List.Gradients) {
            Utils.ForEach(Data.Assets.List.Gradients, (Model, id) => {
                if (Model.gradient) {
                    Utils.Set(ConvertedBoard, {
                        colors : Model.gradient.colors || [],
                        type : Utils.Get(Model, 'linear', 'type'),
                        angle : Utils.Get(Model.gradient, 0, 'path', 'angle')
                    }, 'Gradients', id, Strings.VALUE );

                    if (Model.Themes) {
                        Utils.ForEach(Model.Themes,(value, themeId) => {
                            Utils.Set(ConvertedBoard, {
                                colors : Utils.Get(value, [], 'gradient', 'colors'),
                                type : Utils.Get(value, 'linear', 'type'),
                                angle : Utils.Get(value, 0, 'gradient', 'path', 'angle')
                            }, 'Gradients', id , 'Theme', themeId, Strings.VALUE);
                        });
                    }
                }                
            });
        }

        if (Data.Assets.List.Shadows) {
            Utils.ForEach(Data.Assets.List.Shadows, (Shadow, id) => {
                Utils.Set(ConvertedBoard, {
                    values : Shadow.values,
                    textShadow : Shadow.textShadow
                }, 'Shadows', id, Strings.VALUE );

                if (Shadow.Themes) {
                    Utils.ForEach(Shadow.Themes,(value, themeId) => {
                        Utils.Set(ConvertedBoard, {
                            values : value.values,
                            textShadow : value.textShadow
                        }, 'Shadows', id , 'Theme', themeId, Strings.VALUE);
                    });
                }
            });
        }

        if (Data.Assets.List.Transitions) {
            Utils.ForEach(Data.Assets.List.Transitions, (Transition, id) => {
                Utils.Set(ConvertedBoard, Transition, 'Transitions', id, Strings.VALUE );

                if (Transition.Themes) {
                    Utils.ForEach(Transition.Themes,(value, themeId) => {
                        Utils.Set(ConvertedBoard, {
                            value : value
                        }, 'Transitions', id , 'Theme', themeId, Strings.VALUE);
                    });
                }
            });
        }

        if (Data.Assets.List.Sounds) {
            const SystemSounds = [];
            Utils.ForEach(Data.Assets.List.Sounds, (Sound, id) => {
                if (Sound.MetaSound) {
                    Utils.Set(ConvertedBoard, Sound.MetaSound, 'Sounds', id, Strings.VALUE );
                    if (Sound.MetaSound.provider === 'Facebook Sound Kit') {
                        SystemSounds.push(Sound.MetaSound.Id);
                    }
                    if (Sound.Themes) {
                        Utils.ForEach(Sound.Themes,(value, themeId) => {
                            Utils.Set(ConvertedBoard, {
                                value : value
                            }, 'Sounds', id , 'Theme', themeId, Strings.VALUE);
                        });
                    }
                }                
            });
            if (SystemSounds.length > 0) {
                Utils.ForEach(MetaData.Sounds,(Category) => {
                    Utils.ForEach(Category.sounds,(MetaSound) => {
                        if (SystemSounds.indexOf(MetaSound.id) > -1) {
                            Utils.Set(ConvertedBoard, 
                                {
                                    url : `https://ds101.toolabs.com/assets/sounds/${Category.name}/${MetaSound.name}.m4a`
                                }, 'SystemSounds', MetaSound.id);
                        }
                    });
                });    
            }
        }

        // if (ExportIcons) {
        //     ConvertedBoard.Icons = ExportIcons;
        // }        
    }    

    return ConvertedBoard;
};

const Publisher = {
    Publish_Component,
    Publish_Prototype,
    Publish_Board,
    Publish_Document_Template
}

export default Publisher;