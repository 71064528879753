import React from 'react';
import {
    SC
} from '../importer';

export default (props) => {
    return (
        <React.Fragment>
            <SC.Div_Flex_Cell title='Dark background' key='db' pl={4} pr={4} cursorPointer onClick={props.onChange}>
                <SC.Icons.Icon_Font_Back_Light size={props.small ? 12 : (props.medium ? 14 : 0)} selected={props.Dark} />
            </SC.Div_Flex_Cell>
            <SC.Div_Flex_Cell title='Light background' key='lb' pl={4} pr={4} cursorPointer onClick={props.onChange}>
                <SC.Icons.Icon_Font_Back_Dark size={props.small ? 12 : (props.medium ? 14 : 0)} selected={!props.Dark}/>
            </SC.Div_Flex_Cell>
        </React.Fragment>
    )
}

export const ListCardView = (props) => {
    if (props.compact) {
        return (
            <SC.Icons.Icon_Button hasFill 
                title={props.isListView ? 'Card View' : 'List view'} 
                style={{cursor : 'pointer', minWidth : '22px', ...props.style}} 
                onClick={props.isListView ? props.onCardView : props.onListView}
            >
                {props.isListView ? <SC.Icons.Icon_Card_View size={11} /> : <SC.Icons.Icon_List_View size={9} />}
            </SC.Icons.Icon_Button>
        )        
    }
    return (
        <SC.FRow alc style={props.style}>
            <SC.Icons.Icon_Button hasFill fill={props.isListView ? SC.CurrentTheme.theme.color_brand : null} title='List view' style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '4px', marginLeft : '4px'}} onClick={props.onListView}>
                <SC.Icons.Icon_List_View size={16} />
            </SC.Icons.Icon_Button>
            <SC.Icons.Icon_Button hasFill fill={props.isCardView ? SC.CurrentTheme.theme.color_brand : null} title='Large cards' style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '4px'}} onClick={props.onCardView}>
                <SC.Icons.Icon_Card_View size={14} />
            </SC.Icons.Icon_Button>
        </SC.FRow>
    )
}