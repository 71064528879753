import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    SC
} from '../../../../../../importer';

import ReactResizeDetector from 'react-resize-detector';
import { onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onItemShouldUpdate, onItemClick, onGetItemProps } from '../common';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiYXBwbGluaXN0IiwiYSI6ImNqbDBwa2dlNzE3N2MzcXFwbW11emd1Y3kifQ.3lWPxT2CpCvdTP5Unp25Gg';

let MapBoxWrapper;

export default class MapBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.Load = this.Load.bind(this);
        this.onResize = this.onResize.bind(this);
        this.Ref_Map = React.createRef();

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
        
    }
    UpdateStyle(props) {        
        const renderData = props.GetComponentManager().GetItemStyle(props.Id);
        const currendProps = Utils.JustGet(this.renderData, {}, 'props');
        const isHidden = Utils.IsTrue(renderData.props.hidden);
        if (isHidden && !this.isHidden) {
            if (this.MapInstance) {
                this.MapInstance.remove();
                delete this.MapInstance;
            }
        }
        else if (this.isHidden && !isHidden) {

        }
        this.isHidden = isHidden;
        this.renderData = renderData;
        if (!this.isHidden && Utils.HasAnyChange(currendProps, renderData.props, 'mapTheme', 'latitude', 'longitude', 'mapzoom', 'mapinteractive')) {
            if (this.MapInstance) {
                this.MapInstance.setStyle(renderData.props.mapTheme);
                                
                this.MapInstance.setZoom(renderData.props.mapzoom ||11);
                if (renderData.props.longitude && renderData.props.latitude)
                    this.MapInstance.panTo([renderData.props.latitude, renderData.props.longitude]);
            }
        }        
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
        if (this.MapInstance)
            this.MapInstance.remove();
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    GetMaxSize() {        
        return this.props.GetParentSize();
    }
    HeaderLoaded(scriptLoaded, styleLoaded) {
        if (scriptLoaded) {
            this.scriptLoaded = true;
        }
        if (styleLoaded)
            this.styleLoaded = true;
        if (this.scriptLoaded && this.styleLoaded) {
            MapBoxWrapper = window.mapboxgl;
            
            if (MapBoxWrapper) {
                MapBoxWrapper.accessToken = MAPBOX_TOKEN;
                this.Load();
            }                    
        }
    }
    componentDidMount() {
        onItemDidMount.call(this);
        MapBoxWrapper = window.mapboxgl;

        if (!MapBoxWrapper) {
            const script = document.createElement("script");
            script.src = "https://api.tiles.mapbox.com/mapbox-gl-js/v0.52.0/mapbox-gl.js";
            script.async = true;
            script.onload = this.HeaderLoaded.bind(this, true);
            document.head.appendChild(script);

            var sheet = document.createElement('link');
            sheet.rel = 'stylesheet';
            sheet.href = `https://api.mapbox.com/mapbox-gl-js/v0.49.0/mapbox-gl.css`;
            sheet.type = 'text/css';
            sheet.onload = this.HeaderLoaded.bind(this, false, true);
            document.head.appendChild(sheet);            
        }
        else
            this.Load();
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            willUpdate = true;
        }          
        return willUpdate;
    }
    Load() {
        if (this.Ref_Map && this.Ref_Map.current) {

            const {mapTheme, latitude, longitude, mapzoom, mapinteractive} = this.renderData.props;

            const DN = ReactDOM.findDOMNode(this.Ref_Map.current);
            this.MapInstance = new MapBoxWrapper.Map({
                container: DN,
                style: mapTheme || 'mapbox://styles/mapbox/streets-v10',
                zoom: mapzoom || 11,
                center: [latitude || -122.420679, longitude || 37.772537],
                interactive : false        
            });

            this.MapInstance.on('dragend', (params) => {
                if (params) {
                    
                }
            })
        }
        this.setState({ShouldUpdate : true});
    }
   
    onResize(w, h) {
        if (this.MapInstance)                                                                                                                                                                                                                                                                                                                                           
            this.MapInstance.resize();
    }    
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        const props = onGetItemProps({designer : this});

        

        return (
            <div style={{...this.renderData.style, overflow : 'hidden',
                backgroundImage : `url(${process.env.PUBLIC_URL}/assets/staticmap-light.png)`,
                backgroundSize : 'cover',
                backgroundRepeat : 'no-repeat',
                backgroundPosition : 'center',
            }} {...props} >
                {/* <img alt='static Mapbox map of the San Francisco bay area' src={'https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/-75.4282,44.6801,3.37/900x800?access_token='+MAPBOX_TOKEN} ></img> */}
                {/* <div style={{width : '100%', height:'100%', pointerEvents : 'none'}} ref={this.Ref_Map} /> */}
                {/* <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />                 */}
            </div>
        )
    }
}
 