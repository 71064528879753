import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import {GetItemType} from '../../index';
import { onItemShouldUpdate, onItemDragging, onMouseDownOnItem, onItemClick, onItemResized, onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetChildItem, onGetItemProps, onGetDomProps } from '../../common';

import GridLinesOverlay from './gridLines';

export default class GridContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.isGridContainer = true;

        onConstructItem(this, props);                
        
        this.renderSlot = this.renderSlot.bind(this);
        this.renderSlide = this.renderSlide.bind(this);

        this.UpdateStyle(this.props);

        this.RegisterDropTarget = this.RegisterDropTarget.bind(this);
    }
    shouldComponentUpdate(nextProps, nextState) {        
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {
            return true;
        }
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            if (!this.wasSelected && this.isSelected) {
                this.showGridLines = nextProps.GetComponentManager().GetDesignManager().GetGridProperty(nextProps.Id, 'showGridLines', false);
            }
            return true;
        }

        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {
            return true;
        }
                  
        return false;
    }
    componentDidMount() {
        onItemDidMount.call(this);     
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);

        if (this.onReloadGrid && this.props.ComponentStateId !== prevProps.ComponentStateId)
            this.onReloadGrid();
        // if (this.isGridEditing && this.wasSelected && !this.isSelected) {
        //     this.isGridEditing = false;
        //     this.onGridEditingChanged && this.onGridEditingChanged(false);
        // }
    }
    ToggleEditing(editing) {
        this.isGridEditing = editing || !this.isGridEditing;
        this.onGridEditingChanged && this.onGridEditingChanged(this.isGridEditing);
        this.setState({ShouldUpdate : true});
    }
    UpdateStyle(props) {
        this.renderData = Utils.DeepClone(props.GetComponentManager().GetItemStyle(props.Id));
        this.showGridLines = false;
        if (!this.props.Preview) {
            this.showGridLines = props.GetComponentManager().GetDesignManager().GetGridProperty(props.Id, 'showGridLines', false);
        }
        const {style} = this.renderData;
        if (this.props.IsRootItem) {
            if (!style.width)
                style.width = '100%';
            if (!style.height)
                style.height = '100%';                        
        }
    }     
    GetMaxSize(scaled) {
        let width, height;
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.Viewport) {      
                width = this.props.IsRootItem.Viewport.Width;
                height = this.props.IsRootItem.Viewport.Height;
                const bounds = UIUtils.Utils.GetBounds(this);
                return {
                    width : width,
                    height : height,
                    top : bounds.top,
                    left : bounds.left
                }
            }
        }
        return this.props.GetParentSize(scaled);
    }
    RegisterDropTarget(Id, ParentId, data) {
        this.props.onRegisterDropTarget && this.props.onRegisterDropTarget(Id, this.props.ParentId, data);
    }
    renderContent() {
        const SubItems = this.props.GetComponentManager().GetChildItems(this.props.Id);
        const items = [];
      
        Utils.ForEach(SubItems, (SubItem, i) => {
            let ChildItem = onGetChildItem({
                ParentDesigner : this,
                ParentProps : this.props,
                ChildItemId : SubItem.Id, 
                childProps : {
                    IsGridChild : {

                    },
                }                                          
            })
            if (ChildItem) {
                items.push(ChildItem);
            }            
        });        

        return items;
    }
    renderSlot({SlotItemId, SlotItem, ParentzIndex, ContainerStyle}) {

        const childProps = {
            IsGridChild : {

            },
            isSlotContainer : {
                style : ContainerStyle
            }                   
        };
        if (this.props.isDropTarget) {
            childProps.onRegisterPlaceholder = this.props.onRegisterPlaceholder || this.RegisterPlaceholder;
            childProps.onRegisterDropTarget = this.props.onRegisterDropTarget || this.RegisterDropTarget;
        } 

        const slotContainer = onGetChildItem({
            ChildItemId : SlotItemId,
            ChildItem : SlotItem,
            ParentDesigner : this,
            ParentProps : this.props,
            isDropTarget : this.props.isDropTarget,
            ParentzIndex : ParentzIndex,
            childProps : childProps
        });

        return slotContainer;
    }
    renderSlide({SlideItemId, SlideItem, ContainerStyle, ParentzIndex}) {

        const childProps = {
            IsGridChild : {

            },
            isSlideContainer : {
                style : ContainerStyle
            }                   
        };
        if (this.props.isDropTarget) {
            childProps.onRegisterPlaceholder = this.props.onRegisterPlaceholder || this.RegisterPlaceholder;
            childProps.onRegisterDropTarget = this.props.onRegisterDropTarget || this.RegisterDropTarget;
        } 

        const slideContainer = onGetChildItem({
            ChildItemId : SlideItemId,
            ChildItem : SlideItem,
            ParentDesigner : this,
            ParentProps : this.props,
            isDropTarget : this.props.isDropTarget,
            ParentzIndex : ParentzIndex,
            childProps : childProps
        });

        return slideContainer;
    }
    render() {         
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
        // this.RenderIndex = (this.RenderIndex || 0) + 1;
        let outerStyle = {};
        let otuerProps = {};
        
        if (this.props.IsRootItem && this.props.IsRootItem.SubComponent) {
            outerStyle =  this.props.IsRootItem.SubComponent.style;       
            otuerProps.onMouseDown = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseDown : this.onMouseDown;
            otuerProps.onMouseUp = this.props.Preview ? this.props.IsRootItem.SubComponent.onMouseUp : this.onItemClick;
            otuerProps.onDoubleClick = this.props.IsRootItem.SubComponent.onDoubleClick;
        }

        const style = {
            position : 'relative',
            boxSizing : 'border-box',            
            ...this.renderData.style,            
            ...outerStyle,
            ...this.renderData.props.GridStyle       
        };
        // if (style.overflowX && !style.overflowY)
        //     style.overflowY = 'hidden';
        // if (style.overflowY && !style.overflowX)
        //     style.overflowX = 'hidden';

        if (this.isSelected) {
            // style.transition = 'all 0.2s ease';
        }
        
        if (this.isDragging) {
            style.opacity = 0.5;
        }        

        const props = onGetDomProps({designer : this, props : otuerProps, style : style});
      
        
        let gridLines;
        if (this.props.isDropTarget || this.showGridLines && this.isSelected && !this.isGridEditing) {
            gridLines = (
                <GridLinesOverlay
                    key='GridLinesOverlay'
                    rowCount={this.renderData.props.Computed.fixedRows}
                    colCount={this.renderData.props.Computed.fixedColumns}
                    autoColumns={this.renderData.props.Computed.autoCellCount}
                    style={this.renderData.props.GridStyle}
                    containerStyle={this.renderData.style}
                    ColumnGap={this.renderData.props.Computed.ColumnGap}
                    RowGap={this.renderData.props.Computed.RowGap}
                    GlobalThemeId={this.props.GlobalThemeId}
                    isDropTarget={this.props.isDropTarget}                    
                    Id={this.props.Id}
                    onDragDrop={this.props.onDragDrop}
                    zIndex={(this.props.ParentzIndex || 0) + 1}
                    isSubComponentChild={this.props.isSubComponentChild}
                    GetComponentManager={this.props.GetComponentManager}
                />   
            )
        }

        if (this.props.isDropTarget) {
            // style.outline = '4px solid red';
        }

        return ( 
            <SC.Div_Relative
                {...props}
            >                
                {gridLines}
                {this.renderContent()}
                {/* <div key='zIndex' style={{position : 'absolute', top : 0, left : 0, color : 'red'}}>{this.props.ParentzIndex || 0}</div> */}
                {/* {this.RenderIndex} */}
            </SC.Div_Relative>
        );
    }    
}
 