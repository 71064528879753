import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';
import { GroupTitle, ItemNameEditor } from '../../common';
import { SortableList, SortableListItem} from '../../../../../../../components/SortableContainer';
import { DraggableEditorBox, DraggableEditorHandle } from '../../../../../../../components/editors/transform/transformItemEditor';

export default class TextContentParameters extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddParameter = this.AddParameter.bind(this);
        this.onSortItems = this.onSortItems.bind(this);
    }
    AddParameter() {
        this.props.items.push({
            id : Utils.Id(),
            name : 'param-' + (this.props.items.length + 1)
        });
        this.props.onSave();
        this.RCUpdate();
    }
    DeleteParameter(param) {
        Utils.Remove(this.props.items, (item) => {return item.id === param.id});
        this.props.onSave();
        this.RCUpdate();
    }
    onSortItems(oldIndex, newIndex) {
        Utils.ChangePlace(this.props.items, oldIndex, newIndex);
        this.props.onSave();
        this.RCUpdate();
    }
    renderCustom() {
        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <GroupTitle 
                    title='Parameters' 
                    subGroup
                    hasAdd={{onAdd : this.AddParameter}} 
                    style={{paddingRight : 0, paddingLeft : '4px'}}
                />
                <SortableList                            
                    onSort={this.onSortItems}
                    style={{marginLeft : '-8px', marginRight : '-8px', marginTop : '4px'}}
                >
                    {
                        this.props.items.map((param, i) => {
                            return (
                                <SortableListItem 
                                    key={param.id}
                                    draggableId={param.id}
                                    index={i}
                                    ItemBoxType={Parameter}
                                    BoxProps={{
                                        model : param,
                                        onDelete : this.DeleteParameter.bind(this, param),
                                        onSave : this.props.onSave,
                                        GetItems : () => {return this.props.items}
                                    }}
                                />
                            )
                        })
                    }
                </SortableList>
                
            </SC.FCol>
        )
    }
}

class Parameter extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.SaveName = this.SaveName.bind(this);
    }
    SaveName(name) {
        this.props.model.name = name;
        this.props.onSave();
        this.RCUpdate();
    }
    renderCustom() {
        return (
            <DraggableEditorBox>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '8px', paddingRight : '4px'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <SC.FCol f1 style={{padding : '8px'}}>
                    <ItemNameEditor
                        noMargin
                        fontSize='12px'                        
                        name={this.props.model.name}
                        onSaveName={this.SaveName}
                        onGetItems={this.props.GetItems}
                        model={this.props.model}
                        label={(
                            <SC.FRow alc jsb>
                                <div>NAME</div>
                                <SC.Icons.Icon_Button hasFill onClick={this.props.onDelete}>
                                    <SC.Icons.Icon_Delete size={16} />
                                </SC.Icons.Icon_Button>
                            </SC.FRow>
                        )}
                    />             
                </SC.FCol>
            </DraggableEditorBox>            
        )
    }
}