import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import { TokenItemBox, GroupTitle, TokenPanelHeader } from '../../../../left/designsystem/common';
import ComponentItem from '../../../../left/components/item';
import ComponentList from '../../../../left/components';
import ComponentDesignManager from '../../../../../component/manager';
import { ListCardView } from '../../../../../../../components/light_dark_bar';

export default class SelectComponent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSelectComponent = this.onSelectComponent.bind(this);
        this.Close = this.Close.bind(this);

    }    
    Close() {
        this.isClosing = true;
        this.props.onClose();
    }    
    onSelectComponent(id) {
        if (this.props.onSelectForItem)
            this.props.onSelectForItem(id);
        else 
            this.props.onSelect(id);

        if (!this.props.multiple)
            this.Close();
    }
    
    renderCustom() {
        return (
            <ComponentList 
                selectable
                closable
                GlobalState={this.props.GlobalState}
                GlobalStateId={this.props.GlobalStateId}
                themeId={this.props.themeId}
                disableRender={this.props.disableRender}
                onAdd={this.AddNewComponent}
                onClose={this.props.onClose}
                onSelect={this.onSelectComponent}
                ExcludeComponents={this.props.ExcludeComponents}
            />
        )        
    }
}