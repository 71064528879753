import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_SelectBox extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {      

        let options = [
            {value : '1', label : 'Option 1'},
            {value : '2', label : 'Option 2'},
            {value : '3', label : 'Option 3'},
            {value : '4', label : 'Option 4'}
        ]
        
        return (
            <select 
                style={style} 
                {...props}
            >
                {
                    options.map((option, i) => {
                        return (
                            <option  key={i} value={option.value}>
                                {option.label || option.value}                          
                            </option>
                        )
                    })
                }
            </select>
        );
    }
}