import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout,
    Globals
} from '../../../../../../../importer';

import Flatbush from 'flatbush';
import DragDropManager from '../../../../../../../components/dragdrop/DragDropManager';

export default class GridLines extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.Ref_Target = React.createRef();

        this.onMousMove = this.onMousMove.bind(this);
        this.DropItemOnCell = this.DropItemOnCell.bind(this);

        this.CheckTarget = this.CheckTarget.bind(this);
        this.CheckTargetThrottled = Utils.Throttle(this.CheckTarget, 200);
        this.SetActiveDropTarget = this.SetActiveDropTarget.bind(this);
        this.RemoveActiveDropTarget = this.RemoveActiveDropTarget.bind(this);

        this.LoadOptions();
    }
    LoadOptions() {
        const designerLineColorId = Globals.ProjectManager.Templates.GetStateValue({Id : Globals.ProjectManager.Templates.DefaultTemplateId(Globals.ProjectManager.Templates.Types.Grid), name : 'designerLineColor'});
        if (designerLineColorId) {
            this.designerLineColor = Globals.ProjectManager.Tokens.GetStateValue({Id : designerLineColorId});
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalThemeId !== nextProps.GlobalThemeId) {
            this.LoadOptions();
            return true;   
        }
        return true;
    }
    componentDidMount() {
        this.state.mounted = true;
        this.props.isDropTarget && this.CheckTargetCells();
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.isDropTarget && this.props.isDropTarget) {
            this.CheckTargetCells();
        }            
    }
    RegisterCellTarget(row, column, Id, info) {
        if (DragDropManager.DragData && DragDropManager.DragData.DragItem.Type === Strings.CHILDITEM) {
            if (DragDropManager.DragData.DragItem.ParentId === this.props.Id) {
                if (row >= DragDropManager.DragData.DragItem.GridChildProps.gridRowStart && row < DragDropManager.DragData.DragItem.GridChildProps.gridRowEnd &&
                    column >= DragDropManager.DragData.DragItem.GridChildProps.gridColumnStart && column < DragDropManager.DragData.DragItem.GridChildProps.gridColumnEnd ) {
                        return;
                    }
            }
        }

        const CellTargets = Utils.Get(this, [], 'CellTargets');
        CellTargets.push({
            row : row,
            column : column,
            Id : Id,
            bounds : info.bounds,
            ref : info.ref
        })
        
    }
    CheckTargetCells() {
        if (!this.state.mounted)
            return;
        const CellTargets = Utils.Get(this, [], 'CellTargets');
        if (CellTargets.length > 0) 
        {
            this.targets = new Flatbush(CellTargets.length);    
    
            Utils.ForEach(CellTargets , (CellTarget, i) => {            
                const bounds = CellTarget.bounds;                
                this.targets.add(bounds.left, bounds.top, bounds.left + bounds.width, bounds.top + bounds.height);
            });    
    
            this.targets.finish();

            const DN = ReactDOM.findDOMNode(this.Ref_Target.current);
            DN.addEventListener('mousemove', this.onMousMove);
            DN.addEventListener('mouseup', this.DropItemOnCell);            


            this.setState({active : true})
        }
    }
    onMousMove(e) {
        this.lastX = e.clientX;
        this.lastY = e.clientY;
        this.state.active && this.CheckTargetThrottled(this.lastX, this.lastY);
    }
    CheckTarget(x, y) {     
        if (this.targets) {
            const nearestTarget = this.targets.neighbors(x, y, 1);
            if (nearestTarget.length > 0) {            
                const index = nearestTarget[0];
                if (index !== this.state.CurrentTargetCellIndex) {
                    const CellTargets = Utils.Get(this, [], 'CellTargets');                    
                    this.setState({
                        CurrentTargetCellIndex : index,
                        CurrentTargetCellId : CellTargets[index].Id
                    })
                }
            }
        }
    }
    DropItemOnCell(e) {
        const CellTargets = Utils.Get(this, [], 'CellTargets');
        if (this.state.CurrentTargetCellIndex >= 0) {
            // e && e.stopPropagation();
            const TargetCell = CellTargets[this.state.CurrentTargetCellIndex];
            this.props.onDragDrop({
                drop : true,
                grid : true,
                data : {
                    ParentId : this.props.Id,
                    cell : {
                        gridColumnStart : TargetCell.column,
                        gridColumnEnd : TargetCell.column + 1,
                        gridRowStart : TargetCell.row,
                        gridRowEnd : TargetCell.row + 1,
                    },
                    SubComponent : this.props.isSubComponentChild ? {
                        GetComponentManager : this.props.GetComponentManager
                    } : false
                }                
            });
        }
    }
    SetActiveDropTarget(e) {
        this.isMouseOver = true;        
        this.lastX = e.clientX;
        this.lastY = e.clientY;
        setTimeout(() => {
            if (this.isMouseOver) {
                this.CheckTarget(this.lastX, this.lastY);
                this.setState({activeTarget : true});
        
                AppLayout.Refs.DropOverlay && AppLayout.Refs.DropOverlay.Suspend(true);    
            }            
        }, 100);        
    }
    RemoveActiveDropTarget(e) {
        this.isMouseOver = false;
        if (this.state.activeTarget) {
            this.setState({activeTarget : false});
            AppLayout.Refs.DropOverlay && AppLayout.Refs.DropOverlay.Suspend(false);
        }        
    }
    render() {
        const {colCount, rowCount, style, draggingChildItem, enabled, autoColumns, hidden} = this.props;
        const fixedCells = [];

        if (this.props.isDropTarget) {
            this.CellTargetCount = 0;
            for (let r = 1; r <= rowCount; r++) {
                for (let c = 1; c <= colCount; c++) {                                    
                    const CellId = 100*r + c;
                    fixedCells.push(
                        <GridCellTarget 
                            Id={CellId}
                            key={this.CellTargetCount}                                                      
                            lineColor={this.designerLineColor}
                            onRegisterCellTarget={this.RegisterCellTarget.bind(this, r, c, CellId)}
                            active={this.state.activeTarget}
                            isOver={this.state.CurrentTargetCellId === CellId}
                            zIndex={this.props.zIndex+1}
                        >
                        </GridCellTarget>
                    )
                    this.CellTargetCount++;
                }
            }
        }
        else {
            for (let r = 1; r <= rowCount; r++) {
                for (let c = 1; c <= colCount; c++) {                
                    fixedCells.push(
                        <GridCell 
                            key={1000*r+c}                                                      
                            lineColor={this.designerLineColor}
                            isDropTarget={this.props.isDropTarget}
                        />
                    )
                }
            }
        }

        const props = {};

        if (this.props.isDropTarget && this.state.active) {
            props.onMouseMove = this.onMouseMove;
        }
                

        return (
            <div style={{
                    ...this.props.containerStyle,
                    ...SC.Styles.Absolute,
                    backgroundColor : 'rgba(0,0,0,0)',
                    backgroundImage : 'unset',
                    gridColumnStart : 'unset',
                    gridRowStart : 'unset',
                    gridColumnEnd : 'unset',
                    gridRowEnd : 'unset',
                    pointerEvents : this.props.isDropTarget ? 'all' : 'none',
                    boxSizing : 'border-box',
                    margin : 0,
                    border : 'none',
                    width : 'unset',
                    height : 'unset',
                    zIndex : this.props.zIndex,
                    ...SC.Styles.Flex.Cell
                }}
                onMouseOver={this.SetActiveDropTarget}
                onMouseLeave={this.RemoveActiveDropTarget}
                ref={this.Ref_Target}                
            >
                <div                     
                    style={{
                        ...this.props.style,
                        flex : 1,
                        alignSelf : 'stretch',
                        // backgroundColor : 'rgba(0,34,255, 0.3)',
                        // pointerEvents : 'all',                        
                    }}
                    {...props}                    
                >
                    {fixedCells}                
                </div>
            </div>
        )   
    }
}

const GridCell = (props) => {
    return (
        <div style={{
            border : '1px dashed',
            borderColor : props.lineColor,
            alignSelf : 'stretch',
            justifySelf : 'stretch'
        }}
        />
    )
}

class GridCellTarget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    componentDidMount() {
        const bounds = UIUtils.Utils.GetBounds(this);
        this.props.onRegisterCellTarget({
            bounds : bounds,
            ref : this
        })
    }
    render() { 
        return (
            <div style={{
                border : this.props.isOver ? '1px solid' : '1px dashed',
                borderColor : this.props.isOver ? '#ff5ae3' : '#4dbdff',
                alignSelf : 'stretch',
                justifySelf : 'stretch',
                zIndex : this.props.zIndex,
                opacity : this.props.active ? 1 : 0,
                pointerEvents : 'none',
                position : 'relative',
                color : 'yellow'
            }}
            >
                {this.props.children}
            </div>
        )
    }
}
