import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';
import styled, { css} from 'styled-components';
import {DOCUMENT_ITEMTYPES, DOCUMENT_TOKENTYPES} from '../../../../manager/documents';
import DesignSystemComponentsPanel from '../../../../panels/left/components';
import { SearchInput } from '../../../../searchbar';

export default class SelectDocumentComponent extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSearch = this.onSearch.bind(this);        
        this.CanceSearch = this.CanceSearch.bind(this);        
    }
    
    ComponentSelected(id) {
        Globals.ProjectManager.GetDocumentManager().SetItemType(this.props.id, DOCUMENT_ITEMTYPES.component);
        Globals.ProjectManager.GetDocumentManager().SetItemValue(this.props.id, id, 'id');
        if (this.props.forLayout)
            this.props.onNewItemAdded();
        else
            this.props.onUpdate();        
    }
    onSearch(filterText) {
        this.setState({FilterText : filterText});
    }
    CanceSearch() {
        this.setState({FilterText : null});
    }
    renderCustom() {
        return (
            <SC.FCol style={{
                lineHeight : 'unset',
                ...SC.Styles.Font(),
                ...this.props.style,
                marginTop : '16px'
            }}>
                <SC.FRow alc jsb>
                    <SC.BackClosableTitle title='SELECT COMPONENT' onClose={this.props.onClose} />    
                    <SearchInput
                        FilterText={this.state.FilterText}
                        Search={this.onSearch}
                        CancelSearch={this.CanceSearch}
                    /> 
                </SC.FRow>
                
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : 'repeat( auto-fit, minmax(210px, 1fr) )',
                    gridAutoRows : 'auto',
                    gridGap : '8px',
                    marginTop : '8px'
                }}>
                    <DesignSystemComponentsPanel 
                        justItems
                        selectable
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        onSelect={this.ComponentSelected.bind(this)}
                        filterText={this.state.FilterText}
                        renderEmpty={() => {
                            return (
                                <SC.Div_Flex_Cell
                                style={{
                                    backgroundColor : this.props.textStyles.backgroundHighlight,
                                    width : '100%',
                                    height : '200px'
                                }}
                                >
                                    You don't have any components yet.
                                </SC.Div_Flex_Cell>
                            )
                        }}
                    />
                </div>
            </SC.FCol>
        )
    }
}