import React from 'react';
import {
    SC,
    Globals,
    Utils,
    AppState,
    AppLayout
} from '../../../../../../../../importer';

import {TransitionCardItem, TransitionListItem, AddNewTransitionToken, GetTransitionTokenList } from '../../../../../left/designsystem/motion/transitions';
import TransitionTokenEditor from '../../../../../left/designsystem/motion/transitions/item';
import BaseSelectToken from '../../BaseSelectToken';
import SelectToken from '../..';
import { motion } from 'framer-motion';

export default class SelectTransitionToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW TRANSITION';
        this.moduleName = 'Transitions';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Transitions;        

       this.SelectEase = this.SelectEase.bind(this);
       this.SelectDuration = this.SelectDuration.bind(this);       
       this.SelectTransform = this.SelectTransform.bind(this);
       

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetTransitionTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Transition',
            items : [
                {
                    id : Utils.Id(),
                    type : 'scale',
                    locked : true
                }    
            ],
        };
        AddNewTransitionToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const TransitionItem = AddNewTransitionToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.Transitions && AppLayout.Refs.DesignSystem.Transitions.Reload();
            return TransitionItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Transitions, willDeleteId);
    } 
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const Transition = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        const Transitions = Utils.JustGet(Transition, [], 'items');
        // SelectedToken.value = Utils.GetTransitionCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    }
    GetStyleName() {
        return 'transition';
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();

        const transitions = [];
        Utils.ForEach(this.tokens, (transition, i) => {
            transitions.push(
                <TransitionListItem
                    key={transition.id}
                    notDraggable
                    listView={this.state.isListView}
                    transition={transition}
                    onSelect={this.SelectToken.bind(this, transition.id)}
                    selected={transition.id === this.GetSelectedTokenId()}
                    onPreview={{
                        onMouseEnter : this.PreviewToken.bind(this, true, transition.id, styleName),
                        onMouseLeave : this.PreviewToken.bind(this, false, transition.id, styleName)
                    }}
                />
            )
        });

        if (this.state.isListView) {            
            content = (
                <SC.FCol>
                    {transitions}
                </SC.FCol>
            )
        }
        else {
            content = (
                <div style={{
                    display : 'grid',
                    gridGap : '4px',
                    gridTemplateColumns : 'repeat(auto-fill, minmax(100px, 1fr))',
                    gridAutoRows : 'auto',
                }}>
                    {transitions}
                </div>
            )
        }

        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {content}
            </SC.CustomScrollbars>                             
        )       
    }
    renderTokenEditor() {
        return (
            <React.Fragment>
                <TransitionTokenEditor 
                    noHeader
                    id={this.EditingToken.Id}
                    isNew={this.EditingToken.IsNew}
                    newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                    GlobalState={Globals.ProjectManager.GlobalState}                      
                    offline={this.EditingToken.IsNew}
                    onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                    onSelectEase={this.SelectEase}
                    onSelectDuration={this.SelectDuration}
                    onSelectTransform={this.SelectTransform}
                />
                {
                    this.showTokenSelector &&
                    <motion.div 
                        style={{
                            ...SC.Styles.Absolute, 
                            zIndex : 999999999, 
                            right : '100%',
                            left : 'unset',
                            boxSizing : 'border-box',
                            width : '280px' ,
                            borderRight : SC.CurrentTheme.theme.border_ondark,
                            borderLeft : SC.CurrentTheme.theme.border_ondark,
                            backgroundColor : SC.CurrentTheme.theme.back,
                        }}
                        initial={{opacity : 0.7, x : 24}}
                        animate={{opacity : 1, x : 0}}
                        exit={{opacity : 0, x : 24}}
                        transition={{duration : 0.1}}
                    >
                        <SC.FCol fw fh>
                            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                                <SelectToken                                         
                                    onClose={this.CloseTokenSelector}
                                    onPreviewToken={this.PreviewToken}
                                    GlobalState={this.props.GlobalState}
                                    GlobalStateId={this.props.GlobalStateId}
                                    themeId={this.props.themeId}
                                    onSetPropertyValues={this.SetItemPropertyValues}
                                    onBindToModel={this.BindToModel}
                                    GetComponentManager={this.GetComponentManager}
                                    GetMetaItem={this.GetMetaItem}
                                    onClose={() => {
                                        delete this.showTokenSelector;
                                        this.RCUpdate();
                                    }}
                                    onClosed={() => {                                            
                                    }}
                                    {...this.showTokenSelector}
                                />
                            </SC.FCol>                    
                        </SC.FCol>
                    </motion.div>     
                }
            </React.Fragment>
        )        
    }
    SelectEase(onSelect) {
        this.showTokenSelector = {
            type : AppState.ItemTypes.BOARD.EASECURVE,
            title : 'EASE CURVE',
            onSelect : onSelect,            
        };            
        this.RCUpdate();
    }
    SelectDuration(onSelect) {
        this.showTokenSelector = {
            type : AppState.ItemTypes.BOARD.DURATION,
            title : 'DURATION',
            onSelect : onSelect
        };            
        this.RCUpdate();
    }
    SelectTransform(onSelect) {
        this.showTokenSelector = {
            type : AppState.ItemTypes.BOARD.TRANSFORM,
            title : 'TRANSFORM',
            onSelect : onSelect
        };            
        this.RCUpdate();
    }
    GetSelectedTokenId() {
        return this.props.transition;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
