import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    AppState,
    Events,
    SC,
    Loading,
    Globals,
    Strings,
    Links,
    Utils,
    UIUtils,
    MetaData
} from '../../../../../../importer';

import { TokenPanelHeader, PanelHeaderAddButton, GroupTitle } from '../../../left/designsystem/common';
import { ListCardView } from '../../../../../../components/light_dark_bar';
import { AnimatePresence, motion } from 'framer-motion';
import ComponentDesignManager from '../../../../component/manager';
import { IconButtonBox } from '../../../../../../components/AddCancelBar';
import AliaseEditor from '../../../left/designsystem/aliases/item';
import {AddNewTokenAliase} from '../../../left/designsystem/aliases';
import { LeftScrollPanel } from '../../../left/common';

export default class BaseSelectToken extends ReactBaseComponent 
{
    constructor(props) {
        super(props);

        this.hasAddButton = true;
        this.hasListMode = true;
        this.state.isListView = true;

        this.Close = this.Close.bind(this);
        this.AddToken = this.AddToken.bind(this);
        this.AddTokenAlias = this.AddTokenAlias.bind(this);        
        this.SearchToken = this.SearchToken.bind(this);
        this.ToggleSearchMode = this.ToggleSearchMode.bind(this);        
        this.SearchAliase = this.SearchAliase.bind(this);
        this.AddGroup = this.AddGroup.bind(this);
        this.SelectToken = this.SelectToken.bind(this);
        this.EditToken = this.EditToken.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.onCancelAddToken = this.onCancelAddToken.bind(this);
        this.onCloseTokenEditor = this.onCloseTokenEditor.bind(this);
        this.onSubmitAddToken = this.onSubmitAddToken.bind(this);
        this.PreviewToken = this.PreviewToken.bind(this);
        this.PreviewNewToken = this.PreviewNewToken.bind(this);
        this.CancelNewTokenPreview = this.CancelNewTokenPreview.bind(this);      
        this.renderInnerSelector = this.renderInnerSelector.bind(this);        
        
        this.titleEditToken = 'EDIT TOKEN';
    }
    componentDidMount() {
        super.componentDidMount();

        if (this.WillAddingFirstToken) {
            this.AddToken();
        }
        setTimeout(() => {
            this.isOpened = true;
        }, 200);
    }
    componentWillUnmount() {
        super.componentWillUnmount();

        if (this.EditingToken && this.EditingToken.IsNew) {
            this.SaveToken();
        }
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'RightPanel', 'DesignSystem', this.moduleName, 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    SetAliaseListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'ListView');
        this.setState({
            isAliaseListView : isListView
        })
    }
    Load(props) {
        this.state.isListView = Globals.ProjectManager.Options.Get(this.state.isListView, 'RightPanel', 'DesignSystem', this.moduleName, 'ListView');
        this.state.isAliaseListView = Globals.ProjectManager.Options.Get(this.state.isAliaseListView, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'ListView');
        this.state.isAliaseGroupView = Globals.ProjectManager.Options.Get(this.state.isAliaseGroupView, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'GroupView');
        this.state.isSemanticsCollapsed = Globals.ProjectManager.Options.Get(this.state.isSemanticsCollapsed, 'RightPanel', 'DesignSystem', this.moduleName, 'Aliases', 'Collapsed');
        this.isSearchMode = Globals.ProjectManager.Options.Get(this.isSearchMode, 'RightPanel', 'DesignSystem', this.moduleName, 'SearchMode');

        this.tokens = this.GetTokens(props);
        if (this.IsEmpty()) {
            this.WillAddingFirstToken = true;            
        }
        else if (this.props.tokenId) {
            
        }
    }
    GetTokens() {
        return [];
    }
    IsEmpty() {
        return this.tokens.length === 0;
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.tokenId !== nextProps.tokenId;
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (nextProps.Id !== this.props.Id) {
            if (this.EditingToken) {
                delete this.EditingToken;                                
            }
            willUpdate = true;
        }
        if (this.IsTokenPropChanged(nextProps, nextState))
            willUpdate = true;
        
        if (this.state.isListView !== nextState.isListView || this.state.isAliaseListView !== nextState.isAliaseListView || this.state.isSemanticsCollapsed !== nextState.isSemanticsCollapsed)
            willUpdate = true;
        
        return willUpdate || super.ShouldUpdate(nextProps, nextState);
    }
    Close() {
        this.isClosing = true;
        this.props.onClose();
    }
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }               
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }
    ToggleSearchMode() {
        this.isSearchMode = !this.isSearchMode;
        Globals.ProjectManager.Options.Set(this.isSearchMode, 'RightPanel', 'DesignSystem', this.moduleName, 'SearchMode');
    }
    SearchAliase(filter) {
        
    }
    AddToken() {
        const Id = Utils.Id();
        this.EditingToken = {
            IsNew : true,
            Id : Id            
        };
        this.LastBoundTokenId = this.GetSelectedTokenId();
        this.EditingToken.Model = this.GetNewModel(Id);
        this.PreEditToken && this.PreEditToken();
        this.PreviewToken(true, Id);
        this.RCUpdate();
    }
    AddTokenAlias() {
        const Id = Utils.Id();
        this.EditingToken = {
            IsNew : true,
            IsAlias : true,
            Id : Id            
        };
        this.LastBoundTokenId = this.GetSelectedTokenId();
        this.EditingToken.Model = {

        };
        this.PreEditToken && this.PreEditToken();
        this.PreviewToken(true, Id);
        this.RCUpdate();
    }
    AddGroup() {
        if (!Globals.ProjectManager.CheckGrant_AddTokenGroup(this.tokenType)) {
            return;
        }
        const name = 'New Group';
        const groupid = Globals.ProjectManager.Tokens.AddGroup({type : this.tokenType, name : name})
        const groups = Utils.Get(this, [], 'groups');
        if (groups.length === 0) {
            this.Load(true);
            this.RCUpdate();
            return;
        }
        groups.push({
            id : groupid,
            name : name,
            tokens : []
        })

        this.RCUpdate();
    }
    GetNewModel() {

    }
    SaveToken() {
        
    }
    SelectToken(tokenId, ...rest) {
        this.FireSelectToken(tokenId, ...rest);
        this.Close();
    }
    FireSelectToken(tokenId, ...rest) {
        if (this.props.onSelectForItem)
            this.props.onSelectForItem(this.props.Id, tokenId, [],...rest);
        else 
            this.props.onSelect(tokenId, ...rest);
    }
    GetSelectedTokenId() {

    }
    EditToken() {
        if (this.GetSelectedTokenId()) {
            this.EditingToken = {
                Id : this.GetSelectedTokenId()
            };
            this.PreEditToken();
            this.RCUpdate();
        }
    }
    PreEditToken() {
        
    }
    RemoveTokenBinding() {
        this.SelectToken(null);
    }
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew)
            this.CancelNewTokenPreview();
        else {
            this.UpdateSelectedToken();            
            delete this.EditingToken;                    
            this.props.onUpdate && this.props.onUpdate();
            if (this.DoNotCloseOnSave)
                this.RCUpdate();
            else
                this.Close();
        }
    }
    UpdateDesignSystemPanel() {

    }
    onCancelAddToken() {
        if (this.EditingToken && this.EditingToken.IsNew)
            this.CancelNewTokenPreview();
        else {
            this.UpdateSelectedToken();
        }
        delete this.EditingToken;
        if (!this.DoNotCloseOnSave && this.IsEmpty()) {
            this.Close();
            return;
        }
        this.RCUpdate();
    }
    UpdateSelectedToken() {
        
    }
    onSubmitAddToken() {
        if (this.EditingToken.IsAlias) {
            AddNewTokenAliase({
                type : this.tokenType,
                name : this.EditingToken.Model.name,
                id : this.EditingToken.Id,
                tokenId : this.EditingToken.Model.tokenId
            });
        }
        else {
            const NewToken = this.SaveToken();
            if (NewToken) {
                !this.hasCustomTokenList && this.tokens.push(NewToken);
                this.CancelNewTokenPreview();
                
                let ThemeValue = NewToken.value;
                const props = {};
                if (this.tokenType === Globals.ProjectManager.Tokens.Types.Images)
                    ThemeValue = {url : NewToken.url};
                else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Shadows) {
                    ThemeValue = {values : this.EditingToken.Model.values};
                    props.textShadow = this.EditingToken.Model.textShadow;
                }
                else if (this.tokenType === Globals.ProjectManager.Tokens.Types.Transforms) {
                    ThemeValue = {items : this.EditingToken.Model.items};
                }
                Globals.ProjectManager.Tokens.UpdateCurrentTheme({id : NewToken.id, value : ThemeValue, type : this.tokenType, ...props});
    
                delete this.EditingToken;
                this.props.onUpdate && this.props.onUpdate();
                this.SelectToken(NewToken.id);         
            }
        }        
    }
    PreviewToken(show, tokenId, propName, doNotUpdate) {  
        if (!this.isOpened && !this.AddingFirstToken || !this.props.onPreviewToken)
            return;
        this.AddingFirstToken = false;
        if (this.islosing)
            return;
        this.WillShowPreview = {
            show : show,
            tokenId : tokenId
        }
        this.PreviewingTokenId = show ? tokenId : null;
        setTimeout(() => {
            if (this.WillShowPreview) {                
                this.props.onPreviewToken({
                    propName : propName || this.GetStyleName(),
                    tokenId : this.WillShowPreview.tokenId,
                    show : this.WillShowPreview.show,
                    ParentNames : this.props.ParentNames
                });    
                delete this.WillShowPreview;
                if (!doNotUpdate)
                    this.RCUpdate();
            }
            
        }, 100); 
            
    }
    
    GetStyleName() {

    }
    // componentWillUnmount() {
    //     super.componentWillUnmount();
    //     if (this.PreviewingNewToken) {
    //         this.onSubmitAddToken();
    //     }
    // }
    PreviewNewToken(value) {        
        this.EditingToken && Globals.ProjectManager.Tokens.SetPreviewTokenValue(this.EditingToken.Id, value);
        this.PreviewingNewToken = this.EditingToken.Id;        
        if (this.props.onPreviewNewToken)
            this.props.onPreviewNewToken();
        else
            ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.EditingToken.Id);
    }
    CancelNewTokenPreview() {
        delete this.PreviewingNewToken;
        Globals.ProjectManager.Tokens.DeletePreviewToken(this.EditingToken.Id);
        this.PreviewToken(false, this.EditingToken.Id);
        if (this.props.onPreviewNewToken)
            this.props.onPreviewNewToken(true);
        else
            ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateItemPreviews(this.props.Id, false);
    }
    renderTokenList() {

    }
    renderTokenAliases() {

    }
    renderTokenAliaseListHeader() {

    }
    renderTokenEditor() {

    }
    renderSelectedTokenHeader(props = {onDelete : this.RemoveTokenBinding, onEdit : this.EditToken}) {
        return (
            <SC.FCol  style={{margin : '-8px', marginBottom : '4px', padding : '4px', paddingLeft : '8px', paddingRight : '6px', borderBottom : SC.CurrentTheme.theme.border_seperator}}>
                <SC.FRow alc jsb overflowHidden style={{minHeight : '26px'}}>
                    <SC.TextDivAbbr style={{flex : 1, fontSize : '11px'}}>{props.empty ? 'No Color' : props.name}</SC.TextDivAbbr>
                    {
                        !props.empty && this.renderSelectedTokenButtons(props)                                               
                    }
                </SC.FRow>
                {props.content}        
            </SC.FCol>            
        )
    }
    renderSelectedTokenButtons(props = {onDelete : this.RemoveTokenBinding, onEdit : this.EditToken}) {
        return (
            <SC.FRow>
                <IconButtonBox onClick={props.onEdit}>
                    <SC.Icons.Icon_Button hasFill hasCursor >
                        <SC.Icons.Icon_Edit size={16} />
                    </SC.Icons.Icon_Button>
                </IconButtonBox>
                <IconButtonBox onClick={props.onDelete || this.RemoveTokenBinding} style={{marginLeft : 0}}>
                    <SC.Icons.Icon_Button hasFill hasCursor>
                        <SC.Icons.Icon_Delete size={16} />
                    </SC.Icons.Icon_Button>
                </IconButtonBox>
            </SC.FRow>
        )
    }
    renderSelectedToken() {
        if (this.props.tokenId) {
            return (
                <SC.FCol style={{
                    padding : '8px'
                }}>
                    <button onClick={this.RemoveTokenBinding}>Remove Style</button>
                </SC.FCol>
            )
        }
    }
    renderHeader() {
        return (
            <TokenPanelHeader title={this.props.title} hasAddCancel onClose={this.Close} style={{padding : '2px'}}>
                                           
            </TokenPanelHeader>
        )
    }
    renderTokenListHeader() {
        return (
            <GroupTitle 
                title='TOKENS' 
                hasAdd={{onAdd : this.AddToken}} 
                hasSearch={{onSearch : this.SearchToken, onToggleSearchMode : this.ToggleSearchMode}}
                searchVisible={this.isSearchMode}
                addOn={(
                    <ListCardView 
                        onListView={this.SetListMode.bind(this, true)}
                        onCardView={this.SetListMode.bind(this, false)}
                        isListView={this.state.isListView}
                        isCardView={!this.state.isListView}
                        style={{marginRight : '4px'}}
                        compact
                    />
                )}
                style={{padding : 0, paddingLeft : '4px', marginBottom : '4px'}}
            />
        )
    }
    renderInnerSelector() {
    }
    renderCustom() {
        return (
            <SC.FCol f1>
                {
                    !this.EditingToken &&
                    <SC.FCol f1>
                        {!this.props.innerMode && this.renderHeader()}
                        <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px'}}>
                            {!this.props.innerMode && this.renderSelectedToken()}   
                            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                                {this.hasAliases && this.renderTokenAliaseListHeader()}
                                {this.hasAliases && this.renderTokenAliases()}
                                {this.renderTokenListHeader()}                         
                                {this.renderTokenList()}
                            </SC.CustomScrollbars>                            
                        </SC.FCol>                    
                    </SC.FCol>    
                }
                
                <AnimatePresence>
                    {
                        this.EditingToken && !this.EditingToken.IsAlias && this.Mounted && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                            initial={{opacity : 0.7, x : 24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : 24}}
                            transition={{duration : 0.2}}
                        >
                            {
                                !this.HideEditorHeader && 
                                <TokenPanelHeader 
                                    notBackClosable={this.EditingToken.IsNew}
                                    title={this.EditingToken.IsNew ? this.titleNewToken : this.titleEditToken} 
                                    hasAddCancel={this.EditingToken.IsNew}
                                    onClose={this.onCloseTokenEditor} 
                                    onCancel={this.EditingToken.IsNew ? this.onCancelAddToken : null} 
                                    onAdd={this.EditingToken.IsNew ? this.onSubmitAddToken : null} 
                                    style={{padding : '2px'}}
                                />
                            }                            
                            {this.renderTokenEditor()}
                        </motion.div>
                    }
                </AnimatePresence>
                <AnimatePresence>
                    {
                        this.EditingToken && this.EditingToken.IsAlias && this.Mounted && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                            initial={{opacity : 0.7, x : 24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : 24}}
                            transition={{duration : 0.2}}
                        >
                            {
                                !this.HideEditorHeader && 
                                <TokenPanelHeader 
                                    notBackClosable={this.EditingToken.IsNew}
                                    title={this.EditingToken.IsNew ? this.titleNewToken : this.titleEditToken} 
                                    hasAddCancel={this.EditingToken.IsNew}
                                    onClose={this.onCloseTokenEditor} 
                                    onCancel={this.EditingToken.IsNew ? this.onCancelAddToken : null} 
                                    onAdd={this.EditingToken.IsNew ? this.onSubmitAddToken : null} 
                                    style={{padding : '2px'}}
                                />
                            }                            
                            <LeftScrollPanel>
                                <AliaseEditor 
                                    type={this.tokenType}
                                    onSelectColor={this.SelectColorToken}
                                    isNew={this.EditingToken.IsNew}
                                    id={this.EditingToken.Id}
                                    aliase={this.EditingToken.Model}
                                />
                            </LeftScrollPanel>  
                        </motion.div>                       
                    }
                </AnimatePresence>
                {this.renderInnerSelector()}
            </SC.FCol>            
        )
    }
}