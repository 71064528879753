import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    UIUtils
} from '../../../../importer';
import {Grid, PreviewBox, QuickBrown, FontSelection_Preview} from './common';
import FontStyleSheetManager from '../../../../db/FontStyleSheetManager';

export default class WebSafeFonts extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    SelectFont(font) {
        this.props.onSelectFont({
            provider : Strings.FONT_WEBSAE,
            id : font.Id,
            name : font.Name,
            family : font.Name,
            fontStack : font.Family,
            weight : this.props.fontWeight
        });
    }
    Preview(active, font) {
        if (this.props.onPreview) {
            if (active) {
                this.props.onPreview({
                    provider : Strings.FONT_WEBSAE,
                    id : font.Id,
                    name : font.Name,
                    family : font.Family,
                    weight : this.props.fontWeight
                });
            }
            else
                this.props.onPreview();
        }                    
    }
    renderCustom() {

        let Search = null;
        if (Utils.IsNotNullOrEmpty(this.props.FilterText)) {
            Search = this.props.FilterText.toUpperCase();
        }
        const FontList = FontStyleSheetManager.WebSafeFonts.List();
        let count = 0, visible = 0;
        return FontList.map((font, i) => {
            if (i === 0 && !this.SelectedPreview)
                this.SelectedPreview = font;
            if (Search) {
                if (font.Name.toUpperCase().indexOf(Search) < 0) {
                    return null;
                }
            }

            return (
                <PreviewBox
                    key={i}
                    name={font.Name}
                    SelectFont={this.SelectFont.bind(this, font)}
                    onMouseEnter={this.props.onPreview ? this.Preview.bind(this, true, font) : null}
                    onMouseLeave={this.props.onPreview ? this.Preview.bind(this, false) : null}
                    {...this.props}
                >
                    <FontSelection_Preview dark={this.props.dark} style={{fontSize : this.props.fontSize}} >
                        <span style={{
                            fontFamily : font.Family,
                            fontWeight : this.props.fontWeight || 'normal'
                        }}>
                            {this.props.previewText || QuickBrown}
                        </span>
                    </FontSelection_Preview>
                </PreviewBox>
            )
        });       
    }
}
