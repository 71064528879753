import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import TextContentEditor from '../../../../left/designsystem/textContent/item';
import { TokenPanelHeader, PanelHeaderAddButton, GroupTitle } from '../../../../left/designsystem/common';

export default class EditTextStyle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }        
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.itemId !== nextProps.itemId) {
            
        }
        return true;
    }
    render() {         
        return (  
            <motion.div 
                style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                initial={{opacity : 0.7, x : 24}}
                animate={{opacity : 1, x : 0}}
                exit={{opacity : 0, x : 24}}
                transition={{duration : 0.2}}
            >
                <TokenPanelHeader 
                    title={this.props.title || 'COLOR'} 
                    onClose={this.props.onClose} 
                    style={{padding : '2px'}}
                />                           
                <SC.CustomScrollbars>
                    <TextContentEditor 
                        ref={this.Ref_Editor}
                        onPreviewChange={this.props.onChange}
                        justEditor
                        id={this.props.tokenId}
                        itemId={this.props.itemId}
                        GlobalState={this.props.GlobalState}
                        StateArray={this.props.StateArray}
                        noHeader
                    />
                </SC.CustomScrollbars>
            </motion.div>
        );
    }
}