import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    AppState,
    Globals
} from '../../../../../../../importer';
import { TokenGroup, TokenItemBox } from '../../common';

import BaseGroup from '../base';
import { StyleToken_Color, PropertyLabel, StyleGroupTitle, SmallButtonGroup } from '../../../../right/iteminspector/styleitems/common';
import { FlexDirectionSelector } from '../../../../right/iteminspector/styleitems/layout';
import { FlexJustifyItems, FlexAlignItems } from '../../../../right/iteminspector/styleitems/layout/flex';

export default class Containers extends BaseGroup
{
    constructor(props) {
        super(props);        
        
        this.title = 'FLEX BOX';
        this.DefaultId = Globals.ProjectManager.Templates.DefaultTemplateId(this.elementType);

        this.SelectColor = this.SelectColor.bind(this);
        
        this.LoadOptions();        
    }
    SelectColor(templateId, propName, title, colorId, backgroundTokenId) {
        let onBackgroundColor;        
        if (backgroundTokenId) 
            onBackgroundColor = Globals.ProjectManager.Tokens.GetStateValue({Id : backgroundTokenId, StateArray : this.props.StateArray});
        let props = {};
        if (backgroundTokenId) {
            props = {
                text : true,
                onBackgroundColor : onBackgroundColor,
                hasAccessibility : true
            };
        }
        props[propName] = colorId;

        this.SelectToken({templateId : templateId, propName : propName, type : AppState.ItemTypes.BOARD.COLOR, title : title,
            props : props
        });
    }

    SetFlexValue(templateId, prop, value) {
        Globals.ProjectManager.Templates.SetValue({id : templateId, value : value, name : prop, type : this.elementType});
        this.RCUpdate();
    }
 
    renderDefault() {
        const tokenIdbackgroundColor = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'backgroundColor'});
        const tokenIdcolor = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'color'});
        const flexDirection = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'flexDirection'});
        const justifyContent = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'justifyContent'});
        const alignItems = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'alignItems'});
        const vertical = flexDirection === 'column' || flexDirection === 'column-reverse';
        const flexWrap = Globals.ProjectManager.Templates.GetStateValue({Id : this.DefaultId,  name : 'flexWrap'}) === 'wrap';

        return (
            <SC.FCol style={{padding : '8px'}}>
                {tokenIdbackgroundColor && <PropertyLabel>Background Color</PropertyLabel>}
                <StyleToken_Color 
                    style={{marginTop : 0, marginBottom : '4px'}}
                    tokenId={tokenIdbackgroundColor} 
                    onSelect={this.SelectColor.bind(this, this.DefaultId, 'backgroundColor', 'BACKGROUND COLOR', tokenIdbackgroundColor)} 
                    label='Background'
                />
                {tokenIdcolor && <PropertyLabel>Text Color</PropertyLabel>}
                <StyleToken_Color 
                    style={{marginTop : 0}}
                    label='Text Color'
                    tokenId={tokenIdcolor} 
                    onSelect={this.SelectColor.bind(this, this.DefaultId, 'color', 'TEXT COLOR', tokenIdcolor, tokenIdbackgroundColor)} 
                />
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <StyleGroupTitle>Direction</StyleGroupTitle>    
                    <SC.FRow style={{width  :'185px'}}>
                        <FlexDirectionSelector
                            flexDirection={flexDirection}
                            onSelect={this.SetFlexValue.bind(this, this.DefaultId, 'flexDirection')}
                        /> 
                    </SC.FRow>                    
                </SC.FRow>
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <PropertyLabel>Justify</PropertyLabel>
                    <SC.FRow>
                        <FlexJustifyItems 
                            fd={flexDirection}
                            alit={alignItems}
                            value={justifyContent}
                            vertical={vertical}
                            onChange={this.SetFlexValue.bind(this, this.DefaultId, 'justifyContent')}
                        />
                    </SC.FRow>                    
                </SC.FRow>                
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <PropertyLabel>Align</PropertyLabel>
                    <SC.FRow jsb style={{width : '185px'}}>
                        <SC.FRow jsb>
                            <FlexAlignItems 
                                fd={flexDirection}
                                jct={justifyContent}
                                value={alignItems}
                                vertical={vertical}
                                onChange={this.SetFlexValue.bind(this, this.DefaultId, 'alignItems')}
                            />
                        </SC.FRow>                        
                        <SmallButtonGroup
                            hasBorders size={20} 
                            onClick={this.SetFlexValue.bind(this, this.DefaultId, 'flexWrap', flexWrap ? 'no-wrap' : 'wrap')}
                            selected={flexWrap} 
                            title={`Flex Wrap`}
                            style={{width : '28px', height : '28px'}}
                        >
                            <path fill={ flexWrap ? SC.CurrentTheme.theme.color_brand : null} d="M9.29289322,18 L5.5,18 C4.11928813,18 3,16.8807119 3,15.5 L3,9.5 C3,8.11928813 4.11928813,7 5.5,7 L9.5,7 C9.77614237,7 10,7.22385763 10,7.5 C10,7.77614237 9.77614237,8 9.5,8 L5.5,8 C4.67157288,8 4,8.67157288 4,9.5 L4,15.5 C4,16.3284271 4.67157288,17 5.5,17 L9.29289322,17 L7.14644661,14.8535534 C6.95118446,14.6582912 6.95118446,14.3417088 7.14644661,14.1464466 C7.34170876,13.9511845 7.65829124,13.9511845 7.85355339,14.1464466 L10.8535534,17.1464466 C11.0488155,17.3417088 11.0488155,17.6582912 10.8535534,17.8535534 L7.85355339,20.8535534 C7.65829124,21.0488155 7.34170876,21.0488155 7.14644661,20.8535534 C6.95118446,20.6582912 6.95118446,20.3417088 7.14644661,20.1464466 L9.29289322,18 Z M13.5,8 C13.2238576,8 13,7.77614237 13,7.5 C13,7.22385763 13.2238576,7 13.5,7 L20.5,7 C20.7761424,7 21,7.22385763 21,7.5 C21,7.77614237 20.7761424,8 20.5,8 L13.5,8 Z M13.5,13 C13.2238576,13 13,12.7761424 13,12.5 C13,12.2238576 13.2238576,12 13.5,12 L20.5,12 C20.7761424,12 21,12.2238576 21,12.5 C21,12.7761424 20.7761424,13 20.5,13 L13.5,13 Z M13.5,18 C13.2238576,18 13,17.7761424 13,17.5 C13,17.2238576 13.2238576,17 13.5,17 L20.5,17 C20.7761424,17 21,17.2238576 21,17.5 C21,17.7761424 20.7761424,18 20.5,18 L13.5,18 Z"/>
                        </SmallButtonGroup>
                    </SC.FRow>
                </SC.FRow>
            </SC.FCol>
        )
    }
    renderTemplates() {
        const templateIds = Globals.ProjectManager.Templates.Order(this.elementType);
        const templateItems = [];
        Utils.ForEach(templateIds, (templateId, i) => {
            if (templateId !== this.DefaultId) {
                const template = Globals.ProjectManager.Templates.Template(templateId);
                templateItems.push(
                    <TokenItemBox>
                        <SC.FRow>
                            {template.name}
                        </SC.FRow>
                    </TokenItemBox>                
                )
            }            
        });
        return (
            <SC.FCol style={{padding : '8px'}}>
                {templateItems}
            </SC.FCol>
        )
    }
}