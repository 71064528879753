import { Utils } from '../../toolabs-importer';
import Events from '../Events';
import Globals from '../Globals';
import AppState from '../AppState';

export const LOG_TYPES = {
    DELETE: 'CD',
    ADD_CHILD: 'CAC',
    ADD_ARTBOARD: 'CAAB',
    MOVE_CHILD : 'MOVC',
    ADD_ROOT: 'CARI',
    SET_STYLE: 'CSS',
    SET_PROP: 'CSP',
    SET_POSITION: 'CSPS',
    SET_SIZE: 'CSSS',
    SET_BINDING_STYLE: 'CSSBS',
    MODEL_ADD : 'MDLADD',
    MODEL_DELETE : 'MDLDEL',
    MODEL_CHANGE : 'MDLCNG',
    STATE_CHANGE : 'STCH',
    EVENTS : 'CECH',
    WRAP : 'CWR',
    CHANGE_ITEMTYPE : 'CCIT'
};

export default class HistoryManager
{
	constructor() {
        this.Clear();
    }
    Clear() {
        this.prev = [];
        this.next = [];
        this.LogList = {};
    }
    Clone() {
        return {
            prev : Utils.DeepClone(this.prev),
            next : Utils.DeepClone(this.next),
            LogList : this.LogList
        };
    }
    Load(data) {
        this.prev = data.prev;
        this.next = data.next;
        this.LogList = data.LogList;
    }
    HasPrev() {
        return this.prev.length > 0;
    }
    HasNext() {
        return this.next.length > 0;
    }
    GetLastLog() {
        let dir = 'prev';
        if (this.InUndo)
            dir = 'next';
        const logs = Utils.Get(this, [], dir);
        if (logs.length > 0) {
            let logid = null;
            if (this.InUndo)
                logid = logs[0];
            else
                logid = logs[logs.length-1];
            return this.LogList[logid];
        }
    }
    GetData() {
        return {};
    }
    Log(Info, Data, Direction) {
        const LogLength = 20;
        if (this.SuspendForce || this.Suspend)
            return;
        
        const Change = {
            Info : Info,
            Data : Utils.DeepClone(Data)
        };
        const logid = Utils.Id();
        const logs = Utils.Get(this, [], Direction || 'prev');
        let removed = null;
        if (Direction === 'next') {
            logs.splice(0, 0, logid);
            const remove = logs.length - LogLength;
            if (remove > 0) {
                removed = logs.splice(LogLength-1, remove);
            }
        }
        else {
            logs.push(logid);
            const remove = logs.length - LogLength;
            if (remove > 0)
                removed = logs.splice(0, remove);
        }
        if (removed) {
            Utils.ForEach(removed,(rid, i) => {
                delete this.LogList[rid];
            });
        }
        Utils.Set(this, Change, 'LogList', logid);

        if (Direction !== 'next' && !this.ForRedo) {
            const logs_next = Utils.Get(this, [], 'next');
            if (logs_next) {
                Utils.ForEach(logs_next, (nextid) => {
                    delete this.LogList[nextid];
                });
                this.next = [];
            }
        }
        Events.BCE(Events.DESIGNER.COMPONENT.HISTORY.LOG);
        return Change;
    }
    Broadcast(options) {

    }
    GetCurrentData(PrevData) {
        if (PrevData.IsComponent) {
            if (PrevData.ViewId) {
                return {
                    IsComponent : true,
                    Id : PrevData.Id, 
                    ViewId : PrevData.ViewId,
                    Data : Globals.ProjectManager.GetPrototypeViewData(PrevData.Id, PrevData.ViewId)
                }
            }
            else {
                return {
                    IsComponent : true,
                    Id : PrevData.Id, 
                    Data : Globals.ProjectManager.GetComponentData(PrevData.Id)
                }
            }            
        }
        else if (PrevData.TokenChange) {
            return Globals.ProjectManager.GetTokenChangeLogData();
        }
        else if (PrevData.Full) {
            return PrevData.Data;
        }
    }
    Undo() {
        this.InUndo = true;
        const logs = Utils.Get(this, [], 'prev');
        if (logs.length > 0) {
            const logid = logs[logs.length - 1];
            logs.splice(logs.length - 1, 1);
            this.prev = logs;
            const log = Utils.Get(this, null, 'LogList', logid);
            delete this.LogList[logid];
            if (log) {
                let Data;
                if (log.Data) {
                    Data = this.GetCurrentData(log.Data);
                }
                const NextLog = this.Log(log.Info, Data, 'next');
                if (log.Relations) {
                    NextLog.Relations = Utils.DeepClone(log.Relations);
                    Utils.ForEach(NextLog.Relations, (Relation, ) => {
                        if (Relation.Type === 'Add')
                            Relation.Type = 'Delete';
                        else
                            Relation.Type = 'Add';
                    });
                }
                this.RestoreRelations(log);
                this.ApplyChange(log, true);
            }
        }
        this.InUndo = false;
    }
    Redo() {
        const logs_next = Utils.Get(this, [], 'next');
        if (logs_next && logs_next.length > 0) {
            const logid = logs_next[0];
            logs_next.splice(0, 1);
            const log = Utils.Get(this, null, 'LogList', logid);
            delete this.LogList[logid];
            if (log) {
                this.ForRedo = true;
                let Data;
                if (log.Data) {
                    Data = this.GetCurrentData(log.Data);
                }
                const NextLog = this.Log(log.Info, Data);
                if (log.Relations) {
                    NextLog.Relations = Utils.DeepClone(log.Relations);
                    Utils.ForEach(NextLog.Relations, (Relation, ) => {
                        if (Relation.Type === 'Add')
                            Relation.Type = 'Delete';
                        else
                            Relation.Type = 'Add';
                    });
                }
                this.RestoreRelations(log);

                this.ForRedo = false;
                this.ApplyChange(log);
            }
        }
    }
    RestoreRelations(log) {
        if (log.Relations) {
            Utils.ForEach(log.Relations,(Relation) => {
                if (Relation.Type === 'Add') {
                    Globals.ProjectManager.RelationManager.DeleteRelationId(Relation.Id, true);
                }
                else if (Relation.Type === 'Delete') {
                    Globals.ProjectManager.RelationManager.AddRelationWithId(Relation.Data.OwnerId, Relation.Data.ConsumerId, Relation.Data.TargetId, Relation.Data, null, Relation.Id, true);
                }
            });
        }
    }        
    ApplyChange(Change, IsUndo) {
        if (Change.Data) {
            if (Change.Data.IsComponent) {
                if (Change.Data.ViewId) {
                    Globals.ProjectManager.ReplacePrototypeViewData(Change.Data.Id, Change.Data.ViewId, Change.Data.Data);
                }
                else {
                    Globals.ProjectManager.ReplaceComponentData(Change.Data.Id, Change.Data.Data);
                }                        
                Events.BCE(Events.DESIGNER.COMPONENT.HISTORY.CHANGED, IsUndo, Change.Info.Desc);        
                Events.BCE(Change.Data.Id, Events.PARAMS.REFRESH);
            }            
        }                
    }
}