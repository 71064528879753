import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    Strings,
    SC
} from '../../../../../importer';
import ToolbarIcons from '../../../../../styled/toolbar';
import FlexBox from './container/flexbox';
import { onConstructItem, onItemShouldUpdate, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onGetItemProps } from './common';

export default class Image extends FlexBox {
    constructor(props) {
        super(props);
    }
    UpdateStyle(props) {
        super.UpdateStyle(props);
        this.renderData.style.display = 'flex';
        this.renderData.style.justifyContent = 'center';
        this.renderData.style.alignItems = 'center';
    }       
    renderContent() {
        if (!this.renderData.style.backgroundImage) {
            return (
                <SC.Div_Flex_Cell fw fh 
                    style={{
                        border : '1px dashed #636469',
                        minWidth : '44px',
                        height : '44px'
                    }}
                    cursorPointer
                >
                    <ToolbarIcons.Image style={{width : '48px'}}/>
                </SC.Div_Flex_Cell>                
            )
        }
    }
}
 