import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    Globals,
    Utils
} from '../../../../../../importer';

import { TokenPanelHeader, GroupTitle, TokenItemBox } from '../../../left/designsystem/common';
import { GetDataApi, GetUserId } from '../../../../../../appstate/AppState';
import UserAvatar from 'react-user-avatar';
import { DotMenu } from '../../../left/common';
import { MemberAvatar } from '../members';


export default class TeamEditors extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        super.ListenEvents(
            Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED
        );

        this.ActiveUserCount = Globals.ProjectManager.SessionManager.GetActiveUserCount();
    }
    componentDidMount() {
        super.componentDidMount();
        this.LoadTeam();
    }
    LoadTeam() {
        GetDataApi().get_team_data(Globals.ProjectManager.TeamId).then((result) => {
            this.team = result;
            if (result.members && result.owner)
                result.members.splice(0, 0, result.owner);
            this.setState({loading : false});
        })
    }    
    OnEvent(Event, Params) {
        if (Event === Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED) {
            [this.ActiveUserCount] = Params;
        }
        this.RCUpdate();
    }    
    renderCustom() {

        let editors = [];
        if (Globals.ProjectManager.SessionManager && this.team) {            
            if (this.state.showMembers) {
                const users = Globals.ProjectManager.SessionManager.GetActiveUsers();
                users.map(({editor, id, name, uid}) => {
                    const user = Utils.Find(this.team.members, (u) => {return u.uid === uid});
                    if (user) {
                        editors.push(
                            <TeamUser 
                                key={id}
                                id={id}
                                uid={uid}
                                name={name}                                                
                                avatar={user.avatar}
                                email={user.email}
                                editor={editor}
                            />
                        )
                    }                    
                })

                if (editors.length === 0) {
                    editors = <div style={{paddingLeft : '24px', paddingBottom : '8px'}}>Only You!</div>
                }  
            }                      
        }
        return(
            <SC.FCol style={{paddingTop : '8px'}}>
                <GroupTitle 
                    noPadding
                    title={'ACTIVE USERS ' + (this.ActiveUserCount > 0 ? (this.ActiveUserCount + 1) : '')}
                    expandable={{expanded : this.state.showMembers, onExpand : () => {this.setState({showMembers : !this.state.showMembers})}}}
                />  
                {editors}                                                            
            </SC.FCol>
        );
    }
}

class TeamUser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }    
    render() { 
        const {id, name, email, active, avatar, editor} = this.props;
        return (  
            <SC.HoverParent 
                defaultStyle={{
                    display : 'flex',
                    alignItems : 'center',
                    gridTemplateColumns : 'auto 1fr',
                    gridGap : '8px',
                    padding : '8px',
                    borderRadius : '4px',
                    transition : 'all 0.2s ease',
                    fontSize : '11px'
                }}
                hoverStyle={{
                    backgroundColor : SC.CurrentTheme.theme.back_lighter
                }}
            >
                <MemberAvatar avatar={avatar} noState name={name} />
                <SC.TextDivAbbr style={{flex : 1}}>{name || email}</SC.TextDivAbbr>
                {
                    editor === 'figma' && <SC.Icons.Figma size={10}/>
                }
            </SC.HoverParent>
        );
    }
}