import React from 'react';
import {
    ReactBasicComponent,
    SC,
    UIUtils,
    AppState,
    Links,
    AppLayout,
    Events
} from '../../importer';

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AccountPanel } from '../account';
import UserAvatar from 'react-user-avatar';

class HoC_AccountPopup extends ReactBasicComponent
{
    constructor(props) {
        super(props);
    }    
    ShowAccount() {
        Events.BCE(Events.ACCOUNT.SETTINGS);
        return;
        AppState.OverlayManager.Show(            
            <AccountPanel />,
            {
                title : 'Account Settings'
            }
        )
    }
    renderCustom() {
        const backcolor = SC.CurrentTheme.theme.isLight ? '#2c2d30' : '#ebebeb';
        
        return (
            <motion.div 
                style={{
                    position : 'fixed',
                    top : '48px',
                    right : '13px',
                    padding : '8px',
                    borderRadius : '2px',
                    backgroundColor : backcolor,
                    zIndex : 9999999999,
                    ...this.props.style    
                }}
                initial={{ y : -10}}
                animate={{ y : 0}}
                transition={{duration : 0.2}}
            >
                <div style={{
                    width: 0,
                    height: 0,
                    borderStyle : 'solid',
                    borderWidth : '0 6px 12px 6px',
                    borderColor : `transparent transparent ${backcolor} transparent`,
                    position : 'absolute',
                    bottom : '100%',
                    right : '7px'
                }} />
                {
                    this.props.content || 
                    <React.Fragment>
                        {
                            AppLayout.isDevelopment && this.props.BoardId && <AccountMenuItem onClick={this.ShowAccount.bind(this)}>
                                Account Popup
                            </AccountMenuItem>
                        }
                        <Links.Link To={Links.Main_Account()}>
                            <AccountMenuItem>
                                Account
                            </AccountMenuItem>
                        </Links.Link>                           
                        <AccountMenuItem onClick={() => {AppState.ShowChatPanel()}}>
                            Support
                        </AccountMenuItem>                            
                        <AccountMenuItem onClick={() => {AppState.Logout()}}>
                            Logout
                        </AccountMenuItem>
                    </React.Fragment>
                }
                
            </motion.div>
        )      
    }
}

const AccountPopup =  UIUtils.OutSideClickHandler(HoC_AccountPopup);

export default class AccountButton extends React.Component
{
    constructor(props) {
        super(props);
        this.state = {};
        this.Toggle = this.Toggle.bind(this);
    }
    Toggle() {
        this.setState({open : !this.state.open});
    }    
    render() {
        return (
            <React.Fragment>
                <div style={{
                        minWidth : '28px',
                        minHeight : '28px',
                        marginRight : this.props.compact ? 0 : '12px',
                        // border : '1px solid #D3D3D3',
                        boxSizing : 'border-box',
                        borderRadius : '50%',
                        cursor : 'pointer',
                        position : 'relative',
                        fontWeight : 'bold',
                        color : '#fff',
                        fontSize : '10px'
                    }}
                    onClick={this.Toggle}
                >           
                    <UserAvatar 
                        src={Utils.JustGet(AppState.UserInfo, '', 'pictureUrl')}
                        name={Utils.JustGet(AppState.UserInfo, 'User', 'username')}
                        size="28"
                        // color='#464646'
                    />         
                    {
                        this.state.open ? <AccountPopup style={this.props.style} BoardId={this.props.BoardId} content={this.props.content} onClose={this.Toggle} /> : null
                    }
                </div>
            </React.Fragment>
            
        )
    }
}

export const AccountMenuItem = styled.div`
    color : ${props => props.theme.isLight ? SC.themes[AppLayout.Theme.Dark].font_hover : SC.themes.Light.font};
    font-size : 14px;
    font-weight : ${props => props.theme.isLight ? 300 : 500};
    padding-top : 10px;
    padding-bottom : 10px;
    padding-left : 16px;
    padding-right : 16px;
    transition : all 0.2s ease;
    cursor : pointer;
    &:hover {
        color : ${props => props.theme.isLight ? SC.themes[AppLayout.Theme.Dark].font_reversed : SC.themes.Light.font_hover};
        background-color : ${props => props.theme.isLight ? SC.themes[AppLayout.Theme.Dark].back : SC.themes.Light.back_lighter};
    }
`;