import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals
} from '../../../importer';

import styled, {css} from 'styled-components';
import InputSlider from '../input_slider';
import DropDownSelect from '../enum_dropdown';
import ComponentSizeEditor from '../size_component';
import { TRANSFORMTYPEITEMS } from '.';
import SizeEditor from '../../../views/project/panels/right/iteminspector/styleitems/size';

const Label = (props) => (<div style={{paddingLeft : '8px'}}>{props.children}</div>)

class TransformEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);   
        this.ChangeLogged = true;

        this.ToggleLock = this.ToggleLock.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
    }
    onChangeType(type) {
        this.props.transform.type = type;
        if (type === 'scale')
            this.props.transform.locked = true;
        this.props.onReset(true);
        this.props.onChange(this.props.transform);
    }        
    ToggleLock() {
        this.props.transform.locked = !this.props.transform.locked;
        this.props.onChange(this.props.transform);
    }
    onChangingValue(prop, value) {
        Utils.Set(this.props.transform, value, prop);
        this.props.onRefresh();
        this.RCUpdate();
    }
    onChangeValue(prop, value) {
        Utils.Set(this.props.transform, value, prop);
        this.props.onChange(this.props.transform);
    }
    onChangeSize(prop, size) {
        Utils.Set(this.props.transform, size.value, prop, 'value');
        Utils.Set(this.props.transform, size.unit, prop, 'Unit');
        this.props.onChange(this.props.transform);
    }    
    onChangingScale(prop, value) {        
        if (this.props.transform.locked) {
            Utils.Set(this.props.transform, value / 100, 'scaleX');
            Utils.Set(this.props.transform, value / 100, 'scaleY');
        }
        else 
            Utils.Set(this.props.transform, value / 100, prop);
        this.props.onRefresh();
    }
    onDeleteProp(prop) {
        Utils.UnSet(this.props.transform, prop);
        this.props.onChange(this.props.transform);
    }
    onChangeScale(prop, value) {
        this.props.onReset(true);
        this.onChangingScale(prop, value);
        this.props.onChange(this.props.transform);
    }
    renderCustom() {
        const {transform, notFirst} = this.props;
        
        const options = {
            sliderProps : {
                horizontal : true,
                reverse : true,
                inputStyle : {fontSize : '12px', width : '20px'}
            },
            row : {
                marginBottom : '4px'
            },
            span : {
                width : '60px'
            }
        }
        let value;

        let editor;

        const transformTypes = Utils.DeepClone(this.props.transformTypes);
        const currentTransformType = Utils.Find(TRANSFORMTYPEITEMS, (item) => {return item.id === transform.type});
        if (currentTransformType)
            transformTypes.push(currentTransformType);
        if (transform.type === 'translate') {            
            editor = (
                <SC.FCol>
                    <SC.FRow alc jsb style={{marginBottom : '4px'}}>
                        <Label>Horizontal</Label>
                        <SizeEditor 
                            noNone
                            value={Utils.JustGet(transform.x, null, 'value')}
                            unit={Utils.JustGet(transform.x, Utils.Defaults.Unit(), 'Unit')}
                            onChange={this.onChangeSize.bind(this, 'x')}
                            onChanging={this.onChangeSize.bind(this, 'x')}
                            onDelete={this.onDeleteProp.bind(this, 'x')}
                        />
                    </SC.FRow>
                    <SC.FRow alc jsb>
                        <Label>Vertical</Label>
                        <SizeEditor 
                            noNone
                            value={Utils.JustGet(transform.y, null, 'value')}
                            unit={Utils.JustGet(transform.y, Utils.Defaults.Unit(), 'Unit')}
                            onChange={this.onChangeSize.bind(this, 'y')}
                            onChanging={this.onChangeSize.bind(this, 'y')}
                            onDelete={this.onDeleteProp.bind(this, 'y')}
                        />
                    </SC.FRow>
                </SC.FCol>
            )
        }
        else if (transform.type === 'rotate') {            
            editor = (
                <SC.FCol>
                    {
                        [{id : 'rotateZ', label : 'Rotate 2D'}, {id : 'rotateX', label : 'Rotate 3D-x'}, {id : 'rotateY', label : 'Rotate 3D-y'}].map((rotate, i) => {
                            return (
                                <SC.FCol key={rotate.id} alignStretch style={{marginBottom :  i > 1 ? 0 : '4px'}}>
                                    <Label>{rotate.label}</Label>
                                    <InputSlider 
                                        horizontal 
                                        small 
                                        reverse                                 
                                        postFix={LabelDegree}
                                        value={Utils.JustGet(transform, null, rotate.id)}
                                        min={-180}
                                        max={180}
                                        allowOverflow
                                        onChange={this.onChangeValue.bind(this, rotate.id)}
                                        onChanging={this.onChangingValue.bind(this, rotate.id)}
                                        inputStyle={{fontSize : '12px', marginLeft : '-8px'}}
                                    />
                                </SC.FCol>
                            )
                        })
                    }
                </SC.FCol>
            )
        }
        else if (transform.type === 'scale') {            
            editor = (
                <SC.FCol>
                    {
                        [{id : 'scaleX', label : 'Horizontal'}, {id : 'scaleY', label : 'Vertical'}].map((scale, i) => {
                            return (
                                <SC.FCol key={scale.id} alignStretch style={{marginBottom :  i > 0 ? 0 : '4px'}}>
                                    <Label>{scale.label}</Label>
                                    <InputSlider 
                                        horizontal 
                                        small 
                                        reverse                                 
                                        postFix='%'
                                        value={Utils.UseNullOrEmpty(Utils.JustGet(transform, null, scale.id), 1) * 100}
                                        min={0}
                                        max={300}
                                        allowOverflow
                                        onChange={this.onChangeScale.bind(this, scale.id)}
                                        onChanging={this.onChangingScale.bind(this, scale.id)}
                                        inputStyle={{fontSize : '12px', marginLeft : '-8px'}}
                                    />
                                </SC.FCol>
                            )
                        })
                    }
                </SC.FCol>
            )
        }
        else if (transform.type === 'skew') {            
            editor = (
                <SC.FCol>
                    {
                        [{id : 'skewX', label : 'Horizontal'}, {id : 'skewY', label : 'Vertical'}].map((skew, i) => {
                            return (
                                <SC.FCol key={skew.id} alignStretch style={{marginBottom : i > 0 ? 0 : '4px'}}>
                                    <Label>{skew.label}</Label>                                    
                                    <InputSlider 
                                        horizontal 
                                        small 
                                        reverse                                 
                                        postFix={LabelDegree}
                                        value={Utils.JustGet(transform, null, skew.id)}
                                        min={-180}
                                        max={180}
                                        allowOverflow
                                        onChange={this.onChangeValue.bind(this, skew.id)}
                                        onChanging={this.onChangingValue.bind(this, skew.id)}
                                        inputStyle={{fontSize : '12px', marginLeft : '-8px'}}
                                    />
                                </SC.FCol>
                            )
                        })
                    }
                </SC.FCol>
            )
        }
        return (
            <DraggableEditorBox notFirst={notFirst}>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '4px', paddingRight : '4px'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <SC.FCol f1 style={{padding : '8px', paddingRight : '12px', fontSize : '11px'}}>
                    <SC.FRow jsb style={{marginBottom : '8px'}}>   
                        <SC.FRow f1>
                            <DropDownSelect 
                                hasBorder            
                                autoHeight
                                xsmall
                                toRight
                                fitWidth
                                style={{flex : 1}}
                                items={transformTypes}
                                value={transform.type}
                                onChange={this.onChangeType}
                            />
                        </SC.FRow>       
                        <SC.FRow f1 jsb alc>
                            {
                                transform.type === 'scale' &&
                                <SC.Icons.Icon_Button hasFill hasStroke hasCursor style={{marginLeft : '8px'}} onClick={this.ToggleLock}>
                                    <SC.Icons.Icon_Lock open={!transform.locked} />
                                </SC.Icons.Icon_Button>
                            }
                            <SC.FRow f1 justifyEnd style={{marginLeft : '4px', marginRight : '-8px'}}>
                                <SC.Icons.Icon_ButtonBox dark hasCursor>
                                    <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingLeft :'4px', }} onClick={this.props.onClone} >
                                        <SC.Icons.Icon_Clone size={18}/>
                                    </SC.Icons.Icon_Button>
                                </SC.Icons.Icon_ButtonBox>
                                <SC.Icons.Icon_ButtonBox dark hasCursor>
                                    <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', marginLeft : '2px', paddingRight : '4px'}} onClick={this.props.onDelete} >
                                        <SC.Icons.Icon_Delete size={16}/>
                                    </SC.Icons.Icon_Button>
                                </SC.Icons.Icon_ButtonBox>
                            </SC.FRow>                    
                        </SC.FRow>                                                            
                    </SC.FRow>
                    {editor}
                </SC.FCol> 
            </DraggableEditorBox>
        )
    }
}

export default TransformEditor;

export const DraggableEditorBox = styled.div`
    border : ${props => props.theme.border_onlight_line};
    border-left : none;
    border-right : none;
    display : flex;
    align-items : stretch;
    ${
        props => {
            if (props.notFirst)
                return css`border-top : none;`;
        }
    }
`;
export const DraggableEditorHandle = styled.div`
    border-right : ${props => props.theme.border_onlight_line};
    display : flex;
    justify-content : center;
    align-items : center;
    align-self : stretch;    
`;

export const LabelDegree = (
    <div style={{width : '6px', height : '6px', borderRadius : '50%', boxSizing : 'border-box', border : '1px solid #808284', marginBottom : '6px'}} />
)