import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    UIUtils,
    Utils,
    Globals,
    AppLayout
} from '../../../../../../../../importer';


import styled, {css} from 'styled-components';
import {PresetCurve, PresetItem} from './presetcurve';
import BezierCurve from './bezierCurve';
import Slider from '../../../../../../../../components/editors/slider';
import GetPresets from './presetdata';
import * as Styles from './styles';
import { motionValue, motion } from 'framer-motion';

const Box_Bezier = styled.div`
    height : 100%;
    position : relative;        
    box-sizing : border-box;
`

const Input_Coord_Box = styled.div`
    border : ${props => props.theme.border_onlight_line};
    flex : 1;
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    height : 44px;
    padding : 4px;    
    box-sizing : border-box;
    font-size : 11px;
    text-align : center;
`;

const Input_Coord = styled.input`
    border : none;
    background-color : transparent;
    width : 100%;
    font-size : 14px;
    text-align : center;
`;

const DurationRow = styled.div`
    font-size : 14px;
    margin-top : 12px;
`;

const Box_Duration = styled.div`
    background-color : #1C1C1D;
    border : 1px solid #3E3E42;
    width : 74px;
    height : 30px;
    color : #A6A6A6;
    font-size : 12px;
    display : flex;
    flex-direction : row;
    justify-content : space-around;
    align-items : center;
    padding-left : 4px;
    padding-right : 4px;
    box-sizing : border-box;
`
const Input_Duration = styled.input`    
    font-size : 16px;
    color : white;
    border : none;
    width : 60%;
    background : transparent;
    text-align : right;
    padding-right : 6px;
`;


export const DurationInput = ({duration, onChange}) => {
    return (
        <Box_Duration>
            <Input_Duration value={duration || 200} onChange={onChange} onKeyUp={(e) => e.stopPropagation()} />
            <span>ms</span>
        </Box_Duration>
    )
}



class BezierEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        this.onChanging = this.onChanging.bind(this);        
        this.onChangingDuration = this.onChangingDuration.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
    }
    componentDidMount() {
        super.componentDidMount();
    }
    onChanging(value) {
        this.changingValue = Utils.DeepClone(value);
        this.RCUpdate();
    }
    onChangeCoord(prop, e) {
        let value = e.target.value;

        let coord = Utils.ToNumber(value);
        if (isNaN(coord))
            return;
        const curve = Globals.ProjectManager.Tokens.ValueOf({model : this.props.model});
        if (curve) {
            if (!this.changingValue)
                this.changingValue = Utils.DeepClone(curve);            
            this.changingValue[prop] = value;

            this.RCUpdate();
            this.props.onChangeCurve(this.changingValue);
        }
    }
    onBlurCoord(prop, e) {
        let value = e.target.value;
         if (value === '')
             value = '0';
       
        let coord = Utils.ToNumber(value);
        if (isNaN(coord))
            return;
        const curve = Globals.ProjectManager.Tokens.ValueOf({model : this.props.model});
        if (curve) {
            if (!this.changingValue)
                this.changingValue = Utils.DeepClone(curve);            
            this.changingValue[prop] = value;

            this.RCUpdate();
            this.props.onChangeCurve && this.props.onChangeCurve(this.changingValue);
        }
    }
    onChangeDuration(e) {
        let dur = Utils.ToInteger(e.target.value);
        if (isNaN(dur))
            return;
        
        this.props.onUpdateDuration(dur);
        this.RCUpdate();
    }
    onChangingDuration(d) {
        this.props.onUpdateDuration(d);
        this.RCUpdate();
    }

    componentWillReceiveProps(nextProps) {
        delete this.changingValue;
    }
    ApplyPreset(i,j,path, name) {
        this.props.onPreset(i,j,path, name);
    }
    renderCustom() {
        const {model} = this.props;
        const curve = Globals.ProjectManager.Tokens.ValueOf({model : model});
        const duration = Globals.ProjectManager.Tokens.ValueOf({model : model, name : 'duration'});
        let {x1, y1, x2, y2} = this.changingValue || curve || {x1 : 0, y1 : 0, x2 : 1, y2 : 1};
        const presets = GetPresets();

        const colors = SC.CurrentTheme.theme.isLight ? {
            back : '#0000000a',
            grid : '#fff',
            grid_stroke : '#fff',
            curve : '#29b6fa',
            handleline : '#d8d8d8',
            handle : '#d8d8d8',
            strokeWidth : 1
        } : 
        {
            back : SC.CurrentTheme.theme.back,
            grid : '#414246',
            grid_stroke : '#414246',
            curve : '#CECECE',
            handleline : '#29b6fa',
            handle : '',
            strokeWidth : 2
        }

        return (            
            <SC.FCol fh>
                {
                    !this.props.noPresets && 
                    <React.Fragment>
                        <Styles.Transition_ChoosePreset onClick={this.props.onSelectPreset}>
                            Chose From Ease Presets                                                           
                        </Styles.Transition_ChoosePreset>
                        <SC.FRow alc>
                            {
                                [[0,4], [1,0],[2,0],[3,1]].map((ps, i) => {
                                    return (
                                        <PresetItem style={{width : 'unset', height : 'unset', marginRight : '8px'}} cursorPointer key={i}>
                                            <PresetCurve 
                                                key={i} 
                                                small 
                                                selected={this.Selected1 === ps[0] && this.Selected2 === ps[1]} 
                                                path={presets[ps[0]].items[ps[1]].path} 
                                                curveWidth={2}
                                                onClick={this.ApplyPreset.bind(this, ps[0], ps[1], presets[ps[0]].items[ps[1]].path, presets[ps[0]].items[ps[1]].name)}
                                            />
                                        </PresetItem>
                                    );
                                })
                            }     
                            <SC.Buttons.RoundButton small onClick={this.props.onSelectPreset}>
                                ... More
                            </SC.Buttons.RoundButton>                   
                        </SC.FRow>
                    </React.Fragment>
                }                
                <SC.FCol f1 justifyCenter alc>
                    <Box_Bezier> 
                        <BezierCurve active light={SC.CurrentTheme.theme.isLight} theme={colors} value={this.changingValue || curve} duration={duration} onChange={this.props.onChangeCurve} onChanging={this.onChanging}/>
                        <SamplePlayer horizontal move scale opacity x1={x1} y1={y1} x2={x2} y2={y2} />
                    </Box_Bezier>
                </SC.FCol>                                
                <SC.FRow style={{zIndex:100}}>
                    <Input_Coord_Box>
                        <span>X1</span>
                        <Input_Coord value={x1} placeholder='_ _' onBlur={this.onBlurCoord.bind(this, 'x1')} onChange={this.onChangeCoord.bind(this, 'x1')} onKeyUp={(e) => e.stopPropagation()} />
                    </Input_Coord_Box>
                    <Input_Coord_Box>
                        <span>Y1</span>
                        <Input_Coord value={y1} placeholder='_ _' onBlur={this.onBlurCoord.bind(this, 'y1')} onChange={this.onChangeCoord.bind(this, 'y1')} onKeyUp={(e) => e.stopPropagation()} />
                    </Input_Coord_Box>
                    <Input_Coord_Box>
                        <span>X2</span>
                        <Input_Coord value={x2} placeholder='_ _' onBlur={this.onBlurCoord.bind(this, 'x2')} onChange={this.onChangeCoord.bind(this, 'x2')} onKeyUp={(e) => e.stopPropagation()} />
                    </Input_Coord_Box>
                    <Input_Coord_Box>
                        <span>Y2</span>
                        <Input_Coord value={y2} placeholder='_ _' onBlur={this.onBlurCoord.bind(this, 'y2')} onChange={this.onChangeCoord.bind(this, 'y2')} onKeyUp={(e) => e.stopPropagation()} />
                    </Input_Coord_Box>
                </SC.FRow>
                {
                    !this.props.noDuration && 
                    <DurationRow>
                        <SC.FCol>
                            <SC.FRow jsb alc mb={4}>
                                <SC.FRow alc>
                                    <SC.Icons.Icon_Button hasFill>
                                        <SC.Icons.Icon_History size={18} />
                                    </SC.Icons.Icon_Button>                                
                                    <span style={{marginLeft : '4px'}}>Duration</span>                            
                                </SC.FRow>                         
                                <DurationInput duration={duration} onChange={this.onChangeDuration} />                            
                            </SC.FRow>
                            <div style={{width : '100%'}}>
                                <Slider 
                                    value={duration || 200} 
                                    min={0} max={2000} 
                                    onChanging={this.onChangingDuration} 
                                    onChange={this.props.onChangeDuration}
                                    themeId={AppLayout.ThemeId}
                                />
                            </div>
                        </SC.FCol>                    
                    </DurationRow>
                }                
            </SC.FCol>
        )
    }
}

export default BezierEditor;

const SamplePlayer = (props) => {
    const style = {
        width : '16px',
        height : '16px',
        borderRadius : '50%',
        backgroundColor : 'rgba(0, 125, 255, 1)',
        position : 'absolute'
    }
    if (props.horizontal) {
        style.bottom = '20px';
        style.left = '22px';
    }
    else {
        style.top = '40px';
        style.right = '22px';
    }

    const animateProps = {
        initial : {
            scaleX : 1,
            scaleY : 1,
            rotate : 0,
            x : 0,
            y : 0,
            opacity : 1
        },
        animate : {

        },
        transition : {
            flip: Infinity,
            repeatDelay : 0.4,
            duration : 1
        }
    }

    const {x1, y1, x2, y2} = props;

    animateProps.transition.ease = [Number(x1), Number(y1), Number(x2), Number(y2)]; 
    animateProps.animate.y = props.horizontal ? 0 : (props.move ?  156 : 0);
    animateProps.animate.x = props.horizontal ? (props.move ?  210 : 0) : 0;
    animateProps.animate.scale = props.scale ? (2) : 1;
    animateProps.animate.opacity = props.opacity ? (2) : 1;


    return (
        <motion.div
            key={x1 + x2 + y1 + y2}
            style={style}
            {...animateProps}
        >
        </motion.div>
    )
}