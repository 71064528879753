import { Utils } from '../../toolabs-importer';
import firebase from '@firebase/app';
import '@firebase/app';
import '@firebase/database';
import '@firebase/auth';
import '@firebase/storage';
import '@firebase/messaging';

import AppState from '../../appstate/AppState';
import ReactGA from 'react-ga';
import Globals from '../../appstate/Globals';
import AppLayout from '../../appstate/AppLayout';

var config_old = {
    apiKey: "AIzaSyAUKrl7UYSNPiofOnivkr8BzRnM-j5ldqI",
    serverKey :"AAAAFGrS8Uc:APA91bGIeV2SFjPyuSeVdKe7yIxBTRPdHfkwx2WSiy0zkuQZuuTu3y4ABVnaSe1uUUjtknR2InKDwo3XYaP_RKOhKYay0Q4E7qBvpiYxtw5tzmq7b4338Ib0QU_pIuIK6QVecULTeC55",
    authDomain: "test-component-studio.firebaseapp.com",
    databaseURL: "https://test-component-studio.firebaseio.com",
    projectId: "test-component-studio",
    storageBucket: "test-component-studio.appspot.com",
    messagingSenderId: "87691555143"
};
var config_old2 = {
    apiKey: "AIzaSyCd3xRlZmFZOS9jqz6lmcfvMGeTqMu7Q6c",
    authDomain: "toolabs-beta.firebaseapp.com",
    databaseURL: "https://toolabs-beta.firebaseio.com",
    projectId: "toolabs-beta",
    storageBucket: "toolabs-beta.appspot.com",
    messagingSenderId: "1090133190649",
    appId: "1:1090133190649:web:4f4416066f5e334b"
};

var config_debug = {
    apiKey: "AIzaSyAKGEewskWsYyH8OjEeQUY1Pxbc7lyAAno",
    authDomain: "toolabs-beta-20-1.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-1.firebaseio.com",
    projectId: "toolabs-beta-20-1",
    storageBucket: "toolabs-beta-20-1.appspot.com",
    messagingSenderId: "406283391426",
    appId: "1:406283391426:web:69b9db9ed6289cb5a0465b",
    measurementId: "G-YL9P6MSGLH"
};

var config = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-2.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

var config_documents_debug = {
    apiKey: "AIzaSyAKGEewskWsYyH8OjEeQUY1Pxbc7lyAAno",
    authDomain: "toolabs-beta-20-1.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-1-documents.firebaseio.com",
    projectId: "toolabs-beta-20-1",
    storageBucket: "toolabs-beta-20-1.appspot.com",
    messagingSenderId: "406283391426",
    appId: "1:406283391426:web:69b9db9ed6289cb5a0465b",
    measurementId: "G-YL9P6MSGLH"
};

var config_documents = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-2-documents.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

var config_notifications = {
    apiKey: "AIzaSyAYzCR2XKn-9Ha592WTJAs0Q-v0r5fcb4M",
    authDomain: "toolabs-beta-20-2.firebaseapp.com",
    databaseURL: "https://toolabs-beta-20-2-notifications.firebaseio.com",
    projectId: "toolabs-beta-20-2",
    storageBucket: "toolabs-beta-20-2.appspot.com",
    messagingSenderId: "30542139765",
    appId: "1:30542139765:web:09f2b838e4f536e450833c"
};

let firebaseApp_Documents;
let firebaseApp_Notifications;

export const checkFirebaseJson = (Data) => {
    if (Data) {
        if (Utils.IsObject(Data)) {
            const RemoveKeys = [];
            Utils.ForEach(Data, (SubData, Key) => {
                if (SubData === undefined) {
                    RemoveKeys.push(Key);
                }
                else if (Number.isNaN(SubData)) {
                    RemoveKeys.push(Key);
                }
                else if (!Number.isNaN(Number(SubData)) && !Number.isFinite(Number(SubData))) {
                    RemoveKeys.push(Key);
                }
                else {
                    Data[Key] = checkFirebaseJson(SubData);
                }
            });
            RemoveKeys.map((key) => {
                delete Data[key];
            })
        }
    }
    return Data;
}

const db = {
    test() {
        return 'Firebase'
    },
    init() {
        if (!firebase.apps.length)
            firebase.initializeApp(AppLayout.TeamModeEnabled ? config : config); //firebase.initializeApp(AppLayout.TeamModeEnabled ? config_debug : config);
        const that = this;
        firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                if (AppState)
                {
                    AppState.User = user;
                }
                // firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
                //     console.log(idToken);
                // });
                // console.log( user.uid);
                ReactGA.set({ userId: user.uid });
                db.OnAuthChanged({
                    id: user.uid,
                    name : user.displayName,
                    email : user.email
                });
                try {
                    db.init_messaging();   
                } catch (error) {
                    
                }                

                that.onDisconnectRef = firebase.database().ref(`/user/${user.uid}/session`);
                that.onlineStatus = {
                    online : true,
                    time : firebase.database.ServerValue.TIMESTAMP
                };                
                that.onDisconnectRef.set(that.onlineStatus);
                if (that.onDisconnectRef) {
                    const onDisconnect = that.onDisconnectRef.onDisconnect();                    
                    onDisconnect.set(
                        {
                            online : false,
                            time : firebase.database.ServerValue.TIMESTAMP
                        }
                    );
                }
                             
                var connectionInitialized = false;
                that.isConnected = true;
                var connectedRef = firebase.database().ref(".info/connected");
                connectedRef.on("value", function(snap) {                    
                    if (snap.val() === true) {                        
                        that.isConnected = true;
                        if (connectionInitialized && db.OnConnectionStateChanged)
                            db.OnConnectionStateChanged({connected : true})
                        connectionInitialized = true;
                        if (Globals.ProjectManager.Id) {
                            Globals.ProjectManager.Reload();
                        }
                    } else if (connectionInitialized){
                        that.isConnected = false;
                        setTimeout(() => {
                            if (!that.isConnected) {
                                if (db.OnConnectionStateChanged)
                                    db.OnConnectionStateChanged({connected : false})    
                            }                            
                        }, 4000);
                        
                    }
                });
            } else {                
                db.OnAuthChanged(null);
            }
        });
    },
    getConfig()
    {
        return firebase.apps[0].options;
    },
    getFirebase() {
        return firebase;
    },
    init_messaging() {

        firebase.messaging().onMessage(function (payload) {
            console.log("Message received. ", payload);
            if (payload.notification)
                alert(payload.notification.body);
        });
    },
    requestNotificationPermission() {
        firebase.messaging().requestPermission()
        .then(function () {

            firebase.messaging().getToken()
                .then(function (currentToken) {
                    if (currentToken) {
                        console.log(currentToken);
                        AppState.UpdateUserToken(AppState.User.uid, currentToken);

                    } else {
                        console.log('No Instance ID token available. Request permission to generate one.');
                    }
                })
                .catch(function (err) {
                    console.log('An error occurred while retrieving token. ', err);
                });


        })
        .catch(function (err) {
            console.log('Unable to get permission to notify.', err);
        });
    },
    get_id(path) {
        const ref_model = firebase.database().ref(path).push();
        return ref_model.key;
    },
    get_ref(path) {
        return firebase.database().ref(path);
    },
    get_path(path, name) {
        return new Promise((resolve) => {
            firebase.database().ref(path).once('value').then(function (snapshot) {
                if (snapshot) {
                    let result = {};
                    result[name || 'value'] = snapshot.val()
                    resolve(result);
                }
            });
        })
    },
    get_path_value(path) {
        return new Promise((resolve) => {
            firebase.database().ref(path).once('value').then(function (snapshot) {
                if (snapshot) {
                    resolve(snapshot.val());
                }
            });
        })
    },
    set_path(path, value) {
        firebase.database().ref(path).set(checkFirebaseJson(value));
        this.onSetPath && this.onSetPath(path, value);
    },
    delete_path(path) {
        firebase.database().ref(path).remove();
        this.onDeletePath && this.onDeletePath(path);
    },
    updates(paths) {        
        if (paths && Utils.IsObject(paths)) {
            Utils.ForEach(paths, (value, path) => {
                checkFirebaseJson(value);
            });
        }
        const result = firebase.database().ref().update(paths);
        this.onUpdatePaths && this.onUpdatePaths(paths);
        return result;
    },
    getDatabase() {
        return firebase.database();
    },
    // Documents Database 
    getDocumentsDb() {  
        return firebase.database();

        if (!firebaseApp_Documents) {
            firebaseApp_Documents = firebase.initializeApp(config_documents_debug, 'documents');
        }
        return firebaseApp_Documents.database();
    },
    get_id_documents(path) {
        if (!AppLayout.TeamModeEnabled)
            return this.get_id(path);

        const ref_model = this.getDocumentsDb().ref(path).push();
        return ref_model.key;
    },    
    get_path_documents(path, name) {
        if (!AppLayout.TeamModeEnabled)
            return this.get_path(path, name);

        return new Promise((resolve) => {
            this.getDocumentsDb().ref(path).once('value').then(function (snapshot) {
                if (snapshot) {
                    let result = {};
                    result[name || 'value'] = snapshot.val()
                    resolve(result);
                }
            });
        })
    },
    get_path_documents_value(path) {
        if (!AppLayout.TeamModeEnabled)
            return this.get_path_value(path, name);
        return new Promise((resolve) => {
            this.getDocumentsDb().ref(path).once('value').then(function (snapshot) {
                if (snapshot) {
                    resolve(snapshot.val());
                }
            });
        })
    },
    set_path_documents(path, value) {
        if (!AppLayout.TeamModeEnabled)
            return this.set_path(path, value);

        this.getDocumentsDb().ref(path).set(checkFirebaseJson(value));
        this.onSetPathDocuments && this.onSetPathDocuments(path);
    },
    delete_path_documents(path) {
        if (!AppLayout.TeamModeEnabled)
            return this.delete_path(path);

        this.getDocumentsDb().ref(path).remove();
        this.onDeletePathDocuments && this.onDeletePathDocuments(path);
    },
    updates_documents(paths) {        
        if (!AppLayout.TeamModeEnabled)
            return this.updates(paths);

        if (paths && Utils.IsObject(paths)) {
            Utils.ForEach(paths, (value, path) => {
                checkFirebaseJson(value);
            });
        }
        const result = this.getDocumentsDb().ref().update(paths);
        this.onUpdatePathsDocuments && this.onUpdatePathsDocuments(paths);
        return result;
    },

    // Notifications Db
    getNotificationsDb() {  
        if (!firebaseApp_Notifications) {
            firebaseApp_Notifications = firebase.initializeApp(config_notifications, 'notifications');
        }
        return firebaseApp_Notifications.database();
    },
    // Authentication
    Login(email, password, CB) {
        firebase.auth().signInWithEmailAndPassword(email, password)
            .catch(function (error) {
                var errorCode = error.code;
                var errorMessage = 'Email or password is incorrect';
                if (errorCode === 'auth/user-not-found')
                    errorMessage = 'User not found. Spellcheck your email address.';
                CB && CB({
                    Error: true,
                    Message: errorMessage
                });
            }
            )
            .then((User) => {
                if (User) {
                    ReactGA.event({
                        category:  AppState.AnalysisEvents.Category.Authentication.Name,
                        action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                        userId:User.uid
                      });
                    CB && CB({
                        Error: false,
                        User: User
                    })
                }
            });
    },
    LoginWithGoogle(notPopup) {
        return new Promise((resolve, reject) => {
            var provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('email');
            if (notPopup) {
                firebase.auth().signInWithRedirect(provider)
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                }
                )
                .then((result) => {
                    if (result && result.user) {
                        ReactGA.event({
                            category:  AppState.AnalysisEvents.Category.Authentication.Name,
                            action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                            userId: result.user.uid
                        });
                        resolve(result);
                    }
                });
            }
            else {
                firebase.auth().signInWithPopup(provider)
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                }
                )
                .then((result) => {
                    if (result && result.user) {
                        ReactGA.event({
                            category:  AppState.AnalysisEvents.Category.Authentication.Name,
                            action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                            userId: result.user.uid
                        });
                        resolve(result);
                    }
                });
            }            
        });
    },
    LoginWithTwitter(notPopup) {
        return new Promise((resolve, reject) => {
            var provider = new firebase.auth.TwitterAuthProvider();
            if (notPopup) {
                firebase.auth().signInWithRedirect(provider)
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                }
                )
                .then((result) => {
                    if (result && result.user) {
                        ReactGA.event({
                            category:  AppState.AnalysisEvents.Category.Authentication.Name,
                            action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                            userId: result.user.uid
                        });
                        resolve(result);
                    }
                });
            }
            else {
                firebase.auth().signInWithPopup(provider)
                .catch(function (error) {
                    console.log(error);
                    reject(error);
                }
                )
                .then((result) => {
                    if (result && result.user) {
                        ReactGA.event({
                            category:  AppState.AnalysisEvents.Category.Authentication.Name,
                            action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                            userId: result.user.uid
                        });
                        resolve(result);
                    }
                });
            }
        });
    },
    LoginWithFacebook() {
        return new Promise((resolve, reject) => {
            var provider = new firebase.auth.FacebookAuthProvider();
            provider.addScope('email');
            provider.setCustomParameters({
            'display': 'popup'
            });
            firebase.auth().signInWithPopup(provider)
            .catch(function (error) {
                console.log(error);
                reject(error);
            }
            )
            .then((result) => {
                if (result && result.user) {
                    ReactGA.event({
                        category:  AppState.AnalysisEvents.Category.Authentication.Name,
                        action: AppState.AnalysisEvents.Category.Authentication.Events.Login,
                        userId: result.user.uid
                    });
                    resolve(result.user);
                }
            });
        });
    },
    Logout() {
        ReactGA.event({
            category:  AppState.AnalysisEvents.Category.Authentication.Name,
            action: AppState.AnalysisEvents.Category.Authentication.Events.Logout,
            userId : firebase.auth().uid
          });
        if (this.onDisconnectRef) {
            this.onlineStatus.online = false;
            this.onDisconnectRef.set(this.onlineStatus);
        }
        firebase.auth().signOut();
        
    },
    ResetPassword(email) {
        return firebase.auth().sendPasswordResetEmail(email).then(function () {
            return "";
        }).catch(function (error) {

            var errorCode = error.code;
            var errorMessage = error.message;

            if (errorCode == 'auth/invalid-email') {
            } else if (errorCode == 'auth/user-not-found') {
            }
            console.log(error.message);
            return error;
        });
    },
    Register(email, password, user) {
        return new Promise((resolve, reject) => {
            firebase.auth().createUserWithEmailAndPassword(email, password)
                .catch(function (error) {

                    var errorCode = error.code;
                    var errorMessage = error.message;
                    console.log(error);
                    reject(error);
                }
                )
                .then((UserInfo) => {
                    if (UserInfo) {
                        var User = firebase.auth().currentUser;
                        try {
                            User.updateProfile({
                                displayName: user,
                                photoUrl: '',
                            });
       
                        } catch (error) {
                            
                        }
                        resolve(User.uid);
                        // User.sendEmailVerification().then(function () {
                        //     console.log('Email Verification Sent!');
                        // });
                    }
                })
        });
    },
    registerFigmaAuthentication(requestId, userId) {
        return new Promise((resolve) => {
            firebase.auth().currentUser.getIdToken(/* forceRefresh */ false).then((idToken) => {
                const updates = {};
                updates['/api/figma/authRequests/' + requestId + '/'] = {
                    userId : userId,
                    token : idToken
                };
                this.updates(updates).then(() => {
                    resolve();
                })
              }).catch(function(error) {
                console(error);
            });
        })        
    },
    UpdateUserName(name, callback)
    {
        firebase.auth().currentUser.updateProfile({
        displayName: name
        }).then(function() {
            callback && callback();
        }).catch(function(error) {

        });
    },
    UpdateUserEmail(email)
    {
        firebase.auth().currentUser.updateEmail(email).then(function() {

        }).catch(function(error) {
        });
    },
    GetUserFromEmail : async function(email) {
        return new Promise(async (resolve) => {
            if (false && email.indexOf('namik') > -1) {
                const users = await this.get_path('/user');
                let uid;
                if (users && users.value) {
                    Utils.ForEach(users.value, (user, id) => {
                        if (user.email === email) {
                            uid = id;
                            return false;
                        }
                    });
                }                
                resolve({
                    id : uid
                })
                return;
            }

            firebase.auth().currentUser.getIdToken(/* forceRefresh */ false).then((idToken) => {
                fetch("https://toolabs-xd-api.herokuapp.com/admin/getUserByEmail", {
                    body: JSON.stringify({"email":email}),
                    headers: {
                        Accept: "application/json",
                        AuthToken: idToken,
                        "Content-Type": "application/json"
                    },
                    method: "POST"
                }).then((response) => {
                    if (response) {
                        response.json().then((result) => {                            
                            if (result && result.user) {
                                resolve({
                                    id : result.user.uid,
                                    name : result.user.name
                                })                                                  
                            }
                            else {
                                resolve({
                                    id : null
                                })                
                            }
                        }).catch(function(error) {
                            resolve({
                                id : null
                            })
                        });
                    }
                    else {
                        resolve({
                            id : null
                        })
                    }
                })    
            }).catch(function(error) {
                resolve({
                    id : null
                })
            });
            
        })
    },
    DeleteUser()
    {
        firebase.auth().currentUser.delete().then(function() {

          }).catch(function(error) {
          });
    },
    storage: {
        delete(path) {
            var storageRef = firebase.storage().ref();
            var desertRef = storageRef.child(path);
            return desertRef.delete();
        },
        save(path, File, MetaFile, progress) {
            var storageRef = firebase.storage().ref();
            var desertRef = storageRef.child(path);
            const promise = desertRef.put(File, MetaFile);
            if (progress) {
                promise.on('state_changed', function (snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var prog = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    progress(prog);
                    console.log('Upload is ' + prog + '% done');

                }, function (error) {
                    // Handle unsuccessful uploads
                }, function () {
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    // var downloadURL = uploadTask.snapshot.downloadURL;
                });
            }
            return promise;
        }
    },
    listen_studio_version(callback) {
        var tokensChangedRef = firebase.database().ref("/shared/versions/dsm/latest/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback(dataSnapshot.val());
        });
    },
    listen_projects_new(callback) {
        var newProjectref = firebase.database().ref("/projects/models/");
        newProjectref.on("value",  () =>  {
            this.onProjectListChanged && this.onProjectListChanged();
        });
    },
    listen_tokens_changes(projectId, callback) {
        var tokensChangedRef = firebase.database().ref("/projects/data/" + projectId + "/Changes/Tokens/Xd/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback();
        });
    },
    listen_tokens_changes_figma(projectId, callback) {
        var tokensChangedRef = firebase.database().ref("/projects/data/" + projectId + "/Changes/Tokens/figma/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback();
        });
    },
    listen_figma_pluginversion(callback) {
        var tokensChangedRef = firebase.database().ref("/shared/versions/figma/latest/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback(dataSnapshot.val());
        });
    },
    listen_figma_pluginversion_alternate(callback) {
        var tokensChangedRef = firebase.database().ref("/shared/versions/figma/alternate/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback(dataSnapshot.val());
        });
    },
    listen_tokens_changes_web(projectId, callback) {
        var tokensChangedRef = firebase.database().ref("/projects/data/" + projectId + "/Changes/Tokens/Web/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback();
        });
    },
    listen_mockup_changes_web(projectId, callback) {
        var tokensChangedRef = firebase.database().ref("/projects/data/" + projectId + "/Changes/Mokcup/Web/");
        tokensChangedRef.on("value", function (dataSnapshot) {            
            callback && callback();
        });
    },
    listen_document_change(projectId, documentId, callback) {
        var documentChangeRef = firebase.database().ref("/documents/data/" + projectId + "/" + documentId + "/changeId/");
        documentChangeRef.on("value", function () {            
            callback && callback();
        });
        var tokensChangedRef = firebase.database().ref("/projects/data/" + projectId + "/Changes/Tokens/Web/");
        tokensChangedRef.on("value", function () {            
            callback && callback();
        });
    }
};

export default db;