import React from 'react';
import {
    ReactBaseComponent,
    SC,    
} from '../../../../../../../../importer';
import styled from 'styled-components';
import GetPresets from './presetdata';
import {PresetItem, PresetCurve} from './presetcurve';

const PresetGroupHeader = styled.div`
    font-size : 12px;
    margin-bottom : 8px;
`;



class PresetList extends React.Component {
    ApplyPreset(i,j,path, name) {
        this.props.onApplyPreset(i,j,path, name);
    }
    render() {
        const presets = GetPresets();     
        return (
            <SC.FCol pa={this.props.noPadding ? 0 : 4} className={this.props.className}>
                {
                    presets.map((pg, i) => {
                        return (
                            <SC.FCol key={i} mb={14}>
                                <PresetGroupHeader>{pg.group}</PresetGroupHeader>
                                <SC.SelectPanelGroup noMargin>
                                    {
                                        pg.items.map((pgi, j) => {
                                            return (
                                                <PresetItem key={j} onClick={this.ApplyPreset.bind(this, i, j, pgi.path, pgi.name)} index={j} >
                                                    <PresetCurve selected={this.props.Selected1 === i && this.props.Selected2 === j} path={pgi.path} curveWidth={SC.CurrentTheme.theme.isLight ? 2 : 1} />
                                                    <div style={{marginTop:'-10px'}}>{pgi.name}</div>
                                                </PresetItem>
                                            )
                                        })
                                    }
                                </SC.SelectPanelGroup>
                            </SC.FCol>                                        
                        )
                    })
                }
            </SC.FCol> 
        )
    }
}

export default PresetList;