import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import PublishMetaItem from './metaitem';
import Strings from './strings';

const Publish_Component = function(Component, Board, Platform, MetaDataVersion, GetMockupValue) {    
    let ConvertedComponent = {
        Name : Utils.ToCamelCase(Utils.Get(Component.Data, Component.Model.name ||Component.Model.Name || Component.Model._id, 'Published', 'Name')),
        Id : Component.Model._id,
        MetaDataVersion : MetaDataVersion,
        Default : {

        },
        Preview : {
            Size : Utils.JustGet(Component.Data, {
                width : 400,
                height : 400
            }, 'PreviewSize')
        }  
    };
    const PublishContext = {
        Id : Component.Model._id,
        Platform : Platform,
        MetaItems : Utils.UseNull(Component.Data.MetaItems, []),
        Handlers : Component.Data.Handlers,
        ConsumedElements  : [],
        ConsumedEvents : [], // Collect Ids of Events triggered from Items, then later publish just those events        
        GetMockupValue : GetMockupValue
    };    

    if (!Component.Data.RootItem) {
        return ConvertedComponent;
    }

    // States
    [Strings.STATES, 'PseudoStates'].map((StateType) => {
        const States = Utils.Get(Component.Data, {}, StateType);
        if (States) {
            Utils.ForEach(States.Order, (Id) => {
                const State = States[Id];        
                if (State) {
                    if (State.Variations) {
                        const VariationIds = Utils.Get(State, null, 'Variations', 'Order');
                        if (VariationIds) {
                            Utils.Set(ConvertedComponent, State.name, Strings.STATES, Id, 'Name');
                            Utils.ForEach(VariationIds, (vid) => {
                                const variation = State.Variations[vid];
                                if (variation) {
                                    const convertedVariation = {
                                        name : variation.name
                                    };
                                    if (variation.pseudo)
                                        convertedVariation.pseudo = true;
    
                                    Utils.Set(ConvertedComponent, convertedVariation, Strings.STATES, Id, 'Variations', vid);
                                }                            
                            })
                        }
                    }    
                    else {
                        // Single Variation State
                        Utils.Set(ConvertedComponent, State.name, Strings.STATES, Id, 'Name');
                        Utils.Set(ConvertedComponent, Id, Strings.STATES, Id, 'SingleVariation');
                        if (State.pseudo)
                            Utils.Set(ConvertedComponent, true, Strings.STATES, Id, 'pseudo');
                    }        
                    if (State.ResetOthers)
                        Utils.Set(ConvertedComponent, true, Strings.STATES, Id, 'ResetOthers');
                }                
            });
            ConvertedComponent.StateOrder = Utils.Concat(ConvertedComponent.StateOrder || [], States.Order);
        }
    })
   

    const RootMetaItem = PublishContext.MetaItems[Component.Data.RootItem.Id];
    ConvertedComponent.RootItem = PublishMetaItem({
        MetaItem : RootMetaItem, 
        Id : Component.Data.RootItem.Id, 
        PublishContext : PublishContext, 
        RootItem : true
    });
    ConvertedComponent.Preview.isFixedWidth = Utils.JustGet(RootMetaItem, false, 'Property', 'Default', 'Default', 'fixedWidth', 'value');
    ConvertedComponent.Preview.isFixedHeight = Utils.JustGet(RootMetaItem, false, 'Property', 'Default', 'Default', 'fixedHeight', 'value');

    ConvertedComponent.Preview.width = Utils.JustGet(RootMetaItem, 100, 'Style', 'Default', 'Default', 'width', 'value');
    if (ConvertedComponent.Preview.width === 'auto')
        ConvertedComponent.Preview.width = 1024;
    const width_unit = Utils.JustGet(RootMetaItem, 'px', 'Style', 'Default', 'Default', 'width', 'Unit');
    ConvertedComponent.Preview.height = Utils.JustGet(RootMetaItem, 100, 'Style', 'Default', 'Default', 'height', 'value');
    if (ConvertedComponent.Preview.height === 'auto')
        ConvertedComponent.Preview.height = 1024;
    const height_unit = Utils.JustGet(RootMetaItem, 'px', 'Style', 'Default', 'Default', 'height', 'Unit');
    if (width_unit === '%') {
        ConvertedComponent.Preview.width = Number.parseInt((1024 * ConvertedComponent.Preview.width) / 100);
        ConvertedComponent.Preview.isFixedWidth = false;
    }
    if (height_unit === '%') {
        ConvertedComponent.Preview.height = Number.parseInt((1024 * ConvertedComponent.Preview.height) / 100);
        ConvertedComponent.Preview.isFixedHeight = false;
    }


    // Model View

    Publish_Models({Models : Component.Data.Models, Target : ConvertedComponent});

    // Events : ToDo : publish only necessary event data
    ConvertedComponent.Events = {};
    if (Component.Data.Events) {
        Utils.ForEach(Component.Data.Events.Order, (EventId, i) => {
            if (Component.Data.Events[EventId]) {
                ConvertedComponent.Events[EventId] = {
                    name : Component.Data.Events[EventId].name
                }
            }            
        });
    }
    const Handlers = Utils.DeepClone(Component.Data.Handlers);
    if (Handlers) {
        Utils.ForEach(Handlers, (Handler, id) => {
            Utils.Set(ConvertedComponent, Handler, 'Handlers', id)
        });
    }
        

    if (Component.Data.Conditions) {
        ConvertedComponent.Conditions = Utils.DeepClone(Component.Data.Conditions);
    }
    
    ConvertedComponent.Interactions = [];

    const Events = Utils.JustGet(Component.Data, [], 'Interactions');
    if (Array.isArray(Events)) {
        Events.map((EventDef, i) => {            
            if (EventDef.triggerId) {
                const ConvertedEvent = {                    
                };

                if (EventDef.triggerId === 'event') {
                    ConvertedEvent.triggerId = EventDef.triggerId;
                    if (EventDef.onEventId) {
                        Utils.ForEach(EventDef.onEventId, (SystemStateValue, SystemState) => {
                            Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                Utils.Set(ConvertedEvent, StateValue.value, 'onEventId', SystemState, State);
                            });
                        });
                    }
                }
                else if (EventDef.triggerId === 'condition') {
                    ConvertedEvent.triggerId = EventDef.triggerId;
                    if (EventDef.conditionId) {
                        Utils.ForEach(EventDef.conditionId, (SystemStateValue, SystemState) => {
                            Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                Utils.Set(ConvertedEvent, StateValue.value, 'conditionId', SystemState, State);
                            });
                        });
                    }
                }
                else if (EventDef.triggerId === 'modelvalue') {
                    ConvertedEvent.triggerId = EventDef.triggerId;
                    if (EventDef.onModelId) {
                        Utils.ForEach(EventDef.onModelId, (SystemStateValue, SystemState) => {
                            Utils.ForEach(SystemStateValue, (StateValue, State) => {
                                Utils.Set(ConvertedEvent, StateValue.value, 'onModelId', SystemState, State);
                            });
                        });
                    }
                }
                else {
                    ConvertedEvent.name = MetaData.Events.GetHandler(EventDef.triggerId, PublishContext.Platform);
                }
                
                if (EventDef.disabled) {
                    Utils.ForEach(EventDef.disabled, (SystemStateValue, SystemState) => {
                        Utils.ForEach(SystemStateValue, (StateValue, State) => {
                            Utils.Set(ConvertedEvent, StateValue.value, 'disabled', SystemState, State);
                        });
                    });
                }
                if (EventDef.conditional && EventDef.conditionItems) {
                    ConvertedEvent.conditional = true;
                    const fnConvertCondition = (condition) => {
                        const convertedCondition = {};
                        if (condition) {
                            condition.conditionId && Utils.ForEach(condition.conditionId, (StateValue, State) => {
                                StateValue.value && Utils.Set(convertedCondition, StateValue.value, 'ConditionId', State);
                            });
                            Utils.ForEach(condition.runActionId, (StateValue, State) => {
                                StateValue.value && Utils.Set(convertedCondition, StateValue.value, 'ActionId', State);
                            });
                        }                        
                        return convertedCondition;
                    };
                    ConvertedEvent.conditionItems = {};
                    ConvertedEvent.conditionItems.if = fnConvertCondition(EventDef.conditionItems.if);
                    Utils.ForEach(EventDef.conditionItems.elseifs, (elseif, i) => {
                        const elseifs = Utils.Get(ConvertedEvent.conditionItems, [], 'elseifs');
                        elseifs.push(fnConvertCondition(elseif));
                    });
                    ConvertedEvent.conditionItems.else = fnConvertCondition(EventDef.conditionItems.else);
                }
                else {
                    Utils.ForEach(EventDef.runActionId, (SystemStateValue, SystemState) => {
                        Utils.ForEach(SystemStateValue, (StateValue, State) => {
                            Utils.Set(ConvertedEvent, StateValue.value, 'ActionId', SystemState, State);
                        });
                    });
                }
                ConvertedComponent.Interactions.push(ConvertedEvent);
            }
        })
    }

    // Public
    ConvertedComponent.Public = {

    };
    Utils.ForEach(Component.Data.Handlers, (Event, Id) => {
        Utils.ForEach(Event.Actions, (Action) => {
            if (Action.Type === MetaData.Actions.Propagate.Type) {
                const PublicEvent = {
                    Id : Id,
                    Name : Event.Name
                };
                const PublicHandlers = Utils.Get(ConvertedComponent.Public, [], 'Handlers');
                PublicHandlers.push(PublicEvent);
                return false;
            }

        })
    });

    
    return ConvertedComponent;
}

export default Publish_Component;

const ConvertModelValue = (Model) => {
    const ModelValue = {};
    if (Model.TokenId)
        ModelValue.TokenId = Model.TokenId;            
    else
        ModelValue.value = Model.value;
    return ModelValue;
}

export const Publish_Models = ({Models, Target}) => {    
    const ModelOrders = Utils.JustGet(Models, [], 'Order');
    Utils.ForEach(ModelOrders, (Id, i) => {
        const Model = Utils.JustGet(Models, null, 'List', Id);
        if (Model) {    
            const ConvertedValue = ConvertModelValue(Model);                
            ConvertedValue.Name = Model.name;
            ConvertedValue.dataType = Model.dataType;
            
            if (Model.dataType === MetaData.DataTypes.size && Model.Unit) {
                ConvertedValue.Unit = Model.Unit;
            }
            const stateBinding = Utils.JustGet(Model, {}, 'stateBinding');             
            if (stateBinding && stateBinding.variations) {
                ConvertedValue.stateVariations = stateBinding.variations;
            }
            Utils.Set(Target, ConvertedValue, 'Models', Id);                              
        }
    });
}