import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import {TokenGroup, GroupTitle, TokenItemBox, TokenPanelHeader, TokenItemBoxHoveredName, TokenItemDragHandle} from '../../common';
import DragSource from '../../../../../../../components/dragdrop/HoCDragSource';
import { FontLoader } from '../../../../../../../toolabs-importer';
import FontStyleEditor from './item';
import { LeftScrollPanel } from '../../../common';
import { StatefulTokenMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';
export default class FontTokens extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SelectFont = this.SelectFont.bind(this);
        this.onDeleteFont = this.onDeleteFont.bind(this);
        this.onCancelAddFont = this.onCancelAddFont.bind(this);
        this.onSubmitNewFont = this.onSubmitNewFont.bind(this);

        this.dark = true;        
    }        
    SelectFont() {
        if (!this.props.hasEditGrant)
            return;

        this.props.onSelectFont(this.RCUpdate, null, null);
    }
    onEditFont(id) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.EditFontId = id;
        const isDefaultFont = this.EditFontId === 'DefaultFont';
        // this.props.onPanelOverlay({
        //     show : true,
        //     type  : 'EditFont',
        //     onClose : this.onCancelAddFont,
        //     onDelete : isDefaultFont ? null : this.onDeleteFont,
        //     id : id,
        //     onSelectFont : this.props.onSelectFont,
        //     isDefaultFont : isDefaultFont,
        // })
        // return;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <FontStyleEditor 
                        id={id}
                        isDefaultFont={isDefaultFont}
                        GlobalStateId={this.props.GlobalStateId}
                        onClose={this.onCancelAddFont}
                        onCancel={this.onCancelAddFont}
                        onDelete={isDefaultFont ? null : this.onDeleteFont}
                        onBroadCastChange={this.props.onBroadCastChange}
                    />              
                )
            }            
        })
    }
    onCancelAddFont() {
        delete this.IsNew;
        delete this.EditFontModel;
        delete this.EditFontId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewFont() {
        this.onCancelAddFont();
    }
    onDeleteFont() {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.DELETE_TOKEN.ALL)) {
            return;
        }

        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Fonts, this.EditFontId)) {
            this.onCancelAddFont();
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            return true;
        }
        return super.ShouldUpdate(nextProps, nextState);
    }
    renderCustom() {
        const fonts = Globals.ProjectManager.Tokens.Fonts();
        const defaultFont = Globals.ProjectManager.Tokens.DefaultFont();
        const infoDefault = {};
        const defaultFontValue = Globals.ProjectManager.Tokens.ValueOf({model : Globals.ProjectManager.Tokens.Token('DefaultFont'), info : infoDefault}) || {};
        const fontItems = [
            <FontSelectItem key={'DefaultFont'} id={'DefaultFont'} font={defaultFont} valueState={infoDefault.ValueState} onClick={this.onEditFont.bind(this, 'DefaultFont')} />
        ];
        
        Utils.ForEach(fonts, (fid, i) => {
            if (fid !== 'DefaultFont') {
                const token = Globals.ProjectManager.Tokens.Token(fid);
                const info = {};
                const font = Globals.ProjectManager.Tokens.ValueOf({model : token, info : info}) || {};
    
                fontItems.push(
                    <FontSelectItem key={fid} font={font} valueState={info.ValueState} onClick={this.onEditFont.bind(this, fid)} />
                )
            }            
        });

        return (
            <SC.FCol style={{marginTop : '4px'}}>
                <GroupTitle subGroup title='Fonts' hasAdd={this.props.hasEditGrant ? {onAdd : this.SelectFont} : null} style={{paddingLeft : '12px'}} />
                <SC.FCol style={{paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'}}>
                    {fontItems}
                </SC.FCol>
            </SC.FCol>
        )
    }
}

export const FontSelectItem = ({onClick, selected, sortableProps, valueState, ...rest }) => {    
    return (
        <TokenItemBox onClick={onClick} selected={selected}>
            {   
                sortableProps && <TokenItemDragHandle {...sortableProps.handleProps} />                    
            }                    
            <TokenItemBoxHoveredName style={sortableProps ? {} : {transform : 'translateX(0)'}}>
                {
                    valueState && <StatefulTokenMarker {...valueState} />
                }
                <FontFamilyPreview {...rest} />
            </TokenItemBoxHoveredName>                            
        </TokenItemBox>
    )
}

export const FontFamilyPreview = (props) => {
    const font = props.font || {};        
    const style = {fontSize : '14px', overflow : 'hidden', transition : 'all 0.3s ease', ...Globals.ProjectManager.Tokens.Get_FontFamilyOf(font), ...props.style};
    let preview = props.text;
    if (!Utils.IsNotNullOrEmpty(preview)) {
        preview = FontFamilyDisplayName(font);
    }
        
    return (
        <SC.TextDivAbbr style={style}>{preview}</SC.TextDivAbbr>
    )
}

export const FontFamilyDisplayName = (font) => {
    let preview = '';
    let variant = font.variant ? font.variant.toString() : Utils.UseNullOrEmpty(font.style, 'regular');
    let fontStyle = '';
    if (variant && variant.indexOf('italic') > -1) {
        variant = variant.replace('italic', '');
        fontStyle = ' italic';
    }
    let textFamily = font.family;
    if (font.provider === Strings.FONT_WEBSAE)
        textFamily = font.name;

    let textVariant = Utils.UseUndefined(variant, '');
    if (font.provider === Strings.FONT_WEBSAE) 
        textVariant = font.weight ||'normal';
    preview = textFamily + ' ' + textVariant + fontStyle;
    return preview;
}

export const GetFontItemList = () => {
    return GetFontItemListOfIds(Globals.ProjectManager.Tokens.Fonts());
}

export const GetFontItemListOfIds = (fontIds) => {
    const fontItems = [];
    Utils.ForEach(fontIds, (fid, i) => {
        const token = Globals.ProjectManager.Tokens.Token(fid);
        if (token) {
            const font = Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};

            fontItems.push({
                id : fid,
                name : token.name,
                font : font
            });
        }        
    });

    return fontItems;
}