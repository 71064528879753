
import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Events,
    UIUtils,
    Utils,
    Strings,
    Globals
} from '../../../importer';

import styled from 'styled-components';

import GradientEditor from './editor';
import GradientAngle from './angle';
import * as Icons from './icons';
import InputSlider from '../input_slider';
import { ColorTokenCardItem } from '../../../views/project/panels/left/designsystem/colors';
import { StyleToken_Color } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { GroupTitle } from '../../../views/project/panels/left/designsystem/common';

class AddNewStyle_Gradient extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.SelectColor = this.SelectColor.bind(this);
        this.SelectPoint = this.SelectPoint.bind(this);
        this.ChangeColor = this.ChangeColor.bind(this);
        this.SaveColor = this.SaveColor.bind(this);
        this.onChangeAngle = this.onChangeAngle.bind(this);
        this.onChangeCurve = this.onChangeCurve.bind(this);
        this.AddStopPoint = this.AddStopPoint.bind(this);
    }
    AddStopPoint() {
        const gradient = Utils.Get(this.props.item, {}, 'gradient') || {}
        const colors = Utils.Get(gradient, [], 'colors');
        colors.push({
            stop : 50,
            Id : Utils.Id(),
            colorId : null
        });
        this.RCUpdate();
    }
    SelectPoint(point) {
        this.SelectColorPoint = point;
        this.RCUpdate();
    }
    SelectColor(point) {
        this.props.onEditColor(point.Id, point.color, (color) => {
            point.color = color;
            this.RCUpdate();
            const {gradeint, type, colors, path} = InitializeGradientToken(this.props.item);
            this.gradientValue = Utils.GetGradientCss(colors, type || 'linear', path.angle);
            this.props.onSave && this.props.onSave(this.props.item, this.gradientValue);
        })
        return;
        this.props.onSelectColor(
            (tokenId) => {                
                point.colorId = tokenId;
                this.props.onBindColorTokenToGradient && this.props.onBindColorTokenToGradient(point);
                delete point.previewColorId;

                this.RCUpdate();
                this.props.onSave && this.props.onSave(this.props.item);
            },
            (tokenId, show) => {
                if (show) {                    
                    point.previewColorId = tokenId;
                }
                else {
                    delete point.previewColorId;
                }
                this.RCUpdate();
                this.props.onSave && this.props.onSave(this.props.item);
            },
            () => {
                if (point.previewColorId) {
                    delete point.previewColorId;
                    this.RCUpdate();
                    this.props.onSave && this.props.onSave(this.props.item);
                }
            },
            () => {
                if (point.previewColorId) {
                    this.RCUpdate();
                }
            },            
            point.colorId
        );        
    }
    ChangeColor(c) {
        if (this.SelectColorPoint) {
            this.SelectColorPoint.color = c;
        }
        this.RCUpdate();
    }
    SaveColor() {
        this.SelectColorPoint = null;
        this.RCUpdate();
    }
    HasError() {
        this.Error_Value = false;
        if (!this.props.item.gradient) {
            this.Error_Value = true;
            this.RCUpdate();
            return true;
        }
    }
    SetLinearType(type) {
        this.props.item.type = type;
        this.RCUpdate();
        this.props.onSave && this.props.onSave(this.props.item, this.gradientValue);
    }
    onChangeCurve(path, colors) {
        Utils.Set(this.props.item, {
            path : path,
            colors : colors
        }, 'gradient');
        this.RCUpdate();
        const type = Utils.Get(this.props.item, 'linear', 'type');
        this.gradientValue = Utils.GetGradientCss(colors, type || 'linear', path.angle);
        this.props.onSave && this.props.onSave(this.props.item, this.gradientValue);
    }
    onChangeAngle(e) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        let dur = Utils.ToNumber(e.target.value);
        if (isNaN(dur))
            return;
        Utils.Set(this.props.item, dur, 'gradient', 'path', 'angle');
        this.RCUpdate();
    }
    ChangingStop(point, value) {
        point.stop = value;
        this.RCUpdate();
    }
    ChangeStop(point, value) {
        point.stop = value;
        this.RCUpdate();
        this.props.onSave && this.props.onSave(this.props.item, this.gradientValue);    
    }
    DeleteStop(stop, index) {
        const gradient = Utils.Get(this.props.item, {}, 'gradient') || {}
        const colors = Utils.Get(gradient, [], 'colors');
        colors.splice(index, 1);
        this.props.onSave && this.props.onSave(this.props.item, this.gradientValue);
        this.RCUpdate();
    }
    renderCustom() {

        const {item} = this.props;
        const model = item;

        const {gradeint, type, colors, path} = InitializeGradientToken(model);

        // const gradient = Utils.Get(model, {}, 'gradient') || {};
        // const type = Utils.Get(model, 'linear', 'type');
        // const colors = Utils.Get(gradient, [{
        //     color : "rgba(69, 73, 73, 1)",
        //     stop : 15,
        //     Id : Utils.Id()
        // },
        // {
        //     color : "rgba(10, 10, 10, 1)",
        //     stop : 100,
        //     Id : Utils.Id()
        // }], 'colors');
        // const path = Utils.Get(gradient, {
        //     p1 : {
        //         x : 128,
        //         sx : 128,
        //         y : 256,
        //         sy : 256
        //     },
        //     p2 : {
        //         x : 128,
        //         sx : 128,
        //         y : 0,
        //         sy : 0
        //     },
        //     angle : 305
        // }, 'path');

        Utils.ForEach(colors, (color, i) => {
            let colorId = color.previewColorId || color.colorId;            
            if (colorId)
                color.color = Globals.ProjectManager.Tokens.GetStateValue({Id : colorId, StateArray : this.props.StateArray});
        });

        const gradientValue = Utils.GetGradientCss(colors, type || 'linear', path.angle);

        return (
            <SC.FCol>
                <SC.FCol style={{position:'relative'}} alc>
                    <GradientEditor
                        editing
                        circle
                        linear={path}
                        colors={colors}
                        onChangeCurve={this.onChangeCurve}
                        onSelectColor={this.SelectPoint}
                        gradientValue={{value : gradientValue}}
                        gradientType={type || 'linear'}
                        onChangingAngle={(angle) => this.Ref_Angle && this.Ref_Angle.UpdateAngle(angle)}
                        onChangeGradientCss={this.props.onChangeGradientCss}
                        stateId={this.props.stateId}
                        ownerPosition={this.props.ownerPosition}
                        onBeforeChange={this.props.onBeforeChange}
                    />
                </SC.FCol>
                <SC.FCol style={{maxWidth : '270px', alignSelf : 'center', width : '100%'}}>
                    <SC.FRow jsb alc style={{}}>
                        <SC.FCol>
                            <GradientAngle angle={path.angle}  onChange={this.onChangeAngle}  ref={(r) => this.Ref_Angle = r}/>
                        </SC.FCol>
                        <SC.FRow>
                            <SC.Div_Flex_Cell cursorPointer onClick={this.SetLinearType.bind(this, 'linear')} pa={4}>
                                <Icons.Linear selected={type !== 'radial'} size={28}/>
                            </SC.Div_Flex_Cell>
                            <SC.Div_Flex_Cell cursorPointer onClick={this.SetLinearType.bind(this, 'radial')} pa={4}>
                                <Icons.Radial selected={type === 'radial'} size={28}/>
                            </SC.Div_Flex_Cell>
                        </SC.FRow>
                    </SC.FRow>
                    <GroupTitle 
                        title='Colors'
                        hasAdd={{onAdd : this.AddStopPoint}}
                        style={{
                            paddingLeft : 0,
                            paddingRight : 0,
                            marginTop : 0,
                            marginBottom : '4px',
                            marginRight : '-4px'
                        }}
                    />
                    {
                        colors.map((point, i) => {
                            return (
                                <PointEditor 
                                    key={i}
                                    stop={point.stop}
                                    color={point.color}
                                    onChangingStop={this.ChangingStop.bind(this, point)}
                                    onChangeStop={this.ChangeStop.bind(this, point)}
                                    onSelectColor={this.SelectColor.bind(this, point)}
                                    onDelete={this.DeleteStop.bind(this, point, i)}
                                />
                            )
                        })
                    }                    
                </SC.FCol>                
            </SC.FCol>
        )
    }
}

class PointEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        const token = {
            value : this.props.color
        }
        return (
            <SC.FRow>
                <InputSlider 
                    horizontal
                    reverse
                    inputStyle={{fontSize : '11px', width : '20px'}}
                    value={this.props.stop} 
                    min={0} 
                    max={100} 
                    postFix={(<div style={{fontSize : '11px'}}>%</div>)}
                    onChanging={this.props.onChangingStop} 
                    onChange={this.props.onChangeStop} 
                />
                <SC.GridBackground 
                    onClick={this.props.onSelectColor}
                    title={token.name} 
                    small 
                    dark={!SC.CurrentTheme.theme.isLight} 
                    style={{hoverflow : 'hidden', cursor : 'pointer', width : '32px', margin : '2px', borderRadius : '2px', overflow : 'hidden', height : '24px', boxSizing : 'border-box', marginLeft : '8px', marginRight : '4px'}}
                >
                    <div
                        style={{backgroundColor : token.value, width : '100%', height : '100%', fontWeight : 500, boxSizing : 'border-box'}} 
                    >
                        
                    </div>
                </SC.GridBackground>                  
                <SC.Icons.Icon_Button hasFill hasCursor onClick={this.props.onDelete}>
                    <SC.Icons.Icon_Delete size={16} />
                </SC.Icons.Icon_Button>
            </SC.FRow>
        )
    }
}

export default AddNewStyle_Gradient;

const GradientBox = styled.div`
    flex : 1;    
    position : relative;
    border: none;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    align-items : stretch;
    alignself : 
`;


export const InitializeGradientToken = (model) => {
    const gradient = Utils.Get(model, {}, 'gradient') || {};
    const type = Utils.Get(model, 'linear', 'type');
    const colors = Utils.Get(gradient, [{
        color : "rgba(69, 73, 73, 1)",
        stop : 15,
        Id : Utils.Id()
    },
    {
        color : "rgba(10, 10, 10, 1)",
        stop : 100,
        Id : Utils.Id()
    }], 'colors');
    const path = Utils.Get(gradient, {
        p1 : {
            x : 128,
            sx : 128,
            y : 256,
            sy : 256
        },
        p2 : {
            x : 128,
            sx : 128,
            y : 0,
            sy : 0
        },
        angle : 0
    }, 'path');

    return {
        gradient,
        type,
        colors,
        path
    }
}