import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import styled from 'styled-components';
import ChromaJs from 'chroma-js';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { SetFontTypeFace } from '../../../../views/project/panels/left/designsystem/typography';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss, GetShadowTokenItem } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel, FigmaIcon_Style, Figma_PopupItem } from '../../common';
import { GetColorTokenItem, GetcolorTokenValue, GetGradientTokenItem, GetColorTokenList } from '../../../../views/project/panels/left/designsystem/colors';
import { Seperator } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import { TokenGroup, EditableName } from '../../../../views/project/panels/left/designsystem/common'
import { OutsideCloseDotMenuContainer } from '../../../../views/project/panels/left/common';
import TextInput from '../../../../components/editors/textinput';
import TextValue from '../../../../components/editors/textvalue';
import { GetTextPatternTokenItem } from '../../../../views/project/panels/left/designsystem/typography/patterns';
import { SelectionItemBox } from '../selection/newstyleitem';

export default class LocalStyleTokenItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        };

        this.ShowNewTokenOption = this.ShowNewTokenOption.bind(this);
        this.CreateNewToken = this.CreateNewToken.bind(this);
    }

    ShowNewTokenOption() {      
        this.setState({showToolbar : true})
    }
    CreateNewToken() {
        this.setState({showToolbar : false}, () => {
            this.props.onNewToken();
        });        

    }

    render() {
        let toolbar;
        if (this.state.showToolbar) {
            toolbar = (
                <OutsideCloseDotMenuContainer onClose={() => {
                        this.setState({showToolbar : false})
                    }}
                    x='-50%'
                    style={{
                        left : '50%',
                        transform : 'translateX(-50%)',
                        width : '200px',
                        paddingTop : '4px',
                        paddingBottom : '4px',
                        marginTop : '-4px'
                    }}
                >
                    <SC.FCol>
                        <Figma_PopupItem onClick={this.CreateNewToken}>
                            <SC.Icons.Icon_Button hasFill style={{marginLeft : '-4px', paddingTop : '4px', paddingBottom : '4px'}}>
                                <SC.Icons.Icon_Plus />
                            </SC.Icons.Icon_Button>
                            <div style={{marginLeft : '4px'}}>Create New Token</div>
                        </Figma_PopupItem>
                        <Figma_PopupItem onClick={this.props.onSelectToken} >
                            <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                            <div style={{marginLeft : '10px'}}>Link To Existing Token</div>
                        </Figma_PopupItem>                         
                    </SC.FCol>
                </OutsideCloseDotMenuContainer>
            )
        }

        const {styleItem, type} = this.props;
        if (type === TokenTypes.COLOR) {
            return (
                <SelectionItemBox
                    jsb alc                
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                >
                    <FigmaColorBox  round color={styleItem.rgba} />
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                    {toolbar}
                </SelectionItemBox>
            )
        }
        else if (type === TokenTypes.Shadows) {
            return (
                <SelectionItemBox
                    jsb alc               
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption} 
                >
                    <div 
                        style={{
                            width : '20px',
                            height : '20px',
                            margin : '8px',
                            marginRight : '4px',
                            borderRadius : '2px',
                            backgroundColor : '#fafafa',
                            boxShadow : styleItem.css
                        }}
                    />
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                    {toolbar}
                </SelectionItemBox>
            )
        }        
        else if (type === TokenTypes.TextPatterns) {
            return (
                <SelectionItemBox
                    jsb alc      
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}          
                >
                    <div 
                        style={{                                
                            ...styleItem.preview,
                            fontSize : '14px',
                        }}
                    >
                        Ag
                    </div>
                    <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>{styleItem.name}</SC.TextDivAbbr>
                    {toolbar}
                </SelectionItemBox>
            )
        }
        return (
            <div>{styleItem.name}</div>
        )
    }
}