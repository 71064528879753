import ReactDOM from 'react-dom';
import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    MetaData
} from '../../../../importer';

import { motion, useMotionValue } from 'framer-motion';
import { TokenGroup, TokenPanelHeader, TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName } from '../../../../views/project/panels/left/designsystem/common';
import { LeftScrollPanel } from '../../../../views/project/panels/left/common';
import FigmaLayoutTokenEditor from './item';
import BaseTokenList from '../../../../views/project/panels/left/designsystem/BaseTokenList';
import { SortableListItem } from '../../../../components/SortableContainer';
import { Droppable } from 'react-beautiful-dnd';
import { FigmaIcon_Layout_Padding, FigmaIcon_Layout_Spacing } from '../../common';
import { DocumentToolbarButton } from '../../../../views/project/documents/designer/item/text/toolbar';
import { TextAlignLines } from '../../../../views/project/panels/right/iteminspector/styleitems/text';

export default class FigmaAutoLayouts extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'AUTO LAYOUTS';
        this.moduleName = Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts;
        this.tokenType = Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts;
        this.hasGroups = true;
        this.noListView = true;
        
        this.Ref_FigmaLayoutEdit = React.createRef();
        this.onEditFigmaLayout = this.onEditFigmaLayout.bind(this);
        this.onDeleteFigmaLayout = this.onDeleteFigmaLayout.bind(this);
        this.onCloneFigmaLayout = this.onCloneFigmaLayout.bind(this);
        this.onCancelAddFigmaLayout = this.onCancelAddFigmaLayout.bind(this);
        this.onSubmitNewFigmaLayout = this.onSubmitNewFigmaLayout.bind(this);

        AppLayout.Refs.DesignSystem.FigmaLayouts = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.FigmaLayouts = null;
    }
    Load(groupView) {        
        this.tokens = GetFigmaLayoutTokenList();        
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens)
    }      
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    } 
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        if (super.shouldComponentUpdate(nextProps, nextState)) {
            return true;
        }
        return false;
    }    
    AddTokenToGroup(group) {
        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditFigmaLayoutId = Utils.Id();
        this.EditFigmaLayoutModel = {
            name : 'New Auto Layout',
            horizontalPadding : 10,
            verticalPadding : 10,
            spacing : 10,
            vertical : true,
            fixed : false
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='NEW AUTO LAYOUT' 
                            notBackClosable
                            hasAddCancel 
                            onClose={this.onCancelAddFigmaLayout} 
                            onCancel={this.onCancelAddFigmaLayout} 
                            onAdd={this.onSubmitNewFigmaLayout} 
                        />
                        <LeftScrollPanel>
                            <FigmaLayoutTokenEditor 
                                ref={this.Ref_FigmaLayoutEdit}
                                newModel={this.EditFigmaLayoutModel}
                                GlobalState={props.GlobalState}
                                offline
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    onEditFigmaLayout(id, e) {
        if (this.props.onShowMenu && !this.IsNew) {
            this.props.onShowMenu(id, e);
            return;
        }
        this.EditLayout(id);
    }
    EditLayout(id) {        
        this.EditFigmaLayoutId = id;
        this.props.onPanelOverlay && this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader 
                            title='EDIT AUTO LAYOUT' 
                            hasAddCancel 
                            onClose={this.onSubmitNewFigmaLayout} 
                            onCancel={this.onCancelAddFigmaLayout} 
                            onAdd={this.onSubmitNewFigmaLayout} 
                            onDelete={this.onDeleteFigmaLayout} 
                            onClone={this.onCloneFigmaLayout}
                        />
                        <LeftScrollPanel>
                            <FigmaLayoutTokenEditor 
                                ref={this.Ref_FigmaLayoutEdit}
                                id={id}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                onCancel={this.onCancelAddFigmaLayout}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    onCloneFigmaLayout() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditFigmaLayoutId, Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts);
        this.Load(this.props);
        this.EditLayout(cloneId);        
    }
    onDeleteFigmaLayout() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts, this.EditFigmaLayoutId)) {
            this.Load(this.props);
            this.onCancelAddFigmaLayout();
        }
    }
    onCancelAddFigmaLayout() {
        delete this.IsNew;
        delete this.EditFigmaLayoutModel;
        delete this.EditFigmaLayoutId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewFigmaLayout() {
        if (this.IsNew) {
            
            const FigmaLayoutItem = AddNewFigmaLayoutToken(this.EditFigmaLayoutModel, this.EditFigmaLayoutId);
            this.tokens.push(FigmaLayoutItem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(FigmaLayoutItem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : FigmaLayoutItem.id})
                delete this.AddingToGroup;
            } 

            this.WillScrollTo = this.EditFigmaLayoutId;
            this.selectedId = this.EditFigmaLayoutId;            
        }        
        else {
            const token = Globals.ProjectManager.Tokens.Token(this.EditFigmaLayoutId);
            if (token) {
                const localpattern = Utils.Find(this.tokens, (item) => {return item.id === this.EditFigmaLayoutId});
                localpattern.name = token.name;
                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);
            }            
        }
                
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        this.onCancelAddFigmaLayout();
    }    
    renderTokenList(tokens, gorupId) {
        let style;
        if (this.state.isListView) {
            style = {
                marginLeft : '10px', marginRight : '10px', marginTop : '6px', 
                ...SC.Styles.Flex.Column
            }
        }
        else {
            style = {
                margin : '10px', marginBottom : '4px', marginTop : '4px', marginBottom : 0,
                ...SC.Styles.Flex.Column
            }
        }

        const FigmaLayoutItems = [];
        Utils.ForEach(tokens, (FigmaLayout, i) => {                   
            !FigmaLayout.filtered && FigmaLayoutItems.push(
                <SortableListItem
                    key={FigmaLayout.id}
                    draggableId={FigmaLayout.id}
                    index={i}
                    ItemBoxType={FigmaLayoutListItem}
                    BoxProps={{
                        FigmaLayout : FigmaLayout,
                        onSelect : this.onEditFigmaLayout.bind(this, FigmaLayout.id),
                        onContextMenu : this.onEditFigmaLayout.bind(this, FigmaLayout.id),
                    }}                
                />
            )
        }); 

        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',                     
                            }}
                        >
                            {FigmaLayoutItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }       
}

export const AddNewFigmaLayoutToken = (model, id) => {
    const values = {};
    Utils.ForEach(model, (value, prop) => {
        values[prop] = value;
    });
    delete values.name;

    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.FigmaAutoLayouts,
        name : model.name,
        ...values,
        id : id
    })    
    return GetFigmaLayoutTokenItem(id);
}

export const GetFigmaLayoutTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.FigmaLayouts();
    return GetFigmaLayoutTokenListOfIds(tokenids, StateArray);
}
export const GetFigmaLayoutTokenListOfIds = (tokenids, StateArray) => {    
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetFigmaLayoutTokenItem(id, StateArray));
    });  
    return items;
}

export const GetFigmaLayoutTokenItem = (id, StateArray) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return null;    
    return {
        id : id,
        name : token.name,
        ...GetFigmaLayoutValue(token, StateArray)
    };
}

export const GetFigmaLayoutValue = (token, StateArray) => {    
    const autoLayout =  {        
        spacing : Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'spacing', defaultValue : 10}),
        vertical : Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'vertical', defaultValue : false}),
        fixed : Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'fixed', defaultValue : false})
    };

    const justify = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'justify', defaultValue : 'flex-start'});
    if (justify === MetaData.Properties.FlexValues.FLEXSPACEBETWEEN)
        autoLayout.primaryAxisAlignItems = 'SPACE_BETWEEN';
    else if (justify === MetaData.Properties.FlexValues.FLEXCENTER)
        autoLayout.primaryAxisAlignItems = 'CENTER';
    else if (justify === MetaData.Properties.FlexValues.FLEXEND)
        autoLayout.primaryAxisAlignItems = 'MAX';
    else
        autoLayout.primaryAxisAlignItems = 'MIN';

    const align = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'align', defaultValue : 'flex-start'});
    if (align === MetaData.Properties.FlexValues.FLEXCENTER)
        autoLayout.counterAxisAlignItems = 'CENTER';
    else if (align === MetaData.Properties.FlexValues.FLEXEND)
        autoLayout.counterAxisAlignItems = 'MAX';
    else
        autoLayout.counterAxisAlignItems = 'MIN';

    const SpaceTokenId = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'SpaceTokenId'});
    if (SpaceTokenId) {
        const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(SpaceTokenId);            
        if (tokenModel) {
            const value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, StateArray);
            if (Utils.IsNotNullOrEmpty(value)) {
                autoLayout.spacing = value;
            }
        }        
    }

    const paddingTokenId = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : 'padding'});
    if (paddingTokenId) {
        const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(paddingTokenId);            
        if (tokenModel) {
            const value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, StateArray);
            if (Utils.IsNotNullOrEmpty(value)) {
                autoLayout.paddingTop = value;
                autoLayout.paddingLeft = value;
                autoLayout.paddingRight = value;
                autoLayout.paddingBottom = value;
            }
        }        
    }

    ['paddingTop', 'paddingLeft', 'paddingRight', 'paddingBottom'].map((propName) => {
        const paddingTokenId = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, name : propName});
        if (paddingTokenId) {
            const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(paddingTokenId);            
            if (tokenModel) {
                const value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, StateArray);
                if (Utils.IsNotNullOrEmpty(value)) {
                    if (autoLayout[propName] !== value)
                        autoLayout.mixedPadding = true;
                    autoLayout[propName] = value;                
                }
            }            
        }
    })

    return autoLayout;
}

export const FigmaLayoutListItem = ({onSelect, notDraggable, style, sortableProps, FigmaLayout, onPreview, selected, ...rest}) => {
    const style_box = {
        ...style,
        padding : '6px',
        // paddingTop : '4px',
        ...SC.Styles.Flex.Column,
        minHeight : '60px'
    }
    if (selected) {
        style_box.borderLeft = '1px solid', 
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox onClick={onSelect} style={style_box} {...onPreview} {...rest} selected={selected}>
            <SC.FRow f1 alc overflowHidden>
                {
                    sortableProps && 
                    <TokenItemDragHandle {...sortableProps.handleProps} />
                }                          
                <TokenItemBoxHoveredName style={sortableProps ? {} : {FigmaLayout : 'translateX(0)'}}>
                    <SC.TextDivAbbr style={{flex : 1}}>
                        {FigmaLayout.name}
                    </SC.TextDivAbbr>
                </TokenItemBoxHoveredName>
            </SC.FRow>  
            <div style={{
                display : 'grid',
                gridTemplateColumns : '1fr auto auto auto auto',
                alignItems : 'center',
                gridGap : '8px',
                justifyItems : 'center',
                marginTop : '8px'
            }}>
                <SC.FRow style={{justifySelf : 'start', paddingLeft : '8px'}}>
                    <TextAlignLines selected={!FigmaLayout.vertical} boxed align='stretch'  style={{transform : 'rotate(90deg)'}}/>
                    <TextAlignLines selected={FigmaLayout.vertical} boxed align='stretch' style={{paddingLeft : '16px'}} />
                </SC.FRow>             
                <SC.FCol alc style={{paddingRight : '16px'}}>
                    <div style={{justifySelf : 'left'}}>{FigmaLayout.paddingTop}</div>
                    <SC.FRow alc>
                        <div style={{justifySelf : 'left'}}>{FigmaLayout.paddingLeft}</div>
                        <FigmaIcon_Layout_Padding />
                        <div style={{justifySelf : 'left'}}>{FigmaLayout.paddingRight}</div>
                    </SC.FRow>
                    <div style={{justifySelf : 'left'}}>{FigmaLayout.paddingTop}</div>
                </SC.FCol>
                <FigmaIcon_Layout_Spacing />
                <div style={{justifySelf : 'left'}}>{FigmaLayout.spacing}</div>
            </div>              
            
        </TokenItemBox>
    )
}