import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Events,
    Utils,
    MetaData
} from '../../../importer'

import styled, {css} from 'styled-components';
import Clipboard from 'clipboard';

const BACKGROUND = '#1E1E1E';
const BACKGROUND_DARKER = '#171717';

export default class CodeViewer extends React.Component
{    
    componentDidMount() {
        this.Highlight();               
    }
    componentDidUpdate() {
        this.Highlight();
    }
    Highlight() {
        if (this.Ref_Code && window.hljs) {
            window.hljs.highlightBlock(this.Ref_Code);
        } 
    }
    render() {
        const {MetaObject} = this.props;    
        const content = (
            <code
                ref={(r) => this.Ref_Code = r}
                className={this.props.className || (this.props.js ? 'javascript' : 'json')}
                style={{padding : '12px', boxSizing : 'border-box', overflow : 'auto', height : '100%', backgroundColor : this.props.darker ? BACKGROUND_DARKER : BACKGROUND, ...this.props.codeStyle}}
            >
                {MetaObject && JSON.stringify(MetaObject, null, 2)}
                {this.props.children}
            </code>
        )
        return (
            <pre style={Utils.Merge({userSelect : 'text', margin : 0, overflow : 'auto'}, this.props.style)}>
                {content}       
            </pre>
        )
    }
}

export class CodeCopyBox extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.IdCode = 'Id' + Utils.Id();
    }
    componentDidMount() {
        super.componentDidMount();
        this.clipboard = new Clipboard(`#${this.IdCode}`, {
            text: () => {
                this.clipboardCopied = true;
                setTimeout(() => {
                    this.clipboardCopied = false;
                    this.RCUpdate();
                }, 1500);
                this.RCUpdate();
                if (this.Ref_Code) {
                    if (this.props.copyCode)
                        return this.props.copyCode;
                    return this.Ref_Code.innerText;    
                }
                return '';
            }
        });        
        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.clipboard)
            this.clipboard.destroy();
    }
    renderCustom() {
        return (
            <ComponentCodeBox id={this.IdCode} style={this.props.style} title='Click to copy'>
                <SC.FRow alc jsb style={{padding : '8px', paddingTop : 0}}>
                    {this.props.children || <div />} 
                    <CopyIcon visible={true}>
                        <SC.Icons.Icon_Button hasFill>
                            <SC.Icons.Icon_Clone size={20} />
                        </SC.Icons.Icon_Button>
                        <SC.Div_Flex_Cell ml={8}>
                            {this.clipboardCopied ? 'copied' : 'Click to Copy'}
                        </SC.Div_Flex_Cell>                        
                    </CopyIcon>
                </SC.FRow>                
                <CodeViewer js>
                    <div ref={(r) => this.Ref_Code = r} >
                        {this.props.code}
                    </div>
                </CodeViewer>                    
            </ComponentCodeBox>  
        );
    }
}

const ComponentCodeBox = styled.div`    
    position : relative;
    cursor : pointer;
    user-select : text;
    flex : 1;
    font-size : 12px;
`;


const CopyIcon = styled.div`    
    opacity : ${props => props.visible ? 1 : 0};    
    display : flex;
    align-items : center;
    justify-content : flex-end;    
    ${SC.TransitionHoverOn}
    ${ComponentCodeBox}:hover &{
        ${SC.TransitionHoverOff}
        opacity : 1;
    }
`;