import React from 'react';
import ReactDOM from 'react-dom';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import { Utils, MetaData } from '../../../importer';

class RangeInput extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.onChanging = this.onChanging.bind(this);
		this.Ref_Input = React.createRef();
		this.state = {
			value: Utils.UseNull(this.props.value, 0)
		}
		this.updateStyle(props);
	}
	componentDidMount() {
		const DN = ReactDOM.findDOMNode(this.Ref_Input.current);
		DN.addEventListener('input', this.onChanging);
		DN.addEventListener('change', this.onChange);
	}
	componentWillUnmount() {
		Utils.RemoveStyleSheet(this.props.classId);
	}	
	updateStyle(props) {
		const style = props.style;
		const classId = props.classId;

		const {
			':active': styleobj_track_active,
			':hover': styleobj_track_hover,
			':focus': styleobj_track_focus,
			...styleobj_track_rest } = style.track;

		const {
			':active': styleobj_thumb_active,
			':hover': styleobj_thumb_hover,
			':focus': styleobj_thumb_focus,
			...styleobj_thumb_rest } = style.thumb;

		const style_track = {
			height: '8px',
			cursor: 'pointer',
			...styleobj_track_rest,
			width: '100%',
			display: 'flex',
			alignItems: 'center',
			flexDirection: 'row',
			justifyContent: 'unset',
			alignSelf: 'unset',
			flex: 'unset',
			boxSizing: 'unset'
		};
		const style_thumb = {
			cursor: 'pointer',
			...styleobj_thumb_rest,
		};

		let css_track = Utils.ConvertObjectToCss(style_track);
		let css_track_hover = Utils.ConvertObjectToCss(styleobj_track_hover);
		let css_track_active = Utils.ConvertObjectToCss(styleobj_track_active);
		let css_track_focus = Utils.ConvertObjectToCss(styleobj_track_focus);

		let css_thumb = Utils.ConvertObjectToCss(style_thumb);
		let css_thumb_hover = Utils.ConvertObjectToCss(styleobj_thumb_hover);
		let css_thumb_active = Utils.ConvertObjectToCss(styleobj_thumb_active);
		let css_thumb_focus = Utils.ConvertObjectToCss(styleobj_thumb_focus);

		Utils.SetStyleSheet({
			Id: classId,
			StyleDefinition: `
        .${classId} {
            -webkit-appearance: none;
			padding-top : 8px;
			padding-bottom : 8px;
			cursor : pointer;
            width: 100%;
          }
          .${classId}:focus {
            outline: none;
          }
          .${classId}::-webkit-slider-runnable-track {
              ${css_track}
          }
          .${classId}::-webkit-slider-thumb {
            ${css_thumb}
            -webkit-appearance: none;
          }
          .${classId}:focus::-webkit-slider-runnable-track {
            ${css_track_focus}
          }
          .${classId}:hover::-webkit-slider-runnable-track {
            ${css_track_hover}
          }
          .${classId}:active::-webkit-slider-runnable-track {
            ${css_track_active}
          }
          .${classId}:focus::-webkit-slider-thumb {
            ${css_thumb_focus}
          }
          .${classId}:hover::-webkit-slider-thumb {
            ${css_thumb_hover}
          }
          .${classId}:active::-webkit-slider-thumb {
            ${css_thumb_active}
          }
          .${classId}::-moz-range-track {
            ${css_track}
          }
          .${classId}::-moz-range-thumb {
            ${css_thumb}
          }
          .${classId}::-ms-track {
            ${css_track}
          }
          .${classId}::-ms-fill-lower {
            background: #2a6495;
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          .${classId}::-ms-fill-upper {
            background: ${style.track.background};
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          .${classId}::-ms-thumb {
            ${css_thumb}
          }
          .${classId}:focus::-ms-fill-lower {
            ${css_track_focus}
          }
          .${classId}:focus::-ms-fill-upper {
            ${css_track_focus}
          }
        `, TargetDocument: this.props.document
		})
	}
	shouldComponentUpdate(nextProps, nextState) {
		if (!this.suspendPropUpdate) {
			if (!Utils.IsEqual(this.props.style, nextProps.style)) {
				this.updateStyle(nextProps);
			}
			if (nextProps.value !== nextState.value) {
				this.state.value = nextProps.value;
				return true;
			}
		}
		
		return true;
	}
	onChange(e) {
		this.suspendPropUpdate = false;
		let value = Number(e.target.value);
		this.setState({ value: value }, () => {
			this.props.onChange(value, e);
		})
	}
	onChanging(e) {
		this.suspendPropUpdate = true;
		let value = Number(e.target.value);
		this.setState({ value: value }, () => {
			this.props.onChange(value, e);
		})
	}
	render() {
		const { min, max, step } = this.props;
		return (
			<input
				ref={this.Ref_Input}
				type='range'
				min={min}
				max={max}
				step={step}
				value={this.state.value}
				disabled={this.props.disabled}
				className={this.props.classId}
				style={{
					backgroundColor: 'unset'
				}}
			/>
		)
	}
}

class Generic_RangeInput extends BaseItem {
	constructor(props) {
		super(props);

		this.InstanceId = Utils.Id();
		this.onChange = this.onChange.bind(this);
	}
	onChange(value, e) {
		this.props.onSetModelValue('Value', value);
		return;

		const modelid = Utils.JustGet(this.StateStatics, null, 'variables_current', 'value');
		if (modelid)
			this.props.onSetModelValue(modelid, value);

		this.props.onChange && this.props.onChange(value, e);
		if (this.props.events) {
			if (this.props.events.onChange)
				this.props.events.onChange(e);
		}
	}
	renderItem({ style, props, events, rest }) {


		let style_thumb;
		const { MetaItem } = this.props;
		const ThumbId = Utils.JustGet(MetaItem.Generic, null, 'ThumbId');
		if (ThumbId) {
			const MetaItemThumb = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === ThumbId });
			if (MetaItemThumb) {
				const ThumbStlye = BuildItemStyles(MetaItemThumb, this.props);
				if (ThumbStlye) {
					style_thumb = ThumbStlye.style;
				}
			}
		}

		const models = Utils.JustGet(this.props, {}, 'Models');

		return (
			<div style={{ width: style.width, maxWidth: style.maxWidth }}>
				<RangeInput
					classId={`gcssre-${this.InstanceId}`}
					document={this.props.document}
					disabled={this.props.IsStateActive('disabled', 'disabled')}
					onChange={this.onChange}
					value={models.Value}
					min={models.min}
					max={models.max}
					step={models.step}
					style={{
						track: style || {},
						thumb: style_thumb || {}
					}}
				/>
			</div>
		)
	}
}

export default Generic_RangeInput;
