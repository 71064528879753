import React from 'react';
import {
    SC,
    Globals,
    Utils,
    MetaData
} from '../../../../../../../importer';

import AppLayout from '../../../../../../../appstate/AppLayout';
import { FilterCardItem, FilterListItem, AddNewFilterToken, GetFilterTokenList } from '../../../../left/designsystem/filters';
import FilterTokenEditor from '../../../../left/designsystem/filters/item';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectFilterToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW FILTER';
        this.moduleName = 'Filters';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Filters;        

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetFilterTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Filter',
            items : {}
        };
        AddNewFilterToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const filterItem = AddNewFilterToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.Filters && AppLayout.Refs.DesignSystem.Filters.Reload();
            return filterItem;
        }        
        else {
        }                
    }   
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        let willDeleteId;
        if (this.EditingToken)
            willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Filters, willDeleteId);
    }
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const filter = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        const filters = Utils.JustGet(filter, [], 'items');
        SelectedToken.value = Utils.GetFilterCss(filters, MetaData.Properties.filter); 
    }
    GetStyleName() {
        return 'filter';
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();
        if (this.state.isListView) {
            const filters = [];

            Utils.ForEach(this.tokens, (filter, i) => {
                filters.push(
                    <FilterListItem
                        key={filter.id}
                        notDraggable
                        filter={filter}
                        onSelect={this.SelectToken.bind(this, filter.id)}
                        selected={filter.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, filter.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, filter.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FCol>
                    {filters}
                </SC.FCol>
            )
        }
        else {
            const filters = [];
            Utils.ForEach(this.tokens, (filter, i) => {
                filters.push(
                    <FilterCardItem
                        filter={filter}
                        notDraggable
                        key={filter.id} 
                        onClick={this.SelectToken.bind(this, filter.id)}
                        selected={filter.id === this.GetSelectedTokenId()}
                        onPreview={{
                            onMouseEnter : this.PreviewToken.bind(this, true, filter.id, styleName),
                            onMouseLeave : this.PreviewToken.bind(this, false, filter.id, styleName)
                        }}
                    />
                )
            });

            content = (
                <SC.FRow style={{flexWrap : 'wrap'}}>
                    {filters}
                </SC.FRow>
            )
        }

        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {content}
            </SC.CustomScrollbars>                             
        )       
    }
    renderTokenEditor() {
        return (
            <FilterTokenEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.filter;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
