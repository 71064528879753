import React, { useCallback, useMemo, useState, useEffect } from "react";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import { Editor, Transforms, Range, createEditor, Text, Point } from "slate";
import { withHistory } from 'slate-history'

import { AnimatePresence, motion } from 'framer-motion';
import styled, { css} from 'styled-components';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    AppLayout,
    Events,
    MetaData,
    UIUtils,
    Globals
} from '../../../../../../importer';

import SlateToolbar from './toolbar';

let toolbarEditing = false;


const CustomEditorCommands = {
	isBoldMarkActive(editor) {
		const [match] = Editor.nodes(editor, {
			match: n => n.bold === true,
			universal: true,
		})

		return !!match
	},
	isItalicMarkActive(editor) {
		const [match] = Editor.nodes(editor, {
			match: n => n.italic === true,
			universal: true,
		})

		return !!match
	},
	isUnderlineMarkActive(editor) {
		const [match] = Editor.nodes(editor, {
			match: n => n.underline === true,
			universal: true,
		})

		return !!match
	},
	isAlignmentActive(editor, alignment) {
		const [match] = Editor.nodes(editor, {
			match: n => n.alignment === alignment,
		})

		return !!match;
	},
	isBlockActive(editor, type) {
		const [match] = Editor.nodes(editor, {
			match: n => n.type === type,
		})

		return !!match
	},
	isCodeBlockActive(editor) {
		return CustomEditorCommands.isBlockActive(editor, 'code');
	},
	isLinkActive(editor) {
		let link;
		const [match] = Editor.nodes(editor, {
			match: n => { 
				if (n.link) {
					link = n.link;
					return true;
				}
			},
			universal: true,
		})
		return link;
	},
	getColor(editor) {
		let color;
		const [match] = Editor.nodes(editor, {
			match: n => { 
				if (n.color) {
					color = n.color;
					return true;
				}
			},
			universal: true,
		})
		return color;
	},
	toggleBoldMark(editor) {
		const isActive = CustomEditorCommands.isBoldMarkActive(editor)
		Transforms.setNodes(
			editor,
			{ bold: isActive ? null : true },
			{ match: n => Text.isText(n), split: true }
		)
	},
	toggleItalicMark(editor) {
		const isActive = CustomEditorCommands.isItalicMarkActive(editor)
		Transforms.setNodes(
			editor,
			{ italic: isActive ? null : true },
			{ match: n => Text.isText(n), split: true }
		)
	},
	toggleUnderlineMark(editor) {
		const isActive = CustomEditorCommands.isUnderlineMarkActive(editor)
		Transforms.setNodes(
			editor,
			{ underline: isActive ? null : true },
			{ match: n => Text.isText(n), split: true }
		)
	},
	toggleTextAlignment(editor, alignment) {
		const isActive = CustomEditorCommands.isAlignmentActive(editor, alignment);
		const value = {
			alignment : alignment
		};
		if (isActive)
			value.alignment = false;
		Transforms.setNodes(
			editor,
			value,
			{ match: n => Editor.isBlock(editor, n) }
		)
	},
	toggleCodeBlock(editor) {
		const isActive = CustomEditorCommands.isCodeBlockActive(editor);		
		Transforms.setNodes(
			editor,
			{ type: isActive ? null : 'code' },
			{ match: n => Editor.isBlock(editor, n) }
		)
	},
	toggleTextType(editor, textType) {
		const isActive = CustomEditorCommands.isBlockActive(editor, textType);
		const isList = LIST_TYPES.includes(textType);

		Transforms.unwrapNodes(editor, {
			match: n => LIST_TYPES.includes(n.type),
			split: true,
		});

		Transforms.setNodes(
			editor,
			{ type: isActive ? null : isList ? 'list-item' : textType },
			{ match: n => Editor.isBlock(editor, n) }
		);

		if (!isActive && isList) {
			const block = { type: textType, children: [] }
			Transforms.wrapNodes(editor, block)
		}
	},
	removeLink(editor) {
		Transforms.setNodes(
			editor,
			{ link: null },
			{ match: n => Text.isText(n), split: true }
		)
	},
	StoreLinkNode(editor) {		
		this.WillLinkTo = Utils.Id();
		Transforms.setNodes(
			editor,
			{ willLinkTo: this.WillLinkTo },
			{ match: n => Text.isText(n), split: true }
		)
	},
	StoreColorNode(editor) {		
		this.WillColorTo = Utils.Id();
		Transforms.setNodes(
			editor,
			{ WillColorTo: this.WillColorTo },
			{ match: n => Text.isText(n), split: true }
		)
	},
	saveColor(value, color) {
		const setChildLinks = (node) => {
			if (node) {
				if (node.WillColorTo === this.WillColorTo) {
					node.color = color;
					delete node.WillColorTo;
				}					
				else if (node.children) {
					Utils.ForEach(node.children, (child, i) => {
						setChildLinks(child);
					});
				}
			}

		}
		Utils.ForEach(value, (node, ) => {
			setChildLinks(node);
		});
	},
	setColor(value, color) {
		const setChildLinks = (node) => {
			if (node) {
				if (node.WillColorTo === this.WillColorTo) {
					node.color = color;
				}					
				else if (node.children) {
					Utils.ForEach(node.children, (child, i) => {
						setChildLinks(child);
					});
				}
			}

		}
		Utils.ForEach(value, (node, ) => {
			setChildLinks(node);
		});
	},
	setLink(value, link) {	
		const setChildLinks = (node) => {
			if (node) {
				if (node.willLinkTo === this.WillLinkTo) {
					node.link = link;
					delete node.willLinkTo;
				}					
				else if (node.children) {
					Utils.ForEach(node.children, (child, i) => {
						setChildLinks(child);
					});
				}
			}

		}		
		Utils.ForEach(value, (node, ) => {
			setChildLinks(node);
		});
	}
}

const LIST_TYPES = ['numbered-list', 'bulleted-list']

export const SLATE_ELEMENT_TYPES = {
	d1 : {
		type : 'd1'
	},
	h1 : {
		type : 'h1'
	},
	h2 : {
		type : 'h2'
	},
	h3 : {
		type : 'h3'
	},
	paragraph : {
		type : 'paragraph'
	},
	code : {
		type : 'code'
	},
	bold : {
		type : 'bold'
	},
	italic : {
		type : 'italic'
	},
	underline : {
		type : 'underline'
	}
}

const CustomEditor = (props) => {
	const editor = useMemo(() => withHistory(withReact(createEditor())), []);
	
	const value = props.value || [
		{
			type: props.type,
			children: [{ text: "" }]
		}
	];
	if (Array.isArray(value)) {
		value.map((textLine) => {
			if (!textLine.children) {
				textLine.children = [{ text: "" }];	
			}
		})
	}

	// const [value, setValue] = useState(props.value || [
	// 	{
	// 		type: props.type,
	// 		children: [{ text: "" }]
	// 	}
	// ]);

	// useEffect(() => {
	// 	setValue(value);
	// }, [value])


	const [focused, setFocused] = useState(props.autoFocus);
	const isEditingToolbar = props.id === toolbarEditing;

	const {textStyles, heroItem, shared} = props;	

	const renderElement = useCallback(props => {
		const blockStyle = {
			cursor : 'text'
		};
		const herostyle = {
			
		};
		if (heroItem) {
			herostyle.color = textStyles.heroTextColor;
		}
		if (props.element && props.element.alignment)
			blockStyle.textAlign = props.element.alignment;
		switch (props.element.type) {
			case 'code':
				return <CodeElement {...props} style={{...blockStyle, fontSize : '12px', ...textStyles.code, ...herostyle}} />
			case SLATE_ELEMENT_TYPES.d1.type: 
				return <TextElement {...props} style={{...blockStyle, ...textStyles.d1, ...herostyle}}/>
			case SLATE_ELEMENT_TYPES.h1.type: 
				return <TextElement tag={SLATE_ELEMENT_TYPES.h1.type} {...props} style={{...blockStyle, ...textStyles.h1, ...herostyle}}/>
			case SLATE_ELEMENT_TYPES.h2.type: 
				return <TextElement tag={SLATE_ELEMENT_TYPES.h2.type} {...props} style={{...blockStyle, ...textStyles.h2, ...herostyle}} />
			case SLATE_ELEMENT_TYPES.h3.type: 
				return <TextElement tag={SLATE_ELEMENT_TYPES.h3.type} {...props} style={{...blockStyle, ...textStyles.h3, ...herostyle}} />
			case 'subtitle': 
				return <TextElement tag={'div'} {...props} style={{...blockStyle, fontSize : '12px', ...textStyles.subtitle, ...herostyle}} />
			case 'list-item':
				return <li {...props.attributes} style={{...blockStyle}} >{props.children}</li>
			case 'numbered-list':
				return <ol {...props.attributes} style={{...blockStyle, ...textStyles.list, ...herostyle}} >{props.children}</ol>
			case 'bulleted-list':
      			return <ul {...props.attributes} style={{...blockStyle, ...textStyles.list, ...herostyle}} >{props.children}</ul>
			default:
				return <DefaultElement {...props}  style={{...blockStyle, ...textStyles.paragraph, ...herostyle}} />
		}
	}, []);
	
	const renderLeaf = useCallback(props => {
		return <Leaf {...props} shared={shared} />
	}, []);

	return (
		<Slate 
			editor={editor} 
			value={value} 
			onChange={value => {
				// setValue(value)
		
				// Save the value to Local Storage.
				props.onChange && props.onChange(value);
			}}
		>
			<AnimatePresence>
				{(isEditingToolbar || focused && !props.preview || ((props.selected || focused) && (toolbarEditing === props.id))) &&
					<div style={{
						position : 'absolute',
						left : props.toolbarOnRight ? 'unset' : '-80px',
						right : props.toolbarOnRight ? 0 : 'unset',
						bottom : '100%',
						paddingBottom : 0,
						paddingTop : '8px',
						paddingLeft : '80px',
						zIndex : 100000,
					}}>
						<motion.div 
							style={{
								border : SC.CurrentTheme.theme.border_popup,
								borderRadius : '2px',
								boxSizing : 'border-box',
								backgroundColor : SC.CurrentTheme.theme.back_lighter,
								boxShadow : SC.CurrentTheme.theme.popup_shadow,  
								...props.style              
							}}
							initial={{x : -20}}
							animate={{x : 0}}
							exit={{x : -20, opacity : 0}}
							transition={{duration : 0.2}}
						>
							<SlateToolbar
								fixedType={props.fixedType}
								id={props.id}
								onToolbarEditing={(editing) => {
									// console.log(`toolbarEditing : ${editing}`);
									if (!editing) {
										setTimeout(() => {
											toolbarEditing = editing;
										}, 10);
									}
									else
										toolbarEditing = editing;																		
								}}							
								onToggleBold={event => {
									event.preventDefault()
									CustomEditorCommands.toggleBoldMark(editor)
								}}
								onToggleItalic={event => {
									event.preventDefault()
									CustomEditorCommands.toggleItalicMark(editor)
								}}
								onToggleUnderline={event => {
									event.preventDefault()
									CustomEditorCommands.toggleUnderlineMark(editor)
								}}
								onToggleText={(textType) => {
									CustomEditorCommands.toggleTextType(editor, textType)
								}}
								onSaveLink={(link) => {
									CustomEditorCommands.setLink(props.value, link);
									delete CustomEditorCommands.WillLinkTo;
									// Save the value to Local Storage.
									props.onReloadValue && props.onReloadValue(props.value);
								}}
								onRemoveLink={() => {
									CustomEditorCommands.removeLink(editor)
								}}
								onStoreLinkNode={() => {
									CustomEditorCommands.StoreLinkNode(editor);
								}}
								onStoreColorNode={() => {
									CustomEditorCommands.StoreColorNode(editor);
								}}
								onSaveColor={(color) => {
									CustomEditorCommands.saveColor(props.value, color);
									delete CustomEditorCommands.WillColorTo;
									props.onReloadValue && props.onReloadValue(props.value);
								}}
								onChangeColor={(color) => {
									CustomEditorCommands.setColor(props.value, color);		
									props.onChange && props.onChange(Utils.DeepClone(props.value));
								}}
								onToggleAlign={(alignment) => {
									CustomEditorCommands.toggleTextAlignment(editor, alignment)
								}}
								d1={CustomEditorCommands.isBlockActive(editor, SLATE_ELEMENT_TYPES.d1.type)}
								h1={CustomEditorCommands.isBlockActive(editor, SLATE_ELEMENT_TYPES.h1.type)}
								h2={CustomEditorCommands.isBlockActive(editor, SLATE_ELEMENT_TYPES.h2.type)}
								h3={CustomEditorCommands.isBlockActive(editor, SLATE_ELEMENT_TYPES.h3.type)}
								paragraph={CustomEditorCommands.isBlockActive(editor, SLATE_ELEMENT_TYPES.paragraph.type)}
								isCode={CustomEditorCommands.isBlockActive(editor, 'code')}
								bold={CustomEditorCommands.isBoldMarkActive(editor)}
								italic={CustomEditorCommands.isItalicMarkActive(editor)}
								underline={CustomEditorCommands.isUnderlineMarkActive(editor)}
								isBulletList={CustomEditorCommands.isBlockActive(editor, 'bulleted-list')}
								isNumberList={CustomEditorCommands.isBlockActive(editor, 'numbered-list')}
								alignLeft={CustomEditorCommands.isAlignmentActive(editor, 'left')}
								alignCenter={CustomEditorCommands.isAlignmentActive(editor, 'center')}
								alignRight={CustomEditorCommands.isAlignmentActive(editor, 'right')}
								isLink={CustomEditorCommands.isLinkActive(editor)}
								color={CustomEditorCommands.getColor(editor)}
								onShowColorEditor={props.onShowColorEditor}
							/>
						</motion.div>				
					</div>
			}
			</AnimatePresence>
			
			<Editable 				
				renderElement={renderElement}
				renderLeaf={renderLeaf}
				autoFocus={props.autoFocus || focused || isEditingToolbar}
				spellCheck={false}
				readOnly={props.preview}
				onFocus={() => {
					// console.log(`Focused`);
					setFocused(true);
					if (props.preview)
						return;					
					// props.onSuspendToolbar && props.onSuspendToolbar(true);					
				}}
				onBlur={(event) => {
					// console.log(`Blur`);
					setFocused(false);
					if (props.preview)
						return;
					
						// if (toolbarEditing) {
						// 	toolbarEditing = false;
						// 	return;
						// }
												
					props.onSuspendToolbar && props.onSuspendToolbar(false);
				}}
				placeholder={props.preview ? '' : (props.placeholder || 'Start typing...')}
				onKeyDown={event => {					
					if (!event.ctrlKey) {
						return
					}
					
			
					switch (event.key) {
						// When "`" is pressed, keep our existing code block logic.
						case '`': {
							event.preventDefault()
							CustomEditorCommands.toggleCodeBlock(editor)
							break
						}
				
						case 'b': {
							event.preventDefault()
							CustomEditorCommands.toggleBoldMark(editor)
							break
						}
					}
				}}
			/>
		</Slate>
	)
}

const DefaultElement = props => {
	return <p {...props.attributes} style={{...props.style, margin : 0}} >{props.children}</p>
}
const CodeElement = props => {
	return (
		<pre {...props.attributes} style={{...props.style, backgroundColor : 'rgba(0, 0, 0, 0.1)'}}>
			<code>{props.children}</code>
		</pre>
	);
};
const TextElement = ({tag, ...props}) => {
	const TextElementType = tag || 'div';
	return (
		<TextElementType {...props.attributes}  style={{...props.style, margin : 0, cursor : 'text'}}>
			{props.children}
		</TextElementType>
	);
};
const Leaf = props => {
	const style = {
		fontWeight: props.leaf.bold ? 'bold' : 'inherit', 
		fontStyle : props.leaf.italic ? 'italic' : 'normal',
		textDecoration : props.leaf.underline ? 'underline' : 'none',
		color : props.leaf.color
	};
	if (props.leaf.link && props.shared) {
		return (
			<a
				{...props.attributes}
				href={props.leaf.link}
				style={style}
			>
				{props.children}
			</a>
		)
	}
	return (
		<span
			{...props.attributes}
			
			style={style}
		>
			{props.children}
		</span>
	)
}

export default CustomEditor;