import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../../importer';
import FigmaDesignSystem from '../designsystem';
import FigmaDsmSyncer from '../designsystem/localStyleTokenMappings';
import {EditableName} from '../../../views/project/panels/left/designsystem/common';
import { FigmaMenu, FigmaInfoPanel } from '../common';
import { FigmaProjectCard } from '../projectlist';
import FigmaProjectTokenStyleSyncer from './syncNewStyles';
import { FigmaPluginOptions } from '../options';
import TeamInfoButton from '../../../views/header/teambutton';
import { FigmaTeamMenu } from '../team';
import TeamPanel from '../../../views/project/panels/right/team';

export default class FigmaProject extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.state.loaded = false;

        this.ToggleMenu = this.ToggleMenu.bind(this);        
        this.SaveName = this.SaveName.bind(this);
        AppLayout.Refs.Ref_FigmaProject = this;
        this.Ref_SyncIcon = React.createRef();
        this.Ref_SystesPanel = React.createRef();
        this.Ref_InnerSystesPanel = React.createRef();
        this.Ref_Toolbar = React.createRef();

        this.Undo = this.Undo.bind(this);
        this.Redo = this.Redo.bind(this);
        super.ListenEvents(
            Events.DESIGNER.HISTORY.LIST,
            Events.DESIGNER.COMPONENT.HISTORY.LOG,
            Events.DESIGNER.COMPONENT.HISTORY.CHANGED,
            Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED
        );
        const Manager = this.GetManager();
        if (Manager) {
            this.HasHistoryPrev = Manager.HasPrev();
            this.HasHistoryNext = Manager.HasNext();     
        }     
    }
    componentDidMount() {
        super.componentDidMount();
        this.Load(this.props.projectId);

        if (this.props.firstTimeInThisFile) {
            
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();

        Globals.ProjectManager.Initialize();
        delete AppLayout.Refs.Ref_FigmaProject;
        this.props.GetPluginApi().LoadProject();
    }
    ToggleSyncIcon(show) {
        if (this.Ref_SyncIcon.current) {
            this.Ref_SyncIcon.current.style.opacity = show ? 1 : 0;
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.projectId !== nextProps.projectId) {
            this.Load(nextProps.projectId);
            this.state.loaded = false;
        }
        return true;
    }
    SaveName(name) {
        Globals.ProjectManager.SaveName(name);
        this.setState({willEditName : false});
    }
    GetManager() {
        return Globals.ProjectManager.HistoryManager;
    }
    OnEvent(Event, Params) {
        if (Event === Events.DESIGNER.COMPONENT.HISTORY.LOG) {      
            const Manager = this.GetManager();
            if (Manager) {
                this.HasHistoryPrev = Manager.HasPrev();
                this.HasHistoryNext = Manager.HasNext();
            }                  
        }
        else if (Event === Events.DESIGNER.COMPONENT.HISTORY.CHANGED) {
            // const [IsUndo, Desc] = Params;
            // this.ShowHistoryInfo = `${IsUndo ? 'Undo ' : 'Redo '} ${Desc}`;
            // if (this.historyTimer)
            //     clearTimeout(this.historyTimer);
                
            // this.historyTimer = setTimeout(() => {
            //     this.ShowHistoryInfo = null;
            //     delete this.historyTimer;
            //     this.RCUpdate();
            // }, 2000);
        }
        else if (Event === Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED) {
            [this.ActiveUserCount] = Params;
        }
        this.RCUpdate();
    }
    Undo() {
        this.GetManager() && this.GetManager().Undo();      
    }
    Redo() {
        this.GetManager() && this.GetManager().Redo();
    } 
    Load(projectId) {
        this.props.GetPluginApi().PreLoadProject(projectId, (data) => {
            // console.log(data);
            AppLayout.AppSource.Figma.FileId = data.fileId;
            const isUsed = Utils.JustGet(data.projects, false, projectId);
            
            this.props.GetPluginApi().FigmaProjectsIds = data.projects;

            if (!this.DontCheckProjects && !isUsed && Utils.Keys(data.projects).length > 0) {            
                AppState.Data.BoardList().then((boards) => {
                    const projects = [];
                    boards && Utils.ForEach(boards, (model) => {
                        if (data.projects[model._id]) {
                            projects.push({
                                id : model._id,
                                name : Utils.UseNullOrEmpty(model.name || model.Name, 'Untitled')
                            })
                        }
                    });

                    if (projects.length === 0) {
                        this.DontCheckProjects = true;
                        this.Load(projectId);
                        return;
                    }
                    
                    this.setState({
                        usedInOtherProjects : projects
                    });
                });
                return;                
            }


            Globals.ProjectManager.Load({
                ProjectId : projectId,
                Editor : 'figma'
            }).then((result) => {
                if (result && !result.error) {    
                    window.Globals = Globals;

                    // Make Consistency Checks
                    if (isUsed) {
                        const UnlinkedStyles = [];
                        const LocalStyles = {};
                        if (data.localStyles) {
                            data.localStyles.colors && Utils.ForEach(data.localStyles.colors, (figmaStyle, ) => {
                                LocalStyles[figmaStyle.sid] = figmaStyle;                                
                            });
                            data.localStyles.shadows && Utils.ForEach(data.localStyles.shadows, (figmaStyle, ) => {
                                LocalStyles[figmaStyle.sid] = figmaStyle;                                
                            });
                            data.localStyles.texts && Utils.ForEach(data.localStyles.texts, (figmaStyle, ) => {
                                LocalStyles[figmaStyle.sid] = figmaStyle;                                
                            });
                        }   
                        
                        const StyleTokenMaps = {};
                        Utils.ForEach(data.tokenMap, (tokenData, tokenId) => {
                            if (!Globals.ProjectManager.Data.Data.tokens.list[tokenId]) {
                                // console.log(`Deleted Token ${tokenId}`);
                                delete data.tokenMap[tokenId];
                            }
                            else {
                                if (tokenData.styles && Array.isArray(tokenData.styles)) {
                                    const styles = [];
                                    tokenData.styles.map((styleId) => {
                                        StyleTokenMaps[styleId] = {
                                            tokenId : tokenId,
                                            tokenName : Globals.ProjectManager.Data.Data.tokens.list[tokenId]
                                        }
                                        if (!LocalStyles[styleId]) {
                                            // console.log(`Style Not Found : ${styleId}`);
                                        }
                                        else {
                                            styles.push(styleId);
                                        }
                                    })
                                    tokenData.styles = styles;
                                }
                            }
                        });

                        Utils.ForEach(LocalStyles, (styleData, styleId) => {
                            if (!StyleTokenMaps[styleId]) {
                                UnlinkedStyles.push(styleId);
                            }
                        });
                        
                                                   
                        if (UnlinkedStyles.length > 0 && false) {
                            const tokenNameIds = {};

                            Utils.ForEach(Globals.ProjectManager.Data.Data.tokens.list, ({name}, tokenId ) => {
                                tokenNameIds[name] = tokenId;
                            });

                            UnlinkedStyles.map((styleId) => {
                                console.log(LocalStyles[styleId].name);
                                const tokenIdWithName = tokenNameIds[LocalStyles[styleId].name];
                                if (tokenIdWithName) {
                                    // console.log(`Style Not Linked : ${styleId} [${LocalStyles[styleId].name}] -> tokenId ${tokenIdWithName}`);
                                    const tokenStyles = Utils.Get(data.tokenMap, [], tokenIdWithName, 'styles')
                                    tokenStyles.push(styleId);
                                }
                            })                            
                        }

                        // console.log(data);          
                        
                        this.props.GetPluginApi().Callback_ReplaceStyleTokenMap = () => {
                            delete this.props.GetPluginApi().Callback_ReplaceStyleTokenMap;

                            if (UnlinkedStyles.length > 0 && FigmaPluginOptions.checkStyleTokensonLoad.value) {    
                                this.props.GetPluginApi().LoadFonts();
                                this.props.GetPluginApi().FigmaTokenMap = data.tokenMap;
                                this.setState({needsResolveUnlinkedStyles : {isUsed : isUsed}, });
                                return;
                            }


                            this.PostLoad(projectId, isUsed);
                        }
                        this.props.GetPluginApi().PostMessage({
                            type : 'ReplaceStyleTokenMap',
                            data : {
                                projectId : Globals.ProjectManager.Id,
                                bindings : data.tokenMap
                            }
                        })
                    }
                    else {
                        this.PostLoad(projectId, isUsed);
                    }
                }
                else {
                    this.props.onClose();
                }
            });
            // this.PluginApi.SetOption({key : 'LastProjectId', value : BoardId});
        })
        
    }
    PostLoad(projectId, isUsed) {
        this.props.GetPluginApi().LoadProject(projectId, ({options, tokenMap, bindings, localStyles, selectionCount}) => {
            if (options) {
                if (options.GlobalState) {
                    Globals.ProjectManager.States.ToState(options.GlobalState);
                }
            }

            this.props.GetPluginApi().SelectedNodeCount = selectionCount;
            this.props.GetPluginApi().FigmaTokenMap = tokenMap;
            this.props.GetPluginApi().FigmaStyleTokens = bindings;
            this.props.GetPluginApi().MarkProjectLoaded(projectId);
            Utils.Set(this.props.GetPluginApi().FigmaProjectsIds, {}, projectId);
            this.props.GetPluginApi().SetOption({key : 'LastProjectId', value : projectId});

            this.props.GetPluginApi().ComputeTokenSyncs(bindings, localStyles, Utils.JustGet(options, 'Default', 'StateLabel'), !isUsed);
            if (this.props.GetPluginApi().InitialTokenSync && this.props.GetPluginApi().InitialTokenSync.total > 0) {
                if (!isUsed) {
                    this.setState({loaded : true, needsSync : true});
                }
                else {
                    this.setState({loaded : true, needsSync : true});                                        
                }                            
                return;
            }

            
            this.setState({loaded : true});
        });
    }
    LoadOld(projectId) {
                

        Globals.ProjectManager.Load({
            ProjectId : projectId,
            Editor : 'figma'
        }).then((result) => {
            if (result) {          
                const isUsedBefore = Utils.JustGet(this.props.GetPluginApi().FigmaProjectsIds, false, this.props.projectId);
                this.props.GetPluginApi().LoadProject(projectId, ({options, tokenMap, bindings, localStyles, selectionCount}) => {

                    window.Globals = Globals;

                    if (options) {
                        if (options.GlobalState) {
                            Globals.ProjectManager.States.ToState(options.GlobalState);
                        }
                        // console.log( 'Options : ' + JSON.stringify(options, null, 2));
                    }

                    this.props.GetPluginApi().SelectedNodeCount = selectionCount;
                    this.props.GetPluginApi().FigmaTokenMap = tokenMap;
                    this.props.GetPluginApi().FigmaStyleTokens = bindings;

                    Utils.ForEach(tokenMap, (tokenData, tokenId) => {
                        if (!Globals.ProjectManager.Data.Data.tokens.list[tokenId]) {
                            delete tokenMap[tokenId];
                        }
                    });

                    if (localStyles && bindings) {
                        const tokenNameIds = {};

                        Utils.ForEach(Globals.ProjectManager.Data.Data.tokens.list, ({name}, tokenId ) => {
                            tokenNameIds[name] = tokenId;
                        });

                        const styleNamesIds = {};
                        const styleNamesIdsDuplicate = {};
                        Utils.ForEach(localStyles, ({sid, type, name}, i) => {
                            if (!styleNamesIds[name]) {
                                styleNamesIds[name] = sid;

                                if (!bindings[sid]) {
                                    if (tokenNameIds[name]) {
                                        if (!tokenMap[tokenNameIds[name]]) {
                                            tokenMap[tokenNameIds[name]] = {};
                                        }
                                        tokenMap[tokenNameIds[name]].styles = [sid];
                                    }
                                }
                            }
                            else {
                                if (!styleNamesIdsDuplicate[name])
                                    styleNamesIdsDuplicate[name] = [];
                                styleNamesIdsDuplicate[name].push(sid);
                            }
                        });
                        
                        Utils.ForEach(tokenMap, (tokenData, tokenId) => {
                            if (tokenData && tokenData.styles) {
                                if (Globals.ProjectManager.Data.Data.tokens.list[tokenId]) {
                                    const tokenName = Globals.ProjectManager.Data.Data.tokens.list[tokenId].name
                                    const newStyles = [];
                                    Utils.ForEach(tokenData.styles, (styleId, ) => {
                                        if (styleNamesIds[tokenName]) {
                                            newStyles.push(styleNamesIds[tokenName]);
                                        }
                                    });
                                    tokenData.styles = newStyles;
                                }                                                                
                            }
                        });

                        

                        this.props.GetPluginApi().PostMessage({
                            type : 'ReplaceStyleTokenMap',
                            data : {
                                projectId : Globals.ProjectManager.Id,
                                bindings : tokenMap
                            }
                        })
                    }

                    if (localStyles && bindings && false) {                        
                        this.props.GetPluginApi().ComputeTokenSyncs(bindings, localStyles, Utils.JustGet(options, 'Default', 'StateLabel'), !isUsedBefore);
                        if (this.props.GetPluginApi().InitialTokenSync.total > 0) {
                            if (!isUsedBefore) {
                                this.setState({loaded : true, needsSync : true, needsMergeFirstUse : true});
                            }
                            else {
                                this.setState({loaded : true, needsSync : true});
                                this.props.GetPluginApi().MarkProjectLoaded(this.props.projectId);
                            }                            
                            return;
                        }
                        else {
                            this.props.GetPluginApi().MarkProjectLoaded(this.props.projectId);
                        }
                    }
                    else {
                        this.props.GetPluginApi().MarkProjectLoaded(this.props.projectId);
                    }
                    // console.log(`Project Loaded`);
                    
                    this.setState({loaded : true});                    
                });                
            }       
            else
                this.props.onClose();
        });
    }
    ToggleMenu(e) {
        e && e.stopPropagation();        
        this.setState({showMenu : !this.state.showMenu});
    }
    SelectProject(id) {
        this.state.usedInOtherProjects = false;
        this.props.onSelectProject(id);
    }
    renderCustom() {

        let content;
        if (this.state.usedInOtherProjects) {
            content = (
                <SC.FCol style={{
                    padding : '10px',
                    marginTop : '40px'
                }}>
                    <FigmaInfoPanel warning>
                        <div style={{alignSelf : 'center', fontWeight : 500, fontSize : '14px', lineHeight : '24px', marginBottom : '8px', marginTop : '8px', textAlign : 'center'}}>
                            <div>
                                This Figma file is not linked to this Design System!
                            </div>
                            <div style={{marginTop : '16px'}}>
                                If you continue, local styles will be linked to tokens in this Design System!
                            </div>     
                            <SC.Buttons.RoundButton small style={{width : '100%', maxWidth : '240px', alignSelf : 'center', marginTop : '16px', marginLeft : 'auto', marginRight : 'auto'}} 
                                onClick={() => {
                                    this.DontCheckProjects = true;
                                    this.setState({usedInOtherProjects : false}, () => {
                                        this.Load(this.props.projectId);
                                    })
                                }}
                            >
                                Use This DSM
                            </SC.Buttons.RoundButton>                   
                        </div>
                    </FigmaInfoPanel>
                    <SC.HeaderTitle style={{fontSize : '12px'}}>Projects Used with this Figma File</SC.HeaderTitle>
                    {
                        this.state.usedInOtherProjects.map((model) => {
                            return (
                                <FigmaProjectCard 
                                    key={model.id}
                                    name={model.name}
                                    onSelect={this.SelectProject.bind(this, model.id)}
                                    model={model}
                                />
                            )
                        })
                    }
                </SC.FCol>
            )
        }
        else if (this.state.needsResolveUnlinkedStyles) {
            return (
                <SC.FCol f1>
                    <FigmaProjectHeader 
                        title={Globals.ProjectManager.GetName()}
                        onClose={this.props.onClose}
                    />
                    <FigmaProjectTokenStyleSyncer 
                        {...this.props}
                        onContinue={() => {                            
                            this.setState({needsResolveUnlinkedStyles : false}, () => {
                                this.PostLoad(this.props.projectId, this.state.needsResolveUnlinkedStyles.isUsed);
                            })
                        }}
                    />
                </SC.FCol>
            )
        }
        else {
            if (!this.state.loaded) {
                content = (
                    <SC.Div_Flex_Cell f1>
                        <SC.Loading_Icon />
                    </SC.Div_Flex_Cell>
                )
            }
            else {            
                if (this.state.needsSync) {
                    content = (
                        <SC.FCol fw fh style={{marginTop : '40px'}}>
                            <SC.CustomScrollbars>
                                <FigmaDsmSyncer 
                                    {...this.props}
                                    onCancel={this.props.onClose}
                                    onClose={() => {
                                        this.setState({needsSync : false});
                                    }}
                                />
                            </SC.CustomScrollbars> 
                        </SC.FCol>                    
                    )
                }
                else {
                    content = (
                        <React.Fragment>
                            <FigmaDesignSystem 
                                {...this.props}
                                Ref_SystesPanel={this.Ref_SystesPanel}
                                Ref_InnerSystesPanel={this.Ref_InnerSystesPanel}
                                Ref_Toolbar={this.Ref_Toolbar}
                            />
                        </React.Fragment>
                    )
                }
            }
        }
        

        return (
            <SC.FCol f1 
                onMouseEnter={() => { this.props.GetPluginApi().HandleFigmaChanges_Suspended = true; }}
                onMouseLeave={() => { this.props.GetPluginApi().HandleFigmaChanges_Suspended = false; }}                
            >
                <FigmaProjectHeader 
                    loaded={this.state.loaded}
                    onSaveName={this.SaveName}
                    willEditName={this.state.willEditName}
                    style={this.props.style}
                    onClose={this.props.onClose}
                    Ref_SyncIcon={this.Ref_SyncIcon}
                    projectId={this.props.projectId}
                    teamId={Globals.ProjectManager.TeamId}
                    ActiveUserCount={this.ActiveUserCount}
                    onTeamPanel={this.props.onTeamPanel}
                    // onTeamPanel={() => {
                    //     this.setState({showTeamPanel : !this.state.showTeamPanel})
                    // }}
                    
                    onRename={() => {
                        this.setState({willEditName : true, showMenu : false})
                    }}
                    onDelete={this.props.onDelete}
                    onDuplicate={this.props.onDuplicate}
                    onEditStates={() => {
                        if (this.Ref_Toolbar.current && this.Ref_Toolbar.current.Ref_SystesPanel.current)
                            this.Ref_Toolbar.current.Ref_SystesPanel.current.EditStates();
                        else if (this.Ref_InnerSystesPanel.current)
                            this.Ref_InnerSystesPanel.current.EditStates();
                        else if (this.Ref_SystesPanel.current)
                            this.Ref_SystesPanel.current.EditStates();
                    }}
                    onToggleMenu={this.ToggleMenu}
                    showMenu={this.state.showMenu}
                    onSettings={this.props.onSettings}
                    HasHistoryPrev={this.HasHistoryPrev}
                    HasHistoryNext={this.HasHistoryNext}
                    Undo={this.Undo}
                    Redo={this.Redo}
                />
                {content}
            </SC.FCol>
        )
    }
}

export const FigmaProjectHeader = (props) => {
    return (
        <SC.FRow alc jsb style={{
            borderBottom : SC.CurrentTheme.theme.border_seperator,
            padding : '4px',
            paddingRight : '8px',
            backgroundColor : '#2c2c2c',
            color : '#fff',
            height : '38px',

            boxSizing : 'border-box',
            fontWeight : 500,
            position : 'fixed',
            top : 0, left : 0, right : 0,
            zIndex : 9999
        }}>
            <SC.BackClosableTitle fill='#fff' hoverFill='#fff'
                style={{overflow : 'hidden'}}
                titleStyle={{overflow : 'hidden'}}
                title={(
                    <EditableName 
                        value={props.loaded ?  Utils.UseNullOrEmpty(Globals.ProjectManager.GetName(), 'Untitled') : (props.title || 'Loading')}
                        onSave={props.onSaveName} 
                        willEdit={props.willEditName}
                        notDeletable
                        style={{...props.style, ...SC.Styles.Flex.RowAlcJsb, color : '#e5e5e5'}}
                    />
                )}
                onClick={props.onClose}
            />
            {
                props.loaded && 
                <SC.FRow>
                    {
                        props.projectId && false &&
                        <React.Fragment>
                            <SC.Icons.Icon_Button 
                                style={{padding : '4px', cursor : 'pointer'}}
                                onClick={props.HasHistoryPrev ? props.Undo : undefined} 
                                title='Undo'
                                hasFill
                                fill={props.HasHistoryPrev ? '#fff' : '#494949'}
                                hoverFill={props.HasHistoryPrev ? '#fff' : '#494949'}
                            >
                                <SC.Icons.Icon_Undo />
                            </SC.Icons.Icon_Button>
                            <SC.Icons.Icon_Button 
                                style={{padding : '4px', cursor : 'pointer'}}
                                onClick={props.HasHistoryNext ? props.Redo : undefined} 
                                title='Undo'
                                hasFill
                                fill={props.HasHistoryNext ? '#fff' : '#494949'}
                                hoverFill={props.HasHistoryNext ? '#fff' : '#494949'}
                            >
                                <SC.Icons.Icon_Redo />
                            </SC.Icons.Icon_Button>
                        </React.Fragment>
                    }
                    <SC.Div_Flex_Cell ref={props.Ref_SyncIcon} style={{transition : 'opacity 0.2s ease', opacity : 0}} >
                        <SC.Loading_Icon small />
                    </SC.Div_Flex_Cell>
                    {/* <LightDarkToggle dark={!SC.CurrentTheme.theme.isLight} onToggle={this.ToggleLightDarkMode} /> */}
                    {
                        props.teamId && 
                        <TeamInfoButton
                            activeUserCount={props.ActiveUserCount}
                            light
                            onClick={props.onTeamPanel}
                        />
                        // <FigmaTeamMenu 
                        //     activeUserCount={props.ActiveUserCount} 
                        //     onToggle={props.onToggleTeamPanel}
                        //     open={props.showTeamPanel}
                        // />
                    }
                    <FigmaMenu 
                        top='44px'
                        projectId={props.projectId}
                        onRename={props.onRename}
                        onDelete={props.onDelete}
                        onDuplicate={props.onDuplicate}
                        onEditStates={props.onEditStates}
                        onClose={props.onClose}
                        onToggle={props.onToggleMenu}
                        open={props.showMenu}
                        notifyVersion={AppState.Versions.Figma.DSM !== AppState.Versions.Figma.Plugin}
                        onSettings={props.onSettings}
                    />                        
                </SC.FRow>       
            }
                                     
        </SC.FRow>
    )
}