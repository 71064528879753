import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import PublishMetaItem from './metaitem';
import Strings from './strings';

export const Publish_Token = (tokenId, token, owner, convertValue) => {
    if (token) {
        Utils.ForEach(token.value, (statevalue, state) => {
            if (statevalue.value) {
                if (convertValue) {
                    const convertedValue = convertValue(statevalue);
                    if (convertedValue)
                        Utils.Set(owner, convertedValue, state, tokenId)
                }                    
                else
                    Utils.Set(owner, statevalue.value, state, tokenId);
            }
        });        
    }
}

export const publish_Token_Transform = (transformValue) => {
    let transform;

    if (transformValue.matrix) {
        transform = {
            transform : transformValue.matrix,
            transformOrigin : `${transformValue.originX || 'center'} ${transformValue.originY || 'center'}`
        }
    }
    else
        transform = Utils.GetTransformCssAll(Utils.Get(transformValue, [], 'items'), transformValue.originX, transformValue.orignY, true);

    transform.transforms = Utils.GetTransformMotionObject(Utils.Get(transformValue, [], 'items'));
    transform.transformOrigin = Utils.GetTransformOrigin(transformValue);
    
    return transform;
}

export const Publish_TokenValues = function(Theme, Data, PublishContext) {
    const tokens = Utils.JustGet(Data, null, 'tokens');
    if (tokens) {
        const colors = Utils.JustGet(tokens, [], 'colors', 'order');
        Utils.ForEach(colors, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            if (token) {
                Publish_Token(tokenId, token, Theme.Tokens);
                Publish_Token(tokenId, token.foregroundTokenId, Utils.Get(Theme, {}, 'TokenPairs'));                
            }            
        }); 
        const aliase_colors = Utils.JustGet(tokens, [], 'aliases', 'colors', 'order');
        Utils.ForEach(aliase_colors, (aliaseId, i) => {
            const aliase = Utils.JustGet(tokens, null, 'list', aliaseId);
            aliase && Utils.ForEach(aliase.tokenId, (stateTokenId, state) => {
                if (stateTokenId.id) {
                    Utils.Set(Theme, stateTokenId.id,  'Aliases', state, aliaseId);                    
                }
            });   
        });



        const gradients = Utils.JustGet(tokens, [], 'Gradients', 'order');
        Utils.ForEach(gradients, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            if (token) {
                Utils.ForEach(token.value, (statevalue, state) => {
                    if (statevalue.value && statevalue.value.gradient) {
                        const gradient = Utils.GetGradientCss(statevalue.value.gradient.colors, Utils.Get(statevalue.value.gradient, 'linear', 'type'), Utils.Get(statevalue.value.gradient, 0, 'path', 'angle'), (colorTokenId) => {
                            return Utils.JustGet(Theme.Tokens, null, state, colorTokenId);
                        });
                        if (gradient)
                            Utils.Set(Theme.Tokens, gradient, state, tokenId);
                    }
                        
                });
            }
        });

        const textContents = Utils.JustGet(tokens, [], 'Contents', 'order');
        Utils.ForEach(textContents, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens);
        });

        const shadows = Utils.JustGet(tokens, [], 'Shadows', 'order');
        Utils.ForEach(shadows, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                const shadow = Utils.GetShadowCss(statevalue.value.values, token.textShadow, (colorid) => {
                    return Utils.Get(Theme.Tokens, null, Strings.DEFAULT, colorid);
                });
                return shadow;
            });            
        });

        const borders = Utils.JustGet(tokens, [], 'Borders', 'order');
        Utils.ForEach(borders, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return {
                    borderStyle : Utils.JustGet(statevalue.value, 'solid', 'style'),
                    borderWidth : Utils.px(statevalue.value.value, Utils.JustGet(statevalue.value, 'px', 'Unit')),
                }
            }); 
        });

        const borderRadius = Utils.JustGet(tokens, [], 'BorderRadiuses', 'order');
        Utils.ForEach(borderRadius, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return Utils.px(statevalue.value.value, Utils.JustGet(statevalue.value, 'px', 'Unit'));
            }); 
        });
        

        const transforms = Utils.JustGet(tokens, [], 'Transforms', 'order');
        Utils.ForEach(transforms, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return publish_Token_Transform(statevalue.value);
            });
        });

        const filters = Utils.JustGet(tokens, [], 'Filters', 'order');
        Utils.ForEach(filters, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return Utils.GetFilterCss(Utils.Get(statevalue.value, [], 'items'), MetaData.Properties.filter);
            });
        });

        const images = Utils.JustGet(tokens, [], 'Images', 'order');
        Utils.ForEach(images, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return Utils.JustGet(statevalue, '', 'value', 'url');
            });
        });

        const icons = Utils.JustGet(tokens, [], 'Icons', 'order');
        Utils.ForEach(icons, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens);
        });

        const contents = Utils.JustGet(tokens, [], 'Contents', 'order');
        Utils.ForEach(contents, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens);
        });

        const fonts = Utils.Concat(['DefaultFont'], Utils.JustGet(tokens, [], 'Fonts', 'order'));
        Utils.ForEach(fonts, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            if (token) {
                Utils.ForEach(token.value, (statevalue, state) => {
                    if (statevalue.value) {
                        const fontStyle = GetTypefaceStyle(statevalue.value);
                        Utils.Set(Theme.Tokens, fontStyle, state, tokenId);        
                    }                
                });
            }
        });

        const easeCurves = Utils.JustGet(tokens, [], 'Motion', 'order');
        Utils.ForEach(easeCurves, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            Publish_Token(tokenId, token, Theme.Tokens, (statevalue) => {
                return [
                    Number(statevalue.value.x1),
                    Number(statevalue.value.y1),
                    Number(statevalue.value.x2),
                    Number(statevalue.value.y2) 
                ];
            }); 
        });

        const Transitions = Utils.JustGet(tokens, [], 'Transitions', 'order');
        Utils.ForEach(Transitions, (tokenId, i) => {
            const token = Utils.JustGet(tokens, null, 'list', tokenId);
            if (token) {
                ['easeId', 'durationId', 'delayId', 'transformId', 'opacity'].map((prop) => {
                    Utils.ForEach(token[prop], (propTokenId, state) => {                    
                        if (propTokenId.value) {
                            Utils.Set(Theme.Tokens, propTokenId.value, state, tokenId, prop);
                        }                            
                    });
                })
            }
        });

        Theme.Patterns = {};

        const publish_scales = (ScaleModel, Name) => {
            Utils.ForEach(ScaleModel, (ScaleDef, State) => {                             
                Utils.Set(Theme.Patterns, ScaleDef.baseSize, Name, 'baseSize', State);
                Utils.Set(Theme.Patterns, ScaleDef.ratio, Name, 'ratio', State);

                if (Name === 'Text') {
                    Utils.Set(Theme.Patterns, ScaleDef.lineHeightFactor || 1, Name, 'lineHeightFactor', State);
                    Utils.Set(Theme.Patterns, ScaleDef.letterSpaceFactor, Name, 'letterSpaceFactor', State);
                    Utils.Set(Theme.Patterns, ScaleDef.wordSpacingFactor, Name, 'wordSpacingFactor', State);
                }                
            });
        }
        
        if (tokens.Typescale) {
            publish_scales(tokens.Typescale, 'Text');
        } 
        if (tokens.Spacescale) {
            publish_scales(tokens.Spacescale, 'Space');
        } 
        if (tokens.TimeScale) {
            publish_scales(tokens.TimeScale, 'Motion');
        } 

        const textPatterns = Utils.JustGet(tokens, [], 'TextPatterns', 'order');
        Utils.ForEach(textPatterns, (tokenId, i) => {
            const pattern = Utils.JustGet(tokens, null, 'list', tokenId);
            if (pattern) {
                const convertedPattern = {};
                if (pattern.fontId) {
                    Utils.ForEach(pattern.fontId, (fontid, state) => {
                        Utils.Set(convertedPattern, fontid.value, 'fontid', state);
                    });
                }
                if (pattern.customSize) {
                    const {Unit, ...CustomSizeStates} = pattern.customSize;
                    Utils.ForEach(CustomSizeStates, (customSize, state) => {
                        if (customSize.value && Utils.IsNotNullOrEmpty(customSize.value.value)) {
                            Utils.Set(convertedPattern, 
                                Utils.px(Utils.JustGet(customSize, 16, 'value', 'value'), Utils.Get(customSize, 'px', 'value', 'Unit'))
                                , 'fontSize', state);
                        }                        
                    });
                }
                
                Utils.ForEach(pattern.scaleIndex, (scaleIndex, state) => {
                    Utils.Set(convertedPattern, scaleIndex.value, 'scaleIndex', state);
                });
    
                Utils.ForEach(pattern.scaleFactor, (scaleFactor, state) => {
                    Utils.Set(convertedPattern, scaleFactor, 'scaleFactor', state);
                });
    
                Utils.ForEach(pattern.scaleDiff, (scaleDiff, state) => {
                    Utils.Set(convertedPattern, scaleDiff, 'scaleDiff', state);
                });
    
                ['lineHeight', 'letterSpacing', 'wordSpacing'].map((prop) => {
                    Utils.ForEach(pattern[prop], (propvalue, state) => {
                        const value = Utils.JustGet(propvalue, null, 'value', 'value');
                        if (Utils.IsNotNullOrEmpty(value)) {
                            if (prop !== 'lineHeight' || value > 0)
                                Utils.Set(convertedPattern, Utils.px(value, Utils.JustGet(propvalue, 'px', 'value', 'Unit')), prop, state);
                        }                    
                    });     
                })            
                
                Utils.Set(Theme.Patterns, convertedPattern, 'Text', 'Patterns', tokenId);
            }            
        });

        const spacePatterns = Utils.JustGet(tokens, [], 'SpacePatterns', 'order');
        if (spacePatterns) {
            Utils.ForEach(spacePatterns, (patternId, i) => {
                const pattern = Utils.JustGet(tokens, null, 'list', patternId);
                if (pattern) {
                    const convertedPattern = {};                
                    if (pattern.customSize) {
                        const {Unit, ...CustomSizeStates} = pattern.customSize;
                        Utils.ForEach(CustomSizeStates, (customSize, state) => {
                            if (customSize.value && Utils.IsNotNullOrEmpty(customSize.value.value)) {
                                Utils.Set(convertedPattern, 
                                    Utils.px(Utils.JustGet(customSize, 16, 'value', 'value'), Utils.Get(customSize, 'px', 'value', 'Unit'))
                                    , 'value', state);
                            }                        
                        });
                    }
                    
                    Utils.ForEach(pattern.scaleIndex, (scaleIndex, state) => {
                        Utils.Set(convertedPattern, scaleIndex.value, 'scaleIndex', state);
                    });

                    Utils.ForEach(pattern.scaleFactor, (scaleFactor, state) => {
                        Utils.Set(convertedPattern, scaleFactor, 'scaleFactor', state);
                    });

                    Utils.ForEach(pattern.scaleDiff, (scaleDiff, state) => {
                        Utils.Set(convertedPattern, scaleDiff, 'scaleDiff', state);
                    });
    
                    Utils.Set(Theme.Patterns, convertedPattern, 'Space', 'Patterns', patternId);
                }                
            });        
        }
        const timePatterns = Utils.JustGet(tokens, [], 'TimePatterns', 'order');
        if (timePatterns) {
            Utils.ForEach(timePatterns, (patternId, i) => {
                const pattern = Utils.JustGet(tokens, null, 'list', patternId);
                if (pattern) {
                    const convertedPattern = {};                
                    if (pattern.customSize) {
                        const {Unit, ...CustomSizeStates} = pattern.customSize;
                        Utils.ForEach(CustomSizeStates, (customSize, state) => {
                            if (customSize.value && Utils.IsNotNullOrEmpty(customSize.value.value)) {
                                Utils.Set(convertedPattern, Utils.JustGet(customSize, 200, 'value', 'value'), 'value', state);
                            }                        
                        });
                    }
                    
                    Utils.ForEach(pattern.scaleIndex, (scaleIndex, state) => {
                        Utils.Set(convertedPattern, scaleIndex.value, 'scaleIndex', state);
                    });

                    Utils.ForEach(pattern.scaleFactor, (scaleFactor, state) => {
                        Utils.Set(convertedPattern, scaleFactor, 'scaleFactor', state);
                    });

                    Utils.ForEach(pattern.scaleDiff, (scaleDiff, state) => {
                        Utils.Set(convertedPattern, scaleDiff, 'scaleDiff', state);
                    });
    
                    Utils.Set(Theme.Patterns, convertedPattern, 'Motion', 'Durations', patternId);
                }                
            });  
        }
    }
    
    const BoardStyles = Utils.Get(Data, null, 'Variables');
    if (BoardStyles && BoardStyles.List) {
        Utils.ForEach(BoardStyles.List, (Variable, Id) => {
            if (Variable) {
                if (Variable.type === 'Sizes') {
                    const Value = {
                        value : Variable.value
                    };
                    if (PublishContext.Platform.Name === MetaData.Platforms.ReactNative.Name) {
                        Utils.Set(Theme.Tokens.Default, Variable.value, Id);
                    }
                    else {
                        if (Variable.Unit) {
                            if (Variable.Unit.type === 'Custom') {
                                Value.unit = Variable.Unit;
                            }
                            else {
                                Value.unit = Variable.Unit || 'px';
                            }                            
                        }   
                        else {
                            Value.unit = 'px';
                        }
                        Utils.Set(Theme.Tokens.Default, `${Value.value}${Value.unit}`, Id);
                    }
                    
                }
                else {
                    Utils.Set(Theme.Tokens.Default, Variable.value, Id);

                    if (Variable.type === 'Colors') {
                        if (Variable.foreground && Variable.foreground.tokenId) {
                            Utils.Set(Theme, Variable.foreground.tokenId, 'TokenPairs', 'Default', Id);
                        }
                    }
                }
                if (Variable.Themes) {
                    Utils.ForEach(Variable.Themes,(value, themeId) => {
                        let StateValue = value;
                        if (Variable.type === 'Sizes') {
                            StateValue = {
                                value : value && value.value
                            };
                            if (PublishContext.Platform.Name === MetaData.Platforms.ReactNative.Name) {

                            }
                            else {
                                if (value && value.Unit) {
                                    if (value.Unit.type === 'Custom') {
                                        StateValue.unit = value.Unit;
                                    }
                                    else {
                                        StateValue.unit = value.Unit.unit || 'px';
                                        StateValue.value = `${StateValue.value}${StateValue.unit}`;
                                    }                             
                                }
                            }
                            
                        }
                        Utils.Set(Theme.Tokens, StateValue.value, themeId, Id);
                        if (Variable.type === 'Colors') {
                            if (StateValue.foreground && StateValue.foreground.tokenId) {
                                Utils.Set(Theme, StateValue.foreground.tokenId, 'TokenPairs', themeId, Id);
                            }
                        }                        
                    });
                }
            }            
        })
    }

    const StringDefs = Utils.Get(Data, {}, 'Assets', 'ContentItems');
    if (StringDefs.Values) {
        Utils.ForEach(StringDefs.Values, (StateValues, themeId) => {
            Utils.ForEach(StateValues, (statevalue, tokenid) => {
                Utils.Set(Theme.Tokens, statevalue, themeId, tokenid);
            });            
        });
    }


    if (Data.Assets && Data.Assets.List) { 

        if (Data.Assets.List.Sounds) {
            const SystemSounds = [];
            Utils.ForEach(Data.Assets.List.Sounds, (Sound, id) => {
                if (Sound.MetaSound) {
                    Utils.Set(Theme.Tokens.Default, Sound.MetaSound, id );
                    if (Sound.MetaSound.provider === 'Facebook Sound Kit') {
                        SystemSounds.push(Sound.MetaSound.Id);
                    }
                    if (Sound.Themes) {
                        Utils.ForEach(Sound.Themes,(value, themeId) => {
                            Utils.Set(Theme.Tokens, value, themeId, id );                            
                        });
                    }
                }                
            });
            if (SystemSounds.length > 0) {
                Utils.ForEach(MetaData.Sounds,(Category) => {
                    Utils.ForEach(Category.sounds,(MetaSound) => {
                        if (SystemSounds.indexOf(MetaSound.id) > -1) {
                            Utils.Set(Theme.Tokens.Default, 
                                {
                                    url : `https://ds101.toolabs.com/assets/sounds/${Category.name}/${MetaSound.name}.m4a`
                                }, 'SystemSounds', MetaSound.id);
                        }
                    });
                });    
            }
        }
    }
}

const Publish_Theme = function(Model, Data, PublishContext) {
    const Theme = {
        Tokens : {
            Default : {

            }
        }        
    };

    const States = Utils.Get(Data, {}, Strings.STATES);
    if (States) {
        Utils.Set(Theme, States.Order, 'StateOrder')
        Utils.ForEach(States.Order, (Id) => {
            const State = States[Id];
            if (State) {
                if (State.Variations) {
                    const VariationIds = Utils.Get(State, null, 'Variations', 'Order');
                    if (VariationIds) {
                        Utils.Set(Theme, State.name, Strings.STATES, Id, 'Name');
                        Utils.ForEach(VariationIds, (vid) => {
                            Utils.Set(Theme, Utils.Get(State, '', 'Variations', vid, 'name'), Strings.STATES, Id, 'Variations', vid);
                        })
                    }
                }    
                else {
                    // Single Variation State
                    Utils.Set(Theme, State.name, Strings.STATES, Id, 'Name');
                    Utils.Set(Theme, Id, Strings.STATES, Id, 'SingleVariation');
                }  
            }                  
        });
    }

    Publish_TokenValues(Theme, Data, PublishContext);

    
    return Theme;
}

export default Publish_Theme;

export const GetTypefaceStyle = (font) => {
    const fontStyle = {};
    if (font.provider === 'Google Fonts') {
        fontStyle.fontFamily = font.family;
        if (font.variant) {
            const weightStr = String(font.variant);
            const weight = weightStr.replace('italic', '');
            if (weight && Utils.IsOneOf(weight, '100', '200', '300', '400', '500', '600', '700', '800', '900', 'bold', 'bolder', 'lighter', 'normal'))
                fontStyle.fontWeight = weight;
            if (weightStr.indexOf('italic') > -1) 
                fontStyle.fontStyle = 'italic';
            else
                fontStyle.fontStyle = 'normal';
        }
    }
    else if (font.provider === 'Custom') {
        fontStyle.fontFamily = font.family;
    }
    else {
        fontStyle.fontFamily = font.family;
        if (font.weight)
            fontStyle.fontWeight = font.weight;
        else
            fontStyle.fontWeight = 500;
    }
    return fontStyle;
}
