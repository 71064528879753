import React from 'react';
import ReactDOM from 'react-dom';
import UIUtils from './Utils';
import {Utils} from '../index';

const OutSideClickHandler = (Wrapped) => {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                Hover : false
            };
            this.closePicker = this.closePicker.bind(this);
        }
        closePicker(e) {
            // setTimeout(() => {
                
            // }, 50);       
            
            if (this.Mounted && !this.props.disabled)
            {
                const point = {x:e.clientX, y:e.clientY};
                if (this.props.isOver) {
                    if (this.props.isOver(point))
                        return;
                }
        
                this.Bounds = this.props.onGetBounds ? this.props.onGetBounds() : UIUtils.GetBounds(this);
                if (this.Bounds) {
                    if (UIUtils.IsOverBounds(point, this.Bounds)) 
                        return;                          
                }
                if (this.props.isChildActive && this.props.isChildActive())
                    return;
                
                e.stopPropagation();

                this.props.onClose && this.props.onClose();
            }
        }
         
        componentDidMount() {
            this.Mounted = true;            
            setTimeout(() => {
                if (this.Mounted) {
                    document.addEventListener('mousedown', this.closePicker);
                    this.Bounds = UIUtils.GetBounds(this);
                }
            }, 200);            
        }
        componentWillUnmount() {
            this.Mounted = false;
            document.removeEventListener('mousedown', this.closePicker);          
        }
        render() {            
            return (
                <Wrapped {...this.props} ref={this.props.InnerRef} />
            );
        }
    }
};

export default OutSideClickHandler;
