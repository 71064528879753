import React from 'react';
import {
    ReactBaseComponent,
    Events,
    SC,
    Globals
} from '../../../importer';
import styled, {css} from 'styled-components';
import { OutsideCloseDotMenuContainer, DotMenuContainerBox } from '../../project/panels/left/common';
import { Seperator } from '../../project/panels/right/iteminspector/styleitems/common';
import { InviteMemberItem } from '../../projectlist/team';
import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import { motion, AnimatePresence } from 'framer-motion';

export default class TeamInfoButton extends ReactBaseComponent
{
    constructor(props) {
        super(props);        
        
        super.ListenEvents(
            Events.GLOBAL.MEDIASIZE.CHANGED,
            Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED
        );

        this.ActiveUserCount = 0;
        this.messages = [];
    }   
    componentDidMount() {
        super.componentDidMount();

        if (GetDataApi().IsLocal())
            return;
        this.RefListener = GetDataApi().api.getNotificationsDb().ref(`/users/${GetUserId()}/new/`).limitToLast(20);

        this.RefListener.once('value', (snapshot) => {
            snapshot.forEach((childSnapshot) => {
                this.messages.push({
                    id : childSnapshot.key,
                    ...childSnapshot.val()
                });
            });
            const messageCount = this.messages.length;
            this.RefListener.on("child_added",(data) => {
                this.messages.push({
                    id : data.key,
                    ...data.val()
                });
                if (this.messages.length > messageCount) {
                    this.RCUpdate();
                }                
            });
        });
        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.RefListener) {
            this.RefListener.off('value');
        }
    }
    OnEvent(Event, Params) {
        if (Event === Events.GLOBAL.TEAM.ACTIVEUSERSCHANGED) {
            [this.ActiveUserCount] = Params;
        }
        this.RCUpdate();
    }
    renderCustom() {
        const iconprops = {};
        if (this.props.light) {
            iconprops.hoverFill='#fff';
            iconprops.fill='hsla(0,0%,100%,.8)';
            iconprops.theme = SC.themes.Light;
        }
        return (
            <div style={{
                    minWidth : '24px',
                    minHeight : '24px',
                    marginRight : this.props.compact ? 0 : '12px',                        
                    boxSizing : 'border-box',
                    ...SC.Styles.Flex.Cell,
                    borderRadius : '50%',
                    cursor : 'pointer',
                    position : 'relative'
                }}
                onClick={this.props.onClick}
            > 
                <SC.Icons.Icon_ButtonBox hasFill hasCursor {...iconprops}>
                    <SC.Icons.Icon_Team activeUserCount={this.ActiveUserCount || this.messages.length > 0} />
                </SC.Icons.Icon_ButtonBox>
                {
                    (this.ActiveUserCount > 0  || this.messages.length > 0) ?
                    <div style={{
                        position : 'absolute', top : '10px', left : '50%', transform : 'translateX(-50%)',
                        backgroundColor : '#2ca7ffd9',
                        color : '#fff',
                        paddingLeft : '2px', paddingRight : '2px',
                        borderRadius : '2px',
                        minWidth : '16px',
                        minHeight : '16px',
                        fontSize : '10px',
                        ...SC.Styles.Flex.Cell
                    }}>
                        {this.ActiveUserCount > 0 ? (this.ActiveUserCount + 1) : null}
                        {
                            false && this.messages.length > 0 &&
                            <SC.Icons.Icon_Button hasFill fill='#fff' style={{
                                minWidth : 'unset', minHeight : 'unset'
                                // position : 'absolute',
                                // top : '-8px',
                                // left : '12px'
                            }}>
                                <SC.Icons.Icon_Notification size={14} />
                            </SC.Icons.Icon_Button>
                        }
                    </div> : null
                }                
            </div>
        )
    }
}
