import keys from 'lodash/keys';
import values from 'lodash/values';
import map from 'lodash/map';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import findLast from 'lodash/findLast';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import forEachRight from 'lodash/forEachRight';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import reverse from 'lodash/reverse';
import findKey from 'lodash/findKey';
import get from 'lodash/get';
import set from 'lodash/set';
import unset from 'lodash/unset';
import has from 'lodash/has';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import union from 'lodash/union';
import concat from 'lodash/concat';
import intersection from 'lodash/intersection';
import filter from 'lodash/filter';
import pullAt from 'lodash/pullAt';
import isObject from 'lodash/isObject';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import toNumber from 'lodash/toNumber';
import toInteger from 'lodash/toInteger';
import camelCase from 'lodash/camelCase';
import kebabCase from 'lodash/kebabCase';
import lowerCase from 'lodash/lowerCase';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import upperCase from 'lodash/upperCase';
import upperFirst from 'lodash/upperFirst';
import endsWith from 'lodash/endsWith';
import throttle from 'lodash/throttle';


if (!Array.prototype.fill) {
    Object.defineProperty(Array.prototype, 'fill', {
        value: function (value) {

            // Steps 1-2.
            if (this === null) {
                throw new TypeError('this is null or not defined');
            }

            var O = Object(this);

            // Steps 3-5.
            var len = O.length >>> 0;

            // Steps 6-7.
            var start = arguments[1];
            var relativeStart = start >> 0;

            // Step 8.
            var k = relativeStart < 0 ?
                Math.max(len + relativeStart, 0) :
                Math.min(relativeStart, len);

            // Steps 9-10.
            var end = arguments[2];
            var relativeEnd = end === undefined ?
                len : end >> 0;

            // Step 11.
            var final = relativeEnd < 0 ?
                Math.max(len + relativeEnd, 0) :
                Math.min(relativeEnd, len);

            // Step 12.
            while (k < final) {
                O[k] = value;
                k++;
            }

            // Step 13.
            return O;
        }
    });
}

var ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';

var ID_LENGTH = 8;

var generate = function() {
  var rtn = '';
  for (var i = 0; i < ID_LENGTH; i++) {
    rtn += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
  }
  return rtn;
}

var UNIQUE_RETRIES = 9999;

var generateUnique = function(previous) {
  previous = previous || [];
  var retries = 0;
  var id;

  // Try to generate a unique ID,
  // i.e. one that isn't in the previous.
  while(!id && retries < UNIQUE_RETRIES) {
    id = generate();
    if(previous.indexOf(id) !== -1) {
      id = null;
      retries++;
    }
  }

  return id;
};

function* cartesian(head, ...tail) {
    const remainder = tail.length > 0 ? cartesian(...tail) : [[]];
    for (let r of remainder) for (let h of head) yield [h, ...r];
  }


const Utils = {
    RADIANT_DEGREE_FACTOR : 180 / Math.PI,
    platform : '',    
    Id() {        
        return generateUnique();
    },
    FormatName(value, nameFormat) {
        if (nameFormat === 'camelCase') {
            return this.ToCamelCase(value);
        }
        else if (nameFormat === 'pascalCase') {
            return this.ToPascalCase(value);
        }
        else if (nameFormat === 'snakeCase') {
            return this.ToSnakeCase(value);
        }
        else if (nameFormat === 'kebabCase') {
            return this.ToKebabCase(value);
        }
        else if (nameFormat === 'constantCase') {
            return this.ToConstantCase(value);
        }
        else if (nameFormat === 'dotCase') {
            return this.ToDotCase(value);
        }
        else if (nameFormat === 'lowerCase') {
            return this.ToLowerCase(value);
        }
        return value;
    },
    ToCamelCase(Value) {
        return camelCase(Value);
    },
    ToKebabCase(Value) {
        return kebabCase(Value);
    },
    ToTitleCase(str) {
        return startCase(camelCase(str));
    },
    ToPascalCase(str) {
        return startCase(camelCase(str)).replace(/ /g, '');
    },
    ToConstantCase(str) {
        return upperCase(str).replace(/ /g, '_');
    },
    ToDotCase(str) {
        return lowerCase(str).replace(/ /g, '.');
    },
    ToLowerCase(str) {
        return lowerCase(str).replace(/ /g, '');
    },
    ToPathCase(str) {
        return lowerCase(str).replace(/ /g, '/');
    },
    ToSnakeCase(str) {
        return snakeCase(str);
    },
    Capitalize(str) 
    {
        return upperFirst(lowerCase(str));
    },
    IsNull(Value) {        
        if (Value === null || Value === undefined || Value === 'undefined')
            return true;
        if (isNumber(Value) && isNaN(Value)) {
            return true;
        }
        return false;
    },
    IsNotNull(Value) {
        return !this.IsNull(Value);
    },
    IsNotNullOrEmpty(Value) {
        if (!this.IsNull(Value))
            return this.trim(Value) !== '';
        return false;
    },
    EndsWidth(s, c, u) {
        return endsWith(s, c, u);
    },
    UseNull(Value, Default) {
        if (this.IsNull(Value))
            return Default;
        return Value;
    },
    UseUndefined(Value, Default) {
        if (Value === undefined)
            return Default;
        if (isNumber(Value) && isNaN(Value)) {
            return Default;
        }
        return Value;
    },
    UseNullOrEmpty(Value, Default) {
        if (this.IsNotNullOrEmpty(Value))
            return Value;
        return Default;
    },
    IsTrue(Value) {
        return !this.IsFalse(Value);
        return true;
    },
    IsFalse(Value) {
        if (Value === 'false')
            return true;
        if (Value === '0')
            return true;
        return !Value;
    },
    IsObject(value) {
        return isObject(value);
    },
    IsEqual(o1, o2) {
        return isEqual(o1, o2);
    },
    HasAnyChange(Prev, Post, ...Props) {
        if (Prev && Post) {
            let hasChange = false;
            this.ForEach(Props, (prop) => {
                if (!this.IsEqual(Prev[prop], Post[prop])) {
                    hasChange = true;
                    return false;
                }
            })
            return hasChange;
        }
        return false;
    },
    IsNumber(value) {
        return isNumber(value);
    },
    ToNumber(value) {
        return toNumber(value);
    },
    ToInteger(value) {
        return Math.round(value);
    },
    DeepClone(Source) {
        return cloneDeep(Source);
    },
    DeepReplace(Source, Clone, ValueMap, keys, values) {
        this.ForEach(Source,(value, key) => {
            if (values) {
                if (isObject(value) || Array.isArray(value)) {
                    if (Clone[key])
                        this.DeepReplace(value, Clone[key], ValueMap, keys, values)
                }
                else {
                    if (ValueMap[value]) {
                        Clone[key] = ValueMap[value];
                    }
                }
            }            

            if (keys) {
                if (ValueMap[key] && Clone[key]) {
                    Clone[ValueMap[key]] = Clone[key];
                    delete Clone[key];
                }
            }            
        });
    },
    Throttle : throttle
    ,
    TraverseSubItems(Parent, GetChildList, Fn, ParentId) {
        if (Parent) {
            const ChildList = GetChildList(Parent);
            this.ForEach(ChildList, (Child, index) => {
                const ChildId = Fn(Child, Parent, ParentId, index);
                this.TraverseSubItems(Child, GetChildList, Fn, ChildId);
            })
        }
    },
    Keys(Source) {
        return keys(Source);
    },
    Values(Source) {
        return values(Source);
    },
    Map(Source, fn) {
        return map(Source, fn);
    },
    IsOneOf(Item, ...Items) {
        return this.Includes(Items, Item);
    },
    Has(Owner, Prop) {
        return has(Owner, Prop);
    },
    HasAny(Owner, ...Props) {
        let hasChange = false;
        this.ForEach(Props, (prop) => {
            if (has(Owner, prop)) {
                hasChange = true;
                return false;
            }
        })
        return hasChange;
    },
    JustGet(Owner, Default, ...Names) {
        if (Owner) {
            return get(Owner, Names, Default);
        }
        return Default;
    },
    Get(Owner, Default, ...Names) {
        if (Owner) {
            if (Default && !has(Owner, Names))
                set(Owner, Names, Default);
            let value = get(Owner, Names, Default);
            if (this.IsNull(value) && Default) {
                set(Owner, Names, Default);
                return get(Owner, Names, Default);
            }
            return value;
        }
        return Default;
    },
    Set(Owner, Value, ...Path) {
        set(Owner, Path, Value);
    },
    SetPathArray(Owner, Value, Path) {
        set(Owner, Path, Value);
    },
    GetPathArray(Owner, Default, Names) {
        if (Owner) {
            if (Default && !has(Owner, Names))
                set(Owner, Names, Default);
            return get(Owner, Names, Default) || Default;
        }
    },
    UnSet(Owner, ...Path) {
        unset(Owner, Path);
    },
    UnSetPathArray(Owner, Path) {
        unset(Owner, Path);
    },
    UnSetAll(Owner, Names, ...Path) {
        if (Names) {
            Names.map((name) => {
                unset(Owner, Path, name);
            })
        }
    },
    ToArray(col, convertValue) {
        const arr = [];
        if (col) {
            Utils.ForEach(col, (item, key) => {
                if (convertValue)
                    arr.push(convertValue(item));
                else
                    arr.push(item);
            });
        }
        return arr;
    },
    Filter(List, fnFilter) {
        return filter(List, fnFilter);
    },
    Find(List, fnFilter) {
        return find(List, fnFilter);
    },
    FindIndex(List, fnFilter) {
        return findIndex(List, fnFilter);
    },
    FindLast(List, fnFilter) {
        return findLast(List, fnFilter);
    },
    FindKey(List, o) {
        return findKey(List, o);
    },
    Remove(List, fnFilter) {
        return remove(List, fnFilter);
    },
    ChangePlace(List, OldIndex, NewIndex) {
        if (OldIndex < List.length && NewIndex < List.length) {
            const temp = this.PullAt(List, OldIndex)[0];
            List.splice(NewIndex, 0, temp);
        }
    },
    RemoveEquals(List, Item) {
        return remove(List, (i) => { return Item === i });
    },
    PullAt(List, ...n) {
        return pullAt(List, ...n);
    },
    ForEach(List, fn) {
        return forEach(List, fn);
    },
    ForEachRight(List, fn) {
        return forEachRight(List, fn);
    },
    Includes(List, Value, StartIndex) {
        return includes(List, Value, StartIndex);
    },
    Sort(List, fn) {
        return sortBy(List, fn);
    },    
    Reverse(List) {
        return reverse(List);
    },
    Merge(Main, ...Other) {
        return merge({}, Main, ...Other);
    },
    MergeTo(Main, ...Other) {
        return merge(Main, ...Other);
    },
    Intersection(...Items) {
        return intersection(...Items);
    },
    Uniq(array) {
        return uniq(array);
    },
    Union(a1, a2) {
        return union(a1, a2);
    },
    Concat(Main, ...Other) {
        const arr = [];
        this.ForEach(Other, (a, i) => {
            if (a)
                arr.push(a);
        })
        return concat(Main, ...arr);
    },
    px(value, unit) {
        if (unit === 'auto')
            return unit;
        if (this.UseNativeUnits)
            return value || 0;
        return `${value || 0}${unit || 'px'}`;
    }, 
    parseSize(sizeString, options) {
        const parsed = {};        
        if (sizeString) {
            const str = String(sizeString);
            parsed.value = parseFloat(str, 10)
            parsed.unit = str.match(/[\d.\-\+]*\s*(.*)/)[1] || '';

            if (parsed.unit === null) {
                if (options && !options.CanBeUnitless)
                    parsed.unit = 'px';
            }            
            if (parsed.value === null)
                parsed.value = 0;            
        }
        return parsed;        
    },
    url(value) {
        return `url(${value})`;        
    },
    GetContrastColor(backgroundColor) {
        if(backgroundColor.length < 5) {
            backgroundColor += backgroundColor.slice(1);
        }
        return (backgroundColor.replace('#','0x')) > (0xffffff/2) ? '#333' : '#fff';
    },
    GetContrastLevel(contrast) {
        if (contrast < 3.1)
            return 'FAIL';
        if (contrast < 4.5)
            return 'AA Large';
        if (contrast < 7)
            return 'AA';
        return 'AAA';
    },
    trim (str) {
        if (str && (typeof str === 'string' || str instanceof String))
            return str.replace(/^\s+|\s+$/gm,'');
        return str;
    },
    ValidateEmail(email) {
        var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email); 
    },
    RgbaToHex(rgba) {
        if (this.IsHex(rgba))
            return rgba;
        if (rgba) {
            try {
                var parts = rgba.substring(rgba.indexOf("(")).split(","),
                r = parseInt(this.trim(parts[0].substring(1)), 10),
                g = parseInt(this.trim(parts[1]), 10),
                b = parseInt(this.trim(parts[2]), 10),
                a = parseFloat(this.trim(parts[3].substring(0, parts[3].length - 1))).toFixed(2);
                return ('#' + r.toString(16) + g.toString(16) + b.toString(16) + (a * 255).toString(16).substring(0,2));    
            } catch (error) {
                console.log(error);   
            }            
        }                
    },
    HexToRGBA(hex) {
        var color = [0,0,0];
        
        if (this.IsHex(hex)) {
            let h = hex.replace("#", "");
            if (h.length === 6) {
                color =  [parseInt(h.substr(0, 2), 16), parseInt(h.substr(2, 2), 16), parseInt(h.substr(4, 2), 16)]
            } else if (h.length === 3) {
                color = [parseInt(h.substr(0, 1)+h.substr(0, 1), 16), parseInt(h.substr(1, 1)+h.substr(1, 1), 16), parseInt(h.substr(2, 1)+h.substr(2, 1), 16)]
            } 
        }
        else {
            return {
                error: true,
                message: 'Not valid hex'
            };
        }
        return `rgba(${ color[0] }, ${color[1]}, ${ color[2]}, 1)`;
    },    
    IsHex(color) {
        if (!color || typeof color !== 'string') return false

        if (color.substring(0, 1) === '#') color = color.substring(1)

        switch (color.length) {
            case 3: return /^[0-9A-F]{3}$/i.test(color)
            case 6: return /^[0-9A-F]{6}$/i.test(color)
            case 8: return /^[0-9A-F]{8}$/i.test(color)
            default: return false
        }
        // return /^\x23[a-f0-9]{3}([a-f0-9]{3})?$/i.test(hex);
    },
    IsHexCharacter(character) {
        return /^[a-f0-9]/gi.test(character);
    },
    IsNumberCharacter(character) {
        return /^\d*\.?\d*$/gi.test(character);
    },
    ToRGBA(Color) {
        if (Color) {
            let a = Utils.UseNull(Utils.UseNull(Color.a, Color.A), 1);
            a = this.IsNumber(a) ? a : 1;
            if (a < 0 || a > 1)
                a = 1;
            const r = Utils.UseNull(Utils.UseNull(Color.r, Color.R), 255);
            const g = Utils.UseNull(Utils.UseNull(Color.g, Color.G), 255);
            const b = Utils.UseNull(Utils.UseNull(Color.b, Color.B), 255);
            return `rgba(${r},${g},${b},${a})`;
        }

        return null;
    },
    ToPickerRGBA(Color) {
        if (Color && Array.isArray(Color.rgba)) {
            if (Color.rgba.length === 3) {
                return `rgba(${this.UseNull(Color.rgba[0], 0)}, ${this.UseNull(Color.rgba[1], 0)}, ${this.UseNull(Color.rgba[2], 0)}, 1)`;
            }
            else if (Color.rgba.length === 4) {
                return `rgba(${this.UseNull(Color.rgba[0], 0)}, ${this.UseNull(Color.rgba[1], 0)}, ${this.UseNull(Color.rgba[2], 0)}, ${this.UseNull(Color.rgba[3], 1)})`;
            }
        }
        return 'rgba(0, 0, 0, 1)';
    },
    TransferProps(Source, Target, ...Props) {
        forEach(Props, (prop) => {
            Target[prop] = Source[prop];
            delete Source[prop];
        })
    },
    Combinations(arr) {        
        return new Array(1 << arr.length).fill().map(
            (e1, i) => arr.filter((e2, j) => i & 1 << j));
    },
    GenerateAllVariations(States, GetState) {
        const StateVariations = [];
        const StateModels = [];
        States.map((stateid, i) => {
            const vars = ['Default'];
            const stateModel = GetState(stateid);
            StateModels.push(stateid);

            if (stateModel.SingleVariation) {
                vars.push(stateid);
            }
            else {
                Utils.ForEach(stateModel.Variations.Order, (VariationId, i) => {
                    vars.push(VariationId);
                });
            }
            StateVariations.push(vars);
        });
        
        const StateVariationsMatrix = [...cartesian(...StateVariations)];
        const result = [];
        StateVariationsMatrix.map((stateArray) => {
            const state = {};
            stateArray.map((stateVariation, i) => {
                if (stateVariation !== 'Default') {                    
                        state[StateModels[i]] = stateVariation;
                }
            })
            result.push(state);
        })
        console.log(result);
        return result;

    },
    CheckUnique(value, list, propName, sourceItem, CaseSensitive) {
        let failed = false;
        if (list && value) {
            const valueString = value.toString();
            forEach(list, (item) => {
                if ( item && item[propName]) {
                    const propValue = item[propName].toString();
                    if (CaseSensitive ? propValue === valueString : (propValue.toUpperCase() === valueString.toUpperCase())) {
                        if (sourceItem !== item) {
                            failed = true;
                            return false;
                        }
                    }
                }                
            })
        }
        return !failed;
    },
    StringInsertAt(string, index, value) {
        return string.substr(0, index) + value + string.substr(index);
    },
    IsDeleteCode(code) {
        return (this.platform.indexOf('Mac') > -1 &&  code === 8) || code === 46;
    },
    IsAltKey(e) {
        return e.altKey;
    },
    IsCtrlKey(e) {
        if(this.platform.indexOf('Mac') > -1)//mac OS
        {
            if ( e.key === "Meta" || e.metaKey) 
                return true;
        }
        return e.ctrlKey || e.keyCode === 17;
    },
    KeyCodes : {
        LEFT : 37,
        UP : 38,
        RIGHT : 39,
        DOWN : 40,
        TAB : 9,
        SPACEBAR : 32,
        ESCAPE : 27,
        A : 65,
        C : 67,
        X : 88,
        V : 86,
        Y : 89,
        Z : 90,
        Zero : 48,
        Plus : 187,
        Minus : 189
    },
    IsFlexRow(direction) {
        return direction === 'row' || direction === 'row-reverse';
    },
    IsFlexColumn(direction) {
        return direction === 'column' || direction === 'column-reverse';
    },
    GetGradientCss(colorpoints, type, angle, getStyleColor) {
        const sortedColor = this.Sort(colorpoints, (c) => {return c.stop})
        let colors = '';
        sortedColor.map((color, i) => {
            let colorvalue = color.color;
            if (color.colorId && getStyleColor) {
                colorvalue = getStyleColor(color.colorId);
            }
            colors += `${colorvalue} ${Number(color.stop).toFixed(2)}%`;
            if (i < sortedColor.length - 1)
                colors += ',';
        })
        if (type === 'radial')
            return `radial-gradient(${colors})`;
        else
            return `linear-gradient(${angle}deg, ${colors})`;
         
        return '';
    },
    GetShadowCss(shadows, textShadow, getStyleColor) {
        let shadowvalue = '';
        Utils.ForEach(shadows,( shadow , i) => {
            if (shadow) {
                let color = shadow.color;
                if (shadow.previewColorId) {
                    color = getStyleColor && getStyleColor(shadow.previewColorId);
                }
                else if (shadow.colorId) {
                    color = getStyleColor && getStyleColor(shadow.colorId);
                }
                let inset = '';
                if (shadow.type === 'in')
                    inset = 'inset ';

                let shadowlayer = `${inset}${shadow.x||0}px ${shadow.y||0}px ${shadow.blur||0}px`;
                if (!textShadow)
                    shadowlayer += ` ${shadow.spread||0}px`;
                shadowlayer += ` ${color || 'black'}`;
                
                if (i === 0)
                    shadowvalue = shadowlayer;
                else
                    shadowvalue = shadowlayer + ', ' + shadowvalue;
            }            
        });   
        return shadowvalue;
    },
    GetTransformCss(transforms, originX, originY, getTokenValue) {
        return this.GetTransformCssAll(transforms, originX, originY, false);
    },
    GetTransformCssAll(transforms, originX, originY, all) {
        const result = {
            transform : ''
        }        
        let hasTranslate, hasRotate, hasScale, hasSkew;
        Utils.ForEach(transforms, (transform, i) => {
            let space = Utils.IsNotNullOrEmpty(result.transform) ? ' ' : '';
            if (transform.type === 'translate') {                
                let x = this.JustGet(transform, 0, 'x', 'value');
                let x_unit = this.JustGet(transform, 0, 'x', 'Unit');
                let y = this.JustGet(transform, 0, 'y', 'value');
                let y_unit = this.JustGet(transform, 0, 'y', 'Unit');
                result.transform += space + `translate(${this.px(x, x_unit)}, ${this.px(y, y_unit)})`;
                hasTranslate = true;
            }
            else if (transform.type === 'rotate') {      
                ['rotateZ', 'rotateX', 'rotateY'].map((prop) => {
                    let rotate = this.JustGet(transform, 0, prop);
                    if (rotate === 0) {
                        if (all)
                            result.transform += space + `${prop}(0)`;
                    }
                    else
                        result.transform += space + `${prop}(${rotate}deg)`;
                })
                hasRotate = true;
            }
            else if (transform.type === 'scale') {      
                ['scaleX', 'scaleY'].map((prop) => {
                    let scale = this.JustGet(transform, 1, prop);
                    result.transform += space + ` ${prop}(${scale})`;
                })
                hasScale = true;
            }
            else if (transform.type === 'skew') {      
                ['skewX', 'skewY'].map((prop) => {
                    let skew = this.JustGet(transform, 0, prop);
                    if (skew === 0)
                        result.transform += space + `${prop}(0)`;
                    else
                        result.transform += space + `${prop}(${skew}deg)`;
                });
                hasSkew = true;
            }
        });
        if (all) {
            let space = Utils.IsNotNullOrEmpty(result.transform) ? ' ' : '';
            if (!hasTranslate)
                result.transform += space + 'translateX(0) translateY(0)';
            if (!hasRotate)
                result.transform += space + 'rotateZ(0) rotateX(0) rotateY(0)';
            if(!hasScale)
                result.transform += space + 'scaleX(1) scaleY(1)';
            if (!hasSkew)
                result.transform += space + 'skewX(0) skewY(0)';
        }
        result.transformOrigin = `${originX || 'center'} ${originY || 'center'}`
        return result;
    },   
    GetTransformMotionObject(transforms) {
        const motionObject = {};
        Utils.ForEach(transforms, (transform, i) => {
            if (transform.type === 'translate') {                
                let x = Utils.JustGet(transform, 0, 'x', 'value');
                let x_unit = Utils.JustGet(transform, 0, 'x', 'Unit');
                let y = Utils.JustGet(transform, 0, 'y', 'value');
                let y_unit = Utils.JustGet(transform, 0, 'y', 'Unit');
                
                motionObject.x = Utils.px(x, x_unit);
                motionObject.y = Utils.px(y, y_unit);
    
            }
            else if (transform.type === 'rotate') {      
                ['rotateZ', 'rotateX', 'rotateY'].map((prop) => {
                    let rotate = Utils.JustGet(transform, 0, prop);
                    motionObject[prop] = `${rotate}deg`;
                })
            }
            else if (transform.type === 'scale') {      
                ['scaleX', 'scaleY'].map((prop) => {
                    let scale = Utils.JustGet(transform, 1, prop);
                    motionObject[prop] = scale;
                })
            }
            else if (transform.type === 'skew') {      
                ['skewX', 'skewY'].map((prop) => {
                    let skew = Utils.JustGet(transform, 0, prop);
                    motionObject[prop] = `${skew}deg`;
                });
            }
        });
    
        return motionObject;
    },
    GetTransformOrigin(transform) {
        let originX = 0, originY = 0;
        if (transform.originX === 'custom') {
            originX = Utils.px(transform.customOriginX || 0, '%');
        }
        else {
            if (transform.originX === 'left')
                originX = '0%';
            else if (transform.originX === 'right')
                originX = '100%';
            else 
                originX = '50%';
        }
        if (transform.originY === 'custom') {
            originY = Utils.px(transform.customOriginY || 0, '%');
        }
        else {
            if (transform.originY === 'top')
                originY = '0%';
            else if (transform.originY === 'bottom')
                originY = '100%';
            else 
                originY = '50%';
        }
        return `${originX} ${originY}`;
    },
    GetFilterCss(filters, filterProps) {
        let css_filter = '';
        if (filters) {
            filterProps.propsList.map((prop) => {
                const value = Utils.JustGet(filters, null, prop, 'value');
                if (Utils.IsNotNullOrEmpty(value)) {
                    let unit = '';
                    if (filterProps.subProps[prop].percent)
                        unit = '%';
                    else if (filterProps.subProps[prop].type === 'size')
                        unit = 'px';
                    else if (filterProps.subProps[prop].type === 'angle')
                        unit = 'deg';
                    css_filter += `${prop}(${value}${unit}) `;
                }
            });
        }
        return this.trim(css_filter);
    },
    BorderStyles : {
        Sides : ['', 'Top', 'Left', 'Bottom', 'Right'],
        Corners : ['', 'TopLeft', 'TopRight', 'BottomLeft', 'BottomRight'],
        CornerLabels : ['', 'Top Left', 'Top Right', 'Bottom Left', 'Bottom Right'],
        propColor : (side) => `border${side}Color`,
        labelColor : (side) => `Border ${side} Color`,
        propSize : (side) => `border${side}Width`,
        labelSize : (side) => `Border ${side} Width`,
        propStyle : (side) => `border${side}Style`,
        labelStyle : (side) => `Border ${side} Style`,
        propRadius : (side) => `border${side}Radius`,
        labelRadius : (side) => `Border ${side} Radius`,
        isBorderName : (name) => {
            return ['borderStyle', 'borderLeftStyle', 'borderRightStyle', 'borderTopStyle', 'borderBottomStyle'].indexOf(name) > -1;
        },
        isBorderRadius : (name) => {
            return ['borderRadius', 'borderTopLeftRadius', 'borderTopRightRadius', 'borderBottomLeftRadius', 'borderBottomRightRadius'].indexOf(name) > -1;
        },
        isSpacingName : (name) => {
            return name.indexOf('padding') === 0 || name.indexOf('margin') === 0;
        },
        propPadding : (side) => `padding${side}`,
        propMargin : (side) => `margin${side}`,
    },    
    UrlBase64(data) {
        return `data:image/svg+xml;base64,${data}`;
    },
    GetSubset(keys, obj = {}) {
        keys.reduce((a, c) => ({ ...a, [c]: obj[c] }), {});
    },
    ConvertObjectToCss(styleObject) {
        let cssString = "";
        for (let objectKey in styleObject) {
            cssString += objectKey.replace(/([A-Z])/g, (g) => `-${g[0].toLowerCase()}`) + ": " + styleObject[objectKey] + ";\n";
        }

        return cssString;
    },
    SetStyleSheet({Id, StyleDefinition, TargetDocument}) {
        let SheedId = Id || this.Id();
        const UseDocument = TargetDocument || document;
        var sheet = UseDocument.getElementById(SheedId);
        if (!sheet) {
            sheet = UseDocument.createElement('style')
            sheet.type = 'text/css';
            sheet.id = SheedId;
            UseDocument.body.appendChild(sheet);
        }
        sheet.innerHTML = StyleDefinition;
        return SheedId;
    },
    RemoveStyleSheet(Id) {
        const el = document.getElementById(Id);
        if (el && el.parentNode)
            el.parentNode.removeChild(el);
    },
    Defaults : {
        Unit() {
            return 'px'
        },
    },
    SizeProps : ['width', 'height', 'minWidth', 'minHeight', 'maxWidth', 'maxHeight'],    
    Format(Prefix, Value, Postfix) {
        return `${Prefix}${Value}${Postfix}`;
    },
    Scale(value) {
        return `scale(${value})`
    },
    States : {
        Combinations : function(arr) {
            return new Array(1 << arr.length).fill().map(
                (e1, i) => arr.filter((e2, j) => i & 1 << j));
        },        
        AllPermutations : function(items) {
            return items.reduce((a, b) => a.reduce((r, v) => r.concat(b.map(w => [].concat(v, w))), []));
        },
        GenerateAllVariations({States, StateOrders, Responsive}) {
            const UseStates = Utils.DeepClone(States);
            const UseOrder = Utils.DeepClone(StateOrders);
            if (!UseStates.Default) {                
                UseStates.Default = {
                    Variations : {
                        Order : [
                            'Default', 'Large', 'Medium', 'Small', 'xSmall'
                        ]
                    }
                }
                UseOrder.splice(0, 0,'Default');
            }
            const variations = [];
            UseOrder.map((stateid, i) => {
                const stateModel = UseStates[stateid];
                if (stateModel) {
                    if (stateModel.SingleVariation) {
                        variations.push([stateid]);
                    }
                    else {
                        if (stateModel.Variations && stateModel.Variations.Order) {
                            variations.push(stateModel.Variations.Order)
                        }                    
                    }
                }                
            });
            const AllVariations = this.AllPermutations(variations);
            
            
            return AllVariations;
        },
        BuildStateArrayFromVariations({States, StateOrders, Variations, PseudoStates} ) {
            const StateArray = [];
            if (Variations) {
                let notEmpty = false;
                Utils.ForEach(StateOrders, (StateId) => {
                    if (Variations[StateId]) {
                        StateArray.push(Variations[StateId]);
                    }
                })

                if (PseudoStates && PseudoStates.Order) {
                    Utils.ForEach(PseudoStates.Order, (StateId) => {
                        if (Variations[StateId]) {
                            StateArray.push(Variations[StateId]);
                        }
                    })
                }
            }
            if (StateArray.length === 0)
                return ['Default']
            return StateArray;
        },
        BuildSystemStateLabelFromVariations({States, StateOrder, StateVariation}) {
            const result = {
                NonMediaState : ''
            };
            let State = 'Default';
            let notEmpty = true, notEmptyCustom = false;
            if (StateVariation.System_MediaSize) {
                State = '';
                notEmpty = false;                        
            }

            if (StateVariation) {
                Utils.ForEach(StateOrder, (StateId) => {
                    if (StateVariation[StateId]) {
                        if (States[StateId].ResetOthers)
                            State = '';
                        State += `${notEmpty ? ',' : ''}${StateVariation[StateId]}`;
                        
                        if (StateId !== 'System_MediaSize') {
                            result.NonMediaState += `${notEmptyCustom ? ',' : ''}${StateVariation[StateId]}`;
                            notEmptyCustom = true;
                        }
    
                        notEmpty = true;
                    }
                })
            }       
    
            if (!Utils.IsNotNullOrEmpty(State))
                State = 'Default';
            
            result.State = State;
            return result;
        },
        BuildStateLabelFromVariations({States, StateOrders, Variations, PseudoStates, PseudoStateVariations, ModelValues}) {
            let StateLabel = '';
            if (Variations) {
                let notEmpty = false;
                Utils.ForEach(StateOrders, (StateId) => {
                    if (Variations[StateId]) {
                        if (States[StateId]) {
                            if (States[StateId].ResetOthers)
                                StateLabel = '';
                            StateLabel += `${notEmpty ? ',' : ''}${Variations[StateId]}`;
                            notEmpty = true;
                        }                        
                    }
                })
                // if (!Utils.IsNotNullOrEmpty(StateLabel)) {
                //     StateLabel = 'Default';
                //     notEmpty = true;
                // }                    

                if (PseudoStates && PseudoStates.Order) {
                    Utils.ForEach(PseudoStates.Order, (StateId) => {
                        if (Variations[StateId]) {
                            StateLabel += `${notEmpty ? ',' : ''}${Variations[StateId]}`;
                            notEmpty = true;
                        }
                    })
                }
            }

            if (!Utils.IsNotNullOrEmpty(StateLabel))
                StateLabel = 'Default';

            return StateLabel;
        },
        BuildStateArrayFromStateLabel({StateLabel}) {
            const States = StateLabel.split(',');
            const StateCombinations = Utils.Combinations(States).filter(x => x.length < States.length && x.length > 0);
            const StateArray = [];
            if (States.indexOf('Default') < 0)
                StateArray.splice(0, 0, 'Default');
            if (StateCombinations) {
                for (let i = 1; i < States.length; i++) {
                    Utils.ForEach(StateCombinations, (sc) => {
                        if (sc.length === i) {
                            let s = sc.join(',');
                            if (Utils.IsNotNullOrEmpty(s))
                                StateArray.push(s);
                        }
                    });
                }
            }
            if (Utils.IsNotNullOrEmpty(StateLabel))
                StateArray.push(StateLabel);

            return StateArray;
        },        
        BuildComponentFullStyleState({GlobalStateVariations, ComponentStateVariations}) {
            const StyleState = [];
            
            const StateCombinations_Global = GlobalStateVariations.filter(x => x.length > 0);
            const StateCombinations_Component = ComponentStateVariations.filter(x => x.length > 0);
                    
            const GlobalStateArray = [];
            Utils.ForEach(StateCombinations_Global, (Array_GlobalState, i_gs) => {
                let GlobalStateArrays = [Array_GlobalState];
                Utils.ForEach(Array_GlobalState, (gs, i) => {                
                    if (Utils.IsOneOf(gs, 'Large', 'Medium', 'Small', 'xSmall')) {
                        GlobalStateArrays = [];
                        const pushAlternate = (replaceWith) => {
                            const instance = Utils.DeepClone(Array_GlobalState);
                            instance.splice(i, 1, replaceWith)
                            GlobalStateArrays.push(instance);    
                        }
                        pushAlternate('Default');
                        if (gs === 'Medium' || gs === 'Small' || gs === 'xSmall') {
                            pushAlternate('Large');
                            if (gs === 'Small' || gs === 'xSmall') {
                                pushAlternate('Medium');
                                if (gs === 'xSmall') {
                                    pushAlternate('Small');
                                }
                            }
                        }
                        GlobalStateArrays.push(Array_GlobalState);
                        return false;
                    }
                });
    
                Utils.ForEach(GlobalStateArrays, (gsa, i) => {
                    const GlobalState = gsa.join(',');
                    if (GlobalStateArray.indexOf(GlobalState) < 0) {
                        GlobalStateArray.push(GlobalState);
                        Utils.ForEach(StateCombinations_Component, (Array_Component, i_cs) => {
                            const ComponentState = Array_Component.join(',');
                            StyleState.push({
                                Global : GlobalState,
                                Component : ComponentState
                            })
                        });
                    }                    
                });            
            });

            return StyleState;
        },
        BuildComponentStyleStateFromReversedArrays({GlobalState, ComponentState}) {
            const StyleState = [];

            let States_Global = GlobalState.split(',');
            let States_Component = ComponentState.split(',');

            const StateCombinations_Global = Utils.Combinations(States_Global).filter(x => x.length > 0);
            const StateCombinations_Component = Utils.Combinations(States_Component).filter(x => x.length > 0);
        
            if (GlobalState !== 'Default')
                StateCombinations_Global.splice(0, 0, ['Default']);
            if (ComponentState !== 'Default')
                StateCombinations_Component.splice(0, 0, ['Default']);

            const GlobalStateArray = [];
            Utils.ForEach(StateCombinations_Global, (Array_GlobalState, i_gs) => {
                let GlobalStateArrays = [Array_GlobalState];
                Utils.ForEach(Array_GlobalState, (gs, i) => {                
                    if (Utils.IsOneOf(gs, 'Large', 'Medium', 'Small', 'xSmall')) {
                        GlobalStateArrays = [];
                        const pushAlternate = (replaceWith) => {
                            const instance = Utils.DeepClone(Array_GlobalState);
                            instance.splice(i, 1, replaceWith)
                            GlobalStateArrays.push(instance);    
                        }
                        pushAlternate('Default');
                        if (gs === 'Medium' || gs === 'Small' || gs === 'xSmall') {
                            pushAlternate('Large');
                            if (gs === 'Small' || gs === 'xSmall') {
                                pushAlternate('Medium');
                                if (gs === 'xSmall') {
                                    pushAlternate('Small');
                                }
                            }
                        }
                        GlobalStateArrays.push(Array_GlobalState);
                        return false;
                    }
                });
    
                Utils.ForEach(GlobalStateArrays, (gsa, i) => {
                    const GlobalState = gsa.join(',');
                    if (GlobalStateArray.indexOf(GlobalState) < 0) {
                        GlobalStateArray.push(GlobalState);
                        Utils.ForEach(StateCombinations_Component, (Array_Component, i_cs) => {
                            const ComponentState = Array_Component.join(',');
                            StyleState.push({
                                Global : GlobalState,
                                Component : ComponentState
                            })
                        });
                    }                    
                });            
            });

            return StyleState;
        },
        ChangeSystemStateMediaSize(StateLabel, MediaSize) {
            let States_Global = Utils.UseNullOrEmpty(StateLabel, 'Default').split(',');
            States_Global[0] = MediaSize;
            return States_Global.join(',');
        },
        GenerateStateArray({GlobalStateLabel, StateCombinations_Component}) {
            const Result = {
                GlobalStateArray : []
            }

            if (StateCombinations_Component)
                Result.StateArray = [];

            Result.GlobalState = Utils.UseNullOrEmpty(GlobalStateLabel, 'Default');
            let States_Global = Result.GlobalState.split(',');
            const StateCombinations_Global = Utils.Combinations(States_Global).filter(x => x.length > 0);

            if (Result.GlobalState !== 'Default')
                StateCombinations_Global.splice(0, 0, ['Default']);

            Utils.ForEach(StateCombinations_Global, (Array_GlobalState, i_gs) => {
                let GlobalStateArrays = [Array_GlobalState];
                Utils.ForEach(Array_GlobalState, (gs, i) => {                
                    if (Utils.IsOneOf(gs, 'Large', 'Medium', 'Small', 'xSmall')) {
                        GlobalStateArrays = [];
                        const pushAlternate = (replaceWith) => {
                            const instance = Utils.DeepClone(Array_GlobalState);
                            instance.splice(i, 1, replaceWith)
                            GlobalStateArrays.push(instance);    
                        }
                        pushAlternate('Default');
                        if (gs === 'Medium' || gs === 'Small' || gs === 'xSmall') {
                            pushAlternate('Large');
                            if (gs === 'Small' || gs === 'xSmall') {
                                pushAlternate('Medium');
                                if (gs === 'xSmall') {
                                    pushAlternate('Small');
                                }
                            }
                        }
                        GlobalStateArrays.push(Array_GlobalState);
                        return false;
                    }
                });
    
                Utils.ForEach(GlobalStateArrays, (gsa, i) => {
                    const GlobalState = gsa.join(',');
                    if (Result.GlobalStateArray.indexOf(GlobalState) < 0) {
                        if (Utils.IsNotNullOrEmpty(GlobalState))
                            Result.GlobalStateArray.push(GlobalState);                        
                        if (StateCombinations_Component) {
                            Result.StateArray.push({
                                Global : GlobalState,
                                Component : 'Default'
                            })
                            
                            Utils.ForEach(StateCombinations_Component, (Array_Component, i_cs) => {
                                const ComponentState = Array_Component.join(',');
                                if (ComponentState !== 'Default') {
                                    Result.StateArray.push({
                                        Global : GlobalState,
                                        Component : ComponentState
                                    })
                                }                                
                            });
                        }                        
                    }                    
                });            
            });

            return Result;
        }
    }
}

export default Utils;
