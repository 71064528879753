import React from 'react';
import ReactDOM from 'react-dom';
import ReactResizeDetector from 'react-resize-detector';
import MetaData from '../../../../toolabs-metaui';

var eCharts = null;

const MetaChart = MetaData.Components.Widget.WidgetTypes.eCharts.MetaData;

class Widget_EChart extends React.Component
{
    constructor(props) {
        super(props);               
        
        this.state = {  }
        this.Ref_EChart = React.createRef();

        this.Load = this.Load.bind(this);
        this.onResize = this.onResize.bind(this);
    }
    componentDidMount() {
        // const DN = ReactDOM.findDOMNode(this);
        // if (DN) {
        //     DN.addEventListener('resize', this.OnResize);
        // }
        if (!eCharts) {
            const p = [import('echarts')];
            Promise.all(p).then((r) => {
                eCharts = r[0];                
                this.Load();
            })
        }
        else
            this.Load();
    }
    onResize() {
        const that = this;
        setTimeout(() => {
            if (that.eChartInstance && !that.eChartInstance.isDisposed()) {
                that.eChartInstance.resize();    
            }
        }, 100);
        
    }
    Load() {
        if (this.Ref_EChart.current) {
            const WidgetOptions = this.props;
            if (WidgetOptions) {
                if (WidgetOptions.ChartType) {
                    if (WidgetOptions.ChartOptions) {

                    }
                    else {
                        if (MetaChart.Types[WidgetOptions.ChartType]) {
                            const MetaChartType = MetaChart.Types[WidgetOptions.ChartType];
                            let ChartDef = MetaChartType.SubTypes.Default;
                            if (WidgetOptions.ChartSubType && MetaChartType.SubTypes[WidgetOptions.ChartSubType]) 
                                ChartDef = MetaChartType.SubTypes[WidgetOptions.ChartSubType];

                            if (ChartDef && ChartDef.init) {
                                this.eChartInstance = ChartDef.init(eCharts, ReactDOM.findDOMNode(this.Ref_EChart.current), WidgetOptions.ChartTheme);
                                this.setState({Loaded : true});
                                return;
                            }
                        }
                    }
                }
            }

            var option = {
                title: {
                    text: 'ECharts entry example'
                },
                tooltip: {},
                legend: {
                    data:['Sales']
                },
                xAxis: {
                    data: ["shirt","cardign","chiffon shirt","pants","heels","socks"]
                },
                yAxis: {},
                series: [{
                    name: 'Sales',
                    type: 'bar',
                    data: [5, 20, 36, 10, 10, 20]
                }]
            };

            this.eChartInstance =  eCharts.init(ReactDOM.findDOMNode(this.Ref_EChart.current));
            this.eChartInstance.setOption(option);
        }
    }
    componentWillUnmount() {
        this.eChartInstance && this.eChartInstance.dispose();
        this.eChartInstance = null;
    }
    
    render() {               
        return (
            <React.Fragment>
                <div style={{width : '100%', height:'100%', boxSizing : 'border-box'}} ref={this.Ref_EChart} />
                {this.state.Loaded && <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} /> }
            </React.Fragment>
        ) 
    }
}

export default Widget_EChart;
