import React from 'react';
import ReactDOM from 'react-dom';

let LottiePlayer;

class Widget_Lottie extends React.Component
{
    constructor(props) {
        super(props);        
        this.state =  {
            Loaded : false
        };
        this.Ref_Player = React.createRef();
    }
    componentWillUnmount() {
        this.state.Mounted = false;
        if (this.PlayerInstance)
            this.PlayerInstance.destroy();
    }
    componentDidMount() {        
        LottiePlayer = window.bodymovin;

        if (!LottiePlayer) {            
            const script = document.createElement("script");
            script.src = "https://cdnjs.cloudflare.com/ajax/libs/bodymovin/5.6.5/lottie.min.js";
            script.async = true;
            script.onload = this.HeaderLoaded.bind(this, true);
            document.head.appendChild(script);
        }
        else
            this.Load();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.speed !== nextProps.speed) {
            this.PlayerInstance.setSpeed(nextProps.speed || 1);
        }
        else if (nextProps.LottieUrl !== this.props.LottieUrl) {
            this.Load(nextProps);
        }
        return false;
    }
    HeaderLoaded(scriptLoaded, styleLoaded) {
        if (scriptLoaded) {
            this.scriptLoaded = true;
        }
        if (styleLoaded)
            this.styleLoaded = true;
        if (this.scriptLoaded && this.styleLoaded) {
            LottiePlayer = window.bodymovin;
            
            if (LottiePlayer) {
                this.Load();
            }                    
        }
    }
  
    Load(props = this.props) {
        if (this.Ref_Player && LottiePlayer) {   
            const DN = ReactDOM.findDOMNode(this.Ref_Player.current);  
            this.PlayerInstance = new LottiePlayer.loadAnimation({
                container: DN,
                path: props.LottieUrl,
                renderer: 'svg/canvas/html',
                loop: true, // Optional
                autoplay: true, // Optional
                rendererSettings : {
                    progressiveLoad: true,
                }

            });
            this.PlayerInstance.setSpeed(props.speed || 1);
        }
    }
    render() {       
        return (
            <div style={{...this.props.style, overflow : 'hidden', width : '100%', height: '100%'}} ref={this.Ref_Player} >                    
            </div>
        )        
    }
}

export default Widget_Lottie;
