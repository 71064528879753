import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenPanelHeader, TokenGalleryView, DropAreaContainer, DropFileArea } from '../../common';
import { LeftScrollPanel } from '../../../common';
import SizeEditor from '../../../../right/iteminspector/styleitems/size';

export default class IconSizeEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onChangeSize = this.onChangeSize.bind(this);

        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.EditingModel = {
                name : this.token.name,
                value : Utils.DeepClone(Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), {}))
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!nextProps.newModel) {
                this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
                if (!this.token) {
                    this.props.onClose();
                    return false;
                }
                this.EditingModel = {
                    name : this.token.name,
                    value : Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), {})
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.IconSize);
    }        
    onChangeSize(size) {
        if (!this.props.newModel && !this.ChangeLogged) {
            this.ChangeLogged = true;
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Size'});
        }
        Utils.Set(this.EditingModel, size.value, 'value', 'value');
        Utils.Set(this.EditingModel, size.unit, 'value', 'Unit');
        if (!this.props.newModel) {
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : this.EditingModel.value, type : Globals.ProjectManager.Tokens.Types.IconSize});

            const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
            const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');

            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.IconSize,
                value : Utils.px(width, unit)
            }]);
        }
        else {
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel.value);
        }
        this.RCUpdate();
    }          
    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : name} );
        this.RCUpdate();
    }

   
    renderCustom() {        
        const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
        const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');
        const size = Utils.px(width, unit);
        return (
            <SC.FCol fw fh>
                {
                    !this.props.noHeader && 
                    <TokenPanelHeader title={this.props.newModel ? 'NEW ICON SIZE' : 'EDIT ICON SIZE'} 
                        hasAddCancel 
                        notBackClosable={this.props.newModel ? true : false}
                        onClose={this.props.onCancel} 
                        onCancel={this.props.onCancel} 
                        onAdd={() => {
                            if (this.props.newModel) {
                                this.props.newModel.value = this.tokenvalue;
                            }
                            this.props.onSubmit();
                        }} 
                        onDelete={this.props.onDelete}
                    />
                }                        
                <LeftScrollPanel>
                    <SC.FCol fw fh f1 style={{padding : '8px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                        <ItemNameEditor
                            noMargin
                            fontSize='12px'                        
                            name={this.token.name}
                            onSaveName={this.SaveName}
                            onGetItems={this.GetItems}
                            model={this.token}
                        />
                        <SC.FCol f1 jsb style={{marginTop : '16px'}}>
                            <SC.FRow alc jsb style={{marginTop : '8px'}}>
                                <div>Size</div>
                                <SizeEditor 
                                    value={width}
                                    unit={unit}
                                    onChange={this.onChangeSize}
                                    onChanging={this.onChangeSize}
                                />                        
                            </SC.FRow>
                            <SC.FCol>
                                <div style={{
                                    border : '1px solid',
                                    borderColor : SC.CurrentTheme.theme.color_brand,
                                    borderRadius : '4px',
                                    height : size,
                                    width : size,
                                    marginTop : '24px',
                                    boxSizing : 'border-box'
                                }} />                        
                            </SC.FCol>
                        </SC.FCol>                                                       
                    </SC.FCol>        
                </LeftScrollPanel>
            </SC.FCol>            
        )
    }
}

const StyleItem_Icon_Box = styled.div`
    width : 100%;
    height : 180px;
    background-color : ${props => props.theme.color_content_back};
    ${
    props => {
        if (props.backgroundImage) {
            return css`
                    background-image : url(${props.backgroundImage});
                    background-size : contain;
                    background-repeat : no-repeat;
                    background-position : center;
                `;
        }
    }
    }
`;

export const IconBox = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    background-image : url(${props => props.url});
    background-size : 80% 80%;
    background-repeat : no-repeat;
    background-position : center;
    background-color : ${props => props.selected ? props.theme.back_lightest : 'transparent'}; 
    background-clip : content-box;
    width : 100%;
    height : 100%;
    flex : 1;
    border-radius : 1px;
    box-sizing : border-box;
    cursor : pointer;
    transition : all 0.2s ease;    
    &:hover {
        color : white;
        background-color : ${props => props.theme.back_lightest};
    } 
    ${
        props => {
            if (props.selected) {
                return css`
                    background-color : ${props => props.theme.back_lightest};
                `;
            }
        }
    }
`;