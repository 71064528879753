import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../importer';

import styled from 'styled-components';
import { GroupTitle } from '../designsystem/common';
import { DeviceSelectionButtons } from '../../../panzoom/responsive/deviceselection';
import StateSelector from '../../../selectors/state/stateselector';
import { motion, AnimatePresence } from 'framer-motion';
import { Devices, GetDeviceType } from '../../../panzoom/responsive/devices';
import { DotMenu } from '../common';
import SystemStateEditor from './editor';

export default class SystemStatePanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.onToggleExpand = this.onToggleExpand.bind(this);
        if (!this.props.notExpandable)
            this.expanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'States', 'Expanded');
        else
            this.expanded = true;
        this.ShowMenu = this.ShowMenu.bind(this);

        this.GetStateModel = this.GetStateModel.bind(this);
        this.SetStateVariation = this.SetStateVariation.bind(this);
        this.ResetToDefault = this.ResetToDefault.bind(this);
        this.EditStates = this.EditStates.bind(this);

        this.HasEditGrant = Globals.ProjectManager.CheckGrant_EditSystemStates();
        
        this.LoadDeviceSize(this.props);
    }
    ShowMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.RCUpdate();
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'States', 'Expanded');
        this.RCUpdate();
    }
    LoadDeviceSize(props) {
        let DeviceSize = Globals.ProjectManager.DataManager.Get({width : Devices.Default.Default.width, height : Devices.Default.Default.height}, 'Device', 'Size');

        if (!DeviceSize) {
            return;
        }
        this.DeviceWidth = DeviceSize.width;
        this.DeviceHeight = DeviceSize.height;
        this.DeviceType = GetDeviceType(this.DeviceWidth);
    }
    GetStateModel(id) {
        return Globals.ProjectManager.States.GetState(id);        
    } 
    SetStateVariation(stateId, varId) {
        if (this.props.onSetStateVariation)
            this.props.onSetStateVariation(stateId, varId);
        else {
            Globals.ProjectManager.States.SetSelectedVariation(stateId, varId === 'Default' ? null : varId );
            this.RCUpdate();
        }
    } 
    SetDeviceType(type) {
        this.DeviceType = type;
        this.DeviceWidth = Devices[type].Default.width;
        this.DeviceHeight = Devices[type].Default.height;
        this.SetStateVariation('System_MediaSize', type);
        // this.SetDeviceSize();
        this.RCUpdate();    
    }
    ResetToDefault() {
        this.isMenuOpen = false;
        Globals.ProjectManager.States.SetToDefault();
    }
    EditStates() {
        this.isMenuOpen = false;
        this.isEditingStates = !this.isEditingStates;
        this.RCUpdate();
    }
    renderCustom() {

        let content;        
        let stateEditor;

        if (this.isEditingStates) {
            stateEditor = (
                <AnimatePresence>
                    <motion.div 
                        style={{...SC.Styles.Absolute, zIndex : 999999, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                        initial={{opacity : 0.7, x : -24}}
                        animate={{opacity : 1, x : 0}}
                        exit={{opacity : 0, x : -24}}
                        transition={{duration : 0.2}}
                    >
                        <SystemStateEditor 
                            onClose={this.EditStates}
                            noBreakpoints={this.props.noBreakpointsEdit}
                            global
                        />
                    </motion.div>
                </AnimatePresence>
            )
        }
        else {
            if (this.expanded && !this.props.collapsed) {
                const states = Globals.ProjectManager.States.Get().Order || [];
                const currentVariations = this.props.currentVariations || Globals.ProjectManager.States.GetSelectedVariation();
    
                const stateitems = [];
                let hasStates = false;
                states.map((stateid, i) => {
                    const stateModel = this.GetStateModel(stateid);
                    if (stateModel.CustomSelector || stateModel.SystemState && !this.props.preview && !this.props.systemStates) {
    
                    }
                    else {                        
                        if (this.props.filteredStates && this.props.filteredStates.indexOf(stateid) > -1) {

                        }
                        else {
                            hasStates = true;

                            stateitems.push(
                                <StateSelector                             
                                    vertical
                                    id={stateid} 
                                    key={stateid}
                                    SelectedVariation={currentVariations}
                                    onSetStateVariation={this.SetStateVariation}
                                    GetStateModel={this.GetStateModel}
                                    preview={this.props.preview}
                                    compact
                                />
                            );
                        }                            
                    }                                                        
                }) 
    
                content = (
                    <motion.div
                        initial={{opacity : 0.5, y : -4}}
                        animate={{opacity : 1, y : 0}}
                        transition={{duration  :0.2}}
                    >
                        <SC.FCol
                            style={{
                                backgroundColor : SC.CurrentTheme.theme.back_lighter,
                                margin : '8px',
                                marginTop : '4px',
                                borderRadius : '4px',
                                ...this.props.contentStyle
                            }}
                        >
                            {
                                !this.props.noBreakpoints && 
                                <SC.FCol style={{
                                    padding : '4px',
                                    borderBottom : '1px solid',
                                    borderColor : SC.CurrentTheme.theme.back
                                }}>
                                    <DeviceSelectionButtons 
                                        deviceType={Globals.ProjectManager.GetDeviceType()} 
                                        onToggleResponsive={this.SetStateVariation.bind(this, 'System_MediaSize', 'Default')}
                                        onSelectDevice={this.SetDeviceType.bind(this)} 
                                        themeId={this.props.themeId}
                                        hideLargest
                                        hasAllDevices={{
                                            selected : Globals.ProjectManager.GetDeviceType() === 'Default'
                                        }}
                                    />
                                </SC.FCol>
                            }                                
                            {
                                hasStates && 
                                <SC.FCol style={{padding  :'8px', paddingTop : '4px', paddingBottom : '4px'}}>
                                    {stateitems}                      
                                </SC.FCol>                                             
                            }                         
                        </SC.FCol>
                    </motion.div>
                )
            }
        }

        if (this.props.justContent) {            
            return (
                <React.Fragment>
                    {content}
                    {stateEditor}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <SC.FCol style={{padding : '2px', paddingTop : '6px', paddingBottom : this.expanded ? '2px' : '6px', backgroundColor : SC.CurrentTheme.theme.back, borderBottom : SC.CurrentTheme.theme.border_ondark, ...this.props.style}}>
                    <GroupTitle 
                        title={(
                            <SC.FRow alc>
                                <div>SYSTEM STATES</div>
                                <div 
                                    style={{
                                        width : '8px', height : '8px', borderRadius : '4px', marginLeft : '8px',
                                        backgroundColor : Globals.ProjectManager.CurrentState === 'Default' ? SC.CurrentTheme.theme.border_onlight : SC.CurrentTheme.theme.color_brand
                                    }}
                                />                    
                            </SC.FRow>
                        )} expandable={{}} 
                        expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand}}
                        style={{paddingRight : '4px'}}
                        addOn={this.HasEditGrant ? (
                            <DotMenu onClick={this.ShowMenu} isOpen={this.isMenuOpen} onClose={this.ShowMenu}>
                                {
                                    this.isMenuOpen &&
                                    <SC.FCol style={{minWidth : '160px'}}>
                                        <SC.PopupItem onClick={this.EditStates}>
                                            Edit States
                                        </SC.PopupItem>
                                        <SC.PopupItem onClick={this.ResetToDefault}>
                                            Reset to Default 
                                        </SC.PopupItem>
                                    </SC.FCol>
                                }
                            </DotMenu>                       
                        ) : null}
                    />
                    {content}
                </SC.FCol>
                {stateEditor}
            </React.Fragment>            
        )
    }
}

export const StateItemBox = styled.div`
    background-color : ${props => props.theme.back_lighter};
    margin-bottom : 4px;
    padding : 8px;
    transition : all 0.3s ease;
    cursor : move;
    border-radius : 2px;
    overflow : hidden;
    border : ${props => props.theme.isLight ? '1px solid rgba(255,255,255,0)' : 'none'};
    &: hover{
        background-color : ${props => props.theme.back_light};
        color : ${props => props.theme.font_hover};
        border : ${props => props.theme.isLight ? props.theme.border_ondark : 'none'};
    }
`;