import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../../importer';

import AppLayout from '../../../../../../../../appstate/AppLayout';
import {TransitionCardItem, TransitionListItem, AddNewTransitionToken, GetTransitionTokenList } from '../../../../../left/designsystem/motion/transitions';
import {AddNewEaseCurveToken} from '../../../../../left/designsystem/motion/easing';
import EaeseCurveEditor from '../../../../../left/designsystem/motion/easing/item';
import BaseSelectToken from '../../BaseSelectToken';
import { GetEaseCurveTokenList, EasePattern } from '../../../../../left/designsystem/motion/easing';

export default class SelectEaseCurveToken extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW EASE CURVE';
        this.moduleName = 'EaseCurves';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Motion;        

        this.Ref_ImageEdit = React.createRef();

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'GlobalState')) {
            this.Load(nextProps);
            return true;
        }        
    }
    GetTokens(props) {
        return GetEaseCurveTokenList(props.StateArray);
    }            
    GetNewModel(Id) {
        const Model = {
            name : 'New Ease Curve',
            items : [
                {
                    id : Utils.Id(),
                    type : 'scale',
                    locked : true
                }    
            ],
        };
        AddNewEaseCurveToken(Model, Id);
        this.FireSelectToken(Id);
        return Model;
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const EaseCurveItem = AddNewEaseCurveToken(this.EditingToken.Model, this.EditingToken.Id);
            AppLayout.Refs.DesignSystem.EaseCurves && AppLayout.Refs.DesignSystem.EaseCurves.Reload();
            return EaseCurveItem;
        }        
        else {
        }                
    }  
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;        
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Motion, willDeleteId);
    } 
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const EaseCurve = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : this.props.StateArray});
        
        // SelectedToken.value = Utils.GetTransitionCss(transforms, transform.originX, transform.originY, Globals.ProjectManager.Tokens.ValueOfId); 
    }
    GetStyleName() {
        return 'easeId';
    }
    renderTokenList() {
        let content;
        const styleName = this.GetStyleName();
        const easeCurves = [];

        Utils.ForEach(this.tokens, (easeCurve, i) => {
            easeCurves.push(
                <EasePattern
                    key={easeCurve.id}
                    notDraggable
                    listView={this.state.isListView}
                    pattern={easeCurve}
                    onClick={this.SelectToken.bind(this, easeCurve.id)}
                    selected={easeCurve.id === this.GetSelectedTokenId()}
                    onPreview={{
                        onMouseEnter : this.PreviewToken.bind(this, true, easeCurve.id, styleName),
                        onMouseLeave : this.PreviewToken.bind(this, false, easeCurve.id, styleName)
                    }}
                />
            )
        });

        content = (
            <SC.FCol>
                {easeCurves}
            </SC.FCol>
        )

        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {content}
            </SC.CustomScrollbars>                             
        )       
    }
    renderTokenEditor() {
        return (
            <EaeseCurveEditor 
                noHeader
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                GlobalState={Globals.ProjectManager.GlobalState}                      
                offline={this.EditingToken.IsNew}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                 
        )        
    }
    GetSelectedTokenId() {
        return this.props.easeId;
    }
    renderSelectedToken() {
        if (this.GetSelectedTokenId()) {
            return this.renderSelectedTokenHeader();
        }
    }
}
