import React from 'react';
import ReactDOM from 'react-dom';

import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../importer';
import styled from 'styled-components';
import { OutsideCloseDotMenuContainer } from '../../../views/project/panels/left/common';
import { AccountMenuItem } from '../../../views/header/account';

import Icon_Demo from '../../../components/illustrations/content';
import { PopupArrow } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { GetDataApi } from '../../../appstate/AppState';
import TeamInfoButton from '../../../views/header/teambutton';


export class FigmaTeamMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    
    render() { 
        return (  
            <React.Fragment>
                <TeamInfoButton 
                    activeUserCount={this.props.activeUserCount}
                    light
                    onClick={this.props.onToggle}
                />
                {
                    this.props.open && 
                    <SC.AbsoluteOverlay style={{
                        position : 'fixed', top : this.props.top || '41px', right : '4px', left : '4px',
                        paddingTop : 0, maxHeight : 'calc(100% - 45px)', height : '100%',
                        zIndex : 99999, cursor : 'default',
                        backgroundColor : '#2c2c2c'
                    }}>
                        <SC.CustomScrollbars>                   
                            <PopupArrow color='#2c2c2c' right='-2px' left='unset' arrow='9px' />
                            <AccountMenuItem onClick={this.props.onToggle} style={{paddingTop : '12px', paddingBottom : '6px'}}>
                                <SC.FRow alc style={{marginLeft : '-8px'}}>
                                    <SC.Icons.Icon_Button hasFill fill='#fff' hoverFill='#fff' style={{marginRight : '4px', marginBottom : '-2px'}}>
                                        <SC.Icons.Arrow_Back />
                                    </SC.Icons.Icon_Button>
                                    Back to Projects    
                                </SC.FRow>                        
                            </AccountMenuItem>
                        </SC.CustomScrollbars>     
                    </SC.AbsoluteOverlay>
                }
            </React.Fragment>        
        );
    }
}
