import React, { Component } from 'react';
// import * as AppStateExports  from './appstate';
import AppState from './appstate/AppState';
import Events from './appstate/Events';
import AppLayout from './appstate/AppLayout';
import Strings from './appstate/Strings';
import Links from './appstate/Links';
import Globals from './appstate/Globals';

import ReactBaseComponent from './ReactBase';
import ReactBasicComponent from './ReactBasicComponent';
import ReactPureComponent from './ReactPureBase';
import {Utils} from './toolabs-importer'
import {MetaData} from './toolabs-importer'
import {UIUtils} from './toolabs-importer'
import * as SC from './styled';

const Loading = () => {
    return (
        <SC.Div_Flex_Cell style={{position : 'fixed', top : '50px', left : 0, right : 0, bottom : 0}}>
            <SC.Loading_Icon justIcon/>
        </SC.Div_Flex_Cell>
    );
}

export {
    ReactBaseComponent,
    ReactBasicComponent,
    ReactPureComponent,
    Utils,
    UIUtils,
    MetaData,
    SC,
    AppState,
    Events,
    Links,
    AppLayout,
    Strings,
    Loading,
    Globals
}