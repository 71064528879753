import {
    AppState,
    Events,    
    Utils,
    Globals,
    MetaData,
    SC
} from '../../../importer';
import chroma from 'chroma-js';
import {FontLoader} from '../../../toolabs-importer';
import Strings from '../../../appstate/Strings';
import { publish_Token_Transform } from '../../../../toolabs-modules/toolabs-publish/theme';
import { TEAM_MESSAGE_TYPES } from './sessionmanager';
import { FormatRGB } from '../../../components/editors/color';

const GetData = () => {
    return Utils.Get(Globals.ProjectManager.Data, {}, 'Data');
}

export const TokenTypes = {
    COLOR : 'colors',
    Icons: 'Icons',
    IconSize : 'IconSize',
    IconColor : 'IconColor',
    Images: 'Images',
    Fonts: 'Fonts',
    Gradients: 'Gradients',
    Shadows: 'Shadows',
    Motion: 'Motion',
    Transforms : 'Transforms',
    Transitions : 'Transitions',
    Filters : 'Filters',
    Spacings : 'spacings',
    Sounds : 'Sounds',
    Contents: 'Contents',
    ContentTexts: 'Contents',
    MockupContent : 'MockupContent',
    Description: 'Descriptions',
    Borders : 'Borders',
    BorderRadiuses : 'BorderRadiuses',
    TextPatterns : 'TextPatterns',
    SpacePatterns : 'SpacePatterns',
    TimePatterns : 'TimePatterns',
    Booleans : 'Bool',
    FigmaAutoLayouts : 'FigmaAutoLayout',
    CustomTokenTypes : 'CustomTokenTypes'
};

const TokensManager =  {
    Types : TokenTypes,          
    Order(type) {
        return Utils.JustGet(GetData(), [], 'tokens', type, 'order');
    },
    List() {
        return Utils.JustGet(GetData(), {}, 'tokens', 'list');
    },
    Token(id) {        
        return Utils.JustGet(GetData(), null, 'tokens', 'list', id);
    },
    AliaseTokenId(id) {
        return this.Aliases.GetStateTokenId({Id : id});
        const token = this.Token(id);
        if (token) {
            if (token.aliase) {
                const tokenId = Utils.JustGet(token, null, 'tokenId', Globals.ProjectManager.CurrentState, 'id')
                return this.AliaseTokenId(tokenId);
            }
            return id;
        }
    },
    GetGroups(type) {
        const groups = Utils.JustGet(GetData(), [], 'tokens', 'groups', type);        
        if (groups.length === 0) {
            groups.push({
                id : 'Default',
                name : 'Default',
                order : []
            });
            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', type);
        }
        return groups;
    },
    SetGroupOrder(type, groupid, order) {
        const groups = this.GetGroups(type);
        const index = Utils.FindIndex(groups, (item) => {return item.id === groupid});
        if (index > -1) {            
            
            if (order) {
                groups[index].order = order;
                order.map((tokenId) => {
                    Utils.ForEach(groups, (group, ) => {
                        if (group.id !== groupid) {
                            Utils.RemoveEquals(group.order, tokenId);
                        }
                    });
                })
            }            

            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', type);
        }        
    },
    GetDefaultGroupTokenIds(type, isAlise) {
        const groups = isAlise ? this.Aliases.GetGroups(type) : this.GetGroups(type);
        const tokenids = isAlise ? this.Aliases.Order(type) : this.Order(type);
        const tokenGroupMap = {};
        const defaultTokenIds = [];
        Utils.ForEach(groups, (group, i) => {
            if (group && group.id !== 'Default') {              
                Utils.ForEach(group.order, (tokenId, c) => {
                    if (tokenId) {
                        tokenGroupMap[tokenId] = group.id;
                    }                    
                });                
            }            
        });    
        Utils.ForEach(tokenids, (tokenId, ) => {
            if (!tokenGroupMap[tokenId])
                defaultTokenIds.push(tokenId);
        });
        return defaultTokenIds;
    },
    AddGroup({type, name}) {
        const id = Utils.Id();
        const groups = this.GetGroups(type);        
        groups.push({
            id : id,
            name : name
        });
        Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', type);
        return id;
    },
    AddTokenToGroup({type, groupid, tokenid}) {
        const groups = this.GetGroups(type);
        const index = Utils.FindIndex(groups, (item) => {return item.id === groupid});
        if (index > -1) {
            const order = Utils.Get(groups[index], [], 'order');
            order.push(tokenid);
            Globals.ProjectManager.DataManager.Set(order, 'tokens', 'groups', type, index, 'order');
        }
    },
    ChangeGroupName({type, id, name}) {
        const groups = this.GetGroups(type);
        const index = Utils.FindIndex(groups, (item) => {return item.id === id});
        if (index > -1) {
            groups[index].name = name;
            Globals.ProjectManager.DataManager.Set(name, 'tokens', 'groups', type, index, 'name');
        }
    },
    SetGroupProperty({id, type, name, value}) {
        const groups = this.GetGroups(type);
        const index = Utils.FindIndex(groups, (item) => {return item.id === id});
        if (index > -1) {
            groups[index][name] = value;
            Globals.ProjectManager.DataManager.Set(value, 'tokens', 'groups', type, index, name);
        }
    },
    DeleteGroup({type, id, deleteTokens, deleteUsedTokens, semanticCheck}) {
        return new Promise((resolve) => {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === id});
            if (index > -1) {
                const group = groups[index];
                Globals.ProjectManager.LogTokenChange({Desc : 'Delete Token Group' + group.name});            
                if (group && deleteTokens) {
                    const order = Utils.Get(group, [], 'order');
    
                    if (!deleteUsedTokens) {
                        let hasTokensUsed;
                        Utils.ForEach(order, (tokenId, ) => {
                            if (Globals.ProjectManager.RelationManager.HasRelation(tokenId)) {
                                hasTokensUsed = true;
                                return false;
                            }
                        });
        
                        if (hasTokensUsed) {
                            Events.Ask_YesNo('Some token(s) are being used and cannot be deleted. If you continue they will be no be deleted and will be moved to Default folder.', Events.GLOBAL.NOTIFICATION.TYPES.WARNING, "Continue Deleting?", "Delete!", SC.Icons.Icon_Delete_Warning, true).then((result) => {
                                if (result === Events.GLOBAL.NOTIFICATION.RESULT.NO) {
                                    return false;
                                    resolve(false);
                                }
                                this.DeleteGroup({type : type, id : id, deleteTokens : deleteTokens, deleteUsedTokens : true}).then((result) => {
                                    resolve(result);
                                })
                            });
                            return;
                        }
        
                    }
                    
                    let deleteSemanticsAsWell;
                    let doNotDeleteBoundToSemantics;
                    if (semanticCheck) {
                        deleteSemanticsAsWell = semanticCheck === Events.GLOBAL.NOTIFICATION.RESULT.YES;
                        doNotDeleteBoundToSemantics = semanticCheck === Events.GLOBAL.NOTIFICATION.RESULT.NO;
                    }
                    else if (Utils.IsOneOf(type, this.Types.COLOR, this.Types.Borders, this.Types.BorderRadiuses)) {
                        let hasSemanticTokens;
                        Utils.ForEach(order, (tokenId, ) => {
                            const model = this.Token(tokenId); 
                            const tokenAliases = Utils.Get(model, [], 'aliases');
                            if (tokenAliases.length > 0) {
                                hasSemanticTokens = true;
                                return false;
                            }
                        });                
                        
                        if (hasSemanticTokens) {                        
                            Events.Ask_YesNoCancel('Some token(s) are linked by semantic tokens. Do you want the semantic tokens to be deleted as well?. If NO, these tokens will not be deleted.', Events.GLOBAL.NOTIFICATION.TYPES.WARNING, "Continue Deleting?", "Delete!", SC.Icons.Icon_Delete_Warning, true).then((result) => {
                                if (result === Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) {
                                    resolve(false);
                                    return false;
                                }
                                this.DeleteGroup({type : type, id : id, deleteTokens : deleteTokens, deleteUsedTokens : true, semanticCheck : result}).then((result) => {
                                    resolve(result);
                                })                        
                            });
                            return;
                        }
                    }
                    
                    Utils.ForEach(order, (tokenId, ) => {
                        this.DeleteToken({type : type, id : tokenId, doNotCheckGroups : true, doNotLog : true, silent : true, noRelationCheck : true, deleteSemanticsAsWell : deleteSemanticsAsWell, doNotDeleteBoundToSemantics : doNotDeleteBoundToSemantics});
                    });                
                }
                groups.splice(index, 1);
                Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', type);
                Globals.ProjectManager.DataManager.SetTokensChanged();
                resolve(true);
            }
        })        
    },
    ChangeOrderOfGroups(type, oldIndex, newIndex) {
        const groups = Globals.ProjectManager.Tokens.GetGroups(type);
        Utils.ChangePlace(groups, oldIndex, newIndex);
        Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', type);
    },
    ChangeGroupOrderOfToken(type, groupId, oldIndex, newIndex) {
        if (groupId) {
            const groups = Globals.ProjectManager.Tokens.GetGroups(type);
            const groupindex = Utils.FindIndex(groups, (item) => {return item.id === groupId});
            if (groupindex > -1) {
                const group = groups[groupindex];
                const order = Utils.Get(group, [], 'order');
                Utils.ChangePlace(order, oldIndex, newIndex);
                Globals.ProjectManager.DataManager.Set(order, 'tokens', 'groups', type, groupindex, 'order');
            }
        }
    },
    ChangeGroupOfToken(type, oldgroupid, newgroupid, tokenId, index) {
        const groups = Globals.ProjectManager.Tokens.GetGroups(type);
        const oldgroupindex = Utils.FindIndex(groups, (item) => {return item.id === oldgroupid});
        const oldorder = Utils.Get(groups[oldgroupindex], [], 'order');
        Utils.RemoveEquals(oldorder, tokenId);
        const newgroupindex = Utils.FindIndex(groups, (item) => {return item.id === newgroupid});
        const neworder = Utils.Get(groups[newgroupindex], [], 'order');
        neworder.splice(index, 0, tokenId);
        Globals.ProjectManager.DataManager.Set(oldorder, 'tokens', 'groups', type, oldgroupindex, 'order');
        Globals.ProjectManager.DataManager.Set(neworder, 'tokens', 'groups', type, newgroupindex, 'order');
    },
    // Tokens
    BindTokenToToken({SourceTokenId, ConsumerId, OldRelationId, TargetTokenId}) {
        if (OldRelationId)
            Globals.ProjectManager.RelationManager.DeleteRelationId(OldRelationId);
        if (TargetTokenId) {
            return Globals.ProjectManager.RelationManager.AddRelation(SourceTokenId, ConsumerId || SourceTokenId, TargetTokenId);
        }
    },
    AddCustomToken({typeId, name, value, index, id, doNotUpdateTheme, ...rest}) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Add Token'});
        const useId = id || Utils.Id();
        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.TOKEN_ADDED,
            id : useId      
        });

        let order = this.CustomTokenIds(typeId);
        if (!Array.isArray(order))
            order = [];
        if (order && order.indexOf(useId) < 0) {
            if (index >= 0)
                order.splice(index, 0, useId);
            else
                order.push(useId);
            Globals.ProjectManager.DataManager.Set(order, 'tokens', this.Types.CustomTokenTypes, typeId, 'order');
        }            
        
        const model = {
            name : name,
            type : this.Types.CustomTokenTypes,
            tokenType : typeId,
            value : {
                Default : {
                    value : value
                }
            },
            ...rest
        };
        Globals.ProjectManager.DataManager.Set(model, 'tokens', 'list', useId);
    //    !doNotUpdateTheme &&  this.UpdateCurrentTheme({
    //         id : useId,
    //         value : value,
    //         type : type,
    //         ...rest            
    //     })

        Globals.ProjectManager.DataManager.SetTokensChanged();
        return useId;
    },
    Add({type, name, value, id, index, doNotUpdateTheme,...rest}) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Add Token'});        
        const useId = id || Utils.Id();
        Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
            type : TEAM_MESSAGE_TYPES.TOKEN_ADDED,
            id : useId      
        });
        let order = this.Order(type);
        if (!Array.isArray(order))
            order = [];
        if (order && order.indexOf(useId) < 0) {
            if (index >= 0)
                order.splice(index, 0, useId);
            else
                order.push(useId);
            Globals.ProjectManager.DataManager.Set(order, 'tokens', type, 'order');
        }            
        
        const model = {
            name : name,
            type : type,
            value : {
                Default : {
                    value : value
                }
            },
            ...rest
        };
        Globals.ProjectManager.DataManager.Set(model, 'tokens', 'list', useId);
       !doNotUpdateTheme &&  this.UpdateCurrentTheme({
            id : useId,
            value : value,
            type : type,
            ...rest            
        })

        Globals.ProjectManager.DataManager.SetTokensChanged();
        return useId;
    },
    CloneCustomToken(id, typeId) {
        const source = this.Token(id);
        const clone = Utils.DeepClone(source);
        if (clone) {
            clone.name = 'Copy of ' + clone.name;
        
            const useId = Utils.Id();
            const order = this.CustomTokenIds(typeId);
            order.push(useId);                
            Globals.ProjectManager.DataManager.Set(order, 'tokens', this.Types.CustomTokenTypes, typeId, 'order');
            Globals.ProjectManager.DataManager.Set(clone, 'tokens', 'list', useId);
    
            // const groups = this.GetGroups(type === this.Types.Gradients ? this.Types.COLOR : type);
            // Utils.ForEach(groups, (group, groupindex) => {
            //     if (group.order && group.order.indexOf(id) >= 0)  {
            //         group.order.push(useId);
            //         Globals.ProjectManager.DataManager.Set(group.order, 'tokens', 'groups', type, groupindex, 'order');
            //     }
            // });
    
            Globals.ProjectManager.DataManager.SetTokensChanged();
            return useId;
        }        
    },
    Clone(id, type) {
        const source = this.Token(id);
        const clone = Utils.DeepClone(source);
        if (clone) {
            clone.name = 'Copy of ' + clone.name;
        
            const useId = Utils.Id();
            const order = this.Order(type);
            order.push(useId);                
            Globals.ProjectManager.DataManager.Set(order, 'tokens', type, 'order');        
            Globals.ProjectManager.DataManager.Set(clone, 'tokens', 'list', useId);
    
            const groups = this.GetGroups(type === this.Types.Gradients ? this.Types.COLOR : type);
            Utils.ForEach(groups, (group, groupindex) => {
                if (group.order && group.order.indexOf(id) >= 0)  {
                    group.order.push(useId);
                    Globals.ProjectManager.DataManager.Set(group.order, 'tokens', 'groups', type, groupindex, 'order');
                }
            });
    
            Globals.ProjectManager.DataManager.SetTokensChanged();
            return useId;
        }        
    },
    DeleteToken({type, id, doNotCheckGroups, doNotLog, silent, noRelationCheck, deleteSemanticsAsWell, doNotDeleteBoundToSemantics}) {
        if (!noRelationCheck && Globals.ProjectManager.RelationManager.HasRelation(id)) {
            Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_ITEM_HAS_LINKS), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
            return false;
        }                

        const model = this.Token(id);   
        if (model) {
            // Check if toke has aliases
            if (Utils.IsOneOf(type, this.Types.COLOR, this.Types.Borders, this.Types.BorderRadiuses, this.Types.Shadows)) {
                const tokenAliases = Utils.Get(model, [], 'aliases');
                if (tokenAliases.length > 0) {
                    if (doNotDeleteBoundToSemantics) {
                        return false;
                    }
                    if (deleteSemanticsAsWell) {
                        tokenAliases.map((tokenAliaseId) => {
                            Globals.ProjectManager.Tokens.Aliases.DeleteAliase({type : type, id : tokenAliaseId, doNotCheckGroups : true, doNotLog : true, silent : true});
                        })                        
                    }
                    else if (!noRelationCheck) {
                        Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_ITEM_HAS_LINKS), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                        return false;
                    }                    
                }
            }

            const order = this.Order(type);
            if (!doNotLog) {
                Globals.ProjectManager.LogTokenChange({Desc : 'Delete Token ' + model.name});
            }
            
            Utils.RemoveEquals(order, id);
            Globals.ProjectManager.DataManager.Set(order, 'tokens', type, 'order');
            Globals.ProjectManager.DataManager.Delete('tokens', 'list', id);
            Globals.ProjectManager.RelationManager.DeleteOwner(id);

            if (!doNotCheckGroups) {
                const groups = this.GetGroups(type === this.Types.Gradients ? this.Types.COLOR : type);
                Utils.ForEach(groups, (group, groupindex) => {
                    if (group.order && group.order.indexOf(id) >= 0)  {
                        Utils.RemoveEquals(group.order, id);
                        Globals.ProjectManager.DataManager.Set(group.order, 'tokens', 'groups', type, groupindex, 'order');
                    }
                });
            }            
            
            if (type === this.Types.Images && model) {
                Utils.ForEach(model.value, (statevalue, state) => {
                    if (statevalue && statevalue.value && statevalue.value.UrlId) {
                        Globals.ProjectManager.DataManager.Storage.Delete(statevalue.value.UrlId);                            
                    }
                });                    
            }
            else if (type === this.Types.Fonts && model) {
                Utils.ForEach(model.value, (statevalue, state) => {
                    if (statevalue && statevalue.value) {
                        if (statevalue.value.provider === Strings.CUSTOM && statevalue.value.fontId) {
                            Globals.ProjectManager.Tokens.DeleteCustomFont(statevalue.value.fontId);
                            Globals.ProjectManager.DataManager.Catched.Set(null, 'CustomFonts', statevalue.value.fontId);
                        }              
                    }
                });                    
            }
            else if (type === this.Types.COLOR && model) {
                if (model.mainId) {
                    const mainModel = this.Token(model.mainId);
                    if (mainModel) {
                        const tints = Utils.JustGet(mainModel.variants, [], 'tints', 'ids');
                        const shades = Utils.JustGet(mainModel.variants, [], 'shades', 'ids');
                        Utils.RemoveEquals(tints, id);
                        Utils.RemoveEquals(shades, id);
                        this.UpdateProp({id  :model.mainId, name : 'variants', value : mainModel.variants});
                    }                    
                }                        
            }
        }

        Globals.ProjectManager.TokenMetaData().Delete(id);

        if (!silent) {
            Globals.ProjectManager.DataManager.SetTokensChanged();
            Globals.ProjectManager.SessionManager && Globals.ProjectManager.SessionManager.SendChangeMessage({
                type : TEAM_MESSAGE_TYPES.TOKEN_DELETED,
                id : id      
            });
        }

        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);

        return true;
    },
    Delete(type, id) {
        return this.DeleteToken({type : type, id : id});        
    },
    ChangeOrder(type, oldIndex, newIndex) {
        const order = this.Order(type);
        Utils.ChangePlace(order, oldIndex, newIndex);
        Globals.ProjectManager.DataManager.Set(order, 'tokens', type, 'order');
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    ValueOf({model, defaultValue, name = 'value', state, statearray, info}) {
        let value;
        if (state) {
            value = Utils.JustGet(model, null, name, state, 'value');
            if (info && state !== 'Default' && Utils.IsNotNullOrEmpty(value)) {
                info.ValueState = {
                    state : state,
                    inherited : state !== Globals.ProjectManager.CurrentState
                }
            }
            return value;
        }
        const UseStateArray = statearray || Globals.ProjectManager.ReversedStyleState;

        if (model) {
            if (model.aliase) {
                let aliaseTokenId;
                Utils.ForEach(UseStateArray, (State, i) => {
                    let stateTokenId = Utils.JustGet(model, null, 'tokenId', State, 'id');
                    if (Utils.IsNotNullOrEmpty(stateTokenId)) {
                        aliaseTokenId = stateTokenId;
                        return false;
                    }
                });
                if (aliaseTokenId) {
                    return this.ValueOf({
                        model : this.Token(aliaseTokenId),
                        name : name, 
                        state : state, 
                        statearray : statearray
                    });
                }
                return null;                  
            }
    
            Utils.ForEach(UseStateArray, (State, i) => {
                let statevalue = Utils.JustGet(model, null, name, State, 'value');
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    if (info && State !== 'Default') {
                        info.ValueState = {
                            state : State,
                            inherited : State !== Globals.ProjectManager.CurrentState
                        }
                    }
                    return false;
                }
            });
        }
        
        return value;
    },
    ValueOfId(Id, StateArray) {
        return Globals.ProjectManager.Tokens.ValueOf({
            model : Globals.ProjectManager.Tokens.Token(Id),
            statearray : StateArray
        })
    },
    GetStateValue({Id, name = 'value', StateArray = Globals.ProjectManager.ReversedStyleState, info}) {
        if (!info) {
            const PreviewToken = Utils.JustGet(this, null, 'PreviewTokens', Id);
            if (PreviewToken) {
                return PreviewToken;
            }
        }        
        const model = this.Token(Id);
        if (model) {
            if (model.aliase) {
                let aliaseTokenId;
                Utils.ForEach(StateArray, (GlobalState, i) => {
                    let stateTokenId = Utils.JustGet(model, null, 'tokenId', GlobalState, 'id');
                    if (Utils.IsNotNullOrEmpty(stateTokenId)) {
                        aliaseTokenId = stateTokenId;
                        return false;
                    }
                });    
                if (aliaseTokenId) {
                    return this.GetStateValue({Id : aliaseTokenId, StateArray : StateArray, info : info});
                }
                return null;
            }
            let value;
            Utils.ForEach(StateArray, (GlobalState, i) => {
                let statevalue = Utils.JustGet(model, null, name, GlobalState, 'value');
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    if (info && GlobalState !== 'Default') {
                        info.ValueState = GlobalState;
                    }
                    value = statevalue;
                    return false;
                }
            });
            return value;
        }
    },
    GetParentStateValue({model, CurrentState, name, prop}) {
        if (model) {
            let value;
            const startIndex = Globals.ProjectManager.ReversedStyleState.indexOf(CurrentState)
            if (startIndex >= 0) {
                for (let i=startIndex + 1; i<Globals.ProjectManager.ReversedStyleState.length;  i++) {
                    let statevalue = Utils.JustGet(model, null, name, Globals.ProjectManager.ReversedStyleState[i], 'value');    
                    if (Utils.IsNotNullOrEmpty(statevalue)) {
                        value = statevalue;
                        break;
                    }
                }
            }
            return value;
        }
    },
    GetSpecisicStateValue({Id, name = 'value', state}) {
        const model = this.Token(Id);
        if (model) {
            if (model.aliase) {
                const tokenId = this.Aliases.GetStateTokenId({Id : Id});
                return this.GetSpecisicStateValue({Id : tokenId, name : name, state : state});
            }                
            return Utils.JustGet(model, null, name, state, 'value');
        }        
    },
    SetValue({id, value, name = 'value', offline, state, type, DoNotBroadcast, ...rest}) {
        const token = this.Token(id);
        let useTokenId = id;
        if (token && token.aliase) {
            useTokenId = this.AliaseTokenId(id);
        }
        if (offline) {
            Utils.Set(GetData(), value, 'tokens', 'list', useTokenId, name, state || Globals.ProjectManager.CurrentState, 'value' )
        } 
        else
            Globals.ProjectManager.DataManager.Set(value, 'tokens', 'list', useTokenId, name, state || Globals.ProjectManager.CurrentState, 'value');
        
        if (!DoNotBroadcast && name === 'value') {
            !offline && Globals.ProjectManager.DataManager.SetTokensChanged();
            this.UpdateCurrentTheme({id : useTokenId, value : value, type : type, ...rest});
        }        
    },
    UpdateCurrentTheme({id, value, type, ...rest}) {
        if (Globals.ProjectManager.CurrentTheme) {
            Utils.ForEach(Globals.ProjectManager.CurrentTheme.Theme, (Theme, State) => {
                if (type === this.Types.Borders) {
                    if (value) {
                        Utils.Set(Theme, {
                            borderStyle : value.style,
                            borderWidth : Utils.px(value.value, value.Unit)
                        }, id);
                    }                
                }
                else if (type === this.Types.BorderRadiuses) {
                    if (value) {
                        Utils.Set(Theme, Utils.px(value.value, value.Unit), id);
                    }                
                }
                else if (type === this.Types.Images) {
                    if (value) {
                        Utils.Set(Theme, value.url, id);
                    }                
                }
                else if (type === this.Types.Icons) {
                    if (value) {
                        Utils.Set(Theme, value, id);
                    }                
                }
                else if (type === this.Types.Shadows) {
                    if (value && rest) {
                        const shadow = Utils.GetShadowCss(value.values, rest.textShadow, (colorid) => {
                            return Globals.ProjectManager.Tokens.ValueOfId(colorid);
                        });
                        Utils.Set(Theme, shadow, id);
                    }                
                }
                else if (type === this.Types.Filters) {
                    const filters = Utils.JustGet(value, null, 'items');
                    if (filters) {
                        const filter = Utils.GetFilterCss(filters, MetaData.Properties.filter);
                        Utils.Set(Theme, filter, id);    
                    }                    
                }
                else if (type === this.Types.Transforms) {
                    const transform = publish_Token_Transform(value);
                    if (transform) {
                        Utils.Set(Theme, transform, id);
                    }
                }
                else if (type === this.Types.Motion) {                    
                    Utils.Set(Theme, Utils.ToArray(value, (item) => Number(item)), id);
                }
                else if (type === this.Types.Transitions) {
                    ['easeId', 'durationId', 'delayId', 'transformId'].map((prop) => {
                        if (rest[prop]) {
                            const value = Utils.JustGet(rest[prop], null, 'Default', 'value');
                            if (value) {
                                Utils.Set(Theme, value, id, prop);
                            }
                        }
                    })
                }
                else
                    Utils.Set(Theme, value, id);
            });            
        }
    },
    SetSubValue({id, value, name = 'value', prop}) {
        Globals.ProjectManager.DataManager.Set(value, 'tokens', 'list', id, name, Globals.ProjectManager.CurrentState, 'value', prop);
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    SetValueOf({model, value, name = 'value', state, subProps}) {
        const UseState = state || Globals.ProjectManager.CurrentState;
        const parentValue = this.GetParentStateValue({model : model, name : name, CurrentState : UseState});
        if (parentValue === value) {
            this.DeleteValueOf({model : model, name : name});
            return;
        }
        Utils.Set(model, value, name, UseState, 'value' );
    },
    SetPreviewTokenValue(PreviewTokenId, value) {
        Utils.Set(this, value, 'PreviewTokens', PreviewTokenId);
        Utils.Set(Globals.ProjectManager.CurrentTheme.Theme, value, PreviewTokenId);
    },
    DeletePreviewToken(PreviewTokenId) {
        Utils.UnSet(this, 'PreviewTokens', PreviewTokenId);
        Utils.UnSet(Globals.ProjectManager.CurrentTheme.Theme, PreviewTokenId);
    },
    DeleteValueOf({model, name = 'value', state}) {
        const UseState = state || Globals.ProjectManager.CurrentState;
        Utils.UnSet(model, name, UseState);
    },
    DeleteValue({id, name = 'value', state}) {
        const UseState = state || Globals.ProjectManager.CurrentState;
        Globals.ProjectManager.DataManager.Delete('tokens', 'list', id, name, UseState);
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    GetAliaseIdsOfTokenId(id) {
        return Utils.JustGet(GetData(), [], 'tokens', 'list', id, 'aliases');
    },
    ChangeTokenName(id, name) {
        this.UpdateProp({id : id, name : 'name', value : name});
        Events.BCE(Events.GLOBAL.TOKEN_NAME_CHANGED, [{id : id, name : name}]);
    },
    UpdateProp({id, name, value}) {
        Globals.ProjectManager.DataManager.Set(value, 'tokens', 'list', id, name);
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    DeleteProp({id, name}) {
        Globals.ProjectManager.DataManager.Delete('tokens', 'list', id, name);
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    SaveModel(type, id, model) {
        Globals.ProjectManager.DataManager.Set(model, 'tokens', 'list', id);
        Globals.ProjectManager.DataManager.SetTokensChanged();
    },
    SetName(id, name) {
        this.UpdateProp({id : id, name : 'name', value : name});
    },
    SetNote(id, note) {
        this.UpdateProp({id : id, name : 'note', value : note});
    },
    // Token Ids
    Icons() {
        return this.Order(this.Types.Icons);
    },
    IconSizes() {
        return this.Order(this.Types.IconSize);
    },
    IconColors() {
        return this.Order(this.Types.IconColor);
    },
    Images() {
        return this.Order(this.Types.Images);
    },
    Gradients() {
        return this.Order(this.Types.Gradients);
    },
    Shadows() {
        return this.Order(this.Types.Shadows);
    },
    Transforms() {
        return this.Order(this.Types.Transforms);
    },
    Filters() {
        return this.Order(this.Types.Filters);
    },
    Borders() {
        return this.Order(this.Types.Borders);
    },
    BorderRadiuses() {
        return this.Order(this.Types.BorderRadiuses);
    },
    Fonts() {
        return this.Order(this.Types.Fonts);
    },
    Motion() {
        return this.Order(this.Types.Motion);
    },
    Transitions() {
        return this.Order(this.Types.Transitions);
    },
    FigmaLayouts() {
        return this.Order(this.Types.FigmaAutoLayouts);
    },
    GetTransitionCurve(model) {
        const curve = this.ValueOf({model : model});

        let {x1, y1, x2, y2} = curve || {x1 : 0, y1 : 0, x2 : 1, y2 : 1};
        const s1={x : 17, y : 170};
        const s2={x : 185, y : 2};
        
        const c1x = x1 * (s2.x-s1.x) + s1.x;
        const c1y = y1 * (s2.y-s1.y) + s1.y;
        const c2x = x2 * (s2.x - s1.x) + s1.x;
        const c2y = -1 * (y2 * (s1.y - s2.y) - s1.y);
        return `M${s1.x},${s1.y} C${c1x},${c1y} ${c2x},${c2y} ${s2.x},${s2.y}`;
    },
    Spacings() {
        return this.Order(this.Types.Spacings);
    },
    Sounds() {
        return this.Order(this.Types.Sounds);
    },
    TextContents() {
        return this.Order(this.Types.ContentTexts);
    },
    Booleans() {
        return this.Order(this.Types.Booleans);
    },
    CustomTokenTypes() {
        return this.Order(this.Types.CustomTokenTypes);
    },
    CustomTokenIds(CustomTokenType) {
        return Utils.JustGet(GetData(), [], 'tokens', this.Types.CustomTokenTypes, CustomTokenType, 'order');
    },
    TokenList(type) {
        const ids = this.Order(type);
        const itemlist = {};
        Utils.ForEach(ids, (id) => {
            itemlist[id] = this.Token(id);
        });
        return itemlist;
    },
    CustomTokenList(CustomTokenType) {
        const ids = this.CustomTokenIds(CustomTokenType);
        const itemlist = {};
        Utils.ForEach(ids, (id) => {
            itemlist[id] = this.Token(id);
        });
        return itemlist;
    },
    // Token Model By Id
    Image(Id) {
        return this.Token(Id);
    },
    Gradient(Id) {
        return this.Token(Id);
    },
    Shadow(Id) {
        return this.Token(Id);
    },
    Transform(Id) {
        return this.Token(Id);
    },
    Filter(Id) {
        return this.Token(Id);
    },
    Icon(Id) {
        return this.Token(Id);
    },
    Sound(Id) {
        return this.Token(Id);
    },
    Font(Id) {
        let font = this.Token(Id);
        if (!font) {
            if (Id === 'DefaultFont') {
                this.Add({
                    id : Id,
                    type : this.Types.Fonts,
                    name : 'Primary Font',
                    value : this.DefaultFont()  
                });
                return this.Font(Id);
            }
            else if (Id === 'SecondaryFont') {
                this.Add({
                    id : Id,
                    type : this.Types.Fonts,
                    name : 'Secondary Font',
                    value : this.SecondaryFont()  
                });
                return this.Font(Id);
            }
            
        }
        return font;
    },
    FontValue(Id, StateArray) {
        const font = this.Font(Id);
        return this.ValueOf({model : font, statearray : StateArray});
    },
    DefaultFont() {
        let DefaultFontValue = this.ValueOfId('DefaultFont');
        if (!DefaultFontValue) {
            DefaultFontValue = DEFAULT_PRIMARY_FONT;
        }
        return DefaultFontValue;
    },
    SecondaryFont() {
        let SecondaryFontValue = this.ValueOfId('SecondaryFont');
        if (!SecondaryFontValue) {
            SecondaryFontValue = DEFAULT_SECONDARY_FONT;
        }
        return SecondaryFontValue;
    },
    DefaultFontStyle() {
        const DefaultFontValue = this.DefaultFont();
        if (DefaultFontValue && Utils.IsObject(DefaultFontValue)) {
            const style = {
                fontFamily : DefaultFontValue.family,
                fontWeight : DefaultFontValue.variant
            };
            return style;
        }
    },
    SecondaryFontStyle() {
        const SecondaryFontValue = this.SecondaryFont();
        if (SecondaryFontValue && Utils.IsObject(SecondaryFontValue)) {
            const style = {
                fontFamily : SecondaryFontValue.family,
                fontWeight : SecondaryFontValue.variant
            };
            return style;
        }
    },
    UploadCustomFont(CustomFont) {
        Globals.ProjectManager.DataManager.Catched.Set(CustomFont, 'CustomFonts', CustomFont.Id);
        FontLoader.LoadCustomFont(Utils.ToPascalCase(CustomFont.family) + Utils.UseNullOrEmpty(CustomFont.style, 'Regular'), CustomFont.base64);
    },
    DeleteCustomFont(CustomFontId) {
        Globals.ProjectManager.DataManager.Catched.Delete('CustomFonts', CustomFontId);
    },
    LoadCustomFont(Id, TargetDocument) {
        return new Promise((resolve) => {
            let CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, Id);
            if (!CustomFont) {
                Globals.ProjectManager.DataManager.Catched.Get('CustomFonts', Id).then((result) => {
                    if (result) {
                        CustomFont = result.value;
                        if (CustomFont) {
                            AppState.CatchedData.Set(CustomFont, AppState.CatchedData.CUSTOMFONTS, Id);
                            FontLoader.LoadCustomFont(Utils.ToPascalCase(CustomFont.family) + Utils.UseNullOrEmpty(CustomFont.style, 'Regular'), CustomFont.base64, TargetDocument);
                            resolve();
                        }
                    }
                })
            }
            else {
                FontLoader.LoadCustomFont(Utils.ToPascalCase(CustomFont.family) + Utils.UseNullOrEmpty(CustomFont.style, 'Regular'), CustomFont.base64, TargetDocument);
                resolve();
            }
        })
    },
    Get_FontFamily(Id, StateArray) {
        const UseFontId = Id || 'DefaultFont';        
        if (Id === 'DefaultFont')
            return this.DefaultFontStyle() || {};
        else if (Id === 'SecondaryFont')
            return this.SecondaryFontStyle() || {};

        return this.Get_FontFamilyOf(this.ValueOfId(UseFontId, StateArray));
    },
    Get_FontFamilyOf(MetaFont) {
        const fontStyle = {

        };
        if (MetaFont) {
            if (MetaFont.provider === Strings.FONT_GOOGLE) {
                fontStyle.fontFamily = MetaFont.family;
                if (MetaFont.variant) {
                    const variant = MetaFont.variant.toString();
                    const weight = variant.replace('italic', '');
                    if (weight && Utils.IsOneOf(weight, '100', '200', '300', '400', '500', '600', '700', '800', '900', 'bold', 'bolder', 'lighter', 'normal'))
                        fontStyle.fontWeight = weight;
                    if (variant.indexOf('italic') > -1)
                        fontStyle.fontStyle = 'italic';
                    else
                        fontStyle.fontStyle = 'normal';
                }
            }
            else if (MetaFont.provider === Strings.CUSTOM) {
                fontStyle.fontFamily = Utils.ToPascalCase(MetaFont.family) +  Utils.UseNullOrEmpty(MetaFont.style, 'Regular');
            }
            else {
                fontStyle.fontFamily = MetaFont.family;
                if (MetaFont.weight)
                    fontStyle.fontWeight = MetaFont.weight;
                else
                    fontStyle.fontWeight = 500;
            }
        }

        return fontStyle;
    },
    GetBodyFontStyle() {
        const style = {
            color : '#e4e4e4'
        };
        const DefaultFontValue = this.DefaultFont();
        if (DefaultFontValue) {
            style.fontFamily = DefaultFontValue.family;
            if (DefaultFontValue.weight)
                style.fontWeight = DefaultFontValue.weight;                                            
        }
        const TypeScale = this.Typescale.Get();
        if (TypeScale) {
            if (TypeScale.baseSize)
                style.fontSize = Utils.px(TypeScale.baseSize);
        }
        return style;
    },
    Colors : {
        Order() {
            return Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.COLOR);
        },
        List() {
            const ids = this.Order();
            const itemlist = {};
            Utils.ForEach(ids, (id) => {
                itemlist[id] = this.Get(id)
            });
            return itemlist;
        },
        Get(id) {
            return Globals.ProjectManager.Tokens.Token(id);
        },
        ConvertGradient({Id, ToGradient}) {
            const order_colors = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.COLOR);
            const order_gradients = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.Gradients);
            const token = Globals.ProjectManager.Tokens.Token(Id);

            if (ToGradient) {
                Utils.RemoveEquals(order_colors, Id);
                order_gradients.push(Id);

                const tints = Utils.JustGet(token, null, 'variants', 'tints', 'ids');
                const shades = Utils.JustGet(token, null, 'variants', 'shades', 'ids');
                if (tints) {
                    Utils.ForEach(tints, (tintid, ) => {
                        Globals.ProjectManager.Tokens.DeleteProp({id : tintid, name : 'mainId'});
                    });
                }
                if (shades) {
                    Utils.ForEach(shades, (shadeid, ) => {
                        Globals.ProjectManager.Tokens.DeleteProp({id : shadeid, name : 'mainId'});
                    });
                }
            }
            else {
                Utils.RemoveEquals(order_gradients, Id);
                order_colors.push(Id);
            }
            Globals.ProjectManager.DataManager.Set(order_colors, 'tokens', Globals.ProjectManager.Tokens.Types.COLOR, 'order');
            Globals.ProjectManager.DataManager.Set(order_gradients, 'tokens', Globals.ProjectManager.Tokens.Types.Gradients, 'order');            
            Globals.ProjectManager.DataManager.Set({
                name : token.name,
                type : ToGradient ? Globals.ProjectManager.Tokens.Types.Gradients : Globals.ProjectManager.Tokens.Types.COLOR,
                value : {
                    Default : {

                    }
                }
            }, 'tokens', 'list', Id);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        ChangeOrder(oldIndex, newIndex) {
            Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.COLOR, oldIndex, newIndex);
        },
        ChangeGroupOrder(groupId, oldIndex, newIndex) {
            if (groupId) {
                const groups = Globals.ProjectManager.Tokens.GetGroups(Globals.ProjectManager.Tokens.Types.COLOR);
                const groupindex = Utils.FindIndex(groups, (item) => {return item.id === groupId});
                if (groupindex > -1) {
                    const group = groups[groupindex];
                    const order = Utils.Get(group, [], 'order');
                    Utils.ChangePlace(order, oldIndex, newIndex);
                    Globals.ProjectManager.DataManager.Set(order, 'tokens', 'groups', Globals.ProjectManager.Tokens.Types.COLOR, groupindex, 'order');
                    Globals.ProjectManager.DataManager.SetTokensChanged();
                }
            }
        },
        ChangeOrderOfGroups(oldIndex, newIndex) {
            const groups = Globals.ProjectManager.Tokens.GetGroups(Globals.ProjectManager.Tokens.Types.COLOR);
            Utils.ChangePlace(groups, oldIndex, newIndex);
            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'groups', Globals.ProjectManager.Tokens.Types.COLOR);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        ChangeGroup(oldgroupid, newgroupid, colorid, index) {
            const groups = Globals.ProjectManager.Tokens.GetGroups(Globals.ProjectManager.Tokens.Types.COLOR);
            const oldgroupindex = Utils.FindIndex(groups, (item) => {return item.id === oldgroupid});
            const oldorder = Utils.Get(groups[oldgroupindex], [], 'order');
            Utils.RemoveEquals(oldorder, colorid);
            const newgroupindex = Utils.FindIndex(groups, (item) => {return item.id === newgroupid});
            const neworder = Utils.Get(groups[newgroupindex], [], 'order');
            neworder.splice(index, 0, colorid);
            Globals.ProjectManager.DataManager.Set(oldorder, 'tokens', 'groups', Globals.ProjectManager.Tokens.Types.COLOR, oldgroupindex, 'order');
            Globals.ProjectManager.DataManager.Set(neworder, 'tokens', 'groups', Globals.ProjectManager.Tokens.Types.COLOR, newgroupindex, 'order');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        Add({name, value, id, createVariants, groupId, ...rest}) {
            const usedid =  Globals.ProjectManager.Tokens.Add({type : Globals.ProjectManager.Tokens.Types.COLOR, name : name, id : id, value : value, ...rest});            

            if (createVariants) {
                this.AddVariants({mainId : usedid, groupId : groupId, name : name});
            }
            else if (rest.variants) {

            }

            if (groupId) {
                const groups = Globals.ProjectManager.Tokens.GetGroups(Globals.ProjectManager.Tokens.Types.COLOR);
                const groupindex = Utils.FindIndex(groups, (item) => {return item.id === groupId});
                if (groupindex > -1) {
                    const group = groups[groupindex];
                    const order = Utils.Get(group, [], 'order');                    
                    order.push(usedid);
                    if (createVariants) {
                        const mainIndex = order.indexOf(usedid);
                        const mainColor = Globals.ProjectManager.Tokens.Token(usedid);
                        const tints = Utils.Reverse(Utils.JustGet(mainColor, [], 'variants', 'tints', 'ids'));
                        const shades = Utils.JustGet(mainColor, [], 'variants', 'shades', 'ids');
                        order.splice(mainIndex, 0, ...tints);
                        order.push(...shades);
                    }                    

                    Globals.ProjectManager.DataManager.Set(order, 'tokens', 'groups', Globals.ProjectManager.Tokens.Types.COLOR, groupindex, 'order');
                }
            }
            
            return usedid;
        },
        AddVariants({mainId, groupId, name}) {
            const mainColor = Globals.ProjectManager.Tokens.ValueOfId(mainId);
            const variants = {                        
                tints : {
                    light : 60,
                    saturation : 10,
                    ids : [Utils.Id(), Utils.Id(), Utils.Id()]
                },
                shades : {
                    light : 60,
                    saturation : 10,
                    ids : [Utils.Id(), Utils.Id(), Utils.Id()]
                }
            };
            const order = this.Order(TokenTypes.COLOR);
            const mainIndex = order.indexOf(mainId);
            Utils.ForEach(variants.tints.ids, (tintid, i) => {                        
                this.Add({name : `${name} ${400 - i*100}`, value : mainColor, id : tintid, mainId : mainId, index : mainIndex, tint : {type : 'default'}});
            });
            Utils.ForEach(variants.shades.ids, (shadeid, i) => {
                this.Add({name : `${name} ${600 + i*100}`, value : mainColor, id : shadeid, mainId : mainId, shade : {type : 'default'}});
            });
            Globals.ProjectManager.Tokens.UpdateProp({id : mainId, name : 'variants', value : variants});
            this.CalculateVariants(variants.tints.ids, mainColor, variants.tints.light, variants.tints.saturation, false);
            this.CalculateVariants(variants.shades.ids, mainColor, variants.shades.light, variants.shades.saturation, true);
        },
        Delete(id) {
            const model = this.Get(id);
            const tints = Utils.JustGet(model, null, 'variants', 'tints');            
            const shades = Utils.JustGet(model, null, 'variants', 'shades');

            const result = Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.COLOR, id);
            if (result) {
                const detachVariantIds = [];
                if (tints && tints.ids) 
                    detachVariantIds.push(...tints.ids);
                if (shades && shades.ids) 
                    detachVariantIds.push(...shades.ids);

                detachVariantIds.map((variantId) => {
                    Globals.ProjectManager.Tokens.DeleteProp({id : variantId, name : 'mainId'});
                })
            }
            return result;
        },        
        UpdateProp({id, name, value}) {
            Globals.ProjectManager.Tokens.UpdateProp({type : Globals.ProjectManager.Tokens.Types.COLOR, id : id, name : name, value : value});
        },
        SetValue({id, value, name = 'value', state, offline, result}) {                    
            Globals.ProjectManager.Tokens.SetValue({type : Globals.ProjectManager.Tokens.Types.COLOR, id : id, name : name, value : value, offline : offline, state : state});
                                
            if (name === 'value') {
                const model = this.Get(id);
                this.UpdateVariants(model, result);
                if (result) {
                    if (model.aliase) {
                        const useTokenId = Globals.ProjectManager.Tokens.AliaseTokenId(id);
                        const token = Globals.ProjectManager.Tokens.Token(useTokenId);
                        if (token) {
                            const aliases = Utils.JustGet(token, [], 'aliases');
                            if (aliases.length > 0) {
                                const result_aliases = Utils.Get(result, [], 'aliases');                                
                                result_aliases.push(...aliases);                                
                                result_aliases.push(useTokenId);
                            }    
                        }
                    }
                    else {
                        const aliases = Utils.JustGet(model, [], 'aliases');
                        if (aliases.length > 0) {
                            const result_aliases = Utils.Get(result, [], 'aliases');
                            result_aliases.push(...aliases);
                        }
                    }                    
                }                
            }
        },
        UpdateVariants(model, result) {
            const value = Globals.ProjectManager.Tokens.ValueOf({model : model});
            const tints = Utils.JustGet(model, null, 'variants', 'tints');
            if (tints && tints.ids)
                this.CalculateVariants(tints.ids, value, tints.light, tints.saturation, false, result);
            
            const shades = Utils.JustGet(model, null, 'variants', 'shades');
            if (shades && shades.ids)
                this.CalculateVariants(shades.ids, value, shades.light, shades.saturation, true, result);
        },
        ValueOfId(id) {
            const model = this.Get(id);
            if (model)
                return Globals.ProjectManager.Tokens.ValueOf({model : model});
        },
        GetForegroundId(id) {
            const model = this.Get(id);
            if (model) {
                return Globals.ProjectManager.Tokens.ValueOf({model : model, name : 'foregroundTokenId'} );
            }
        },
        GetColorForegroundValue(Id) {
            const foregroundId = this.GetForegroundId(Id);
            if (foregroundId)
                return this.ValueOfId(foregroundId);
        },
        Save(id, model) {
            Globals.ProjectManager.Tokens.SaveModel(Globals.ProjectManager.Tokens.Types.COLOR, id, model);
        },
        DetachVariant(id, mainId) {
            const mainModel = Globals.ProjectManager.Tokens.Token(mainId);
            if (mainModel) {
                const tints = Utils.JustGet(mainModel.variants, [], 'tints', 'ids');
                const shades = Utils.JustGet(mainModel.variants, [], 'shades', 'ids');
                Utils.RemoveEquals(tints, id);
                Utils.RemoveEquals(shades, id);
                Globals.ProjectManager.Tokens.UpdateProp({id  : mainId, name : 'variants', value : mainModel.variants});
                Globals.ProjectManager.Tokens.DeleteProp({id : id, name : 'mainId'});
            } 
        },
        CalculateVariants(ids, mainColor, light, saturation, isShade, result) {
            if (ids.length > 0) {
                if (chroma.valid(mainColor)) {
                    const hsl = chroma(mainColor).hsl();
                    let main_saturation = hsl[1] * 10000;
                    let main_light = hsl[2] * 10000;
                    
                    let diff_saturation = saturation * (isShade ? main_saturation : 10000 - main_saturation) / (100 * ids.length);
                    let diff_light = light * (isShade ? main_light : 10000 - main_light) / (100 * ids.length);
    
                    Utils.ForEach(ids, (id, i) => {                
                        const use_saturation = (main_saturation + ((isShade ? -1 : 1) * (i + 1) * diff_saturation)) / 10000;
                        const use_light = (main_light + ((isShade ? -1 : 1) * (i + 1) * diff_light)) / 10000;
                        let variant = Utils.ToRGBA(FormatRGB(chroma.hsl(hsl[0], use_saturation, use_light).rgba()).rgb);   
                        this.SetValue({id : id, value : variant, result : result});
    
                        if (result) {
                            const changedItems = Utils.Get(result, [], 'items');
                            changedItems.push({
                                Id : id,
                                Type : Globals.ProjectManager.Tokens.Types.COLOR,
                                value : variant
                            })
                        }
                    });
                }                
            }                
        },    
        
    },
    TypePatterns : {
        GetList() {
            const order = this.Get();
            const patterns = [];
            Utils.ForEach(order, (patternId, ) => {
                patterns.push(Globals.ProjectManager.Tokens.Token(patternId));
            });
            return patterns;
        },
        Get() {
            const order = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TextPatterns);
            if (order.length === 0) {

                let oldmodel = Utils.Get(GetData(), null, 'tokens', 'TypePatterns');
                if (oldmodel) {
                    Utils.ForEach(oldmodel, (model, i) => {
                        Globals.ProjectManager.Tokens.Add({
                            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
                            name : model.name,
                            tag : model.tag,
                            id : model.id,
                            scaleIndex : model.scaleIndex,
                            fontId : model.fontId
                        });
                    });
                }
                else {
                    const defaultFont = {
                        Default : { value : 'DefaultFont'}
                    };
                    const models = [
                        {
                            tag : 'D1',                
                            name : 'Display 1',
                            scaleIndex : {Default : {value : 6}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'D2',                        
                            name : 'Display 2',
                            scaleIndex : {Default : {value : 5}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'H1',                        
                            name : 'H1',
                            scaleIndex : {Default : {value : 4}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'H2',                        
                            name : 'H2',
                            scaleIndex : {Default : {value : 3}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'H3',                        
                            name : 'H3',
                            scaleIndex : {Default : {value : 2}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'H4',                        
                            name : 'H4',
                            scaleIndex : {Default : {value : 1}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'H5',                        
                            name : 'H5',
                            scaleIndex : {Default : {value : 0}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'Body',                        
                            name : 'Body',
                            scaleIndex : {Default : {value : 0}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'Small',                        
                            name : 'Small',
                            scaleIndex : {Default : {value : -1}},
                            fontId : Utils.DeepClone(defaultFont)
                        },
                        {
                            tag : 'x-Small',                        
                            name : 'x-Small',
                            scaleIndex : {Default : {value : -2}},
                            fontId : Utils.DeepClone(defaultFont)
                        }
                    ];
    
                    Utils.ForEach(models, (model, i) => {
                        Globals.ProjectManager.Tokens.Add({
                            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
                            name : model.name,
                            tag : model.tag,
                            id : Utils.Id(),
                            scaleIndex : model.scaleIndex,
                            fontId : model.fontId
                        });
                    });
                }

                

                return Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TextPatterns);
            }            
            return order;
        },
        Set(model) {
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'TypePatterns');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        NewPattern(Id) {                            
            return {
                name : 'New Pattern',
                fontId : {
                    Default : {
                        value : 'DefaultFont'
                    }
                },
                scaleIndex : {
                    Default : {
                        value : 0
                    }
                }
            };
        },
        Clone(id) {
            const source = this.GetPattern(id);
            const clone = Utils.DeepClone(source);
            clone.id = Utils.Id();
            const patterns = this.Get();
            clone.name = 'Copy of ' + clone.name;
            patterns.push(clone);
            this.Set(patterns);
            return clone;
        },
        DeletePattern(Id) {
            return Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.TextPatterns, Id);
        },
        GetPattern(Id) {
            return Globals.ProjectManager.Tokens.Token(Id);
        },
        GetFontSize(pattern, scales, globalstate, stateArray) {
            return this.GetFontSizeAndInfo({pattern : pattern, globalstate : globalstate, stateArray : stateArray});
        },
        GetFontSizeAndInfo({pattern, globalstate, stateArray, info}) {
            const typeScale = Globals.ProjectManager.Tokens.Typescale.Get(globalstate, stateArray);

            let fontSize;

            const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize',  state : globalstate, statearray : stateArray, info : info});
            if (customSize && Utils.IsNotNullOrEmpty(customSize.value)) {
                fontSize = customSize.value;
            }
            else  {
                const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const factor = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const diff = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff',  state : stateArray ? null : globalstate, statearray : stateArray, info : info}), 0);                
                fontSize = Globals.ProjectManager.Tokens.GetScaleValue({
                    scaleIndex : scaleIndex,
                    factor : factor,
                    diff : diff,
                    baseSize : typeScale.baseSize,
                    ratio : typeScale.ratio
                });
            }                            
            const style = {
                fontSize : Utils.px(fontSize)
            };            
            const UseStateArray = stateArray || Globals.ProjectManager.ReversedStyleState;
         
            Utils.ForEach(UseStateArray, (State, i) => {                
                ['lineHeight', 'letterSpacing', 'wordSpacing'].map((prop) => {
                    if (!style[prop]) {
                        const value = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : prop,  state : State, info : info});                        
                        if (value) {
                            if (prop === 'lineHeight') {
                                if (value.value >= fontSize) {
                                    if (Utils.UseNullOrEmpty(value.Unit, 'px') === 'px')
                                        value.value = Utils.ToInteger(value.value);

                                    style[prop] = Utils.px(value.value, value.Unit);
                                }
                            }
                            else
                                style[prop] = Utils.px(value.value, value.Unit);                                
                        }
                    }                    
                })
            });
            
            if (!style.lineHeight && typeScale.lineHeightFactor) {
                const fontSize = Utils.parseSize(style.fontSize);
                style.lineHeight = Utils.px(CalculateLineHeight({fontSize : fontSize.value, fontSizeUnit : fontSize.unit, lineHeightFactor : typeScale.lineHeightFactor}), fontSize.unit);
            }
            if (!Utils.IsNotNullOrEmpty(style.letterSpacing) && Utils.IsNotNullOrEmpty(typeScale.letterSpaceFactor)) {
                style.letterSpacing = Utils.px(Math.max(-1, Math.min(2, typeScale.letterSpaceFactor)), 'em');
            }
            if (!Utils.IsNotNullOrEmpty(style.wordSpacing) && Utils.IsNotNullOrEmpty(typeScale.wordSpacingFactor)) {
                style.wordSpacing = Utils.px(Math.max(0, Math.min(2, typeScale.wordSpacingFactor)), 'em');
            }
            
            return style;
        },
        GetPatternFontId(pattern, globalstate, stateArray) {
            return Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'fontId', state : stateArray ? null : globalstate, statearray : stateArray});
        },
        GetPatternStyleFromId(Id) {
            const pattern = Globals.ProjectManager.Tokens.TypePatterns.GetPattern(Id);; 
            return this.GetPatternStyleAndInfo({pattern : pattern});
        },
        GetPatternStyle(pattern, scales, globalstate, stateArray) {
            return this.GetPatternStyleAndInfo({pattern : pattern, scales : scales, globalstate : globalstate, stateArray : stateArray});
        },
        GetPatternStyleAndInfo({pattern, scales, globalstate, stateArray, info}) {            
            let style = this.GetFontSizeAndInfo({pattern : pattern, scales : scales, globalstate : globalstate, stateArray : stateArray, info : info});   
            
            const fontId = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'fontId', state : stateArray ? null : globalstate, statearray : stateArray, info : info});
            style = Utils.Merge(style, Globals.ProjectManager.Tokens.Get_FontFamily(fontId, stateArray));
            
            return style;
        },
        GetFigmaStyle(pattern, DoNotConvertWeight) {
            const figmaStyle = {};
            let textStyle = this.GetFontSize(pattern);   
            figmaStyle.fontSize = Utils.ToNumber(Utils.parseSize(textStyle.fontSize).value);
            figmaStyle.lineHeight = Utils.ToNumber(Utils.parseSize(textStyle.lineHeight).value);
            const letterSpacing = Utils.parseSize(textStyle.letterSpacing);
            let ls = letterSpacing.value;
            
            if (letterSpacing.unit === 'em') {
                figmaStyle.letterSpacing = 100 * Utils.ToNumber(ls);
                figmaStyle.letterSpacingUnit = 'PERCENT';
            }
            else {
                figmaStyle.letterSpacing = ls;
                figmaStyle.letterSpacingUnit = 'PIXELS';
            }

            const convertWeightToFigmaStyle = (variant) => {
                let style = '';
                if (variant) {
                    let useVariant = variant.toString();
                    const weight = useVariant.replace('italic', '');
                
                    if (Utils.IsNotNullOrEmpty(weight)) {
                        if (weight === '700' || weight === 'bold') 
                            style = 'Bold';
                        else if (weight === '900' || weight === 'bolder') 
                            style = 'Black';
                        else if (weight === '800') 
                            style = 'Extra Bold';
                        else if (weight === '600') 
                            style = 'Semi Bold';
                        else if (weight === '500' || weight === 'bolder') 
                            style = 'Medium';
                        else if (weight === '300' || weight === 'lighter') 
                            style = 'Light';
                        else if (weight === '200') 
                            style = 'Extra Light';
                        else if (weight === '100') 
                            style = 'Thin';
                        else
                            style = 'Regular';
                    }
                    
    
                    if (useVariant.indexOf('italic') > -1) {
                        if (Utils.IsNotNullOrEmpty(style))
                            style += ' ';
                        style += 'Italic';
                    }
                        
                }
                return style;
            }

            const fontId = this.GetPatternFontId(pattern);
            const MetaFont = Globals.ProjectManager.Tokens.ValueOfId(fontId);
            if (MetaFont) {
                if (MetaFont.provider === Strings.FONT_GOOGLE) {
                    figmaStyle.fontName = {
                        family : MetaFont.family,
                        style : DoNotConvertWeight ? MetaFont.variant : convertWeightToFigmaStyle(MetaFont.variant)
                    }                                    
                    if (DoNotConvertWeight) {
                        if (MetaFont.variant === 'regular')
                            figmaStyle.fontName.style = 400;
                    }
                }
                else if (MetaFont.provider === Strings.CUSTOM) {
                    figmaStyle.fontName = {
                        family : MetaFont.family,
                        style : MetaFont.style
                    };
                    if (DoNotConvertWeight && Utils.IsNotNullOrEmpty(MetaFont.style)) {
                        figmaStyle.fontName.style = ConvertFontStyleToWeight(MetaFont.style);                        
                    }
                }
                else {
                    figmaStyle.fontName = {
                        family : MetaFont.family,
                        style : DoNotConvertWeight ? MetaFont.variant : convertWeightToFigmaStyle(MetaFont.weight)
                    }
                }
            }
            

            return figmaStyle;
        },
        GetPatternStyleOfState(pattern, scales, globalstate) {
            let style = this.GetFontSize(pattern, scales, globalstate);   
            const fontId = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'fontId', state : globalstate, statearray});
            style = Utils.Merge(style, Globals.ProjectManager.Tokens.Get_FontFamily(fontId));
            
            return style;
        },
        ApplyPatternStyle(style_pattern, stlye) {
            if (style_pattern) {
                if (style_pattern.fontFamily)
                    stlye.fontFamily = style_pattern.fontFamily;
                if (style_pattern.fontWeight)
                    stlye.fontWeight = style_pattern.fontWeight;
                if (style_pattern.fontSize)
                    stlye.fontSize = style_pattern.fontSize;
                if (style_pattern.lineHeight)
                    stlye.lineHeight = style_pattern.lineHeight;
                if (style_pattern.letterSpacing)
                    stlye.letterSpacing = style_pattern.letterSpacing;
                    if (style_pattern.wordSpacing)
                    stlye.wordSpacing = style_pattern.wordSpacing;
                if (style_pattern.fontStyle)
                    stlye.fontStyle = style_pattern.fontStyle;
            }
        }
    },
    Typescale : {
        Get(GlobalState, statearray) {
            let model = Utils.Get(GetData(), null, 'tokens', 'Typescale');                            
            if (!model || !model.Default) {
                model = {
                    Default : {
                        baseSize : 16,
                        scaleType : 'minorthird',
                        ratio : 1.2,
                        lineHeightFactor : 1.1,
                        letterSpaceFactor : 0.1,
                        wordSpacingFactor : 0.2
                    }
                };
                this.Set(model);
            }            
            const UseStateArray = statearray || Globals.ProjectManager.ReversedStyleState;
            let value;
            Utils.ForEach(UseStateArray, (State, i) => {
                let statevalue = Utils.JustGet(model, null, State);
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    return false;
                }
            });        
            return value;
        },        
        Set(model) {                    
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'Typescale');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        SetScale(definition) {                                                 
            Globals.ProjectManager.DataManager.Set(definition, 'tokens', 'Typescale', Globals.ProjectManager.CurrentState);                            
            Globals.ProjectManager.DataManager.SetTokensChanged();
        }
    },
    SpaceScale : {
        Get(GlobalState, statearray) {
            let model = Utils.Get(GetData(), null, 'tokens', 'Spacescale');                            
            if (!model || !model.Default) {
                model = {
                    Default : {
                        baseSize : 16,
                        scaleType : 'custom',
                        ratio : 2
                    }
                };
                this.Set(model);
            }            
            const UseStateArray = statearray || Globals.ProjectManager.ReversedStyleState;
            let value;
            Utils.ForEach(UseStateArray, (State, i) => {
                let statevalue = Utils.JustGet(model, null, State);
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    return false;
                }
            });        
            return value;
        },               
        Set(model) {                    
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'Spacescale');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        SetScale(definition) {                                                 
            Globals.ProjectManager.DataManager.Set(definition, 'tokens', 'Spacescale', Globals.ProjectManager.CurrentState);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        }        
    },
    SpacePatterns : {
        Get() {
            const order = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.SpacePatterns);
            if (order.length === 0) {
                let oldmodel = Utils.Get(GetData(), null, 'tokens', 'SpacePatterns');
                if (oldmodel) {
                    Utils.ForEach(oldmodel, (model, i) => {
                        Globals.ProjectManager.Tokens.Add({
                            type : Globals.ProjectManager.Tokens.Types.SpacePatterns,
                            name : model.name,
                            id : model.id,
                            scaleIndex : model.scaleIndex
                        });
                    });
                }
                else {
                    const models = [     
                        {
                            tag : 'none',                        
                            name : 'none',
                            scaleIndex : {Default : {value :'Custom'}},
                            customSize : {
                                Default : {
                                    value : {
                                        value : 0,
                                        Unit : 'px'
                                    }
                                }
                            }
                        },               
                        {
                            tag : 'xx-small',                        
                            name : 'xx-small',
                            scaleIndex : {Default : {value : -3}}
                        },
                        {
                            tag : 'x-small',                        
                            name : 'x-small',
                            scaleIndex : {Default : {value : -2}}
                        },
                        {
                            tag : 'small',                        
                            name : 'small',
                            scaleIndex : {Default : {value : -1}}
                        },
                        {
                            tag : 'regular',                        
                            name : 'regular',
                            scaleIndex : {Default : {value : 0}}
                        },
                        {
                            tag : 'large',                        
                            name : 'large',
                            scaleIndex : {Default : {value : 1}}
                        },
                        {
                            tag : 'x-large',                        
                            name : 'x-large',
                            scaleIndex : {Default : {value : 2}}
                        },
                        {
                            tag : 'xx-large',                    
                            name : 'xx-large',
                            scaleIndex : {Default : {value : 3}}
                        },
                    ];

                    Utils.ForEach(models, (model, i) => {
                        Globals.ProjectManager.Tokens.Add({
                            type : Globals.ProjectManager.Tokens.Types.SpacePatterns,
                            name : model.name,
                            id : Utils.Id(),
                            scaleIndex : model.scaleIndex,
                            customSize : model.customSize
                        });
                    });
                }

                return Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.SpacePatterns);
            }            
            return order;
        },
        Set(model) {
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'SpacePatterns');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        NewPattern(Id) {                            
            return {
                name : 'New Space',
                scaleIndex : {Default : {value : 0}}
            };
        },
        DeletePattern(Id) {
            return Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.SpacePatterns, Id);
        },
        GetPattern(Id) {
            return Globals.ProjectManager.Tokens.Token(Id);
        },
        GetSpaceSize(pattern, scales, globalstate, stateArray) {
            return this.GetSpaceSizeAndInfo({pattern : pattern, scales : scales, globalstate : globalstate, stateArray : stateArray});
        },
        GetSpaceSizeAndInfo({pattern, scales, globalstate, stateArray, info}) {
            if (!pattern)
                return 0;
            if (pattern.aliase) {
                const aliasetokenId = Globals.ProjectManager.Tokens.Aliases.GetStateTokenIdOf({model : pattern, StateArray : stateArray});
                if (aliasetokenId) {
                    const aliasePattern = this.GetPattern(aliasetokenId);
                    if (aliasePattern) {
                        return this.GetSpaceSizeAndInfo({pattern : aliasePattern, scales : scales, globalstate : globalstate, stateArray : stateArray, info : info});
                    }
                }
                return 0;
            }
            let spacesize;
            const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize',  state : globalstate, statearray : stateArray, info : info});
            if (customSize && Utils.IsNotNullOrEmpty(customSize.value)) {
                spacesize = customSize.value;
            }
            else  {
                const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const factor = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const diff = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff',  state : stateArray ? null : globalstate, statearray : stateArray, info : info}), 0);
                const model = Globals.ProjectManager.Tokens.SpaceScale.Get(globalstate, stateArray);
                spacesize = Globals.ProjectManager.Tokens.GetScaleValue({
                    scaleIndex : scaleIndex,
                    factor : factor,
                    diff : diff,
                    baseSize : model.baseSize,
                    ratio : model.ratio
                });
            }                            
            return Number(spacesize);
        },        
        GetSpaceUnit(pattern, globalstate, stateArray) {
            return this.GetSpaceUnitAndInfo({pattern : pattern, globalstate : globalstate, stateArray : stateArray});
        },
        GetSpaceUnitAndInfo({pattern, globalstate, stateArray, info}) {
            const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize',  state : globalstate, statearray : stateArray, info : info});
            if (customSize && Utils.IsNotNullOrEmpty(customSize.value)) {
                return customSize.Unit;
            }
            return 'px';
        }
    },
    TimeScale : {
        Get(GlobalState, statearray) {
            let model = Utils.Get(GetData(), null, 'tokens', 'TimeScale');                            
            if (!model || !model.Default) {
                model = {
                    Default : {
                        baseSize : 200,
                        scaleType : 'majorthird',
                        ratio : 1.25
                    }
                };
                this.Set(model);
            }            
            const UseStateArray = statearray || Globals.ProjectManager.ReversedStyleState;
            let value;
            Utils.ForEach(UseStateArray, (State, i) => {
                let statevalue = Utils.JustGet(model, null, State);
                if (Utils.IsNotNullOrEmpty(statevalue)) {
                    value = statevalue;
                    return false;
                }
            });        
            return value;
        },               
        Set(model) {                    
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'TimeScale');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        SetScale(definition) {                                                 
            Globals.ProjectManager.DataManager.Set(definition, 'tokens', 'TimeScale', Globals.ProjectManager.CurrentState);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },        
    },
    TimePatterns : {
        Get() {
            const order = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TimePatterns);
            if (order.length === 0) {
                const models = [                    
                    {
                        tag : 'instant',
                        name : 'instant',
                        scaleIndex : {Default : {value :'Custom'}},
                        customSize : {
                            Default : {
                                value : {
                                    value : 0
                                }
                            }
                        }
                    },
                    {
                        tag : 'x-fast',
                        name : 'x-fast',
                        scaleIndex : {Default : {value : -2}}
                    },
                    {
                        tag : 'fast',
                        name : 'fast',
                        scaleIndex : {Default : {value : -1}}
                    },
                    {
                        tag : 'normal',
                        name : 'normal',
                        scaleIndex : {Default : {value : 0}}
                    },
                    {
                        tag : 'slow',
                        name : 'slow',
                        scaleIndex : {Default : {value : 1}}
                    },
                    {
                        tag : 'x-slow',
                        name : 'x-slow',
                        scaleIndex : {Default : {value : 2}}
                    }
                ];

                Utils.ForEach(models, (model, i) => {
                    Globals.ProjectManager.Tokens.Add({
                        type : Globals.ProjectManager.Tokens.Types.TimePatterns,
                        name : model.name,
                        id : Utils.Id(),
                        tag : model.tag,
                        scaleIndex : model.scaleIndex,
                        customSize : model.customSize
                    });
                });

                return Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TimePatterns);
            }            
            return order;
        },
        Set(model) {
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'TimePatterns');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        NewPattern(Id) {                            
            return {
                name : 'New Duration',
                scaleIndex : {Default : {value : 0}}
            };
        },        
        DeletePattern(Id) {
            return Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.TimePatterns, Id);
        },
        GetPattern(Id) {
            return Globals.ProjectManager.Tokens.Token(Id);
        },
        GetTimeSize(pattern, scales, globalstate, stateArray) {
            return this.GetTimeSizeAndInfo({pattern : pattern, scales : scales, globalstate : globalstate, stateArray : stateArray});
        },
        GetTimeSizeAndInfo({pattern, scales, globalstate, stateArray, info}) {
            let Timesize;
            const customSize = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'customSize',  state : globalstate, statearray : stateArray, info : info});
            if (customSize && Utils.IsNotNullOrEmpty(customSize.value)) {
                Timesize = customSize.value;
            }
            else  {
                const scaleIndex = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleIndex',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const factor = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleFactor',  state : stateArray ? null : globalstate, statearray : stateArray, info : info});
                const diff = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'scaleDiff',  state : stateArray ? null : globalstate, statearray : stateArray, info : info}), 0);
                const model = Globals.ProjectManager.Tokens.TimeScale.Get(globalstate, stateArray);
                Timesize = Globals.ProjectManager.Tokens.GetScaleValue({
                    scaleIndex : scaleIndex,
                    factor : factor,
                    diff : diff,
                    baseSize : model.baseSize,
                    ratio : model.ratio
                });
            }                            
            return Timesize;
        },        
    },
    Utils : {
        ScalePresets : [
            {id : 'custom', label : 'Custom', value : 1.25 },    
            {id : 'minorsecond', label : 'Minor Second', value : 1.067 },
            {id : 'majorsecond', label : 'Major Second', value : 1.125 },
            {id : 'minorthird', label : 'Minor Third', value : 1.20 },
            {id : 'majorthird', label : 'Major Third', value : 1.25 },
            {id : 'perfectfourth', label : 'Perfect Fourth', value : 1.333 },
            {id : 'augmentedfourth', label : 'Augmented Fourth', value : 1.414 },
            {id : 'perfectfifth', label : 'Perfect Fifth', value : 1.5 },
            {id : 'minorsixth', label : 'Minor Sixth', value : 1.6 },
            {id : 'golden', label : 'Golden Ratio', value : 1.618 }, 
            {id : 'majorsixth', label : 'Major Sixth', value : 1.667 },
            // {id : 'fibonacci', label : 'Fibonacci Sequence', value : 1 }
        ],
        predefinedScales : null,
        GetPredefinedTypeScales() {
            if (!this.predefinedScales) {
                this.predefinedScales = [];
                Utils.ForEach(this.ScalePresets, (preset, i) => {
                    if (preset.id !== 'custom') {
                        this.predefinedScales.push({
                            value : (preset.value - 1) * 1000,
                            id : preset.id,
                            label : preset.label
                        });
                    }
                }); 
            }
            return this.predefinedScales;    
        },
        CalculateScale(value) {
            let newScale = (1000 + value) / 1000;
            let index = 0;
            Utils.ForEach(this.ScalePresets, (preset, i) => {
                if (newScale < preset.value && preset.id !== 'custom') {
                    index = i;
                    return false;
                }
            });                
            let scalePreset = this.ScalePresets[index];
            if (index > 0) {
                if ((newScale - this.ScalePresets[index-1].value) < (scalePreset.value - newScale))
                    scalePreset = this.ScalePresets[index-1];
            }
            let id = scalePreset.id;
            let ratio = scalePreset.value;
            let diff = Math.abs(ratio - newScale);
            // console.log(diff);
            if (diff > 0.02) {
                id = 'custom';
                ratio = newScale;
            }
            return {
                id : id,
                ratio : ratio
            };
        }     
    },
    Aliases : {
        Order(type) {
            return Utils.JustGet(GetData(), [], 'tokens', 'aliases', type, 'order');
        },
        ChangeOrder(type, oldIndex, newIndex) {
            const order = this.Order(type);
            Utils.ChangePlace(order, oldIndex, newIndex);
            Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', type, 'order');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        List(type) {
            const ids = this.Order(type);
            const itemlist = {};
            Utils.ForEach(ids, (id) => {
                itemlist[id] = this.TokenAliase(id);
            });
            return itemlist;
        },
        TokenAliase(id) {        
            return Utils.JustGet(GetData(), null, 'tokens', 'list', id);
        },
        GetGroups(type) {
            const groups = Utils.JustGet(GetData(), [], 'tokens', 'aliases', 'groups', type);        
            if (groups.length === 0) {
                groups.push({
                    id : 'Default',
                    name : 'Default',
                    order : []
                });
                Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);
            }
            return groups;
        },
        AddGroup({type, name}) {
            const id = Utils.Id();
            const groups = this.GetGroups(type);        
            groups.push({
                id : id,
                name : name
            });
            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);
            Globals.ProjectManager.DataManager.SetTokensChanged();
            return id;
        },
        SetGroupOrder(type, groupid, order) {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === groupid});
            if (index > -1) {            
                
                if (order) {
                    groups[index].order = order;
                    order.map((tokenId) => {
                        Utils.ForEach(groups, (group, ) => {
                            if (group.id !== groupid) {
                                Utils.RemoveEquals(group.order, tokenId);
                            }
                        });
                    })
                }            
    
                Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);
            }        
        },
        AddTokenToGroup({type, groupid, tokenid}) {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === groupid});
            if (index > -1) {
                const order = Utils.Get(groups[index], [], 'order');
                order.push(tokenid);
                Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', 'groups', type, index, 'order');
                Globals.ProjectManager.DataManager.SetTokensChanged();
            }
        },
        ChangeGroupName({type, id, name}) {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === id});
            if (index > -1) {
                groups[index].name = name;
                Globals.ProjectManager.DataManager.Set(name, 'tokens', 'aliases', 'groups', type, index, 'name');
                Globals.ProjectManager.DataManager.SetTokensChanged();
            }
        },
        SetGroupProperty({id, type, name, value}) {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === id});
            if (index > -1) {
                groups[index][name] = value;
                Globals.ProjectManager.DataManager.Set(value, 'tokens', 'aliases', 'groups', type, index, name);
            }
        },
        DeleteGroup({type, id, deleteTokens}) {
            const groups = this.GetGroups(type);
            const index = Utils.FindIndex(groups, (item) => {return item.id === id});
            if (index > -1) {
                const group = groups[index];
                Globals.ProjectManager.LogTokenChange({Desc : 'Delete Aliase Group' + group.name});            
                if (group && deleteTokens) {
                    const order = Utils.Get(group, [], 'order');
                    Utils.ForEach(order, (tokenId, ) => {
                        this.DeleteAliase({type : type, id : tokenId, doNotCheckGroups : true, doNotLog : true, silent : true});
                    });                
                }
                groups.splice(index, 1);
                Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);
                Globals.ProjectManager.DataManager.SetTokensChanged();
            }
        },
        ChangeOrderOfGroups(type, oldIndex, newIndex) {
            const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(type);
            Utils.ChangePlace(groups, oldIndex, newIndex);
            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        ChangeGroupOrderOfToken(type, groupId, oldIndex, newIndex) {
            if (groupId) {
                const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(type);
                const groupindex = Utils.FindIndex(groups, (item) => {return item.id === groupId});
                if (groupindex > -1) {
                    const group = groups[groupindex];
                    const order = Utils.Get(group, [], 'order');
                    Utils.ChangePlace(order, oldIndex, newIndex);
                    Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', 'groups', type, groupindex, 'order');
                    Globals.ProjectManager.DataManager.SetTokensChanged();
                }
            }
        },
        ChangeGroupOfToken(type, oldgroupid, newgroupid, tokenId, index) {
            const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(type);
            const oldgroupindex = Utils.FindIndex(groups, (item) => {return item.id === oldgroupid});
            const oldorder = Utils.Get(groups[oldgroupindex], [], 'order');
            Utils.RemoveEquals(oldorder, tokenId);
            const newgroupindex = Utils.FindIndex(groups, (item) => {return item.id === newgroupid});
            const neworder = Utils.Get(groups[newgroupindex], [], 'order');
            neworder.splice(index, 0, tokenId);
            Globals.ProjectManager.DataManager.Set(oldorder, 'tokens', 'aliases', 'groups', type, oldgroupindex, 'order');
            Globals.ProjectManager.DataManager.Set(neworder, 'tokens', 'aliases', 'groups', type, newgroupindex, 'order');
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        CloneGroup(groupId, type) {
            const groups = Globals.ProjectManager.Tokens.Aliases.GetGroups(type);
            const groupindex = Utils.FindIndex(groups, (item) => {return item.id === groupId});
            const group = groups[groupindex];
            const clone = Utils.DeepClone(group);
            clone.name = 'Copy of ' + group.name;
            const order = Utils.Get(group, [], 'order');
            clone.order = [];
            Utils.ForEach(order, (aliaseId, i) => {
                clone.order.push(this.Clone(aliaseId, type));
            });
            clone.order.map((tokenId) => {
                Utils.RemoveEquals(order, tokenId);
            })
            clone.id = Utils.Id();
            groups.splice(groupindex + 1, 0, clone);;
            Globals.ProjectManager.DataManager.Set(groups, 'tokens', 'aliases', 'groups', type);            
        },
        AddAliase({type, id, name, tokenId, groupId, ...rest}) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Add Token'});
            const useId = id || Utils.Id();
            const order = this.Order(type);
            if (order.indexOf(useId) < 0) {
                order.push(useId);
                Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', type, 'order');
            }            
            
            const model = {
                name : name,
                aliase : true,               
                ...rest
            };
            Globals.ProjectManager.DataManager.Set(model, 'tokens', 'list', useId);

            this.SetTokenId({
                id : useId,
                tokenId : tokenId,
                state : 'Default'
            })
            Globals.ProjectManager.DataManager.SetTokensChanged();
            return tokenId;
        },
        Clone(id, type) {
            const source = this.TokenAliase(id);
            const clone = Utils.DeepClone(source);
            clone.name = 'Copy of ' + clone.name;
            
            const useId = Utils.Id();
            const order = this.Order(type);
            order.push(useId);                
            Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', type, 'order');
            Globals.ProjectManager.DataManager.Set(clone, 'tokens', 'list', useId);

            Utils.ForEach(source.tokenId, (tokenId, state) => {
                if (tokenId) {
                    const token = Globals.ProjectManager.Tokens.Token(tokenId.id);
                    const tokenAliases = Utils.Get(token, [], 'aliases');
                    tokenAliases.push(useId);
                    Globals.ProjectManager.DataManager.Set(tokenAliases, 'tokens', 'list', tokenId.id, 'aliases');
                }                    
            });   

            const groups = this.GetGroups(type === Globals.ProjectManager.Tokens.Types.Gradients ? Globals.ProjectManager.Tokens.Types.COLOR : type);
            Utils.ForEach(groups, (group, groupindex) => {
                if (group.order && group.order.indexOf(id) >= 0)  {
                    group.order.push(useId);
                    Globals.ProjectManager.DataManager.Set(group.order, 'tokens', 'aliases', 'groups', type, groupindex, 'order');
                }
            });

            Globals.ProjectManager.DataManager.SetTokensChanged();
            return useId;
        },
        DeleteAliase({id, type, doNotCheckGroups, doNotLog, silent}) {
            if (Globals.ProjectManager.RelationManager.HasRelation(id)) {
                Events.AlertSimple(Strings.Error_Delete(Strings.MESSAGE_ITEM_HAS_LINKS), Events.GLOBAL.NOTIFICATION.TYPES.WARNING);
                return false;
            }        
    
            const model = this.TokenAliase(id);   
            if (model) {
                const order = this.Order(type);
                if (!doNotLog)
                    Globals.ProjectManager.LogTokenChange({Desc : 'Delete Token Aliase' + model.name});
                Utils.RemoveEquals(order, id);
                Globals.ProjectManager.DataManager.Set(order, 'tokens', 'aliases', type, 'order');
                Globals.ProjectManager.DataManager.Delete('tokens', 'list', id);
                Globals.ProjectManager.RelationManager.DeleteOwner(id);
    
                Utils.ForEach(model.tokenId, (tokenId, state) => {
                    if (tokenId) {
                        const token = Globals.ProjectManager.Tokens.Token(tokenId.id);
                        const tokenAliases = Utils.Get(token, [], 'aliases');
                        if (tokenAliases.indexOf(id) > -1) {
                            Utils.RemoveEquals(tokenAliases, id);
                            Globals.ProjectManager.DataManager.Set(tokenAliases, 'tokens', 'list', tokenId.id, 'aliases');
                        }
                    }                    
                });                                

                if (!doNotCheckGroups) {
                    const groups = this.GetGroups(type === Globals.ProjectManager.Tokens.Types.Gradients ? Globals.ProjectManager.Tokens.Types.COLOR : type);
                    Utils.ForEach(groups, (group, groupindex) => {
                        if (group.order && group.order.indexOf(id) >= 0)  {
                            Utils.RemoveEquals(group.order, id);
                            Globals.ProjectManager.DataManager.Set(group.order, 'tokens', 'aliases', 'groups', type, groupindex, 'order');
                        }
                    });                                
                }                
            }
            
    
            Globals.ProjectManager.DataManager.SetTokensChanged();

            return true;
        },
        Delete({id, type}) {
            return this.DeleteAliase({id : id, type : type});
        },
        SetTokenId({id, tokenId, state}) {
            const aliase = this.TokenAliase(id);            
            const oldTokenId = Utils.JustGet(aliase, null, 'tokenId', state || Globals.ProjectManager.CurrentState, 'id');
            Globals.ProjectManager.DataManager.Set(tokenId, 'tokens', 'list', id, 'tokenId', state || Globals.ProjectManager.CurrentState, 'id');
            if (oldTokenId !== tokenId) {
                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                const tokenAliases = Utils.Get(token, [], 'aliases');
                if (tokenAliases.indexOf(id) < 0) {
                    tokenAliases.push(id);
                    Globals.ProjectManager.DataManager.Set(tokenAliases, 'tokens', 'list', tokenId, 'aliases');
                }                
                if (oldTokenId) {
                    const oldToken = Globals.ProjectManager.Tokens.Token(oldTokenId);
                    if (oldToken) {
                        const oldTokenAliases = Utils.Get(oldToken, [], 'aliases');
                        const index = oldTokenAliases.indexOf(id);
                        if (index >= 0) {
                            oldTokenAliases.splice(index, 1);
                            Globals.ProjectManager.DataManager.Set(oldTokenAliases, 'tokens', 'list', oldTokenId, 'aliases');
                        }       
                    }
                }
            }    
            Globals.ProjectManager.DataManager.SetTokensChanged();        
        },
        ChangeAliaseName(id, name) {
            this.UpdateProp({id : id, name : 'name', value : name});
            Events.BCE(Events.GLOBAL.TOKEN_NAME_CHANGED, [{id : id, name : name}]);
        },
        UpdateProp({id, name, value}) {
            Globals.ProjectManager.DataManager.Set(value, 'tokens', 'list', id, name);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        DeleteProp({id, name}) {
            Globals.ProjectManager.DataManager.Delete('tokens', 'list', id, name);
            Globals.ProjectManager.DataManager.SetTokensChanged();
        },
        GetStateTokenId({Id, StateArray = Globals.ProjectManager.ReversedStyleState}) {
            const PreviewToken = Utils.JustGet(this, null, 'PreviewTokens', Id);
            if (PreviewToken) {
                return PreviewToken;
            }
            const model = this.TokenAliase(Id);
            return this.GetStateTokenIdOf({model : model, StateArray : StateArray});
        },
        GetStateTokenIdOf({model, StateArray = Globals.ProjectManager.ReversedStyleState, info}) {            
            if (model) {
                let tokenId;
                Utils.ForEach(StateArray, (GlobalState, i) => {
                    let statevalue = Utils.JustGet(model, null, 'tokenId', GlobalState, 'id');
                    if (Utils.IsNotNullOrEmpty(statevalue)) {
                        tokenId = statevalue;
                        if (info && GlobalState !== 'Default') {
                            info.ValueState = GlobalState;
                        }
                        return false;
                    }
                });
                return tokenId;
            }
        },
    },
    GetScaleValue({scaleIndex, factor, diff, baseSize, ratio}) {
        if (Utils.IsNotNullOrEmpty(factor)) {
            return Math.round(baseSize * factor + (Utils.UseNullOrEmpty(diff, 0)));
        }
        return Math.round(baseSize * Math.pow(ratio, scaleIndex) + (Utils.UseNullOrEmpty(diff, 0)));
    },
};

export const CalculateScales = (model, ratio) => {
    const useRatio = Utils.UseNullOrEmpty(ratio, model.ratio || 1.25);
    model.ratio = useRatio;

    model.scales = {};
    let val_em = 1;
    let val_px = model.baseSize ;
    for (let j=0; j < model.downScaleCount; j++) {
        val_em = Number.parseFloat((val_em / useRatio).toFixed(3));
        val_px = Utils.ToInteger(val_px / useRatio);
        model.scales[0 - j - 1] = {
            em : val_em,
            px : val_px
        };
    }
    val_em = 1;
    val_px = model.baseSize ;


    model.scales[0] = {
        em : val_em,
        px : val_px
    };
    for (let i=0; i < model.upScaleCount; i++ ) {
        val_em = val_em * useRatio;
        val_px = val_px * useRatio;
        model.scales[i+1] = {
            em : Number.parseFloat(val_em.toFixed(3)),
            px : Utils.ToInteger(val_px)
        };
    };        
}

export const CalculateScaleForValue = ({baseValue, ratio, targetValue, result}) => {
    if (result.scale < -20 || result.scale > 20) 
        return result;
    let value = Math.round(baseValue * Math.pow(ratio, result.scale));
    const offset = Math.abs(value - targetValue);
    let scaleDiff = value > targetValue ? -1 : 1;
    let nextValue = Math.round(baseValue * Math.pow(ratio, result.scale + scaleDiff));
    const nextOffset = Math.abs(nextValue - targetValue);

    if (targetValue < baseValue) {        
        if ((nextValue - targetValue)  > 0) {
            result.scale += scaleDiff;
            return CalculateScaleForValue({
                baseValue: baseValue,
                ratio: ratio,
                targetValue: targetValue,
                result: result,
            });
        } else {
            result.diff = nextOffset;
            result.scale += scaleDiff;
            return result;
        }
    }
    else {
        if (nextOffset < offset && nextValue - targetValue <= 0) {
            result.scale += scaleDiff;
            return CalculateScaleForValue({
                baseValue: baseValue,
                ratio: ratio,
                targetValue: targetValue,
                result: result,
            });
        } else {
            result.diff = offset;
            return result;
        }
    }
    
}

export const CalculateLineHeight = ({fontSize, fontSizeUnit = 'px', lineHeightFactor}) => {
    return Utils.ToInteger(fontSize * Math.max(1, lineHeightFactor) + (fontSizeUnit === 'px' ? 4 : 0));
}

window.CalculateScaleForValue = CalculateScaleForValue;

export default TokensManager;

export const DEFAULT_PRIMARY_FONT = {
    family : 'Roboto',
    category : 'sans-serif',
    id : 'Roboto',
    name : 'Roboto',
    provider : 'Google Fonts',
    url : 'https://fonts.googleapis.com/css?family=Roboto:regular',
    variant : '400'
};

export const DEFAULT_SECONDARY_FONT = {
    family : 'Arial',
    id : 'Arial',
    name : 'Arial',
    provider : 'Websafe Fonts',
    weight : 700
};


export const ConvertFontStyleToWeight = (weightStyle) => {
    const style = Utils.UseNullOrEmpty(weightStyle, '').toUpperCase();
    let weight;
    if (style === 'BOLD') 
        weight = "700";                            
    else if (style === 'BLACK') 
        weight = "900";
    else if (style === 'EXTRA BOLD') 
        weight = "800";
    else if (style === 'SEMI BOLD') 
        weight = "600";
    else if (style === 'MEDIUM') 
        weight = "500";
    else if (style === 'LIGHT') 
        weight = "300";
    else if (style === 'EXTRA LIGHT') 
        weight = "200";
    else if (style === 'THIN') 
        weight = "100";
    else
        weight = "400";
    return weight;
}