import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../../importer';

import FigmaDSToolbar from '../designsystem/toolbar';
import UnboundFigmaLocalStyles from '../designsystem/unboundstyles';

export default class FigmaProjectTokenStyleSyncer extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.RefToolbar = React.createRef();
    }
    renderCustom() {
        return (
            <React.Fragment>
                <SC.CustomScrollbars>
                    <SC.FCol fw fh style={{position : 'relative', marginTop : '40px'}}>
                        <UnboundFigmaLocalStyles 
                            {...this.props}
                            RefToolbar={this.RefToolbar}
                            loading
                        />                    
                    </SC.FCol>                
                </SC.CustomScrollbars>
                <FigmaDSToolbar 
                    ref={this.RefToolbar} 
                />
            </React.Fragment>            
        )
    }
}