import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Events,
    Strings,
    Globals,
    AppLayout
} from '../../../../../../../importer';

import { TokenItemBox, GroupTitle, TokenPanelHeader} from '../../common';
import PatternEditor from './item';
import { LeftScrollPanel } from '../../../common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { StatefulTokenMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';

export default class SpacePatterns extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Ref_PatternEdit = React.createRef();

        this.AddPattern = this.AddPattern.bind(this);
        this.onEditPattern = this.onEditPattern.bind(this);        
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onClonePattern = this.onClonePattern.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);
        this.PreviewPattern = this.PreviewPattern.bind(this);

        this.state.isListView = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Spacing', 'ListView');        
        this.onSortPattern = this.onSortPattern.bind(this);

        AppLayout.Refs.DesignSystem.Spacing = this;

        this.Load();
    }        
    Load() {
        const patterns = Globals.ProjectManager.Tokens.SpacePatterns.Get();
        this.patternItems = [];
        Utils.ForEach(patterns, (patternId, i) => {
            const patternItem = GetSpacePatternItem(patternId);
            if (patternItem && patternItem.pattern) {
                this.patternItems.push(patternItem)         
            }            
        });

        this.patternItems = Utils.Sort(this.patternItems, (item) => {return item.spaceValue});
    }
    Reload() {
        this.Load();
        this.RCUpdate();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.spacingId !== nextProps.spacingId || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load();
        }
        else if (this.props.baseSize !== nextProps.baseSize) {
            Utils.ForEach(this.patternItems, (pattern, i) => {
                pattern.spaceValue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(pattern.pattern);
            });
            return true;
        }
        return true;
    }
    onSortPattern(oldIndex, newIndex) {
        const patterns = Globals.ProjectManager.Tokens.SpacePatterns.Get();
        Utils.ChangePlace(patterns, oldIndex, newIndex);
        Globals.ProjectManager.Tokens.SpacePatterns.Set(patterns);
        Utils.ChangePlace(this.patternItems, oldIndex, newIndex);
        this.RCUpdate();
    }    
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'DesignSystem', 'Spacing', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    AddPattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternId = Utils.Id();        
        this.EditPatternModel = Globals.ProjectManager.Tokens.SpacePatterns.NewPattern(this.EditPatternId);
        Globals.ProjectManager.LogTokenChange({Desc : 'Add Space Pattern'});
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.SpacePatterns,
            ...this.EditPatternModel,
            id : this.EditPatternId
        });
        this.onEditPattern(this.EditPatternId);
    }
    onCancelAddPattern() {
        if (this.IsNew) {
            this.onDeletePattern();
        }
        else
            this.onCloseEditor();
        
    }
    onCloseEditor() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            this.Load();
        }        
        else {
            const patternitem = Utils.Find(this.patternItems, (item) => {return item.id === this.EditPatternId});
            if (patternitem) {
                patternitem.spaceValue =  Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(patternitem.pattern);
            }
        }
            
        this.onCloseEditor();

        Events.BroadcastThrottle_400(Events.GLOBAL.TOKEN_VALUE_CHANGING);
    }
    onDeletePattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        const result = Globals.ProjectManager.Tokens.SpacePatterns.DeletePattern(this.EditPatternId);
        if (result) {
            this.Load();            
        }
        this.onCloseEditor();
    }
    onClonePattern() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditPatternId, Globals.ProjectManager.Tokens.Types.SpacePatterns);
        this.Load();
        this.onEditPattern(cloneId);
        this.props.onUpdate();
    }
    SaveEditingPattern() {
        if (this.Ref_PatternEdit.current)
            this.Ref_PatternEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }

    onEditPattern(patternId, e) {
        if (this.props.onSelectPattern && !this.IsNew) {
            this.props.onSelectPattern(patternId, e);
            return;
        }
        this.EditPattern(patternId);
    }
    EditPattern(patternId) {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.EditPatternId = patternId;

        const pattern = Globals.ProjectManager.Tokens.Token(patternId);

        this.props.onPanelOverlay({
            show : true,
            render : () => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title='NEW PATTERN' 
                            hasAddCancel 
                            notBackClosable={this.IsNew}
                            onClose={this.onSubmitNewPattern} 
                            onCancel={this.onCancelAddPattern} 
                            onAdd={this.onSubmitNewPattern} 
                            onDelete={!this.IsNew && this.onDeletePattern}
                            onClone={!this.IsNew && this.onClonePattern}
                            onLight
                        />
                        <LeftScrollPanel>
                            <PatternEditor 
                                compact
                                editing
                                isNew={this.IsNew}
                                pattern={pattern}
                                baseSize={this.props.baseSize}
                                ratio={this.props.ratio}
                                id={patternId}
                                GlobalStateId={this.props.GlobalStateId}
                                onSave={this.SaveEditingPattern}
                                onSelectFont={this.SelectFont}
                                ref={this.Ref_PatternEdit}
                                onClose={this.onSubmitNewPattern} 
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    PreviewPattern() {

    }
    renderCustom() {

        return (
            <SpacePatternList 
                patterns={this.patternItems}
                baseSize={this.props.baseSize}
                onListView={this.SetListMode.bind(this, true)}
                onCardView={this.SetListMode.bind(this, false)}
                isListView={this.state.isListView}
                hasEditGrant={this.props.hasEditGrant}
                onAdd={this.AddPattern}
                onEdit={this.onEditPattern}
                onPreview={this.PreviewPattern}
                selectedId={this.props.selectedId}
                title='Patterns'
                notDraggable={this.props.notDraggable}
                onShowMenu={this.props.onShowMenu}
                filterText={this.props.filterText}
            />
        )

    }
}

export class SpacePatternList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            filter : this.props.filterText
        }

        this.SearchToken = this.SearchToken.bind(this);
    }
    onEditPattern(patternId, e) {
        this.props.onEdit(patternId, e);
    }
    PreviewPattern(show, id, pattern) {
        this.props.onPreview(show, id, pattern);
    }
    SearchToken(filter) {
        this.setState({filter : filter});        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return true;
    }
    onShowMenu(patternId, e) {
        this.props.onShowMenu && this.props.onShowMenu(patternId, e);
    }
    render() { 

        const spaceItems = [];
       
        let filterText;
        if (Utils.IsNotNullOrEmpty(this.state.filter))
            filterText = this.state.filter.toUpperCase(); 

        Utils.ForEach(this.props.patterns, ({id, pattern, spaceValue, spaceUnit, valueState}, i) => {

            let filtered = false;

            if (filterText) {
                if (pattern.name.toUpperCase().indexOf(filterText) < 0) {                    
                    filtered = true;
                }               
            }

            if (!filtered) {
                let DragItem = {
                    Type : Strings.TOOLBARITEM,
                    Item : {                            
                        MetaItem : {
                            Type : Strings.TOKENVALUE,
                            TokenType : AppState.ItemTypes.BOARD.PATTERN_TEXT,
                            Id : id,
                            Value : Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(pattern),
                            name : pattern.name
                        }
                    } 
                };
                
                const BoxProps = {
                    pattern : pattern,
                    spaceValue : spaceValue,
                    spaceUnit : spaceUnit,
                    valueState : valueState,
                    style : this.props.notDraggable ? {cursor : 'pointer'} : {cursor : 'move'},
                    onClick : this.onEditPattern.bind(this, id),
                    onContextMenu : this.onShowMenu.bind(this, id),
                    onMouseEnter : this.props.notDraggable ? this.PreviewPattern.bind(this, true, id, pattern) : null,
                    onMouseLeave : this.props.notDraggable ? this.PreviewPattern.bind(this, false, id, pattern) : null,
                    DragItem : DragItem,
                    selected  : this.props.selectedId === id,
                    listView : this.props.isListView
                };
    
                spaceItems.push(       
                    <DraggablePattern
                        key={id}
                        {...BoxProps}
                    />          
                )
            }            
        });

        return (
            <SC.FCol style={{marginTop : '8px', paddingBottom : '4px'}}>
                <GroupTitle 
                    title={this.props.title} 
                    subGroup
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.props.onAdd} : null} 
                    style={{paddingLeft : '12px'}}
                    hasSearch={{onSearch : this.SearchToken, style : {marginLeft : '10px', marginRight : '10px'}}}
                    addOn={(
                        <ListCardView 
                            onListView={this.props.onListView}
                            onCardView={this.props.onCardView}
                            isListView={this.props.isListView}
                            isCardView={!this.props.isListView}
                            compact
                        />
                    )}
                />
                <SC.FCol
                    style={{
                        paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'
                    }}
                >
                    {spaceItems}
                </SC.FCol>                
            </SC.FCol>
            
        )
    }
}

export const SpacePattern = ({DragItem, fontStyle, style, pattern, spaceValue, spaceUnit, valueState, name, listView, onPreview, sortableProps, ...rest}) => {    
    const style_box = {...style};
    const absvalue = Math.abs(spaceValue);
    const style_preview = {
        minWidth : Utils.px(absvalue),
        minHeight : Utils.px(absvalue),
        maxWidth : Utils.px(absvalue),
        maxHeight : Utils.px(absvalue),
        backgroundColor : SC.CurrentTheme.theme.color_brand,
        transition : 'all 0.3s ease'
    };
    if (spaceValue < 0) {
        style_preview.backgroundColor = '#e252d7';
    }
    if (listView) {
        delete style_preview.minHeight;
        style_preview.maxHeight = '4px';
        style_preview.minHeight = '4px';
        delete style_preview.border;
    }
    if (rest.selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox {...rest} style={style_box} {...onPreview}>
            <SC.FRow alc jsb style={{marginBottom : '8px'}}>
                <SC.FRow f1 alc overflowHidden>
                    {
                        sortableProps && <SC.DragBox first {...sortableProps.handleProps} style={{border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                            <SC.Icons.DragIcon xsmall />
                        </SC.DragBox>      
                    }
                    {valueState && <StatefulTokenMarker {...valueState} />}
                    <SC.TextDivAbbr style={{flex : 1}}>
                        {name || pattern.name}
                    </SC.TextDivAbbr>
                </SC.FRow>                
                <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}><span style={{marginRight : '4px', marginLeft : '4px'}}>{spaceValue}</span>{spaceUnit}</div>
            </SC.FRow>
            <div style={style_preview}>
            </div>
        </TokenItemBox>
    )
}

const DraggablePattern = SpacePattern;

export const GetSpacingPatternList = () => {

    const patterns = Globals.ProjectManager.Tokens.SpacePatterns.Get();
    return GetSpacingPatternListOfIds(patterns);
}

export const GetSpacingPatternListOfIds = (patterns) => {    
    let patternItems = [];
    // let maxDuration = 0;
    Utils.ForEach(patterns, (patternId, i) => {
        const pattern = GetSpacePatternItem(patternId);
        if (pattern) {
            patternItems.push(pattern);
        }            
    });
    return patternItems;
    // return Utils.Sort(patternItems, (item) => {return item.spaceValue});
}

export const GetSpacePatternItem = (patternId, stateArray) => {
    const pattern = Globals.ProjectManager.Tokens.Token(patternId);
    if (pattern) {
        const info = {};
        const spaceValue = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSizeAndInfo({pattern : pattern, stateArray : stateArray, info : info});
        const spaceUnit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnitAndInfo({pattern : pattern, stateArray : stateArray, info : info});
        return {
            id : patternId,
            pattern : pattern,
            name : pattern.name,
            spaceValue : spaceValue,
            spaceUnit : spaceUnit,
            valueState : info.ValueState
        };
    }
    return {};
}