import React from 'react';
import ReactDOM from 'react-dom';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import styled from 'styled-components';
import ChromaJs from 'chroma-js';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { SetFontTypeFace } from '../../../../views/project/panels/left/designsystem/typography';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss, GetShadowTokenItem } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel, FigmaIcon_Style, Figma_PopupItem, FigmaStyleMenuPopup } from '../../common';
import { GetColorTokenItem, GetcolorTokenValue, GetGradientTokenItem, GetColorTokenList } from '../../../../views/project/panels/left/designsystem/colors';
import { Seperator } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import { TokenGroup, EditableName } from '../../../../views/project/panels/left/designsystem/common'
import { OutsideCloseDotMenuContainer } from '../../../../views/project/panels/left/common';
import TextInput from '../../../../components/editors/textinput';
import TextValue from '../../../../components/editors/textvalue';
import { GetTextPatternTokenItem } from '../../../../views/project/panels/left/designsystem/typography/patterns';


export default class NewTokenStyleItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
        };

        this.onNameChange = this.onNameChange.bind(this);
        this.ShowNewTokenOption = this.ShowNewTokenOption.bind(this);
        this.CreateNewToken = this.CreateNewToken.bind(this);
        
        this.onChangingTextContent = this.onChangingTextContent.bind(this);
        this.UpdateTextContentsThrottled = Utils.Throttle(this.UpdateNodeTexts, 500);
        
    }
    onNameChange(name) {
        this.props.styleItem.name = name;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.showToolbar && !nextProps.showToolbar) {
            this.state.showToolbar = false;
        }
        return true;
    }
    ShowNewTokenOption() {
        if (!this.state.showToolbar) {
            this.TokensWithSameValue = [];

            if (this.props.type === TokenTypes.COLOR) {
                if (ChromaJs.valid(this.props.styleItem.value)) {
                    const existingTokens = this.props.GetExistingTokens(TokenTypes.COLOR);                
                    const tokens = existingTokens[ChromaJs(this.props.styleItem.value).hex()];
                    if (tokens && tokens.length > 0) {
                        tokens.map((tokenId) => {
                            this.TokensWithSameValue.push(GetColorTokenItem({id : tokenId}));
                        })                    
                    }
                }                
            }
            else if (this.props.type === TokenTypes.TextPatterns) {
                const existingTokens = this.props.GetExistingTokens(TokenTypes.TextPatterns);     

                const tokens = existingTokens[this.props.id];
                if (tokens && tokens.length > 0) {
                    tokens.map((tokenId) => {
                        this.TokensWithSameValue.push(GetTextPatternTokenItem({id : tokenId}));
                    })                    
                }             
            }
            else if (this.props.type === TokenTypes.Shadows) {
                const existingTokens = this.props.GetExistingTokens(TokenTypes.Shadows);     

                const tokens = existingTokens[this.props.id];
                if (tokens && tokens.length > 0) {
                    tokens.map((tokenId) => {
                        this.TokensWithSameValue.push(GetShadowTokenItem({id : tokenId}));
                    })                    
                }             
            }
        }
        else {
            delete this.TokensWithSameValue;
        }
        this.props.onShowToolbar(this.props.id);
        this.setState({showToolbar : true})
    }
    CreateNewToken() {
        this.setState({showToolbar : false}, () => {
            this.props.onNewToken();
        });        

    }
    UseToken(token) {
        this.setState({showToolbar : false}, () => {
            this.props.onUseToken(token.id);
        })        
    }
    SelectItems(select, e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onSelectItems(select);
    }
    DeleteItems(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onDeleteItems();
    }
    ZoomToItems(e) {
        e.stopPropagation();
        this.setState({showToolbar : false})
        this.props.onZoomToItems();
    }
    onChangingTextContent(value) {
        this.props.styleItem.value = value;
        this.UpdateTextContentsThrottled();
    }
    UpdateNodeTexts() {
        this.props.GetPluginApi().UpdateNodeTexts(this.props.styleItem.nodes, this.props.styleItem.value);
    }
    render() { 
        const {type, styleItem, count} = this.props;

        let content, toolbar;

        if (this.state.showToolbar && this.props.showToolbar) {
            let existingTokens;

            if (this.TokensWithSameValue && this.TokensWithSameValue.length > 0) {
                existingTokens = (
                    <React.Fragment>
                        <Seperator />
                        <div style={{margin : '8px', paddingLeft : '8px', marginRight : 0}}>Or, link to one of the following token(s) which has the same style : </div>
                        <SC.FCol style={{padding : '8px'}}>
                            {
                                this.TokensWithSameValue.map((token) => {
                                    if (type === TokenTypes.COLOR) {
                                        return (
                                            <SelectionItemBox key={token.id} alc onMouseDown={this.UseToken.bind(this, token)}>
                                                <FigmaColorBox 
                                                    color={token.value}
                                                    round
                                                />
                                                <SC.TextDivAbbr style={{marginLeft : '8px'}}>{token.name}</SC.TextDivAbbr>
                                            </SelectionItemBox>
                                        )
                                    }
                                    else if (type === TokenTypes.Shadows) {
                                        return (
                                            <SelectionItemBox key={token.id} alc onMouseDown={this.UseToken.bind(this, token)}>
                                                <div 
                                                    style={{
                                                        width : '20px',
                                                        height : '20px',
                                                        margin : '8px',
                                                        borderRadius : '2px',
                                                        ...styleItem.preview
                                                    }}
                                                />      
                                                <SC.TextDivAbbr style={{marginLeft : '8px'}}>{token.name}</SC.TextDivAbbr>
                                            </SelectionItemBox>
                                        )
                                    }
                                    else if (type === TokenTypes.TextPatterns) {
                                        return (
                                            <SelectionItemBox key={token.id} alc onMouseDown={this.UseToken.bind(this, token)}>
                                                <div 
                                                    style={{                                
                                                        ...styleItem.preview,               
                                                        lineHeight : '1.2em',
                                                        overflow : 'hidden',
                                                        maxWidth : '100px',
                                                        fontSize : '16px',
                                                        fontWeight : 500
                                                    }}
                                                >
                                                    <SC.TextDivAbbr>Ag</SC.TextDivAbbr>
                                                </div> 
                                                <SC.TextDivAbbr style={{marginLeft : '8px'}}>{token.name}</SC.TextDivAbbr>
                                            </SelectionItemBox>
                                        )
                                    }
                                })
                            }    
                        </SC.FCol>                                                
                    </React.Fragment>                    
                )
            }

            toolbar = (
                <FigmaStyleMenuPopup onClose={() => {
                    this.setState({showToolbar : false})
                }}>
                    <Figma_PopupItem onClick={this.CreateNewToken} disabled={!this.props.hasEditGrant}>
                        <SC.Icons.Icon_Button hasFill style={{marginLeft : '-4px', paddingTop : '4px', paddingBottom : '4px'}}>
                            <SC.Icons.Icon_Plus />
                        </SC.Icons.Icon_Button>
                        <div style={{marginLeft : '4px'}}>Create New Token</div>
                    </Figma_PopupItem>
                    <Figma_PopupItem onClick={this.props.onSelectToken} >
                        <FigmaIcon_Style style={{marginLeft : '4px', paddingTop : '4px', paddingBottom : '4px'}} />
                        <div style={{marginLeft : '10px'}}>Link To Existing Token</div>
                    </Figma_PopupItem>     
                    {existingTokens}                          
                    <Seperator style={{marginTop : '4px', marginBottom : '4px'}} />
                    <FigmaSelection_CommonActions 
                        onSelect={this.SelectItems.bind(this, true)}
                        onDeSelect={this.SelectItems.bind(this, false)}
                        onZoom={this.ZoomToItems.bind(this)}
                        onDelete={this.DeleteItems.bind(this)}
                    />
                </FigmaStyleMenuPopup>
            )
        }

        
        if (type === TokenTypes.COLOR || type === TokenTypes.Gradients) {
            content =  (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                    title='Click to select token'
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    <TextInput 
                        placeholder='Token Name'
                        value={styleItem.name}
                        onChange={this.onNameChange}
                        // onFocus={this.ShowNewTokenOption}
                        style={{
                            width : '120px',
                            fontSize : '11px',
                            marginRight : '8px',
                            flex : 1
                        }}
                    />
                    <FigmaColorBox 
                        color={styleItem.value}
                        round
                    />
                    <SC.FRow>
                        <SC.Icons.Icon_Button dark hasFill hasCursor 
                                style={{marginLeft : '4px', marginRight : '2px'}}                 
                            >
                                <SC.Icons.Arrow_Back />
                            </SC.Icons.Icon_Button>
                        <FigmaColorBox 
                            color={styleItem.value}
                        />    
                    </SC.FRow>   
                    {toolbar}
                </SelectionItemBox>
            );            
        }
        else if (type === TokenTypes.Shadows) {
            content =  (
                <SelectionItemBox 
                    alc jsb 
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                    title='Click to select token'
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    <SC.TextDivAbbr style={{flex : 1}}>Tokenize</SC.TextDivAbbr>
                    <div 
                        style={{
                            width : '20px',
                            height : '20px',
                            margin : '8px',
                            borderRadius : '2px',
                            ...styleItem.preview
                        }}
                    />              
                    {toolbar}
                </SelectionItemBox>
            );            
        }
        else if (type === TokenTypes.TextPatterns) {
            content =  (
                <SelectionItemBox 
                    alc jsb 
                    style={{marginTop : '4px', marginBottom : '4px'}}
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                    title='Click to select token'
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this style'>{count}</NodeCount>
                    <SC.FCol f1>
                        <SC.TextDivAbbr style={{flex : 1}}>{styleItem.name}</SC.TextDivAbbr>
                        <div style={{
                            display : 'grid',
                            gridTemplateColumns : '1fr 60px',
                            gridGap : '8px',
                            justifyItems : 'start',
                            alignItems : 'baseline',
                            padding : '4px'
                        }}>
                            <div >Font Family</div>                                
                            <div >{styleItem.figmaText.fontFamily}</div>
                            <div >Font Weight</div>                                
                            <div >{styleItem.figmaText.fontWeight}</div>
                            <div >Font Size</div>
                            <div >{styleItem.figmaText.fontSize}</div>
                            <div >Line Height</div>
                            <div >{styleItem.figmaText.lineHeight}</div>
                            <div >Letter Spacing</div>
                            <SC.TextDivAbbr>{Number(styleItem.figmaText.letterSpacing).toFixed(4)}em</SC.TextDivAbbr>
                        </div> 
                    </SC.FCol>                        
                    <div 
                        style={{                                
                            ...styleItem.preview,               
                            lineHeight : '1.2em',
                            overflow : 'hidden',
                            maxWidth : '100px'
                        }}
                    >
                        <SC.TextDivAbbr>Ag</SC.TextDivAbbr>
                    </div>       
                    {toolbar}      
                </SelectionItemBox>
            );            
        }
        else if (type === TokenTypes.ContentTexts) {
            content = (
                <SelectionItemBox 
                    jsb alignStart
                    active={this.state.showToolbar}
                    onMouseDown={this.ShowNewTokenOption}
                    title='Click to select token'
                >
                    <NodeCount active={this.state.showToolbar} title='Number of layers with this content'>{count}</NodeCount>
                    <SC.FCol f1>
                        <TextInput 
                            placeholder='Token Name'
                            value={styleItem.name}
                            onChange={this.onNameChange}
                            onFocus={this.ShowNewTokenOption}
                            style={{
                                fontSize : '11px'
                            }}
                        />                                  
                        <TextValue 
                            multiline
                            value={styleItem.value}
                            boxStyle={{
                                marginTop : '8px'
                            }}
                            style={{
                                fontSize : '11px'
                            }}
                            onChanging={this.onChangingTextContent}
                        />    
                    </SC.FCol>
                    
                    {toolbar}
                </SelectionItemBox>
            )
        }

        return (
            <React.Fragment>
                {content}
                {
                    toolbar && false &&
                    <React.Fragment>
                        <div style={{paddingLeft : '14px', paddingTop : '8px'}}>For <span style={{fontStyle : 'bold'}}>{count}</span> item(s) with this style</div>
                        {toolbar}
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
} 

export const SelectionItemBox = styled(SC.FRow)`    
    position : relative;
    padding : 4px;
    border-radius : 2px;
    font-weight : 400;
    font-size : 11px;
    background-color : ${props => props.active ? 'rgba(0,0,0,0.05)' : 'unset'};
    &:hover {
        background-color : rgba(0,0,0,0.05);
    }
`;

export const NodeCount = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    background-color : ${props => props.active ? '#fff' : props.theme.back_lighter};    
    font-size : 11px;
    border-radius : 4px;
    padding : 4px;
    margin-right : 8px;
    min-width : 22px;
    box-sizing : border-box;
    ${SelectionItemBox}:hover &{
        background-color : #fff;
    }
    ${SC.PopupItem}:hover &{
        background-color : #2c2c2c;
        color : #fff;
    }
`;


export const FigmaSelection_CommonActions = ({onSelect, onDeSelect, onZoom, onDelete}) => {
    return (
        <React.Fragment>
            <Figma_PopupItem onClick={onSelect}>
                <SC.Icons.Icon_Canvas_Focus selected size={16}/>
                <div style={{marginLeft : '8px'}}>Select only these items</div>
            </Figma_PopupItem>
            <Figma_PopupItem onClick={onDeSelect}>
                <SC.Icons.Icon_Canvas_Focus size={16}/>
                <div style={{marginLeft : '8px'}}>Deselect these items</div>
            </Figma_PopupItem>
            <Figma_PopupItem onClick={onZoom}>
                <SC.Icons.Icon_Search size={14} style={{marginLeft : '1px', marginRight : '10px'}} />
                <div>Zoom to these items</div>
            </Figma_PopupItem>
            <Figma_PopupItem onClick={onDelete}>
                <SC.Icons.Icon_Delete style={{marginLeft : '2px'}} />
                <div style={{marginLeft : '12px'}}>Delete these items</div>
            </Figma_PopupItem>
        </React.Fragment>
    )
}