import React from 'react';
import {
    SC,
    Utils
} from '../importer';

import styled from 'styled-components';

export default ({onCancel, onAdd, onClone, onDelete, cancelAndDelete, style, onLight}) => {
    return (
        <SC.FRow alc justifyEnd style={style}>                    
            {
                onClone && onDelete && 
                <IconButtonBox onClick={onClone} title='Clone'>
                    <SC.Icons.Icon_Button hasFill hasCursor>
                        <SC.Icons.Icon_Clone size={18} />
                    </SC.Icons.Icon_Button>                
                </IconButtonBox>
            }
            {
                onDelete && 
                <React.Fragment>
                    <IconButtonBox onClick={onDelete}  title='Delete'>
                        <SC.Icons.Icon_Button hasFill hasCursor>
                            <SC.Icons.Icon_Delete size={17} />
                        </SC.Icons.Icon_Button>
                    </IconButtonBox>
                    {/* <div style={{alignSelf : 'stretch', marginLeft : '8px', marginRight : '4px', width : '1px', borderLeft : SC.CurrentTheme.theme.border_ondark}} /> */}
                </React.Fragment>
            }
            {(!onDelete || cancelAndDelete) && onCancel && <IconButtonBox onClick={onCancel}>
                <SC.Icons.Icon_Button hasFill hasCursor>
                    <SC.Icons.Icon_Close size={16} />
                </SC.Icons.Icon_Button>
            </IconButtonBox>}            
            {!onDelete && onAdd && <IconButtonBox onClick={onAdd}>
                <SC.Icons.Icon_Button hasFill hasCursor>
                    <SC.Icons.Icon_Check size={24} />
                </SC.Icons.Icon_Button>
            </IconButtonBox>}
        </SC.FRow>
    )
}

export const IconButtonBox = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    cursor : pointer;
    width : 24px;
    height : 24px;
    border-radius : 2px;
    margin-left : 4px;
    border : 1px solid rgba(0,0,0,0);
    background-color : ${props => props.theme.back};
    &:hover {
        background-color : ${props => props.theme.back_lightest};
        border : ${props => props.theme.border_onlight_line};
    }
`;