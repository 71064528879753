import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    SC,
    UIUtils
} from '../../../../../../importer';
import { motion } from 'framer-motion';

export default class DRopPlaceholder extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            visible : this.props.data.IsParentRoot
        };
    }
    componentDidMount() {
        const bounds = UIUtils.Utils.GetBounds(this);
        this.vertical = bounds.height > bounds.width;

        this.props.onRegister(this.props.data.ParentId, {
            ref : this,
            data : this.props.data,
            Id : Utils.Id()
        })
    }
    componentWillUnmount() {
        this.unmounted = true;
    }
    ToggleVisible(visible) {
        this.setState({visible : visible});
    }
    Toggle(active) {
        this.setState({active : active});
    }
    render() {                 
        let content;
    
        return ( 
            <SC.Div_Relative style={{
                position : 'relative', alignSelf : 'stretch', boxSizing : 'content-box', display : 'flex', 
                minWidth : '1px', minHeight : '1px',
                pointerEvents : 'none'
            }}>
                <motion.div
                    style={{
                        // flex : 1,
                        // alignSelf : 'stretch',
                        // backgroundColor : this.state.active ? '#ff5ae3' : '#4dbdff',
                        // opacity : this.state.visible ? 1 : 0,
                        // ...SC.Styles.Flex.Cell,
                        borderRadius : '4px',
                        ...SC.Styles.Absolute,                                                
                    }}       
                    animate={{
                        backgroundColor : this.state.active ? '#ff5ae3' : '#4dbdff',
                        opacity : this.state.visible ? 1 : 0,
                        top : this.vertical ? 0 : (this.state.active ? -2 : 0),
                        left : this.vertical ? (this.state.active ? -2 : 0) : 0,
                        right : this.vertical ? (this.state.active ? -2 : 0) : 0,
                        bottom : this.vertical ? 0 : (this.state.active ? -2 : 0),
                    }}
                    transition={{
                        duration : 0.2
                    }}                     
                >  
                     {/* <motion.div
                        style={{
                            ...SC.Styles.Absolute
                        }}
                        animate={{
                            width : this.state.active ? 16 : 1,
                            height : this.state.active ? 16 : 1,
                        }}
                        transition={{
                            duration : 0.2
                        }}
                    >                      
                    </motion.div> */}
                </motion.div>
            </SC.Div_Relative>        
        );
    }
}