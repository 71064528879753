import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../importer';

import {TokenItemBox, TokenItemDragHandle, TokenItemBoxHoveredName} from '../common';
import { SortableList, SortableListItem } from '../../../../../../components/SortableContainer';
import SoundEditor from './item';
import Dropzone from 'react-dropzone';
import PlaySound from './playsound';
import BaseTokenList from '../BaseTokenList';
import {Droppable, Draggable} from 'react-beautiful-dnd';
import { StatefulTokenListItemName, StatefulTokenMarker } from '../colors';
import { GRANT_TYPES } from '../../../../manager';

export default class Sounds extends BaseTokenList
{
    constructor(props) {
        super(props);

        this.title = 'SOUNDS';
        this.moduleName = 'Sounds';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Sounds;
        this.hasGroups = true;
        
        this.state.isListView = false;
        
        this.Ref_SoundEdit = React.createRef();
        this.onEditSound = this.onEditSound.bind(this);
        this.onDeleteSound = this.onDeleteSound.bind(this);
        this.onCancelAddSound = this.onCancelAddSound.bind(this);
        this.onSubmitNewSound = this.onSubmitNewSound.bind(this);

        this.onDrop = this.onDrop.bind(this);

        AppLayout.Refs.DesignSystem.Sounds = this;

        super.LoadOptions();
        if (this.expanded || this.props.singleView) {
            this.expanded = true;
            this.Load(this.state.isGroupView);
        }
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        AppLayout.Refs.DesignSystem.Sounds = null;
    }
    Load(groupView) {
        const tokenids = Globals.ProjectManager.Tokens.Sounds();
        this.tokens = [];

        Utils.ForEach(tokenids, (id, i) => {
            const tokenItem = GetSoundTokenItem(id);
            if (tokenItem) {
                this.tokens.push(tokenItem);
            }
        });
        if (groupView)
            this.groups = super.GetGroupedTokenList(this.tokens);
    }  
    SearchToken(filter) {
        let filterText;
        let filteredCount = 0;
        if (Utils.IsNotNullOrEmpty(filter))
            filterText = filter.toUpperCase();
        Utils.ForEach(this.tokens, (token, i) => {
            delete token.filtered;
            if (filterText) {
                if (token.name.toUpperCase().indexOf(filterText) < 0) {                    
                    token.filtered = true;
                }
            }
        });
        this.RenderId = Utils.Id();
        this.RCThrottledUpdate_1();
    }  
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return true;
    }
    AddTokenToGroup(group) {
        if (!Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL)) {
            return;
        }

        this.AddingToGroup = group;
        this.IsNew = true;
        this.EditSoundId = Utils.Id();
        this.EditSoundModel = {
            name : 'New Sound'
        };
        
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SoundEditor 
                        ref={this.Ref_SoundEdit}
                        newModel={this.EditSoundModel}
                        onCancelAddSound={this.onCancelAddSound}
                        onSubmitNewSound={this.onSubmitNewSound}
                        offline
                    />                 
                )
            }
        })
    }
    onEditSound(id) {        
        this.EditSoundId = id;
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SoundEditor 
                        ref={this.Ref_SoundEdit}
                        id={id}
                        onCancelAddSound={this.onSubmitNewSound}
                        onSubmitNewSound={this.onSubmitNewSound}
                        onDeleteSound={this.onDeleteSound}
                        GlobalStateId={props.GlobalStateId}
                    />               
                )
            }
        })
    }        
    onDeleteSound() {
        if (Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Sounds, this.EditSoundId)) {
            this.Load(this.props);
            this.onCancelAddSound();
        }
    }
    onCancelAddSound() {
        delete this.IsNew;
        delete this.EditSoundModel;
        delete this.EditSoundId;

        this.props.onPanelOverlay({close : true});
        this.RCUpdate();
    }
    onSubmitNewSound() {
        if (this.IsNew) {            
            const newid = Globals.ProjectManager.Tokens.Add({
                type : Globals.ProjectManager.Tokens.Types.Sounds,
                name : this.EditSoundModel.name,
                value : this.EditSoundModel.value                 
            });                

            const tokenitem = {
                id : newid,
                name : this.EditSoundModel.name
            }
            this.tokens.push(tokenitem);

            if (this.AddingToGroup) {
                this.AddingToGroup.tokens.push(tokenitem);
                Globals.ProjectManager.Tokens.AddTokenToGroup({type : this.tokenType, groupid : this.AddingToGroup.id, tokenid : tokenitem.id})
                delete this.AddingToGroup;
            } 

            if (this.EditSoundModel.value.provider === Strings.SOUND_FACEBOOKKIT) {
                
            }
            else if (this.EditSoundModel.value.provider === Strings.CUSTOM) {
                tokenitem.url = this.EditSoundModel.value.data;
            }
        }        
        else {       
            this.Load(this.state.isGroupView);   
            Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING);         
        }
                
        this.onCancelAddSound();
    }    
    onDrop(files) {
        if (files) {
            Utils.ForEach(files,(file, i) => {
                const Id = Globals.ProjectManager.Tokens.Add({
                    type  :Globals.ProjectManager.Tokens.Types.Sounds,
                    name : file.name,
                    value : {
                        loading : true,
                        provider : Strings.CUSTOM
                    }
                });
                this.tokens.push({
                    id : Id,
                    name : file.name,
                    loading : true
                })
                this.RCUpdate();
                Globals.ProjectManager.UploadSoundFile(Id, file, null, () => {
                    this.Load();
                    this.RCUpdate();
                });
            });
        }
    }
    renderTokenList(tokens, gorupId) {
        const style = {
            marginTop : '4px', marginLeft : '10px', marginRight : '10px',
            transition : 'all 0.2s ease', ...SC.Styles.Flex.Column
        } 
       
        const soundItems = [];
        Utils.ForEach(tokens, (sound, i) => {                   
            let sounditem; 
            if (!sound.filtered) {
                if (!this.state.isListView) {
                    sounditem = (
                        <PlaySound 
                            sound={sound.data}
                            type={sound.category}
                            fileName={sound.fileName}      
                        />
                    )
                }            
    
                soundItems.push(
                    <SortableListItem
                        key={sound.id}
                        draggableId={sound.id}
                        index={i}
                        ItemBoxType={SoundListItem}
                        isTranfsormed
                        BoxProps={{
                            name : sound.name,
                            sound : sounditem,
                            valueState : sound.valueState,
                            isListView : this.state.isListView,
                            onClick : this.onEditSound.bind(this, sound.id)
                        }}                
                    />
                )    
            }            
        });

        return (
            <Droppable 
                droppableId={gorupId}
                type={'TOKENS'}
                direction={this.state.isListView ? 'vertical' : 'horizontal'}
            >
                {
                    (provided, snapshot) => (
                        <div
                            {...provided.droppableProps} 
                            ref={provided.innerRef} 
                            style={{
                                ...provided.droppableProps.style,
                                ...style,
                                border : '1px dashed',                                                    
                                borderColor : snapshot.isDraggingOver ? SC.CurrentTheme.theme.color_brand : 'transparent',                                
                            }}
                        >
                            {soundItems}
                            {provided.placeholder}
                        </div>
                    )
                }
            </Droppable>
        )  
    }
    
    renderCustom() {
        return (
            <Dropzone
                onDrop={this.onDrop}
                ref={(r) => this.Ref_DropZone = r}
                style={{}}
                accept="audio/*"
                activeStyle={{}}
                onClick={(e) => {e.preventDefault()}}
                inputProps={{
                    style : {width : '10px'}
                }}
            >
            {({ isDragActive, getRootProps, getInputProps }) => {
                return (
                    <SC.FCol 
                        style={{
                            border : '1px dashed',
                            borderColor :isDragActive ? SC.CurrentTheme.theme.color_brand : 'transparent',
                            flex : 1,                            
                        }}
                        {...getRootProps()}
                    >                
                        {super.renderCustom()}                                
                    </SC.FCol>                    
                )
            }}                
            </Dropzone>            
        )
    } 
}


export const GetSoundTokenList = (StateArray) => {
    const tokenids = Globals.ProjectManager.Tokens.Sounds();
    return GetSoundTokenListOfIds(tokenids, StateArray);
}

export const GetSoundTokenListOfIds = (tokenids, StateArray) => {
    const items = [];

    Utils.ForEach(tokenids, (id, i) => {
        items.push(GetSoundTokenItem(id, StateArray));
    });  
    return items;
}

export const GetSoundTokenItem = (id, StateArray) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (!token)
        return {};
    const info = {};
    const tokenvalue = Globals.ProjectManager.Tokens.ValueOf({model : token, statearray : StateArray, info : info}) || {};
    const tokenitem = {
        id : id,
        name : token.name,
        provider : tokenvalue.provider,
        valueState : info.ValueState
    }
    if (tokenvalue.provider === Strings.CUSTOM) {
        tokenitem.data = tokenvalue.data;
    }
    else if (tokenvalue.provider === Strings.SOUND_FACEBOOKKIT) {
        tokenitem.category = tokenvalue.category;
        tokenitem.soundid = tokenvalue.soundid;
        tokenitem.fileName = tokenvalue.fileName;
    }   

    return tokenitem;
}

export const SoundListItem = ({name, url, valueState, loading, sound, isListView, sortableProps, ...rest}) => {
    const style_box = {
        padding : '4px', paddingLeft : '6px', marginRight : isListView ? 0 : '4px', marginBottom : 0, flex : 1,
        ...SC.Styles.Flex.RowAlcJsb
    };

    return (
        <SC.FRow style={{marginBottom : '4px', alignItems : 'stretch'}}>
            <TokenItemBox {...rest} style={style_box}>
                <SC.FRow f1 alc overflowHidden>
                    <StatefulTokenListItemName 
                        name={name}
                        sortableProps={sortableProps}
                        ValueState={valueState}
                    />                     
                </SC.FRow>                       
            </TokenItemBox>    
            {!isListView && sound}         
        </SC.FRow>
        
    )
}