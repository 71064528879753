import {
    AppState,
    Events,
    MetaData,
    Utils,
    Globals
} from '../../../importer';
import ProjectMetaData from '../../../../metadata.js';
import {Publish as Publisher } from '../../../toolabs-importer';


const GetData = () => {
    return Utils.Get(Globals.ProjectManager.Data, {}, 'Data');
}

const PublishManager = {
    BoardPackage(BoardId, JustBoard) {
        return new Promise((resolve) => {
            AppState.CatchedData.Clear(AppState.CatchedData.BOARD_PUBLISHED);
            this.Board(BoardId).then((Result) => {
                const resolve_data = {
                    data_board: Result,
                    data_components: {}
                };
                let Published_Board = Result;
                if (Published_Board) {
                    const components = Globals.ProjectManager.GetComponentModels();
                    Published_Board.Components = {};
                    let Promise_Components = [];
                    Utils.ForEach(components, (dt, cid) => {
                        if (!JustBoard) {
                            Promise_Components.push(
                                Globals.ProjectManager.Publisher.Component(cid)
                            );
                        }
                        Published_Board.Components[cid] = {
                            name: dt.name
                        };
                    });

                    if (JustBoard) {
                        Utils.ForEach(Published_Board,(value, key) => {
                            if (key !== null) {
                                Globals.ProjectManager.SavePubished(value, `boards/${BoardId}/${key}`);
                            }
                        });
                    }
                    else {
                        Promise.all(Promise_Components).then((PublishedComponents) => {
                            if (PublishedComponents) {
                                Utils.ForEach(PublishedComponents, (pc, i) => {
                                    if (pc) {
                                        // pc.MetaData.BoardId = BoardId;
                                        // AppState.DesignData.Board.Published.Save(pc.MetaData, 'components', pc.Id);
                                        resolve_data.data_components[pc.Id] = pc.MetaData;
                                    }
                                });
                                Utils.ForEach(Published_Board,(value, key) => {
                                    if (key !== null) {
                                        Globals.ProjectManager.SavePubished(value, `boards/${BoardId}/${key}`);
                                    }
                                });
                                // AppState.DesignData.Board.Published.Save(Published_Board, 'boards', BoardId);
                                resolve(resolve_data);
                            }
                        })
                    }
                }
            });
        });

    },
    Board(Id) {
        return new Promise((resolve) => {
            let p = AppState.CatchedData.Get(AppState.CatchedData.BOARD_PUBLISHED, Id);
            if (p)
                resolve(p);
            else {
                const PublishedBoard = Publisher.Publish_Board(
                    Globals.ProjectManager.Data.Model,
                    Globals.ProjectManager.Data.Data,
                    Globals.ProjectManager.Data.Icons,
                    MetaData.Platforms.React.Name
                );
                AppState.CatchedData.Set(PublishedBoard, AppState.CatchedData.BOARD_PUBLISHED, Id);
                resolve(PublishedBoard);
            }
        })
    },
    GetComponentMetaData(Id) {
        return this.Component(Id);
    },
    Component(Id, willPublishBoard) {
        return new Promise((resolve, reject) => {
            const ComponentData = Globals.ProjectManager.GetComponentData(Id);
            if (ComponentData) {
                if (ComponentData.ChangeId !== ComponentData.PublishedId) {
                    AppState.CatchedData.Clear(AppState.CatchedData.COMPONENT_PUBLISHED, Id);
                }

                let p_c = AppState.CatchedData.Get(AppState.CatchedData.COMPONENT_PUBLISHED, Id);
                let p_b = AppState.CatchedData.Get(AppState.CatchedData.BOARD_PUBLISHED, Globals.ProjectManager.Id);
                if (p_c && p_b) {
                    if (willPublishBoard) {
                        AppState.DesignData.Board.Published.Save(p_c, 'components', Id);
                        Utils.ForEach(p_b,(value, key) => {
                            if (key !== null) {
                                AppState.DesignData.Board.Published.Save(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                            }
                        });
                    }
                    resolve({
                        MetaData: p_c,
                        BoardData: p_b,
                        Id: Id
                    });
                }
                else {
                    this.Board(Globals.ProjectManager.Id).then((published_board) => {
                        const published_component = Publisher.Publish_Component(
                            {
                                Model: {
                                    _id : Id
                                },
                                Data: ComponentData
                            },
                            published_board,
                            MetaData.Platforms.React.Name,
                            ProjectMetaData.metadata.version.current,
                            Globals.ProjectManager.Mockups.GetRecordPublishValue
                        );
                        AppState.CatchedData.Set(published_component, AppState.CatchedData.COMPONENT_PUBLISHED, Id);
                        published_component.BoardId = Globals.ProjectManager.Id;
                        published_component.SourceChangeId = ComponentData.ChangeId;
                        Globals.ProjectManager.SavePubished(published_component, 'components', Id);
                        if (!Utils.IsNotNullOrEmpty(ComponentData.ChangeId)) {
                            ComponentData.ChangeId = Utils.Id();
                            Globals.ProjectManager.SetComponentChangeId(Id, ComponentData.ChangeId);    
                        }
                        ComponentData.PublishedId = ComponentData.ChangeId;
                        Globals.ProjectManager.SetComponentPublishedId(Id, ComponentData.ChangeId);
                        if (willPublishBoard) {
                            Utils.ForEach(published_board,(value, key) => {
                                if (key !== null) {
                                    Globals.ProjectManager.SavePubished(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                                }
                            });
                        }
                        resolve({
                            MetaData: published_component,
                            BoardData: published_board,
                            Id: Id
                        });
                    })
                }
            }
        });
    },
    Prototype(Id, willPublishBoard, publishPackage) {
        return new Promise((resolve, reject) => {
            const PrototypeData = Globals.ProjectManager.GetPrototypeData(Id);
            if (PrototypeData) {                            
                if (PrototypeData.ChangeId !== PrototypeData.PublishedId) {
                    AppState.CatchedData.Clear(AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                }
                let p_c = AppState.CatchedData.Get(AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                let p_b = AppState.CatchedData.Get(AppState.CatchedData.BOARD_PUBLISHED, Globals.ProjectManager.Id);
                if (p_c && p_b && !publishPackage) {
                    if (willPublishBoard) {
                        AppState.DesignData.Board.Published.Save(p_c, 'prototypes', Id);
                        Utils.ForEach(p_b,(value, key) => {
                            if (key !== null) {
                                AppState.DesignData.Board.Published.Save(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                            }
                        });
                    }
                    resolve({
                        MetaData: p_c,
                        BoardData: p_b,
                        Id: Id
                    });
                }
                else {
                    let callBoardBackage = this.Board;
                    if (publishPackage)
                        callBoardBackage = this.BoardPackage;

                    this.Board(Globals.ProjectManager.Id).then((result_published_board) => {
                        let published_board = result_published_board;
                        // if (publishPackage)
                        //     published_board = result_published_board.data_board;

                        const published_prototype = Publisher.Publish_Prototype(
                            {
                                Model: {

                                },
                                Data: PrototypeData
                            },
                            published_board,
                            MetaData.Platforms.React.Name,
                            ProjectMetaData.metadata.version.current,
                            Globals.ProjectManager.Mockups.GetRecordPublishValue
                        );

                        AppState.CatchedData.Set(published_prototype, AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                        published_prototype.BoardId = Globals.ProjectManager.Id;
                        published_prototype.SourceChangeId = PrototypeData.ChangeId;
                        Globals.ProjectManager.SavePubished(published_prototype, 'prototypes', Id);
                        Globals.ProjectManager.SetPrototypePublishedId(Id, false, PrototypeData.ChangeId);
                        if (willPublishBoard) {
                            Utils.ForEach(published_board,(value, key) => {
                                if (key !== null) {
                                    AppState.DesignData.Board.Published.Save(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                                }
                            });
                        }
                        resolve({
                            MetaData: published_prototype,
                            BoardData: published_board,
                            Id: Id
                        });
                    })
                }

            }
            else {
                reject();
            }
        });
    },
    Documents_Template(Id, willPublishBoard, publishPackage) {
        return new Promise((resolve, reject) => {
            const PrototypeData = Globals.ProjectManager.GetPrototypeData(Id);
            if (PrototypeData) {                            
                if (PrototypeData.ChangeId !== PrototypeData.PublishedId) {
                    AppState.CatchedData.Clear(AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                }
                let p_c = AppState.CatchedData.Get(AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                let p_b = AppState.CatchedData.Get(AppState.CatchedData.BOARD_PUBLISHED, Globals.ProjectManager.Id);
                if (p_c && p_b && !publishPackage) {
                    if (willPublishBoard) {
                        AppState.DesignData.Board.Published.Save(p_c, 'documents', Id);
                        Utils.ForEach(p_b,(value, key) => {
                            if (key !== null) {
                                AppState.DesignData.Board.Published.Save(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                            }
                        });
                    }
                    resolve({
                        MetaData: p_c,
                        BoardData: p_b,
                        Id: Id
                    });
                }
                else {
                    let callBoardBackage = this.Board;
                    if (publishPackage)
                        callBoardBackage = this.BoardPackage;

                    this.Board(Globals.ProjectManager.Id).then((result_published_board) => {
                        let published_board = result_published_board;
                        // if (publishPackage)
                        //     published_board = result_published_board.data_board;

                        const published_prototype = Publisher.Publish_Prototype(
                            {
                                Model: {

                                },
                                Data: PrototypeData
                            },
                            published_board,
                            MetaData.Platforms.React.Name,
                            ProjectMetaData.metadata.version.current,
                            Globals.ProjectManager.Mockups.GetRecordPublishValue
                        );

                        AppState.CatchedData.Set(published_prototype, AppState.CatchedData.PROTOTYPE_PUBLISHED, Id);
                        published_prototype.BoardId = Globals.ProjectManager.Id;
                        published_prototype.SourceChangeId = PrototypeData.ChangeId;
                        AppState.DesignData.Board.Published.Save(published_prototype, 'documents', Id);
                        Globals.ProjectManager.SetPrototypePublishedId(Id, true, PrototypeData.ChangeId);
                        if (willPublishBoard) {
                            Utils.ForEach(published_board,(value, key) => {
                                if (key !== null) {
                                    AppState.DesignData.Board.Published.Save(value, `boards/${Globals.ProjectManager.Id}/${key}`);
                                }
                            });
                        }
                        resolve({
                            MetaData: published_prototype,
                            BoardData: published_board,
                            Id: Id
                        });
                    })
                }

            }
            else {
                reject();
            }
        });
    }
}

export default PublishManager;