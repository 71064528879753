import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings
} from '../../importer';
import * as Styled from './styles';
import InputForm from './input_form';
import Switch from '../../components/editors/Switch';


export default class Account_Settings extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClickResetPassword = this.onClickResetPassword.bind(this);
        this.onClickDeleteAccount = this.onClickDeleteAccount.bind(this);
        this.onNameChanged = this.onNameChanged.bind(this);
        this.onEmailNotificationChanged = this.onEmailNotificationChanged.bind(this);
        this.onPushNotificationChanged = this.onPushNotificationChanged.bind(this);
        this.onPlaySoundsChanged = this.onPlaySoundsChanged.bind(this);

        this.loaded = false;
        this.name = AppState.User.displayName;
        this.emailNotification = true;
        this.pushNotification = false;
        this.playSounds = true;
    }
    componentDidMount() {
        super.componentDidMount();
        this.load();
    }
    load() {
        this.loaded = false;
        this.name = AppState.User.displayName;        

        AppState.DesignData.Admin.UserInfo(AppState.User.uid).then(
            (result) => {
                if (result && result.value) {
                    if (result.value.settings) {
                        this.emailNotification = result.value.settings.email_notification;
                        this.pushNotification = result.value.settings.push_notification;
                        this.playSounds = result.value.settings.play_sounds;
                    }
                    this.provider = result.value.provider;
                }
                this.loaded = true;
                this.RCUpdate();
            }
        );

    }
    onNameChanged(name) {
        this.name = name;
        AppState.UpdateUserSettings({uid : AppState.User.uid, name : this.name});
    }
    onEmailNotificationChanged(emailNotification) {

        this.emailNotification = emailNotification;

    }
    onPushNotificationChanged(pushNotification) {
        this.pushNotification = pushNotification;
        if (pushNotification) {
            AppState.RequestNotificationPermission();
        }
    }
    onPlaySoundsChanged(playSounds) {
        this.playSounds = playSounds;
    }
    onSave() {
        var userSettings =
        {
            email_notification: this.emailNotification,
            push_notification: this.pushNotification,
            play_sounds: this.playSounds
        };
        AppState.UpdateUserSettings({uid : AppState.User.uid, name : this.name, setting : userSettings});
        //Events.AlertSimple(Strings.SAVED_SUCCESS, Events.GLOBAL.NOTIFICATION.TYPES.SUCCESS);
        Events.Notify(Strings.SAVED_SUCCESS, Events.GLOBAL.NOTIFICATION.TYPES.SUCCESS, true);
    }
    onCancel() {
        this.props.onClose && this.props.onClose();
    }
    onClickResetPassword() {
        AppState.ResetPassword(this.email)
        .then(
            (result) => {
                if (result.error) {
                    this.ErrorMessage = result.error.errorMessage;
                    this.RCUpdate();
                }
                else {
                    Events.AlertSimple(Strings.PASSWORD_RESET_MAIL_SENT, Events.GLOBAL.NOTIFICATION.TYPES.INFO, "Change your password", "Please check your inbox!", SC.Icons.Icon_Email_Sent);
                }
            }
        );
    }
    onClickDeleteAccount() {
        Events.Ask_YesNo(Strings.Delete_Account(this.email), Events.GLOBAL.NOTIFICATION.TYPES.WARNING, "Delete your account?", "Your account will be removed permanantly!", SC.Icons.Icon_Delete_Warning, true).then((result) => {
            if (result === Events.GLOBAL.NOTIFICATION.RESULT.YES) {
                AppState.DeleteUser(AppState.User.uid).then(function () {
                    AppState.LogOut();
                }).catch(function (error) {

                });
            }
        });
    }

    renderCustom() {
        if (!this.loaded)
            return (
                <Loading />
            );

        return (
            <SC.FCol alc f1 style={{ paddingTop: '96px' }}>
                <Styled.Account_Main >
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 1fr',
                        gridGap : '24px',
                        alignSelf : 'stretch'
                    }}>
                        <SC.InnerPanelGroup style={{
                            padding : '24px'
                        }}>
                            <Styled.Form_GroupTitle style={{justifyContent : 'flex-start'}}>
                                ACCOUNT
                            </Styled.Form_GroupTitle>
                            <InputForm
                                label="NAME"
                                showIcon
                                required
                                value={AppState.User.displayName}
                                onChanged={this.onNameChanged}
                            />
                            {
                                this.provider === 'email' &&
                                <div>
                                    <Styled.Input_Form_Label>
                                        PASSWORD
                                </Styled.Input_Form_Label>

                                    <Styled.Button_ResetPassword onClick={this.onClickResetPassword}>
                                        Reset Password
                                    </Styled.Button_ResetPassword>

                                    <Styled.Password_Description alc>
                                        <SC.Icons.ErrorIcons.info size={24} color={"#AAAAAA"} />
                                        <div style={{ marginLeft: 5, marginTop: 5, lineHeight: '16px' }}>Your passwords are not stored in our database but are secure in Google Authentication. </div>
                                    </Styled.Password_Description>
                                </div>
                            }
                        </SC.InnerPanelGroup>
                        <SC.InnerPanelGroup style={{
                            padding : '24px'
                        }}>
                            <Styled.Form_GroupTitle style={{justifyContent : 'flex-start'}}>
                                NOTIFICATIONS
                            </Styled.Form_GroupTitle>
                            <Styled.Notification_Row style={{ marginTop: 35 }}>
                                <div>
                                    Email Notifications
                            </div>
                                <Switch value={this.emailNotification} onChange={this.onEmailNotificationChanged} />
                            </Styled.Notification_Row>
                            <Styled.Notification_Row>
                                <div>
                                    Push Notifications
                            </div>
                                <Switch value={this.pushNotification} onChange={this.onPushNotificationChanged} />
                            </Styled.Notification_Row>                          
                        </SC.InnerPanelGroup>
                        <Styled.Delete_Account onClick={this.onClickDeleteAccount}>Click to delete your account.</Styled.Delete_Account>
                    </div>
                    <Styled.Divider style={{ marginTop: '24px' }} />
                    <SC.FRow style={{ width: '100%', marginTop: 20 }} justifyEnd>
                        <SC.Buttons.RoundButton round  onClick={this.onCancel}>
                            Cancel
                        </SC.Buttons.RoundButton>
                        <SC.Buttons.RoundButton primary round style={{marginLeft : '16px'}}  onClick={this.onSave}>
                            Save
                        </SC.Buttons.RoundButton>
                    </SC.FRow>
                </Styled.Account_Main>
            </SC.FCol>
        );
    }
}

