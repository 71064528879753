import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';

class TextInput extends React.Component {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value : this.props.value || ''
        }

        this.updateStyle(props);
    }
    updateStyle(props) {
        const style = props.style;        
        const classId = props.classId;

        

        const {
            ':hover' : styleobj_track_hover, 
            ':focus' : styleobj_track_focus, 
            ...styleobj_track_rest} = style;


        const style_track = {
            width : '100%',
            fontFamily : 'unset',
            fontSize : 'unset',
            minWidth : 0,
            ...styleobj_track_rest,
        };

        let css_track = Utils.ConvertObjectToCss(style_track);
        let css_track_hover = Utils.ConvertObjectToCss(styleobj_track_hover);
        let css_track_focus = Utils.ConvertObjectToCss(styleobj_track_focus);
        let placeholder = '';

        if (!Utils.IsNotNullOrEmpty(props.value)) {
            const style_placeholder = Utils.GetSubset(['color', 'fontSize'], style);
            const style_placeholder_focus = Utils.GetSubset(['color', 'fontSize'], styleobj_track_focus);
            let css_placeholder = Utils.ConvertObjectToCss(style_placeholder);
            let css_placeholder_focus = Utils.ConvertObjectToCss(style_placeholder_focus);

            placeholder = `
            .${classId}::-webkit-input-placeholder { /* Chrome */
                ${css_placeholder}
              }
              .${classId}:-ms-input-placeholder { /* IE 10+ */
                ${css_placeholder}
              }
              .${classId}::-moz-placeholder { /* Firefox 19+ */
                ${css_placeholder}
              }
              .${classId}:-moz-placeholder { /* Firefox 4 - 18 */
                ${css_placeholder}
              }
              
              .${classId}:focus::-webkit-input-placeholder { /* Chrome */
                ${css_placeholder_focus}
              }
              .${classId}:focus:-ms-input-placeholder { /* IE 10+ */
                ${css_placeholder_focus}
              }
              .${classId}:focus::-moz-placeholder { /* Firefox 19+ */
                ${css_placeholder_focus}
              }
              .${classId}:focus:-moz-placeholder { /* Firefox 4 - 18 */
                ${css_placeholder_focus}
              }
            `;
        }

        Utils.SetStyleSheet({
            Id : classId, 
            StyleDefinition : `
        .${classId} {
            ${css_track}
          }
          .${classId}:focus {
            ${css_track_focus}
          }
          .${classId}:hover {
            ${css_track_hover}
          }
          ${placeholder}
        `, TargetDocument : this.props.document})
    }
    componentDidMount() {
        if (!Utils.IsNotNullOrEmpty(this.props.value)) {
            this.props.onSwitchToEmptyState(true);
        }
    }
    componentWillUnmount() {
        Utils.RemoveStyleSheet(this.props.classId);
    }
    shouldComponentUpdate(nextProps, nextState) {        
        if (this.props.classId !== nextProps.classId || !Utils.IsEqual(this.props.style, nextProps.style)) {
            this.updateStyle(nextProps);
        }
        if (nextProps.value !== this.state.value)
            this.state.value = nextProps.value;
        return true;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.value !== this.props.value) {
            if (!Utils.IsNotNullOrEmpty(this.props.value)) {
                this.props.onSwitchToEmptyState(true);
            }
            else if (!Utils.IsNotNullOrEmpty(prevProps.value)) {
                this.props.onSwitchToEmptyState(false);
            }
        }
    }
    onChange(e) {
        let value = e.target.value;
        this.setState({value : value}, () => {
            this.props.onChange(value, e);
        })        
    }
    onFocus(e) {        
        this.props.onFocus && this.props.onFocus(e);
        if (window.AppState) {
            window.AppState.Designer.SetEditingText(true);;
        }
    }
    onBlur(e) {
        this.props.onBlur && this.props.onBlur();
        if (window.AppState) {
            window.AppState.Designer.EditingText = false;
        }
    }
    render() {                        
        const {style, classId, document, onSwitchToEmptyState, ...rest} = this.props;
        return (
            <input
                type='text'         
                {...rest}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                value={this.state.value}                
                className={this.props.classId}                
            >
            </input>
        )
    }
}

const StyledInput = TextInput; //Radium(TextInput)

class Generic_TextInput extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();
        this.onChange = this.onChange.bind(this);
        this.SwitchToEmptyState = this.SwitchToEmptyState.bind(this);
    }    
    onChange(value, e) {
        this.props.onSetModelValue('value', value);		
    }
    SwitchToEmptyState(isEmpty) {
        this.props.onSetState({
            MergeWithCurrentState : true,
            Refersh : true,
            Variations : {
                empty : isEmpty ? 'empty' : null
            }
        })
    }
    renderItem({style, props, events, rest}) {                   
        const models = Utils.JustGet(this.props, {}, 'Models');
     
        return (
            <StyledInput
                {...events}
                {...rest}
                style={style}
                readOnly={this.props.IsStateActive('readonly', 'readonly')}
                disabled={this.props.IsStateActive('disabled', 'disabled')}
                onChange={this.onChange}
                value={models.value}
                placeholder={models.placeholder}
                onSwitchToEmptyState={this.SwitchToEmptyState}
                classId={`gcsstf-${this.InstanceId}_${Utils.IsNotNullOrEmpty(models.value) ? 'e' : 'p'}`}
            />
        )
    }
}

export default Generic_TextInput;