import ReactDOM from 'react-dom';
import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { TokenTypes } from '../../../../views/project/manager/tokens';
import ChromaJs from 'chroma-js';

import { FigmaLogo } from '../..';
import { FigmaColorBox } from '../localStyleTokenMappings/styleitem';
import { GetShadowTokenBoxCss, GetShadowTokenItem, GetShadowTokenList } from '../../../../views/project/panels/left/designsystem/shadows';
import { FigmaInfoPanel, Figma_PopupItem, FigmaIcon_Style } from '../../common';
import { GetColorTokenItem, GetColorTokenList, GetGradientTokenItem } from '../../../../views/project/panels/left/designsystem/colors';
import { Seperator } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
import { TokenGroup, TokenPanelHeader } from '../../../../views/project/panels/left/designsystem/common';
import { SelectionItemBox, NodeCount} from './newstyleitem';
import TokenItem from './tokenitem';
import NewTokenItem from './newstyleitem';
import { OutsideCloseDotMenuContainer } from '../../../../views/project/panels/left/common';
import DropDownSelect from '../../../../components/editors/enum_dropdown';
import { GetTextPatternList } from '../../../../views/project/panels/left/designsystem/typography/patterns';
import { GetUniqueTextTokenStyle, GetUniqueFigmaTextStyle } from '../../api';
import { GetTextContentTokenList } from '../../../../views/project/panels/left/designsystem/textContent';
import LocalStyleTokenItem from './localStyleTokens';
import UnboundFigmaLocalStyles from '../unboundstyles';
import { AliaseItemEditor, GetTokenAliaseItem } from '../../../../views/project/panels/left/designsystem/aliases';

// views/project/panels/right/iteminspector/selectToken';

export default class FigmaSelection extends ReactBasicComponent {
    constructor(props) {
        super(props);

        this.Ref_Loading = React.createRef();
        this.GetExistingTokens = this.GetExistingTokens.bind(this);
        this.ShowToolbarFor = this.ShowToolbarFor.bind(this);        
        this.RenderFilterItem = this.RenderFilterItem.bind(this);        
        this.CreateColorToken = this.CreateColorToken.bind(this);
        
        this.Load = this.Load.bind(this);
    }

    componentDidMount() {

        this.refresher = setInterval(() => {
            // this.Relo
        }, 2000);

        this.Reload();
    }
    shouldComponentUpdate(nextProps, nextState) {
        
        return true;
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.selectionId !== this.props.selectionId) {
            this.Reload();
            return false;
        }
        else if (this.props.GlobalStateId !== prevProps.GlobalStateId && !this.IsSuspended) {
            // if (this.SelectionData) {
            //     Utils.ForEach(this.SelectionData.colors.tokens, (tokenItem, tokenId) => {
            //         GetcolorTokenValue(Globals.ProjectManager.Tokens.Token(tokenId), tokenItem);
            //     });
            // }   
            this.Load(false, this.state);
        }
        else if (this.state.filterItemtype !== prevState.filterItemtype) {
            this.Load(true, this.state);
        }
    }
    Reload(loadLastSelection) {        
        let DN = ReactDOM.findDOMNode(this.Ref_Loading.current);
        if (DN) DN.style.opacity = 1;
        // console.log('GetSelection.0');
        if (loadLastSelection) {
            this.props.GetPluginApi().GetLastSelection(() => {
                // console.log('GetSelection.1');
                this.Load(false, this.state);
            })
        }
        else {
            this.props.GetPluginApi().GetSelection(() => {
                // console.log('GetSelection.1');
                this.Load(false, this.state);
            })
        }        
    }

    GetExistingTokens(type) {
        const ExistingTokens = {};
        if (type === TokenTypes.COLOR) {
            const tokens_color = GetColorTokenList();                        
            Utils.ForEach(tokens_color, (colorToken, ) => {
                const ids = Utils.Get(ExistingTokens, [], ChromaJs(colorToken.value).hex());
                ids.push(colorToken.id);
            });
            
        }            
        else if (type === TokenTypes.TextPatterns) {
            const tokens_text = GetTextPatternList();                        
            Utils.ForEach(tokens_text, (textToken, ) => {
                const tokenStyleId = GetUniqueTextTokenStyle(textToken.pattern);                
                const ids = Utils.Get(ExistingTokens, [], tokenStyleId);
                ids.push(textToken.id);
            });
        }
        else if (type === TokenTypes.ContentTexts) {
            const tokens_text = GetTextContentTokenList();                        
            Utils.ForEach(tokens_text, (textToken, ) => {
                const ids = Utils.Get(ExistingTokens, [], textToken.value);
                ids.push(textToken.id);
            });
        }
        else if (type === TokenTypes.Shadows) {
            const tokens_shadows = GetShadowTokenList().All;
            Utils.ForEach(tokens_shadows, (shadowToken, ) => {                
                const tokenStyleId = shadowToken.value;                
                const ids = Utils.Get(ExistingTokens, [], tokenStyleId);
                ids.push(shadowToken.id);
            });
        }
        return ExistingTokens;
    }

    Load(DoNotUpdate, state) {
        const PluginApi = this.props.GetPluginApi();

        // console.log(`Load 1`);

        if (!this.tooManyStylesAccepted && PluginApi.SelectedNodeCount > 1000) {
            this.RCUpdate();
            return;
        }

        if ((!PluginApi.FigmaStyleTokens || Utils.Keys(PluginApi.FigmaStyleTokens).length === 0) && !this.ReloadTried) {
            this.ReloadTried = true;
            PluginApi.GetFigmaStyleTokenMap(({bindings, tokenMap}) => {
                PluginApi.FigmaTokenMap = tokenMap || {};
                PluginApi.FigmaStyleTokens = bindings || {};
                this.Load(false, this.state);
            })
            return;
        }

        const styleTypes = ['colors', 'gradients', 'shadows', 'texts'];
        this.props.GetPluginApi().GetLocalStyles((styles) => {

            const StyleIdMap = {};
            if (styles) {
                styleTypes.map((styleType) => {
                    if (styles[styleType]) {
                        styles[styleType].map((styleItem) => {
                            styleItem.type = styleType;
                            StyleIdMap[styleItem.sid] = styleItem;
                        })
                    }
                })
            }

            const nodes = PluginApi.SelectedNodes;
            this.SelectionData = {
                colors : {
                    tokens : {},
                    styles : {},
                    empty : []
                },
                strokes : {
                    tokens : {},
                    styles : {},
                    empty : []
                },
                shadows  :{
                    tokens : {},
                    styles : {},
                    empty : []
                },
                texts  :{
                    tokens : {},
                    styles : {},
                    contents : {},
                    contentTokens : {}
                },
                booleans  :{

                },
                radiuses : {
    
                },
                borders : {
    
                },
                icons : {
    
                },
                spacings : {

                },
                autolayouts : {

                },
                itemCounts : {
                    all : 0,
                    text : 0,
                    component : 0,
                    masters : 0,
                    instances : 0,
                    nontext : 0
                }
            }
            if (nodes && nodes.length > 0) {
                const FigmaStyleTokens = PluginApi.FigmaStyleTokens || {};
                
                const isComponent = (nodeData) => {
                    return nodeData.type === 'COMPONENT' || nodeData.owner === 'COMPONENT' || nodeData.owner === 'COMPONENT_SET';
                }
    
                FigmaStyleTokens && nodes.map((nodeData) => {
                    let isFiltered = false;
                    this.SelectionData.itemCounts.all++;
                    if (nodeData.type !== 'TEXT') {
                        this.SelectionData.itemCounts.nontext++;
                        if (state.filterItemtype === 'text')
                            isFiltered = true;
                        else {
                            if (isComponent(nodeData) || nodeData.owner === 'INSTANCE') {
                                this.SelectionData.itemCounts.component++;
                                if (nodeData.owner === 'INSTANCE') {
                                    this.SelectionData.itemCounts.instances++;                                
                                }
                                else {
                                    this.SelectionData.itemCounts.masters++;
                                }
    
                            }
                        }
                    }
                    else {
                        this.SelectionData.itemCounts.text++;
                    }
    
                    if (state.filterItemtype && state.filterItemtype !== 'all') {
                        if (state.filterItemtype === 'text' && nodeData.type !== 'TEXT') 
                            isFiltered = true;
                        else if (state.filterItemtype === 'component' && (!isComponent(nodeData) && nodeData.owner !== 'INSTANCE')) 
                            isFiltered = true;
                        else if (state.filterItemtype === 'masters' && (!isComponent(nodeData) || nodeData.owner === 'INSTANCE')) 
                            isFiltered = true;
                        else if (state.filterItemtype === 'instances' && (nodeData.owner !== 'INSTANCE')) 
                            isFiltered = true;
                        else if (state.filterItemtype === 'nontext' && nodeData.type === 'TEXT') 
                            isFiltered = true;
                        else {
    
                        }
                    }
    
                    if (!isFiltered || state.filterItemtype === 'all') {
                        let hasFill;
                        if (nodeData.fillStyleId) {
                            hasFill = true;
                            let isFilllBoundToToken;
                            if (FigmaStyleTokens[nodeData.fillStyleId] && FigmaStyleTokens[nodeData.fillStyleId].tokenId) {
                                const tokenId = FigmaStyleTokens[nodeData.fillStyleId].tokenId;
                                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                                if (token) {       
                                    isFilllBoundToToken = true;                     
                                    let tokenItem = this.SelectionData.colors.tokens[tokenId];
                                    if (!tokenItem) {
                                        if (token.type === TokenTypes.Gradients) {
                                            tokenItem = GetGradientTokenItem({token : token});
                                            tokenItem.preview = {
                                                background : tokenItem.value
                                            }
                                        }
                                        else {
                                            tokenItem = GetColorTokenItem({token : token});
                                            tokenItem.preview = {
                                                backgroundColor : tokenItem.value
                                            }
                                        }
                                        tokenItem.isAliase = token.aliase;
                                        this.SelectionData.colors.tokens[tokenId] = tokenItem;
            
                                    }
                                    const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                    tokenNodes.push(nodeData.id);
                                }                        
                            }
    
                            if (!isFilllBoundToToken) {
                                const localStyle = StyleIdMap[nodeData.fillStyleId];
                                if (localStyle) {                                    
                                    if (localStyle.type === 'colors') {
                                        const styleItem = this.props.GetPluginApi().ParseFigmaLocalSyle_Color(localStyle);                                        
                                        if (!this.SelectionData.colors.styles[styleItem.rgba]) {
                                            this.SelectionData.colors.styles[styleItem.rgba] = {
                                                value : styleItem.rgba,
                                                preview : {backgroundColor : styleItem.rgba},
                                                styleId : nodeData.fillStyleId,
                                                name : styleItem.name
                                            }                                            
                                        }
    
                                        const nodes = Utils.Get(this.SelectionData.colors.styles, [], styleItem.rgba, 'nodes');                                    
                                        nodes.push(nodeData.id);
                                    }
                                }
                            }
                        }
                        else {
                            if (nodeData.fills) {
                                Utils.ForEach(nodeData.fills, (fill, ) => {
                                    hasFill = true;
                                    if (fill.type === TokenTypes.COLOR) {
                                        const rgba = this.props.GetPluginApi().ConvertFigmaColorToRgba(fill.color);
                                        if (!this.SelectionData.colors.styles[rgba]) {
                                            Utils.Set(this.SelectionData.colors.styles, {backgroundColor : rgba}, rgba, 'preview');
                                            Utils.Set(this.SelectionData.colors.styles, rgba, rgba, 'value');
                                        }
    
                                        const nodes = Utils.Get(this.SelectionData.colors.styles, [], rgba, 'nodes');                                    
                                        nodes.push(nodeData.id);
    
                                    }   
                                    else if (fill.type === TokenTypes.Gradients) {
                                        const stops = this.props.GetPluginApi().ConvertFigmaGradientColors(fill.colors);
                                        const gradient = Utils.GetGradientCss(stops, 'linear', 90);
                                        const nodes = Utils.Get(this.SelectionData.colors.styles, [], gradient, 'nodes');
                                        Utils.Set(this.SelectionData.colors.styles, gradient, gradient, 'value');
                                        Utils.Set(this.SelectionData.colors.styles, {background : gradient}, gradient, 'preview');
                                        Utils.Set(this.SelectionData.colors.styles, stops, gradient, 'stops');
                                        Utils.Set(this.SelectionData.colors.styles, TokenTypes.Gradients, gradient, 'type');
                                        nodes.push(nodeData.id);
                                    }
                                });
                            }
                        }
    
                        if (!hasFill) {
                            this.SelectionData.colors.empty.push(nodeData.id);
                        }
    
        
                        let hasStroke;
                        if (nodeData.strokeStyleId) {
                            hasStroke = true;
                            if (FigmaStyleTokens[nodeData.strokeStyleId] && FigmaStyleTokens[nodeData.strokeStyleId].tokenId) {
                                const tokenId = FigmaStyleTokens[nodeData.strokeStyleId].tokenId;
                                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                                if (token) {                            
                                    let tokenItem = this.SelectionData.strokes.tokens[tokenId];
                                    if (!tokenItem) {
                                        if (token.type === TokenTypes.Gradients) {
                                            tokenItem = GetGradientTokenItem({token : token});
                                            tokenItem.preview = {
                                                background : tokenItem.value
                                            }
                                        }
                                        else {
                                            tokenItem = GetColorTokenItem({token : token});
                                            tokenItem.preview = {
                                                backgroundColor : tokenItem.value
                                            }
                                        }
                                        
                                        tokenItem.isAliase = token.aliase;
                                        this.SelectionData.strokes.tokens[tokenId] = tokenItem;
            
                                    }
                                    const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                    tokenNodes.push(nodeData.id);
                                }                        
                            }                                    
                        }
                        else {
                            if (nodeData.strokes) {
                                Utils.ForEach(nodeData.strokes, (stroke, ) => {
                                    hasStroke = true;
                                    const rgba = this.props.GetPluginApi().ConvertFigmaColorToRgba(stroke.color);
                                    const nodes = Utils.Get(this.SelectionData.strokes.styles, [], rgba, 'nodes');
                                    Utils.Set(this.SelectionData.strokes.styles, rgba, rgba, 'value');
                                    Utils.Set(this.SelectionData.strokes.styles, {backgroundColor : rgba}, rgba, 'preview');
                                    nodes.push(nodeData.id);
                                });
                            }
                        }
    
                        if (!hasStroke) {
                            this.SelectionData.strokes.empty.push(nodeData.id);
                        }
        
                        let hasEffect;
                        if (nodeData.effectStyleId) {
                            hasEffect = true;
                            if (FigmaStyleTokens[nodeData.effectStyleId] && FigmaStyleTokens[nodeData.effectStyleId].tokenId) {
                                const tokenId = FigmaStyleTokens[nodeData.effectStyleId].tokenId;
                                const token = Globals.ProjectManager.Tokens.Token(tokenId);
                                if (token) {                            
                                    let tokenItem = this.SelectionData.shadows.tokens[tokenId];
                                    if (!tokenItem) {
                                        tokenItem = GetShadowTokenItem({token : token});
                                        tokenItem.preview = {
                                            boxShadow : tokenItem.value
                                        }
                                        
                                        tokenItem.isAliase = token.aliase;
                                        this.SelectionData.shadows.tokens[tokenId] = tokenItem;
            
                                    }
                                    const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                    tokenNodes.push(nodeData.id);
                                }                        
                            }                                    
                        }
                        else {
                            if (nodeData.shadows && Array.isArray(nodeData.shadows) && nodeData.shadows.length > 0) {    
                                hasEffect = true;
                                const figmaShadows = this.props.GetPluginApi().ParseFigmaStyleShadows(nodeData);
                                const css = Utils.GetShadowCss(figmaShadows, false);
                                
                                const nodes = Utils.Get(this.SelectionData.shadows.styles, [], css, 'nodes');
                                Utils.Set(this.SelectionData.shadows.styles, {boxShadow : css}, css, 'preview');
                                Utils.Set(this.SelectionData.shadows.styles, figmaShadows, css, 'shadows');
                                nodes.push(nodeData.id);
                            }
                        }
                        if (!hasEffect) {
                            this.SelectionData.shadows.empty.push(nodeData.id);
                        }
        
                        if (nodeData.type === 'TEXT') {
                            if (nodeData.textStyleId) {
                                let isBoundToToken;
                                if (FigmaStyleTokens[nodeData.textStyleId] && FigmaStyleTokens[nodeData.textStyleId].tokenId) {
                                    const tokenId = FigmaStyleTokens[nodeData.textStyleId].tokenId;
                                    const token = Globals.ProjectManager.Tokens.Token(tokenId);
                                    if (token) {       
                                        isBoundToToken = true;                     
                                        let tokenItem = this.SelectionData.texts.tokens[tokenId];
                                        if (!tokenItem) {
                                            tokenItem = {
                                                name : token.name,
                                                preview : Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(token)
                                            }
                                            
                                            this.SelectionData.texts.tokens[tokenId] = tokenItem;
                
                                        }
                                        const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                        tokenNodes.push(nodeData.id);
                                    }                        
                                }     
                                
                                if (!isBoundToToken) {
                                    const localStyle = StyleIdMap[nodeData.textStyleId];
                                    if (localStyle) {       
                                        const figmaText = this.props.GetPluginApi().ParseFigmaLocalSyle_TextStyle(localStyle);
                                        const figmaStyleId = GetUniqueFigmaTextStyle(figmaText);

                                        if (!this.SelectionData.texts.styles[figmaStyleId]) {
                                            this.SelectionData.texts.styles[figmaStyleId] = {
                                                figmaText : figmaText,
                                                preview : GetFigmaTextStylePreview(figmaText),
                                                styleId : nodeData.fillStyleId,
                                                name : figmaText.name
                                            }                                            
                                        }
    
                                        const nodes = Utils.Get(this.SelectionData.texts.styles, [], figmaStyleId, 'nodes');                                    
                                        nodes.push(nodeData.id);
                                    }
                                }
                            }
                            else if (nodeData.textStyle) {
                                const figmaText = this.props.GetPluginApi().ParseFigmaLocalSyle_TextStyle(nodeData.textStyle);
                                    
                                const uniqueTextStyleId = GetUniqueFigmaTextStyle(figmaText);
                                const nodes = Utils.Get(this.SelectionData.texts.styles, [], uniqueTextStyleId, 'nodes');
                                Utils.Set(this.SelectionData.texts.styles, GetFigmaTextStylePreview(figmaText), uniqueTextStyleId, 'preview');
                                Utils.Set(this.SelectionData.texts.styles, figmaText, uniqueTextStyleId, 'figmaText');
                                nodes.push(nodeData.id);
                            }
    
                            let hasCustomContent = true;
                            if (nodeData.contentId) {
                                const token = Globals.ProjectManager.Tokens.Token(nodeData.contentId);
                                if (token) {
                                    hasCustomContent = false;
                                    let tokenItem = this.SelectionData.texts.contentTokens[nodeData.contentId];
                                    if (!tokenItem) {
                                        tokenItem = {
                                            name : token.name,
                                            value : Globals.ProjectManager.Tokens.ValueOf({model : token})
                                        }
                                        
                                        this.SelectionData.texts.contentTokens[nodeData.contentId] = tokenItem;
            
                                    }
                                    const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                    tokenNodes.push(nodeData.id);
                                }
                            }
                            if (hasCustomContent) {
                                const figmaContent = Utils.UseNullOrEmpty(nodeData.content, '');
                                const nodes = Utils.Get(this.SelectionData.texts.contents, [], figmaContent, 'nodes');
                                Utils.Set(this.SelectionData.texts.contents, figmaContent, figmaContent, 'value');
                                nodes.push(nodeData.id);
                            }                                                
                        }
    
                        [
                            {name : 'boolId', prop : 'booleans', label : 'VISIBILITY'},
                            {name : 'radiusId', prop : 'radiuses', label : 'RADII'},
                            {name : 'borderId', prop : 'borders', label : 'BORDERS'},
                            {name : 'iconId', prop : 'icons', label : 'ICONS'},
                            {name : 'spaceId', prop : 'spacings', label : 'SPACING'},
                            {name : 'layoutId', prop : 'autolayouts', label : 'AUTO LAYOUT'},
                        ].map((tokenProp) => {
                            const tokenId = nodeData[tokenProp.name];
                            if (tokenId) {
                                const token = Globals.ProjectManager.Tokens.Token(tokenId);    
                                if (token) {
                                    let tokenItem = this.SelectionData[tokenProp.prop][tokenId];
                                    if (!tokenItem) {
                                        tokenItem = {
                                            name : token.name,
                                            value : Globals.ProjectManager.Tokens.ValueOf({model : token})
                                        }
    
                                        if (tokenProp.prop === 'radiuses') {
                                            tokenItem.style = Utils.px( Utils.ToNumber(Utils.UseNull(tokenItem.value.value, 0)), tokenItem.value.Unit);
                                        }
                                        else if (tokenProp.prop === 'borders') {
                                            tokenItem.style = Utils.px( Utils.ToNumber(Utils.UseNull(tokenItem.value.value, 0)), tokenItem.value.Unit);
                                        }
                                        else if (tokenProp.prop === 'spacings') {
                                            tokenItem.isWidth = nodeData.spaceWidth;
                                            tokenItem.isHeight = nodeData.spaceHeight;
                                        }
                                        tokenItem.isAliase = token.aliase;
                                        this.SelectionData[tokenProp.prop][tokenId] = tokenItem;        
                                    }
                                    const tokenNodes = Utils.Get(tokenItem, [], 'nodes');
                                    tokenNodes.push(nodeData.id);
                                }
                            }
                        })
                    }                
                })
            }
            else {
                this.LocalStyleTokenMaps = {
                    colors : [],
                    gradients : [],
                    shadows : [],
                    texts : []
                };
                if (styles) {
                    if (styles.colors) {
                        styles.colors.map((localStyle) => {
                            const styleItem = this.props.GetPluginApi().ParseFigmaLocalSyle_Color(localStyle);
                            if (PluginApi.FigmaStyleTokens && PluginApi.FigmaStyleTokens[localStyle.sid] && PluginApi.FigmaStyleTokens[localStyle.sid].tokenId) {
                                styleItem.tokenId = PluginApi.FigmaStyleTokens[localStyle.sid].tokenId;
                            }
                            else {
                                this.LocalStyleTokenMaps.colors.push(styleItem);
                            }                            
                        })
                    }

                    if (styles.shadows) {
                        styles.shadows.map((localStyle) => {
                            const styleItem = this.props.GetPluginApi().ParseFigmaLocalSyle_Shadow(localStyle);
                            if (PluginApi.FigmaStyleTokens && PluginApi.FigmaStyleTokens[localStyle.sid] && PluginApi.FigmaStyleTokens[localStyle.sid].tokenId) {
                                styleItem.tokenId = PluginApi.FigmaStyleTokens[localStyle.sid].tokenId;
                            }
                            else {
                                this.LocalStyleTokenMaps.shadows.push(styleItem);
                            }                            
                        })
                    }

                    if (styles.texts) {
                        styles.texts.map((localStyle) => {
                            const styleItem = this.props.GetPluginApi().ParseFigmaLocalSyle_TextStyle(localStyle);
                            if (PluginApi.FigmaStyleTokens && PluginApi.FigmaStyleTokens[localStyle.sid] && PluginApi.FigmaStyleTokens[localStyle.sid].tokenId) {
                                styleItem.tokenId = PluginApi.FigmaStyleTokens[localStyle.sid].tokenId;
                            }
                            else {
                                this.LocalStyleTokenMaps.texts.push(styleItem);
                            }                            
                        })
                    }
                }
            }

            const DN = ReactDOM.findDOMNode(this.Ref_Loading.current);
            if (DN) DN.style.opacity = 0;

            if (!DoNotUpdate) {
                this.RCUpdate();
            } 
        });
                       
    }

    RenderFilterItem(item) {
        return (
            <SC.PopupItem style={{padding : 0, flex : 1}}>        
                <SC.FRow f1 alc jsb>
                    <span>{item.label}</span>                    
                    <NodeCount style={{marginRight : 0, marginLeft : '16px'}}>{this.SelectionData.itemCounts[item.id]}</NodeCount>
                </SC.FRow>
            </SC.PopupItem>
        )
    }
    
    SelectToken_Color(color, tokenId, nodes, isStroke) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'SELECT COLOR TOKEN',
            forAliase : false,
            defaultValue : color,
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    if (isStroke)
                        this.props.GetPluginApi().BindStrokeToken(newTokenId, nodes, () => this.Reload(true));
                    else
                        this.props.GetPluginApi().BindColorToken(newTokenId, nodes, () => this.Reload(true));
                    this.props.GetPluginApi().SendNotification('Color token applied!');
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }        
    EditSemanticToken(tokenId, type) {
        const aliase = GetTokenAliaseItem(tokenId);
        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <AliaseItemEditor 
                        id={tokenId}
                        type={type}
                        RefToolbar={this.props.RefToolbar}
                        aliase={aliase}
                        onCancelAddAliase={() => {this.props.onPanelOverlay({close : true});}}
                    />                 
                )
            }
        })

        // this.props.RefToolbar.current.ShowPanel({
        //     type : AppState.ItemTypes.BOARD.SEMANTICTOKEN,
        //     title : 'EDIT SEMANTIC TOKEN',
        //     justEditor : true,
        //     onSelect : (newTokenId) => {
                
        //     },
        //     onClosed : () => {

        //     },
        //     tokenId : tokenId,
        //     onPreviewNewToken : () => {
                
        //     }
        // })
    }
    EditToken_Color(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'EDIT COLOR TOKEN',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_TextStyle(defaultStyle, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.PATTERN_TEXT,
            title : 'SELECT TEXT STYLE',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindTextStyleToken(newTokenId, nodes, () => {
                        this.Reload(true);
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_TextStyle(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.PATTERN_TEXT,
            title : 'EDIT TEXT STYLE',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_ContentToken(defaultStyle, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.CONTENT_TEXT,
            title : 'SELECT TEXT CONTENT',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindTextContentToken(newTokenId, nodes, () => {
                        setTimeout(() => {
                            this.Reload(true);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_TextContent(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.CONTENT_TEXT,
            title : 'EDIT TEXT CONTENT',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_Shadow(shadows, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.SHADOW,
            title : 'SELECT SHADOW',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindShadowToken(newTokenId, nodes, () => this.Reload(true));
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_Shadow(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.SHADOW,
            title : 'EDIT SHADOW TOKEN',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_Radius(defaultStyle, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BORDER_RADIUS,
            title : 'SELECT RADIUS',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindCornerRadiusToken(newTokenId, nodes, () => {
                        setTimeout(() => {
                            this.Reload(false);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_Radius(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BORDER_RADIUS,
            title : 'EDIT RADIUS',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_Border(defaultStyle, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BORDER_STYLE,
            title : 'SELECT BORDER',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindBorderToken(newTokenId, nodes, () => {
                        setTimeout(() => {
                            this.Reload(false);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_Border(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BORDER_STYLE,
            title : 'EDIT BORDER',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    SelectToken_Boolean(defaultStyle, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BOOLEAN,
            title : 'SELECT VISIBILITY TOKEN',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindBooleanToken(newTokenId, nodes, () => {
                        setTimeout(() => {
                            this.Reload(false);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_Boolean(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.BOOLEAN,
            title : 'EDIT VISIBILITY',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            id : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }

    SelectToken_Spacing(isWidth, isHeight, tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.SPACING,
            title : 'SELECT SPACING',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindSpaceToken(newTokenId, isWidth, isHeight, nodes, () => {
                        setTimeout(() => {
                            this.Reload(false);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_Spacing(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.SPACING,
            title : 'EDIT SPACING',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }

    SelectToken_AutoLayout(tokenId, nodes) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.FIGMA_AUTOLAYOUT,
            title : 'SELECT AUTO LAYOUT',
            onSelect : (newTokenId) => {
                if (newTokenId) {
                    this.props.GetPluginApi().BindLayoutToken(newTokenId, nodes, () => {
                        setTimeout(() => {
                            this.Reload(false);
                        }, 200);                        
                    });                                    
                }
            },
            onClosed : () => {

            },
            propName : 'tokenId',
            tokenId : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    EditToken_AutoLayout(tokenId) {
        this.props.RefToolbar.current.ShowPanel({
            type : AppState.ItemTypes.BOARD.FIGMA_AUTOLAYOUT,
            title : 'EDIT AUTO LAYOUT',
            justEditor : true,
            onSelect : (newTokenId) => {
                
            },
            onClosed : () => {

            },
            id : tokenId,
            onPreviewNewToken : () => {
                
            }
        })
    }
    UseExistingColorToken(styleItem, nodes, tokenId) {
        if (styleItem.styleId && tokenId){
            this.props.GetPluginApi().BindTokenToSLocaltyle(styleItem.styleId, tokenId);
        }
        this.props.GetPluginApi().BindColorToken(tokenId, nodes, () => this.Reload());
        this.props.GetPluginApi().SendNotification('Color token applied!');
    }

    CreateNewColortoken(styleItem, value, nodes, isStroke, e) {
        e && e.stopPropagation();
        let tokenId = this.CreateColorToken(styleItem);

        if (tokenId) {
            if (styleItem.styleId){
                this.props.GetPluginApi().BindTokenToSLocaltyle(styleItem.styleId, tokenId);
            }
            if (isStroke) {
                this.props.GetPluginApi().BindStrokeToken(tokenId, nodes, () => {
                    this.props.GetPluginApi().SendNotification('Color token applied!');
                    this.Reload(true);
                });        
            }    
            else {
                this.props.GetPluginApi().BindColorToken(tokenId, nodes, () => {
                    this.props.GetPluginApi().SendNotification('Color token applied!');
                    this.Reload(true);
                });            
            }
        }
    }
    CreateColorToken(styleItem) {
        let tokenId;
        if (styleItem.type === TokenTypes.Gradients) {            
            tokenId = Globals.ProjectManager.Tokens.Add({
                type : Globals.ProjectManager.Tokens.Types.Gradients,
                name : Utils.UseNullOrEmpty(styleItem.name, 'New Gradient'),
                value : this.props.GetPluginApi().ConvertFigmaGradientValue(styleItem)
            });   
        }
        else {
            tokenId = Globals.ProjectManager.Tokens.Colors.Add({
                name : Utils.UseNullOrEmpty(styleItem.name, ChromaJs(styleItem.value).hex()),
                value : styleItem.value
            });
        }
        return tokenId;
    }
    CreateNewShadowToken(styleItem, nodes, e) {
        e && e.stopPropagation();

        styleItem.name = 'Shadow';
        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Shadow(styleItem);

        if (tokenId) {
            this.props.GetPluginApi().BindShadowToken(tokenId, nodes, () => {
                this.props.GetPluginApi().SendNotification('Shadow token applied!');
                this.Reload(true);
            });            
        }
    }
    CreateTextToken(tokenItem, nodes) {
        const tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Text(tokenItem.figmaText, tokenItem.figmaText.fontId);
        if (tokenId) {
            this.props.GetPluginApi().BindTextStyleToken(tokenId, nodes, () => {
                this.props.GetPluginApi().SendNotification('Text token applied!');
                this.Reload(true);
            });            
        }
    }
    CreateTextContentToken(tokenItem, nodes) {
        const tokenId = Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.ContentTexts,
            name : Utils.UseNullOrEmpty(tokenItem.name, Utils.UseNullOrEmpty(tokenItem.value, 'Empty').substr(0, 10)),
            value : tokenItem.value
        });   
        if (tokenId && nodes) {
            this.props.GetPluginApi().BindTextContentToken(tokenId, nodes, () => {
                this.props.GetPluginApi().SendNotification('Text token applied!');
                this.Reload(true);
            });            
        }
        return tokenId;
    }
    CreateTokensForAll(type, isStroke, e) {
        e && e.stopPropagation();
        this.IsSuspended = true;
        this.props.GetPluginApi().SilentBinding = true;
        try {
            if (type === TokenTypes.TextPatterns) {
                const ExistingTokens = this.GetExistingTokens(type);

                if (this.state.hasExistingTokens) {
                    
                }
                else {
                    let hasExistingTokens = false;                    
                    Utils.ForEach(this.SelectionData.texts.styles, (textItem, styleId) => {
                        if (ExistingTokens[styleId]) {
                            hasExistingTokens = true;
                            return false;
                        }             
                    });
    
                    if (hasExistingTokens) {
                        this.setState({hasExistingTokens : {
                            type : type
                        }});
                        return;
                    }
                }   

                Utils.ForEach(this.SelectionData.texts.styles, (tokenItem, styleId) => {
                    let tokenId;
                    if (this.ExistingTokensAction === 'attach') {
                        const existingText = ExistingTokens[styleId];
                        if (existingText) {
                            tokenId = existingText[0];
                        }                      
                    }
                    if (!tokenId) {
                        tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Text(tokenItem.figmaText, tokenItem.figmaText.fontId);
                    }

                    this.props.GetPluginApi().BindTextStyleToken(tokenId, tokenItem.nodes, () => {
                        console.log('BindResponse : 1');
                        this.props.GetPluginApi().GetSelection(() => {                    
                            console.log('BindResponse : Selected : 1');
                            this.Load(false, this.state);
                        })
                    });                                
                });
            }
            else if (type === TokenTypes.ContentTexts) {
                const ExistingTokens = this.GetExistingTokens(type);

                if (this.state.hasExistingTokens) {
                    
                }
                else {
                    let hasExistingTokens = false;                    
                    Utils.ForEach(this.SelectionData.texts.contents, (textItem, styleId) => {
                        if (ExistingTokens[styleId]) {
                            hasExistingTokens = true;
                            return false;
                        }             
                    });
    
                    if (hasExistingTokens) {
                        this.setState({hasExistingTokens : {
                            type : type
                        }});
                        return;
                    }
                }  

                Utils.ForEach(this.SelectionData.texts.contents, (tokenItem, styleId) => {
                    let tokenId;
                    if (this.ExistingTokensAction === 'attach') {
                        const existingText = ExistingTokens[styleId];
                        if (existingText) {
                            tokenId = existingText[0];
                        }                      
                    }
                    if (!tokenId) {
                        tokenId = this.CreateTextContentToken({value : styleId, name : tokenItem.name});
                    }

                    this.props.GetPluginApi().BindTextContentToken(tokenId, tokenItem.nodes);                                
                });
            }
            else if (type === TokenTypes.COLOR) {
                const colors = isStroke ? this.SelectionData.strokes.styles : this.SelectionData.colors.styles;

                const ExistingTokens = this.GetExistingTokens(type);
                
                if (this.state.hasExistingTokens) {
                    
                }
                else {
                    let hasExistingTokens = false;                    
                    Utils.ForEach(colors, (coloritem, color) => {
                        if (coloritem.type === 'Gradients') {

                        }
                        else {
                            if (ExistingTokens[ChromaJs(color).hex()]) {
                                hasExistingTokens = true;
                                return false;
                            }
                        }                        
                    });
    
                    if (hasExistingTokens) {
                        this.setState({hasExistingTokens : {
                            type : type,
                            isStroke : isStroke
                        }});
                        return;
                    }
                }                

                Utils.ForEach(colors, (tokenItem, styleId) => {
                    let tokenId;
                    if (this.ExistingTokensAction === 'attach') {
                        if (tokenItem.type === 'Gradients') {

                        }
                        else {
                            const existingColors = ExistingTokens[ChromaJs(tokenItem.value).hex()];
                            if (existingColors) {
                                tokenId = existingColors[0];
                            }
                        }                        
                    }
                    if (!tokenId) {
                        tokenId = this.CreateColorToken(tokenItem);
                    }
                    if (tokenItem.styleId) {
                        this.props.GetPluginApi().BindTokenToSLocaltyle(tokenItem.styleId, tokenId);
                    }
                    if (isStroke)
                        this.props.GetPluginApi().BindStrokeToken(tokenId, tokenItem.nodes);
                    else
                        this.props.GetPluginApi().BindColorToken(tokenId, tokenItem.nodes);
                });
            }
            else if (type === TokenTypes.Shadows) {
                const ExistingTokens = this.GetExistingTokens(type);

                if (this.state.hasExistingTokens) {
                    
                }
                else {
                    let hasExistingTokens = false;                    
                    Utils.ForEach(this.SelectionData.shadows.styles, (shadow, styleId) => {
                        if (ExistingTokens[styleId]) {
                            hasExistingTokens = true;
                            return false;
                        }             
                    });
    
                    if (hasExistingTokens) {
                        this.setState({hasExistingTokens : {
                            type : type
                        }});
                        return;
                    }
                }   

                Utils.ForEach(this.SelectionData.shadows.styles, (tokenItem, styleId) => {
                    let tokenId;
                    if (this.ExistingTokensAction === 'attach') {
                        const existingShadows = ExistingTokens[styleId];
                            if (existingShadows) {
                                tokenId = existingShadows[0];
                            }                      
                    }
                    if (!tokenId) {
                        tokenId = this.props.GetPluginApi().CreateTokenFromFigma_Shadow(tokenItem);
                    }
                    
                    this.props.GetPluginApi().BindShadowToken(tokenId, tokenItem.nodes);                
                });
            }
        } catch (error) {
            
        }

        setTimeout(() => {
            this.props.GetPluginApi().SilentBinding = false;
            this.IsSuspended = false;

            

            this.props.GetPluginApi().GetFigmaStyleTokenMap(({bindings, tokenMap}) => {
                this.props.GetPluginApi().FigmaTokenMap = tokenMap || {};
                this.props.GetPluginApi().FigmaStyleTokens = bindings || {};
                this.props.GetPluginApi().GetSelection(() => {                    
                    this.Load(false, this.state);
                })
            })
        }, 400);

        

        delete this.ExistingTokensAction;
        this.state.hasExistingTokens = false;        
    }

    onToggleExpand(prop, value) {
        this[prop] = !this[prop];
        this.RCUpdate();
    }
    DetachToken(tokenId, tokenType, nodes) {
        this.props.GetPluginApi().DetachToken(tokenId, nodes, () => {
            this.Reload();
        });        
    }
    SelectNodes(nodes, select) {
        this.props.GetPluginApi().SelectNodes(nodes, select);
    }
    
    DeleteNodes(nodes) {
        this.props.GetPluginApi().DeleteNodes(nodes);
    }
    ZoomToNodes(nodes) {
        this.props.GetPluginApi().ZoomToNodes(nodes);
    }
    ShowToolbarFor(id) {
        this.setState({showToolbarForId : id});
    }

    
    renderCustom() {

        if (this.props.GetPluginApi().SelectedNodeCount === 0) {            

            return (
                <UnboundFigmaLocalStyles 
                    GetPluginApi={this.props.GetPluginApi}
                    GetLocalStyleTokenMaps={() => {return this.LocalStyleTokenMaps}}
                    onLoad={this.Load}
                    onCreateColorToken={this.CreateColorToken}
                    RefToolbar={this.props.RefToolbar}
                />
            )
        }
        if (!this.tooManyStylesAccepted && this.props.GetPluginApi().SelectedNodeCount > 1000) {
            return (
                <SC.FCol style={{
                    backgroundColor : 'rgba(255, 235, 0, 0.54)',
                    margin : '10px',
                    padding : '16px',
                    alignItems : 'center',
                    textAlign : 'center',
                    lineHeight : '1.5em'
                }}>
                    Too many layer styles selected, it may take longer to process.
                    Do you want to continue?
                    <SC.Buttons.RoundButton style={{width : '140px', marginTop : '16px'}} xsmall onClick={() => {
                        this.tooManyStylesAccepted = true;
                        this.Reload();                        
                    }} >Continue</SC.Buttons.RoundButton>
                </SC.FCol>
            )
        }

        let content;
        const sharedProps = {
            hasEditGrant : this.props.hasEditGrant
        };

        if (this.SelectionData) {            
            let hasColorTokens, hasColors;
            const colorTokens = [];
            Utils.ForEach(this.SelectionData.colors.tokens, (tokenItem, tokenId) => {
                hasColorTokens = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                colorTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.COLOR}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Color.bind(this, null, tokenId, nodes, false)}
                        onEditToken={tokenItem.isAliase ? this.EditSemanticToken.bind(this, tokenId, TokenTypes.COLOR) : this.EditToken_Color.bind(this, tokenId)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}

                    />
                )
            });
            if (this.SelectionData.colors.empty.length > 0) {
                hasColorTokens = true;

                const nodes = this.SelectionData.colors.empty;
                colorTokens.splice(0, 0,
                    <TokenItem 
                        key='Empty'
                        empty
                        emptyTitle='No Fill'
                        tokenId={null}
                        type={TokenTypes.COLOR}                        
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Color.bind(this, null, null, nodes, false)}                        
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                    />
                )
            }
                

            const colorStyles = [];
            Utils.ForEach(this.SelectionData.colors.styles, (styleItem, rgba) => {
                const nodes = Utils.JustGet(styleItem, [], 'nodes');
                hasColors = true;
                colorStyles.push(
                    <NewTokenItem 
                        key={rgba}
                        id={rgba}
                        type={TokenTypes.COLOR}
                        styleItem={styleItem}
                        count={nodes.length}
                        GetExistingTokens={this.GetExistingTokens}
                        onSelectToken={this.SelectToken_Color.bind(this, styleItem.value, null, nodes, false)}
                        onNewToken={this.CreateNewColortoken.bind(this, styleItem, rgba, nodes, false)}
                        onUseToken={this.UseExistingColorToken.bind(this, styleItem, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        showToolbar={this.state.showToolbarForId === rgba}
                        onShowToolbar={this.ShowToolbarFor}
                        {...sharedProps}
                    />
                )
            });

            let hasStrokeTokens, hasStrokes;
            const strokeTokens = [];
            Utils.ForEach(this.SelectionData.strokes.tokens, (tokenItem, tokenId) => {
                hasStrokeTokens = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                strokeTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.COLOR}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Color.bind(this, null, tokenId, nodes, true)}
                        onEditToken={tokenItem.isAliase ? this.EditSemanticToken.bind(this, tokenId, TokenTypes.COLOR) : this.EditToken_Color.bind(this, tokenId)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                    />                    
                )
            });
            if (this.SelectionData.strokes.empty.length > 0) {
                hasStrokeTokens = true;

                const nodes = this.SelectionData.strokes.empty;
                strokeTokens.splice(0, 0,
                    <TokenItem 
                        key='Empty'
                        empty
                        emptyTitle='No Stroke'
                        tokenId={null}
                        type={TokenTypes.COLOR}                        
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Color.bind(this, null, null, nodes, true)}                        
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                    />
                )
            }

            const strokeStyles = [];
            Utils.ForEach(this.SelectionData.strokes.styles, (styleItem, rgba) => {
                const nodes = Utils.JustGet(styleItem, [], 'nodes');
                hasStrokes = true;
                strokeStyles.push(
                    <NewTokenItem 
                        key={rgba}
                        id={rgba}
                        type={TokenTypes.COLOR}
                        styleItem={styleItem}
                        count={nodes.length}
                        GetExistingTokens={this.GetExistingTokens}
                        onSelectToken={this.SelectToken_Color.bind(this, styleItem.value, null, nodes, true)}
                        onNewToken={this.CreateNewColortoken.bind(this, styleItem, rgba, nodes, true)}
                        onUseToken={(tokenId) => {
                            this.props.GetPluginApi().BindStrokeToken(tokenId, nodes, () => this.Reload());
                            this.props.GetPluginApi().SendNotification('Color token applied!');
                        }}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        showToolbar={this.state.showToolbarForId === rgba}
                        onShowToolbar={this.ShowToolbarFor}
                        {...sharedProps}
                    />
                )
            });
            
            let hasShadowTokens;
            const shadowTokens = [];
            Utils.ForEach(this.SelectionData.shadows.tokens, (tokenItem, tokenId) => {
                hasShadowTokens = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                shadowTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.Shadows}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Shadow.bind(this, null, tokenId, nodes)}
                        onEditToken={tokenItem.isAliase ? this.EditSemanticToken.bind(this, tokenId, TokenTypes.Shadows) : this.EditToken_Shadow.bind(this, tokenId)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                        
                    />
                )
            });
            if (this.SelectionData.shadows.empty.length > 0) {
                hasShadowTokens = true;

                const nodes = this.SelectionData.shadows.empty;
                shadowTokens.splice(0, 0,
                    <TokenItem 
                        key='Empty'
                        empty
                        emptyTitle='No Shadows'
                        tokenId={null}
                        type={TokenTypes.Shadows}                        
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Shadow.bind(this, null, null, nodes)}                        
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                    />
                )
            }

            let hasShadowStyles;
            const shadowStyles = [];
            Utils.ForEach(this.SelectionData.shadows.styles, (tokenItem, uniqueCss) => {
                hasShadowStyles = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                shadowStyles.push(
                    <NewTokenItem 
                        key={uniqueCss}
                        type={TokenTypes.Shadows}
                        styleItem={tokenItem}
                        count={nodes.length}
                        GetExistingTokens={this.GetExistingTokens}
                        onSelectToken={this.SelectToken_Shadow.bind(this, tokenItem.shadows, null, nodes)}
                        onNewToken={this.CreateNewShadowToken.bind(this, tokenItem, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        showToolbar={this.state.showToolbarForId === uniqueCss}
                        id={uniqueCss}
                        onShowToolbar={this.ShowToolbarFor}
                        onUseToken={(tokenId) => {
                            this.props.GetPluginApi().BindShadowToken(tokenId, nodes, () => this.Reload());
                            this.props.GetPluginApi().SendNotification('Shadow style token applied!');
                        }}
                        {...sharedProps}
                    />                    
                )
            });

            let hasTextTokens;
            const textTokens = [];
            Utils.ForEach(this.SelectionData.texts.tokens, (tokenItem, tokenId) => {
                hasTextTokens = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                textTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.TextPatterns}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_TextStyle.bind(this, null, tokenId, nodes)}
                        onEditToken={this.EditToken_TextStyle.bind(this, tokenId)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        {...sharedProps}
                    />
                )
            });
            let hasTextStyles;
            const textStyles = [];
            Utils.ForEach(this.SelectionData.texts.styles, (tokenItem, styleId) => {
                hasTextStyles = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                textStyles.push(
                    <NewTokenItem 
                        key={styleId}
                        type={TokenTypes.TextPatterns}
                        styleItem={tokenItem}
                        count={nodes.length}
                        GetExistingTokens={this.GetExistingTokens}
                        onSelectToken={this.SelectToken_TextStyle.bind(this, tokenItem.figmaText, null, nodes)}
                        onNewToken={this.CreateTextToken.bind(this, tokenItem, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        id={styleId}
                        showToolbar={this.state.showToolbarForId === styleId}                        
                        onShowToolbar={this.ShowToolbarFor}
                        onUseToken={(tokenId) => {
                            this.props.GetPluginApi().BindTextStyleToken(tokenId, nodes, () => this.Reload());
                            this.props.GetPluginApi().SendNotification('Text style token applied!');
                        }}
                        {...sharedProps}
                    />   
                )
            });

            let hasTextContentTokens = false;
            const textContentTokens = [];
            Utils.ForEach(this.SelectionData.texts.contentTokens, (tokenItem, tokenId) => {
                hasTextContentTokens = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                textContentTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.ContentTexts}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_ContentToken.bind(this, null, tokenId, nodes)}
                        onEditToken={this.EditToken_TextContent.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.ContentTexts, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let hasTextContents = false;
            const textContents = [];
            Utils.ForEach(this.SelectionData.texts.contents, (tokenItem, styleId) => {
                hasTextContents = true;

                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                textContents.push(
                    <NewTokenItem 
                        key={styleId}
                        type={TokenTypes.ContentTexts}
                        styleItem={tokenItem}
                        count={nodes.length}
                        GetExistingTokens={this.GetExistingTokens}
                        onSelectToken={this.SelectToken_ContentToken.bind(this, tokenItem.figmaText, null, nodes)}
                        onNewToken={this.CreateTextContentToken.bind(this, tokenItem, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        id={styleId}
                        showToolbar={this.state.showToolbarForId === styleId}
                        onShowToolbar={this.ShowToolbarFor}
                        {...sharedProps}
                    />   
                )
            });

            let hasRadii;
            const radiiTokens = [];
            Utils.ForEach(this.SelectionData.radiuses, (tokenItem, tokenId) => {
                hasRadii = true;
                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                radiiTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.BorderRadiuses}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Radius.bind(this, null, tokenId, nodes)}
                        onEditToken={tokenItem.isAliase ? this.EditSemanticToken.bind(this, tokenId, TokenTypes.BorderRadiuses) : this.EditToken_Radius.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.BorderRadiuses, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let hasBorder;
            const borderTokens = [];
            Utils.ForEach(this.SelectionData.borders, (tokenItem, tokenId) => {
                hasBorder = true;
                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                borderTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.Borders}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Border.bind(this, null, tokenId, nodes)}
                        onEditToken={tokenItem.isAliase ? this.EditSemanticToken.bind(this, tokenId, TokenTypes.Borders) : this.EditToken_Border.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.Borders, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let hasBoolean;
            const boolTokens = [];
            Utils.ForEach(this.SelectionData.booleans, (tokenItem, tokenId) => {
                hasBoolean = true;
                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                boolTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.Booleans}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Boolean.bind(this, null, tokenId, nodes)}
                        onEditToken={this.EditToken_Boolean.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.Booleans, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let hasSpacing;
            const spacingTokens = [];
            Utils.ForEach(this.SelectionData.spacings, (tokenItem, tokenId) => {
                hasSpacing = true;
                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                spacingTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.SpacePatterns}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_Spacing.bind(this, tokenItem.isWidth, tokenItem.isHeight, tokenId, nodes)}
                        onEditToken={this.EditToken_Spacing.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.Booleans, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let hasAutoLayouts;
            const autoLayoutTokens = [];
            Utils.ForEach(this.SelectionData.autolayouts, (tokenItem, tokenId) => {
                hasAutoLayouts = true;
                const nodes = Utils.JustGet(tokenItem, [], 'nodes');                
                autoLayoutTokens.push(
                    <TokenItem 
                        key={tokenId}
                        tokenId={tokenId}
                        type={TokenTypes.FigmaAutoLayouts}
                        styleItem={tokenItem}
                        count={nodes.length}
                        onSelectToken={this.SelectToken_AutoLayout.bind(this, tokenId, nodes)}
                        onEditToken={this.EditToken_AutoLayout.bind(this, tokenId)}
                        onDetachToken={this.DetachToken.bind(this, tokenId, TokenTypes.Booleans, nodes)}
                        onSelectItems={this.SelectNodes.bind(this, nodes)}
                        onDeleteItems={this.DeleteNodes.bind(this, nodes)}
                        onZoomToItems={this.ZoomToNodes.bind(this, nodes)}
                        GetPluginApi={this.props.GetPluginApi}
                        {...sharedProps}
                    />   
                )
            });

            let existingToolbar;            

            if (this.state.hasExistingTokens) {
                existingToolbar = (
                    <OutsideCloseDotMenuContainer onClose={() => {
                            this.setState({hasExistingTokens : false})
                        }}
                        style={{
                            left : 'unset',
                            right : 0,
                            top : '100%',
                            width : '240px',
                            paddingTop : '4px',
                            paddingBottom : '4px',
                            marginTop : '-4px',
                            padding : '8px'
                        }}
                    >
                        <div style={{
                            display : 'grid',
                            gridGap : '8px'
                        }}>
                            <div style={{padding : '8px', lineHeight : '1.5em', textAlign : 'center'}}>For styles having the same value with an existing token :</div>
                            <SC.Buttons.RoundButton xsmall onClick={() => {
                                this.ExistingTokensAction = 'attach';
                                this.CreateTokensForAll(this.state.hasExistingTokens.type, this.state.hasExistingTokens.isStroke);
                            }} >Attach to existing token</SC.Buttons.RoundButton>
                            {
                                this.props.hasEditGrant && 
                                <SC.Buttons.RoundButton xsmall onClick={() => {
                                    this.ExistingTokensAction = 'create';
                                    this.CreateTokensForAll(this.state.hasExistingTokens.type, this.state.hasExistingTokens.isStroke);
                                }}>Create new token</SC.Buttons.RoundButton>
                            }                            
                        </div>                        
                    </OutsideCloseDotMenuContainer>
                )
            }

            content = (
                <SC.FCol>
                    {
                        hasColorTokens &&
                        <TokenGroup
                            title='FILLS - TOKENS'
                            expandable={{expanded : !this.colorTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'colorTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct styles'>{colorTokens.length}</NodeCount>)}
                        >
                            {!this.colorTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>                                    
                                    {colorTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasColors &&
                        <TokenGroup
                            title='FILLS'
                            expandable={{expanded : !this.colorStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'colorStylesCollapsed')}}
                            addOn={this.colorStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>                                    
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={this.CreateTokensForAll.bind(this, TokenTypes.COLOR, false)}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct styles'>{colorStyles.length}</NodeCount>     */}
                                    {this.state.hasExistingTokens && this.state.hasExistingTokens.type === TokenTypes.COLOR && !this.state.hasExistingTokens.isStroke && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.colorStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {colorStyles}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    
                    {
                        hasStrokeTokens &&
                        <TokenGroup
                            title='STROKES - TOKENS'
                            expandable={{expanded : !this.strokeTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'strokeTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct styles'>{strokeTokens.length}</NodeCount>)}
                        >
                            {!this.strokeTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {strokeTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasStrokes &&
                        <TokenGroup
                            title='STROKES'
                            expandable={{expanded : !this.strokeStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'strokeStylesCollapsed')}}
                            addOn={this.strokeStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={this.CreateTokensForAll.bind(this, TokenTypes.COLOR, true)}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct styles'>{strokeStyles.length}</NodeCount>     */}
                                    {this.state.hasExistingTokens && this.state.hasExistingTokens.type === TokenTypes.COLOR && this.state.hasExistingTokens.isStroke && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.strokeStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {strokeStyles}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }

                    {
                        hasShadowTokens &&
                        <TokenGroup
                            title='SHADOWS - TOKENS'
                            expandable={{expanded : !this.shadowTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'shadowTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct styles'>{shadowTokens.length}</NodeCount>)}
                        >
                            {!this.shadowTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {shadowTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasShadowStyles &&
                        <TokenGroup
                            title='SHADOW EFFECTS'
                            expandable={{expanded : !this.shadowEffectsCollapsed,  onExpand : this.onToggleExpand.bind(this, 'shadowEffectsCollapsed')}}
                            addOn={this.shadowEffectsCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={this.CreateTokensForAll.bind(this, TokenTypes.Shadows, false)}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct styles'>{shadowStyles.length}</NodeCount>     */}
                                    {this.state.hasExistingTokens && this.state.hasExistingTokens.type === TokenTypes.Shadows && existingToolbar}
                                </SC.FRow>                                
                            )}
                        >
                            {!this.shadowEffectsCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {shadowStyles}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }

                    {
                        hasTextTokens &&
                        <TokenGroup
                            title='TEXT STYLES - TOKENS'
                            expandable={{expanded : !this.textTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'textTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct styles'>{textTokens.length}</NodeCount>)}
                        >
                            {!this.textTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {textTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasTextStyles &&
                        <TokenGroup
                            title='TEXT STYLES'
                            expandable={{expanded : !this.textStylesCollapsed,  onExpand : this.onToggleExpand.bind(this, 'textStylesCollapsed')}}
                            addOn={this.textStylesCollapsed ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={this.CreateTokensForAll.bind(this, TokenTypes.TextPatterns, false)}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct styles'>{textStyles.length}</NodeCount>     */}
                                    {this.state.hasExistingTokens && this.state.hasExistingTokens.type === TokenTypes.TextPatterns && existingToolbar}
                                </SC.FRow>                                
                            )}                           
                        >
                            {!this.textStylesCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {textStyles}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasTextContentTokens &&
                        <TokenGroup
                            title='TEXT CONTENTS - TOKENS'
                            expandable={{expanded : this.textContentTokensExpanded,  onExpand : this.onToggleExpand.bind(this, 'textContentTokensExpanded')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {this.textContentTokensExpanded && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {textContentTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasTextContents &&
                        <TokenGroup
                            title='TEXT CONTENTS'
                            expandable={{expanded : this.textContentsExpnaded,  onExpand : this.onToggleExpand.bind(this, 'textContentsExpnaded')}}
                            addOn={!this.textContentsExpnaded ? null : (
                                <SC.FRow alc style={{position : 'relative'}}>
                                    <SC.Icons.Icon_ButtonBox hasFill style={{marginRight : '4px'}} hasCursor onClick={this.CreateTokensForAll.bind(this, TokenTypes.ContentTexts, false)}>
                                        <div style={{paddingLeft : '4px'}}>Tokenize All</div>
                                        {/* <SC.Icons.Arrow_Back /> */}
                                    </SC.Icons.Icon_ButtonBox>                                                                        
                                    {/* <NodeCount title='Number of distinct texts'>{textContents.length}</NodeCount>     */}
                                    {this.state.hasExistingTokens && this.state.hasExistingTokens.type === TokenTypes.ContentTexts && existingToolbar}
                                </SC.FRow>                                
                            )}                           
                        >
                            {this.textContentsExpnaded && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {textContents}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasSpacing && 
                        <TokenGroup
                            title='SPACING TOKENS'
                            expandable={{expanded : !this.spacingTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'spacingTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {!this.spacingTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {spacingTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasAutoLayouts && 
                        <TokenGroup
                            title='AUTO LAYOUT TOKENS'
                            expandable={{expanded : !this.autoLayoutTokensCollapsed,  onExpand : this.onToggleExpand.bind(this, 'autoLayoutTokensCollapsed')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {!this.autoLayoutTokensCollapsed && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {autoLayoutTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasRadii && 
                        <TokenGroup
                            title='RADII TOKENS'
                            expandable={{expanded : this.radiiTokensExpanded,  onExpand : this.onToggleExpand.bind(this, 'radiiTokensExpanded')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {this.radiiTokensExpanded && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {radiiTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasBorder && 
                        <TokenGroup
                            title='BORDER TOKENS'
                            expandable={{expanded : this.borderTokensExpanded,  onExpand : this.onToggleExpand.bind(this, 'borderTokensExpanded')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {this.borderTokensExpanded && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {borderTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                    {
                        hasBoolean && 
                        <TokenGroup
                            title='VISIBILITY TOKENS'
                            expandable={{expanded : this.boolsTokensExpanded,  onExpand : this.onToggleExpand.bind(this, 'boolsTokensExpanded')}}
                            // addOn={(<NodeCount title='Number of distinct cotnents'>{textContentTokens.length}</NodeCount>)}
                        >
                            {this.boolsTokensExpanded && 
                                <SC.FCol style={{paddingLeft : '10px', paddingRight : '10px', paddingTop : '4px'}}>
                                    {boolTokens}
                                </SC.FCol>
                            }
                        </TokenGroup>
                    }
                </SC.FCol>
            )
        }        

        return (
            <SC.FCol style={{fontSize : '11px', fontWeight : 400, paddingTop : '10px'}}>
                <SC.AbsoluteOverlay ref={this.Ref_Loading} style={{...SC.Styles.Flex.Cell, transition : 'opacity 0.5s ease', opacity : 0, pointerEvents : 'none'}}>
                    <SC.Loading_Icon />
                </SC.AbsoluteOverlay>
                {
                    !this.props.GetOptions().selectionInfoClosed && 
                    <FigmaInfoPanel style={{margin : '10px', marginTop : '4px'}} onClose={() => {
                        this.props.GetOptions().SetOption('selectionInfoClosed', true);
                        this.RCUpdate();
                    }}>
                        <div style={{marginBottom  :'8px'}}>Distinct styles in your selection are listed below.</div> 
                        <div>Click on a style item and select a token which will replace all items in the selection with that style.</div>
                    </FigmaInfoPanel>
                }                
                <SC.FRow alc jsb style={{paddingLeft : '12px', marginBottom : '8px', paddingRight : '10px'}}>
                    <div style={{flex : 1}}>Filter Item Types</div>
                    <DropDownSelect 
                        items={[
                            {id : 'all', label : 'All'},
                            {id : 'text', label : 'Texts'},
                            {id : 'nontext', label : 'Non-text'},
                            {id : 'component', label : 'Components'},
                            {id : 'masters', label : 'Main Components'},
                            {id : 'instances', label : 'Component Instances'},
                        ]}
                        hasBorder            
                        autoHeight
                        xsmall                        
                        left='unset'
                        style={{flex : 1}}
                        itemStyle={{fontSize : '11px'}}
                        value={this.state.filterItemtype || 'all'}
                        onChange={(type) => this.setState({filterItemtype : type})}
                        renderItem={this.RenderFilterItem}
                    />
                </SC.FRow>
                {content}
                {false && JSON.stringify(this.props.GetPluginApi().SelectedNodes, null, 2)}
            </SC.FCol>
        )
    }
}

export const GetFigmaTextStylePreview = (figmaText) => {
    return {
        fontSize : Utils.px(figmaText.fontSize),
        fontFamily : figmaText.fontFamily,
        fontWeight : figmaText.fontWeight,
        lineHeight : figmaText.lineHeight ? Utils.px(figmaText.lineHeight) : 'auto',
        letterSpacing : Utils.px(figmaText.letterSpacing, figmaText.letterSpacingUnit)
    }
}