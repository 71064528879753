import React from 'react';
import {
    SC,
    Globals,
    Utils,
    ReactBaseComponent,
    AppState
} from '../../../../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import ColorTokenEditor from '../../../../left/designsystem/colors/color';
import { TokenPanelHeader, PanelHeaderAddButton, GroupTitle } from '../../../../left/designsystem/common';
import SelectToken from '..';

export default class SelectColorEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.EditColor = this.EditColor.bind(this);

        this.model = {
            value : {
                Default : {
                    value : this.props.color
                }
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.itemId !== nextProps.itemId) {
            this.model = {
                value : {
                    Default : {
                        value : nextProps.color
                    }
                }
            }   
        }
        return true;
    }
    EditColor(id, color, onChange) {
        this.showColorSelector = {
            type : AppState.ItemTypes.BOARD.COLOR,
            title : 'GRADIENT COLOR',
            noGradient : true,
            justEditor : true,
            itemId : id,
            color : color,
            onChange : onChange,            
            // onClosed : onClosed
        };            
        this.RCUpdate();
    }
    renderCustom() { 
        return (  
            <React.Fragment>
                <motion.div 
                    style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column', opacity : this.showColorSelector ? 0 : 1}}
                    initial={{opacity : 0.7, x : 24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : 24}}
                    transition={{duration : 0.2}}
                >
                    <TokenPanelHeader 
                        title={this.props.title || 'COLOR'} 
                        onClose={this.props.onClose} 
                        style={{padding : '2px'}}
                    />                           
                    <SC.CustomScrollbars>
                        <ColorTokenEditor 
                            ref={this.Ref_Editor}
                            onPreviewChange={this.props.onChange}
                            justEditor
                            id={this.props.tokenId}
                            itemId={this.props.itemId}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            StateArray={this.props.StateArray}
                            noGradient
                            newModel={this.props.tokenId ? null : this.model}
                            onEditColor={this.EditColor}
                        />
                    </SC.CustomScrollbars>
                </motion.div>
                {
                    this.showColorSelector &&
                    <motion.div 
                        style={{...SC.Styles.Absolute, zIndex : 1000000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                        initial={{opacity : 0.7, x : 24}}
                        animate={{opacity : 1, x : 0}}
                        exit={{opacity : 0, x : 24}}
                        transition={{duration : 0.2}}
                    >
                        <SC.FCol fw fh>
                            <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                                <SelectToken                                         
                                    onClose={this.CloseTokenSelector}
                                    onPreviewToken={this.PreviewToken}
                                    GlobalState={this.props.GlobalState}
                                    GlobalStateId={this.props.GlobalStateId}
                                    themeId={this.props.themeId}
                                    onSetPropertyValues={this.SetItemPropertyValues}
                                    onBindToModel={this.BindToModel}
                                    GetComponentManager={this.GetComponentManager}
                                    GetMetaItem={this.GetMetaItem}
                                    onClose={() => {
                                        delete this.showColorSelector;
                                        this.RCUpdate();
                                    }}
                                    onClosed={() => {                                            
                                    }}
                                    {...this.showColorSelector}
                                />
                            </SC.FCol>                    
                        </SC.FCol>
                    </motion.div>  
                }
            </React.Fragment>
            
        );
    }
}