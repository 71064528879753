import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    MetaData
} from '../../../../../../../importer';
import { GroupTitle, ItemNameEditor, TokenItemBox } from '../../common';
import TextInput from '../../../../../../../components/editors/textinput';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import NumberInput  from '../../../../../../../components/editors/input_number';
import { DataTypeList } from '../../../../../../../../toolabs-modules/toolabs-metaui/datatypes';
import ToolbarIcons from '../../../../../../../styled/toolbar';
import Slider from '../../../../../../../components/editors/slider';
import Switch from '../../../../../../../components/editors/Switch';
import { ImageBox } from '../../images';
import DataRowItem from './rowitem';

export default class DataPopulator extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
    }
    SelectValueType(field, category, id, label) {
        field.fakeType = {
            category : category,
            id : id,
            label : label
        };
        Globals.ProjectManager.Mockups.SaveField({id : field.id, prop : 'fake', value : field.fakeType});
        this.RCUpdate();
    }
    onChangeBoolRank(field, value) {
        field.fakeType = {
            rank : value
        }
        Globals.ProjectManager.Mockups.SaveField({id : field.id, prop : 'fake', value : field.fakeType});
        this.RCUpdate();
    }
    onChangeNumber(field, prop, value) {
        Utils.Set(field, value, 'fakeType', prop);
        Globals.ProjectManager.Mockups.SaveField({id : field.id, prop : 'fake', value : field.fakeType});
        this.RCUpdate();
    }
    renderFakeValueList(field) {
        if (field.dataType === MetaData.DataTypes.string) {
            const style_item = {
                textAlign : 'center'
            }
            return (
                <SC.FRow style={{fontSize : '11px', textAlign : 'center'}}>
                    <SC.FCol style={{borderRight : SC.CurrentTheme.theme.border_seperator, width : '100px'}}>
                        <FakeType title={MOCKUP_FAKETYPES.lorem.title} />
                        {
                            MOCKUP_FAKETYPES.lorem.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'lorem', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                        <FakeType title='DATE & TIME' />
                        {
                            MOCKUP_FAKETYPES.date.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'date', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>  
                    <SC.FCol style={{borderRight : SC.CurrentTheme.theme.border_seperator}}>
                        <FakeType title='NAMES' />
                        {
                            MOCKUP_FAKETYPES.name.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'name', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>       
                    <SC.FCol style={{borderRight : SC.CurrentTheme.theme.border_seperator}}>
                        <FakeType title='ADDRES' />
                        {
                            MOCKUP_FAKETYPES.address.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'address', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                        <FakeType title='PHONE' />
                        {
                            MOCKUP_FAKETYPES.phone.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'phone', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>
                    <SC.FCol style={{borderRight : SC.CurrentTheme.theme.border_seperator}}>
                        <FakeType title='COMMERCE' />
                        {
                            MOCKUP_FAKETYPES.commerce.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'commerce', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                        <FakeType title='INTERNET' />
                        {
                            MOCKUP_FAKETYPES.internet.fields.map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, 'internet', valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>   
                    <SC.FCol style={{borderRight : SC.CurrentTheme.theme.border_seperator}}>
                        <FakeType title='BUSINESS' />
                        {
                            [...MOCKUP_FAKETYPES.company.fields, ...MOCKUP_FAKETYPES.finance.fields].map((valueType, i) => {
                                return (
                                    <SC.PopupItem style={style_item} onClick={this.SelectValueType.bind(this, field, valueType.category, valueType.id, valueType.label)}>
                                        {valueType.label}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>   
                </SC.FRow>
            )
        }    
          
    }    
    MarkFieldToPopulate(field, checked) {
        field.keepOriginal = !checked;
        Globals.ProjectManager.Mockups.SaveField({id : field.id, prop : 'keepOriginal', value : field.keepOriginal});
        this.RCUpdate();
    }
    renderCustom() {
        const fieldMaps = [];

        Utils.ForEach(this.props.fields, (field, ) => {
            fieldMaps.push(
                <div>{field.name}</div>
            );
            let valueType = 'Select Fake Value Type';

            if (field.dataType === MetaData.DataTypes.string) {
                if (field.fakeType) {
                    valueType = field.fakeType.label; 
                }
    
                fieldMaps.push(
                    <DropDownSelect 
                        hasBorder            
                        autoHeight
                        xsmall
                        toRight
                        fitWidth
                        right='unset'
                        style={{flex : 1, minWidth : '120px'}}
                        getCustomContent={this.renderFakeValueList.bind(this, field)}
                        renderLabelItem={() => {
                            return (
                                <div style={{fontSize : '11px', paddingLeft : '4px', paddingRight : '4px'}}>
                                    {valueType}
                                </div>
                            )
                        }}
                    />
                )
            }
            else if (field.dataType === MetaData.DataTypes.image) {
                if (field.fakeType) {
                    valueType = field.fakeType.label; 
                }
                else    
                    valueType = '';

                fieldMaps.push(
                    <SC.FRow>
                        <TextInput 
                            placeholder='Search tag'
                            value={valueType}
                            onChange={this.SelectValueType.bind(this, field, 'filter', 'image')}
                        />
                    </SC.FRow>                    
                )
            }        
            else if (field.dataType === MetaData.DataTypes.boolean) {                
                fieldMaps.push(
                    <SC.FRow alc>
                        TRUE
                        <Slider 
                            style={{marginLeft : '4px', marginRight : '4px'}} 
                            value={Utils.Get(field.fakeType, 50, 'rank')} 
                            onChange={this.onChangeBoolRank.bind(this, field)}
                            themeId={AppLayout.ThemeId}
                        />
                        FALSE
                    </SC.FRow>
                )
            }
            else if (field.dataType === MetaData.DataTypes.number) {                
                fieldMaps.push(
                    <SC.FRow alc jsb>
                        <SC.FRow alc>
                            MIN
                            <NumberInput 
                                value={Utils.JustGet(field.fakeType, 0, 'min')} 
                                onChange={this.onChangeNumber.bind(this, field, 'min')}
                                boxStyle={{
                                    width : '58px',
                                    height : '24px',
                                    marginLeft : '4px',
                                    marginRight : '16px',
                                    backgroundColor : SC.CurrentTheme.theme.input_back
                                }}
                                style={{                                    
                                    width : '100%',
                                    textAlign : 'center',
                                    fontSize : '11px',                
                                    padding : 0
                                }}
                            />
                        </SC.FRow>
                        <SC.FRow alc>
                            MAX
                            <NumberInput 
                                value={Utils.JustGet(field.fakeType, 100, 'max')} 
                                onChange={this.onChangeNumber.bind(this, field, 'max')}
                                boxStyle={{
                                    width : '58px',
                                    height : '24px',
                                    marginLeft : '4px',
                                    backgroundColor : SC.CurrentTheme.theme.input_back
                                }}
                                style={{                                    
                                    width : '100%',
                                    textAlign : 'center',
                                    fontSize : '11px',                
                                    padding : 0
                                }}
                            />
                        </SC.FRow>
                       
                    </SC.FRow>
                )
            }            

            fieldMaps.push(
                <SC.FRow alc jsb>
                    <div style={{marginRight : '4px'}}>Populate</div>
                    <Switch value={!field.keepOriginal} onChange={this.MarkFieldToPopulate.bind(this, field)} round />
                </SC.FRow>
            )
        });

        return (
            <SC.FCol style={{alignSelf : 'flex-end'}}>
                <div
                    style={{
                        display : 'grid',
                        gridTemplateColumns : 'auto auto auto',
                        gridGap : '8px',
                        gridColumnGap : '16px',
                        alignItems : 'center',
                        fontSize : '11px'
                    }}
                >
                    {fieldMaps}
                </div>   
            </SC.FCol>
        )
    }
}

export const MOCKUP_FAKETYPES = {
    lorem : {
        title : 'LOREM IPSUM',
        fields : [
            {id : 'word', label : 'Word'},
            {id : 'words', label : 'Words'},
            {id : 'sentence', label : 'Sentence'},
            {id : 'slug', label : 'Slug'},
            {id : 'sentences', label : 'Sentences'},
            {id : 'paragraph', label : 'Paragraph'},
            {id : 'paragraphs', label : 'Paragraphs'},
            {id : 'text', label : 'Text'},
            {id : 'lines', label : 'Lines'},
        ]
    },
    date : {
        title : 'DATE & TIME',
        fields : [
            {id : 'past', label : 'Past'},
            {id : 'future', label : 'Future'},
            {id : 'recent', label : 'Recent'},
            {id : 'soon', label : 'Soon'},
            {id : 'month', label : 'Month'},
            {id : 'weekday', label : 'Weekday'}
        ]
    },
    name : {
        title : 'NAMES',
        fields : [
            {id : 'firstName', label : 'First Name'},
            {id : 'lastName', label : 'Last Name'},
            {id : 'fullName', label : 'Full Name'},
            {id : 'jobTitle', label : 'Job Title'},
            {id : 'prefix', label : 'Prefix'},
            {id : 'suffix', label : 'Suffix'},
            {id : 'title', label : 'Title'},
            {id : 'jobDescriptor', label : 'Job Descriptor'},
            {id : 'jobArea', label : 'Job Area'},
            {id : 'jobType', label : 'Job Type'}
        ]
    },
    address : {
        title : 'ADDRES',
        fields : [
            {id : 'zipCode', label : 'Zip Code'},
            {id : 'city', label : 'City'},
            {id : 'streetName', label : 'Street Name'},
            {id : 'streetAddress', label : 'Street Address'},
            {id : 'secondaryAddress', label : 'Secondary Address'},
            {id : 'county', label : 'County'},
            {id : 'country', label : 'Country'},
            {id : 'countryCode', label : 'Country Code'},
            {id : 'state', label : 'State'},
            {id : 'stateAbbr', label : 'State Abbr'},
            {id : 'latitude', label : 'Latitude'},
            {id : 'longitude', label : 'Longitude'} 
        ]
    },
    phone : {
        title : 'PHONE',
        fields : [
            {id : 'phoneNumber', label : 'Phone Number'},
            {id : 'phoneNumberFormat', label : 'Phone Number Format'},
            {id : 'phoneFormats', label : 'Phone Format'}
        ]
    },
    commerce : {
        title : 'COMMERCE',
        fields : [
            {id : 'department', label : 'Department'},
            {id : 'productName', label : 'Product Name'},
            {id : 'price', label : 'Price'},
            {id : 'productAdjective', label : 'Product Adjective'},
            {id : 'productMaterial', label : 'Product Material'},
            {id : 'product', label : 'Product'}
        ]
    },
    internet : {
        title : 'INTERNET',
        fields : [
            {id : 'email', label : 'Email'},
            {id : 'exampleEmail', label : 'Example Email'},
            {id : 'userName', label : 'Username'},
            {id : 'password', label : 'Password'},
            {id : 'url', label : 'Url'},
            {id : 'domainName', label : 'Domain Name'},
            {id : 'ip', label : 'IP'},
            {id : 'ipv6', label : 'IPv6'},
            {id : 'mac', label : 'MAC Address'}
        ]
    },
    company : {
        title : 'COMPANY',
        fields : [
            {id : 'companyName', label : 'Company Name', category : 'company'},
            {id : 'catchPhrase', label : 'Catch Phrase', category : 'company'},
            {id : 'bs', label : 'Businesss Field', category : 'company'},
            {id : 'bsNoun', label : 'Business Noun', category : 'company'},
        ]
    },
    finance : {
        title : 'BUSINESS',
        fields : [
            {id : 'account', label : 'Account', category : 'finance'},
            {id : 'accountName', label : 'Account Name', category : 'finance'},
            {id : 'currencyName', label : 'Currency Name', category : 'finance'},
            {id : 'currencySymbol', label : 'Currency Symbol', category : 'finance'},
            {id : 'bitcoinAddress', label : 'Bitcoin Address', category : 'finance'},
            {id : 'iban', label : 'IBAN', category : 'finance'}
        ]
    }
}

const FakeType = ({title}) => (
    <div style={{padding  :'6px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
        {title}
    </div>
)