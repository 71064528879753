import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils
} from '../../../importer';
import styled, {css} from 'styled-components';

import MountAnimator from '../../../components/Hoc_AnimatedMounter';
// import { SetConfig } from '../tokens/token_types';
import LightDarkBar from '../../../components/light_dark_bar';
import Slider from '../../../components/editors/slider';

const SetConfig = () => {
    // TODO
}


const InnerDeleteSelection = (props) => (
    <SC.FRow alc className={props.className}>
        <div>{props.label}</div>
        <SC.Icons.Icon_Button hasFill style={{padding : '8px', cursor : 'pointer'}} onClick={props.onDeleteSelection}>
            <SC.Icons.Icon_Delete size={20} />
        </SC.Icons.Icon_Button>
    </SC.FRow>
);

const DeleteSelection = MountAnimator(InnerDeleteSelection);

export default class SearchBar extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.CancelSearch = this.CancelSearch.bind(this);
        this.Search = this.Search.bind(this);
        this.SetSortMode = this.SetSortMode.bind(this);

    }
    SetSortMode(e) {
        e && e.stopPropagation();
        const SortMode = ((this.props.SortMode || 0) + 1) % 3;
        this.props.onSetSortMode && this.props.onSetSortMode(SortMode);
    }
    CancelSearch() {
        this.Search('');
    }
    Search(s) {
        this.props.onSearch && this.props.onSearch(s);
    }
    UpdateSelection(selection) {
        this.selection = selection;
        this.RCUpdate();
    }
    UpdateFilterMessage(filterMessage) {
        this.filterMessage = filterMessage;
        this.RCUpdate();
    }
    renderCustom() {

        let selection_bar;

        if (this.selection) {
            selection_bar = (
                <SC.FRow alc>
                    <SC.SortAndFilter.StylePanel_Total_Border />
                    <DeleteSelection
                        label={this.selection.label}
                        onDeleteSelection={this.props.onDeleteSelection}
                    />    
                </SC.FRow>                
            )
        }

        let viewStates;

        const {viewStateOptions} = this.props;
        if (viewStateOptions) {
            viewStates = [];
            if (viewStateOptions.lightDark) {
                viewStates.push(
                    <LightDarkBar 
                        key='lightdark'
                        Dark={viewStateOptions.Dark}
                        medium
                        onChange={(e) => {
                            e && e.stopPropagation();
                            viewStateOptions.Dark = !viewStateOptions.Dark;
                            SetConfig(viewStateOptions.module, 'DL', viewStateOptions.Dark);
                            viewStateOptions.onChange();
                        }}
                    />
                )
            }
            if (viewStateOptions.sliderSize) {
                if (viewStates.length > 0)
                    viewStates.push(<SC.SortAndFilter.StylePanel_Total_Border key='sliderborder' style={{marginLeft : '8px', marginRight : '12px'}}/>);
                viewStates.push(
                    <div style={{width : '140px', marginRight : '4px'}} key='slider'>
                        <Slider
                            small
                            min={20}
                            max={100}
                            handleBack={SC.CurrentTheme.theme.back_lighter}
                            value={viewStateOptions.sliderSize.value}
                            onChange={viewStateOptions.sliderSize.onChange}
                            onChanging={viewStateOptions.sliderSize.onChanging}
                        />
                    </div>
                )
                
            }
            if (viewStateOptions.modes) {
                if (viewStates.length > 0)
                    viewStates.push(<SC.SortAndFilter.StylePanel_Total_Border key='modeborder' style={{marginLeft : '8px', marginRight : '8px'}}/>);

                if (viewStateOptions.modes.list) {
                    viewStates.push(
                        <SC.Div_Flex_Cell title='List view' key='list' pr={2} cursorPointer onClick={(e) => {
                            e && e.stopPropagation();
                            SetConfig(viewStateOptions.module, 'VM', 0);
                            viewStateOptions.onChange();
                        }}>
                            <SC.Icons.Icon_List_View selected={viewStateOptions.viewMode  === 0} />
                        </SC.Div_Flex_Cell>
                    )
                }
                if (viewStateOptions.modes.small) {
                    viewStates.push(
                        <SC.Div_Flex_Cell title='List view' key='small' pr={2} cursorPointer onClick={(e) => {
                            e && e.stopPropagation();
                            SetConfig(viewStateOptions.module, 'VM', 1);
                            viewStateOptions.onChange();
                        }}>
                            <SC.Icons.Icon_Color_Medium_View selected={viewStateOptions.viewMode  === 1} />
                        </SC.Div_Flex_Cell>
                    )
                }
                if (viewStateOptions.modes.medium) {
                    viewStates.push(
                        <SC.Div_Flex_Cell title='List view' key='medium' pr={2} cursorPointer onClick={(e) => {
                            e && e.stopPropagation();
                            SetConfig(viewStateOptions.module, 'VM', 2);
                            viewStateOptions.onChange();
                        }}>
                            <SC.Icons.Icon_Card_View selected={viewStateOptions.viewMode  === 2} />
                        </SC.Div_Flex_Cell>
                    )
                }
            }
            viewStates = (
                <SC.FRow alc >
                    {viewStates}
                </SC.FRow>
            )
        }
        let leftBar;
        let filterMessages;
        let sorting;
        
        if (!this.props.noOptions) {
            leftBar = (
                <SC.FRow alc style={{paddingLeft : '8px'}}>           
                    {viewStates}                             
                </SC.FRow>
            );

            if (!this.props.noSorting) {
                sorting = (
                    <React.Fragment>
                        <SC.Div_Flex_Cell title='Change sort order' mr={10} cursorPointer onClick={this.SetSortMode} >
                            {
                                this.props.SortMode ? (
                                    <SC.Icons.Icon_Sort_AZ size={20} selected SortUp={this.props.SortMode === 2}/>
                                ) :
                                <SC.Icons.Icon_Sort_AZ size={20} />
                            }
                        </SC.Div_Flex_Cell>
                        <SC.SortAndFilter.StylePanel_Total_Border />
                    </React.Fragment>
                )
            }

            if (!this.props.noFilterMessage) {
                filterMessages = (
                    <React.Fragment>
                        <SC.SortAndFilter.StylePanel_Total_Border style={{marginLeft : '8px'}} />
                        <SC.SortAndFilter.StylePanel_TotalMessage>{this.filterMessage}</SC.SortAndFilter.StylePanel_TotalMessage>
                        {selection_bar}
                    </React.Fragment>   
                )
            }
        }
        

        if (this.props.leftBar) {
            leftBar = (
                <SC.FRow alc>
                    <SC.FRow>
                        {this.props.leftBar}
                    </SC.FRow>
                    {
                        leftBar ? 
                        <React.Fragment>
                            <SC.SortAndFilter.StylePanel_Total_Border style={{marginRight : 0, marginLeft : '8px'}} />
                            {leftBar}
                        </React.Fragment> : null
                    }                                        
                </SC.FRow>
            )
        }
        
        
        return (
            <SC.FRow jsb f1>                                
                <SC.FRow alc>         
                    {sorting}
                    {this.props.rightBarAction}
                    {
                        this.props.hasTags && 
                        <SC.Div_Flex_Cell title='Filter by Tags' cursorPointer style={{paddingRight : '8px', paddingLeft : '8px'}} onClick={this.ToggleTagFilter} >
                            <SC.Icons.Icon_TagFilter selected={this.TagFilterVisible} size={22}/>
                        </SC.Div_Flex_Cell>
                    }                    
                    <SearchInput
                        short={this.props.short}
                        hidden={this.props.searchHidden}
                        SearchHint={this.props.SearchHint}
                        FilterText={this.props.FilterText}
                        Search={this.Search}
                        CancelSearch={this.CancelSearch}
                        small={this.props.small}                        
                    />   
                    {filterMessages}                                                
                </SC.FRow>
                {leftBar}
            </SC.FRow>
        )
    }
}

export class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const style = {...this.props.style};
        if (this.props.hidden)
            style.visibility = 'hidden';
        if (this.props.small)
            style.height = '28px';
        if (this.props.full)
            style.width = '100%';
        return (
            <SearchBox style={style} small={this.props.small} short={this.props.short} dark={this.props.dark} expanded={this.state.SearchHasFocus || Utils.IsNotNullOrEmpty(this.props.FilterText)} >
                <SC.Icons.Icon_Button hasFill style={{...SC.Styles.Flex.Cell, paddingLeft : '10px', marginRight : '10px'}}>
                    <SC.Icons.Icon_Search size={16} />
                </SC.Icons.Icon_Button>
                <UIUtils.Utils.SearchInput
                    InputType={SearchInputStyle}
                    placeholder={this.props.SearchHint || 'Search by name'}
                    value={this.props.FilterText || ''}
                    onSearch={this.props.Search}
                    onCancel={this.props.CancelSearch}
                    debounce
                    hasClose
                    hasFullClose={this.props.hasFullClose}
                    autoFocus={this.props.autoFocus}
                    closeSize={18}
                    onFocus={() => { AppState.Designer.SetEditingText('search bar');; this.setState({SearchHasFocus : true}); }}
                    onBlur={() => { AppState.Designer.EditingText = false; this.setState({SearchHasFocus : false}); }}
                />
            </SearchBox>
        )
    }
}

const SearchBox = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : stretch;
    transition : 0.2s ease;
    transition-property : width, background-color;
    background-color : ${props => props.theme.isLight ? (props.theme.back_lighter) : (props.dark ? props.theme.back_lighter : props.theme.back_lighter)};
    padding : 6px;
    padding-top : ${props => props.small ? 0 : 6}px;
    padding-bottom : ${props => props.small ? 0 : 6}px;
    box-sizing : border-box;
    padding-left : 0;
    border-radius : 22px;
    border : ${props => props.theme.isLight ? '1px solid #f0f0f000' : props.theme.border_seperator};
    &:hover {
        background-color : ${props => props.theme.isLight ? props.theme.card_back_hover : props.theme.back_lightest};
        border : ${props => props.theme.isLight ? props.theme.border_ondark : props.theme.border_seperator};
    }
    ${
    props => {
        if (props.expanded)
            return css`
                width : ${props => props.short ? 280 : 360}px;
                background-color : ${props => props.theme.isLight ? props.theme.card_back_hover : props.theme.back_lightest};
                border : ${props => props.theme.isLight ? props.theme.border_ondark : props.theme.border_seperator};
            `;
        else
            return css`width : 200px;`;
    }
    }
`;

const SearchInputStyle = styled.input`
    background-color : transparent;
    box-sizing : border-box;
    line-height : 20px;
    border : none;
    flex : 1;
    align-self : stretch;
    font-size : 12px;
    color : #a5a5a5;
    letter-spacing : 0.53px;
    ${props => {
        if (!Utils.IsNotNullOrEmpty(props.value))
            return css`font-style : italic;  color : #6c6d70;`;
    }}
`;
