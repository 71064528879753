import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout
} from '../../importer';

import styled, {css} from 'styled-components';
import Input_Name from '../../components/editors/input_name';
import Input_Note from '../../components/editors/input_note';
import MountAnimator from '../../components/Hoc_AnimatedMounter';

class ProjectCard extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.ToBoard = this.ToBoard.bind(this);
        this.onClone = this.onClone.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onChangeNote = this.onChangeNote.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.Restore = this.Restore.bind(this);        
    }
    onCancel() {

    }
    onSubmit() {
        this.props.model.name = this.Ref_Name.GetValue();
        AppState.Data.Board.ChangeProp(this.props.model.id, 'Name', this.props.model.name);
    }
    onChangeNote(note) {
        this.props.model.Note = note;
        AppState.Data.Board.ChangeProp(this.props.model.id, 'Note', note);
    }
    onClone() {
        this.props.onClone(this.props.model.id);
    }
    onDelete() {
        this.props.onDelete(this.props.model.id);
    }
    ToBoard() {
        super.RedirectTo(Links.Board_Edit(this.props.model.id, this.props.admin));
    }
    Restore() {
        AppState.Data.Board.RestoreDeleted(this.props.model.id);
        delete this.props.model.deleted;
        this.props.onRestored();
    }
    renderCustom() {
        const {model} = this.props;

        return (
            <ProjectCardBox className={this.props.className}>
                <SC.FCol>
                    <Input_Name
                        ref={(r) => this.Ref_Name = r}
                        InputType={Input_ProjectName}
                        required
                        value={model.name || ''}
                        styleProps = {{
                            placeholder : 'Project Name',
                            onBlur : this.onSubmit
                        }}
                        onValidate={this.props.ValidateName}
                        onCancel={this.Cancel}
                        onSubmit={this.onSubmit}
                        showError
                    />
                    <SC.FRow>
                            <SC.Div_Styled mr={24} style={{color:SC.CurrentTheme.theme.font, fontWeight:300}}>
                                Created on <span style={{color:SC.CurrentTheme.theme.font, fontWeight:400}}>{this.props.model.dateCreated}</span>
                            </SC.Div_Styled>
                            <SC.Div_Styled style={{color:SC.CurrentTheme.theme.font,fontWeight:300}}>
                                Time spent <span style={{color:SC.CurrentTheme.theme.font,fontWeight:400}}>0 hours</span>
                            </SC.Div_Styled>
                    </SC.FRow>
                    <SC.FRow style={{marginTop : '12px'}}>
                        <Input_Note wrap value={Utils.Get(this.props.model, '', 'Note')} onChange={this.onChangeNote} />
                    </SC.FRow>
                </SC.FCol>
                <SC.FRow jsb style={{marginTop : '24px'}}>
                    <SC.FRow>
                        <SC.Buttons.RoundButton small onDark style={{width : '32px', marginLeft : 0}} onClick={this.onClone}>
                            <SC.Icons.Icon_Clone />
                        </SC.Buttons.RoundButton>
                        <SC.Buttons.RoundButton small onDark style={{width : '32px', marginLeft : '8px'}} onClick={this.onDelete}>
                            <SC.Icons.Icon_Delete />
                        </SC.Buttons.RoundButton>
                    </SC.FRow>
                    {
                        this.props.isDeleted ?
                        <SC.Buttons.RoundButton small onDark style={{width : '120px'}} onClick={this.Restore}>
                            Restore
                        </SC.Buttons.RoundButton> : 
                        <Links.Link To={Links.Board_Edit(model.id)}>
                            <SC.Buttons.RoundButton small onDark style={{width : '100px'}}>
                                Edit
                            </SC.Buttons.RoundButton>
                        </Links.Link>
                    }                    
                </SC.FRow>
            </ProjectCardBox>
        )

    }
}

export const ProjectCardBox = styled.div`
    display : flex;
    flex-direction : column;
    justify-content : space-between;
    align-items : stretch;
    padding : 24px;
    border-radius : 8px;
    min-height : 240px;
    height : 100%;
    box-sizing : border-box;
    background-color : ${props => props.theme.back_lighter};
    box-shadow : rgba(0,0,0,0.3) 0px 2px 12px 0px;
    &:hover {

    }
    ${
        props => {
            if (AppLayout.Theme.Current === 'Light') {
                return css`
                    box-shadow : 3px 8px 18px rgba(0,0,0,0.18);
                `;
            }
        }
    }
`;

export const Input_ProjectName = styled(SC.Card_Input) `
    font-size : 20px;
    padding-left : 0px;
    margin-bottom : 16px;
    color : ${props => props.theme.text_card_title};
    font-weight : ${props => props.theme.font_weight};
    border-bottom : 1px solid ${props => props.theme.border_onlight};
`;

export default  MountAnimator(ProjectCard);
