import React from 'react';
import {
    SC,
    Globals,
    Utils,
    AppLayout
} from '../../../../../../../importer';

import BorderStyleEditor from '../../../../left/designsystem/borders/border';
import { BorderStyleTokenItem, GetBorderStyleTokenList, GetBorderStyleTokenValue } from '../../../../left/designsystem/borders';
import BaseSelectToken from '../BaseSelectToken';
import BorderStyleItem, {BorderSideSelector, StyleTokenBorderStyle} from '../../styleitems/borders/indexold';
import { ToggleButton, Seperator } from '../../styleitems/common';

export default class SelectBorderStyle extends BaseSelectToken
{
    constructor(props) {
        super(props);

        this.titleNewToken = 'NEW BORDER';
        this.moduleName = 'BorderStyle';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Borders;        
        this.hasCustomTokenList = true;
        this.state.isListView = false;
        this.DoNotCloseOnSave = true;

        this.Load(this.props);
        
        this.StyleValues = {};
        
        this.SelectedSide = this.props.side;
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.propName !== nextProps.propName ||  this.props[this.props.propName] !== nextProps[this.props.propName];
    }
    GetTokens(props) {
        return GetBorderStyleTokenList();
    }        
    GetNewModel(Id) {
        const item = {
            type : Globals.ProjectManager.Tokens.Types.Borders,
            name : 'New Border',            
            id : this.EditingToken.Id
        };
        Globals.ProjectManager.Tokens.Add(item);
        this.FireSelectToken(Id);
        return item;
    }   
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();        
        Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Borders, willDeleteId);
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {           
            Globals.ProjectManager.Tokens.SetValue({id : this.EditingToken.Id, value : this.EditingToken.Model.value, type : Globals.ProjectManager.Tokens.Types.Borders});          
            AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
            this.Load();
            return {
                type : Globals.ProjectManager.Tokens.Types.Borders,
                name : this.EditingToken.Model.name,
                value : this.EditingToken.Model.value,
                id : this.EditingToken.Id
            };
        }        
        else {
        }                
    }   
    UpdateSelectedToken() {
        const SelectedToken = Utils.Find(this.tokens, (token) => {return token.id === this.EditingToken.Id});        
        const token = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
        const item = GetBorderStyleTokenValue(token, this.EditingToken.Id, this.props.StateArray);
        SelectedToken.width = item.width;
        SelectedToken.style = item.style;
        AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
    }
    GetStyleName() {
        return Utils.BorderStyles.propStyle(this.SelectedSide);
    }
    GetSelectedTokenId() {
        if (this.props.propName === 'tokenId')
            return this.props.tokenId;
        return Utils.JustGet(this.StyleValues, null, Utils.UseNullOrEmpty(this.SelectedSide, 'All'));
    }
    renderTokenList() {        
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                {
                    this.tokens.length > 0 && 
                    <SC.FCol style={{fontSize : '11px'}}>                        
                        {
                            this.tokens.map((border, i) => {
                                return (
                                    <BorderStyleTokenItem
                                        key={border.id}
                                        border={border}
                                        isListView={this.state.isListView}
                                        onSelect={this.SelectToken.bind(this, border.id)}
                                        selected={border.id === this.GetSelectedTokenId()}
                                        onPreview={{
                                            onMouseEnter : this.PreviewToken.bind(this, true, border.id, this.props.propName),
                                            onMouseLeave : this.PreviewToken.bind(this, false, border.id, this.props.propName)
                                        }}
                                    />   
                                )
                            })
                        }
                    </SC.FCol>
                }     
            </SC.CustomScrollbars> 
        )       
    }
    renderTokenEditor() {
        return (
            <BorderStyleEditor 
                id={this.EditingToken.Id}
                newModel={this.EditingToken.IsNew ?  this.EditingToken.Model : null}  
                onClose={this.onCancelAddToken}
                onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
            />                
        )        
    }   
}