import React from 'react';
import {
    SC,
    Utils
} from '../importer';

export const InfoPanel = ({warning, success, error, style, onClose,  ...rest}) => {
    let close;
    if (onClose) {
        close = (
            <SC.Icons.Icon_Button hasFill style={{
                position : 'absolute',
                top : '4px',
                right : '4px',
                cursor : 'pointer',
                padding : '4px'
            }} onClick={onClose}>
                <SC.Icons.Icon_Close size={16}/>
            </SC.Icons.Icon_Button>
        )
    }
    return (
        <div style={{
            padding : '8px',
            backgroundColor : SC.CurrentTheme.theme.back_lighter,
            lineHeight : '16px',
            marginBottom  :'8px',
            borderLeft : success ? SC.CurrentTheme.theme.borderline_selected :  ( error ? '1px solid #ff4343' : '1px solid #0096ea'),
            borderRadius : '2px',
            position : 'relative',
            color : SC.CurrentTheme.theme.font,
            ...style
        }}>                                                
            {close}
            {rest.children}                            
        </div>
    )
}

export const InfoLine = (props) => <div style={{marginBottom : !props.last ? '4px' : 0}}>{props.children}</div>