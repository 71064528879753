import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    MetaData    
} from '../../../../../../../../../importer';

import styled from 'styled-components';
import NumberInput from '../../../../../../../../../components/editors/input_number';
import ItemSizer from './itemsizer';
import { DotMenu } from '../../../../../../left/common';

export default class GridRowColumnEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);
        this.Delete = this.Delete.bind(this);
        this.Clone = this.Clone.bind(this);
        this.DeleteItem = this.DeleteItem.bind(this);
        this.CloneItem = this.CloneItem.bind(this);        
        this.ChangeColumnType = this.ChangeColumnType.bind(this);
        this.AddItem = this.AddItem.bind(this);
        this.onSortColumns = this.onSortColumns.bind(this);
        this.ChangeColumnRepeatType = this.ChangeColumnRepeatType.bind(this);
        this.ToggleHidden = this.ToggleHidden.bind(this);
        this.ShowMenu = this.ShowMenu.bind(this);
    }
    ShowMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        this.RCUpdate();
    }
    onSortColumns(oldIndex, newIndex) {
        const columns = Utils.Get(this.props.data, [], 'Repeat', 'Items');
        Utils.ChangePlace(columns, oldIndex, newIndex);        
        this.props.onSave();
        this.RCUpdate();
    }
    ChangeColumnType(type) {
        const {data} = this.props;

        if (type === 'Repeat') {
            data.Repeat = {
                Items : [data.Single]
            }
            delete data.Single;
        }
        else {
            if (data.Repeat && data.Repeat.Items.length > 0) {
                data.Single = data.Repeat.Items[0];
            }
            else {
                data.Single = {
                    size : {
                        unit : 'fr',
                        value : 1
                    }
                };
            }
            delete data.Repeat;
        }
        this.props.onSave();
        this.RCUpdate();
    }
    ToggleHidden() {
        this.isMenuOpen = false;
        Utils.Set(this.props.data, !Utils.JustGet(this.props.data, false, 'hidden'), 'hidden');
        this.props.onSave();
        this.RCUpdate();
    }
    Clone() {
        this.isMenuOpen = false;
        this.props.onClone(this.props.index);
    }
    Delete() {
        this.isMenuOpen = false;
        this.props.onDelete(this.props.index)
    }
    AddItem() {
        const {data} = this.props;
        const columns = Utils.Get(data, [], 'Repeat', 'Items');
        columns.push({
            Id : Utils.Id(),
            size : {
                unit : 'fr',
                value : 1
            }
        });
        this.props.onSave();
        this.RCUpdate();
    }
    DeleteItem(index) {
        const {data} = this.props;
        const columns = Utils.Get(data, [], 'Repeat', 'Items');
        columns.splice(index, 1);
        this.props.onSave();
        this.RCUpdate();
    }
    CloneItem(index) {
        const {data} = this.props;
        const columns = Utils.Get(data, [], 'Repeat', 'Items');
        const newcol = Utils.DeepClone(columns[index]);
        newcol.Id = Utils.Id();
        columns.splice(index, 0, newcol);
        this.props.onSave();
        this.RCUpdate();
    }    
    OnChangeRepeatCount(value) {     
        this.props.data.Repeat = value;
        this.props.onSave();
        this.RCUpdate();
    }
    ChangeColumnRepeatType(type) {
        if (type === 'auto')
            this.props.data.Repeat.RepeatType = 'autoFill';
        else
            this.props.data.Repeat.RepeatType = type;
        this.props.onSave();
        this.RCUpdate();
    }
    renderCustom() {        
        const {data} = this.props;

        let RepeaterItem;

        if (!this.props.auto) {
            RepeaterItem = (
                <React.Fragment>
                    <NumberInput
                        spinnded
                        value={Utils.Get(data, 1, 'Repeat')}
                        numeralPositiveOnly
                        onChange={this.OnChangeRepeatCount.bind(this)}
                        style={{
                            textAlign : 'center',
                            fontSize : '12px',
                            alignSelf : 'center',
                            maxWidth : '20px',
                            paddingRight : 0
                        }}
                        boxStyle={{height : '24px'}}
                    />
                    <SC.Div_Flex_Cell style={{fontSize : '14px', marginLeft : '8px', marginRight : '4px'}}>
                        x
                    </SC.Div_Flex_Cell>
                </React.Fragment>
            )
        }

        const isHidden = Utils.JustGet(data, false, 'hidden');
        return (
            <SC.InnerPanelGroup style={{marginBottom : '4px', padding : '4px', flexDirection : 'row'}}>
                <SC.DragBox first {...this.props.sortableProps.handleProps} style={{cursor:'move', height : 'unset', border : 'none'}} >
                    <SC.Icons.DragIcon xsmall />
                </SC.DragBox>  
                <SC.FCol f1 >
                    <SC.FRow alignStretch>
                        {RepeaterItem}
                        <ItemSizer
                            index={0}
                            value={Utils.Get(data, {}, 'Single', 'size')}
                            onSave={this.props.onSave}
                            getCurrentSize={this.props.getCurrentSize}
                            hasAuto={this.props.hasAuto}
                        /> 
                        <DotMenu onClick={this.ShowMenu} isOpen={this.isMenuOpen} onClose={this.ShowMenu} buttonStyle={{marginRight : '-4px', alignSelf : 'center'}}>
                            {
                                this.isMenuOpen &&
                                <SC.FCol style={{minWidth : '160px'}}>
                                    <SC.PopupItem onClick={this.EditStates}>
                                        <SC.FRow alc jsb>
                                            <div>Show/Hide</div>
                                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}} onClick={this.ToggleHidden} title={isHidden ? 'Make Visible' : 'Make Hidden'}>
                                                <SC.Icons.Icon_Visibility size={16} visible={!isHidden} />
                                            </SC.Icons.Icon_Button>
                                        </SC.FRow>
                                    </SC.PopupItem>
                                    <SC.PopupItem onClick={this.Clone}>
                                        <SC.FRow alc jsb>
                                            <div>Duplicate</div>
                                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingLeft :'4px'}}>
                                                <SC.Icons.Icon_Clone size={18}/>
                                            </SC.Icons.Icon_Button>
                                        </SC.FRow>
                                    </SC.PopupItem>
                                    <SC.PopupItem onClick={this.Delete}>
                                        <SC.FRow alc jsb>
                                            <div>Delete</div>
                                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingLeft :'4px'}} >
                                                <SC.Icons.Icon_Delete size={18}/>
                                            </SC.Icons.Icon_Button>
                                        </SC.FRow>
                                    </SC.PopupItem>
                                    
                                </SC.FCol>
                            }
                        </DotMenu> 
                        {false && <SC.FRow alc jsb style={{marginLeft : '4px'}}>
                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer'}} onClick={this.ToggleHidden} title={isHidden ? 'Make Visible' : 'Make Hidden'}>
                                <SC.Icons.Icon_Visibility size={isHidden ? 16 : 15} visible={!isHidden} />
                            </SC.Icons.Icon_Button>
                            <SC.FRow justifyEnd style={{marginLeft : '4px'}}>
                                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingLeft :'4px', paddingRight : '4px'}} onClick={this.Clone} >
                                    <SC.Icons.Icon_Clone size={16}/>
                                </SC.Icons.Icon_Button>
                                <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', paddingLeft :'4px'}} onClick={this.Delete} >
                                    <SC.Icons.Icon_Delete size={16}/>
                                </SC.Icons.Icon_Button>
                            </SC.FRow>
                        </SC.FRow>   }                     
                    </SC.FRow>
                </SC.FCol>                               
            </SC.InnerPanelGroup>            
        );
    }
}