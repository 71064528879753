import MetaData from '../toolabs-metaui';
import Utils from '../toolabs-utils';
import PublishMetaItem from './metaitem';
import Strings from './strings';
import { Publish_Models } from './component';

const Publish_Prototype = function(Prototype, Board, Platform, MetaDataVersion, GetMockupValue) {    
    let ConvertedPrototype = {
        Name : Utils.ToCamelCase(Utils.Get(Prototype.Data, Prototype.Model.name || Prototype.Model.Name || Prototype.Model._id, 'Published', 'Name')),
        Id : Prototype.Model._id,
        MetaDataVersion : MetaDataVersion,
        MainViewId : Prototype.Data.MainViewId,
        Views : {

        },
        Default : {

        }
    };

    Utils.ForEach(Prototype.Data.Views, (View, ViewId) => {
        const ConvertedView = {
            name : View.name
        };

        const PublishContext = {
            Platform : Platform,
            MetaItems : Utils.UseNull(View.Design.MetaItems, []),
            Handlers : View.Design.Handlers,
            BoardData : Prototype.BoardData,
            GetMockupValue : GetMockupValue           
        };
        ConvertedView.RootView = PublishMetaItem({
            MetaItem : PublishContext.MetaItems[View.Design.RootId], 
            Id : View.Design.RootId,
            PublishContext : PublishContext,
            RootItem : true
        });

        ConvertedView.MasterViewId = View.MasterViewId;        
        ConvertedPrototype.Views[ViewId] = ConvertedView;

        if (View.Design.ContentItems) {
            Utils.ForEach(View.Design.ContentItems, (ContentContainerId, ContentId) => {
                if (View.Design.MetaItems[ContentContainerId]) {
                    Utils.Set(ConvertedView,
                        PublishMetaItem({
                            MetaItem : View.Design.MetaItems[ContentContainerId], 
                            Id : ContentContainerId,
                            PublishContext : PublishContext,
                            RootItem : true
                        }),
                            'ContentItems', ContentId
                    );
                    ConvertedView.ContentItems[ContentId].Id = ContentContainerId;
                }
            });
        }

        // States
        const States = Utils.Get(View.Design, {}, Strings.STATES);
        if (States) {
            Utils.ForEach(States.Order, (Id) => {
                const State = States[Id];        
                if (State) {
                    if (State.Variations) {
                        const VariationIds = Utils.Get(State, null, 'Variations', 'Order');
                        if (VariationIds) {
                            Utils.Set(ConvertedView, State.name, Strings.STATES, Id, 'Name');
                            Utils.ForEach(VariationIds, (vid) => {
                                Utils.Set(ConvertedView, Utils.Get(State, '', 'Variations', vid, 'name'), Strings.STATES, Id, 'Variations', vid);
                            })
                        }
                    }    
                    else {
                        // Single Variation State
                        Utils.Set(ConvertedView, State.name, Strings.STATES, Id, 'Name');
                        Utils.Set(ConvertedView, Id, Strings.STATES, Id, 'SingleVariation');
                    }        
                    if (State.ResetOthers)
                        Utils.Set(ConvertedView, true, Strings.STATES, Id, 'ResetOthers');
                }                
            });
            ConvertedView.StateOrder = States.Order;
        }

        Publish_Models({Models : View.Design.Models, Target : ConvertedView});

        // Events : ToDo : publish only necessary event data
        const Handlers = Utils.DeepClone(View.Design.Handlers);
        if (Handlers)
        ConvertedView.Handlers = Handlers;

        // Events : ToDo : publish only necessary event data
        ConvertedView.Events = {};
        if (View.Design.Events) {
            Utils.ForEach(View.Design.Events.Order, (EventId, i) => {
                if (View.Design.Events[EventId]) {
                    ConvertedView.Events[EventId] = {
                        name : View.Design.Events[EventId].name
                    }
                }            
            });
        }
        

        // Public
        ConvertedView.Public = {

        };
        if (View.Design.Conditions) {
            ConvertedView.Conditions = Utils.DeepClone(View.Design.Conditions);
        }
        ConvertedView.Interactions = Utils.DeepClone(View.Design.Interactions);

        Utils.ForEach(View.Design.Handlers, (Event, Id) => {
            Utils.ForEach(Event.Actions, (Action) => {
                if (Action.Type === MetaData.Actions.Propagate.Type) {
                    const PublicEvent = {
                        Id : Id,
                        Name : Event.Name
                    };
                    const PublicHandlers = Utils.Get(ConvertedView.Public, [], 'Handlers');
                    PublicHandlers.push(PublicEvent);
                    return false;
                }
            })
        });
    });
   


    // Public
    ConvertedPrototype.Public = {

    };
    
    return ConvertedPrototype;
}

export default Publish_Prototype;