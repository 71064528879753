import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils
} from '../../../../../../../importer';
import ReactResizeDetector from 'react-resize-detector';
import { onConstructItem, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onItemShouldUpdate, onItemClick, onGetItemProps } from '../../common';

var ApexCharts = null;
var apexchartsPromise = null;

export default class ApexChart extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.onResize = this.onResize.bind(this);
        this.Load = this.Load.bind(this);
        this.Ref_EChart = React.createRef();

        onConstructItem(this, props);

        this.UpdateStyle(this.props);
        
    }
    UpdateStyle(props) {        
        const renderData = props.GetComponentManager().GetItemStyle(props.Id);
        const currendProps = Utils.JustGet(this.renderData, {}, 'props');
        this.renderData = renderData;
        if (Utils.HasAnyChange(currendProps, renderData.props, 'ChartType', 'ChartTheme', 'DataSource', 'Sparkline', 'Animated')) {
            this.Load();
        }        
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
        if (this.eChartInstance) {
            this.eChartInstance.destroy();
            this.eChartInstance = null;
        }
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
    }
    GetMaxSize() {        
        return this.props.GetParentSize();
    }    
    componentDidMount() {
        onItemDidMount.call(this);
        if (!ApexCharts) {
            if (!apexchartsPromise)
                apexchartsPromise = [import('apexcharts')];
            Promise.all(apexchartsPromise).then((r) => {
                ApexCharts = r[0].default;                
                this.Load();
            })
        }
        else
            this.Load();
    }
    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {
            willUpdate = true;
        }          
        return willUpdate;
    }
    Load() {
        if (this.Ref_EChart.current) {

            const bounds = UIUtils.Utils.GetBounds(this);
            this.Width = bounds.width;
            this.Height = bounds.height;
           
            this.Loaded = true;
            if (this.eChartInstance)
                this.eChartInstance.destroy();
            
            const {ChartType, ChartTheme, DataSource, Sparkline, Animated} = this.renderData.props;
            
            if (ChartType) {
                if (MetaData.Components.Widget.WidgetTypes.ApexCharts.MetaData.Types[ChartType]) {
                    const MetaChartType = MetaData.Components.Widget.WidgetTypes.ApexCharts.MetaData.Types[ChartType];
                    let ChartDef = MetaChartType.SubTypes.Default;
                    
                    if (ChartDef && ChartDef.init) {
                        let options = {
                            width : this.Width,
                            height : this.Height,
                            sparkline : this.props.Temp || Sparkline,
                            animated : this.props.Temp ? false : Animated
                        };
                        this.eChartInstance = ChartDef.init(ApexCharts, ReactDOM.findDOMNode(this.Ref_EChart.current), options);
                        return;
                    }
                }
            }   
        }
        this.setState({ShouldUpdate : true});
    }   
    onResize(w, h) {
        this.Width = w;
        this.Height = h;
        if (this.eChartInstance) {
            this.eChartInstance.updateOptions(
                {
                    chart : {
                        width : this.Width,
                        height : this.Height
                    }
                },
                true,
                true
            )
        }
        return;
                                                                                                                                                                                                                                                                                                                                                          
    }    
    render() { 
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
        const props = onGetItemProps({designer : this});

        return (
            <div style={{...this.renderData.style, overflow : 'hidden'}} {...props} >
                <div style={{width : '100%', height:'100%'}} ref={this.Ref_EChart} />
                <ReactResizeDetector handleWidth handleHeight onResize={this.onResize} />
            </div>
        )
    }
}
 