import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import styled, {css} from 'styled-components';
import { ItemNameEditor, TokenPanelHeader, TokenGalleryView, DropAreaContainer, DropFileArea } from '../../common';
import RadioGroup from '../../../../../../../components/editors/radiogroup';
import TextValue from '../../../../../../../components/editors/textvalue';
import { LeftScrollPanel } from '../../../common';
import Dropzone from 'react-dropzone';
import IconGallery from '../../../../../selectors/icon/online';
import API_IconFinder from '../../../../../../../integrations/iconfinder';
import LightDarkBar from '../../../../../../../components/light_dark_bar';

export default class IconEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.onDrop = this.onDrop.bind(this);
        this.SelectOnlineIcon = this.SelectOnlineIcon.bind(this);
        this.PastFromSvg = this.PastFromSvg.bind(this);
        this.SaveOnlineIcon = this.SaveOnlineIcon.bind(this);

        this.Ref_Name = React.createRef();
        this.Ref_PastedSvg = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.tokenvalue = {                
            }
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.tokenvalue = Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.token})) || {};
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.token = Globals.ProjectManager.Tokens.Token(nextProps.id);
            if (!this.token) {
                this.props.onCancelAddIcon();
                return false;
            }
            this.tokenvalue = Utils.DeepClone(Globals.ProjectManager.Tokens.ValueOf({model : this.token})) || {};
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Icons);
    }        
    SaveValue() {
        if (this.props.newModel) {
            
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : this.tokenvalue,
                type : Globals.ProjectManager.Tokens.Types.Icons
            })
            Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.Icons,
                value : this.tokenvalue
            }]);
        }        
    }
    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
            
        this.RCUpdate();
    }

    Select(e) {
        e && e.stopPropagation();
        this.refs.Ref_File && this.refs.Ref_File.click()
    }
    onDrop(files) {
        if (files && files.length > 0) {
            var reader = new FileReader();
            reader.onload = (e) => {
                if (!this.props.newModel)
                    Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Token'});
                this.tokenvalue.data = e.target.result;
                this.tokenvalue.provider = Strings.CUSTOM;
                this.SaveValue();
                this.RCUpdate();
            }
            reader.readAsDataURL(files[0]);
        }
    }       
    SelectOnlineIcon(e) {                
        e && e.stopPropagation();

        this.ShowGallery = {

        }
        if (this.props.onToggleGalleryView)
            this.props.onToggleGalleryView();
        else
            this.RCUpdate();
    }
    SaveOnlineIcon(img, provider) {
        if (provider === Strings.ICONFINDER) {
            this.tokenvalue = {
                provider : Strings.ICONFINDER,
                iconId : img.id,
                downloadUrl : img.downloadUrl
            };
            const MetaImage = {
                MetaIcon : this.tokenvalue,                            
                name : `Icon ${img.id}`
            }
            API_IconFinder.DownloadSVG(img.downloadUrl, MetaImage, this.props.id).then((result) => {
                try {
                    if (!this.props.newModel)
                        Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Token'});
                    this.tokenvalue.data = window.btoa(result.data);
                    this.tokenvalue.svg = result.data;
                    this.SaveValue();   
                    this.RCUpdate();                 
                } catch (error) {
                    
                }
                delete this.ShowGallery;
                if (this.props.onToggleGalleryView)
                    this.props.onToggleGalleryView();
                else
                    this.RCUpdate();
            });
        }
        else if (provider === Strings.ICON_GOOGLE_MATERIAL){ 
            if (!this.props.newModel)
                Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Token'});
            this.tokenvalue = {
                provider : Strings.ICON_GOOGLE_MATERIAL,
                Id : img.id,
                paths : img.icon.Paths
            };            
            this.SaveValue();
            delete this.ShowGallery;

            if (this.props.onToggleGalleryView)
                this.props.onToggleGalleryView();
            else
                this.RCUpdate();
        }        
    }
    PastFromSvg() {
        if (AppLayout.AppSource.Figma) {
            this.Ref_PastedSvg.current.focus();
            document.execCommand("paste");
            setTimeout(() => {
                const text = this.Ref_PastedSvg.current.value;    
                if (!this.props.newModel)
                    Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Token'});
                this.tokenvalue.data = text;
                this.tokenvalue.provider = 'svg';
                this.SaveValue();
                this.RCUpdate();
            }, 250);

            return;
        }
                
        navigator.clipboard.readText()
        .then(text => {            
            if (!this.props.newModel)
                Globals.ProjectManager.LogTokenChange({Desc : 'Change Icon Token'});
            this.tokenvalue.data =  `data:image/svg+xml;base64,${window.btoa(text)}`;
            this.tokenvalue.provider = Strings.CUSTOM;
            this.SaveValue();
            this.RCUpdate();
        })
        .catch(err => {            
        });

    }
    
    renderCustom() {

        let preview = null;
        let previewUrl, iconitem;

        if (this.tokenvalue.provider === Strings.ICONFINDER && this.tokenvalue.data) {
            iconitem = (
                <IconBox 
                    url={Utils.UrlBase64(this.tokenvalue.data)}
                    style={{
                        maxWidth : '120px',
                        height : '120px',
                        
                    }}
                />
            )
        }
        else if (this.tokenvalue.provider === Strings.CUSTOM && this.tokenvalue.data) {
            iconitem = (
                <IconBox 
                    url={this.tokenvalue.data}
                    style={{
                        maxWidth : '120px',
                        height : '120px',
                    }}
                />
            )
        }
        else if (this.tokenvalue.provider === 'svg' && this.tokenvalue.data) {
            iconitem = (
                <IconBox 
                    url={`data:image/svg+xml;base64,${window.btoa(this.tokenvalue.data)}`}
                    style={{
                        maxWidth : '120px',
                        height : '120px',
                    }}
                />
            )
        }
        else if (this.tokenvalue.provider === Strings.ICON_GOOGLE_MATERIAL && this.tokenvalue.paths) {
            iconitem = (
                <IconBox 
                    style={{
                        maxWidth : '120px',
                        height : '120px',
                    }}
                >
                    <SC.Icons_Preview compact  xmlns='http://www.w3.org/2000/svg' viewBox={`0 0 24 24`} width='100%' height='80%' dark={SC.CurrentTheme.theme.isLight} >
                        {
                            this.tokenvalue.paths.map((p, i) => {
                                return (
                                    <path key={i} d={p} />
                                )
                            })
                        }
                    </SC.Icons_Preview>
                </IconBox>            
            )
        }
        if (iconitem) {
            const isDark = Utils.UseNull(this.tokenvalue.dark, !SC.CurrentTheme.theme.isLight);
            preview = (
                <React.Fragment>
                    <SC.FRow jsb alc style={{paddingLeft : '4px'}}>
                        <div>Preview Background</div>
                        <SC.FRow>
                            <LightDarkBar 
                                small 
                                Dark={isDark}
                                medium
                                onChange={(e) => {                                    
                                    this.tokenvalue.dark = !isDark;
                                    this.SaveValue();
                                    this.RCUpdate();
                                }}
                            />
                        </SC.FRow>                        
                    </SC.FRow> 
                    <SC.GridBackground small dark={isDark} >
                        <SC.Div_Flex_Cell 
                            style={{height:'120px', position:'relative'}}
                            onClick={() => this.SelectOnlineIcon()}
                        >
                            <SC.Overlay>
                                Click to Change
                            </SC.Overlay>
                            {iconitem}                    
                        </SC.Div_Flex_Cell>
                    </SC.GridBackground>
                </React.Fragment>
            )   
        }        

        return (
            <SC.FCol fw fh>
                {
                    !this.ShowGallery && 
                    <React.Fragment>
                        {
                            !this.props.noHeader && 
                            <TokenPanelHeader title={this.props.newModel ? 'NEW ICON' : 'EDIT ICON'} 
                                hasAddCancel 
                                notBackClosable={this.props.newModel ? true : false}
                                onClose={this.props.onCancelAddIcon} 
                                onCancel={this.props.onCancelAddIcon} 
                                onAdd={() => {
                                    if (this.props.newModel) {
                                        this.props.newModel.value = this.tokenvalue;
                                    }
                                    this.props.onSubmitNewIcon();
                                }} 
                                onDelete={this.props.onDeleteIcon}
                            />
                        }                        
                        <LeftScrollPanel>
                            <SC.FCol fw fh f1 style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                                <ItemNameEditor
                                    noMargin
                                    fontSize='12px'                        
                                    name={this.token.name}
                                    onSaveName={this.SaveName}
                                    onGetItems={this.GetItems}
                                    model={this.token}
                                />
                                <SC.FCol f1 jsb style={{marginTop : '16px'}}>
                                    {preview}
                                    <Dropzone
                                        onDrop={this.onDrop}
                                        ref={(r) => this.Ref_DropZone = r}
                                        style={{}}
                                        accept="image/*"
                                        activeStyle={{}}                
                                    >
                                    {({ isDragActive, getRootProps, getInputProps }) => {
                                        return (                                            
                                            <SC.FCol style={{ position : 'relative'}} {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <SC.FCol onClick={(e) => {e.stopPropagation()}}>
                                                    <DropAreaContainer
                                                        onClick={this.SelectOnlineIcon}                            
                                                        style={{marginTop : '4px', minHeight : '60px'}}
                                                    >
                                                        Select from Gallery
                                                    </DropAreaContainer>
                                                    <SC.Div_Flex_Cell style={{padding : '4px'}}>
                                                        OR
                                                    </SC.Div_Flex_Cell>                                                                   
                                                </SC.FCol>
                                                <SC.FCol onClick={(e) => {e.stopPropagation()}}>
                                                    <DropAreaContainer
                                                        onClick={this.PastFromSvg}                            
                                                        style={{marginTop : '4px', minHeight : '60px', position : 'relative'}}
                                                    >
                                                        Paste Svg from Clipboard
                                                        <input ref={this.Ref_PastedSvg} style={{opacity : 0, position : 'absolute'}} />
                                                    </DropAreaContainer>
                                                    <SC.Div_Flex_Cell style={{padding : '4px'}}>
                                                        OR
                                                    </SC.Div_Flex_Cell>                                                                   
                                                </SC.FCol>                            
                                                <DropFileArea active={isDragActive} />                    
                                                {
                                                    this.IsUploading ?
                                                    <SC.Div_Flex_Cell style={{
                                                            position : 'absolute', top : 0, left : 0, right : 0, bottom : 0, backgroundColor : 'rgba(0,0,0,0.5)'
                                                        }}>
                                                        <SC.Loading_Icon justIcon/>
                                                    </SC.Div_Flex_Cell> : null
                                                }
                                            </SC.FCol>
                                        );
                                    }}

                                    </Dropzone>
                                </SC.FCol>                                                       
                            </SC.FCol>        
                        </LeftScrollPanel>
                    </React.Fragment>
                }
                {
                    this.ShowGallery && 
                    <TokenGalleryView 
                        title='ICON GALLERY' 
                        onClose={() => {
                            delete this.ShowGallery;
                            if (this.props.onToggleGalleryView)
                                this.props.onToggleGalleryView();
                            else
                                this.RCUpdate();
                        }}
                        onRightPanel={this.props.onRightPanel}
                    >
                        <IconGallery 
                            noInfo
                            onSelect={this.SaveOnlineIcon}
                            onPreviewChange={this.props.onPreviewChange}
                        />
                    </TokenGalleryView>
                }
            </SC.FCol>            
        )
    }
}

const StyleItem_Icon_Box = styled.div`
    width : 100%;
    height : 180px;
    background-color : ${props => props.theme.color_content_back};
    ${
    props => {
        if (props.backgroundImage) {
            return css`
                    background-image : url(${props.backgroundImage});
                    background-size : contain;
                    background-repeat : no-repeat;
                    background-position : center;
                `;
        }
    }
    }
`;

export const IconBox = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    background-image : url(${props => props.url});
    background-size : 80% auto;
    background-repeat : no-repeat;
    background-position : center;
    background-color : ${props => props.selected ? props.theme.back_lightest : 'transparent'}; 
    background-clip : content-box;
    width : 100%;
    height : 100%;
    flex : 1;
    border-radius : 1px;
    box-sizing : border-box;
    cursor : pointer;
    transition : all 0.2s ease;    
    &:hover {
        color : white;
        background-color : ${props => props.theme.back_lightest};
    } 
    ${
        props => {
            if (props.selected) {
                return css`
                    background-color : ${props => props.theme.back_lightest};
                `;
            }
        }
    }
`;