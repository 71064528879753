/*
applinist@gmail.com
Free plan
Client Id : UtemAaWYGL3BiBSOvBeNiX0ePK0GDVhDMozYUa129AGnrVmtwq32p1Ol9NZFTMTI
Client Secret : LNfJwyueeagGSNW9xJ1wwfXffZoOY5NpeyCndgqmnDI4x68CfdltmjIlA8M7EbzP
*/

import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Strings,
    UIUtils,
    ReactPureComponent,
    MetaData
} from '../../importer';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';

import get from 'simple-get';
import DragSource from '../../components/dragdrop/HoCDragSource';
import GoogleMaterialIcons from './googlematerialicons';

const TOOLABS_API_URL = "https://us-central1-test-component-studio.cloudfunctions.net/";
const API_URL = 'https://api.iconfinder.com/v3';
const CLIENT_ID = 'UtemAaWYGL3BiBSOvBeNiX0ePK0GDVhDMozYUa129AGnrVmtwq32p1Ol9NZFTMTI';
const CLIENT_SECRET = 'LNfJwyueeagGSNW9xJ1wwfXffZoOY5NpeyCndgqmnDI4x68CfdltmjIlA8M7EbzP';

let API_Authenticated = false;
let API_Loading = false;
let API_LoadingPage = -1;
let API_PageCount = 30;
let API_LoadingPromise;
let API_SearchOptions = {

};

const IconCell = styled.div `
display : flex;
align-items : center;
justify-content : center;
fill : ${props => props.selected ? '#E4E4E4' : '#999999'};
border : 1px solid ${props => props.selected ? '#0096ea' : '#4A4A4A'};
padding : 4px;
width : 50px;
height : 50px;
${SC.TransitionHoverOff}
&:hover {
    ${SC.TransitionHoverOn}
    border : 1px solid #0096ea;
}
`

export const API_IconFinder = {
    Init() {
        API_LoadingPage = -1;
        API_SearchOptions = {};
    },
    IsLoading() {
        return API_Loading;
    },
    GetToken() {
        if (!API_Authenticated) {
            const opts = {
                method: 'GET',
                url: `${TOOLABS_API_URL}/api_iconFinder_getToken`,
                json:true
            };
            get.concat(opts, (err, res, data) => {
                if (err) {
                    // throw err;
                    return;
                }
                if (data.access_token)
                    API_Authenticated = true;
            });
        }
    },
    DownloadSVG(downloadUrl, metaimage, assetId) {
        return new Promise((resolve, reject) => {
            const opts = {
                method: 'GET',
                url: `${API_URL}/${downloadUrl}?client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`
            };
            get.concat(opts, (err, res, data) => {
                if (err) {
                    throw err;
                    return;
                }
                var svgData = new TextDecoder("utf-8").decode(data);
                // console.log(svgData);
                resolve({
                    data : svgData, 
                    metaimage : metaimage, 
                    id : assetId
                });
            });
        });
    },
    Categories() {
        if (!API_Authenticated) {
            API_IconFinder.GetToken();
        }
        return new Promise((resolve, reject) => {
            const opts = {
                method: 'GET',
                url: `${API_URL}/categories?count=100&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
                json: true
            };        
            get.concat(opts, (err, res, data) => {
                if (err) {
                    throw err;
                    return;
                }
                const result = {
                    total : data.total_count,
                    categories : data.categories
                };
                resolve(result);
            });
        });
    },
    IconSets: (options) => {
        if (API_LoadingPromise) {
            return API_LoadingPromise;
        }
        if (!API_Authenticated) {
            API_IconFinder.GetToken();
        }
        if (!Utils.IsEqual(API_SearchOptions, options)) {
            API_LoadingPage = -1;
            API_SearchOptions = Utils.DeepClone(options);
        }
        API_Loading = true;
        API_LoadingPage += 1;

        const that = this;
        API_LoadingPromise = new Promise((resolve, reject) => {
            const opts = {
                method: 'GET',
                url: `${API_URL}/iconsets?count=${API_PageCount}&offset=${API_LoadingPage * API_PageCount}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
                json: true
            };
            get.concat(opts, (err, res, data) => {
                if (err) {
                    throw err;
                    return;
                }
                let result = {
                    total: data.total_count,
                    total_page: data.total_count / API_PageCount,
                    sets: []
                }

                Utils.ForEach(data.icons, (imgdata, i) => {
                    var raster_sizes = Utils.FindLast(imgdata.raster_sizes);
                    var vector_sizes = Utils.FindLast(imgdata.vector_sizes);
                    if (raster_sizes)
                        result.sets.push({
                            id: imgdata.icon_id,
                            preview: raster_sizes.formats[0].preview_url,
                            url: raster_sizes.formats[0].preview_url,
                            downloadUrl: vector_sizes.formats[0].download_url,
                            width: 512,
                            height: 512
                        })
                });

                API_Loading = false;
                API_LoadingPromise = null;
                resolve(result);
            })

        });
        return API_LoadingPromise;
    },
    Search: (options) => {
        if (API_LoadingPromise) {
            return API_LoadingPromise;
        }
        if (!API_Authenticated) {
            API_IconFinder.GetToken();
        }
        if (!Utils.IsEqual(API_SearchOptions, options)) {
            API_LoadingPage = -1;
            API_SearchOptions = Utils.DeepClone(options);
        }
        API_Loading = true;
        API_LoadingPage += 1;

        const that = this;
        API_LoadingPromise = new Promise((resolve, reject) => {
            let styleFilter = '';
            if (options.Style)
                styleFilter = `&style=${options.Style}`;
            const opts = {
                method: 'GET',
                url: `${API_URL}/icons/search?query=${Utils.JustGet(API_SearchOptions, 'icon', 'SearchText')}${styleFilter}&premium=0&vector=1&count=${API_PageCount}&offset=${API_LoadingPage * API_PageCount}&client_id=${CLIENT_ID}&client_secret=${CLIENT_SECRET}`,
                json: true
            };
            get.concat(opts, (err, res, data) => {
                if (err) {
                    throw err;
                    return;
                }
                let result = {
                    query : Utils.JustGet(API_SearchOptions, null, 'SearchText'),
                    total: data.total_count,
                    total_page: data.total_count / API_PageCount,
                    images: [],
                    reset : API_LoadingPage === 0
                }

                Utils.ForEach(data.icons, (imgdata, i) => {
                    var raster_sizes = Utils.FindLast(imgdata.raster_sizes);
                    var vector_sizes = Utils.FindLast(imgdata.vector_sizes);
                    if (raster_sizes)
                        result.images.push({
                            id: imgdata.icon_id,
                            preview: raster_sizes.formats[0].preview_url,
                            url: raster_sizes.formats[0].preview_url,
                            downloadUrl: vector_sizes.formats[0].download_url,
                            width: 512,
                            height: 512
                        })
                });

                API_Loading = false;
                API_LoadingPromise = null;
                resolve(result);
            })

        });
        return API_LoadingPromise;
    }
};

export class IconThumbnail extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.ImageLoaded = this.ImageLoaded.bind(this);
    }    
    ImageLoaded() {
        this.loaded = true;
        this.showLoader = this.props.last;
        this.RCUpdate();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.dark !== this.props.dark || nextProps.id !== this.props.id || (this.props.hasSelect && this.props.hasSelect.selected !== nextProps.hasSelect.selected))
            return true;
        if (this.state.ShouldUpdate !== nextState.ShouldUpdate)
            return true;
        return false;
    }
    renderCustom() {
        const {url, dark, hasSelect, materialIcon, last, onLoadPage, ...rest} = this.props;
        let selectOverlay = null;
        let selectProps = null;
        if (hasSelect) {
            selectProps = {
                onClick : hasSelect.onSelect,
                
            }
            selectOverlay = (
                <SC.Overlay
                    style={Utils.Merge({                        
                        fontSize : '16px',                        
                        textAlign:'center'
                    }, hasSelect.selected ? {opacity :  1} : null)}
                >
                    Click to {hasSelect.selected ? 'deselect' : 'select'}
                </SC.Overlay>
            )
        }
        const style = {
            position:'relative', 
            borderRadius:'2px', 
            width : '100%', 
            height:'100%', 
            backgroundColor : dark ? '#1d1e1f' : '#dedede', 
            cursor:'pointer'
        };
        if (this.props.DragItem) {
            style.cursor = 'move';
        }
        return (
            <SC.Div_Flex_Cell 
                style={style} 
                {...rest}
                onClick={this.props.onSelect}
                {...selectProps}
            >
                <VisibilitySensor partialVisibility={true} >
                    {
                        ({isVisible}) => {
                            if (isVisible && !this.visible) {
                                this.props.onLoadPage();
                                this.showLoader = false;
                                this.RCUpdate();
                            }

                            this.visible = isVisible;
                            
                            if (isVisible) {
                                // return (
                                //     <MaterialIcon Id={this.props.id} dark={dark} />
                                // )

                                return (
                                    <img 
                                        onLoad={this.ImageLoaded}
                                        draggable={false}
                                        style={Utils.Merge({
                                            flex : 1,
                                            maxWidth : '100%',
                                            maxHeight : '100%',
                                            padding : '4px',
                                            boxSizing : 'border-box'
                                        },this.loaded ? null : {backgroundColor:'rgba(0,0,0,0.1)'})}
                                        src={this.props.url}
                                    />
                                )
                            }
                            return (<div style={{width:'100%', height : '100%'}} />)
                        }
                    }                         
                </VisibilitySensor>
                {
                    this.props.DragItem ? null : 
                    <SC.Overlay
                        style={Utils.Merge({                        
                            fontSize : '16px',                        
                            textAlign:'center'
                        }, hasSelect.selected ? {opacity :  1} : null)}
                    >
                        Click to {hasSelect.selected ? 'deselect' : 'select'}
                    </SC.Overlay>
                }                
            </SC.Div_Flex_Cell>
        )        
    }
}

export const DraggedIconThumbnail = DragSource(IconThumbnail);

export class SelectedIconList extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    Update(images) {
        this.images = images;
        this.RCUpdate();
    }
    renderCustom() {    
        const images = this.images || this.props.images || [];
        if (images === 0)
            return null;
        
        return (
            <SC.FCol f1 fh style={{marginLeft : '-8px', marginRight : '-8px'}}>                 
                <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                    <SC.FCol style={{paddingLeft : '8px', paddingRight : '8px'}}>
                        {
                            images.map((img) => {
                                let icon = null;
                                if (img.provider === Strings.ICONFINDER) {
                                    icon = (
                                        <img 
                                            draggable={false}
                                            style={{
                                                height : '100%'
                                            }}
                                            src={img.preview}
                                        />
                                    )
                                }
                                else {
                                    icon = (
                                        <svg xmlns='http://www.w3.org/2000/svg' width='70%' height='70%' viewBox={`0 0 24 24`} fill={'#1d1e1f'}>
                                            {
                                                img.paths.map((p, i) => {
                                                    return (
                                                        <path key={i} d={p} />
                                                    )
                                                })
                                            }
                                        </svg>
                                    )
                                }
                                return (
                                    <SC.Div_Flex_Cell
                                        alc
                                        key={img.id}
                                        style={{
                                            width : '100%', height : '120px', 
                                            overflow : 'hidden', cursor : 'pointer', 
                                            marginBottom : '4px',
                                            backgroundColor : '#292a2d',
                                            padding : '4px',
                                            boxSizing : 'border-box',
                                            cursor : 'pointer',
                                            position : 'relative',
                                            backgroundColor : '#acacac'
                                        }}
                                        onClick={() => this.props.onSelect(img)}
                                    >
                                        {icon}
                                        <SC.Overlay>
                                            Click to deselect
                                        </SC.Overlay>
                                    </SC.Div_Flex_Cell>
                                )
                            })
                        }
                    </SC.FCol>
                </SC.CustomScrollbars>
            </SC.FCol>
        )
    }
}

var Provider = Strings.ICON_GOOGLE_MATERIAL;

export class IconfinderGallery extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.refreshIndex = 0;
        this.LoadPage = this.LoadPage.bind(this);
        this.Search = this.Search.bind(this);
        this.CancelSearch = this.CancelSearch.bind(this);        
        this.Select = this.Select.bind(this);
        this.empty = true;    
        
        Provider = this.props.provider || Provider;
        this.filterStyle = this.props.filterIconStyle;

        API_IconFinder.Init();
    }
    componentDidMount() {
        super.componentDidMount();        
    } 
    shouldComponentUpdate = (nextProps, nextState) => {
        Provider = nextProps.provider || Provider;
        this.filterStyle = nextProps.filterIconStyle;
        if (nextProps.filterText !== this.props.filterText || nextProps.filterIconStyle !== this.props.filterIconStyle) {
            this.Search(nextProps.filterText);
        }

        return true;
    }
    Select(img) {

        let item = {
            provider : Provider
        }
        if (Provider === Strings.ICONFINDER) {
            item.id = img.id;
            Utils.ForEach(img, (val, prop) => {
                item[prop] = val;
            });
        }
        else {
            item.id = img.id;
            item.paths = img.icon.Paths;
            item.name = img.icon.Name;
        }
        if (this.props.options.single) {
            this.props.options.onSelect(item);
            this.props.onClose();
            return;
        }

        const selectedImages = Utils.Get(this, {}, 'selectedImages');
        if (selectedImages[img.id])
            delete selectedImages[img.id];
        else
            selectedImages[img.id] = item;

        if (this.props.onUpdateSelection)
            this.props.onUpdateSelection();
        this.RCUpdate();
    }
    GetSelectedImages() {
        const selectedimages = [];
        Utils.ForEach(this.selectedImages, (img, id) => {
            selectedimages.push(img);
        });
        return selectedimages;
    }
    Search(s) {
        if (s !== this.FilterText) {
            delete this.images;
            this.FilterText = s;
            this.empty = !Utils.IsNotNullOrEmpty(this.FilterText);
            this.LoadPage();
        }        
    }
    CancelSearch() {
        if (Utils.IsNotNullOrEmpty(this.FilterText)) {
            delete this.images;
            this.FilterText = '';
            this.LoadPage();
        }        
    }
    LoadPage() {
        const that = this;
        if (API_IconFinder.IsLoading())
            return;

        if (this.FilterText !== this.LastFilter)
            delete this.images;
        this.LastFilter = this.FilterText;
        API_IconFinder.Search({
            SearchText : this.empty ? 'search' : this.FilterText,
            Style : this.filterStyle === 'all' ? null : this.filterStyle
        }).then((result) => {
            
            if (result.query !== that.FilterText) {
                delete that.images;
                that.LoadPage();
                return;
            }

            this.total = result.total;
            if (result.reset)
                that.images = [];
            const images = Utils.Get(that, [], 'images');
            
            let tempIndex = images.length;
            Utils.ForEach(result.images, (img, i) => {
                images.push(img);
            });
            this.refreshIndex = tempIndex;
            that.RCUpdate();            
        })      
    }

    renderCustom() {        
        const selectedimages = [];
        Utils.ForEach(this.selectedImages, (img, id) => {
            selectedimages.push(img);
        });

        let IconListItem = null;
        let overlayitem = null;

        if (Provider === Strings.ICONFINDER) {
            if (this.empty || this.images && this.images.length === 0) {                   
                IconListItem = (
                    <SC.FCol style={{
                        fontSize : '36px',
                        alignItems : 'center'
                    }}>
                        {
                            Utils.IsNotNullOrEmpty(this.FilterText) ? 
                            <div>Nothing found with keyword "{this.FilterText}"</div> : 
                            <div>Select icon style and search by keywords</div>
                        }                        
                    </SC.FCol>
                )
            }
            else if (!this.images) {
                IconListItem = (
                    <SC.Div_Flex_Cell f1>
                        <SC.Loading_Icon />
                    </SC.Div_Flex_Cell>
                )
            }
            else if (this.images) {
                
                const gridStyle = {
                    display : 'grid',
                    gridGap : '16px',
                    gridTemplateColumns : 'repeat(auto-fill, minmax(80px, 1fr))',
                    gridAutoRows : 'minmax(80px, 1fr)'
                };

                IconListItem = this.images && this.images.length > 0 ? 
                <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                    <SC.FCol style={this.props.style}>
                        <div style={gridStyle}>
                            {
                                this.images.map((img, i) => {
                                    return (
                                        <IconThumbnail 
                                            key={img.id} 
                                            id={img.id}
                                            url={img.preview} 
                                            last={i===this.refreshIndex} 
                                            onLoadPage={this.LoadPage}
                                            dark={this.props.dark}
                                            hasSelect={{
                                                onSelect : this.Select.bind(this, img),
                                                selected : Utils.Get(this, null, 'selectedImages', img.id) ? true : false
                                            }}
                                        />
                                    )
                                })
                            }
                        </div>                                                                             
                    </SC.FCol>
                </SC.CustomScrollbars>                    
                : null;
            }
        }
        else {
            IconListItem = (
                <GoogleMaterialIcons 
                    Dark={this.props.dark}
                    selectedImages={this.selectedImages}
                    onSelect={this.Select}
                    filter={this.FilterText ? this.FilterText.toUpperCase() : null}
                    style={this.props.style}
                    largeView={this.props.largeView}
                />
            )
        }
        return (
            <SC.FCol f1 fw fh >
                {IconListItem}                                
            </SC.FCol>
        )
    }
}

export default API_IconFinder;