import React from 'react';
import {
    SC
} from '../../../importer';

import styled, {css} from 'styled-components';

export const Block_CodeInfo = styled.div`
    display : flex;
    flex-direction : column;
    padding : 12px;
    font-size : 14px;
    background-color : ${props => props.theme.back_lighter};
    border-radius : 4px;
    box-shadow : ${props => props.theme.card_shadow};
`;

export const StepNumber = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    font-size : 14px;
    border : ${props => props.theme.border_onlight_line};
    border-radius : 50%;
    width : ${props => props.width || 24}px;
    height : ${props => props.height || 24}px;
    margin-right : 12px;
    font-weight : 500;
`;

export const Block_PlatformItem = styled.div`
    margin-bottom : ${props => props.noBottomMargin ? 0 : 8}px;
    font-size : 14px;
    padding : 8px;
    cursor : pointer;
    min-width : 100px;
    text-align : center;
    border : 1px solid ${props => props.selected ? props => props.theme.color_brand : '#414246'};
    ${SC.TransitionHoverOn}
    &:hover {
        ${SC.TransitionHoverOff}
        color : white;
        border-color : #658c91;
    }
`;

export const Block_DownloadButton = styled.div`    
    font-size : 14px;    
    cursor : pointer;
`;