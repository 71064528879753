import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_RangeInput extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    UpdateStyle(props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        const ThumbId = Utils.JustGet(MetaItem.Generic, null, 'ThumbId');     
        this.renderDataThumb = props.GetComponentManager().GetItemStyle(ThumbId);
    }
    renderContent({style, props}) {      

        let style_track =  {
            ...style
        };
        let style_thumb = {
            ...this.renderDataThumb.style
        }

        const state = this.props.GetComponentManager().ComponentState;
        let statename = Utils.UseNullOrEmpty(state, '').replace(',', '_');
        const classId=`gcss-${this.props.Id}-${statename}`;
        return (
            <div {...props}>
                <RangeInput     
                    State={state}
                    classId={classId}
                    value={this.renderData.props.value}
                    style={{
                        track : style_track,
                        thumb : style_thumb
                    }}
                    
                /> 
            </div>
        )
    }
}

class RangeInput extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            value : Utils.UseNull(this.props.value, 0)
        }
        this.StopPropagation = this.StopPropagation.bind(this);

        this.updateStyle(props);
    }
    updateStyle(props) {
        const style = props.style;        
        const classId = props.classId;

        const style_track = {
            cursor : 'pointer',            
            ...style.track,    
            display : 'flex',
            alignItems : 'center',
        };
        const style_thumb = {
            cursor : 'pointer',
            ...style.thumb,
        };

        let css_track = Utils.ConvertObjectToCss(style_track);
        let css_thumb = Utils.ConvertObjectToCss(style_thumb);

        Utils.SetStyleSheet({
            Id : classId, 
            StyleDefinition : `
        .${classId} {
            -webkit-appearance: none;
            padding-top : 8px;
			padding-bottom : 8px;
            cursor : pointer;
            pointer-events : none;
            ${css_track}
          }
          .${classId}:focus {
            outline: none;
          }
          .${classId}::-webkit-slider-runnable-track {
              ${css_track}
          }
          .${classId}::-webkit-slider-thumb {
            ${css_thumb}
            -webkit-appearance: none;
          }
          .${classId}:focus::-webkit-slider-runnable-track {
            background: #367ebd;
          }
          .${classId}::-moz-range-track {
            ${css_track}
          }
          .${classId}::-moz-range-thumb {
            ${css_thumb}
          }
          .${classId}::-ms-track {
            ${css_track}
          }
          .${classId}::-ms-fill-lower {
            background: #2a6495;
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          .${classId}::-ms-fill-upper {
            background: ${style.track.background};
            border: 0.2px solid #010101;
            border-radius: 2.6px;
            box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          }
          .${classId}::-ms-thumb {
            ${css_thumb}
          }
          .${classId}:focus::-ms-fill-lower {
            background: ${style.track.background};
          }
          .${classId}:focus::-ms-fill-upper {
            background: #367ebd;
          }
        `, TargetDocument : this.props.document})
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.value !== nextProps.value)
            this.state.value = nextProps.value;
        if (!Utils.IsEqual(this.props.style, nextProps.style)) {
            this.updateStyle(nextProps);
        }
        return true;
    }
    componentWillUnmount() {
        // Utils.RemoveStyleSheet(this.props.classId);
    }
    StopPropagation(e) {
        e && e.stopPropagation();
        e && e.preventDefault();
    }
    render() {
        return (            
            <input 
                type='range'        
                min={1} 
                max={100} 
                step={1} 
                value={this.state.value}  
                onChange={(e) => {this.setState({value : e.target.value})}}
                className={this.props.classId}
                disabled
                style={{
                    backgroundColor : 'unset',
                    pointerEvents : 'none'
                }}
                // onMouseDown={this.StopPropagation}
                // onMouseUp={this.StopPropagation}
                onClick={this.StopPropagation}
            />
        )
    }
}