import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Events,
    AppLayout,
    Strings,
    Globals
} from '../../../../../../../importer';

import {TokenItemBox, GroupTitle, TokenPanelHeader} from '../../common';
import PatternEditor from './item';
import { LeftScrollPanel } from '../../../common';
import { StatefulTokenMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';

export default class TimePatterns extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Ref_PatternEdit = React.createRef();

        this.AddPattern = this.AddPattern.bind(this);
        this.onEditPattern = this.onEditPattern.bind(this);        
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onClonePattern = this.onClonePattern.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);
        this.PreviewPattern = this.PreviewPattern.bind(this);

        AppLayout.Refs.DesignSystem.Durations = this;

        this.Load();
    }        
    Load() {
        this.patternItems = GetDurationPatternList();
    }
    Reload() {
        this.Load();
        this.RCUpdate();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.spacingId !== nextProps.spacingId || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load();
        }
        else if (this.props.baseSize !== nextProps.baseSize) {
            Utils.ForEach(this.patternItems, (pattern, i) => {
                pattern.timeValue = Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(pattern.pattern);
            });
            return true;
        }
        return true;
    }
    
    AddPattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternId = Utils.Id();        
        this.EditPatternModel = Globals.ProjectManager.Tokens.TimePatterns.NewPattern(this.EditPatternId);

        Globals.ProjectManager.LogTokenChange({Desc : 'Add Time Pattern'});
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.TimePatterns,
            ...this.EditPatternModel,
            id : this.EditPatternId
        });

        this.onEditPattern(this.EditPatternId);
    }
    onCancelAddPattern() {
        if (this.IsNew) {
            this.onDeletePattern();
        }
        else
            this.onCloseEditor();
    }
    onCloseEditor() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            this.Load();
        }        
        else {
            const patternitem = Utils.Find(this.patternItems, (item) => {return item.id === this.EditPatternId});
            if (patternitem) {
                patternitem.timeValue =  Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(patternitem.pattern);
            }

            this.patternItems = Utils.Sort(this.patternItems, (item) => {return item.timeValue});
        }
                
        this.onCloseEditor();
        Events.BroadcastThrottle_400(Events.GLOBAL.TOKEN_VALUE_CHANGING);
    }
    onDeletePattern() {
        const result = Globals.ProjectManager.Tokens.SpacePatterns.DeletePattern(this.EditPatternId);
        if (result) {
            this.Load();            
        }
        this.onCloseEditor();
    }
    onClonePattern() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditPatternId, Globals.ProjectManager.Tokens.Types.TimePatterns);
        this.Load();
        this.onEditPattern(cloneId);
        this.props.onUpdate && this.props.onUpdate();
    }
    SaveEditingPattern() {
        if (this.Ref_PatternEdit.current)
            this.Ref_PatternEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }
    onEditPattern(patternId) {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.EditPatternId = patternId;

        const pattern = Globals.ProjectManager.Tokens.Token(patternId);

        this.props.onPanelOverlay({
            show : true,
            render : () => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title={this.IsNew ? 'ADD DURATION' : 'EDIT DURATION'}
                            hasAddCancel 
                            notBackClosable={this.IsNew}
                            onClose={this.onSubmitNewPattern} 
                            onCancel={this.onCancelAddPattern} 
                            onAdd={this.onSubmitNewPattern} 
                            onDelete={!this.IsNew && this.onDeletePattern}
                            onClone={!this.IsNew && this.onClonePattern}
                            onLight
                        />
                        <LeftScrollPanel>
                            <PatternEditor 
                                compact
                                editing
                                isNew={this.IsNew}
                                pattern={pattern}
                                id={patternId}
                                baseSize={this.props.baseSize}
                                ratio={this.props.ratio}
                                GlobalStateId={this.props.GlobalStateId}
                                onSave={this.SaveEditingPattern}
                                onSelectFont={this.SelectFont}
                                ref={this.Ref_PatternEdit}
                                onClose={this.onSubmitNewPattern} 
                                onUpdate={this.props.onUpdate}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }
    PreviewPattern() {

    }
    renderCustom() {

        return (
            <DurationList
                patterns={this.patternItems}
                onAdd={this.AddPattern}
                onEdit={this.onEditPattern}
                onPreview={this.PreviewPattern}
                selectedId={this.props.selectedId}
                filterText={this.props.filterText}
                title='Durations'
                notDraggable={this.props.notDraggable}
            />
        )
    }
}

export class DurationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    onEditPattern(patternId) {
        this.props.onEdit(patternId);
    }
    PreviewPattern(show, id, pattern) {
        this.props.onPreview && this.props.onPreview(show, id, pattern);
    }
    render() { 

        const timeItems = [];
       
        let filterText;
        if (Utils.IsNotNullOrEmpty(this.props.filterText))
            filterText = this.props.filterText.toUpperCase();

        Utils.ForEach(this.props.patterns, ({id, pattern, timeValue, percent, valueState}, i) => {
            let filtered;
            if (filterText && pattern.name.toUpperCase().indexOf(filterText) < 0) {                    
                filtered = true;
            }   
            if (!filtered) {
                let DragItem = {
                    Type : Strings.TOOLBARITEM,
                    Item : {                            
                        MetaItem : {
                            Type : Strings.TOKENVALUE,
                            TokenType : AppState.ItemTypes.BOARD.ANIMATION,
                            Id : id,
                            Value : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(pattern),
                            name : pattern.name
                        }
                    } 
                };
                
                const BoxProps = {
                    pattern : pattern,
                    timeValue : timeValue,
                    percent : percent,
                    style : this.props.notDraggable ? {cursor : 'pointer'} : {cursor : 'move'},
                    onClick : this.onEditPattern.bind(this, id),
                    onMouseEnter : this.props.notDraggable ? this.PreviewPattern.bind(this, true, id, pattern) : null,
                    onMouseLeave : this.props.notDraggable ? this.PreviewPattern.bind(this, false, id, pattern) : null,
                    DragItem : DragItem,
                    selected  : this.props.selectedId === id,
                    listView : true,
                    valueState : valueState
                };
    
                timeItems.push(       
                    <DraggablePattern
                        key={id}
                        {...BoxProps}
                    />          
                )
            }            
        });

        return (
            <SC.FCol style={{marginTop : '8px', paddingBottom : '4px'}}>
                <GroupTitle 
                    title={this.props.title} 
                    subGroup
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.props.onAdd} : null}
                    style={this.props.noPadding ? {paddingLeft : '12px', paddingRight : 0} : {paddingLeft : '12px'}}
                />
                <SC.FCol
                    style={{
                        paddingLeft : this.props.noPadding ? 0 : '8px', paddingRight : this.props.noPadding ? 0 : '8px', marginTop : '6px'
                    }}
                >
                    {timeItems}
                </SC.FCol>                
            </SC.FCol>
            
        )
    }
}

export const DurationPattern = ({DragItem, pattern, timeValue, percent, name, valueState, listView, onPreview, sortableProps, ...rest}) => {    
    const style_box = {
        ...SC.Styles.Flex.RowAlcJsb
    };
    if (rest.selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    return (
        <TokenItemBox {...rest} style={style_box} {...onPreview}>
            <SC.FRow f1 alc>
                {valueState && <StatefulTokenMarker {...valueState} />}
                <SC.TextDivAbbr>{pattern.name}</SC.TextDivAbbr>
            </SC.FRow>            
            {
                timeValue && 
                <SC.FRow style={{marginLeft  :'8px', fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}} alc>
                    {timeValue}
                    <div style={{marginLeft : '4px', fontSize : '11px'}}>ms</div>
                </SC.FRow>
            }
        </TokenItemBox>
    )
}


export const GetDurationPatternList = () => {

    const patterns = Globals.ProjectManager.Tokens.TimePatterns.Get();
    return GetDurationPatternListOfIds(patterns);
}

export const GetDurationPatternListOfIds = (patterns) => {    
    let patternItems = [];
    // let maxDuration = 0;
    Utils.ForEach(patterns, (patternId, i) => {
        const pattern = Globals.ProjectManager.Tokens.Token(patternId);
        if (pattern) {
            patternItems.push(GetDurationTokenItem(pattern, patternId));
        }            
    });
    // maxDuration *= 1.5;
    // Utils.ForEach(patternItems, (pattern, ) => {
    //     pattern.percent = pattern.timeValue / maxDuration;
    // });

    return Utils.Sort(patternItems, (item) => {return item.timeValue});
}

export const GetDurationTokenItem = (pattern, id, StateArray) => {
    if (pattern) {
        const info = {};
        const timeValue = Globals.ProjectManager.Tokens.TimePatterns.GetTimeSizeAndInfo({pattern : pattern, stateArray : StateArray, info : info});
        // maxDuration = Math.max(timeValue, maxDuration);
        return {
            id : id,
            pattern : pattern,
            timeValue : timeValue,
            valueState : info.ValueState
        }
    }
}

const DraggablePattern = DurationPattern;

export const AddNewDurationToken = (model, id) => {    
    Globals.ProjectManager.Tokens.Add({
        type : Globals.ProjectManager.Tokens.Types.TimePatterns,        
        ...model,
        id : id
    });

    return {
        id : id,
        pattern : model,
        timeValue : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(model)
    }
}