import ProjectManager from '../views/project/manager';
import ArtboardManager from '../views/project/components/canvas/manager';
import {GetDataApi} from './AppState';
import { Utils } from '../toolabs-importer';
// import ComponentDesignManager from '../views/project/component/manager';

const Globals = {
    ProjectManager : new ProjectManager(),
    ArtboardManager : new ArtboardManager(),
    ComponentManager : null, //new ComponentDesignManager(),
    ZoomConsumer : null,
    ZoomSliderRef : null,
    ZoomSpeed : 30,
    SetActiveComponentManager(Manager, Owner) {
        this.ComponentManager = Manager;
        Utils.ForEach(this.ActiveComponentManagerConsuemrs, (consumer, i) => {
            if (consumer !== Owner && consumer.onActiveComponentManagerChanged)
                consumer.onActiveComponentManagerChanged();
        });
    },
    ActiveComponentManagerConsuemrs : [],
    RegisterToActiveComponentManager(consumer) {
        this.ActiveComponentManagerConsuemrs.push(consumer);
    },
    UnRegisterToActiveComponentManager(consumer) {
        Utils.Remove(this.ActiveComponentManagerConsuemrs, consumer);
    }
};

export default Globals;

Globals.print_firebasepath = (option) => {
    let componentId;
    if (option === 'component') {
        componentId = Globals.ComponentManager.Id;
    }
    GetDataApi().print(Globals.ProjectManager.Id, componentId);
}