import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout
} from '../../../importer';

import { DotMenu } from '../../project/panels/left/common';
import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import ProjectList, { ProjectCard } from '../list';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

export default class TeamAndProjects extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            showDeletedProjects : false
        };

        this.ShowDeletedProjects = this.ShowDeletedProjects.bind(this);
        this.ShowMenu = this.ShowMenu.bind(this);
        this.NewProject = this.NewProject.bind(this);        
        this.AcceptInvite = this.AcceptInvite.bind(this);
    }
    
    ShowMenu() {
        this.setState({showMenu : !this.state.showMenu});
    }
    NewProject() {
        this.props.onNewProject(this.props.id);
    }
    AcceptInvite(invite) {
        GetDataApi().accept_team_invitaion(invite.id, invite.memberId, invite.invitationId).then(() => {
            this.props.onLoad();
        })
    }
    ShowDeletedProjects() {
        this.setState({showDeletedProjects : true});
    }
    render() { 
        if (!this.props.active && !this.props.owner) {
            return (
                <InfoPanel style={{
                    padding : '16px',
                    display : 'grid',
                    gridTemplateColumns : '1fr auto auto',
                    gridGap : '16px',
                    alignItems : 'center',
                    fontSize : '12px'
                }}>
                    <SC.TextDivAbbr>{'Owner'} invited to {this.props.name} as {this.props.memberType}</SC.TextDivAbbr>
                    <SC.LinkText onClick={this.AcceptInvite.bind(this)}>Accept</SC.LinkText>
                    <SC.LinkText>Decline</SC.LinkText>
                </InfoPanel>
            )
        }
        return (
            <Draggable key={this.props.id}  draggableId={this.props.id} index={this.props.index}>
                {
                    (provided, snapshot) => (
                        <div {...provided.draggableProps} 
                            style={{
                                ...provided.draggableProps.style, ...SC.Styles.Flex.Column, marginTop : '24px', marginBottom : '16px',                                
                            }} ref={provided.innerRef}>
                            <SC.HoverParent 
                                defaultStyle={{
                                    ...SC.Styles.Flex.RowAlcJsb, position : 'relative',
                                    borderBottom : SC.CurrentTheme.theme.border_seperator,
                                    paddingBottom : '16px'
                                }}>
                                {/* <SC.HoverChild defaultStyle={{ width : '24px', ...SC.Styles.Flex.Cell, position : 'absolute', right : '100%', top : '50%', transform : 'translateY(-50%)'}} >
                                    <SC.DragBox {...provided.dragHandleProps} first style={{...provided.dragHandleProps.style, height : 'unset', marginLeft : '4px', border : 'none'}} onMouseDown={(e) => e.stopPropagation()}>
                                        <SC.Icons.DragIcon  style={{pointer : 'unset'}}/>
                                    </SC.DragBox>
                                </SC.HoverChild> */}
                                <SC.FRow alc jsb f1 style={{fontSize : '18px', fontWeight : 500, flex : 1, paddingLeft : '4px'}}>
                                    <div >{this.props.name}</div>    
                                    {!this.props.ownedTeams && <div style={{fontSize : '12px', marginRight : '16px'}}>{AppState.Team.GetTypeName(this.props.memberType)}</div>}
                                </SC.FRow>
                                
                                <SC.FRow alc>                                                                
                                    <DotMenu onClick={this.ShowMenu} isOpen={this.state.showMenu} onClose={this.ShowMenu}>
                                    {
                                        this.state.showMenu &&
                                        <SC.FCol style={{minWidth : '160px'}}>
                                            {
                                                (this.props.owner || this.props.admin)  && 
                                                <SC.PopupItem onClick={this.props.onSettings}>
                                                    Team Settings
                                                </SC.PopupItem>
                                            }                                            
                                            <SC.PopupItem onClick={this.NewProject}>
                                                Create New Project
                                            </SC.PopupItem>
                                            {
                                                this.props.owner && 
                                                <SC.PopupItem onClick={this.props.onDelete}>
                                                    Delete Team
                                                </SC.PopupItem>
                                            }
                                            {
                                                !this.props.owner && 
                                                <SC.PopupItem onClick={this.props.onLeaveTeam}>
                                                    Leave Team
                                                </SC.PopupItem>
                                            }
                                        </SC.FCol>
                                    }
                                </DotMenu>
                                </SC.FRow>                                
                            </SC.HoverParent>
                            {
                                (this.props.projects.length > 0 || this.props.deleted) ?
                                <SC.FRow alc jsb style={{
                                    fontSize : '14px', 
                                    paddingLeft : '4px', 
                                    fontWeight : 500, 
                                    letterSpacing : '1.2px',
                                    marginTop : '16px',
                                    color : SC.CurrentTheme.theme.text_card_title,
                                    marginBottom : '16px'
                                }}>
                                    {
                                        this.state.showDeletedProjects ? 
                                        <SC.BackClosableTitle title='Deleted Team Projects' titleStyle={{fontSize : 'inherit'}} style={{marginLeft : '-8px'}} onClose={() => {this.setState({showDeletedProjects : false})}} /> :
                                        <div>Team Projects</div>
                                    }
                                    <SC.FRow alc>
                                    {
                                        this.props.deleted && !this.state.showDeletedProjects ?
                                        <React.Fragment>
                                            <SC.LinkText style={{fontSize : '14px'}} onClick={this.ShowDeletedProjects}>
                                                {this.props.deleted} Deleted
                                            </SC.LinkText>
                                            <SC.SortAndFilter.StylePanel_Total_Border style={{marginLeft : '8px', marginRight : '8px'}} />
                                        </React.Fragment>
                                         : null
                                    }         
                                    <SC.Icons.Icon_ButtonBox hasFill hasCursor onClick={this.NewProject} title='Create New Team Project'>
                                        <SC.Icons.Icon_Plus size={24} />
                                    </SC.Icons.Icon_ButtonBox>   
                                    </SC.FRow>                            
                                </SC.FRow> : null
                            }                                                
                            <ProjectList 
                                teamId={this.props.id} 
                                filterProject={this.props.filterProject} 
                                sortMode={this.props.sortMode}
                                projects={this.props.projects}
                                onLoad={this.props.onLoad}
                                onNew={this.NewProject}
                                deleted={this.state.showDeletedProjects}
                                shareable={this.props.shareable}
                                admin={this.props.admin}
                                onGetTeams={this.props.onGetTeams}
                                canDelete={this.props.ownedTeams || this.props.admin}
                            />
                        </div>
                    )
                }
            </Draggable>
        )
        return (  
            <div style={{
                padding : '16px',
                backgroundColor : SC.CurrentTheme.theme.back_lighter,
                borderRadius : '4px',
                border : SC.CurrentTheme.theme.border_seperator,
                marginTop : '16px'
            }}>
                {this.props.name}
            </div>
        );
    }
}
 
