import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils
} from '../../importer';
import styled, {css} from 'styled-components';

const RadioItemConteiner = styled.div`
    display : flex;
    justify-content : flex-start;
    padding-left : 8px;
    padding-right : 8px;
    align-items : center;
    min-height : ${props => props.small ? 24 : (props.large ? 44 : 30)}px;
    border : ${props => props.theme.border_onlight_line};
    border-radius : 2px;
    margin : 4px;
    flex : 1;
    display : flex;
    cursor : pointer;
`;

const RadioButtonBox = styled.div`
    width : 14px;
    height : 14px;
    border-radius : 50%;
    margin-right : 6px;
    border : ${props => props.theme.isLight ? '1px solid #959595' : '1px solid #565656'};
    box-sizing : border-box;
    display : flex;
    justify-content : center;
    align-items : center;
`;
const RadioButtonInner = styled.div`
    width : 8px;
    height : 8px;
    border-radius : 50%;
    background-color : ${props => props.selected ? props.theme.color_brand : 'transparent'};    
`;

const RadioItem = (props) => {
    const style = {};
    const style_box = {};
    const style_pin = {};
    if (props.first || props.vertical)
        style.marginLeft = 0;
    if (props.last || props.vertical)
        style.marginRight = 0;
    if (props.small) {
        style.margin = '2px';
        style.marginTop = 0;
        style.marginBottom = 0;

        style_box.width = '12px';
        style_box.height = '12px';
        if (SC.CurrentTheme.theme.isLight) {
            style_pin.width = '8px';
            style_pin.height = '8px';
        }
        else {
            style_pin.width = '6px';
            style_pin.height = '6px';
        }
        
    }
    

    return (
        <RadioItemConteiner small={props.small} selected={props.selected} onClick={props.onSelect} large={props.large} style={Utils.Merge(style, props.style)}>
            <RadioButtonBox style={style_box}>
                <RadioButtonInner selected={props.selected} style={style_pin} />
            </RadioButtonBox>
            {props.labelComponent ? props.text : (props.renderLabel ? props.renderLabel(props.item) : <div>{props.text}</div>)}            
        </RadioItemConteiner>
    )
}

export default class RadioGroup extends ReactBaseComponent
{
    constructor(props) {
        super(props);    
    }    
    Select(item) {
        this.props.onSelect && this.props.onSelect(item.id, item);
    }
    renderCustom() {       
        const Items = [];
        if (this.props.items) {
            Utils.ForEach(this.props.items,(item, i) => {
                Items.push(
                    <RadioItem 
                        small={this.props.small}
                        vertical={this.props.vertical}
                        first={i === 0}
                        last={i === this.props.items.length - 1}
                        selected={item.id === this.props.value} 
                        key={item.id} 
                        value={item.id} 
                        item={item}
                        text={item.label || item.id} 
                        labelComponent={this.props.labelComponent}
                        onSelect={this.Select.bind(this, item)}
                        large={this.props.large}
                        style={this.props.itemStyle}
                        renderLabel={this.props.renderLabel}
                    />
                )
            });
        }
        if (this.props.Container && this.props.Container.Type) {
            return (
                <this.props.Container.Type {...this.props.Container.Props}>
                    {Items}
                </this.props.Container.Type>
            )
        }
        return (
            <SC.FRow style={this.props.style}>
                {Items}
            </SC.FRow>
        )              
    }
}