import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    Loading,
    Strings,
    Globals,
    MetaData
} from '../../../../../../../importer';
import { GroupTitle, ItemNameEditor, TokenPanelHeader } from '../../common';
import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import { DraggableEditorBox, DraggableEditorHandle } from '../../../../../../../components/editors/transform/transformItemEditor';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import { DataTypeList } from '../../../../../../../../toolabs-modules/toolabs-metaui/datatypes';

export default class MockupSchemaEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);     

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.Close = this.Close.bind(this);

        this.AddField = this.AddField.bind(this);
        this.onSortField = this.onSortField.bind(this);

        this.Ref_DataEditor = React.createRef();

        this.Load(props.id);
    }    
    Load(id) {
        this.collection = Globals.ProjectManager.Mockups.Collection(id) || {};        
        this.fields = [];
        if (this.collection) {
            Utils.ForEach(this.collection.fields, (fieldid, i) => {
                const field = Globals.ProjectManager.Mockups.Field(fieldid);
                if (field) {
                    this.fields.push({
                        id : fieldid,
                        name : field.name,
                        model : field
                    });
                }            
            });
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.id !== nextProps.id) {
            this.Load(nextProps.id);
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Mockups.Collections();
    }        
    Close() {
        if (!this.props.onClose()) {
            this.props.onHardClose && this.props.onHardClose();
        }
    }
    SaveName(name) {
        this.collection.name = name;
        Globals.ProjectManager.Mockups.SaveCollection(this.props.id, this.collection);
        this.RCUpdate();
    } 
    EditRows() {
        Events.BCE(Events.DESIGNER.BOARD.MOCKUPDATA.EDIT, {
            collectionId : this.props.id,
            RegisterClose : (close) => {
                // onAddCloseBack && onAddCloseBack(close);
                // registerClose && registerClose(close);                
            }                   
        });
    }
    AddField() {
        const id = Globals.ProjectManager.Mockups.AddField({collectionId : this.props.id, dataType : MetaData.DataTypes.string});
        this.fields.push({
            id : id,
            model : Globals.ProjectManager.Mockups.Field(id)
        });
        this.FillFocusToField = id;
        this.RCUpdate();
        if (this.Ref_DataEditor.current) {
            this.Ref_DataEditor.current.Load();
            this.Ref_DataEditor.current.RCUpdate();
        }
        this.props.onFieldsChanged && this.props.onFieldsChanged();
    }
    componentDidUpdate(prevProps, prevState) {
        delete this.FillFocusToField;
    }
    onSortField(oldIndex, newIndex) {
        Globals.ProjectManager.Mockups.ChangeFieldOrder(this.props.id, oldIndex, newIndex);        
        this.RCUpdate();
    }   
    onEditField(id) {

    }
    onDeleteField(id) {
        Globals.ProjectManager.Mockups.DeleteField(id);        
        Utils.Remove(this.fields, (item) => {return item.id === id});
        this.RCUpdate();
        if (this.Ref_DataEditor.current) {
            this.Ref_DataEditor.current.Load();
            this.Ref_DataEditor.current.RCUpdate();
        }
        this.props.onFieldsChanged && this.props.onFieldsChanged();
    }
    renderCustom() {

        const fieldItems = [];

        Utils.ForEach(this.fields, (field, i) => {                               
            fieldItems.push(
                <SortableListItem
                    key={field.id}
                    draggableId={field.id}
                    index={i}
                    ItemBoxType={CollectionFieldItem}
                    isTranfsormed
                    BoxProps={{
                        id : field.id,
                        model : field.model,
                        willFocus : field.id === this.FillFocusToField,
                        notFirst : i > 0,
                        onClick : this.onEditField.bind(this, field.id),
                        onDelete : this.onDeleteField.bind(this, field.id),
                        onUpdate : () => {
                            if (this.Ref_DataEditor.current) {
                                this.Ref_DataEditor.current.Load();
                                this.Ref_DataEditor.current.RCUpdate();
                            }
                            this.props.onFieldsChanged && this.props.onFieldsChanged();
                        },
                        GetFields : () => {
                            return this.fields
                        }
                    }}                
                />
            )
        });
        return (
            <React.Fragment>
                <TokenPanelHeader title='DATA COLLECTION' 
                    hasAddCancel 
                    onClose={this.Close} 
                    onCancel={this.props.onCancel} 
                    onAdd={() => {
                        this.props.onClose();
                    }} 
                    onDelete={this.props.isNew ? null : this.props.onDelete}
                    style={{height : '36px', padding : 0, paddingRight : '8px', borderBottom : 'none'}}
                />
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.CustomScrollbars>
                        <SC.FCol f1 style={{padding : '8px'}}>
                            <ItemNameEditor
                                noMargin
                                fontSize='12px'                        
                                name={this.collection.name}
                                onSaveName={this.SaveName}
                                onGetItems={this.GetItems}
                                model={this.collection}
                            />
                            <GroupTitle 
                                title='Fields' 
                                subGroup
                                hasAdd={{onAdd : this.AddField}}     
                                style={{padding : 0, marginTop : '16px'}}                
                            />
                            <SortableList
                                style={{
                                    marginTop : '4px',
                                    marginLeft : '-12px', marginRight : '-8px',
                                    transition : 'all 0.2s ease',
                                    fontSize : '11px'
                                }}
                                onSort={this.onSortField}
                            >
                                {fieldItems}
                            </SortableList>
                        </SC.FCol>
                    </SC.CustomScrollbars> 
                </SC.FCol>
            </React.Fragment>       
        )
    }
}


class CollectionFieldItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.SaveName = this.SaveName.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        this.state.ShouldUpdate = false;
    }
    onChangeType(type) {
        this.props.model.dataType = type;
        Globals.ProjectManager.Mockups.SaveField({id : this.props.id, prop : 'dataType', value : type});
        this.setState({ShouldUpdate : true})
        this.props.onUpdate();
    }
    SaveName(name) {
        Globals.ProjectManager.Mockups.SaveField({id : this.props.id, prop : 'name', value : name});
        this.props.onUpdate();
    }
    render() { 
        const {model} = this.props;
        return (  
            <DraggableEditorBox notFirst={this.props.notFirst}>
                <DraggableEditorHandle {...this.props.sortableProps.handleProps} style={{paddingLeft : '8px', paddingRight : '4px'}} ><SC.Icons.DragIcon xsmall /></DraggableEditorHandle>
                <SC.FCol f1>
                    <SC.FRow justifyEnd>
                        
                    </SC.FRow>
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 2fr',
                        gridGap : '8px',
                        flex : 1,
                        padding : '8px',
                        alignItems : 'center'
                    }}>
                        <div>Name</div>
                        <SC.FRow alc >
                            <ItemNameEditor
                                noMargin
                                noLabel
                                dark
                                autoFocus={this.props.willFocus}
                                InputType={SC.Form_Input}
                                fontSize='12px'                        
                                name={model.name}
                                onSaveName={this.SaveName}
                                onGetItems={this.props.GetFields}
                                model={model}
                                themeId={AppLayout.ThemeId}
                            />  
                            <SC.Icons.Icon_Button hasFill style={{cursor : 'pointer', marginRight : '-8px'}} onClick={this.props.onDelete} >
                                <SC.Icons.Icon_Delete size={16}/>
                            </SC.Icons.Icon_Button>
                        </SC.FRow>                        
                        <div>Data Type</div>
                        <DropDownSelect 
                            hasBorder            
                            autoHeight
                            xsmall
                            toRight
                            fitWidth
                            style={{flex : 1}}
                            items={DataTypeList}
                            value={model.dataType}
                            onChange={this.onChangeType}
                        />
                    </div>
                </SC.FCol>                
            </DraggableEditorBox>
        );
    }
}