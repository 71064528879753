import {
    AppState,
    Events,
    Strings,
    MetaData,
    Utils,
    Globals
} from '../../../../importer';

import { LOG_TYPES } from '../../../../appstate/historymanager/base';


export default class ComponentStateManager {
    constructor(Manager) {
        this.State = 'Default';

        this.Manager = Manager;
    }
        

    Get() {
        return this.States();
    }
    Order() {
        return this.States().Order || [];
    }
    States() {
        return this.Manager.DataManager.Get({}, Strings.STATES)  || {};
    }
    PseudoStates() {
        return this.Manager.DataManager.Get({}, Strings.PSEUDOSTATES);
    }
    DeleteMetaItemState(MetaItem, StateId) {
        const deleteStates = [];
        Utils.ForEach(MetaItem.Property, (ComponentStates, GlobalState) => {
            Utils.ForEach(ComponentStates, (StateProps, ComponentState) => {
                if (ComponentState.indexOf(StateId) > -1) {
                    deleteStates.push(ComponentState);   
                }                
            });
        });

        Utils.ForEach(MetaItem.Tokens, (ComponentStates, GlobalState) => {
            Utils.ForEach(ComponentStates, (StateProps, ComponentState) => {
                if (ComponentState.indexOf(StateId) > -1) {
                    Utils.ForEach(StateProps, (PropValue, PropName) => {
                        if (PropValue && PropValue.RelationId) {
                            Globals.ProjectManager.RelationManager.DeleteRelationId(PropValue.RelationId, true);
                        }
                    });
                }                
            });
        });

        Utils.ForEach(MetaItem.Property, (ComponentStates, GlobalState) => {
            Utils.ForEach(deleteStates, (delState) => {
                MetaItem.Property && delete MetaItem.Property[GlobalState][delState];
            });
        });
        Utils.ForEach(MetaItem.Tokens, (ComponentStates, GlobalState) => {
            Utils.ForEach(deleteStates, (delState) => {
                MetaItem.Tokens && delete MetaItem.Tokens[GlobalState][delState];
            });
        });
    }
    DeleteStateRelations(StateId) {
        Globals.ProjectManager.RelationManager.DeleteStateRelations(this.Manager.Id, StateId);
        this.Manager.RelationManager.DeleteStateRelations(this.Manager.Id, StateId);
    }
    Delete(id) {
        const States = this.States();

        this.Manager.Log({
            Target : LOG_TYPES.STATE_CHANGE,
            Id : id,
            Desc : `Delete State : ${Utils.Get(States, '', id, 'name')}`
        });

        Utils.RemoveEquals(States.Order, id);
        this.Manager.DataManager.Set(States.Order, Strings.STATES, Strings.ORDER);
        if (States[id]) {
            let State = States[id];
            this.Manager.DataManager.Delete(Strings.STATES, id);

            // Delete Item Styles
            const MetaItems_Component = this.Manager.GetMetaItems();
            Utils.ForEach(MetaItems_Component, (MetaItem, Id) => {
                if (State.Variations) {
                    Utils.ForEach(State.Variations, (v, vid) => {
                        this.DeleteMetaItemState(MetaItem, vid);
                    });
                }
                else
                    this.DeleteMetaItemState(MetaItem, id);
            });


            const Models = this.Manager.Variables();
            if (Models && Models.States) {
                const delStates = [];
                if (State.Variations) {
                    Utils.ForEach(State.Variations, (v, vid) => {
                        Utils.ForEach(Models.States, (msv, mdid) => {
                            if (mdid.indexOf(vid) > -1)
                                delStates.push(mdid);
                        });
                    });
                }
                else {
                    Utils.ForEach(Models.States, (msv, mdid) => {
                        if (mdid.indexOf(id) > -1)
                            delStates.push(mdid);
                    });
                }

                Utils.ForEach(delStates, (sid) => {
                    this.Manager.DataManager.Delete(Strings.MODELS, 'States', sid);
                });
            }

            if (State.Variations) {
                Utils.ForEach(State.Variations, (v, vid) => {
                    this.DeleteStateRelations(vid);
                });
            }
            else {
                this.DeleteStateRelations(id);
            }

            if (this.State == State.name)
                this.SetState('Default');
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED);
        }
    }
    AddState(name) {
        const Id = Utils.Id();
        this.Manager.Log({
            Target : LOG_TYPES.STATE_CHANGE,
            Id : Id,
            Desc : `Add State : ${name}`
        });

        const States = this.States();
        // todo : check unique name
        States[Id] = {
            name: name,
            SingleVariation: true
        };
        this.Manager.DataManager.Set(States[Id], Strings.STATES, Id);
        let Order = Utils.Get(States, [], Strings.ORDER);                
        Order.push(Id);
        this.Manager.DataManager.Set(Order, Strings.STATES, Strings.ORDER);

        Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED);
        return Id;
    }
    ChangeOrder(oldIndex, newIndex) {
        this.Manager.Log({
            Target : LOG_TYPES.STATE_CHANGE,
            Desc : `Change State Order`
        });
        const list = this.States();
        Utils.ChangePlace(list.Order, oldIndex, newIndex);
        this.Manager.DataManager.Set(list.Order, Strings.STATES, Strings.ORDER);
    }
    ChangeVariationOrder(stateid, oldIndex, newIndex) {        
        const stateModel = this.GetState(stateid);
        const Variations = Utils.Get(stateModel, null, 'Variations') || {};
        const Ordered = Utils.Get(Variations, [], 'Order');
        Utils.ChangePlace(Ordered, oldIndex, newIndex);
        this.UpdateState(stateid, stateModel);
    }
    AddStateVariation(stateid, name) {
        const stateModel = this.GetState(stateid);
        const Variations = Utils.Get(stateModel, null, 'Variations') || {};
        const Ordered = Utils.Get(Variations, [], 'Order');
        const Id = Utils.Id();
        Ordered.push(Id);
        Variations.Order = Ordered;
        Variations[Id] = {name : name};
        stateModel.Variations = Variations;
        stateModel.SingleVariation = false;
        this.UpdateState(stateid, stateModel);
        return Id;
    }
    DeleteStateVariation(stateid, variationId) {
        const stateModel = this.GetState(stateid);
        Utils.RemoveEquals(stateModel.Variations.Order, variationId);        
        delete stateModel.Variations[variationId];
        if (stateModel.Variations.Order.length === 0)
            stateModel.SingleVariation = true;
        this.UpdateState(stateid, stateModel);
    }
    UpdateState(id, state) {
        this.Manager.DataManager.Set(state, Strings.STATES, id);
    }
    UpdateStateProp(Id, Prop, Value) {
        this.Manager.DataManager.Set(Value, Strings.STATES, Id, Prop);
    }
    GetState(id) {
        return this.Manager.DataManager.Get({}, Strings.STATES, id);
    }
    SetState(State) {
        this.State = State;
        Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED, this.StateVariation);
    }
    GetSelectedVariation() {
        return Utils.Get(this, Strings.DEFAULT, Strings.STATEVARIATION);
    }
    IsStateOn(CombinedState, StateToCheck) {
        if (CombinedState) {
            let States = CombinedState.split(',');
            if (States && States.length > 0) {
                return Utils.Includes(States, StateToCheck);
            }
        }                
        return false;
    }
    SetGlobalState(GlobalState) {
        this.GlobalState = GlobalState;
        if (GlobalState === 'Default')
            this.GlobalState = 'Default';
        this.CurrentStateVariation = this.GetCurrentState();
        this.BuildStateArray();
        Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION, this.StateVariation);
    }
    GetGlobalState() {
        return this.GlobalState || 'Default';
    }
    CurrentState() {
        if (!this.CurrentStateVariation)
            this.CurrentStateVariation = this.GetCurrentState();
        return this.CurrentStateVariation;
    }
    GetCustomState(SourceStateVariation) {
        let StateVariation = SourceStateVariation || this.StateVariation;
        let State = '';
        if (StateVariation) {
            const States = this.States();
            const StateOrder = Utils.Get(States, [], Strings.ORDER);
            let notEmpty = false;
            Utils.ForEach(StateOrder, (StateId) => {
                if (StateVariation[StateId]) {
                    if (States[StateId].ResetOthers)
                        State = '';
                    State += `${notEmpty ? ',' : ''}${StateVariation[StateId]}`;
                    notEmpty = true;
                }
            })
        }
        return Utils.UseNullOrEmpty(State, 'Default');
    }
    GetCurrentState(GlobalState) {
        let State = this.GetCustomState();
        const PseudoState = this.GetCurrentPseudoState();
        if (Utils.IsNotNullOrEmpty(PseudoState)) {
            if (Utils.IsNotNullOrEmpty(State))
                State = State + ',' + PseudoState;
            else
                State = PseudoState;
        }
            
        if (Utils.IsNotNullOrEmpty(State))
            return State;
        return Strings.DEFAULT;
    }
    GetCurrentPseudoState(SourceStateVariation) {
        let StateVariation = SourceStateVariation || this.PseudoStateVariation;
        let State = '';
        if (StateVariation) {
            const States = this.PseudoStates();
            const StateOrder = Utils.Get(States, [], Strings.ORDER);
            let notEmpty = false;
            Utils.ForEach(StateOrder, (StateId) => {
                if (StateVariation[StateId]) {
                    if (States[StateId].ResetOthers)
                        State = '';
                    State += `${notEmpty ? ',' : ''}${StateVariation[StateId]}`;
                    notEmpty = true;
                }
            })
        }
        if (Utils.IsNotNullOrEmpty(State))
            return State;
        return '';
    }
    MergeWithCustomStates(SourceStateVariation) {
        let State = this.GetCustomState();
        // if (!Utils.IsNotNullOrEmpty(State))
        //     State = Strings.DEFAULT;
        
        const States = this.PseudoStates();
        const StateOrder = Utils.Get(States, [], Strings.ORDER);
        let notEmpty = Utils.IsNotNullOrEmpty(State);
        Utils.ForEach(StateOrder, (StateId) => {
            if (SourceStateVariation[StateId]) {
                State += `${notEmpty ? ',' : ''}${SourceStateVariation[StateId]}`;
                notEmpty = true;
            } 
        })

        if (Utils.IsNotNullOrEmpty(State))
            return State;
        return Strings.DEFAULT;
    }
    SetToDefault(silent) {
        Utils.UnSet(this, Strings.STATEVARIATION);
        if (!silent) {
            this.CurrentStateVariation = this.GetCurrentState();
            this.BuildStateArray();
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION, this.StateVariation);
        }                
    }
    SetPseudoToDefault(silent) {
        Utils.UnSet(this, Strings.PSEUDOSTATEVARIATION);
        if (!silent) {
            this.CurrentStateVariation = this.GetCurrentState();
            this.BuildStateArray();
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION, this.StateVariation);
        }                
    }
    SetSelectedPseudoVariation(StateId, VariationId) {
        if (VariationId)
            Utils.Set(this, VariationId, Strings.PSEUDOSTATEVARIATION, StateId);
        else
            Utils.UnSet(this, Strings.PSEUDOSTATEVARIATION, StateId);

        this.CurrentStateVariation = this.GetCurrentState();

        this.BuildStateArray();

        this.Manager.SetState(this.Manager.GlobalState, this.CurrentStateVariation);
        // Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED, this.StateVariation);
        Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION, this.StateVariation);
    }
    SetSelectedVariation(StateId, VariationId, internal) {
        if (VariationId)
            Utils.Set(this, VariationId, Strings.STATEVARIATION, StateId);
        else
            Utils.UnSet(this, Strings.STATEVARIATION, StateId);

        this.CurrentStateVariation = this.GetCurrentState();

        this.BuildStateArray();
        this.Manager.SetState(this.Manager.GlobalState, this.CurrentStateVariation);
        // Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED, this.StateVariation);
        if (!internal)
            Events.BCE(Events.DESIGNER.COMPONENT.STATE.CHANGED_VARIATION, this.StateVariation);
    }
    BuildStateArray() {
        const currentState = this.CurrentState();
        this.StateArray = Utils.States.BuildStateLabelFromVariations({StateLabel : currentState} );
        this.CurrentStateVariation = this.GetCurrentState();
    }    
}
