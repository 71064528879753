/*
applinist@gmail.com
Free plan
Client Id : UtemAaWYGL3BiBSOvBeNiX0ePK0GDVhDMozYUa129AGnrVmtwq32p1Ol9NZFTMTI
Client Secret : LNfJwyueeagGSNW9xJ1wwfXffZoOY5NpeyCndgqmnDI4x68CfdltmjIlA8M7EbzP
*/

import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Strings,
    UIUtils,
    ReactPureComponent,
    MetaData
} from '../../importer';
import styled from 'styled-components';
import Scrollbars from 'react-custom-scrollbars';
import VisibilitySensor from 'react-visibility-sensor';

import get from 'simple-get';
import DropDownSelect from '../../components/editors/enum_dropdown';
import RadioGroup from '../../components/editors/radiogroup';
import DragSource from '../../components/dragdrop/HoCDragSource';
import LightDarkBar from '../../components/light_dark_bar';


export class MaterialThumbNail extends React.Component
{
    constructor(props) {
        super(props);
    }    
    shouldComponentUpdate(nextProps, nextState) {        
        
        if (Utils.HasAnyChange(this.props, nextProps, 'dark', 'hasSelect'))
            return true;
        return false;
    }
    render() {

        const { dark, icon, hasSelect, ...rest} = this.props;


        const style = {
            position:'relative', borderRadius:'2px', width : '100%', height:'100%', backgroundColor : dark ? '#1d1e1f' : '#dedede', cursor:'pointer'
        };
        if (this.props.DragItem) {
            style.cursor = 'move';
        }
        return (
            <SC.Div_Flex_Cell style={style} onClick={hasSelect.onSelect} {...rest}>
                <svg xmlns='http://www.w3.org/2000/svg' width='70%' height='70%' viewBox={`0 0 24 24`} fill={!dark ? '#1d1e1f' : '#dedede'}>
                    {
                        icon.Paths.map((p, i) => {
                            return (
                                <path key={i} d={p} />
                            )
                        })
                    }
                </svg>
                {
                    this.props.DragItem ? null : 
                    <SC.Overlay
                        style={Utils.Merge({                        
                            fontSize : '16px',                        
                            textAlign:'center'
                        }, hasSelect.selected ? {opacity :  1} : null)}
                    >
                        Click to {hasSelect.selected ? 'deselect' : 'select'}
                    </SC.Overlay>
                }                
            </SC.Div_Flex_Cell>
        )
    }
}

const DraggableMaterialIcon = DragSource(MaterialThumbNail);

export default class GoogleMaterialIcons extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Select = this.Select.bind(this);
        // const boardicons = AppState.Data.Get.Board.Assets.AssetList(AppState.Data.Get.Board.Assets.Types.Icons);
        // this.SelectedIcons = {};
        // Utils.ForEach(boardicons,(ic, id) => {
        //     if (ic.MetaIcon && ic.MetaIcon.Id)
        //         this.SelectedIcons[ic.MetaIcon.Id] = true;
        // });
    }
    componentDidMount() {
        super.componentDidMount();
        AppState.Assets.SystemIcons().then((icons) => {
            if (icons && icons.value) {
                this.Icons = [];
                Utils.ForEach(icons.value,(icon, id) => {
                    this.Icons.push({
                        id : id,
                        icon : icon
                    })
                });
                             
                this.LastIndex = 0;
                this.LoadedList = [];
                this.LoadPage();
                this.RCUpdate();
            }            
        })
    }    
    LoadPage(props = this.props) {
        let added = 0;
        
        let filter;
        if (props.filter)
            filter = props.filter.toUpperCase();

        while (added < 120) {
            if (this.Icons && this.LastIndex < this.Icons.length) {
                const icon = this.Icons[this.LastIndex];
                this.LastIndex++;
                let willAdd = true;
                if (filter) {
                    const name = icon.icon.Name.toUpperCase();
                    if (name.indexOf(filter) < 0) {
                        willAdd = false;
                    }
                }
                if (willAdd) {
                    this.LoadedList.push(icon);
                    added++;
                }              
            }
            else
                break;
        }
        if (added > 0) {            
            this.LoaderId = Utils.Id();
        }
        else {
            this.LoaderId = null;
        }                     
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (nextProps.filter !== this.props.filter) {
            this.LastIndex = 0;
            this.LoadedList = [];
            this.LoadPage(nextProps);
        }
        return true;
   }

    Select(icon) {
        this.props.onSelect(icon, Strings.ICON_GOOGLE_MATERIAL);
    }
    PreviewPattern(show, icon) {
        this.WillShowPreview = {
            show : show,
            icon : icon
        }
        setTimeout(() => {
            if (this.WillShowPreview) {
                this.props.onPreviewChange(this.WillShowPreview.show, {provider : Strings.ICON_GOOGLE_MATERIAL, paths : this.WillShowPreview.icon.Paths});
                delete this.WillShowPreview;
            }
            
        }, 200);         
    }
    renderCustom() {       
        let content = null;
        if (!this.LoadedList) {
            content = (
                <SC.Div_Flex_Cell fw fh>
                    <SC.Loading_Icon />
                </SC.Div_Flex_Cell>
            )
        }
        else {

            const icons = [];
            Utils.ForEach(this.LoadedList, (icon, i) => {
                let iconItem;
                const props = {

                };
                if (this.props.onPreviewChange) {
                    props.onMouseEnter =this.PreviewPattern.bind(this, true, icon.icon);
                    props.onMouseLeave = this.PreviewPattern.bind(this, false, icon.icon);
                }
                if (this.props.dragsource) {
                    iconItem = (
                        <DraggableMaterialIcon 
                            key={icon.id} 
                            id={icon.id}
                            icon={icon.icon}
                            dark={this.props.Dark}
                            hasSelect={{}}
                            {...props}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.SvgIcon.Type,
                                        Id : icon.id,
                                        Provider : {
                                            provider : Strings.ICON_GOOGLE_MATERIAL,
                                            id : icon.id,
                                            paths : icon.icon.Paths
                                        }
                                    }
                                } 
                            }}
                        />
                    )
                }   
                else {
                    iconItem = (
                        <MaterialThumbNail
                            key={icon.id} 
                            id={icon.id}
                            icon={icon.icon}
                            dark={this.props.Dark}
                            {...props}
                            hasSelect={{
                                onSelect : this.Select.bind(this, icon),
                                selected : Utils.Get(this.props.selectedImages, null, icon.id) ? true : false
                            }}
                        />
                    )
                }                
                                             
                if (i === this.LoadedList.length - 1 && this.LoaderId) {
                    icons.push(
                        <VisibilitySensor
                            key={this.LoaderId}
                            partialVisibility={true}
                            scrollCheck={true}
                            scrollDelay={200}
                        >
                            {
                                ({isVisible}) => {                                    
                                    if (isVisible) {
                                        setTimeout(() => {
                                            this.LoadPage();
                                            this.RCUpdate();
                                        }, 1);                                        
                                    }
                                    return <div />;
                                }                        
                            }
                        </VisibilitySensor>
                    )
                }
                
                icons.push(iconItem);
            });

            let grid_style = {
                display : 'grid',
                gridGap : '16px',
                gridTemplateColumns : 'repeat(auto-fill, minmax(80px, 1fr))',
                gridAutoRows : 'minmax(80px, 1fr)',
                padding : this.props.largeView ? '24px' : '8px',
                paddingTop : 0
            }
            if (this.props.justContent) {
                grid_style = {
                    display : 'grid',
                    gridGap : '4px',
                    gridTemplateColumns : 'repeat(auto-fit, minmax(70px, 1fr))',
                    gridAutoRows : '80px',
                    padding : this.props.largeView ? '24px' : '8px',
                    paddingTop : 0
                }
            }
            content = (
                <div style={grid_style} >
                    {icons}       
                </div>
            )            
        }
        if (this.props.justContent)
            return content;
        return (
            <SC.FCol f1>          
                <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                    {content}
                </SC.CustomScrollbars>  
            </SC.FCol>            
        )
    }
}
var Provider = Strings.ICON_GOOGLE_MATERIAL;
