import BaseHistoryManager from './base';
import Events from '../Events';
import Data from '../AppData';
import Globals from '../Globals';

export default class DesignSystemHistoryManager extends BaseHistoryManager {
    constructor(Manager) {
        super();
        this.Manager = Manager;

        this.CHANGE_TYPES = {
            DELETE: 'CD',
            ADD_CHILD: 'CAC',
            ADD_ARTBOARD: 'CAAB',
            ADD_ROOT: 'CARI',
            SET_STYLE: 'CSS',
            SET_PROP: 'CSP',
            SET_POSITION: 'CSPS',
            SET_SIZE: 'CSSS',
            SET_BINDING_STYLE: 'CSSBS',
            MODEL_ADD : 'MDLADD',
            MODEL_DELETE : 'MDLDEL',
            MODEL_CHANGE : 'MDLCNG',
            STATE_CHANGE : 'STCH',
            EVENTS : 'CECH',
            WRAP : 'CWR',
            CHANGE_ITEMTYPE : 'CCIT'
        };
    }
    GetData() {
        return {
            Id : 'Board',
            Data : this.Manager.Data.Data
        }
    }
    Broadcast(options) {
        if (options.historyChanged)
            Events.BCE(Events.DESIGNER.COMPONENT.HISTORY.LOG);
    }
    ApplyChange(Change, IsUndo) {
        if (Change.Data) {
            if (Change.Data.IsComponent) {
                this.Manager.ReplaceComponentData(Change.Data.Id, Change.Data.Data);
                Events.BCE(Change.Data.Id, Events.PARAMS.REFRESH);
                if (Globals.ActiveComponentManager.DesignManagers[Change.Data.Id]) {
                    Globals.ActiveComponentManager.DesignManagers[Change.Data.Id].UpdateRenderers();
                }
            }
            else if (Change.Data.TokenChange) {
                this.Manager.RestoreTokenChange(Change.Data.Data);
                Events.BroadcastThrottle(Events.GLOBAL.TOKENS_CHANGED);
            }
            else if (Change.Data.Full && Change.Data.Data) {
                Globals.ProjectManager.DataManager.Replace(Change.Data.Data);
            }
        }
        
        Events.BCE(Events.DESIGNER.COMPONENT.HISTORY.CHANGED, IsUndo, Change.Info.Desc);
    }    
}