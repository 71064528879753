import React from 'react';
import BaseItem from './BaseItem';
import {Utils} from '../importer';

export default class Text extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, events, rest}) {

        const style_text = Utils.Merge({            
            whiteSpace : 'pre-line',
            display : 'flex',
            flexDirection: 'column',
            alignItems : 'stretch'            
        }, style);

        let textItem = '';
        if (Utils.IsObject(props.text)) {

        }
        else
            textItem = props.text;

        if (props.truncate) {
            style_text.overflow = 'hidden';
            style_text.maxWidth = '100%';
            textItem = (
                <span style={{
                    textOverflow : 'ellipsis',
                    overflow : 'hidden',
                    whiteSpace : 'nowrap'
                }}>
                    {textItem}
                </span>
            )
        }

        return (
            <div
                ref={(r) => this.Ref_Instance = r}
                style={style_text}
                {...events}
                {...rest}
            >
                {textItem}
            </div>
        )
    }
}