/* eslint-disable */
import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import Events from './appstate/Events';
import {Utils} from './toolabs-importer';

const duration = 500;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
  }
  
  const transitionStyles = {
    entering: { opacity: 0 },
    entered:  { opacity: 1 },
  };

class ReactPureComponent extends React.PureComponent
{
    constructor(props) {
        super(props);

        this.state = {
            ShouldUpdate : false
        };
        this.RCUpdate = this.RCUpdate.bind(this);
        this.RCThrottledUpdate_0_5 = Utils.Throttle(this.RCUpdate, 50);
        this.RCThrottledUpdate_1 = Utils.Throttle(this.RCUpdate, 100);
        this.RCThrottledUpdate_2 = Utils.Throttle(this.RCUpdate, 200);
        this.RCThrottledUpdate_3 = Utils.Throttle(this.RCUpdate, 300);
    }
    componentDidMount() {
        this.Mounted = true;
        if (this.Events) {
            Events.ListenEvents(this, this.Events);
        }
        if (this.Transitions) {
            this.TransitionIn = true;
            this.RCUpdate();
        }
    }
    Exit() {

    }
    componentWillUnmount() {
        this.Mounted = false;
        if (this.Events) {
            this.Events.map((e) => Events.DropListen(e, this));
        }
    }
    ListenEvents(...Ids) {
        this.Events = Utils.Union(this.Events, Ids);
    }
    RCSetStateValue(Property, Value) {
        if (this.state) {
            if (this.Mounted)
            {
                let state = {};
                state[Property] = Value;
                this.setState(state);
            }
        }
        else {
            this.state = {};
            this.state[Property] = Value;
        }
    }
    RCSetState(cb) {
        if (this.Mounted) {
            this.setState((ps) => {
                if (this.Mounted)
                    return cb(ps);
            })
        }
    }
    RCUpdate() {
        this.RCSetStateValue('ShouldUpdate', true);
    }
    RedirectTo(Path) {
        this.RedirectToPath = Path;
        this.RCUpdate();
    }
    render() {
        this.state.ShouldUpdate = false;
        if (this.RedirectToPath) {
            const ReDirectTo = this.RedirectToPath;
            this.RedirectToPath = null;
            return (
                <Redirect push to={ReDirectTo} />
            );
        }
        if (this.renderCustom) {
            let  rh = null;
            // if (this.Transitions) {
            //     rh = (
            //         <Transition in={this.props.in } mountOnEnter={true} timeout={duration}>
            //             {(state) => this.renderCustom({...defaultStyle, ...transitionStyles[state]})}
            //         </Transition>
            //     )
            // }
            // else {
            //     rh = this.renderCustom();
            //     if (rh === undefined) {
            //         return null;
            //     }
            // }
            rh = this.renderCustom();
            if (rh === undefined) {
                return null;
            }
            return rh;
        }                    
        return null;
    }
}

export default ReactPureComponent;
