import Platforms from '../Platforms';
import DataType from '../datatypes';

const borderStyleValues = {
    none : {
        Id : 'none',
        Label : 'No'
    },
    solid : {
        Id : 'solid',
        Label : 'Solid'
    },
    dotted : {
        Id : 'dotted',
        Label : 'Dotted'
    },
    dashed : {
        Id : 'dashed',
        Label : 'Dashed'
    },
    double : {
        Id : 'double',
        Label : 'Double'
    },
    groove : {
        Id : 'groove',
        Label : 'Groove'
    },
    ridge : {
        Id : 'ridge',
        Label : 'Ridge'
    },
    inset : {
        Id : 'inset',
        Label : 'Inset'
    },
    outset : {
        Id : 'outset',
        Label : 'Outset'
    },
    initial : {
        Id : 'initial',
        Label : 'Initial'
    },
    inherit : {
        Id : 'inherit',
        Label : 'Inherit'
    }
};

const Styles = {
    testText : {
        name : 'text',
        type : DataType.string,
        label : 'Text'
    },
    testIcon : {
        name : 'icon',
        type : DataType.SvgIcon,
        label : 'Svg Icon'
    },    
    cursor : {
        name : 'cursor',
        type : DataType.enum,
        label : 'Cursor',
        values : {
            auto : {
                Id : 'auto',
                css : 'auto',
                Label : 'auto'
            },
            default : {
                Id : 'default',
                css : 'default',
                Label : 'default'
            },
            none : {
                Id : 'none',
                css : 'none',
                Label : 'none'
            },
            contextMenu : {
                Id : 'contextMenu',
                css : 'context-menu',
                Label : 'context-menu'
            },
            help : {
                Id : 'help',
                css : 'help',
                Label : 'help'
            },
            pointer : {
                Id : 'pointer',
                css : 'pointer',
                Label : 'pointer'
            },
            progress : {
                Id : 'progress',
                css : 'progress',
                Label : 'progress'
            },
            wait : {
                Id : 'wait',
                css : 'wait',
                Label : 'wait'
            },
            cell : {
                Id : 'cell',
                css : 'cell',
                Label : 'cell'
            },
            crosshair : {
                Id : 'crosshair',
                css : 'crosshair',
                Label : 'crosshair'
            },
            text : {
                Id : 'text',
                css : 'text',
                Label : 'text'
            },
            verticalText : {
                Id : 'verticalText',
                css : 'vertical-text',
                Label : 'vertical-text'
            },
            alias : {
                Id : 'alias',
                css : 'alias',
                Label : 'alias'
            },
            copy : {
                Id : 'copy',
                css : 'copy',
                Label : 'copy'
            },
            move : {
                Id : 'move',
                css : 'move',
                Label : 'move'
            },
            noDrop : {
                Id : 'noDrop',
                css : 'no-drop',
                Label : 'no-drop'
            },
            notAllowed : {
                Id : 'notAllowed',
                css : 'not-allowed',
                Label : 'not-allowed'
            },
            eResize : {
                Id : 'eResize',
                css : 'e-resize',
                Label : 'e-resize'
            },
            nResize : {
                Id : 'nResize',
                css : 'n-resize',
                Label : 'n-resize'
            },
            neResize : {
                Id : 'neResize',
                css : 'ne-resize',
                Label : 'ne-resize'
            },
            nwResize : {
                Id : 'nwResize',
                css : 'nw-resize',
                Label : 'nw-resize'
            },
            sResize : {
                Id : 'sResize',
                css : 's-resize',
                Label : 's-resize'
            },
            seResize : {
                Id : 'seResize',
                css : 'se-resize',
                Label : 'se-resize'
            },
            swResize : {
                Id : 'swResize',
                css : 'sw-resize',
                Label : 'sw-resize'
            },
            wResize : {
                Id : 'wResize',
                css : 'w-resize',
                Label : 'w-resize'
            },
            ewResize : {
                Id : 'ewResize',
                css : 'ew-resize',
                Label : 'ew-resize'
            },
            nsResize : {
                Id : 'nsResize',
                css : 'ns-resize',
                Label : 'ns-resize'
            },
            neswResize : {
                Id : 'neswResize',
                css : 'nesw-resize',
                Label : 'nesw-resize'
            },
            nwseResize : {
                Id : 'nwseResize',
                css : 'nwse-resize',
                Label : 'nwse-resize'
            },
            colResize : {
                Id : 'colResize',
                css : 'col-resize',
                Label : 'col-resize'
            },
            rowResize : {
                Id : 'rowResize',
                css : 'row-resize',
                Label : 'row-resize'
            },
            allScroll : {
                Id : 'allScroll',
                css : 'all-scroll',
                Label : 'all-scroll'
            },
            zoomIn : {
                Id : 'zoomIn',
                css : 'zoom-in',
                Label : 'zoom-in'
            },
            zoomOut : {
                Id : 'zoomOut',
                css : 'zoom-out',
                Label : 'zoom-out'
            },
            grab : {
                Id : 'grab',
                css : 'grab',
                Label : 'grab'
            },
            grabbing : {
                Id : 'grabbing',
                css : 'grabbing',
                Label : 'grabbing'
            },
        }
    },
    width : {
        name : 'width',
        type : DataType.size,
        label : 'Width'
    },
    minWidth : {
        name : 'minWidth',
        type : DataType.size,
        label : 'Min. Width'
    },
    maxWidth : {
        name : 'maxWidth',
        type : DataType.size,
        label : 'Max Width'
    },
    height : {
        name : 'height',
        type : DataType.size,
        label : 'Height'
    },
    minHeight : {
        name : 'minHeight',
        type : DataType.size,
        label : 'Min. Height'
    },
    maxHeight : {
        name : 'maxHeight',
        type : DataType.size,
        label : 'Max Height'
    },
    alignSelf : {
        name : 'alignSelf',
        type : DataType.number,
        label : 'Align Self'
    },
    justifySelf : {
        name : 'justifySelf',
        type : DataType.number,
        label : 'Justify Self'
    },
    x : {
        name : 'x',
        css : 'left',
        type : DataType.size,
        label : 'x'
    },
    y : {
        name : 'y',
        css : 'top',
        type : DataType.size,
        label : 'y'
    },
    top : {
        name : 'top',
        type : DataType.size,
        label : 'Top'
    },
    left : {
        name : 'left',
        type : DataType.size,
        label : 'Left'
    },
    right : {
        name : 'right',
        type : DataType.size,
        label : 'Right'
    },
    bottom : {
        name : 'bottom',
        type : DataType.size,
        label : 'Bottom'
    },
    position : {
        name : 'position',
        type : DataType.enum,
        label : 'Position',
        values : {
            relative : {
                Id : 'relative',
                Label : 'Relative'
            },
            absolute : {
                Id : 'absolute',
                Label : 'Absolute'
            },
            fixed : {
                Id : 'fixed',
                Label : 'Fixed on Screen'
            }
        }
    },
    overflowX : {
        name : 'overflowX'
    },
    overflowY : {
        name : 'overflowY'
    },
    overflow : {
        name : 'overflow',
        type : DataType.enum,
        label : 'Overflow',
        values : {
            auto : {
                Id : 'auto',
                Label : 'Auto'
            },
            hidden : {
                Id : 'hidden',
                Label : 'Hidden'
            },
            overlay : {
                Id : 'overlay',
                Label : 'Overlay'
            },
            scroll : {
                Id : 'scroll',
                Label : 'Scroll'
            },
            visible : {
                Id : 'visible',
                Label : 'Visible'
            }            
        },        
    },
    color : {
        name : 'color',
        type : DataType.color,
        label : 'Color'
    },
    fill : {
        name : 'fill',
        type : DataType.color,
        label : 'Fill'
    },
    backgroundProps : [
        'backgroundColor',
        'backgroundImage',
        'backgroundPosition',
        'backgroundSize',
        'backgroundAttachment',
        'backgroundOrigin',
        'backgroundClip',
        'backgroundRepeat'    
    ],
    backgroundColor : {
        name : 'backgroundColor',
        type : DataType.color,
        label : 'Background Color'
    },
    backgroundImage : {
        name : 'backgroundImage',
        type : DataType.backgroundImage,
        label : 'Background Image'        
    },
    backgroundPosition : {
        name : 'backgroundPosition',
        type : DataType.backgroundPosition,
        label : 'Position'
    },
    backgroundSize : {
        name : 'backgroundSize',
        type : DataType.backgroundSize,
        label : 'Size'
    },
    backgroundAttachment : {
        name : 'backgroundAttachment',
        type : DataType.enum,
        label : 'Attachment',
        values : {
            scroll : {
                Id : 'scroll',
                Label : 'Scroll'
            },
            fixed : {
                Id : 'fixed',
                Label : 'Fixed'
            },
            local : {
                Id : 'local',
                Label : 'Local'
            }
        }
    },
    backgroundOrigin : {
        name : 'backgroundOrigin',
        type : DataType.enum,
        label : 'Origin',
        values : {
            borderBox : {
                Id : 'borderBox',
                css : 'border-box',
                Label : 'Border Box'
            },
            paddingBox : {
                Id : 'paddingBox',
                css : 'padding-box',
                Label : 'Padding Box'
            },
            contentBox : {
                Id : 'contentBox',
                css : 'content-box',
                Label : 'Content Box'
            }
        }
    },
    backgroundClip : {
        name : 'backgroundClip',
        type : DataType.enum,
        label : 'Clip',
        values : {
            borderBox : {
                Id : 'borderBox',
                css : 'border-box',
                Label : 'Border Box'
            },
            paddingBox : {
                Id : 'paddingBox',
                css : 'padding-box',
                Label : 'Padding Box'
            },
            contentBox : {
                Id : 'contentBox',
                css : 'content-box',
                Label : 'Content Box'
            }
        }
    },
    backgroundRepeat : {
        name : 'backgroundRepeat',
        type : DataType.enum,
        label : 'Repeat',
        values : {
            noRepeat : {
                Id : 'noRepeat',
                css : 'no-repeat',
                Label : 'No Repeat'
            },
            repeat : {
                Id : 'repeat',
                Label : 'Repeat'
            },
            repeatX : {
                Id : 'repeatX',
                css : 'repeat-x',
                Label : 'Horizontal'
            },
            repeatY : {
                Id : 'repeatY',
                css : 'repeat-y',
                Label : 'Vertical'
            },
            space : {
                Id : 'space',
                Label : 'Space'
            },
            round : {
                Id : 'round',
                Label : 'Round'
            }
        }
        // condition : if backgroundImage
    },
    padding : {
        name : 'padding',
        type : DataType.size,
        label : 'Padding'
    },
    paddingTop : {
        name : 'paddingTop',
        type : DataType.size,
        label : 'Padding - Top'
    },
    paddingLeft : {
        name : 'paddingLeft',
        type : DataType.size,
        label : 'Padding - Left'
    },
    paddingRight : {
        name : 'paddingRight',
        type : DataType.size,
        label : 'Padding - Right'
    },
    paddingBottom : {
        name : 'paddingBottom',
        type : DataType.size,
        label : 'Padding - Bottom'
    },
    margin : {
        name : 'margin',
        type : DataType.size,
        label : 'Margin'
    },
    marginTop : {
        name : 'marginTop',
        type : DataType.size,
        label : 'Margin - Top'
    },
    marginLeft : {
        name : 'marginLeft',
        type : DataType.size,
        label : 'Margin - Left'
    },
    marginRight : {
        name : 'marginRight',
        type : DataType.size,
        label : 'Margin - Right'
    },
    marginBottom : {
        name : 'marginBottom',
        type : DataType.size,
        label : 'Margin - Bottom'
    },
    fontSize : {
        name : 'fontSize',
        type : DataType.size,
        label : 'Font Size'
    },
    fontFamily : {
        name : 'fontFamily',
        type : DataType.fontFamily,
        label : 'Font Family'
    },
    fontWeight : {
        name : 'fontWeight',
        type : DataType.number,
        label : 'Font Weight'
    },
    lineHeight : {
        name : 'lineHeight',
        type : DataType.size,
        label : 'Line Height',        
    },
    letterSpacing : {
        name : 'letterSpacing',
        type : DataType.size,
        label : 'Letter Spacing'
    },
    wordSpacing : {
        name : 'wordSpacing',
        type : DataType.size,
        label : 'Word Spacing'
    },
    textVerticalAlign : {
        name : 'textVerticalAlign',
        type : DataType.flex,
        label : 'Vertical Alignment',
        css : 'justifyContent'
    },
    textTransform : {
        name : 'textTransform',
        type : DataType.enum,
        label : 'Text Transform',
    },
    textAlign : {
        name : 'textAlign',
        type : DataType.enum,
        label : 'Alignment',
        values : {
            left : {
                Id : 'left',
                Label : 'Left'
            },
            right : {
                Id : 'right',
                Label : 'Right'
            },
            center : {
                Id : 'center',
                Label : 'Center'
            },
            justify : {
                Id : 'justify',
                Label : 'justify'
            }
        }
    },    
    fontStyle : {
        name : 'fontStyle',
        type : DataType.enum,
        label : 'Text Style',
        values : {            
            normal : {
                Id : 'normal',
                Label : 'Normal'
            },
            italic : {
                Id : 'italic',
                Label : 'Italic',
            }
        }
    },
    textDecoration : {
        name : 'textDecoration',
        type : DataType.enum,
        label : 'Decoration',
        values : {
            underline : {
                Id : 'underline',
                Label : 'Underline'
            },
            lineThrough : {
                Id : 'lineThrough',
                Label : 'Line Through',
                css : 'line-through'
            },
            overline : {
                Id : 'overline',
                Label : 'overline',
            }
        }
    },
    borderStyle : {
        name : 'borderStyle',
        label : 'Border Style',
        type : DataType.enum,
        values : borderStyleValues
    },
    borderRadius : {
        name : 'borderRadius',
        type : DataType.size,
        label : 'Border Radius'
    },
    borderTopLeftRadius : {
        name : 'borderTopLeftRadius',
        type : DataType.size,
        label : 'Top-Left Radius'
    },
    borderTopRightRadius : {
        name : 'borderTopRightRadius',
        type : DataType.size,
        label : 'Top-Right Radius'
    },
    borderBottomLeftRadius : {
        name : 'borderBottomLeftRadius',
        type : DataType.size,
        label : 'Bottom-Left Radius'
    },
    borderBottomRightRadius : {
        name : 'borderBottomRightRadius',
        type : DataType.size,
        label : 'Bottom-Right Radius'
    },
    borderWidth : {
        name : 'borderWidth',
        type : DataType.size,
        label : 'Border Width'
    },
    borderColor : {
        name : 'borderColor',
        type : DataType.color,
        label : 'Border Color'
    },
    borderLeftStyle : {
        name : 'borderLeftStyle',
        label : 'Border Left Style',
        type : DataType.enum,
        values : borderStyleValues
    },    
    borderLeftWidth : {
        name : 'borderLeftWidth',
        type : DataType.size,
        label : 'Border Left Width'
    },
    borderLeftColor : {
        name : 'borderLeftColor',
        type : DataType.color,
        label : 'Border Left Color'
    },
    borderRightStyle : {
        name : 'borderRightStyle',
        label : 'Border Right Style',
        type : DataType.enum,
        values : borderStyleValues
    },    
    borderRightWidth : {
        name : 'borderRightWidth',
        type : DataType.size,
        label : 'Border Right Width'
    },
    borderRightColor : {
        name : 'borderRightColor',
        type : DataType.color,
        label : 'Border Right Color'
    },
    borderTopStyle : {
        name : 'borderTopStyle',
        label : 'Border Top Style',
        type : DataType.enum,
        values : borderStyleValues
    },    
    borderTopWidth : {
        name : 'borderTopWidth',
        type : DataType.size,
        label : 'Border Top Width'
    },
    borderTopColor : {
        name : 'borderTopColor',
        type : DataType.color,
        label : 'Border Top Color'
    },
    borderBottomStyle : {
        name : 'borderBottomStyle',
        label : 'Border Bottom Style',
        type : DataType.enum,
        values : borderStyleValues
    },    
    borderBottomWidth : {
        name : 'borderBottomWidth',
        type : DataType.size,
        label : 'Border Bottom Width'
    },
    borderBottomColor : {
        name : 'borderBottomColor',
        type : DataType.color,
        label : 'Border Bottom Color'
    },
    flex : {
        name : 'flex',
        type : DataType.number,
        label : 'Flex Value'
    },
    opacity : {
        name : 'opacity',
        type : DataType.number,
        label : 'Opacity'
    },
    zIndex : {
        name : 'zIndex',
        type : DataType.number,
        label : 'z-index',
    },
    ShortHands : {
        position : [
            'position', 'left', 'top', 'right', 'bottom'
        ],
        gridChild : [
            
        ]
    },
    gridColumnGap : {
        name : 'gridColumnGap',
        type : DataType.size,
        label : 'Column Gap'
    },
    gridRowGap : {
        name : 'gridRowGap',
        type : DataType.size,
        label : 'Row Gap'
    },

    Paddings : ['padding', 'paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'],
    Margins : ['margin', 'marginLeft', 'marginRight', 'marginTop', 'marginBottom']
};

export default Styles;

export const CommonStyles = [
    Styles.cursor.name,
    Styles.width.name,
    Styles.minWidth.name,
    Styles.maxWidth.name,
    Styles.height.name,
    Styles.minHeight.name,
    Styles.maxHeight.name,
    Styles.position.name,
    Styles.left.name,
    Styles.top.name,
    Styles.right.name,
    Styles.bottom.name,
    Styles.margin.name,
    Styles.marginTop.name,
    Styles.marginLeft.name,
    Styles.marginRight.name,
    Styles.marginBottom.name,
    Styles.padding.name,
    Styles.paddingTop.name,
    Styles.paddingLeft.name,
    Styles.paddingRight.name,
    Styles.paddingBottom.name,
    Styles.color.name,
    Styles.backgroundColor.name,
    Styles.borderStyle.name,
    Styles.borderRadius.name,
    Styles.borderTopLeftRadius.name,
    Styles.borderTopRightRadius.name,
    Styles.borderBottomLeftRadius.name,
    Styles.borderBottomRightRadius.name,
    Styles.borderWidth.name,
    Styles.borderColor.name,
    Styles.borderLeftStyle.name,
    Styles.borderLeftWidth.name,
    Styles.borderLeftColor.name,
    Styles.borderRightStyle.name,
    Styles.borderRightWidth.name,
    Styles.borderRightColor.name,
    Styles.borderTopStyle.name,
    Styles.borderTopWidth.name,
    Styles.borderTopColor.name,
    Styles.borderBottomStyle.name,
    Styles.borderBottomWidth.name,
    Styles.borderBottomColor.name,
    Styles.flex.name,
    Styles.opacity.name,
    Styles.zIndex.name,
    
    Styles.backgroundImage.name,
    Styles.backgroundAttachment.name,
    Styles.backgroundClip.name,
    Styles.backgroundOrigin.name,
    Styles.backgroundPosition.name,
    Styles.backgroundRepeat.name,
    Styles.backgroundSize.name,

    Styles.alignSelf.name,
    Styles.justifySelf.name,

    Styles.overflowX.name,
    Styles.overflowY.name,
];

export const SizeStyles = [
    Styles.width.name,
    Styles.minWidth.name,
    Styles.maxWidth.name,
    Styles.height.name,
    Styles.minHeight.name,
    Styles.maxHeight.name,
    Styles.padding.name,
    Styles.paddingBottom.name,
    Styles.paddingLeft.name,
    Styles.paddingRight.name,
    Styles.paddingTop.name,
    Styles.margin.name,
    Styles.marginBottom.name,
    Styles.marginLeft.name,
    Styles.marginRight.name,
    Styles.marginTop.name,
    Styles.fontSize.name,
    Styles.borderWidth.name,
    Styles.borderTopWidth.name,
    Styles.borderLeftWidth.name,
    Styles.borderRightWidth.name,
    Styles.borderBottomWidth.name,
    Styles.borderRadius.name,
    Styles.borderTopLeftRadius.name,
    Styles.borderTopRightRadius.name,
    Styles.borderBottomLeftRadius.name,
    Styles.borderBottomRightRadius.name
]

export const TextStyles = [
    Styles.fontSize.name,
    Styles.fontFamily.name,
    Styles.fontWeight.name,
    Styles.textAlign.name,
    Styles.textDecoration.name,
    Styles.fontStyle.name,
    Styles.lineHeight.name,
    Styles.letterSpacing.name,
    Styles.wordSpacing.name,
    Styles.textVerticalAlign.name,
    Styles.textTransform.name
]
