import React from 'react';

const AnimatedMounter = (WrappedComponent) => {
    return class extends React.Component {
        state = {Animated: false}
        componentDidMount(){
           setTimeout(() => {
                this.setState({Animated: true})
            }, 10)
        }
        render(){
            let classNames_Card = 'card-animated';
            if (this.props.fromLeft)
                classNames_Card = 'card-animated-fade-fromleft';
            else if (this.props.fromRight)
                classNames_Card = 'card-animated-fade-fromright';
            if (this.state.Animated)
                classNames_Card += ' card-animated-mounted';

            return (
                <WrappedComponent {...this.props} className={classNames_Card}/>
            );
        }
    }
}

export default AnimatedMounter;
