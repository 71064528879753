import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Strings,
    MetaData,
    Globals
} from '../../../../../../importer';

import {TokenGroup, TokenPanelHeader, TokenItemBox} from '../../designsystem/common';
import {UIElementGrid} from '../UIElements';
import ToolbarItem, {DraggableToolbarItem, ElementBox} from '../UIElements/element';
import ToolbarIcons from '../../../../../../styled/toolbar';
import {MOCKUP_FAKETYPES} from '../../designsystem/mockupcontent/collection/populator';


export default class MockupValueSelector extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Load();
    }
    Load() {
        this.rows = [];
        const field = Globals.ProjectManager.Mockups.Field(this.props.FieldId);
        if (field)  {
            const collection = Globals.ProjectManager.Mockups.Collection(field.collectionId);
            this.fieldName = field.name;
            this.collectionName = collection.name;

            const rows = Utils.Get(collection, [], 'rows');
            Utils.ForEach(rows, (rowId, ) => {
                const fieldValue = Globals.ProjectManager.Mockups.GetRecordValue(rowId, this.props.FieldId);
                this.rows.push({
                    RecordId : rowId,
                    Value : fieldValue,
                    DataType : field.dataType
                })
            });
        }
    }
    SelectField(row) {
        this.props.onSelect(row.RecordId, row.Value);
    }
    renderCustom() {
        const items = [];

        Utils.ForEach(this.rows, (row, i) => {
            let valueItem;
            if (row.DataType === MetaData.DataTypes.image) {
                valueItem = (
                    <img src={Utils.JustGet(row.Value, '', 'url')} style={{width : '100%', height : 'auto'}} />
                );             
            }
            else {            
                valueItem = row.Value;
            }
            items.push(
                <TokenItemBox key={row.RecordId} onClick={this.SelectField.bind(this, row)} style={{marginBottom : '4px'}}>
                    {valueItem}
                </TokenItemBox>
            )
        });

        let content = (
            <SC.FCol style={{padding : '8px'}}>
                <div style={{marginBottom : '8px', fontSize : '11px', lineHeight : '18px'}}>
                    Select a <span style={{fontWeight : 'bold'}}>{this.fieldName}</span> value from <span style={{fontWeight : 'bold'}}>{this.collectionName}</span> collection :
                </div>
                {items}
            </SC.FCol>
        )
        return (
            <SC.FCol fw fh style={{position : 'relative'}}>
                <TokenPanelHeader 
                    title='MOCKUP DATA' 
                    onClose={this.props.onClose} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                />                
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.CustomScrollbars>
                        {content}
                    </SC.CustomScrollbars>            
                </SC.FCol>                
            </SC.FCol>
        )
    }
}