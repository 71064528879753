import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../../importer';
import { TokenItemBox } from '../../../views/project/panels/left/designsystem/common';
import NewProject from './newProject';
import TextInput from '../../../components/editors/textinput';
import InputSlider from '../../../components/editors/input_slider';
import { Seperator } from '../../../views/project/panels/right/iteminspector/styleitems/common';
import { FigmaLogo } from '..';
import { FigmaGoToolabsItem } from '../common';
import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import { InfoPanel } from '../../../components/info';
import { DotMenu } from '../../../views/project/panels/left/common';


export default class FigmaProjectList extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            loading : true,
            newProject : false,
            projects : [],
            deleted : []
        }        

        this.Load = this.Load.bind(this);
        this.Delete = this.Delete.bind(this);
    }
    componentDidMount() {        
        this.Load();

        this.RefListener = GetDataApi().api.get_ref(`/user_data/${GetUserId()}/teams/`);
        this.RefListener && this.RefListener.on("value",(snapshot) => {
            this.state.loading = true;
            this.Load();
        });

        AppLayout.Refs.ProjectList = this;
    }
    componentWillUnmount() {
        AppLayout.Refs.ProjectList = null;

        if (this.RefListener) {
            this.RefListener.off('value');
        }
    }
    Reload() {
        this.Load();
    }
    Load() {
        GetDataApi().load_users_projecets().then((result) => {
            this.UserData = result;
            this.TotalProjectCount = this.UserData.projects.length;
            Utils.ForEach(this.UserData.teams, (team, ) => {
                this.TotalProjectCount += team.projects.length;
            });
            this.Postload();
            this.setState({loading : false})
        })

        return;
        false && AppState.Data.BoardList().then((boards) => {
            const rows = [];
            const deleted = [];
            this.deletedProjectCount = 0;
            boards && Utils.ForEach(boards, (model) => {
                if (model.deleted) {
                    this.deletedProjectCount++;
                    deleted.push({
                        id : model._id,
                        name : Utils.UseNullOrEmpty(model.name || model.Name, 'Untitled')
                    });
                }
                else {
                    rows.push({
                        id : model._id,
                        name : Utils.UseNullOrEmpty(model.name || model.Name, 'Untitled')
                    });
                }                
            });
            
            this.setState({loaded : true, projects : rows, deleted : deleted, newProject : rows.length === 0});
        })
    }
    Postload() {
        this.FilterProjects(this.UserData);
        
        this.UserData.teams && this.UserData.teams.map((team) => {
            this.FilterProjects(team);
        })

        if (!this.UserData.deleted && this.state.showDeletedProjects) {
            this.state.showDeletedProjects = false;
        }
    }
    FilterProjects(data) {
        const filterName = Utils.UseNullOrEmpty(this.FilterText, '').toUpperCase();
        data.projects = Utils.Sort(data.projects, (m) => { return m.index });
        data.filtered = 0;
        data.deleted = 0;
        if (this.SortMode) {
            data.projects = Utils.Sort(data.projects, (m) => { return m.Name });
            if (this.SortMode === 2)
                data.projects = Utils.Reverse(data.projects);
        }
        Utils.ForEach(data.projects, (project, b) => {
            if (project.deleted)
                data.deleted++;

            project.filtered = false;
            if (Utils.IsNotNullOrEmpty(filterName)) {            
                   project.filtered = Utils.UseNullOrEmpty(project.Name, '').toUpperCase().indexOf(filterName) < 0
                   if (project.filtered)
                        data.filtered++;
            }
        }); 
    }
    SelectProject(id) {        
        this.props.onSelect(id);
    }    
    AcceptInvite(team, accept) {
        if (accept) {
            GetDataApi().accept_team_invitaion(team.id, team.memberId, team.invitationId).then(() => {
                this.state.loading = true;
                this.Load();
            })
        }     
        else {
            GetDataApi().decline_team_invitaion(team.id, team.memberId, team.invitationId).then(() => {
                this.state.loading = true;
                this.Load();
            })
        }   
    }
    Delete(id, teamId, isArchieved) {
        if (isArchieved) {
            AppState.Data.Board.DeleteFromArchive(id, teamId).then((result) => {
                this.Load();
            })
        }
        else {
            AppState.Data.Board.Delete(id, teamId).then((result) => {
                this.Load();
            })
        }           
    }
    Clone(id) {
        AppState.Data.Board.Clone(id).then((result) => {
            this.Load();
        })
    } 
    render() { 
        if (this.state.loading) {
            return (
                <SC.FCol alc style={{padding : '16px'}}>
                    <div>Loading Projects</div>
                    <SC.Loading_Icon />
                </SC.FCol>
            )
        }

        if (this.state.newProject) {
            return (
                <NewProject 
                    onCancel={() => this.setState({newProject : false})}
                    onSubmit={this.props.onSelect}
                    GetPluginApi={this.props.GetPluginApi}
                    name={this.props.name}
                    firstProject={this.TotalProjectCount === 0}
                    teamId={this.state.teamId}
                />
            )
        }

        let team_content;

        if (this.UserData.teams && this.UserData.teams.length > 0) {
            const invitations = [];
            const teams = [];

            this.UserData.teams.map((team) => {
                if (!team.active && !team.owner && !this.props.ownedTeams) {
                    invitations.push(
                        <InfoPanel style={{
                            padding : '8px',
                            fontSize : '12px',
                            marginTop : '24px',
                            ...SC.Styles.Flex.Column
                        }}>
                            <div>{team.ownerName} invited to {team.name} as {team.memberType}</div>
                            <SC.FRow alc justifyEnd>
                                <SC.LinkText primary onClick={this.AcceptInvite.bind(this, team, true)}>Accept</SC.LinkText>
                                <SC.LinkText onClick={this.AcceptInvite.bind(this, team, false)}>Decline</SC.LinkText>
                            </SC.FRow>                            
                        </InfoPanel>
                    )
                }
                else {
                    teams.push(
                        <React.Fragment>
                            <ListHeader style={{marginTop : '24px'}} title={`${team.name}'s Projects`} onAdd={() => {
                                    this.setState({
                                        newProject : true,
                                        teamId : team.id
                                    })
                            }} />
                            {
                                team.projects.map((teamProject) => {
                                    return (
                                        <FigmaProjectCard 
                                            key={teamProject.id}
                                            onSelect={this.SelectProject.bind(this, teamProject.id)}
                                            used={this.props.GetPluginApi().FigmaProjectsIds[teamProject.id] && this.TotalProjectCount > 1}
                                            name={teamProject.Name}
                                            model={teamProject} 
                                            teamId={team.id}
                                            onClone={this.Clone.bind(this, teamProject.id)}
                                        />
                                    )  
                                })
                            }
                        </React.Fragment>
                    )
                }
            });

            team_content = (
                <React.Fragment>
                    {invitations}
                    {teams}
                </React.Fragment>
            )
        }


        return (  
            <React.Fragment>
                <SC.CustomScrollbars>
                    <SC.FCol style={{
                        padding : '10px'
                    }}>                                
                        <ListHeader 
                            title={
                                this.state.showDeletedProjects ? 
                                <SC.BackClosableTitle title='Deleted Projects' titleStyle={{fontSize : 'inherit'}} style={{marginLeft : '-8px'}} onClose={() => {this.setState({showDeletedProjects : false})}} /> : 
                                'My Projects'
                            }
                            onAdd={() => {
                                this.setState({
                                    newProject : true
                                })
                            }} 
                            deleted={this.UserData.deleted && !this.state.showDeletedProjects ? this.UserData.deleted : false}
                            onShowDeletedProjects={() => {this.setState({showDeletedProjects : !this.state.showDeletedProjects})}}
                            showDeletedProjects={this.state.showDeletedProjects}
                        />
                        {
                            this.UserData.projects.map((board, i) => {                                
                                if (board.deleted && !this.state.showDeletedProjects || !board.deleted && this.state.showDeletedProjects)
                                    return null;
                                return (
                                    <FigmaProjectCard 
                                        key={board.id}
                                        onSelect={this.SelectProject.bind(this, board.id)}
                                        used={this.props.GetPluginApi().FigmaProjectsIds[board.id] && this.TotalProjectCount > 1}
                                        name={board.Name}
                                        model={board} 
                                        onRestored={this.Load}
                                        canDelete
                                        onDelete={this.Delete}
                                        onClone={this.Clone.bind(this, board.id)}
                                    />
                                )                                
                            })
                        }         
                        {team_content}
                        {
                                this.state.deleted.length > 0 && false &&
                                <React.Fragment>
                                    <Seperator />
                                    <SC.LinkText style={{
                                        padding : '8px',
                                        cursor : 'pointer'
                                    }} onClick={() => {
                                        this.setState({
                                            showDeletedProjects : true
                                        })
                                    }}>
                                        Show Deleted Projects ({this.state.deleted.length})
                                    </SC.LinkText>
                                </React.Fragment>                                
                            }
                    </SC.FCol>
                    <Seperator />
                    {
                        !this.props.isMenuOpen && 
                        <SC.FCol style={{backgroundColor : '#2c2c2c', margin : '10px', marginTop : 0, borderRadius : '4px', overflow : 'hidden'}}>
                            <FigmaGoToolabsItem style={{borderbottom : 'none', marginBottom : 0}} />
                        </SC.FCol>  
                    }            
                </SC.CustomScrollbars>    
            </React.Fragment>
        );
    }
}
 

export const FigmaProjectCardOld = (props) => {
    return (
        <TokenItemBox 
            onClick={props.onSelect}
            selected
            style={{
                marginTop : '8px',
                marginBottom : 0,
                fontSize : '12px',
                padding : '16px',
                flexDirection : 'column'
            }}
        >           
            <div>{props.name}</div>
            {
                props.used &&
                <React.Fragment>
                    <Seperator />
                    <SC.FRow alc>
                        <FigmaLogo />
                        <div style={{marginLeft : '8px'}}>Used in this figma document</div>
                    </SC.FRow>
                </React.Fragment>
            }                                                
        </TokenItemBox>
    ) 
}

export class FigmaProjectCard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        
        this.onClone = this.onClone.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.Restore = this.Restore.bind(this);        

        this.Share = this.Share.bind(this);        
        this.onRemoveFromTeam = this.onRemoveFromTeam.bind(this);        
        this.ShowMenu = this.ShowMenu.bind(this);
    }
    ShowMenu(e) {
        this.setState({showMenu : !this.state.showMenu, showTeamMenu : false});
    }         
    onClone() {
        this.setState({showMenu : false}, () => {
            this.props.onClone(this.props.model.id);
        });
    }
    onDelete() {
        this.setState({showMenu : false}, () => {
            this.props.onDelete(this.props.model.id, this.props.teamId, this.props.model.deleted);
        });        
    }    
    Restore(e) {
        e && e.stopPropagation();

        this.setState({showMenu : false}, () => {
            AppState.Data.Board.RestoreDeleted(this.props.model.id, this.props.teamId);
            delete this.props.model.deleted;
            this.props.onRestored();
        });         
    }
    Share() {   
        const teams = this.props.onGetTeams();
        if (teams.length === 1) {
            this.ShareWithTeam(teams[0].id);
        }
        else {
            this.setState({showTeamMenu : true});
        }        
    }
    onRemoveFromTeam() {
        GetDataApi().unmake_team_project(this.props.model.id, this.props.teamId).then(() => {
            this.props.onLoad();
        })
    }
    ShareWithTeam(teamId) {
        this.setState({showMenu : false}, () => {
            GetDataApi().make_team_project(this.props.model.id, teamId, this.props.teamId).then(() => {
                this.props.onLoad();
            })
        }); 
    }
    render() {
        const {model} = this.props;
        const name = model.name || model.Name;

        let menuContent;
        if (this.state.showMenu) {
            if (this.state.showTeamMenu) {
                const teams = this.props.onGetTeams();
                menuContent = (
                    <SC.FCol style={{minWidth : '160px'}}>
                        <SC.BackClosableTitle title='SELECT TEAM' onClick={() => {this.setState({showTeamMenu : false})}} style={{marginBottom : '8px'}} />
                        {
                            teams.map(({name, id}) => {
                                if (id === this.props.teamId)
                                    return null;
                                return (
                                    <SC.PopupItem onClick={this.ShareWithTeam.bind(this, id)}>
                                        {name}
                                    </SC.PopupItem>
                                )
                            })
                        }
                    </SC.FCol>
                )
            }
            else {
                menuContent = (
                    <SC.FCol style={{minWidth : '160px'}}>                                                
                        <SC.PopupItem onClick={this.onClone}>
                            Duplicate
                        </SC.PopupItem>
                        {
                            model.deleted && 
                            <React.Fragment>
                                <SC.PopupItem onClick={this.Restore}>
                                    Restore
                                </SC.PopupItem>
                                <SC.PopupItem onClick={this.onDelete}>
                                    Delete Permanently
                                </SC.PopupItem>
                            </React.Fragment>                                 
                        }                        
                        {
                            !model.deleted &&
                            <React.Fragment>
                                {
                                    this.props.shareable && this.props.teamId && 
                                    <SC.PopupItem onClick={this.onRemoveFromTeam}>
                                        Remove from Team
                                    </SC.PopupItem>
                                }
                                {
                                    this.props.shareable &&
                                    <SC.PopupItem onClick={this.Share}>
                                        {this.props.teamId ? 'Move to Another Team' : 'Share with Team'}
                                    </SC.PopupItem>  
                                }   
                                {
                                    this.props.canDelete && 
                                    <SC.PopupItem onClick={this.onDelete}>
                                        Delete
                                    </SC.PopupItem>
                                }                                                                 
                            </React.Fragment>
                        }                                                                                      
                    </SC.FCol>
                )
            }
        }

        return (
            <SC.HoverParent 
                defaultStyle={{
                    padding : '8px',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    borderRadius : '4px',
                    border : '1px solid transparent',
                    position : 'relative',
                    ...SC.Styles.Flex.Column,
                    ...this.props.style,
                    cursor : 'pointer',
                    fontSize : '12px',                    
                    marginTop : '8px',
                    marginBottom : 0
                }} 
                hoverStyle={{
                    backgroundColor : SC.CurrentTheme.theme.back_lightest,
                    border : SC.CurrentTheme.theme.border_seperator,
                }}
                onClick={this.props.onSelect}
            >
                <SC.FRow alc jsb>
                    <SC.TextDivAbbr style={{flex : 1, paddingLeft : '4px'}}>{name}</SC.TextDivAbbr>
                    <SC.FRow alc>
                        {
                            model.deleted && <SC.LinkText primary style={{paddingLeft : '8px', paddingRight : 'px', marginRight : '4px'}} onClick={this.Restore}>Restore</SC.LinkText>
                        }
                        <DotMenu onClick={this.ShowMenu} isOpen={this.state.showMenu} onClose={this.ShowMenu}>
                            { this.state.showMenu && menuContent }
                        </DotMenu>
                    </SC.FRow>       
                </SC.FRow>
                {
                this.props.used &&
                <React.Fragment>
                    <Seperator />
                    <SC.FRow alc>
                        <FigmaLogo size={10} />
                        <div style={{fontSize : '11px', marginLeft : '8px'}}>Used in this figma document</div>
                    </SC.FRow>
                </React.Fragment>
            }                                
            </SC.HoverParent>
        )
    }
}

const ListHeader = (props) => {
    return (
        <SC.FRow alc jsb style={{fontSize : '12px', paddingLeft : '4px', ...props.style}}>
            <div style={{flex : 1}}>{props.title}</div>
            <SC.FRow alc jsb>
                {
                    props.deleted ?
                    <React.Fragment>
                        <SC.LinkText onClick={props.onShowDeletedProjects}>
                            {props.deleted} Deleted
                        </SC.LinkText>
                        <SC.SortAndFilter.StylePanel_Total_Border style={{marginLeft : '8px', marginRight : '8px'}} />
                    </React.Fragment>
                        : null
                }  
                <SC.Icons.Icon_Button hasFill hasCursor title='Create New Project' onClick={props.onAdd}>
                    <SC.Icons.Icon_Plus size={22} />
                </SC.Icons.Icon_Button>
            </SC.FRow>            
        </SC.FRow> 
    )
}