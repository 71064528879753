import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';
import { onGetChildItem } from '../../common';

export default class Generic_Textarea extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {      


        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');
        return (
            <textarea 
                style={style} 
                {...props}
            >
                {textContent}
            </textarea>
        )
    }
}