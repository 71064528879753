import React from 'react';
import BaseItem from './BaseItem';
import {Utils} from '../importer';
import { GetSubItems } from './Container';

export default class Image extends BaseItem
{
    constructor(props) {
        super(props);
    }    
    renderItem({style, props, events, rest}) {        
        return (
            <div 
                ref={(r) => this.Ref_Instance = r}
                draggable={false}                                     
                style={style}
                {...events}
                {...rest}
            >
            </div>
        );
    }
}