import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    Globals,
    AppLayout,
    UIUtils
} from '../../../../../importer';
import DragDropManager from '../../../../../components/dragdrop/DragDropManager';
import { onItemShouldUpdate, onItemClick, onItemDidMount, onItemWillUnMount, onItemDidUpdate, onConstructItem, onGetChildItem } from './common';
import ComponentRenderManager from '../../manager/renderManager';
import {GetItemType} from './index';
import FlexBox from './container/flexbox';

export default class SubComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.isSubComponent = {};

        onConstructItem(this, props);

        this.GetSubComponentManager = this.GetSubComponentManager.bind(this);
        this.onEditInplace = this.onEditInplace.bind(this);
        this.SelectItem = this.SelectItem.bind(this);        
        this.renderSlot = this.renderSlot.bind(this);
        this.renderSlide = this.renderSlide.bind(this);

        this.onChangeModelValue = this.onChangeModelValue.bind(this);
        this.onChangingModelValue = this.onChangingModelValue.bind(this);

        this.UpdateStyle(this.props);
    }
    UpdateStyle(props = this.props) {
        this.renderData = props.GetComponentManager().GetItemStyle(props.Id);
        this.LoadComponent();
    }   
    RefreshGenericItem() {
        const SubComponentData = Globals.ProjectManager.GetComponentData(this.ComponentId);
        if (SubComponentData) {
            if (SubComponentData.GenericType === MetaData.Components.Generic.Carousel.Type ||
                SubComponentData.GenericType === MetaData.Components.Generic.Tab.Type ||
                SubComponentData.GenericType === MetaData.Components.Generic.Accordion.Type) {
                this.isSubComponent.Props = {
                    Slides : Utils.DeepClone(this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slides') || {}),
                    renderSlide : this.renderSlide
                }
                this.isSubComponent.GenericType = SubComponentData.GenericType;
            }
        };
        this.setState({ShouldUpdate : true});
    }
    componentDidMount() {
        onItemDidMount.call(this);
    }
    componentWillUnmount() {
        onItemWillUnMount.call(this);
        if (this.SubcomponentManager) {
            this.SubcomponentManager.Destruct();
        }
    }
    componentDidUpdate(prevProps, prevState) {
        onItemDidUpdate.call(this, prevProps, prevState);
        if (this.wasSelected && !this.isSelected) {
            if (this.state.Editing) {
                this.SubcomponentManager.SetEditing(false);
                this.setState({Editing : false});
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {        
        let willUpdate = false;
        if (!this.props.isDropTarget && nextProps.isDropTarget || this.props.isDropTarget && !nextProps.isDropTarget) {
            this.disableDropTarget = false;
            if (nextProps.isDropTarget && DragDropManager.DragData) {
                const ComponentItemId = Utils.JustGet(DragDropManager.DragData.DragItem, null, 'Item', 'MetaItem', 'Id');
                if (ComponentItemId === this.ComponentId)
                    this.disableDropTarget = true;
            }
            willUpdate = true;
        }
        if (onItemShouldUpdate.call(this, nextProps, nextState)) {            
            if (this.props.ComponentStateId !== nextProps.ComponentStateId)
                this.ComponentStateId = nextProps.ComponentStateId;
            return true;
        }                
                            
        if (this.state.Editing !== nextState.Editing) {
            this.ComponentStateId = Utils.Id();
            if (nextState.Editing) {
                AppLayout.Refs.SelectionDesigner.RegisterItem(this.props.Id, this, true);
            }
            else {
                AppLayout.Refs.SelectionDesigner.UnRegisterItem(this.props.Id, this, true);
            }
            willUpdate = true;
        }

        
            
        return willUpdate;
    }    
    GetMaxSize(scaled) {        
        let width, height;
        if (this.props.IsRootItem) {
            if (this.props.IsRootItem.Viewport) {      
                width = this.props.IsRootItem.Viewport.Width;
                height = this.props.IsRootItem.Viewport.Height;
                const bounds = UIUtils.Utils.GetBounds(this);
                if (this.props.IsRootItem.IsPage)
                    height = 999999;
                return {
                    width : width,
                    height : height,
                    top : bounds.top,
                    left : bounds.left,
                    actualwidth : bounds.width,
                    actualheight : bounds.height
                }
            }
        }
        return this.props.GetParentSize(scaled);
    }    
    LoadComponent() {
        if (this.renderData.props.Id !== this.ComponentId && this.renderData.props.Id) {
            this.ComponentId = this.renderData.props.Id;
            this.SubcomponentManager = new ComponentRenderManager();
            this.SubcomponentManager.isSubComponent = {
                ItemId : this.props.Id,
                GetManager : this.props.GetComponentManager
            }
            this.SubcomponentManager.Load(this.ComponentId);            
            this.SubcomponentManager.onUpdate = () => {
                this.UpdateStyle(this.props);
                this.ComponentStateId = Utils.Id();
                this.setState({ShouldUpdate : true})
            }

            const SubComponentData = Globals.ProjectManager.GetComponentData(this.ComponentId);
            if (SubComponentData) {
                if (SubComponentData.GenericType === MetaData.Components.Generic.Carousel.Type ||
                    SubComponentData.GenericType === MetaData.Components.Generic.Tab.Type ||
                    SubComponentData.GenericType === MetaData.Components.Generic.Accordion.Type) {
                    this.isSubComponent.Props = {
                        Slides : Utils.DeepClone(this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slides') || {}),
                        renderSlide : this.renderSlide
                    }
                    this.isSubComponent.GenericType = SubComponentData.GenericType;
                }
            }
        }
        if (this.SubcomponentManager) {
            this.SubcomponentManager.SetState({
                CurrentGlobalState : this.props.GetComponentManager().GlobalState,
                ComponentVariations : this.renderData.props.Variations
            });

            this.SubcomponentManager.LoadMockupValues();
    
            this.isFixedWidth = this.SubcomponentManager.GetDesignManager().GetItemPropertyValue({MetaItem : this.SubcomponentManager.GetRootMetaItem(), PropertyName : 'fixedWidth'});
            this.isFixedHeight = this.SubcomponentManager.GetDesignManager().GetItemPropertyValue({MetaItem : this.SubcomponentManager.GetRootMetaItem(), PropertyName : 'fixedHeight'});
            if (this.isFixedWidth) {
                delete this.renderData.style.width;
            }
            if (this.isFixedHeight) {
                delete this.renderData.style.height;
            }
            
            if (this.renderData.props.Models) {
                Utils.ForEach(this.renderData.props.Models, (subComponentModelValue, subComponentModelId) => {
                    let value = subComponentModelValue.value;
                    let optionValues = subComponentModelValue.Options;
                    if (subComponentModelValue.ModelId) {
                        const modelValue = Utils.JustGet(this.renderData.models, null, subComponentModelId);
                        if (modelValue) {
                            if (modelValue.modelId) {
                                const parentModelValue = this.props.GetComponentManager().GetModelValue({ModelId : modelValue.modelId});
                                if (parentModelValue)
                                    value = parentModelValue.value;
                            }
                        }
                    }
                    else {
                        let tokenId = subComponentModelValue.PreviewTokenId || subComponentModelValue.TokenId;
                        if (tokenId)
                            value = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenId, StateArray : this.props.GetComponentManager().ReversedGlobalStateArray});
                    }
                    if (Utils.IsNotNull(value)) {
                        Utils.Set(this.SubcomponentManager, value, 'ModelValues', subComponentModelId, 'value');                        
                    }                    
                    Utils.Set(this.SubcomponentManager, optionValues, 'ModelValues', subComponentModelId, 'options');
                });
            }
        }        
    }
    GetSubComponentManager() {
        return this.SubcomponentManager;
    }
    onEditInplace(e) {
        e && e.stopPropagation();        
        this.SubcomponentManager.SetEditing(true);
        this.SubcomponentManager.onActiveChanged = () => {
            // this.setState({editing : false});
        }
        this.setState({Editing : true});        
    }
    SelectItem(Id, isSelect, isMultiple) {
        if (this.state.Editing) {
            this.SubcomponentManager.GetDesignManager().SelectItem(Id, isSelect, isMultiple);
            this.setState({
                ShouldUpdate : true
            })
        }
        else {
            this.props.onSelect(this.props.Id, isSelect, isMultiple);
        }        
    }
    onChangingModelValue(componentModelId, value) {
        Utils.Set(this.renderData.props.Models, value, componentModelId, 'value');
        this.props.GetComponentManager().GetDesignManager().SetSubComponentModels({
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.ComponentId,
            ModelId : componentModelId,
            ModelValue : {
                value : value
            }
        });
    }
    onChangeModelValue(componentModelId, value) {
        this.props.GetComponentManager().GetDesignManager().SetSubComponentModels({
            MetaItem : this.props.GetComponentManager().GetMetaItem(this.props.Id),
            Id : this.props.Id,
            ComponentId : this.ComponentId,
            ModelId : componentModelId,
            ModelValue : {
                value : value
            }
        });
    }
    renderSlot({slotId, ParentzIndex, ContainerStyle}) {
        let slotItemId = this.props.GetComponentManager().GetDesignManager().GetItemProp(this.props.Id, 'Slots', slotId);
        let SlotItem = this.props.GetComponentManager().GetMetaItem(slotItemId);
        
        if (!SlotItem) {
            SlotItem = MetaData.ItemInitializer.FromType(MetaData.Components.Div.Type);
            delete SlotItem.Property.Default.Default.backgroundColor;
            SlotItem.ParentId = this.props.Id;
            slotItemId = Utils.Id();
            this.props.GetComponentManager().GetDesignManager().SetItemProp(this.props.Id, slotItemId, 'Slots', slotId);
            this.props.GetComponentManager().GetDesignManager().AddChildItem({
                ParentId : this.props.Id, 
                MetaItem : SlotItem, 
                Id : slotItemId, 
                ChildIndex : 0, 
                LogOptions : {},
                Silent : true
            });
        }

        return this.props.renderSlotContent({
            SlotItemId : slotItemId,
            SlotItem : SlotItem,
            ParentzIndex : (ParentzIndex || 0) + 1,
            ContainerStyle : ContainerStyle
        });
    }
    renderSlide(slide, style) {
        let SlideItem = this.props.GetComponentManager().GetMetaItem(slide.ItemId);
        return this.props.renderSlidecontent({
            SlideItemId : slide.ItemId,
            SlideItem : SlideItem,
            ParentzIndex : (this.props.ParentzIndex || 0) + 1,
            ContainerStyle : {
                flex : 1,
                alignSelf : 'stretch',
                minHeight : this.props.isDropTarget ? '80px' : 'unset',
                ...style
            }
        });
    }
    render() {         
        if (Utils.IsTrue(this.renderData.props.hidden))
            return null;
            
        const style = {
            position : 'relative',
            ...this.renderData.style
        };
        if (this.isDragging) {
            style.opacity = 0.5;
        }
        if (this.isSelected) {
            style.transition = 'all 0.2s ease';
        }
        let content;
        if (this.SubcomponentManager) {
            const RootMetaItem = this.SubcomponentManager.GetRootMetaItem();
            if (RootMetaItem) {
                const RootId = this.SubcomponentManager.GetRootId();
                const ChildItemType = GetItemType(RootMetaItem.Type, false, RootMetaItem.Generic);       
                
                if (this.disableDropTarget)
                    style.pointerEvents = 'none';
                else
                    style.pointerEvents = 'all';

                const props = {
                    IsRootItem : {
                        SubComponent : {
                            Id : this.props.Id,
                            style : style,
                            onMouseDown : (this.state.Editing)? null : this.onMouseDown,
                            onMouseUp : (this.state.Editing) ? null : this.onItemClick,
                            onDoubleClick : (this.state.Editing) ? null : this.onEditInplace,
                            onMouseOver : (this.state.Editing)? null : this.onMouseOver,
                            onMouseOut : (this.state.Editing)? null : this.onMouseOut,        
                            onMouseOverChild : (this.state.Editing)? null : this.onMouseOverChild,
                        },
                    },                    
                    Id : RootId,
                    TargetId : this.props.Id,
                    GetParentSize : this.GetItemSize,
                    ParentId : this.props.ParentId,                    
                    onDragDrop : this.props.onDragDrop,
                    GetComponentManager : this.GetSubComponentManager,
                    Preview : this.props.Preview, //!this.SubcomponentManager.Editing  && !this.props.isDropTarget,
                    GlobalStateId : this.props.GlobalStateId,
                    GlobalThemeId : this.props.GlobalThemeId,
                    ComponentStateId : this.ComponentStateId,
                    onMouseOverChild : this.props.onMouseOverChild,
                    isSubComponentChild : {
                        ComponentId : this.ComponentId,
                        onMouseDownOnItem : this.onMouseDown,
                        onItemClick : this.onItemClick,
                        onChangingModelValue : this.onChangingModelValue,
                        onChangeModelValue : this.onChangeModelValue
                    },
                    onRegisterPlaceholder : this.props.onRegisterPlaceholder,
                    onRegisterDropTarget : this.props.onRegisterDropTarget,
                    isDropTarget : this.disableDropTarget ? false : this.props.isDropTarget,
                    renderSlot : this.renderSlot,
                    SelectionId : this.props.SelectionId,
                    onSelect : this.SelectItem,
                    IsGridChild : this.props.IsGridChild,
                    ParentzIndex : this.props.ParentzIndex                    
                };

                if (RootMetaItem.Generic) {
                    props.GenericType = RootMetaItem.Generic.Type;
                }

                if (this.state.Editing) {
                    props.SelectedItems = this.SubcomponentManager.GetSelectedItems();
                }
                else {
                    props.SelectedItems = [];
                }

                return (
                    <ChildItemType 
                        // key={this.state.Editing ? 'Editing' : 'preview'}
                        {...props}        
                        {...this.isSubComponent.Props}                                     
                    />
                )
            }
        }

        return ( 
            <div
                style={style}
                onMouseDown={this.onMouseDown}
                onMouseUp={this.onItemClick}
            >
                {content}
            </div>
        );
    }
}
 