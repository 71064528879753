import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Events,
    Globals,
    Utils
} from '../../../../../importer';

import Scrollbars from 'react-custom-scrollbars';
import { TokenPanelHeader, GroupTitle, TokenItemBox } from '../../left/designsystem/common';
import { InviteMemberItem } from '../../../../projectlist/team';
import { GetDataApi, GetUserId } from '../../../../../appstate/AppState';
import { Seperator } from '../iteminspector/styleitems/common';
import { TEAM_MESSAGE_TYPES } from '../../../manager/sessionmanager';
import UserAvatar from 'react-user-avatar';
import { DotMenu } from '../../left/common';
import TeamMembers from './members';
import TeamEditors from './editors';
import TeamActivities from './messages';
import { RequestTeamAccess } from '../../../../projectlist/teams';


export default class TeamPanel extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        this.state.showActivities = true;

        this.ShowMenu = this.ShowMenu.bind(this);
        this.InviteMember = this.InviteMember.bind(this);        

    }
    componentDidMount() {
        super.componentDidMount();

        if (!Globals.ProjectManager.TeamId) {
            GetDataApi().load_users_teams().then((teamData) => {
                this.TeamData = teamData;
                GetDataApi().check_teamaccess(GetUserId()).then((hasAccess) => {
                    if (hasAccess) {
                        if (hasAccess.granted) {                                               
                            this.setState({loaded : true, hasAccess : true});        
                        }
                        else {
                            this.setState({loaded : true, waitingAccess : true}, () => {
                                this.RefListenerAccess = GetDataApi().api.get_ref(`/teams/earlyaccess/${GetUserId()}/granted`);
                                this.RefListenerAccess.on("value",(snapshot) => {
                                    const granted = snapshot.val();
                                    if (granted !== this.state.hasAccess) {
                                        this.setState({
                                            hasAccess : granted,
                                            waitingAccess : !granted
                                        });
                                    }                            
                                });
                            });
                        }
                    }
                    else {
                        this.setState({loaded : true});
                    }
                }); 
            })                
        }        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.RefListenerAccess) {
            this.RefListenerAccess.off('value');
        }
    }
    InviteMember(type, email) {
        this.setState({inviting : true}, () => {
            GetDataApi().invite_team_member({
                id : Globals.ProjectManager.TeamId,
                email : email,
                type : type
            }).then(() => {              
                this.setState({inviting : false});
            })
        }) 
    }
    ShowMenu(e) {
        e && e.stopPropagation();
        this.setState({isMenuOpen : !this.state.isMenuOpen});
    }
    ShareWithTeam(id) {
        GetDataApi().make_team_project(Globals.ProjectManager.Id, id).then(() => {
            Globals.ProjectManager.ReloadWithTeam(id);
            this.RCUpdate();
        })
    }
    renderCustom() {
        let content;
        let title = <SC.TextDivAbbr>{Globals.ProjectManager.TeamId ? `TEAM (${Globals.ProjectManager.TeamName})` : 'TEAM'}</SC.TextDivAbbr> ;
        if (Globals.ProjectManager.TeamId) {
            if (this.state.showInviteMember) {
                title = 'INVITE MEMBER';
                content = (
                    <div style={{padding : '10px', paddingTop : 0}}>
                        <InviteMemberItem compact 
                            onAdd={this.InviteMember} 
                            inviting={this.state.inviting}                            
                        />
                    </div>        
                )
            }
            else {
                let members, activeUsers, messages;
    
                if (Globals.ProjectManager.SessionManager) {                
                    members = (
                        <TeamMembers 
                            onInviteMember={() => {
                                this.setState({
                                    showInviteMember : true
                                })
                            }}
                        />
                    );

                    activeUsers = (
                        <TeamEditors />
                    );
    
                    messages = (                    
                        <TeamActivities />
                    )
                }
    
                content = (
                    <SC.FCol style={{minHeight : '100px'}}>
                        {members}
                        {activeUsers}
                        {messages}                        
                    </SC.FCol>
                )
            }
        }
        else {
            if (this.state.loaded) {
                let shareWithTeam;
                if (this.TeamData && this.TeamData.teams.length > 0) {
                    const teams = [];
                    const activeTeams = [];
                    this.TeamData.teams.map((team) => {
                        if (team.owner) 
                            activeTeams.push(team);
                    })
                    this.TeamData.teams.map((team) => {
                        if (team.active && !team.owner) 
                            activeTeams.push(team);
                    })
                    activeTeams.map(({name, id}) => {
                        teams.push(
                            <SC.PopupItem key={id} onClick={this.ShareWithTeam.bind(this, id)}>
                                {name}
                            </SC.PopupItem>
                        )
                    })
                    shareWithTeam = (
                        <React.Fragment>
                            <SC.GroupLabel style={{padding : '8px'}} subGroup >SHARE WITH TEAM</SC.GroupLabel>
                            {teams}
                        </React.Fragment>
                    )
                }
                if (this.state.hasAccess) {
                    content = (
                        <SC.FCol style={{padding : '8px'}}>
                            {shareWithTeam}
                        </SC.FCol>
                    )
                }
                else {
                    content = (
                        <SC.FCol style={{padding : '8px'}}>
                            <RequestTeamAccess 
                                compact
                                waitingAccess={this.state.waitingAccess}
                                onWaitingAccess={() => this.setState({waitingAccess : true})}
                            />
                            {
                                shareWithTeam ? <Seperator style={{marginTop : '16px'}} /> : null
                            }
                            {shareWithTeam}
                        </SC.FCol>
                    )
                }
            }    
            else {
                content = (
                    <SC.Loading_Icon />
                )
            }                    
        }
        
        
        return (
            <SC.FCol fw fh >        
                {
                    !this.props.noHeader &&
                    <TokenPanelHeader 
                        title={title} 
                        onClose={() => { 
                            if (this.state.isMenuOpen) return; 
                            if (this.state.showInviteMember) {
                                this.setState({showInviteMember : false});
                                return;
                            } 
                            Events.BCE(Events.GLOBAL.TEAM.SHOWPANEL, Events.PARAMS.HIDE);
                        }} 
                        style={{padding : '2px'}}
                    >
                        <DotMenu onClick={this.ShowMenu} isOpen={this.state.isMenuOpen} onClose={this.ShowMenu}>
                            <SC.PopupItem onClick={() => {
                                this.setState({
                                    isMenuOpen : false,
                                    showInviteMember : true
                                })
                            }}>
                                Invite Member
                            </SC.PopupItem>      
                            <SC.PopupItem onClick={() => Events.BCE(Events.GLOBAL.TEAM.SHOWPANEL, Events.PARAMS.HIDE)}>
                                Close Team Panel
                            </SC.PopupItem>
                        </DotMenu>
                    </TokenPanelHeader>
                }
                
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                        {content}                                                 
                    </SC.CustomScrollbars>
                </SC.FCol>
            </SC.FCol>
        )
    }
}



