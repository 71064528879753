import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBasicComponent,
    SC,
    Utils,
    UIUtils
} from '../../importer';

import styled from 'styled-components';


export default class NewSlider extends ReactBasicComponent
{
    constructor(props) {
        super(props);

        let useTheme = this.props.theme || SC.CurrentTheme.theme;

        this.Ref_Input = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.onChanging = this.onChanging.bind(this);
        this.state.value = this.props.value;

        this.style_thumb = {
            position : 'absolute',
            top : '50%',
            width : this.props.bigThumb ? '16px' : '12px',
            height : this.props.bigThumb ? '16px' : '12px',
            borderRadius : '50%',
            border : useTheme.isLight ? '1px solid #959595' : '1px solid #3d3d3d',
            marginTop : this.props.bigThumb ? '-7px'  :'-5px',
            marginLeft : 0, //this.props.bigThumb ? '-8px' : '-6px',
            boxSizing : 'border-box',
            pointerEvents : 'none',
            backgroundColor : SC.CurrentTheme.theme.input_back
        }
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this.Ref_Input.current);
        DN.addEventListener('input', this.onChanging);
        DN.addEventListener('change', this.onChange);
    }
    onChange(e) {
        let value = !Utils.IsNotNullOrEmpty(e.target.value) ? null : Utils.ToNumber(e.target.value);
        this.props.onChange && this.props.onChange(value);
        this.setState({value : value});
    }
    onChanging(e) {
        let value = !Utils.IsNotNullOrEmpty(e.target.value) ? null : Utils.ToNumber(e.target.value);
        if (this.props.onChanging)
            this.props.onChanging(value);
        else 
        this.props.onChange && this.props.onChange(value);
        this.setState({value : value});
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.value !== nextState.value) {
            this.state.value = Utils.ToNumber(nextProps.value);
            return true;
        }
        if (this.state.value !== nextState.value)
            return true;

        return super.ShouldUpdate(nextProps, nextState);
    }
    renderCustom() {
        let useTheme = this.props.theme || SC.CurrentTheme.theme;
        const style = Utils.Merge(this.props.style, {position : 'relative'});
        const ratio = Math.min(100 * ((this.state.value - (this.props.min || 0)) / ((this.props.max || 100)-(this.props.min || 0))), 100) / 100;
        const progressleft = `calc(${100*ratio}% - 2px)`;
        const progressright = `calc(${100*ratio}% + 14px)`;

        let background;
        if (this.props.fixedPoints) {
            background = (
                <FixedPoints 
                    points={this.props.fixedPoints} 
                    min={this.props.min || 0} 
                    max={this.props.max || 100} 
                    selectedIndex={this.props.selectedIndex}
                    onSelectPreset={this.props.onSelectPreset}
                    theme={useTheme}
                />
            )
        }
        else {
            background = (
                <React.Fragment>
                    <div style={{
                        position : 'absolute',
                        top : '50%',
                        left : 0,
                        width : progressleft,
                        height : '1px',
                        backgroundColor : useTheme.color_brand
                    }} 
                    />
                    <div style={{
                        position : 'absolute',
                        top : '50%',
                        right : '-12px',
                        left : progressright,
                        height : '1px',
                        backgroundColor : useTheme.isLight ? '#e6e6e6' : '#3d3d3d'
                    }} 
                    />
                </React.Fragment>
            )
        }

        return (
            <div style={style} title={this.props.title} >
                <input 
                    ref={this.Ref_Input}
                    type='range'        
                    min={this.props.min || 0} 
                    max={this.props.max || 100} 
                    step={this.props.step || 1} 
                    value={this.state.value}
                    disabled={this.props.disabled || this.props.readOnly}
                    className={'slider'}
                    style={{
                        backgroundColor : 'unset',
                        transform : 'translateY(6px)'
                    }}
                />                
                <div style={{position : 'absolute', left : 0, right : this.props.fitToWidth ? 0 : '12px', top : '50%', bottom : '50%'}}>
                    {background}
                    <div style={{...this.style_thumb,
                        left : `${100*ratio}%`
                    }} />
                </div>
            </div>
        )
    }
}

class FixedPoints extends React.Component
{
    constructor(props) {
        super(props);

        const {points, min, max} = this.props;
        this.positions = [];
        const width = max - min;
        Utils.ForEach(points, (point, i) => {
            this.positions.push({
                percent : 100 * ((point.value - min) / width) + (this.props.offset || 0),
                id : point.id,
                label : point.label,
                value : point.value
            })
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.selectedIndex !== this.props.selectedIndex)
            return true;
        return false;
    }
    Select(point, e) {
        e && e.stopPropagation();
        this.props.onSelectPreset(point.id, point.value)
    }
    render() {
        let useTheme = this.props.theme || SC.CurrentTheme.theme;
        return (
            <div style={{
                position : 'absolute',
                top : '50%',
                left : 0,
                right : '0px',
                height : '1px',
                borderTop : useTheme.isLight ? '1px solid #959595' : '1px solid rgb(99, 99, 103)',
            }} 
            >
                {
                    this.positions.map((point, i) => {
                        return (
                            <FixedPoint key={i} title={point.label} onClick={this.Select.bind(this, point)} style={{
                                cursor : 'pointer',
                                position : 'absolute',
                                left : `${point.percent}%`,                                
                                top : '-3px',
                                width : '6px',
                                height : '6px',
                                backgroundColor : this.props.selectedIndex === i ? useTheme.color_brand :'rgb(99, 99, 103)',
                                borderRadius : '50%',
                                zIndex : 9,
                                marginLeft : '5px'
                            }} />
                        )
                    })
                }
            </div>
        )
    }
}

const FixedPoint = styled.div`
    transition : transform 0.2s ease;
    &:hover {
        transform : scale(1.8);
    }
`;