import React from 'react';
import {
    ReactBaseComponent,
    AppState,
    SC,
    Utils,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../../../../importer';

import styled from 'styled-components';
import {ItemNameEditor, TokenItemBox, DraggableTokenItem, TokenPanelHeader, GroupTitle, EditableName} from '../../common';
import { StyleToken_Color } from '../../../../right/iteminspector/styleitems/common';
import { InfoPanel } from '../../../../../../../components/info';
import { StyleTokenBorderStyle } from '../../../../right/iteminspector/styleitems/borders';
import { StyleTokenShadowItem } from '../../../../right/iteminspector/styleitems/shadow';
import { SpacePattern, GetSpacePatternItem } from '../../spacing/patterns';

import { BorderRadiusTokenItem, GetBorderRadiusTokenValue } from '../../borders';

export default class AliaseItem extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);

        this.SelectToken = this.SelectToken.bind(this);
        this.EditToken = this.EditToken.bind(this);
        
        this.aliase = this.props.aliase;
    }
    SaveName(name) {
        this.aliase.name = name;
        if (!this.props.isNew)
            Globals.ProjectManager.Tokens.Aliases.ChangeAliaseName(this.props.id, name);
        this.RCUpdate();
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.Aliases.List(this.props.type);
    }
    EditToken() {
        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id) {
            this.aliase = nextProps.aliase;
        }
        this.aliase = nextProps.aliase;
        return true;
    }
    renderSelectToken() {
        let item;

        if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
            item =  (
                <StyleToken_Color 
                    tokenId={this.aliase.tokenId}
                    onSelect={this.SelectToken} 
                />
            )
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
            item =  (
                <StyleTokenShadowItem 
                    tokenId={this.aliase.tokenId}
                    onSelect={this.SelectToken} 
                />
            )
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
            item =  (
                <StyleTokenBorderStyle 
                    tokenId={this.aliase.tokenId}
                    onSelectBorderStyle={this.SelectToken} 
                />
            )
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
            let radiusModel;
            if (this.aliase.tokenId)
                radiusModel = GetBorderRadiusTokenValue(Globals.ProjectManager.Tokens.Token(this.aliase.tokenId), this.aliase.tokenId);
            if (!radiusModel)
                radiusModel = {
                    model : {
                        name : 'Select Radius Token'
                    }
                }
            item =  (
                <BorderRadiusTokenItem 
                    isListView
                    radius={radiusModel}
                    tokenId={this.aliase.tokenId}
                    onSelect={this.SelectToken} 
                />
            )
        }
        else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
            if (this.aliase.tokenId) {
                const spacePattern = GetSpacePatternItem(this.aliase.tokenId);
                item =  (
                    <SpacePattern 
                        pattern={spacePattern.pattern}
                        spaceValue={spacePattern.spaceValue}
                        spaceUnit={spacePattern.spaceUnit}
                        onClick={this.SelectToken} 
                        listView
                    />
                )
            }
            else {
                item = (
                    <TokenItemBox onClick={this.SelectToken}>
                        Select Space Token
                    </TokenItemBox>
                )
            }
        }
        

        return (
            <SC.FCol style={{marginTop : '8px'}}>
                <InfoPanel style={{fontSize : '11px'}}>
                    This aliase will use the following token 
                </InfoPanel>
                {item}
                {/* {
                    this.aliase.tokenId && 
                    <SC.LinkText onClick={this.EditToken} style={{marginTop : '8px', alignSelf : 'flex-end', fontSize : '11px'}}>
                        Edit Token
                    </SC.LinkText>
                }                                 */}
            </SC.FCol>
        )
    }
    SelectToken() {
        let onClosed;
        const onPreview = ({show, tokenId}) => {
                    
        };
        const onSelect = (tokenId) => {
            this.aliase.tokenId = tokenId;
            if (!this.props.isNew) {
                Globals.ProjectManager.Tokens.Aliases.SetTokenId({id : this.props.id, tokenId : tokenId});
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);

                Events.BCE(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                    Id : this.aliase.id,
                    Type : this.props.type,
                    value : Globals.ProjectManager.Tokens.ValueOfId(tokenId)
                }]);
            }
            this.RCUpdate();
        };

        if (this.props.onSelectColor) {
            this.props.onSelectColor(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            const props = {
                title : 'SELECT TOKEN',        
                forAliase : true        
            };
            if (this.props.type === Globals.ProjectManager.Tokens.Types.COLOR) {
                props.type = AppState.ItemTypes.BOARD.COLOR;
                props.title = 'SELECT COLOR TOKEN';
                props.noGradient = false;
                props.isGradients = true;
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Shadows) {
                props.type = AppState.ItemTypes.BOARD.SHADOW;
                props.title = 'SELECT SHADOW TOKEN';
                props.textAndBox = true;
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Borders) {
                props.type = AppState.ItemTypes.BOARD.BORDER_STYLE
                props.title = 'SELECT BORDER TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.BorderRadiuses) {
                props.type = AppState.ItemTypes.BOARD.BORDER_RADIUS;
                props.title = 'SELECT RADIUS TOKEN';
            }
            else if (this.props.type === Globals.ProjectManager.Tokens.Types.Spacings) {
                props.type = AppState.ItemTypes.BOARD.SPACING;
                props.title = 'SELECT SPACE TOKEN';
            }
            else
                return;
            this.props.RefToolbar.current.ShowPanel({
                ...props,
                forAliase : true,
                onSelect : onSelect,
                onPreviewToken : onPreview,
                onClosed : onClosed,
                propName : 'tokenId',
                tokenId : this.aliase.tokenId,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewNewToken : () => {
                    
                }
            })
        } 
    }
    renderCustom() {
        return (
            <SC.FCol fw fh style={{backgroundColor : SC.CurrentTheme.theme.back, padding : '8px', boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.aliase.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.aliase}
                    onSubmit={this.props.onSubmit}
                />
                {this.renderSelectToken()}
            </SC.FCol>
        )
    }
}