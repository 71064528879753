import React from 'react';
import ReactDOM from 'react-dom';
import Utils from '../toolabs-utils';

const UIUtils = {
    IsOver(Position, Object, Log) {
        if (!Object)
            return false;
        const DN = ReactDOM.findDOMNode(Object);
        if (DN)
        {
            const CR = DN.getBoundingClientRect();
            Log && console.log(`top:${CR.top}, bottom:${CR.bottom}, left:${CR.left}, right:${CR.right} | x:${Position.x} , y:${Position.y}`);
            const isOver = this.IsOverBounds(Position, CR);
            return isOver;
        }
        return false;
    },
    IsOverBounds(Position, Bounds) {
        return Position.y >= Bounds.top && Position.y <= Bounds.bottom && Position.x >= Bounds.left && Position.x <= Bounds.right;
    },
    ConvertPosition(Object, x, y) {
        if (Object) {
            const DN = ReactDOM.findDOMNode(Object);
            if (DN)
            {
                const CR = DN.getBoundingClientRect();
                return {
                    x : x - CR.left,
                    y : y - CR.top
                };
            }
        }
        return {
            x : x,
            y : y
        };
    },
    GetBounds(Object, withMargins) {
        const DN = ReactDOM.findDOMNode(Object);
        if (DN)
        {
            const bounds = DN.getBoundingClientRect();
            if (!withMargins)
                return bounds;
            const style = window.getComputedStyle(DN);
            if (style) {
                let computedBounds = Utils.DeepClone(bounds);
                if (style.marginLeft)
                    computedBounds.width += style.marginLeft;
                if (style.marginRight)
                    computedBounds.width += style.marginRight;
                if (style.marginTop)
                    computedBounds.height += style.marginTop;
                if (style.marginBottom)
                    computedBounds.height += style.marginBottom;
                return computedBounds;
            }
        }
    },
    GetPaddingMargins(DN) {
        const style = window.getComputedStyle(DN);


        if (style) {
            return {
                marginLeft : Utils.ToInteger(style.marginLeft.replace('px', '')) || 0,
                marginTop : Utils.ToInteger(style.marginTop.replace('px', '')) || 0,
                marginRight : Utils.ToInteger(style.marginRight.replace('px', '')) || 0,
                marginBottom : Utils.ToInteger(style.marginBottom.replace('px', '')) || 0,
                paddingLeft: Utils.ToInteger(style.paddingLeft.replace('px', '')) || 0,
                paddingRight: Utils.ToInteger(style.paddingRight.replace('px', '')) || 0,
                paddingBottom: Utils.ToInteger(style.paddingBottom.replace('px', '')) || 0,
                paddingTop: Utils.ToInteger(style.paddingTop.replace('px', '')) || 0,
            };
        }
    },
    IsInContainer(Object, Container) {
        const CR = this.GetBounds(Object);
        if (CR)
        {
            return CR.left >= Container.left && CR.right <= Container.right && CR.top >= Container.top && CR.bottom <= Container.bottom;
        }
        return false;
    },
    TranslateCss(x, y) {
        return `translate(${x}px, ${y}px)`;
    },
    Translate(Object, x, y) {
        const DN = ReactDOM.findDOMNode(Object);
        this.TranslateDN(DN, x, y);
    },
    TranslateDN(DN, x, y) {
        if (DN) {
            if (!x && !y) {
                // DN.style.transition = null;
                DN.style.transform = '';
            }
            else {
                // DN.style.transition = '50ms ease';
                DN.style.transform = `translate(${x}px, ${y}px)`;
            }
        }
    },


    HoCKeyboardCancelSubmit : (Wrapped) => {
        return class extends React.Component {
            constructor(props) {
                super(props);
                this.OnKeyDown = this.OnKeyDown.bind(this);
                this.OnKeyUp = this.OnKeyUp.bind(this);
            }
            OnKeyDown(e) {
                e.stopPropagation();
                return true;
            }
            OnKeyUp(e) {
                e.stopPropagation();
                if (this.props.onSubmit) {
                    if (e.charCode === 13 || e.keyCode === 13)
                        this.props.onSubmit();
                }
                else if (this.props.onCancel && e.keyCode === 27) {
                    this.props.onCancel();
                }

                return true;
            }
            render() {
                return (
                    <Wrapped {...this.props}
                        onKeyDown={this.OnKeyDown}
                        onKeyUp={this.OnKeyUp}
                    />
                );
            }
        }
    },
    SmoothScrollTo(RefScroll, position, offset, duration, TweenLite, Power2, ease) {
        setTimeout(() => {
            if (RefScroll) {
                RefScroll.view.scroll({
                    top:  position - Utils.UseNull(offset, 0),
                    behavior: 'smooth'
                });

                // const scrollTop = RefScroll.getScrollTop();
                // const obj = {
                //     top : scrollTop
                // };

                // let obj_To = {
                //     top : position - Utils.UseNull(offset, 0),
                //     onUpdate: (o, p) => {
                //         RefScroll.scrollTop(o[p]);
                //     },
                //     onUpdateParams : [obj, 'top'],
                //     ease : ease ||  Power2.easeInOut
                // }
                // let dur = duration;
                // if (!dur) {
                //     const diff = Math.abs(obj_To.top - obj.top);
                //     dur = (diff / 2400 * (diff < 1000 ? 1.4 : 1));
                // }

                // TweenLite.to(obj, dur , obj_To);
            }
        }, 10);
    }
}

class SearchInput extends React.Component {
    constructor(props) {
        super(props);
        this.OnKeyDown = this.OnKeyDown.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value : this.props.value
        }
    }
    componentDidMount() {
        if (this.props.autoFocus)
            setTimeout(() => {
                this.Ref_Input && this.Ref_Input.focus();
            }, 10);

    }
    onChange(e) {
        if (!this.props.debounce) {
            this.lastSentSearch = e.target.value;
            this.props.onSearch(this.lastSentSearch);
            this.setState({value : e.target.value})
        }
        else {
            this.setState({value : e.target.value})
        }
    }
    OnKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    OnKeyUp(e) {
        e.stopPropagation();
        if (this.props.onSubmit && (e.charCode === 13 || e.keyCode === 13))
            this.props.onSubmit();
        else if (this.props.onCancel && e.keyCode === 27) {
            this.state.value = '';
            this.props.onCancel();
        }
        else if (e.keyCode === 40 && this.props.onDown) {
            this.props.onDown();
        }
        else if (e.keyCode === 38 && this.props.onUp) {
            this.props.onUp();
        }
        else {
            if (this.props.debounce) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.props.onSearch(this.state.value);
                    this.lastSentSearch = this.state.value;
                }, this.props.debounceTime || 200);
            }
        }
        return true;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.value !== this.state.value && nextProps.value !== this.lastSentSearch)
            this.state.value = nextProps.value;
    }
    render() {
        let {InputType, onSearch, onCancel, hasClose, closeSize, ...restProps} = this.props;
        let ref = {};

        const value = this.props.debounce ? this.state.value : this.props.value;
        const input = (
            <InputType
                {...restProps}
                value={value}
                ref={(r) => this.Ref_Input = r}
                onChange={this.onChange}
                onKeyDown={this.OnKeyDown}
                onKeyUp={this.OnKeyUp}
                style={Utils.Merge({width : '100%'}, this.props.style)}
            />
        )
        if (this.props.hasClose || this.props.hasFullClose) {
            return (
                <div style={{display:'flex', alignItems:'center', flex:1, paddingRight:'8px'}}>
                    {input}
                    {
                        (this.props.hasFullClose || Utils.IsNotNullOrEmpty(value)) ?
                        <div style={{cursor:'pointer'}} onClick={this.props.onCancel} >
                            <svg xmlns="http://www.w3.org/2000/svg" width={this.props.closeSize || 14} viewBox="0 0 23 23">
                                <path fill="#AEAEAE" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                            </svg>
                        </div> : null
                    }
                </div>
            )
        }
        return input;
    }
}

UIUtils.SearchInput = SearchInput;

class ContentEditable extends React.Component {
    constructor() {
      super();
      this.emitChange = this.emitChange.bind(this);
      this.onFocus = this.onFocus.bind(this);
      this.onBlur = this.onBlur.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      this.onKeyUp = this.onKeyUp.bind(this);
    }


    render() {
      var { tagName, html, plainText, ...props } = this.props;

      return React.createElement(
        tagName || 'div',
        {
          ...props,
          className:'content-editable-note',
          ref: (e) => this.htmlEl = e,
          onInput: this.emitChange,
          onFocus :this.onFocus,
          onBlur: this.onBlur,
          contentEditable: !this.props.disabled,
          'aria-multiline' : "true",
          role:'textbox',
          dangerouslySetInnerHTML: {__html: html},
          focused : props.focused,
          spellCheck : "false",
          onKeyDown : this.onKeyDown,
          onKeyUp : this.onKeyUp
        },
        this.props.children);
    }
    componentDidMount() {
        const DN = ReactDOM.findDOMNode(this);
        this.LastValue = DN.innerText;
    }
    shouldComponentUpdate(nextProps) {
    if (this.isFocused)
        return false;
      let { props, htmlEl } = this;

      // We need not rerender if the change of props simply reflects the user's edits.
      // Rerendering in this case would make the cursor/caret jump

      // Rerender if there is no element yet... (somehow?)
      if (!htmlEl) {
        return true;
      }

      // ...or if html really changed... (programmatically, not by user edit)
      if (nextProps.html !== htmlEl.innerHTML && nextProps.html !== props.html) {
        return true;
      }

      let optional = ['style', 'className', 'disable', 'tagName'];

      // Handle additional properties
      return optional.some(name => props[name] !== nextProps[name]);
    }

    componentDidUpdate() {
      if ( this.htmlEl && this.props.html !== this.htmlEl.innerHTML ) {
        // Perhaps React (whose VDOM gets outdated because we often prevent
        // rerendering) did not update the DOM. So we update it manually now.
        this.htmlEl.innerHTML = this.props.html;
      }
    }
    onFocus(e) {
        this.isFocused = true;
        this.props.onFocus && this.props.onFocus();
    }
    onBlur(e) {
        let value = e.currentTarget.innerHTML;
        if (this.props.plainText)
            value = this.LastValue;
        this.props.onBlur && this.props.onBlur(value);
        this.isFocused = false;
    }
    onKeyDown(e) {
        e.stopPropagation();
        return true;
    }
    onKeyUp(e) {
        e.stopPropagation();
        return true;
    }
    emitChange(evt) {
      if (!this.htmlEl) return;
      var html = this.htmlEl.innerHTML;
      if (this.props.onChange && html !== this.lastHtml) {
        if (this.props.plainText)
            evt.target = {value : evt.currentTarget.innerText};
        else
            evt.target = { value: html };
        this.props.onChange(evt);
        this.lastHtml = html;
      }
      this.LastValue = evt.currentTarget.innerText;
    }
  }

UIUtils.ContentEditable = ContentEditable;

export default UIUtils;
