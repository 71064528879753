import DataTypes from '../datatypes';
import Utils from "../../toolabs-utils";

const DataContentValues = {
    Name1 : 'Fake Name 1',
    Name2 : 'Fake Name 2',
    Books : 'Books',
    Age1 : 34,
    Age2 : 52,
    Bool1 : true,
    Book1 : 'Book Name 1',
    Book2 : 'Book Name 2',
    Book3 : 'Book Name 3',
    Image1 : { Source :'url', Url : 'https://i.idefix.com/cache/150x242-0/originals/0001775620001-1.jpg'},
    Image2 : { Source :'url', Url : 'https://i.idefix.com/cache/150x242-0/originals/0000000064464-1.jpg'},
    Image3 : { Source :'url', Url : 'https://images.unsplash.com/photo-1515800352606-849721435cbc?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjMyMTExfQ&s=504c6707f66d52ffeb10cf31c726d1ad'},
    CarImage1 : {Source : 'url', Url : 'https://images.unsplash.com/photo-1493399239475-a070e6858bbf?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjMyMTExfQ&s=c0ca53c3cdb4079b555c8387e7591cf6'},
    CarImage2 : {Source : 'url', Url : 'https://images.unsplash.com/photo-1489686995744-f47e995ffe61?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjMyMTExfQ&s=547edc12a5644b0355d9850407fa8671'},
    CarImage3 : {Source : 'url', Url : 'https://images.unsplash.com/photo-1504455637435-8d1a13cd8a93?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=200&fit=max&ixid=eyJhcHBfaWQiOjMyMTExfQ&s=9cdc741c1bad3e6bc05da5bc047326c8'},
    Car1 : 'Car Name 1',
    Car2 : 'Car Name 2',
    Car3 : 'Car Name 3',
    BookList1 : [
        {
            'Id name 1.1' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Book1'
            },
            'Id image 1.1' : {
                type : DataTypes.MetaImage,
                name : 'Cover Picture',
                value : 'Image2'
            }
        },
        {
            'Id name 1.1' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Book2'
            },
            'Id image 1.1' : {
                type : DataTypes.MetaImage,
                name : 'Cover Picture',
                value : 'Image1'
            }
        },
        {
            'Id name 1.1' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Book3'
            },
            'Id image 1.1' : {
                type : DataTypes.MetaImage,
                name : 'Cover Picture',
                value : 'Image3'
            }
        },
        {
            'Id name 1.1' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Book3'
            },
            'Id image 1.1' : {
                type : DataTypes.MetaImage,
                name : 'Cover Picture',
                value : 'Image1'
            }
        }
    ],
    CarList1 : [
        {
            'car1.1 name' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Car1'
            },
            'car1.1 img' : {
                type : DataTypes.MetaImage,
                name : 'Car Picture',
                value : 'CarImage1'
            }
        },
        {
            'car1.1 name' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Car2'
            },
            'car1.1 img' : {
                type : DataTypes.MetaImage,
                name : 'Car Picture',
                value : 'CarImage2'
            }
        },
        {
            'car1.1 name' : {
                type : DataTypes.string,
                name : 'Name',
                value : 'Car3'
            },
            'car1.1 img' : {
                type : DataTypes.MetaImage,
                name : 'Car Picture',
                value : 'CarImage3'
            }
        },
    ]
};

const DataContentManager = {
    GetValue(Id) {
        return DataContentValues[Id];
    },
    DummyData : {
        'Id Name' : {
            type : DataTypes.string,
            name : 'Name',
            value : 'Name1'
        },
        'Id age' : {
            type : DataTypes.number,
            name : 'Age',
            value : 'Age1'
        },
        'Id isAlive' : {
            type : DataTypes.boolean,
            name : 'Alive',
            value : 'Bool1'
        },
        'Id picture 123' : {
            type : DataTypes.MetaImage,
            name : 'Picture',
            value : 'Image1'
        },
        'Id books 3123' : {
            type : DataTypes.list,
            name : 'Books',
            value : 'BookList1'
        },
        'Id cars 1' : {
            type : DataTypes.list,
            name : 'Cars',
            value : 'CarList1'
        }
    }
};

export default DataContentManager;
