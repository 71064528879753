import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 120,
            height : 40
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 40
            },                
            'width': {
                'value': 120
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['System', 'disabled', 'hover'],
        System : {
            name : 'System State',
            Variations : {
                Order : ['active', 'focus'],
                active : {
                    name : 'Active',
                    pseudo : true
                },
                focus : {
                    name : 'Focused',
                    pseudo : true
                },                
            }
        },
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            pseudo : true,
            name : 'Hover'
        }
    };

    Component.States = {
        Order : ['round'],        
        round : {
            SingleVariation : true,
            name : 'Round'
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {
            '8ejo9_1Cs': {
                dataType: 'string',
                name: 'Title',
                value : 'Label'
            }
        },
        Order: ['8ejo9_1Cs']
    };        
    
    Utils.MergeTo(MetaItem, {        
        Generic : {
            Type : GenericType
        },
        Models : {
            Default: {
                Default: {
                    textContent : {
                        Id : '8ejo9_1Cs'
                    }
                }
            }            
        },
        Property: {
            Default: {
                Default: {
                    display : {
                        value : 'flex'
                    },
                    alignItems: {
                        value: 'center'
                    },
                    flexDirection: {
                        value: 'column'
                    },
                    justifyContent: {
                        value: 'space-around'
                    },
                    text: {
                        value: ''
                    },
                    fixedHeight : {
                        value : true
                    },
                    backgroundColor: {
                        value: 'rgba(245, 245, 245, 1)'
                    },
                    borderStyle: {
                        value: 'none'
                    },
                    color: {
                        value: 'unset'
                    },
                    fontSize: {
                        value: 14
                    },
                    width : {
                        value : 200
                    },
                    height: {
                        value: 40
                    }
                },
                round : {
                    borderRadius : {
                        value : 99
                    }
                },
                hover: {
                    backgroundColor: {
                        value: 'rgba(255, 255, 255, 1)'
                    }
                },
                active: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                },
                disabled: {
                    borderColor: {
                        value: 'rgba(209, 209, 209, 1)'
                    },
                    color: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                },
                focus: {
                    borderColor: {
                        value: 'rgba(68, 137, 220, 1)'
                    }
                }
            }
        }
    });

    return Component;
}