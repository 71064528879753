import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';
import SystemStatePanel from '../../../../views/project/panels/left/systemstates';

// views/project/panels/right/iteminspector/selectToken';

export default class FigmaDSToolbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.Ref_SystesPanel = React.createRef();
    }
    ShowPanel(props) {
        this.setState({
            ShowPanelProps : props
        })
    }
    EditStates() {
        this.Ref_SystesPanel.current && this.Ref_SystesPanel.current.EditStates();
    }
    render() { 
        let content_panel;
        if (this.state.ShowPanelProps) {
            content_panel = (
                <motion.div 
                    style={{
                        ...SC.Styles.Absolute, 
                        zIndex : 999999999, 
                        boxSizing : 'border-box',
                        borderRight : SC.CurrentTheme.theme.border_ondark,
                        borderLeft : SC.CurrentTheme.theme.border_ondark,
                        backgroundColor : SC.CurrentTheme.theme.back,
                        display : 'grid', gridTemplateRows : '1fr'
                    }}
                    initial={{opacity : 0.7, x : -24}}
                    animate={{opacity : 1, x : 0}}
                    exit={{opacity : 0, x : -24}}
                    transition={{duration : 0.1}}
                >
                    {/* <FigmaSystemStates 
                        showThemeSelector={this.props.showThemeSelector}
                        GlobalState={this.props.GlobalState}
                        Ref_SystesPanel={this.Ref_SystesPanel}
                        onToggleThemeSelector={this.props.onToggleThemeSelector}
                        themeId={this.props.themeId}
                        inPanel
                    />  */}
                    <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back, position : 'relative'}}>
                        <SelectToken                                         
                            onClose={this.CloseTokenSelector}
                            onPreviewToken={this.PreviewToken}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            themeId={this.props.themeId}
                            onSetPropertyValues={this.SetItemPropertyValues}
                            onBindToModel={this.BindToModel}
                            GetComponentManager={this.GetComponentManager}
                            GetMetaItem={this.GetMetaItem}                                
                            onClosed={() => {                                            
                            }}
                            {...this.state.ShowPanelProps}
                            onClose={() => {
                                let onClose;
                                if (this.state.ShowPanelProps)
                                    onClose = this.state.ShowPanelProps.onClose;
                                
                                this.setState({
                                    ShowPanelProps : null
                                })
                                onClose && onClose();
                            }}
                        />
                    </SC.FCol> 
                </motion.div>                
            )
        }
        return (
            <AnimatePresence>
                {content_panel}
            </AnimatePresence> 
        )
    }
}
 

export const FigmaSystemStates = (props) => {
    return (
        <SC.FCol
            style={{
                paddingLeft : '2px',
                paddingRight : '2px',
                marginTop : props.inPanel ? 0 : '40px',
                backgroundColor : props.inPanel ? SC.CurrentTheme.theme.back : 'unset'
            }}
        >
            <SC.FRow alc jsb style={{padding : '10px', paddingTop : '4px',  fontSize : '11px', paddingBottom : props.showThemeSelector ? 0 : '8px'}}>
                <div 
                    style={{
                        width : '8px', height : '8px', borderRadius : '4px', marginLeft : '2px', marginRight : '6px',
                        backgroundColor : props.GlobalState === 'Default' ? '#efefef' : SC.CurrentTheme.theme.color_brand
                    }}
                />
                <div style={{flex : 1}}>Theme Manager</div>
                {
                    props.showThemeSelector ? 
                    <SC.FRow alc>
                        {
                            props.hasEditGrant && 
                            <SC.Icons.Icon_ButtonBox style={{marginRight : '4px', paddingLeft : '8px', paddingRight : '8px'}} hasFill hasCursor onClick={(e) => {props.Ref_SystesPanel.current.EditStates();}}>
                                <SC.LinkText>Edit Themes</SC.LinkText>
                            </SC.Icons.Icon_ButtonBox>
                        }
                        <SC.Icons.Icon_ButtonBox hasFill hasCursor onClick={(e) => {props.onToggleThemeSelector(false)}}>
                            <SC.Icons.Icon_Close size={16} />
                        </SC.Icons.Icon_ButtonBox>                               
                    </SC.FRow> : 
                    <SC.Icons.Icon_ButtonBox hasFill hasCursor onClick={(e) => {props.onToggleThemeSelector(true)}} title='Show Themes'>
                        <SC.Icons.Icon_SliderSettings size={24} />
                    </SC.Icons.Icon_ButtonBox>                        
                }                        
            </SC.FRow>
            <SystemStatePanel 
                ref={props.Ref_SystesPanel}
                collapsed={!props.showThemeSelector}
                justContent
                notExpandable
                noBreakpointsEdit={false}
                themeId={props.themeId} 
                contentStyle={{
                    marginBottom : props.inPanel ? '10px' : '16px'
                }}
                style={{
                    borderBottom : 'none'
                }}
            />                 
        </SC.FCol>  
    )
}