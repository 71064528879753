import React from 'react';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import { renderEngineIcon } from '../../SvgIcon';
import { GenericAccordion_SubTypeIds } from '../../../../toolabs-metaui/components/generic/initialize/accordion';

class Generic_Accordion extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();     

        if (this.props.slides) {
            this.slides = Utils.JustGet(this.props.slides, [], 'items');
            const activeId = Utils.JustGet(this.props.slides, null, 'activeSlide');
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === activeId}));
            this.state.active = index || 0;
        }
        else {
            this.slides = [];
            this.state.active = 0;
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.active !== nextState.active)
            return true;
        return super.shouldComponentUpdate(nextProps, nextState);
    }    
    SelectTab(index) {            
        this.setState({
            active : index
        });
    }
    renderItem({style, props, events, rest}) {                   
        const { MetaItem } = this.props;

        const options = {};
        GenericAccordion_SubTypeIds.map((SubType) => {
            options[SubType.Id] = {};
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);
            if (SubTypeId) {
                const MetaItemSubType = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === SubTypeId });
                if (MetaItemSubType) {
                    options[SubType.Id] = BuildItemStyles(MetaItemSubType, this.props) || {};
                }
            }
        })

        return (
            <div style={{
                ...style,
                position : 'relative',
                overflow : 'hidden'
            }}>
                {
                    this.slides && this.slides.map((slide, i) => {
                        let selectedStyle;
                        if (this.state.active === i) {
                            selectedStyle = options.HeaderSelected.style;
                        }
                        let firstLastStyle;
                        if (i === 0)
                            firstLastStyle = options.HeaderFirst.style;
                        else if (i === this.slides.length - 1)
                            firstLastStyle = options.HeaderLast.style;
                        return (
                            <React.Fragment key={slide.Id}>
                                <div 
                                    key={slide.Id}
                                    style={{
                                        ...options.Header.style,
                                        ...firstLastStyle,
                                        ...selectedStyle
                                    }}
                                    onClick={this.SelectTab.bind(this, i)}
                                >
                                    Header {i+1}
                                </div>
                                {
                                    this.state.active === i && 
                                    <div 
                                        key='content'
                                        style={{
                                            ...options.Content.style,
                                            display : 'flex'
                                        }}
                                    >
                                        <AccordionContent 
                                            slide={slide}                       
                                            renderSlide={this.props.renderCollectionItem}
                                        />
                                    </div>
                                }
                            </React.Fragment>
                        )
                    })
                }
            </div>
        )
    }
}

export default Generic_Accordion;


class AccordionContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
        
    render() { 
        const style = {
            flex : 1,
            display : 'flex',
            alignItems : 'stretch',
            transition : 'transform 0.4s ease-in-out',
        };
        
      
        return (  
            <div
                style={style}
            >
                {this.props.renderSlide && this.props.renderSlide(this.props.slide)}
            </div>
        );
    }
}
