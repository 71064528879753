import React from 'react';
import ReactDOM from 'react-dom';

import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { SC } from '../importer';


export class SortableList extends React.Component {
    constructor(props) {
        super(props);

        this.onDragEnd = this.onDragEnd.bind(this);
        this.GroupId = Utils.Id();
        this.state = {  };
        this.style = this.props.horizontal ? {...SC.Styles.Flex.Row} : {...SC.Styles.Flex.Column};
    }
    onDragEnd(result) {
        const { source, destination } = result;
        this.props.onDragEnd && this.props.onDragEnd();
        
        if (!destination) {            
            return;
        }

        this.props.onSort(source.index, destination.index);
    }

    render() { 
        const {style, onSort, children, ...rest} = this.props;
        return (  
            <DragDropContext
                onDragStart={this.props.onDragStart}
                onDragEnd={this.onDragEnd}
            >
                <Droppable 
                    droppableId={`DPH_${this.GroupId}`}
                    type={this.props.type}
                >
                    {
                        provided => (
                            <div
                                {...provided.droppableProps} 
                                ref={provided.innerRef} 
                                style={{
                                    ...provided.droppableProps.style,
                                    ...this.style,
                                    ...this.props.style
                                }}
                                {...rest}
                            >
                                {children}                                
                                {provided.placeholder}
                            </div>
                        )
                    }  
                </Droppable>
            </DragDropContext>
        );
    }
}

export const SortableListItem = ({draggableId, index, ItemBoxType, children, style, BoxProps, isTranfsormed}) => {
    return (
        <Draggable key={draggableId} draggableId={`DH_${draggableId}`} index={index}>
            {
                (provided, snapshot) => {
                    const style_item = {...provided.draggableProps.style};
                    if (isTranfsormed)
                        style_item.position = 'static';
                    return (
                        <div {...provided.draggableProps} ref={provided.innerRef} style={style_item}>
                            <ItemBoxType 
                                sortableProps={{
                                    props : {},
                                    handleProps : provided.dragHandleProps,
                                    isDragging : snapshot.isDragging
                                }}
                                style={style}
                                {...BoxProps}
                            >
                                {children}
                            </ItemBoxType>
                        </div>
                    )
                }
            }
        </Draggable>
    );
}