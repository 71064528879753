import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout
} from '../../../importer';

import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import { TeamEditor } from '../team';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import { InfoPanel } from '../../../components/info';
import TeamAndProjects from './teamcard';

export default class Teams extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            loading : true,
            hasAccess : false
        }
        

        this.NewTeam = this.NewTeam.bind(this);        
    }
    componentDidMount() {
        this.props.ownedTeams && GetDataApi().check_teamaccess(GetUserId()).then((hasAccess) => {
            if (hasAccess) {
                if (hasAccess.granted) {
                    this.setState({hasAccess : true});
                }
                else {
                    this.setState({hasAccess : false, waitingAccess : true}, () => {
                        this.RefListenerAccess = GetDataApi().api.get_ref(`/teams/earlyaccess/${GetUserId()}/granted`);
                        this.RefListenerAccess.on("value",(snapshot) => {
                            const granted = snapshot.val();
                            if (granted !== this.state.hasAccess) {
                                this.setState({
                                    hasAccess : granted,
                                    waitingAccess : !granted
                                });
                            }                            
                        });
                    });
                }
            }
            else {
    
            }
        });        

        if (!this.props.ownedTeams && !GetDataApi().IsLocal()) {
            this.RefListener = GetDataApi().api.get_ref(`/user_data/${GetUserId()}/teams/`);
            this.RefListener.on("value",(snapshot) => {
                this.props.onLoad();
            });
        }
    }
    componentWillUnmount() {
        if (this.RefListener) {
            this.RefListener.off('value');
        }
        if (this.RefListenerAccess) {
            this.RefListenerAccess.off('value');
        }
    }
    
    NewTeam() {
        this.setState({newTeam : true});
    }    
    onTeamSettings(team) {
        this.setState({editTeam : team});
    }
    onDeleteTeam(team) {
        Events.Ask_Delete('Team and members will be deleted permanently.', Events.GLOBAL.NOTIFICATION.TYPES.WARNING).then((result) => {
            if (result === Events.GLOBAL.NOTIFICATION.RESULT.CANCEL) {
                return;
            }
            else {
                GetDataApi().delete_team(team.id).then((result) => {
                    this.props.onLoad();
                })
            }
        })
    }
    onCloseTeamEditor() {
        this.setState({editTeam : false});
    }
    AcceptInvite(team, accept) {
        this.setState({processingAccept : true}, () => {
            if (accept) {
                GetDataApi().accept_team_invitaion(team.id, team.memberId, team.invitationId).then(() => {
                    this.state.processingAccept = false;
                    this.props.onLoad();
                })
            }     
            else {
                GetDataApi().decline_team_invitaion(team.id, team.memberId, team.invitationId).then(() => {
                    this.state.processingAccept = false;
                    this.props.onLoad();
                })
            }
        })           
    }
    LeaveTeam(team) {
        GetDataApi().leave_team(team.id, team.memberId).then(() => {
            this.props.onLoad();
        })
    }
    render() { 
        const style_header = {
            fontSize : '24px', 
            paddingLeft : '4px', 
            fontWeight : 500, 
            letterSpacing : '1.2px',
            color : SC.CurrentTheme.theme.text_card_title
        }

        let noAccess;

        if (!this.state.hasAccess && this.props.ownedTeams) {            
            noAccess = (
                <RequestTeamAccess 
                    waitingAccess={this.state.waitingAccess}
                    onWaitingAccess={() => this.setState({waitingAccess : true})}
                />
            )
        }

        let content, header;
        

        if (this.state.newTeam) {
            header = (
                <SC.BackClosableTitle title='New Team' large style={{...style_header, marginLeft : '-8px'}} titleStyle={{fontSize : 'inherit'}} onClose={() => {this.setState({newTeam : false})}} />
            )

            content = (
                <TeamEditor 
                    onClose={() => {this.setState({newTeam : false})}}
                    onLoad={this.props.onLoad}
                    isNew
                />
            )
        }
        else if (this.state.editTeam) {
            header = (
                <SC.BackClosableTitle title='Team Settings' large style={{...style_header, marginLeft : '-8px'}} titleStyle={{fontSize : 'inherit'}} onClose={this.onCloseTeamEditor.bind(this)} />
            )

            content = (
                <TeamEditor 
                    onClose={() => {this.setState({editTeam : false})}}
                    onLoad={this.props.onLoad}
                    id={this.state.editTeam.id}
                    team={this.state.editTeam}

                />
            )
        }
        else {            
            header = (
                <SC.FRow alc jsb style={style_header}>
                    <div>{this.props.ownedTeams ? 'My Teams' : 'Teams I am member of'}</div>
                    {this.props.ownedTeams && this.state.hasAccess && <SC.Buttons.RoundButton small style={{width : '120px'}} onClick={this.NewTeam}>New Team</SC.Buttons.RoundButton>}                    
                </SC.FRow>
            )
            if (this.props.loading) {
                content = (
                    <SC.Loading_Icon small />
                )
            }
            else {                
                let invitations = [], teamItems = [];

                this.props.teams && this.props.teams.map((team, i) => {
                    if (!team.active && !team.owner && !this.props.ownedTeams) {
                        invitations.push(
                            <InfoPanel style={{
                                padding : '16px',
                                display : 'grid',
                                gridTemplateColumns : '1fr auto auto',
                                gridGap : '16px',
                                alignItems : 'center',
                                fontSize : '12px',
                                marginTop : '24px'
                            }}>
                                <SC.TextDivAbbr>{team.ownerName} invited to {team.name} as {team.memberType}</SC.TextDivAbbr>
                                {
                                    this.state.processingAccept ? 
                                    <SC.Loading_Icon small /> : 
                                    <React.Fragment>
                                        <SC.LinkText primary onClick={this.AcceptInvite.bind(this, team, true)}>Accept</SC.LinkText>
                                        <SC.LinkText onClick={this.AcceptInvite.bind(this, team, false)}>Decline</SC.LinkText>
                                    </React.Fragment>
                                }
                                
                            </InfoPanel>
                        )
                    }
                    else if (team.owner === this.props.ownedTeams) {
                        teamItems.push(
                            <TeamAndProjects 
                                index={i}
                                key={team.id}
                                id={team.id}
                                name={team.name}
                                owner={team.owner}
                                active={team.active}
                                memberType={team.memberType}
                                admin={team.memberType === 'admin'}
                                projects={team.projects}
                                deleted={team.deleted}
                                shareable={this.props.teams.length > 1 && this.props.ownedTeams}
                                onSettings={this.onTeamSettings.bind(this, team)}
                                onNewProject={this.props.onNewProject}
                                onDelete={this.onDeleteTeam.bind(this, team)}
                                onLeaveTeam={this.LeaveTeam.bind(this, team)}
                                filterProject={this.props.filterProject}
                                sortMode={this.props.sortMode}
                                onLoad={this.props.onLoad}
                                ownedTeams={this.props.ownedTeams}
                                onGetTeams={this.props.onGetTeams}                                
                            />
                        );
                    }                    
                })

                if (this.props.ownedTeams) {

                }
                else {
                    if (teamItems.length === 0 && invitations.length === 0) {
                        return null;
                    }
                }

                if (this.props.teams) {
                    content = (
                        <Droppable 
                            droppableId='Teams'
                            type='TEAMS'
                        >
                            {
                                provided => (
                                    <div
                                        {...provided.droppableProps} 
                                        ref={provided.innerRef} 
                                        style={{
                                            ...provided.droppableProps.style,
                                            ...SC.Styles.Flex.Column
                                        }}
                                    >
                                        {teamItems}
                                        {provided.placeholder}
                                    </div>
                                )
                            }    
                        </Droppable> 
                    )
                    content = (
                        <React.Fragment>
                            {invitations}
                            {content}
                        </React.Fragment>
                    )
                }
                else {
                    content = (
                        <div>No Team</div>
                    )
                }            
            }
        }
        
        return (  
            <SC.FCol style={{
                marginTop : this.props.ownedTeams ? 0 : '48px'
            }}>
                {header}
                {noAccess}
                {content}                 
            </SC.FCol>                
        );
    }
}


export class RequestTeamAccess extends React.Component {

    constructor(props) {
        super(props);
        this.ApplyForAccess = this.ApplyForAccess.bind(this);
    }
    ApplyForAccess() {
        GetDataApi().apply_teamaccess(GetUserId());
        this.props.onWaitingAccess();
    }
    render() {
        return (
            <SC.FCol alc={this.props.compact} style={{fontSize : '14px'}}>
                <InfoPanel style={{
                    marginTop : this.props.compact ? 0 :'32px',
                    marginBottom : '24px',
                    padding : '16px',
                    lineHeight : '22px'                        
                }}>
                    With team mode, you will be able to create teams and work on the same projects with your team members collaboratively!
                </InfoPanel>
                {
                    this.props.waitingAccess ? 
                    <SC.FCol>
                        <div>You applied for early access.</div>
                        <div style={{marginTop : '8px'}}>We will send an email when it is ready.</div>
                    </SC.FCol> : 
                    <SC.Buttons.RoundButton onClick={this.ApplyForAccess}>Apply for early access</SC.Buttons.RoundButton>
                }
                
            </SC.FCol>
        )
    }
}