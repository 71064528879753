import Utils from '../toolabs-utils';

const FontLoader = {
    families : {},
    loadedFonts : {},    
    LoadTemp(fontFamily, variant, url) {
        return this.LoadInternal(true, fontFamily, variant, url);
    },
    Load(fontFamily, variant, url, targetDocument) {
        return this.LoadInternal(false, fontFamily, variant, url, targetDocument);
    },
    LoadInternal(isTemp, fontFamily, variant, url, targetDocument) {
        const target = targetDocument || document;

        if (!targetDocument && this.families[fontFamily] && this.families[fontFamily].variants[variant]) {
            if (!this.families[fontFamily].variants[variant].loaded) {
                return this.families[fontFamily].variants[variant].promise;                            
            }                
            else {
                return new Promise(function(resolve) {
                    resolve();
                });
            }
        }
        const id = Utils.Id();
        const that = this;
        const promiseFunction = function(resolve, reject) {
            var sheet = target.createElement('link');
            sheet.rel = 'stylesheet';
            sheet.href = url;
            sheet.id = id;
            sheet.type = 'text/css';
            target.head.appendChild(sheet);
            
            const sheetonload = () => {
                if (!isTemp) {
                    Utils.Set(that.families, true, fontFamily, 'variants', variant, 'loaded');
                    Utils.UnSet(that.families, fontFamily, 'variants', variant, 'promise');
                }                
                resolve(id);
            };
            sheet.addEventListener('load', sheetonload);
            sheet.onreadystatechange = function(){
              if (sheet.readyState === 'loaded' || sheet.readyState === 'complete') {
                sheetonload();
              }
            };                
        };
        const fontPromise = new Promise(promiseFunction);       
        if (!isTemp) 
            Utils.Set(this.families, fontPromise, fontFamily, 'variants', variant, 'promise');
        return fontPromise;
    },
    UnLoad(elementId, fontFamily, variant) {
        // Utils.UnSet(this.families, fontFamily, 'variants', variant);
        const linkElement = document.getElementById(elementId);
        if (linkElement) {
            linkElement.remove();
        }
    },
    LoadCustomFont(fontFamily, fontData, targetDocument) {
        const useFontFamily = Utils.ToPascalCase(fontFamily);
        if (this.loadedFonts[useFontFamily])
            return;
        const target = targetDocument || document;
        var newStyle = target.createElement('style');
        const fontFace = `
            @font-face {
                font-family : "${useFontFamily}";
                src : url(${fontData}) format("woff");
            }
        `;
        newStyle.appendChild(target.createTextNode(fontFace));                                
        target.head.appendChild(newStyle);
        this.loadedFonts[useFontFamily] = {
            loaded : true
        }
    }
}

export default FontLoader;