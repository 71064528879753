import React from 'react';
import {
    AppLayout,
    SC,
} from '../../../../importer';
import styled, {css} from 'styled-components';
import { SearchInput } from '../../searchbar';
import LightDarkBar from '../../../../components/light_dark_bar';
import Slider from '../../../../components/editors/slider';

export const QuickBrown = 'The quick brown fox jumps over the lazy dog';

export const PreviewBox = ({SelectFont, className, name, children, style, ...rest}) => {
    return (
        <SC.FCol onClick={SelectFont} className={className} {...rest} style={{...style}}>
            <div style={{fontSize : '12px', marginBottom : '8px'}}>
                {name}
            </div>
            {children}
        </SC.FCol>
    )
}

export const FontListHeader = (props) => {
    return (
        <SC.FRow f1 justifyEnd alc style={{paddingRight : '8px'}}>
            <SearchInput
                FilterText={props.filterText}
                Search={props.Search}
                CancelSearch={() => props.Search('')}
                short
                small
            />
            <SC.FRow style={{marginLeft : '8px', marginRight : '8px'}}>
                <LightDarkBar
                    medium
                    Dark={props.IsDark}
                    onChange={props.onToggleDark}
                />
            </SC.FRow>
            <SC.SortAndFilter.StylePanel_Total_Border />
            <SC.FRow alc>
                <div style={{width : '140px'}}>
                    <Slider
                        small
                        min={10}
                        max={100}
                        value={props.scale * 100}
                        onChange={props.onChangeScale}
                        onChanging={props.onChangingScale}
                        themeId={AppLayout.ThemeId}
                    />
                </div>
            </SC.FRow>                        
        </SC.FRow>
    )
}

export const Grid = styled.div`
    width : 100%;
    display : grid;
    grid-template-columns : repeat(auto-fill, minmax(${props => props.cellWidth || 260}px, 1fr));
    grid-auto-rows : minmax(200px, auto);
    justify-content : flex-start;
    align-content : flex-start;
    grid-gap : 16px;
`;

export const FontSelection_Preview = styled.div`
    font-size : 24px;
    flex : 1;
    padding : 14px;
    cursor : pointer;
    display : flex;
    justify-content : center;
    border-radius : 2px;
    align-items : center;   
    background-color : ${props => props.dark ? (props.theme.isLight ? props.theme.back_light : '#1e1e1e') : (props.theme.isLight ? '#363636' : '#D1D1D1')};
    color : ${props => props.dark ? (props.theme.isLight ? '#363636' : '#D1D1D1') : (props.theme.isLight ? '#ffffff' : '#1e1e1e')};   
`;
