import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Strings,
    Globals
} from '../../../importer';

import ChromaJs from 'chroma-js';
import { TokenTypes, CalculateScaleForValue, CalculateLineHeight, ConvertFontStyleToWeight } from '../../../views/project/manager/tokens';
import { FormatRGB } from '../../../components/editors/color';
import { SetFontTypeFace } from '../../../views/project/panels/left/designsystem/typography';
import {FontLoader} from '../../../toolabs-importer';
import { InitializeGradientToken } from '../../../components/editors/gradient';
import { FigmaPluginOptions } from '../options';
import { GetFigmaLayoutTokenItem, GetFigmaLayoutValue } from '../designsystem/autolayouts';

export default class FigmaPluginApi {
    constructor() {        

        this.ProjectId = null;

        this.UpdateFigmaStyleThrottled = this.UpdateFigmaStyleThrottled.bind(this);
        this.UpdateFigmaStyle = Utils.Throttle(this.UpdateFigmaStyleThrottled, 200);
        
        this.FigmaFonts = {};
        this.FigmaProjectsIds = {};

    }
    PreLoadProject(projectId, callback) {
        this.FigmaFonts = {};
        this.FigmaProjectsIds = {};
        this.FigmaStyleTokens = {};
        this.FigmaTokenMap = {};

        this.Callback_PreloadProject = callback;
        this.PostMessage({
            type : 'PreLoadProject',
            data : {
                projectId : projectId
            }
        });
    }
    LoadFonts() {
        const ExistingFontIds = Globals.ProjectManager.Tokens.Fonts();
        Utils.ForEach(ExistingFontIds, (FontId, ) => {
            const FontValue = Globals.ProjectManager.Tokens.FontValue(FontId);
            if (FontValue && FontValue.variant) {
                let variant = FontValue.variant.toString();
                if (variant === 'regular')
                    variant = '400';
                Utils.Set(this.FigmaFonts, FontId, FontValue.family, 'variants', variant, 'fontId');
            }
        });
    }
    LoadProject(Id, Callback) {
        this.FigmaFonts = {};
        this.FigmaStyleTokens = {};
        this.FigmaTokenMap = {};
        
        this.Callback_LoadProject = Callback;
        this.ProjectId = Id;        

        if (this.ProjectId) {
            this.LoadFonts();
        }        

        this.PostMessage({
            type : 'LoadProject',
            data : {
                projectId : Id,
                dataVersion : AppState.Versions.Figma.Plugin
            }
        });

        setTimeout(() => {
            if (this.Callback_LoadProject) {
                this.Callback_LoadProject({});
                delete this.Callback_LoadProject;
            }
        }, 1000);
    }
    MarkProjectLoaded(Id) {
        this.PostMessage({
            type : 'MarkProjectLoaded',
            data : {
                projectId : Id
            }
        });
    }
    BindColorToken(tokenId, nodeIds, callback) {
        this.BindFillOrStrokeToken(tokenId, false, nodeIds, callback);
    }
    BindStrokeToken(tokenId, nodeIds, callback) {
        this.BindFillOrStrokeToken(tokenId, true, nodeIds, callback);
    }
    BindFillOrStrokeToken(tokenId, isStroke, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);
        if (token) {
            let useToken = token;
            if (token.aliase) {
                const aliasetokenId = Globals.ProjectManager.Tokens.AliaseTokenId(tokenId);
                useToken = Globals.ProjectManager.Tokens.Token(aliasetokenId) || token;            
            }
            const value = Globals.ProjectManager.Tokens.ValueOf({model : token});
            this.Callback_Bindtoken = callback; 
            if (useToken.type === TokenTypes.Gradients) {
                const message = {
                    type : 'BindToken',
                    data : {
                        projectId : this.ProjectId,
                        id : tokenId,
                        name : token.name,
                        value : this.GetTokenValueForFigma_Gradient(useToken),
                        type : TokenTypes.Gradients,                    
                        nodeIds : nodeIds,
                        isStroke : isStroke,
                        isSilent : this.SilentBinding
                    }                                
                };
                this.PostMessage(message);
            }
            else {
                if (ChromaJs.valid(value)) {
                    const color = ChromaJs(value).rgba();
                    const message = {
                        type : 'BindToken',
                        data : {
                            projectId : this.ProjectId,
                            id : tokenId,
                            name : token.name,
                            value : {
                                red : color[0],
                                green : color[1],
                                blue : color[2],
                                alpha : color[3] * 100
                            },
                            type : TokenTypes.COLOR,                        
                            nodeIds : nodeIds,
                            isStroke : isStroke,
                            isSilent : this.SilentBinding
                        }                                
                    };
                    this.PostMessage(message);
                }
            }
        }        
    }
    
    BindCornerRadiusToken(tokenId, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);        
        this.Callback_Bindtoken = callback;
        token && this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : token.name,
                value : this.GetTokenValueForFigma_Radius(token),
                type : TokenTypes.BorderRadiuses,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });     
    }
    BindBorderToken(tokenId, nodeIds, callback) {
        this.Callback_Bindtoken = callback;
        const token = Globals.ProjectManager.Tokens.Token(tokenId);        
        token && this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : token.name,
                value : this.GetTokenValueForFigma_Border(token),
                type : TokenTypes.Borders,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });     
    }
    BindImageToken(tokenId, nodeIds) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);        
        token && this.GetTokenValueForFigma_Image(token).then((imageData) => {
            this.PostMessage({
                type : 'BindToken',
                data : {
                    projectId : this.ProjectId,
                    id : tokenId,
                    name : token.name,
                    value : imageData,
                    type : TokenTypes.Images,                
                    nodeIds : nodeIds,
                    isSilent : this.SilentBinding
                }     
            });
        })
    }
    BindShadowToken(tokenId, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);       
        this.Callback_Bindtoken = callback; 
        this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : token.name,
                value : this.GetTokenValueForFigma_Shadow(token),
                type : TokenTypes.Shadows,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });        
    }
    BindTextStyleToken(tokenId, nodeIds, callback) {
        const textPattern = Globals.ProjectManager.Tokens.Token(tokenId);
        const textStyle = Globals.ProjectManager.Tokens.TypePatterns.GetFigmaStyle(textPattern);

        this.Callback_Bindtoken = callback;

        this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : textPattern.name,
                value : textStyle,
                type : TokenTypes.TextPatterns,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });
    }
    BindSpaceToken(tokenId, width, height, nodeIds, callback) {
        const spacePattern = Globals.ProjectManager.Tokens.Token(tokenId);
        const value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(spacePattern);
        
        this.Callback_Bindtoken = callback;
        
        this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : spacePattern.name,
                value : {
                    size : value,
                    width : width ? true : false,
                    height : height ? true : false,
                },
                type : TokenTypes.SpacePatterns,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });
    }
    BindTextContentToken(tokenId, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});

        this.Callback_Bindtoken = callback;
        
        this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : token.name,
                value : {
                    characters : value
                },
                type : TokenTypes.ContentTexts,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });
    }
    BindBooleanToken(tokenId, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});

        this.Callback_Bindtoken = callback;
        
        this.PostMessage({
            type : 'BindToken',
            data : {
                projectId : this.ProjectId,
                id : tokenId,
                name : token.name,
                value : value ? true : false,
                type : TokenTypes.Booleans,                
                nodeIds : nodeIds,
                isSilent : this.SilentBinding
            }     
        });
    }
    BindLayoutToken(tokenId, nodeIds, callback) {
        const tokenItem = GetFigmaLayoutTokenItem(tokenId);
        if (tokenItem) {
            this.PostMessage({
                type : 'BindToken',
                data : {
                    projectId : this.ProjectId,
                    ...tokenItem,
                    type : TokenTypes.FigmaAutoLayouts,                
                    nodeIds : nodeIds,
                    isSilent : this.SilentBinding
                }     
            }); 
        }
    }
    GetIconTokenSvg(token) {
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});

        if (value) {
            let svg;
            if (value.provider === Strings.FIGMA) {
                svg = value.svg;
            }
            else if (value.provider === 'svg') {
                svg = value.data;
            }
            else if (value.provider === Strings.ICONFINDER) {
                svg = value.svg;
            }
            else if (value.provider === Strings.ICON_GOOGLE_MATERIAL) {                
                svg = `<svg viewBox='0 0 24 24'>`;
                value.paths.map((p, i) => {
                    svg += `<path  d='${p}' />`;
                })
                svg += '</svg>';
            }
            return svg;
        }
    }
    BindIconToken(tokenId, nodeIds, callback) {
        const token = Globals.ProjectManager.Tokens.Token(tokenId);
        const svg = this.GetIconTokenSvg(token);

        if (svg) {
            this.Callback_Bindtoken = callback;

            this.PostMessage({
                type : 'BindToken',
                data : {
                    projectId : this.ProjectId,
                    id : tokenId,
                    name : token.name,
                    value : {
                        svg : svg
                    },
                    type : TokenTypes.Icons,                
                    nodeIds : nodeIds,
                    isSilent : this.SilentBinding
                }     
            });
        }       
    }
    UpdateNodeTexts(nodeIds, value) {
        this.PostMessage({
            type : 'UpdateNodeTexts',
            data : {
                projectId : this.ProjectId,
                nodeIds : nodeIds,
                value : value
            }     
        });
    }
    UpdateNodeTokenMaps(callback) {
        this.Callback_UpdateNodeTokenMaps = callback;
        this.PostMessage({
            type : 'UpdateNodeTokenMaps',
            data : {
                projectId : this.ProjectId
            }     
        });
    }
    GetTokenValueForFigma_Gradient(token) {
        let value = Globals.ProjectManager.Tokens.ValueOf({model : token});
        let gradient = {
            type : 'linear',
            stops : []
        }

        if (value && value.gradient) {
            gradient.angle = Utils.JustGet(value.gradient, 90, 'path', 'angle');

            Utils.ForEach(value.gradient.colors, (gradientcolor, ) => {
                let color = gradientcolor.color;
                if (gradientcolor.colorId) {
                    color = Globals.ProjectManager.Tokens.ValueOfId(gradientcolor.colorId);
                }
                if (ChromaJs.valid(color)) {
                    const chromacolor = ChromaJs(color).rgba();;
                    gradient.stops.push({
                        pos : gradientcolor.stop,
                        color : {
                            red : chromacolor[0],
                            green : chromacolor[1],
                            blue : chromacolor[2],
                            alpha : chromacolor[3] * 100,
                        }
                    })
                }
            });            
        } 
        return gradient;
    }
    GetTokenValueForFigma_Radius(token) {
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});
        return Utils.JustGet(value, 0, 'value');
    }
    GetTokenValueForFigma_Border(token) {
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});
        return Utils.JustGet(value, 0, 'value');
    }
    GetTokenValueForFigma_Image(token) {
        const url = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : token}), null, 'url');
        return this.ConvertImageToArray(url);
    }
    GetTokenValueForFigma_Shadow(token) {
        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});
        const shadows = [];
        value && value.values && Utils.ForEach(value.values, (shadow, i) => {
            const figmaShadow = {
                inset : shadow.type === 'in',
                blur : shadow.blur,
                spread : shadow.spread,
                x : shadow.x,
                y : shadow.y
            };
            let color;
            if (shadow.colorId) {
                color = Globals.ProjectManager.Tokens.ValueOfId(shadow.colorId);                                            
            }
            else
                color = shadow.color;
            if (color) {
                if (ChromaJs.valid(color)) {
                    const chromaColor = ChromaJs(color).rgba();
                    figmaShadow.color = {
                        red : chromaColor[0],
                        green : chromaColor[1],
                        blue : chromaColor[2],
                        alpha : chromaColor[3] * 100,
                    }
                }
            }
            shadows.push(figmaShadow);
        });
        return {
            shadows : shadows
        };
    }

    ParseFigmaLocalSyle_Color(figmaColor) {
        return {
            fid : figmaColor.sid,
            name : figmaColor.name,            
            rgba : this.ConvertFigmaColorToRgba(figmaColor.color)
        };
    }
    ParseFigmaLocalSyle_Gradient(figmaGradient) {
        const gradient = {
            fid : figmaGradient.sid,
            name : figmaGradient.name,
            type : 'linear',
            stops : this.GetFigmaGradientStops(figmaGradient)
        }
        return gradient;
    }
    ConvertFigmaGradientColors(colors) {
        const sortedColor = Utils.Sort(colors, (c) => {return c.pos});
        const stops = [];
        sortedColor.map((figmaStop) => {
            stops.push({
                Id : Utils.Id(),
                stop : Number(Number(figmaStop.pos).toFixed(2)),
                color : this.ConvertFigmaColorToRgba(figmaStop.color)
            })
        })  
        return stops;     
    }
    ParseFigmaLocalSyle_Shadow(figmaEffect) {
        const shadow = {
            fid : figmaEffect.sid,
            name : figmaEffect.name,
            shadows : this.ParseFigmaStyleShadows(figmaEffect)
        };        
        shadow.css = Utils.GetShadowCss(shadow.shadows, false);
        return shadow;
    }
    ParseFigmaStyleShadows(figmaEffect) {
        const shadows = [];
        figmaEffect.shadows && figmaEffect.shadows.map((figmaShadow) => {
            const shadowLayer = {
                blur : figmaShadow.blur,
                spread : figmaShadow.spread,
                x : figmaShadow.x,
                y : figmaShadow.y
            };
            if (figmaShadow.inset)
                shadowLayer.type = 'in';
        
            shadowLayer.color = this.ConvertFigmaColorToRgba(figmaShadow.color);
            shadows.push(shadowLayer);
        })
        return shadows;
    }
    ParseFigmaLocalSyle_TextStyle(figmaText) {
        const token = {
            tokenId : figmaText.tokenId,
            fid : figmaText.sid,
            name : figmaText.name,
            fontSize : figmaText.fontSize,
            fontFamily : figmaText.fontFamily,
            fontWeight : figmaText.fontWeight
        };

        if (figmaText.lineHeight.unit === 'PERCENT') {
            token.lineHeight =  Math.round((figmaText.fontSize * figmaText.lineHeight.value) / 100);
        }
        else 
            token.lineHeight = figmaText.lineHeight.value;

        if (figmaText.letterSpacing.unit === 'PERCENT') {
            token.letterSpacing = figmaText.letterSpacing.value / 100;
            token.letterSpacingUnit = 'em';
        }
        else {
            token.letterSpacing = figmaText.letterSpacing.value;
            token.letterSpacingUnit = 'px';
        }

        if (!this.FigmaFonts[figmaText.fontFamily]) {
            this.FigmaFonts[figmaText.fontFamily] = {
                variants : {}
            };
        }
        if (!this.FigmaFonts[figmaText.fontFamily].variants[figmaText.fontWeight]) {
        
            let useFontId, useFontOldWeight;
            // if (fontCount === 0)
            //     useFontId = 'DefaultFont';
            // else if (fontCount === 1)
            //     useFontId = 'SecondaryFont';

            if (this.ProjectId) {
                const ExistingFontIds = Globals.ProjectManager.Tokens.Fonts();
                let fontExists = false;
                Utils.ForEach(ExistingFontIds, (FontId, ) => {
                    const FontValue = Globals.ProjectManager.Tokens.FontValue(FontId);
                    if (FontValue) {
                        if (FontValue.family === figmaText.fontFamily) {
                            if (FontValue.variant === figmaText.fontWeight.toString()) {
                                useFontId = FontId;
                                fontExists = true;
                                return false;
                            }
                            else if (FontValue.provider === Strings.CUSTOM) {
                                if (ConvertFontStyleToWeight(FontValue.style) === figmaText.fontWeight.toString()) {
                                    useFontId = FontId;
                                    fontExists = true;
                                    return false;
                                }   
                            }
                        }
                    }
                });
    
                
                if (!fontExists) {
                    const url = `https://fonts.googleapis.com/css?family=${figmaText.fontFamily}:${figmaText.fontWeight}`;
    
                    if (this.ProjectId) {
                        let oldFontId = 'DefaultFont';
                        
                        if (figmaText.tokenId) {
                            const token = Globals.ProjectManager.Tokens.Token(figmaText.tokenId);
                            if (token) {
                                const oldFontIdUsed = Globals.ProjectManager.Tokens.TypePatterns.GetPatternFontId(token);
                                if (oldFontIdUsed) {
                                    oldFontId = oldFontIdUsed;         
                                    const OldFontValue = Globals.ProjectManager.Tokens.FontValue(oldFontId);                           
                                    if (OldFontValue && OldFontValue.variant) {
                                        useFontOldWeight = OldFontValue.variant.toString();
                                    }
                                }                                
                            }
                        }

                        const patternIds = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TextPatterns);
                        let isFontUsedElseWhere = false;
                        Utils.ForEach(patternIds, (patternId, i) => {
                            const pattern = Globals.ProjectManager.Tokens.Token(patternId);
                            if (pattern && pattern.fontId) {
                                Utils.ForEach(pattern.fontId, ({value}, State) => {
                                    if (value && value === oldFontId) {
                                        if (patternId !== figmaText.tokenId || patternId === figmaText.tokenId && State !== Globals.ProjectManager.CurrentState) {
                                            isFontUsedElseWhere = true;
                                        }
                                    }
                                });
                            }
                        });

                        if (!isFontUsedElseWhere) {
                            useFontId = oldFontId;
                            if (this.FigmaFonts[figmaText.fontFamily] && useFontOldWeight)
                                delete this.FigmaFonts[figmaText.fontFamily].variants[useFontOldWeight];
                        }

                        useFontId = SetFontTypeFace(useFontId, {
                            provider : Strings.FONT_GOOGLE,
                            family : figmaText.fontFamily,
                            variant : figmaText.fontWeight.toString(),
                            url : url                                            
                        }, false, true);
                    }
                    
    
                    FontLoader.Load(figmaText.fontFamily, figmaText.fontWeight.toString(), url);     
                }    
            }
            else
                useFontId = Utils.Id();
                

            if (useFontId) {
                this.FigmaFonts[figmaText.fontFamily].variants[figmaText.fontWeight.toString()] = {
                    fontId : useFontId
                };
            }            

            token.fontId = useFontId;
        }
        else {
            token.fontId = this.FigmaFonts[figmaText.fontFamily].variants[figmaText.fontWeight].fontId;
        }
        return token;
    }

    LoadFigmaStyleFonts(fonts) {
        const invalidFontUrls = Utils.Get(this, [], 'invalidFontUrls');
        Utils.ForEach(fonts, (defs, fontFamily) => {
            defs.variants = Utils.Sort(defs.variants, (variant) => {return variant});
            let url = `https://fonts.googleapis.com/css?family=${fontFamily}`;
            defs.variants.map((variant, i) => {
                url += ':' + variant;
            })

            if (invalidFontUrls.indexOf(url) > -1) {

            }
            else {
                var sheet = document.createElement('link');
                sheet.rel = 'stylesheet';
                sheet.href = url;
                sheet.id = fontFamily;
                sheet.type = 'text/css';                
    
                sheet.addEventListener('error', () => {                    
                    invalidFontUrls.push(url);
                });
                
                document.head.appendChild(sheet);
            }
                            
        });
    }
    CreateTokenFromFigma_Color(color) {
        return Globals.ProjectManager.Tokens.Colors.Add({
            id : color.id,
            name : color.name,
            value : color.rgba
        });
    }
    CreateTokenFromFigma_Gradient(gradient) {
        return Globals.ProjectManager.Tokens.Add({
            id : gradient.tokenId,
            type : Globals.ProjectManager.Tokens.Types.Gradients,
            name : gradient.name,
            value : this.ConvertFigmaGradientValue(gradient)
        });      
    }
    ConvertFigmaGradientValue(gradient) {
        return {
            type : 'linear',
            gradient : {
                type : 'linear',
                colors : gradient.stops,
                path : {
                    angle : 90,
                    p1 : {x: 128, sx: 128, y: 256, sy: 256},
                    p2 : {x: 128, sx: 128, y: 0, sy: 0}
                }
            }
        }
    }
    ConvertFigmaShadowValue(shadow) {
        return {
            values : shadow.shadows
        }
    }
    ConvertFigmaTextValue(figmaText, fontId) {
        const result = {scale : 0, diff : 0};
        const typeScale = Globals.ProjectManager.Tokens.Typescale.Get();
        const {scale, diff} = CalculateScaleForValue({result : result, baseValue : typeScale.baseSize, ratio : typeScale.ratio, targetValue : figmaText.fontSize});
        return {
            fontId : fontId || 'DefaultFont',
            scaleIndex : scale,
            scaleDiff : diff,
            lineHeight : {
                value : figmaText.lineHeight,
                Unit : 'px'
            },
            letterSpacing : {
                value : figmaText.letterSpacing,
                Unit : figmaText.letterSpacingUnit || 'em'
            }
        }
    }
    CreateTokenFromFigma_Shadow(shadow) {
        return Globals.ProjectManager.Tokens.Add({
            id : shadow.tokenId,
            type : Globals.ProjectManager.Tokens.Types.Shadows,
            name : shadow.name,
            value : {
                values : shadow.shadows
            },
        });
    }
    CreateTokenFromFigma_Text(text, fontId) {
        const value = this.ConvertFigmaTextValue(text, fontId);
        return Globals.ProjectManager.Tokens.Add({
            id : text.tokenId,
            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
            name : Utils.UseNullOrEmpty(text.name, text.fontFamily + ' ' + text.fontSize),
            fontId : {
                Default : {
                    value : value.fontId || 'DefaultFont'
                }
            },
            scaleIndex : {
                Default : {
                    value : value.scaleIndex
                }
            },
            scaleDiff : {
                Default : {
                    value : value.scaleDiff
                }
            },
            lineHeight : {
                Default : {
                    value : value.lineHeight
                }
            },
            letterSpacing : {
                Default : {
                    value : value.letterSpacing
                }
            }        
        });
    }
    
    ConvertFigmaColorToRgba(color) {
        const {r,g,b,a} = color;
        let alpha = Number(Utils.UseNullOrEmpty(a, 0).toFixed(2));
        const chromaColor = ChromaJs(r,g,b).alpha(alpha, true);
        if (ChromaJs.valid(chromaColor)) {
            return Utils.ToRGBA(FormatRGB(chromaColor.rgba()).rgb);
        }
        return 'rgba(0,0,0,1)';
    }
    ConvertTokenColorToRgba(color) {
        if (ChromaJs.valid(color)) {
            const chromaColor = ChromaJs(color);
            if (ChromaJs.valid(chromaColor)) {
                return Utils.ToRGBA(FormatRGB(chromaColor.rgba()).rgb);
            }
        }        
        return 'rgba(0,0,0,1)';
    }

    GetLocalStyles(callback) {
        this.Callback_GetLocalStyles = callback;
        this.PostMessage({
            type : 'GetLocalStyles'
        });
    }
    GetFigmaStyleTokenMap(callback) {
        this.Callback_GetStylesTokenMap = callback;
        this.PostMessage({
            type : 'GetStylesTokenMap',
            data : {
                projectId : this.ProjectId
            }
        });
    }

    UpdateFigmaStyleThrottled(styleId, tokenId, callback) {
        const data = {};
        data[tokenId] = {
            styles : [styleId]
        };
        this.UpdateFigmaStyles(data, callback);
    }

    UpdateFigmaStyles(figmaStyleTokenMap, callback) {
        const updateStyles = {};
        if (figmaStyleTokenMap) {
            Utils.ForEach(figmaStyleTokenMap, ({styles}, tokenId) => {
                let token = Globals.ProjectManager.Tokens.Token(tokenId);
                let targetToken = token;
                let tokenName;
                let useTokenId = tokenId;
                if (token) {
                    tokenName = token.name;
                    if (token.aliase) {
                        const aliasetokenId = Globals.ProjectManager.Tokens.AliaseTokenId(tokenId);
                        useTokenId = aliasetokenId || useTokenId;
                        targetToken = Globals.ProjectManager.Tokens.Token(aliasetokenId) || token;

                        if (token && FigmaPluginOptions.semanticTokenName.value) {
                            tokenName = token.name;
                        }
                    }
                }                
                if (token) {
                    if (targetToken.type === TokenTypes.COLOR) {
                        let value = Globals.ProjectManager.Tokens.ValueOf({model : token});
                        // let value = Globals.ProjectManager.CurrentTheme.Theme[Globals.ProjectManager.CurrentState][useTokenId];
                        if (ChromaJs.valid(value)) {
                            const color = ChromaJs(value).rgba();;
                            value = {
                                red : color[0],
                                green : color[1],
                                blue : color[2],
                                alpha : color[3] * 100,
                            }

                            styles && styles.map((styleId) => {
                                updateStyles[styleId] = {
                                    type : TokenTypes.COLOR,
                                    value : value,
                                    name : tokenName
                                }
                            }) 
                        }                                               
                    } 
                    else if (targetToken.type === TokenTypes.Gradients) {
                        let value = this.GetTokenValueForFigma_Gradient(token)
                        value && styles && styles.map((styleId) => {
                            updateStyles[styleId] = {
                                type : TokenTypes.Gradients,
                                value : value,
                                name : tokenName
                            }
                        })                                
                    }   
                    else if (targetToken.type === TokenTypes.Shadows) {                                   
                        let value = this.GetTokenValueForFigma_Shadow(targetToken);
                        value && styles && styles.map((styleId) => {
                            updateStyles[styleId] = {
                                type : 'Shadows',
                                value : value,
                                name : tokenName
                            }
                        })
                    }                     
                    else if (targetToken.type === TokenTypes.TextPatterns) {
                        const textStyle = Globals.ProjectManager.Tokens.TypePatterns.GetFigmaStyle(token);

                        textStyle && styles && styles.map((styleId) => {
                            updateStyles[styleId] = {
                                type : TokenTypes.TextPatterns,
                                value : textStyle,
                                name : tokenName
                            }
                        })
                    }    
                    else if (targetToken.type === TokenTypes.ContentTexts) {
                        const value = Globals.ProjectManager.Tokens.ValueOf({model : token});
                        if (value) {
                            updateStyles[tokenId] = {
                                type : TokenTypes.ContentTexts,
                                value : {
                                    characters : value,
                                    name : tokenName
                                }
                            }
                        }                        
                    }
                    else if (targetToken.type === TokenTypes.Booleans) {                                                           
                        updateStyles[tokenId] = {
                            type : TokenTypes.Booleans,
                            value : Utils.IsTrue(Globals.ProjectManager.Tokens.ValueOf({model : targetToken})),
                            name : tokenName
                        }
                    } 
                    else if (targetToken.type === TokenTypes.SpacePatterns) {                                   
                        const value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(targetToken);
                    
                        updateStyles[tokenId] = {
                            type : TokenTypes.SpacePatterns,
                            value : value,
                            name : tokenName
                        };

                        const autoLayoutTokenIds = Globals.ProjectManager.Tokens.FigmaLayouts();

                        autoLayoutTokenIds && autoLayoutTokenIds.map((autoLayoutTokenId) => {
                            const autoLayoutToken = Globals.ProjectManager.Tokens.Token(autoLayoutTokenId);
                            if (autoLayoutToken) {
                                const tokenItem = GetFigmaLayoutValue(autoLayoutToken);
                                updateStyles[autoLayoutTokenId] = {
                                    type : TokenTypes.FigmaAutoLayouts,
                                    value : tokenItem,
                                    name : autoLayoutToken.name
                                }
                            }                            
                        })
                    } 
                    else if (targetToken.type === TokenTypes.FigmaAutoLayouts) {                                   
                        const tokenItem = GetFigmaLayoutValue(targetToken);
                        updateStyles[tokenId] = {
                            type : TokenTypes.FigmaAutoLayouts,
                            value : tokenItem,
                            name : tokenName
                        }
                    } 
                    else if (targetToken.type === TokenTypes.BorderRadiuses) {                                   
                        let value = this.GetTokenValueForFigma_Radius(targetToken);
                    
                        updateStyles[tokenId] = {
                            type : TokenTypes.BorderRadiuses,
                            value : value,
                            name : tokenName
                        }
                    } 
                    else if (targetToken.type === TokenTypes.Borders) {                                   
                        let value = this.GetTokenValueForFigma_Border(targetToken);
                    
                        updateStyles[tokenId] = {
                            type : TokenTypes.Borders,
                            value : value,
                            name : tokenName
                        }
                    } 
                    else if (targetToken.type === TokenTypes.Images) {             
                        const url = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : token}), null, 'url');                      
                        this.ConvertImageToArray(url).then((imageData) => {
                            const updateImageStyle = {};
                          
                            styles && styles.map((styleId) => {
                                updateImageStyle[styleId] = {
                                    type : TokenTypes.Images,
                                    value : imageData
                                }
                            })
                            this.PostMessage({
                                type : 'UpdateStyleValues',
                                data : {
                                    projectId : this.ProjectId,
                                    styleValues : updateImageStyle,
                                    GlobalState : Globals.ProjectManager.States.StateVariation,
                                    StateLabel : Globals.ProjectManager.States.CurrentStateVariation
                                }
                            });
                        })
                    }  
                    else if (targetToken.type === TokenTypes.Icons) {             
                        const svg = this.GetIconTokenSvg(token);
                        updateStyles[tokenId] = {
                            type : TokenTypes.Icons,
                            value : svg,
                            name : tokenName
                        }
                    }  
                }                                          
            });
           

            if (Utils.Keys(updateStyles).length > 0) {
                this.Callback_UpdateStyleValues = callback;
                this.PostMessage({
                    type : 'UpdateStyleValues',
                    data : {
                        projectId : this.ProjectId,
                        styleValues : updateStyles,
                        GlobalState : Globals.ProjectManager.States.StateVariation,
                        StateLabel : Globals.ProjectManager.States.CurrentStateVariation
                    }
                });
            }            
            else if (callback) {
                callback();
            }
        }                          
    }
    UpdateTokenNames(tokens) {        
        if (FigmaPluginOptions.semanticTokenName.value) {
            if (tokens) {
                Utils.ForEach(tokens, (item, i) => {
                    const token = Globals.ProjectManager.Tokens.Token(item.id);
                    if (token && token.aliase) {
                        const tokenId = Globals.ProjectManager.Tokens.AliaseTokenId(item.id);
                        if (tokenId) {
                            const aliaseToken = Globals.ProjectManager.Tokens.Token(tokenId);
                            if (aliaseToken) {
                                item.name = aliaseToken.name;
                            }
                        }
                    }
                });
            }            
        }
        this.PostMessage({
            type : 'UpdateStyleNames',
            data : {
                projectId : this.ProjectId,
                tokens : tokens
            }
        });
    }
    GetSelection(callback) {
        this.Callback_GetSelection = callback;
        this.PostMessage({
            type : 'GetSelection'
        });
    }
    GetLastSelection(callback) {
        this.Callback_GetLastSelection = callback;
        this.PostMessage({
            type : 'GetLastSelection'
        });
    }
    ComputeTokenSyncs(tokenmap, localStyles, state, DoNotSync) {        
        this.InitialTokenSync = {
            colors : [],
            gradients : [],
            shadows : [],
            texts : [],
            tokenNames : [],
            total : 0
        };

        let hasNewStyleTokens = false;

        if (localStyles) {
            Utils.ForEach(localStyles, (figmaStyle, ) => {
                const boundTokenId = Utils.JustGet(tokenmap, null, figmaStyle.sid, 'tokenId');
                let createTokenId;
                if (boundTokenId) {
                    let token = Globals.ProjectManager.Tokens.Token(boundTokenId);
                    let tokenName;
                    let aliasetokenId;
                    if (token) {
                        tokenName = token.name;
                        if (token.aliase) {
                            aliasetokenId = Globals.ProjectManager.Tokens.AliaseTokenId(boundTokenId);
                            token = Globals.ProjectManager.Tokens.Token(aliasetokenId);

                            if (token && FigmaPluginOptions.semanticTokenName.value) {
                                tokenName = token.name;
                            }
                        }
                    }
                                            
                    if (token) {
                        if (tokenName !== figmaStyle.name) {
                            this.InitialTokenSync.tokenNames.push({
                                type : figmaStyle.type,
                                tokenId : boundTokenId,
                                styleId : figmaStyle.sid,
                                name : tokenName,
                                figmaName : figmaStyle.name
                            })

                            this.InitialTokenSync.total++;
                        }

                        if (figmaStyle.type === TokenTypes.COLOR) {
                            let figmaColor = this.ConvertFigmaColorToRgba(figmaStyle.color);

                            if (token.type === TokenTypes.Gradients) {
                                Globals.ProjectManager.Tokens.Colors.ConvertGradient({Id : boundTokenId, ToGradient : false});
                                token = Globals.ProjectManager.Tokens.Token(boundTokenId);
                            }                  
                            const tokenValue = Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : token}), '').replace(/\s+/g, '');          
                            let tokenColor = tokenValue;

                            if (ChromaJs.valid(tokenValue)) {
                                const rgba = ChromaJs(tokenValue).rgba();
                                const frgba = FormatRGB(rgba).rgb;
                                tokenColor = Utils.ToRGBA(frgba);
                            }
                            if (tokenColor !== figmaColor) {
                                let isAliaseAlreadyAdded = false;
                                if (aliasetokenId) {
                                    Utils.Find(this.InitialTokenSync.colors, (changed) => {
                                        if (changed.token && (changed.token.aliaseOf === aliasetokenId || changed.token.id === aliasetokenId)) {
                                            isAliaseAlreadyAdded = true;
                                            return true;
                                        }
                                        return false;
                                    })    
                                    
                                }
                                if (!isAliaseAlreadyAdded) {
                                    this.InitialTokenSync.colors.push({
                                        token : {
                                            id : boundTokenId,
                                            aliaseOf : aliasetokenId,
                                            name : token.name,
                                            color : tokenColor
                                        },
                                        figma : {
                                            id : figmaStyle.sid,
                                            color : figmaColor,
                                            name : figmaStyle.name
                                        }
                                    })
        
                                    this.InitialTokenSync.total++;
                                }
                                
                            }                            
                        }
                        else if (figmaStyle.type === TokenTypes.Gradients) {                            
                            if (token.type === TokenTypes.COLOR) {
                                Globals.ProjectManager.Tokens.Colors.ConvertGradient({Id : boundTokenId, ToGradient : true});
                                const gradient = {};
                                InitializeGradientToken(gradient);
                                Globals.ProjectManager.Tokens.Colors.SetValue({id : boundTokenId, value : gradient});
                                token = Globals.ProjectManager.Tokens.Token(boundTokenId);
                            } 

                            const tokenGradient = Globals.ProjectManager.Tokens.ValueOf({model : token});

                            if (tokenGradient && tokenGradient.gradient) {
                                const tokenStops = tokenGradient.gradient.colors;
                                const figmaStops = this.GetFigmaGradientStops(figmaStyle);
                    
                                let isChanged;
                                if (tokenStops.length !== figmaStops.length)
                                    isChanged = true;
                                else {
                                    Utils.ForEach(tokenStops, (tokenStop, i) => {
                                        const figmaStop = figmaStops[i];
                                        if (figmaStop.stop !== tokenStop.stop)  {
                                            isChanged = true;
                                            return false;
                                        }
                                        else {
                                            if (figmaStop.color !== tokenStop.color) {
                                                isChanged = true;
                                                return false;
                                            }
                                        }
                                    });
                                }

                                if (isChanged) {
                                    this.InitialTokenSync.gradients.push({
                                        token : {
                                            id : boundTokenId,
                                            name : token.name,
                                            stops : tokenStops,
                                            css : Utils.GetGradientCss(tokenStops, 'linear', 90)
                                        },
                                        figma : {
                                            id : figmaStyle.sid,
                                            stops : figmaStops,
                                            css : Utils.GetGradientCss(figmaStops, 'linear', 90)
                                        }
                                    })
        
                                    this.InitialTokenSync.total++;
                                }
                            } 
                        }
                        else if (figmaStyle.type === TokenTypes.Shadows) {                            
                            const tokenShadow = Globals.ProjectManager.Tokens.ValueOf({model : token});
                            
                            const tokenshadows = Utils.JustGet(tokenShadow, [], 'values');
                            const token_css = Utils.GetShadowCss(tokenshadows, token.textShadow, 
                                (tokenId) => {
                                    return Globals.ProjectManager.Tokens.ValueOfId(tokenId);
                                }
                            ); 
                            figmaStyle.shadows = this.ParseFigmaStyleShadows(figmaStyle);
                            const figma_css = Utils.GetShadowCss(figmaStyle.shadows, token.textShadow, 
                                (tokenId) => {
                                    return Globals.ProjectManager.Tokens.ValueOfId(tokenId);
                                }
                            ); 

                            if (figma_css !== token_css) {
                                this.InitialTokenSync.shadows.push({
                                    token : {
                                        id : boundTokenId,
                                        name : token.name,
                                        shadows : tokenshadows,
                                        css : token_css
                                    },
                                    figma : {
                                        id : figmaStyle.sid,
                                        shadows : figmaStyle.shadows,
                                        css : figma_css
                                    }
                                })
    
                                this.InitialTokenSync.total++;
                            }                           
                        }
                        else if (figmaStyle.type === TokenTypes.TextPatterns) {                        
                            const tokenTextStyle = Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyle(token);
                            const tokenTextFigmaStyle = Globals.ProjectManager.Tokens.TypePatterns.GetFigmaStyle(token);
                            const tokenStyleId = GetUniqueTextTokenStyle(token);

                            const figmaText = this.ParseFigmaLocalSyle_TextStyle(figmaStyle);
                            const figmaTextStyle = this.ConvertFigmaTextValue(figmaText, figmaText.fontId);
                            const figmaStyleId = GetUniqueFigmaTextStyle(figmaText);

                            let isChanged = tokenStyleId !== figmaStyleId;

                            if (isChanged) {
                                this.InitialTokenSync.texts.push({
                                    token : {
                                        id : boundTokenId,
                                        name : token.name,
                                        textStyle : tokenTextStyle,
                                        textFigmaStyle : tokenTextFigmaStyle
                                    },
                                    figma : {
                                        id : figmaStyle.sid,
                                        figmaText : figmaText,
                                        textStyle : figmaTextStyle
                                    }
                                })
    
                                this.InitialTokenSync.total++;
                            }                           
                        }
                    }
                    else {
                        createTokenId = boundTokenId;
                    }
                }
                else {
                    // createTokenId = Utils.Id();
                }
                if (createTokenId) {
                    if (!DoNotSync) {
                        figmaStyle.tokenId = createTokenId;
                        let tokenId = this.CreateNewTokenFromStyle(figmaStyle);
                        
                        if (tokenId) {
                            hasNewStyleTokens = true;
                            Utils.Set(this.FigmaStyleTokens, tokenId, figmaStyle.sid, 'tokenId');
                            Utils.Set(this.FigmaTokenMap, { styles : [figmaStyle.sid] }, tokenId);
                        }
                    }
                    else {
                        this.InitialTokenSync.total++;
                    }
                }
            });
        }

        if (hasNewStyleTokens) {
            this.PostMessage({
                type : 'ReplaceStyleTokenMap',
                data : {
                    projectId : Globals.ProjectManager.Id,
                    bindings : this.FigmaTokenMap
                }
            })
        }

        // if (Utils.Keys(new_token_stylemappings).length > 0) {
        //     this.PostMessage({
        //         type : 'BindStylesToTokens',
        //         data : {
        //             projectId : Globals.ProjectManager.Id,
        //             bindings : new_token_stylemappings
        //         }
        //     })
        // }

    }

    BindTokenToSLocaltyle(styleId, tokenId, callback) {
        this.BindTokensToSLocaltyles([{
            styleId : styleId,
            tokenId : tokenId
        }], callback);
    }
    BindTokensToSLocaltyles(bindings, callback) {
        this.Callback_BindStylesToTokens = callback;
        this.PostMessage({
            type : 'BindTokenToSLocaltyles',
            data : {
                projectId : Globals.ProjectManager.Id,
                bindings : bindings
            }
        })
    }
    DeleteStyleOfToken(tokenId, markStyle) {
        this.PostMessage({
            type : 'DeleteStyleToken',
            data : {
                projectId : Globals.ProjectManager.Id,
                tokenId : tokenId,
                markStyle : markStyle
            }
        })
    }
    GetDebugData(callback) {
        this.Callback_GetDebugData = callback;
        this.PostMessage({
            type : 'GetDebugData'
        })
    }
    CreateNewTokenFromStyle(figmaStyle) {
        let tokenId;
        if (figmaStyle.type === TokenTypes.COLOR) {
            tokenId = this.CreateTokenFromFigma_Color({
                id : figmaStyle.tokenId,
                name : figmaStyle.name,
                rgba : this.ConvertFigmaColorToRgba(figmaStyle.color)
            });                        
        }
        else if (figmaStyle.type === TokenTypes.Gradients) {            
            figmaStyle.stops = this.GetFigmaGradientStops(figmaStyle);
            tokenId = this.CreateTokenFromFigma_Gradient(figmaStyle);                        
        } 
        else if (figmaStyle.type === TokenTypes.Shadows) {            
            figmaStyle.shadows = this.ParseFigmaStyleShadows(figmaStyle);
            tokenId = this.CreateTokenFromFigma_Shadow(figmaStyle);                        
        } 
        else if (figmaStyle.type === TokenTypes.TextPatterns) {            
            const text = this.ParseFigmaLocalSyle_TextStyle(figmaStyle);

            if (text) {
                tokenId = this.CreateTokenFromFigma_Text(text, text.fontId);            
            }            
        }     
        return tokenId;
    }

    GetFigmaGradientStops(figmaStyle) {
        const stops = [];
        figmaStyle.stops.map((stop) => {
            stops.push({
                stop : stop.stop,
                color : this.ConvertFigmaColorToRgba(stop.color),
                Id : Utils.Id()
            })
        })
        return stops;
    }

    OnFigmaStylesChanged({styles, state}) {
        if (!this.ProjectId)
            return;

        if (this.HandleFigmaChanges_Suspended)
            return;

        if (styles) {
            const new_token_stylemappings = {};

            Utils.ForEach(styles, (figmaStyle, ) => {
                let createTokenId;
                if (figmaStyle.tokenId) {
                    const token = Globals.ProjectManager.Tokens.Token(figmaStyle.tokenId);
                    if (token) {
                        if (figmaStyle.name !== token.name) {
                            Globals.ProjectManager.Tokens.UpdateProp({id : figmaStyle.tokenId, name : 'name', value : figmaStyle.name});
                        }
                        if (figmaStyle.type === TokenTypes.COLOR) {
                            let color = this.ConvertFigmaColorToRgba(figmaStyle.color);
                            const result = {
                                items : []
                            }
                            Globals.ProjectManager.Tokens.Colors.SetValue({
                                id : figmaStyle.tokenId,
                                value : color,
                                state : state,
                                result : result
                            });

                            Utils.Set(Globals.ProjectManager.CurrentTheme.Theme, color, Globals.ProjectManager.CurrentState, figmaStyle.tokenId);

                            if (result.aliases) {
                                const data = {};
                                result.aliases.map((aliaseId) => {
                                    const tokenStyleBinding = Utils.JustGet(this.FigmaTokenMap, null, aliaseId);
                                    if (tokenStyleBinding) {
                                        if (tokenStyleBinding.styles && tokenStyleBinding.styles.length > 0) {                        
                                            Utils.Set(data, tokenStyleBinding.styles, aliaseId, 'styles');
                                        }                    
                                    }                    
                                });
                                if (Utils.Keys(data).length > 0) {
                                    this.UpdateFigmaStyles(data);
                                }
                            }
                        }
                        else if (figmaStyle.type === TokenTypes.Gradients) {
                            const tokenGradient = Globals.ProjectManager.Tokens.ValueOf({model : token});
                            const figmaStops = this.GetFigmaGradientStops(figmaStyle);
                            Utils.Set(tokenGradient, figmaStops, 'gradient', 'colors');
    
                            Globals.ProjectManager.Tokens.SetValue({
                                id : figmaStyle.tokenId,
                                value : tokenGradient,
                                type : TokenTypes.Gradients,
                                state : state
                            });
                        }
                        else if (figmaStyle.type === TokenTypes.Shadows) {
                            figmaStyle.shadows = this.ParseFigmaStyleShadows(figmaStyle);
                            const value = this.ConvertFigmaShadowValue(figmaStyle);
                            Globals.ProjectManager.Tokens.SetValue({
                                id : figmaStyle.tokenId,
                                value : value,
                                type : TokenTypes.Shadows,
                                state : state
                            });
                        }
                        else if (figmaStyle.type === TokenTypes.TextPatterns) {
                            const text = this.ParseFigmaLocalSyle_TextStyle(figmaStyle);
                            const textProps = this.ConvertFigmaTextValue(text, text.fontId);

                            Utils.ForEach(textProps, (textValue, valueName) => {    
                                Globals.ProjectManager.Tokens.SetValue({
                                    id : figmaStyle.tokenId,
                                    name : valueName,
                                    value : textValue,
                                    type : TokenTypes.TextPatterns,
                                    DoNotBroadcast : true,
                                    state : state
                                });
                            });
                        }
                    }        
                    else {
                        createTokenId = figmaStyle.tokenId;
                    }            
                }
                else {
                    if (FigmaPluginOptions.autoCreateTokens.value)
                        createTokenId = Utils.Id();
                }

                if (createTokenId) {                    
                    let tokenId = this.CreateNewTokenFromStyle(figmaStyle);
                        
                    if (tokenId) {
                        Utils.Set(this, tokenId, 'FigmaStyleTokens', figmaStyle.sid, 'tokenId')
                        Utils.Set(this.FigmaTokenMap, {styles : [figmaStyle.sid]}, tokenId);
                        new_token_stylemappings[tokenId] = {
                            styles : [figmaStyle.sid],
                            type : figmaStyle.type
                        }
                    }
                }
            });

            if (Utils.Keys(new_token_stylemappings).length > 0) {
                this.PostMessage({
                    type : 'BindStylesToTokens',
                    data : {
                        projectId : Globals.ProjectManager.Id,
                        bindings : new_token_stylemappings
                    }
                })
            }

            Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
        }
    }
    DetachToken(tokenId, nodeIds, callback) {
        this.Callback_DetachToken = callback;
        this.PostMessage({
            type : 'DetachToken',
            data : {
                projectId : Globals.ProjectManager.Id,
                id : tokenId,
                nodeIds : nodeIds
            }
        }) 
    }
    SelectNodes(nodeIds, select) {
        this.PostMessage({
            type : 'SelectNodes',
            data : {
                nodes : nodeIds,
                select : select
            }
        })  
    }
    DeleteNodes(nodeIds) {
        this.PostMessage({
            type : 'DeleteNodes',
            data : {
                nodes : nodeIds
            }
        })  
    }
    ZoomToNodes(nodeIds) {
        this.PostMessage({
            type : 'ZoomNodes',
            data : {
                nodes : nodeIds
            }
        })  
    }
    HandleMessage(message) {
        if (message.type === 'GetLocalStyles_Response') {
            if (message.data && this.Callback_GetLocalStyles) {
                this.Callback_GetLocalStyles(message.data);
                delete this.Callback_GetLocalStyles;
            }                          
        }
        else if (message.type === 'GetStylesTokenMapValues_Response') {
            if (message.data && this.Callback_GetTokenMapValues) {
                this.Callback_GetTokenMapValues(message.data);
                delete this.Callback_GetTokenMapValues;
            }                          
        }
        else if (message.type === 'GetStylesTokenMap_Response') {
            if (message.data) {
                if (this.Callback_GetStylesTokenMap) {
                    this.Callback_GetStylesTokenMap(message.data);
                    delete this.Callback_GetStylesTokenMap;
                }                    
            }                          
        }
        else if (message.type === 'PreLoadProject_Response') {
            if (this.Callback_PreloadProject) {
                const callback = this.Callback_PreloadProject;
                delete this.Callback_PreloadProject;
                callback(message.data);                
            }
        }        
        else if (message.type === 'LoadProject_Response') {
            if (this.Callback_LoadProject) {
                const callback = this.Callback_LoadProject;
                delete this.Callback_LoadProject;
                callback(message.data);                
            }
        }
        else if (message.type === 'UpdateStyleValues_Response') {
            if (this.Callback_UpdateStyleValues) {
                this.Callback_UpdateStyleValues(message.data);
            }
        }
        else if (message.type === 'GetSelectionResponse') {
            if (message.data && message.data.nodes) {
                this.SelectedNodes = message.data.nodes;
            }
            else {
                this.SelectedNodes = 0;
            }
            // console.log(this.SelectedNodes);

            if (this.Callback_GetSelection) {                
                const callback = this.Callback_GetSelection;
                delete this.Callback_GetSelection;                
                callback();
            }
        }
        else if (message.type === 'GetLastSelectionResponse') {
            if (message.data && message.data.nodes) {
                this.SelectedNodes = message.data.nodes;
            }
            else {
                this.SelectedNodes = 0;
            }
            // console.log(this.SelectedNodes);

            if (this.Callback_GetLastSelection) {                
                const callback = this.Callback_GetLastSelection;
                delete this.Callback_GetLastSelection;                
                callback();
            }
        }
        else if (message.type === 'BindToken_Response') {
            const {tokenmap, bindings, selection} = message.data;
            this.FigmaTokenMap = tokenmap;
            this.FigmaStyleTokens = bindings;

            // console.log(message);

            if (this.Callback_Bindtoken) {
                const callback = this.Callback_Bindtoken;
                callback();
                delete this.Callback_Bindtoken;
            }
            else if (this.onBindTokenResponse) {
                this.onBindTokenResponse();
            }
        }
        else if (message.type === 'ImportSvg_Response') {
            if (this.Callbac_ImportSvg) {
                this.Callbac_ImportSvg(message.data);
            }
        }
        else if (message.type === 'Component_Import_Response') {
            if (this.Callbac_ImportComponent) {
                this.Callbac_ImportComponent(message.data);
            }
        }
        else if (message.type === 'BindTokenToSLocaltyles_Response') {
            if (this.Callback_BindStylesToTokens) {
                this.FigmaTokenMap = message.data.tokenmap;
                this.FigmaStyleTokens = message.data.bindings;
                this.Callback_BindStylesToTokens();
                delete this.Callback_BindStylesToTokens;
            }
        }
        else if (message.type === 'UpdateNodeTokenMaps_Response') {
            const {tokenMap} = message.data;
            this.UpdateFigmaStyles(tokenMap, () => {
                if (this.Callback_UpdateNodeTokenMaps) {
                    const callback = this.Callback_UpdateNodeTokenMaps;
                    delete this.Callback_UpdateNodeTokenMaps;
                    callback();
                }
                AppLayout.Refs.Ref_FigmaProject && AppLayout.Refs.Ref_FigmaProject.ToggleSyncIcon(false);
            });     
        }
        else if (message.type === 'FireStylesChanged') {
            this.OnFigmaStylesChanged(message.data);
        }
        else if (message.type === 'FireTokenChanged') {
            const {tokenId, type, value} = message.data;
            if (type === 'content') {
                Globals.ProjectManager.Tokens.SetValue({id : tokenId, value : value});

                Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                    Id : tokenId,
                    Type : Globals.ProjectManager.Tokens.Types.ContentTexts,
                    value : value,
                    DoNotUpdateFigma : true
                }]);
            }
        }
        else if (message.type === 'GetDebugData_Response') {
            if (this.Callback_GetDebugData){
                const cb = this.Callback_GetDebugData;
                delete this.Callback_GetDebugData;
                cb(message.data);
            }
        }
        else if (message.type === 'ReplaceStyleTokenMap_Response') {
            if (this.Callback_ReplaceStyleTokenMap){
                this.Callback_ReplaceStyleTokenMap();
            }
        }
        else if (message.type === 'DetachToken_Response') {
            if (this.Callback_DetachToken){
                const cb = this.Callback_DetachToken;
                delete this.Callback_DetachToken;
                cb();
            }
        }
        else {
            console.log('Unknown : ' + message.type)
        }
    }

    PostMessage(message) {        
        window.parent.postMessage({
            toolabsMessage : message
        }, '*');
    }
    SendNotification(message) {
        this.PostMessage({
            type : 'Notify',
            data : {
                message : message
            }
        })
    }
    SetOption({key, value}) {
        this.PostMessage({
            type : 'SetOption', 
            data : {
                key : key,
                value : value    
            }                    
        });
    }
    SetProjectOption({projectId, key, value}) {
        this.PostMessage({
            type : 'SetProjectOption', 
            data : {
                projectId : projectId,
                key : key,
                value : value    
            }                    
        });
    }
    SetProjectOptions({projectId, options}) {
        this.PostMessage({
            type : 'SetProjectOptions', 
            data : {
                projectId : projectId,
                options : options
            }                    
        });
    }
    Resize({width, height}) {
        this.PostMessage({
            type : 'ResizePlugin', 
            data : {
                width : width,
                height : height   
            }                    
        });
    }
    GenerateStaticPageOfState() {
        this.PostMessage({
            type : 'GenerateStaticPageOfState', 
            data : {
                name : Globals.ProjectManager.CurrentState
            }                    
        }); 
    }
    ConvertImageToArray(url) {
        return new Promise((resolve, reject) => {
            try {
                var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

                //create image, set src to base64 and onload draw to canvas
                var image = new Image();
                image.crossOrigin = "Anonymous";
                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    ctx.drawImage(image, 0, 0);
                    // var imageData = ctx.getImageData(0, 0, 34, 34);

                    canvas.toBlob(blob => {
                        const reader = new FileReader()
                        reader.onload = () => {
                            const imagedata = new Uint8Array(reader.result)

                            resolve(imagedata);
                        }
                        reader.onerror = () => reject(new Error('Could not read from blob'))
                        reader.readAsArrayBuffer(blob)
                      })

                    
                }
                image.src = url;
            } catch (error) {
                console.log(error);
            }
        })
    }
}

export const GetUniqueTextTokenStyle = (textToken) => {
    const tokenStyle = Globals.ProjectManager.Tokens.TypePatterns.GetFigmaStyle(textToken, true);   

    let letterSpacing = Utils.UseNullOrEmpty(tokenStyle.letterSpacing, 0);
    let letterSpacingUnit = 'px';
    if (tokenStyle.letterSpacingUnit === 'PERCENT') {
        letterSpacing = letterSpacing / 100;
        letterSpacingUnit = 'em';
    }

    return tokenStyle.fontSize + '_' + 
    Utils.JustGet(tokenStyle.fontName, 'family', 'family') + '_' + 
    Utils.JustGet(tokenStyle.fontName, 'style', 'style') + '_' + 
    Utils.UseNullOrEmpty(tokenStyle.lineHeight, tokenStyle.fontSize) + '_' + 
    letterSpacing + letterSpacingUnit + '_';
}

export const GetUniqueFigmaTextStyle = (figmaText) => {
    return figmaText.fontSize + '_' + 
    figmaText.fontFamily + '_' + 
    figmaText.fontWeight + '_' +     
    Utils.UseNullOrEmpty(figmaText.lineHeight, CalculateLineHeight({fontSize : figmaText.fontSize, lineHeightFactor : Globals.ProjectManager.Tokens.Typescale.Get().lineHeightFactor})) + '_' + 
    Utils.UseNullOrEmpty(figmaText.letterSpacing, 0) + Utils.UseNullOrEmpty(figmaText.letterSpacingUnit, 'px') + '_';
}