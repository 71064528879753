import React from 'react';

import {
    ReactBasicComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../../importer';

import { AnimatePresence, motion } from 'framer-motion';
import SelectToken from '../../../../views/project/panels/right/iteminspector/selectToken';
import { TokenTypes } from '../../../../views/project/manager/tokens';
import { SetFontTypeFace } from '../../../../views/project/panels/left/designsystem/typography';
import { FigmaInfoPanel } from '../../common';

import StyleTokenMapItem from './styleitem';
import { FigmaLogo } from '../..';
import Switch from '../../../../components/editors/Switch';
import { Seperator } from '../../../../views/project/panels/right/iteminspector/styleitems/common';
// views/project/panels/right/iteminspector/selectToken';

export default class LocalStyleTokenMappings extends ReactBasicComponent {
    constructor(props) {
        super(props);

        this.ContinueLoadProject = this.ContinueLoadProject.bind(this);        
    }
    componentDidMount() {
        
    }
    componentDidUpdate(prevProps, prevState) {
        
    }
    ContinueLoadProject() {
        Utils.Set(this.props.GetPluginApi().FigmaProjectsIds, true, this.props.projectId);
                
        this.props.GetPluginApi().LoadProject(this.props.projectId, ({options, tokenMap, bindings, localStyles, selectionCount}) => {

            this.props.GetPluginApi().SelectedNodeCount = selectionCount;
            this.props.GetPluginApi().FigmaTokenMap = tokenMap;
            this.props.GetPluginApi().FigmaStyleTokens = bindings;

            if (localStyles && bindings) {                        
                this.props.GetPluginApi().ComputeTokenSyncs(bindings, localStyles, Utils.JustGet(options, 'Default', 'StateLabel'), false);               
            }
            this.props.GetPluginApi().MarkProjectLoaded(this.props.projectId);
            
            this.props.onClose();
        });          
    }
    UpdateAllTokensFromFigma(type, e, doNotClose) {        
        let items;
        const ChangedTokens = this.props.GetPluginApi().InitialTokenSync;
        if (type === 'all') {
            this.UpdateAllTokensFromFigma(TokenTypes.COLOR, e, true);
            this.UpdateAllTokensFromFigma(TokenTypes.Gradients, e, true);
            this.UpdateAllTokensFromFigma(TokenTypes.Shadows, e, true);
            this.UpdateAllTokensFromFigma(TokenTypes.TextPatterns, e, true);
            this.UpdateAllTokensFromFigma('Names', e, true);

            this.props.GetPluginApi().GetFigmaStyleTokenMap(({tokenMap}) => {
                // console.log(`CB - GET : StateTokenMap `);
                this.props.GetPluginApi().UpdateFigmaStyles(tokenMap, () => {
                    this.props.onClose();
                });                
            });
            return;
        }
        if (type === TokenTypes.COLOR)
            items = ChangedTokens.colors;
        else if (type === TokenTypes.Gradients)
            items = ChangedTokens.gradients;
        else if (type === TokenTypes.Shadows)
            items = ChangedTokens.shadows;
        else if (type === TokenTypes.TextPatterns)
            items = ChangedTokens.texts;
        else if (type === 'Names')
            items = ChangedTokens.tokenNames;

        if (items) {
            items.map((item) => {
                this.UpdateTokenFromFigma(type, item, -1);
            })

            ChangedTokens.total -= items.length;

            if (type === TokenTypes.COLOR)
                delete ChangedTokens.colors;
            else if (type === TokenTypes.Gradients)
                delete ChangedTokens.gradients;
            else if (type === TokenTypes.Shadows)
                delete ChangedTokens.shadows;
            else if (type === TokenTypes.TextPatterns)
                delete ChangedTokens.texts;
            else if (type === 'Names')
                delete ChangedTokens.tokenNames;
        }

        if (!doNotClose && ChangedTokens.total === 0) {
            this.props.onClose();
            return;
        }

        this.RCUpdate();
    }
    UpdateTokenFromFigma(type, item, index) {
        const ChangedTokens = this.props.GetPluginApi().InitialTokenSync;
        if (type === TokenTypes.COLOR) {
            const result = {
                items : []
            }
            Globals.ProjectManager.Tokens.Colors.SetValue({
                id : item.token.id,
                value : item.figma.color,
                type : TokenTypes.COLOR,
                result : result
            });

            Utils.Set(Globals.ProjectManager.CurrentTheme.Theme, item.figma.color, Globals.ProjectManager.CurrentState, item.token.id);

            if (result.aliases) {
                const data = {};
                result.aliases.map((aliaseId) => {
                    const tokenStyleBinding = Utils.JustGet(this.props.GetPluginApi().FigmaTokenMap, null, aliaseId);
                    if (tokenStyleBinding) {
                        if (tokenStyleBinding.styles && tokenStyleBinding.styles.length > 0) {                        
                            Utils.Set(data, tokenStyleBinding.styles, aliaseId, 'styles');
                        }                    
                    }                    
                });
                if (Utils.Keys(data).length > 0) {
                    this.props.GetPluginApi().UpdateFigmaStyles(data);
                }
            }
            
            if (index > -1) {
                ChangedTokens.colors.splice(index, 1);
                ChangedTokens.total -= 1;
            }            
        }
        else if (type === TokenTypes.Gradients) {
            const tokenGradient = Globals.ProjectManager.Tokens.ValueOfId(item.token.id);
            Utils.Set(tokenGradient, item.figma.stops, 'gradient', 'colors');
            Globals.ProjectManager.Tokens.SetValue({
                id : item.token.id,
                value : tokenGradient,
                type : TokenTypes.Gradients
            });

            if (index > -1) {
                ChangedTokens.gradients.splice(index, 1);
                ChangedTokens.total -= 1;
            }            
        }
        else if (type === TokenTypes.Shadows) {            
            Globals.ProjectManager.Tokens.SetValue({
                id : item.token.id,
                value : {
                    values : item.figma.shadows
                },
                type : TokenTypes.Shadows
            });

            if (index > -1) {
                ChangedTokens.shadows.splice(index, 1);
                ChangedTokens.total -= 1;
            }            
        }
        else if (type === TokenTypes.TextPatterns) {            
            Utils.ForEach(item.figma.textStyle, (textValue, valueName) => {    
                Globals.ProjectManager.Tokens.SetValue({
                    id : item.token.id,
                    name : valueName,
                    value : textValue,
                    type : TokenTypes.TextPatterns,
                    DoNotBroadcast : true
                });
            });

            if (index > -1) {
                ChangedTokens.texts.splice(index, 1);
                ChangedTokens.total -= 1;
            }            
        }
        else if (type === 'Names') {
            Globals.ProjectManager.Tokens.UpdateProp({id : item.tokenId, name : 'name', value : item.figmaName});
            if (index > -1) {
                ChangedTokens.tokenNames.splice(index, 1);
                ChangedTokens.total -= 1;
            }   
        }
        if (ChangedTokens.total === 0) {
            this.props.onClose();
            return;
        }
        this.RCUpdate();
    }
    UpdateFigmaFromToken(type, item, index) {
        const ChangedTokens = this.props.GetPluginApi().InitialTokenSync;
        if (type === 'Names') {            
            this.props.GetPluginApi().UpdateTokenNames([{
                id : item.tokenId,
                name : item.name
            }]);
        }
        else
            this.props.GetPluginApi().UpdateFigmaStyle(item.figma.id, item.token.id);     
        if (index > -1) {
            if (type === TokenTypes.COLOR)
                ChangedTokens.colors.splice(index, 1);
            else if (type === TokenTypes.Gradients)
                ChangedTokens.gradients.splice(index, 1);
            else if (type === TokenTypes.Shadows)
                ChangedTokens.shadows.splice(index, 1);
            else if (type === TokenTypes.TextPatterns)
                ChangedTokens.texts.splice(index, 1);
            else if (type === 'Names') 
                ChangedTokens.tokenNames.splice(index, 1);

            ChangedTokens.total -= 1;

            this.RCUpdate();
        }  
        if (ChangedTokens.total === 0) {
            this.props.onClose();
            return;
        }
    }    
    UpdateAllFigmaStyles(type, e, doNotClose) {
        if (type === 'all') {
            this.UpdateAllFigmaStyles(TokenTypes.COLOR, e, true);
            this.UpdateAllFigmaStyles(TokenTypes.Gradients, e, true);
            this.UpdateAllFigmaStyles(TokenTypes.Shadows, e, true);
            this.UpdateAllFigmaStyles(TokenTypes.TextPatterns, e, true);
            this.UpdateAllFigmaStyles('Names', e, true);

            this.props.GetPluginApi().GetFigmaStyleTokenMap(({tokenMap}) => {
                // console.log(`CB - GET : StateTokenMap `);
                this.props.GetPluginApi().UpdateFigmaStyles(tokenMap, () => {
                    this.props.onClose();
                });                
            });
            return;
        }
        let items;
        const ChangedTokens = this.props.GetPluginApi().InitialTokenSync;        
        if (type === TokenTypes.COLOR)
            items = ChangedTokens.colors;
        else if (type === TokenTypes.Gradients)
            items = ChangedTokens.gradients;
        else if (type === TokenTypes.Shadows)
            items = ChangedTokens.shadows;
        else if (type === TokenTypes.TextPatterns)
            items = ChangedTokens.texts;
        else if (type === 'Names') {
            const names = [];
            ChangedTokens.tokenNames.map((tokenName) => {
                names.push({id : tokenName.tokenId, name : tokenName.name});
            })
            if (names.length > 0) {
                this.props.GetPluginApi().UpdateTokenNames(names);
            }            
            ChangedTokens.total -= ChangedTokens.tokenNames.length;

            delete ChangedTokens.tokenNames;

            if (ChangedTokens.total === 0) {
                this.props.onClose();
                return;
            }
    
            this.RCUpdate();

            return;
        }            

        if (items) {
            items.map((item) => {
                this.UpdateFigmaFromToken(type, item, -1);
            })

            ChangedTokens.total -= items.length;
            
            if (type === TokenTypes.COLOR)
                delete ChangedTokens.colors;
            else if (type === TokenTypes.Gradients)
                delete ChangedTokens.gradients;
            else if (type === TokenTypes.Shadows)
                delete ChangedTokens.shadows;
            else if (type === TokenTypes.TextPatterns)
                delete ChangedTokens.texts;
        }

        if (!doNotClose && ChangedTokens.total === 0) {
            this.props.onClose();
            return;
        }

        this.RCUpdate();
    }

    renderCustom() {
        let colors, gradients, shadows, texts, tokenNames;

        if (this.props.needsMergeFirstUse) {
            return (
                <SC.FCol style={{
                    padding : '10px',
                    fontSize : '11px',
                    paddingTop : '16px',
                    paddingBottom : '16px',                
                }}>
                    <div style={{alignSelf : 'center', fontWeight : 500, fontSize : '14px', lineHeight : '24px', marginBottom : '16px', marginTop : '8px', textAlign : 'center'}}>
                        <div>
                            This Figma file is not linked to this Design System!
                        </div>
                        <div style={{marginTop : '16px'}}>
                            If you continue, local styles will be imported as design tokens.
                        </div>                        
                    </div>                    
                    <SC.Buttons.RoundButton small style={{marginTop : '8px', width : '100%', maxWidth : '240px', alignSelf : 'center', marginBottom : '8px'}} onClick={this.props.onCancel}>
                        Back to Project List
                    </SC.Buttons.RoundButton>
                    <SC.Buttons.RoundButton small style={{width : '100%', maxWidth : '240px', alignSelf : 'center', marginTop : '16px', marginBottom : '8px'}} onClick={this.ContinueLoadProject}>
                        Continue
                    </SC.Buttons.RoundButton>  
                </SC.FCol>
            )
        }

        const ChangedTokens = this.props.GetPluginApi().InitialTokenSync;
        if (ChangedTokens.colors && ChangedTokens.colors.length > 0) {
            const items = [];
          
            ChangedTokens.colors.map((colorChange, i) => {
                items.push((
                    <StyleTokenMapItem 
                        type={TokenTypes.COLOR}
                        key={colorChange.token.id}
                        item={colorChange}
                        onUpdateToken={this.UpdateTokenFromFigma.bind(this, TokenTypes.COLOR, colorChange, i)}
                        onUpdateFigma={this.UpdateFigmaFromToken.bind(this, TokenTypes.COLOR, colorChange, i)}
                    />
                ));
            })

            colors = (
                <React.Fragment>
                    <StyleTokenMapItem 
                        header
                        type={TokenTypes.COLOR}
                        key='color'
                        onUpdateToken={this.UpdateAllTokensFromFigma.bind(this, TokenTypes.COLOR)}
                        onUpdateFigma={this.UpdateAllFigmaStyles.bind(this, TokenTypes.COLOR)}                        
                        style={{paddingTop : 0}}
                    />
                    <SC.FCol style={{
                        backgroundColor : SC.CurrentTheme.theme.back_lighter,
                        borderRadius : '4px'
                    }}>
                        {items}
                    </SC.FCol>
                </React.Fragment>
            )
        }

        if (ChangedTokens.gradients && ChangedTokens.gradients.length > 0) {
            const items = [];
          
            ChangedTokens.gradients.map((colorChange, i) => {
                items.push((
                    <StyleTokenMapItem 
                        type={TokenTypes.Gradients}
                        key={colorChange.token.id}
                        item={colorChange}
                        onUpdateToken={this.UpdateTokenFromFigma.bind(this, TokenTypes.Gradients, colorChange, i)}
                        onUpdateFigma={this.UpdateFigmaFromToken.bind(this, TokenTypes.Gradients, colorChange, i)}
                    />
                ));
            })

            gradients = (
                <React.Fragment>
                    <StyleTokenMapItem 
                        header
                        type={TokenTypes.Gradients}
                        key='gradient'
                        onUpdateToken={this.UpdateAllTokensFromFigma.bind(this, 'gradients')}
                        onUpdateFigma={this.UpdateAllFigmaStyles.bind(this, 'gradients')}                        
                        style={{paddingTop : 0}}
                    />
                    <SC.FCol style={{
                        backgroundColor : SC.CurrentTheme.theme.back_lighter,
                        borderRadius : '4px'
                    }}>
                        {items}
                    </SC.FCol>
                </React.Fragment>
            )
        }

        if (ChangedTokens.shadows && ChangedTokens.shadows.length > 0) {
            const items = [];
          
            ChangedTokens.shadows.map((shadowChange, i) => {
                items.push((
                    <StyleTokenMapItem 
                        type={TokenTypes.Shadows}
                        key={shadowChange.token.id}
                        item={shadowChange}
                        onUpdateToken={this.UpdateTokenFromFigma.bind(this, TokenTypes.Shadows, shadowChange, i)}
                        onUpdateFigma={this.UpdateFigmaFromToken.bind(this, TokenTypes.Shadows, shadowChange, i)}
                    />
                ));
            })

            shadows = (
                <React.Fragment>
                    <StyleTokenMapItem 
                        header
                        type={TokenTypes.Shadows}
                        key='shadows'
                        onUpdateToken={this.UpdateAllTokensFromFigma.bind(this, TokenTypes.Shadows)}
                        onUpdateFigma={this.UpdateAllFigmaStyles.bind(this, TokenTypes.Shadows)}                        
                        style={{paddingTop : 0}}
                    />
                    <SC.FCol style={{
                        backgroundColor : SC.CurrentTheme.theme.back_lighter,
                        borderRadius : '4px'
                    }}>
                        {items}
                    </SC.FCol>
                </React.Fragment>
            )
        }
        if (ChangedTokens.texts && ChangedTokens.texts.length > 0) {
            const items = [];
          
            ChangedTokens.texts.map((textChange, i) => {
                items.push((
                    <StyleTokenMapItem 
                        type={TokenTypes.TextPatterns}
                        key={textChange.token.id}
                        item={textChange}
                        onUpdateToken={this.UpdateTokenFromFigma.bind(this, TokenTypes.TextPatterns, textChange, i)}
                        onUpdateFigma={this.UpdateFigmaFromToken.bind(this, TokenTypes.TextPatterns, textChange, i)}
                    />
                ));
            })

            texts = (
                <React.Fragment>
                    <StyleTokenMapItem 
                        header
                        type={TokenTypes.TextPatterns}
                        key='texts'
                        onUpdateToken={this.UpdateAllTokensFromFigma.bind(this, TokenTypes.TextPatterns)}
                        onUpdateFigma={this.UpdateAllFigmaStyles.bind(this, TokenTypes.TextPatterns)}                        
                        style={{paddingTop : 0}}
                    />
                    <SC.FCol style={{
                        backgroundColor : SC.CurrentTheme.theme.back_lighter,
                        borderRadius : '4px'
                    }}>
                        {items}
                    </SC.FCol>
                </React.Fragment>
            )
        }

        if (ChangedTokens.tokenNames && ChangedTokens.tokenNames.length > 0) {
            const items = [];
          
            ChangedTokens.tokenNames.map((nameChange, i) => {
                items.push((
                    <SC.FRow jsb alc key={i} style={{                        
                        marginBottom : '4px',
                        backgroundColor : SC.CurrentTheme.theme.back_lighter,
                        borderRadius : '4px',
                    }}>
                        <SC.Icons.Icon_ButtonBox dark={!this.props.header} hasFill hasCursor onClick={this.UpdateFigmaFromToken.bind(this, 'Names', nameChange, i)}
                            style={{padding : '4px', marginRight : '4px', flex : 1, justifyContent : 'space-between'}}                 
                        >
                            <div>{nameChange.name}</div>
                            <SC.Icons.Arrow_Back expanded style={{paddingBottom : 0}}/>
                        </SC.Icons.Icon_ButtonBox>
                        <SC.Icons.Icon_ButtonBox dark={!this.props.header} hasFill hasCursor onClick={this.UpdateTokenFromFigma.bind(this, 'Names', nameChange, i)}
                            style={{padding : '4px', marginLeft : '4px', textAlign : 'right', flex : 1, justifyContent : 'space-between'}}
                        >
                            <SC.Icons.Arrow_Back  style={{paddingBottom : 0}}/>
                            <div>{nameChange.figmaName}</div>                            
                        </SC.Icons.Icon_ButtonBox>
                    </SC.FRow>
                ));                
            })
            tokenNames = (
                <React.Fragment>
                    <StyleTokenMapItem 
                        header
                        type='Names'
                        key='names'
                        onUpdateToken={this.UpdateAllTokensFromFigma.bind(this, 'Names')}
                        onUpdateFigma={this.UpdateAllFigmaStyles.bind(this, 'Names')}                        
                        style={{paddingTop : 0}}
                    />
                    <SC.FCol style={{                        
                    }}>
                        {items}
                    </SC.FCol>
                </React.Fragment>
            )
        }

        return (
            <SC.FCol style={{
                padding : '10px',
                fontSize : '11px',
                paddingTop : '8px',
                paddingBottom : '16px',                
            }}>     
                <div style={{alignSelf : 'center', fontWeight : 500, fontSize : '12px', marginBottom : '16px', marginTop : '8px'}}>SYNC PENDING CHANGES</div>                 
                {
                    !this.props.GetOptions().pendingChangeInfo && 
                    <FigmaInfoPanel onClose={() => {
                        this.props.GetOptions().SetOption('pendingChangeInfo', true);
                        this.RCUpdate();
                    }}>
                        <div style={{marginBottom : '8px', textAlign : 'center'}}>Following DSM Tokens & Figma Styles have changed while offline.</div>                    
                        <div style={{textAlign : 'center'}}>You need to merge the changes to continue!</div>
                    </FigmaInfoPanel>
                }
                {
                    ChangedTokens && ChangedTokens.total > 1 &&
                    <React.Fragment>
                        <SC.Buttons.RoundButton xsmall style={{marginTop : '8px', width : '100%', maxWidth : '240px', alignSelf : 'center', marginBottom : '8px'}} onClick={this.UpdateAllTokensFromFigma.bind(this, 'all')}>
                            <div>Uppdate All Tokens </div>
                            <SC.Icons.Arrow_Back />
                            <FigmaLogo />   
                            
                        </SC.Buttons.RoundButton>
                        <SC.Buttons.RoundButton xsmall style={{width : '100%', maxWidth : '240px', alignSelf : 'center', marginBottom : '8px'}} onClick={this.UpdateAllFigmaStyles.bind(this, 'all')}>
                            <div>Uppdate All Figma Styles</div>
                            <SC.Icons.Arrow_Back expanded style={{paddingBottom : 0}}/>
                            <FigmaLogo />   
                        </SC.Buttons.RoundButton>
                    </React.Fragment>
                }
                {colors}
                {gradients}
                {shadows}
                {texts}
                {tokenNames}
            </SC.FCol>
        )
    }    
}
 
