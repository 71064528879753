import React from 'react';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals,
    Strings
} from '../../../importer';
import styled from 'styled-components';
import { TokenItemBox, TokenGroup, TokenPanelHeader, GroupTitle } from '../../../views/project/panels/left/designsystem/common';

import SystemStatePanel from '../../../views/project/panels/left/systemstates';
import Colors from '../../../views/project/panels/left/designsystem/colors';
import Typography from '../../../views/project/panels/left/designsystem/typography';
import FontStyleEditor from '../../../views/project/panels/left/designsystem/typography/fonts/item';
import Shadows from '../../../views/project/panels/left/designsystem/shadows';
import Spacings from '../../../views/project/panels/left/designsystem/spacing';
import Borders from '../../../views/project/panels/left/designsystem/borders';
import MotionSystem from '../../../views/project/panels/left/designsystem/motion';
import Transforms from '../../../views/project/panels/left/designsystem/transforms';
import Filters from '../../../views/project/panels/left/designsystem/filters';
import Images from '../../../views/project/panels/left/designsystem/images';
import Iconography from '../../../views/project/panels/left/designsystem/icons';
import Sounds from '../../../views/project/panels/left/designsystem/sounds';
import TextContents from '../../../views/project/panels/left/designsystem/textContent';
import DefaultStyles from '../../../views/project/panels/left/designsystem/defultstyles';
import TokenAliases from '../../../views/project/panels/left/designsystem/aliases';

import FigmaDSToolbar from './toolbar';
import { LeftScrollPanel, OutsideCloseDotMenuContainer } from '../../../views/project/panels/left/common';
import { motion, AnimatePresence  } from 'framer-motion';

import DropDownSelect from '../../../components/editors/enum_dropdown';
import Switch from '../../../components/editors/Switch';
import { SearchInput } from '../../../views/project/searchbar';


export default class DemoDesignSystem extends ReactBaseComponent {
    constructor(props) {
        super(props);
        
        this.state.GlobalStateId = Utils.Id();
        this.state.GlobalThemeId = Utils.Id();
        this.state.autoCheckChanges = true;

        this.GlobalFigmaState = this.state.GlobalStateId;

        this.onChangeTokenType = this.onChangeTokenType.bind(this);
        this.renderTokenHeader = this.renderTokenHeader.bind(this);
        this.renderDropdownItem = this.renderDropdownItem.bind(this);

        this.onPanelOverlay = this.onPanelOverlay.bind(this);        

        this.Ref_Scroll = React.createRef();
        this.RefToolbar = React.createRef();        

        this.TokenType = 'all';        

        super.ListenEvents(
            Events.GLOBAL.STATE_CHANGED,
            Events.GLOBAL.TOKENS_CHANGED,
            Events.GLOBAL.TOKEN_VALUE_CHANGING,
            Events.DESIGNER.HISTORY.LIST,
            Events.DESIGNER.COMPONENT.STATE.MANAGER,
            Events.DESIGNER.COMPONENT.HISTORY.CHANGED
            
        );
                
    }   

    
    
    OnEvent(Event, Params) {
        
        if (Event === Events.GLOBAL.TOKEN_VALUE_CHANGING) {
            const [Tokens] = Params;

            Globals.ProjectManager.CurrentTheme.ChangingTokens = Tokens;

            const data = {};

            Utils.ForEach(Tokens, ({value, Id, Type}, i) => {
                if (Type === Globals.ProjectManager.Tokens.Types.COLOR) {
                    Utils.Set(Globals.ProjectManager.CurrentTheme.Theme, value, Globals.ProjectManager.CurrentState, Id);
                    data[Id] = value;
                }
            });

            const Id = Utils.Id();

            window.parent.postMessage({
                toolabsMessage : {
                    type : 'TokenChange',
                    tokens : true,
                    data : {
                        themeId : Id,
                        tokens : data
                    }
                }
            }, '*');

            if (Globals.ProjectManager && Globals.ProjectManager.CurrentTheme) {
                Globals.ProjectManager.CurrentTheme.ThemeId = Id;
            }
            
            this.setState({
                GlobalStateId : Id,
                GlobalThemeId : Id
            });
        }
        else if (Event === Events.GLOBAL.STATE_CHANGED) {
            const Id = Utils.Id();
            // console.log(`GlobalStateId : ` + Id);
            this.setState({
                GlobalStateId : Id,
                GlobalThemeId : Id,
                ThemeStateId : Id
            });     
            
            this.props.onUpdateTheme && this.props.onUpdateTheme();
        }
        else if (Event === Events.GLOBAL.TOKENS_CHANGED || Event === Events.DESIGNER.COMPONENT.HISTORY.CHANGED) {
            Globals.ProjectManager.UpdateTokenValues();    
            const Id = Utils.Id();             
            this.setState({GlobalStateId : Id});

            this.props.onUpdateTheme && this.props.onUpdateTheme();

            return;
        }
        this.RCUpdate();
    }
    componentDidMount() {
        super.componentDidMount();
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }
       
    componentDidUpdate(prevProps, prevState) {
        if (prevState.ThemeStateId !== this.state.ThemeStateId) {            
        }
    }    
    onChangeTokenType(type) {
        this.TokenType = type;
        // this.Ref_Scroll.current && this.Ref_Scroll.current.scrollTop(0);
        Globals.ProjectManager.Options.Set(type, 'LeftPanel', 'DesignSystem', 'TokenType');
        this.RCUpdate();
    }
    renderTokenHeader(label) {
        return (
            <GroupTitle title={label} style={{textTransform : 'uppercase', padding : 0}} />
        )
    }
    renderDropdownItem(item) {        
        return (
            <GroupTitle title={item.label} style={{textTransform : 'uppercase', padding : 0, fontSize : '11px'}} />
        )
    }
    onPanelOverlay(options) {
        if (options.close) {
            delete this.HasPanelOverlay;
            this.RCUpdate();
            return;
        }
        this.HasPanelOverlay = options;
        this.RCUpdate();
    }
    renderCustom() {         

        const props = {
            GlobalState : Globals.ProjectManager.States.CurrentStateVariation,
            GlobalStateId : this.state.GlobalStateId,
            GlobalThemeId : this.state.GlobalThemeId,
            themeId : this.props.themeId,
            onPanelOverlay : this.onPanelOverlay,
            onShowConsumers : this.onShowConsumers,
            onUpdate : this.RCUpdate,
            RefToolbar : this.RefToolbar,
            filterText : this.state.filterText,
            showSearchBar : this.state.showSearchBar
        }

        const header = (
            <SC.FRow style={{
                padding : '10px',
                position : 'relative'
            }}>
                {
                    this.state.showSearchBar ? 
                    <SearchInput 
                        small full style={{minHeight : '32px'}}
                        hasFullClose
                        autoFocus
                        SearchHint='Search tokens...'
                        FilterText={this.state.filterText}
                        CancelSearch={() => {
                            this.setState({showSearchBar : false, filterText : ''})
                        }}
                        Search={(value) => {
                            this.setState({filterText : value})
                        }}
                    /> : 
                    <React.Fragment>
                        <DropDownSelect 
                            items={[
                                {id : 'all', label : 'All Tokens'},
                                {id : 'aliases', label : 'Semantic Token Aliases'},                        
                                {id : Globals.ProjectManager.Tokens.Types.COLOR, label : 'Colors'},
                                {id : Globals.ProjectManager.Tokens.Types.Shadows, label : 'Shadows'},
                                {id : Globals.ProjectManager.Tokens.Types.Fonts, label : 'Typography'},                            
                                {id : Globals.ProjectManager.Tokens.Types.Spacings, label : 'Spacing'},
                                {id : Globals.ProjectManager.Tokens.Types.Borders, label : 'Borders & Radii'},                                
                                {id : Globals.ProjectManager.Tokens.Types.Icons, label : 'Iconography'},
                                {id : Globals.ProjectManager.Tokens.Types.Images, label : 'Images'},
                                {id : Globals.ProjectManager.Tokens.Types.Transitions, label : 'Motion'},
                                {id : Globals.ProjectManager.Tokens.Types.Transforms, label : 'Transforms'},
                                {id : Globals.ProjectManager.Tokens.Types.Filters, label : 'Filters'},
                                {id : Globals.ProjectManager.Tokens.Types.Sounds, label : 'Sounds'},
                                {id : Globals.ProjectManager.Tokens.Types.ContentTexts, label : 'Text Contents'},
                            ]}
                            fullwidth
                            popupStyle={{minWidth : 'unset', left : '8px', right : '8px', marginTop : '-4px'}}
                            autoHeight
                            largeText
                            popupItemStyle={{fontWeight : 500, padding : '4px', paddingLeft : '8px'}}
                            value={this.TokenType}
                            onChange={this.onChangeTokenType}
                            boxStyle={{paddingLeft : '8px', paddingRight : '4px', borderRadius : '4px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                            style={{minHeight : '32px', width : 'unset',  flex : 1}}
                            renderLabelItem={this.renderTokenHeader}
                            renderItem={this.renderDropdownItem}
                        /> 
                        <SC.Icons.Icon_ButtonBox hasFill onClick={() => this.setState({showSearchBar : !this.state.showSearchBar})} style={{cursor : 'pointer', paddingLeft : '2px', paddingRight : '4px', marginLeft : '8px'}} title={'Toggle Search Box'} >
                            <SC.Icons.Icon_Search size={14} />
                        </SC.Icons.Icon_ButtonBox>
                    </React.Fragment>
                }
                
            </SC.FRow>
        )

        let panelOverlay;

        if (this.HasPanelOverlay) {
            if (this.HasPanelOverlay.render)
                panelOverlay = this.HasPanelOverlay.render({...this.props, ...props});
            else {        
                if (this.HasPanelOverlay.type === 'EditFont') {
                    panelOverlay = (
                        <SC.FCol fw fh>
                            <TokenPanelHeader title='EDIT TYPEFACE' hasAddCancel onClose={this.HasPanelOverlay.onClose} onCancel={this.HasPanelOverlay.onCancel} onAdd={this.HasPanelOverlay.onAdd} onDelete={this.HasPanelOverlay.onDelete} />
                            <LeftScrollPanel>
                                <FontStyleEditor 
                                    id={this.HasPanelOverlay.id}
                                    onSelectFont={this.HasPanelOverlay.onSelectFont}
                                    isDefaultFont={this.HasPanelOverlay.isDefaultFont}
                                    GlobalStateId={this.state.GlobalStateId}
                                    onClose={this.HasPanelOverlay.onClose}
                                />
                            </LeftScrollPanel>                        
                        </SC.FCol>
                    )
                }
            }
        }
        props.singleView = this.TokenType !== 'all' || this.TokenType === 'DefaultStyles';
        props.noScrolls = true;

        let commonHeader;
        if (props.singleView) {
            // props.children = header;
        }
        else {
            commonHeader = header;
        }

        commonHeader = header;

        const isTokenEditMode = this.props.tokenMode === 'edit';
       
        let content;
        
        content = (
            <SC.FCol f1         
                style={{
                    backgroundColor : SC.CurrentTheme.theme.back,
                    paddingTop : props.singleView ? 0 : '4px',
                    // height : '100%',
                    boxSizing : 'border-box'
                }}       
            >      
                {
                    this.TokenType === 'aliases' &&
                    <TokenAliases 
                        {...props} 
                        noScroll
                        width={this.props.width} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.COLOR) && 
                    <Colors 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Shadows) && 
                    <Shadows 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Fonts) && 
                    <Typography 
                        {...props} 
                        selectFontsInPanel 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Spacings) && 
                    <Spacings  {...props} />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Borders) && 
                    <Borders 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Icons) && 
                    <Iconography 
                        {...props}                         
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Images) && 
                    <Images {...props} />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transitions) && 
                    <MotionSystem {...props} />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Transforms) && 
                    <Transforms 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Filters) && 
                    <Filters 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.Sounds) && 
                    <Sounds 
                        {...props} 
                    />
                }
                {
                    (!props.singleView || this.TokenType === Globals.ProjectManager.Tokens.Types.ContentTexts) && 
                    <TextContents last {...props} 
                    />
                }
            </SC.FCol>
        )
        
        return (  
            <SC.FCol f1 style={{
                
            }}>    
                <SC.FCol
                    style={{
                        paddingLeft : '2px',
                        paddingRight : '2px',
                        paddingTop : '8px'
                    }}
                >                    
                    <SystemStatePanel 
                        ref={this.props.Ref_SystesPanel}
                        justContent
                        noBreakpointsEdit={false}
                        themeId={this.props.themeId} 
                        contentStyle={{
                            marginBottom : 0
                        }}
                        style={{
                            borderBottom : 'none'
                        }}
                    />                 
                </SC.FCol>                         
                {commonHeader}
                <LeftScrollPanel ScrollRef={this.Ref_Scroll} big>                                      
                    {content}   
                </LeftScrollPanel>
                <AnimatePresence>
                    {
                        (this.HasPanelOverlay || this.state.usageOfTokenId) && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 99, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                            initial={{opacity : 0.7, x : -24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : -24}}
                            transition={{duration : 0.2}}
                        >
                            {panelOverlay}
                        </motion.div>
                    }
                </AnimatePresence>
                <FigmaDSToolbar 
                    ref={this.RefToolbar} 
                />                            
            </SC.FCol>
        );
    }
}
