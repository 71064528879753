import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    MetaData,
    Globals
} from '../../../importer';
import styled, {css} from 'styled-components';
import {Packager} from '../../../toolabs-importer';
import CodeViewer, { CodeCopyBox } from '../common/codeviewer';
import { StepNumber, Block_CodeInfo, Block_PlatformItem, Block_DownloadButton } from './styles';
import RadioGroup from '../../../components/editors/radiogroup';
import { InfoPanel, InfoLine } from '../../../components/info';

export const LoadZipper = (withUtils) => {
    return new Promise((resolve) => {
        const p = [import('jszip'), import('file-saver')];
        if (withUtils)
            p.push(import('jszip-utils'));
        Promise.all(p).then((r) => {            
            const result = {
                jsZip : r[0].default,
                FileSaver : r[1].default
            };
            if (withUtils)
                result.jsZipUtils = r[2].default;
            resolve(result);
        })          
    });
}


export default class ExportToCodeSteps extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        this.Download = this.Download.bind(this);
        this.SetMetaDataMode = this.SetMetaDataMode.bind(this);
    }    
    Download() {
        this.RCSetStateValue('Publishing', true)
        
        Globals.ProjectManager.Publisher.BoardPackage(Globals.ProjectManager.Id).then((result) => {
            this.RCSetStateValue('Publishing', false);
            if (result) {
                let package_files;
                if (this.props.platform === MetaData.Platforms.React.Name || this.props.platform === MetaData.Platforms.PReact.Name)
                    package_files = Packager.React(result.data_board, result.data_components);
                const package_name = `toolabs-${Utils.ToKebabCase(Globals.ProjectManager.GetName())}`;
                if (package_files) {
                    LoadZipper().then(({jsZip, FileSaver}) => {
                        var zip = new jsZip;
                        const zip_root = zip.folder(package_name);
                        zip_root.file(package_files.index_js.name, package_files.index_js.content);
                        zip_root.file(package_files.style_board_js.name, package_files.style_board_js.content);
                        const folder_components = zip_root.folder('components');
                        Utils.ForEach(package_files.components,(compfile, i) => {
                            folder_components.file(compfile.name, compfile.content);
                        });

                        zip.generateAsync({type:"blob"})
                        .then((content) => {
                            // see FileSaver.js
                            FileSaver.saveAs(content, `${package_name}.zip`);
                            this.RCSetStateValue('Publishing', false);
                        });
                    })                    
                }
            }
        });
    }
    SetMetaDataMode() {
        this.LiveMetaDataByWrappers = !this.LiveMetaDataByWrappers;
        if (this.LiveMetaDataByWrappers || this.props.embedMetaData) {
            this.props.onShowComponentSelector();
        }
        this.RCUpdate();
    }    
    GetComponentMetaData(Id) {
        return Globals.ProjectManager.Publisher.Component(Id);
    }

    renderCustom() {
        const {embedMetaData, directScript} = this.props;        
        let item_engine = null;

        let url, fileName, engineName;
        if (this.props.platform === MetaData.Platforms.React.Name) {
            url = `<script src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1621074/engine-react.min.js" />`;
            fileName = 'React.js';
            engineName = 'react';
        }
        else if (this.props.platform === MetaData.Platforms.PReact.Name) {
            url = `<script src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1621074/engine-preact.min.js" />`;
            fileName = 'pReact.js';
            engineName = 'preact';
        }
        else if (this.props.platform === MetaData.Platforms.VueJs.Name) {
            url = `<script src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1621074/engine-vuejs.min.js" />`;
            fileName = 'vuejs.js';
            engineName = 'vuejs';
        }

        if (directScript) {
            
            item_engine = (
                <SC.FCol>
                    <Block_CodeInfo>
                        <SC.FRow alc style={{zIndex : 100}}>
                            <StepNumber>
                                1
                            </StepNumber>                        
                                Include engine script file  for {fileName}
                        </SC.FRow>                    
                        <CodeCopyBox 
                            key={this.props.platform}
                            code={url}
                            style={{marginTop : '-12px'}}
                        />
                    </Block_CodeInfo>                    
                </SC.FCol> 
            );
        }
        else {
            item_engine = (
                <SC.FCol>
                    <Block_CodeInfo>
                        <SC.FRow alc style={{zIndex : 100}}>
                            <StepNumber>
                                1
                            </StepNumber>                        
                            Install render engine for {fileName}
                        </SC.FRow>                    
                        <CodeCopyBox 
                            key={this.props.platform}
                            code={`$ npm install @toolabs/engine-${engineName}`}
                            copyCode={`npm install @toolabs/engine-${engineName}`}
                            style={{marginTop : '-12px'}}
                        />
                    </Block_CodeInfo>                    
                </SC.FCol>                
            )
        }

        let item_metadata = null;
        if (embedMetaData) {
            item_metadata = (
                <SC.FCol mt={24}>
                    <Block_CodeInfo>
                        <Block_DownloadButton onClick={this.Download}>
                            <SC.FRow>
                                <StepNumber>
                                    2
                                </StepNumber> 
                                <SC.Div_Flex_Cell>
                                    {this.state.Publishing ? 'Generating package...' : 'Click Download Metadata Package'}
                                </SC.Div_Flex_Cell>
                            </SC.FRow>
                        </Block_DownloadButton>
                    </Block_CodeInfo>
                    <SC.FCol mt={24}>
                        <Block_CodeInfo>
                            <SC.FRow>
                                <StepNumber>
                                    3
                                </StepNumber> 
                                <SC.Div_Flex_Cell>
                                    Extract package files into source folder of your project
                                </SC.Div_Flex_Cell>
                            </SC.FRow>
                            <SC.FRow mt={8}>
                                <CodeViewer style={{fontSize:'12px', lineHeight:'16px'}}>
{
`your-app
├── node_modules
├── package.json
├── .gitignore
├── public
│   └── favicon.ico
│   └── index.html
│   └── manifest.json
└── src    
    └── App.js
    └── `    
}
<span style={{color : SC.CurrentTheme.theme.color_brand}}>{`<toolabs-${Utils.ToKebabCase(Globals.ProjectManager.GetName())}>`}</span>
{
`        
        └── index.js
        └── .
        └── .
`            
}
                               </CodeViewer>
                               <SC.FCol ml={12} f1>
                                <CodeViewer js style={{fontSize:'12px', lineHeight:'16px'}}>
<div style={{backgroundColor:'#303134', padding : '4px'}}>
{`import * as Toolabs from './toolabs-${Utils.ToKebabCase(Globals.ProjectManager.GetName())}';`}
</div>
{
`.
.
render() {
    return (
        <App>`}
<div style={{backgroundColor:'#303134', padding : '4px'}}>
{this.props.CodeDeclare}
</div>
{
`        </App>
    )
}
`}                                   
                                </CodeViewer>
                               </SC.FCol>
                            </SC.FRow>                 
                        </Block_CodeInfo>
                    </SC.FCol>
                    <SC.FCol mt={24}>
                        <Block_CodeInfo>
                            <SC.FRow style={{zIndex : 100}}>
                                <StepNumber>
                                    4
                                </StepNumber> 
                                <SC.FRow f1 alc>
                                    Get configured component code
                                </SC.FRow>
                            </SC.FRow>
                            <CodeCopyBox 
                                style={{marginTop : '-12px'}}
                                key={this.props.CodeDeclareId}
                                code=
{<div>{
`
render() {
  return (
    <App>`}
<div style={{backgroundColor:'#303134', padding : '4px'}}>
{this.props.CodeDeclare}
</div>
{
`    </App>
  )
}
`}</div>}
copyCode={this.props.CodeDeclare}
                                />
                        </Block_CodeInfo>
                    </SC.FCol>
                </SC.FCol>                
            )
        }
        else {
            item_metadata = (
                <SC.FCol mt={directScript ? 0 : 24}>
                    {
                        directScript ? null :    
                        <SC.FCol>
                            <Block_CodeInfo>
                                <SC.FRow style={{zIndex : 100}}>
                                    <StepNumber>
                                        2
                                    </StepNumber> 
                                    <SC.Div_Flex_Cell>
                                        Import Render Engine
                                    </SC.Div_Flex_Cell>
                                </SC.FRow>
                                <SC.FRow>                                
                                    <SC.FCol f1>
                                    <CodeCopyBox 
                                        key={this.props.platform}
                                        code={`import LiveComponent from '@toolabs/engine-${engineName}';`}
                                        style={{marginTop : '-12px'}}
                                    />
                                </SC.FCol>
                                </SC.FRow>                            
                            </Block_CodeInfo>
                        </SC.FCol>
                    }                    
                    <SC.FCol mt={24}>
                        <Block_CodeInfo>
                            <SC.FRow alc style={{zIndex : 100}}>
                                <StepNumber>
                                    {directScript ? 2 : 3}
                                </StepNumber> 
                                <SC.FRow f1 alc>
                                    <SC.Div_Styled>
                                        Use Components By
                                    </SC.Div_Styled>  
                                    <SC.InnerPanel style={{padding : '4px', marginLeft : '8px'}}>
                                        <RadioGroup
                                            vertical
                                            small
                                            itemStyle={{border : 'none', margin : 0, flex : 'unset', fontSize : '14px'}}
                                            Container={{Type : SC.FRow, Props : {alc : true, style : {}} }}
                                            items={[
                                                {id : 'id', label : 'Component Id'},
                                                {id : 'name', label : 'Named Wrappers'}
                                            ]}
                                            value={this.LiveMetaDataByWrappers ? 'name' : 'id'}
                                            onSelect={(val) => {this.LiveMetaDataByWrappers = val === 'name'; this.RCUpdate();}}
                                        />
                                    </SC.InnerPanel>                                  
                                </SC.FRow>
                            </SC.FRow>
                            <SC.FRow>                                
                                <SC.FCol f1>
                                {
                                    this.LiveMetaDataByWrappers ? 
                                    <CodeCopyBox
                                        key={this.props.platform} 
                                        style={{marginTop : '-12px'}}
                                        code=
{<div>{
`.
.
render() {
    return (
        <App>`}
<div style={{backgroundColor:'#303134', padding : '4px'}}>
{`              <Toolabs.MyComponent
                    id='<Component-Id>'
                    options={...} 
                />`}
</div>
{
`           </App>
        )
}
`}</div>}
copyCode={`<Toolabs.MyComponent 
    id='<Component-Id>'
    options={...} 
/>`}
                                    /> : 
                                    <CodeCopyBox 
                                        style={{marginTop : '-12px'}}
                                        key={this.props.CodeDeclareId}
                                        code=
{<div>{
`
render() {
  return (
    <App>`}
<div style={{backgroundColor:'#303134', padding : '4px'}}>
{this.props.CodeDeclare}
</div>
{
`    </App>
  )
}
`}</div>}
copyCode={this.props.CodeDeclare}
                                />
                                }                                    
                               </SC.FCol>
                            </SC.FRow>             
                        </Block_CodeInfo>
                    </SC.FCol>
                </SC.FCol>                
            )
        }
        return (
            <SC.FCol f1>
                {
                    this.HideInfoPanel ? null : 
                    <InfoPanel onClose={() => {this.HideInfoPanel = true; this.RCUpdate(); }} style={{fontSize : '12px', marginBottom : '16px', backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                        <InfoLine>Toolabs UI components live on a Metadata Driven based system. </InfoLine>
                        <InfoLine>What you need is a render engine for the platform of your codebase and metadata of components you made with Toolabs.</InfoLine>
                        <InfoLine last>Follow this interactive guide to get your living components ready.</InfoLine>
                    </InfoPanel>                
                }                
                {item_engine}
                {item_metadata}
                
            </SC.FCol>
        )    
    }
}