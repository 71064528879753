import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';
import { ShadowCardItem } from '../../../../left/designsystem/shadows';
import { StyleGroupTokenItem, INSPECTOR_ITEM_MARGIN } from '../common';

export default class ItemShadow extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (Utils.HasAnyChange(this.props, nextProps, 'Id', 'ManagerId', 'GlobalStateId', 'GlobalState', 'shadow'))
        return true;        

        return super.ShouldUpdate(nextProps, nextState);
    }    
    renderCustom() {
        return (
            <StyleTokenShadowItem 
                onSelect={this.props.onSelectSahadow}
                tokenId={this.props.shadow.TokenId}
                isTextShadow={this.props.isTextShadow}
            />
        )        
    }
}

export const StyleTokenShadowItem = ({onSelect, tokenId, statearray, isTextShadow}) => {
    const token = {
        name : ''
    }
    let isEmpty = true;
    let textShadow = isTextShadow;
    if (tokenId) {
        const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
        if (tokenModel) {
            textShadow = textShadow || tokenModel.textShadow;            
            const shadows = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : tokenModel, statearray : statearray}), [], 'values');
            let shadowvalue = Utils.GetShadowCss(shadows, textShadow, Globals.ProjectManager.Tokens.ValueOfId); 
            token.value = shadowvalue;
            token.name = tokenModel.name;
            isEmpty = false;
        }            
    }
    return (
        <StyleGroupTokenItem onClick={onSelect} style={{marginTop : INSPECTOR_ITEM_MARGIN, ...SC.Styles.Flex.Row, height : isEmpty ? '30px' : 'auto'}} >
            <SC.FCol f1 style={{justifyContent : 'space-evenly', paddingLeft : '8px', paddingRight : '8px'}}>
                <SC.TextDivAbbr>
                    Shadow
                </SC.TextDivAbbr>
                {
                    !isEmpty && 
                    <SC.TextDivAbbr style={{marginTop : '8px', fontSize : '11px', fontWeight : 500}}>
                        {token.name}
                    </SC.TextDivAbbr>
                }
                
            </SC.FCol>
            {
                !isEmpty ?
                <ShadowCardItem
                    shadow={token}
                    textShadow={textShadow}        
                    small                
                /> : 
                <SC.Icons.Icon_Button hasFill style={{paddingRight : '4px'}}>
                    <SC.Icons.Icon_Plus size={20} />
                </SC.Icons.Icon_Button>
            }
        </StyleGroupTokenItem>   
        
    )
}