import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import {GroupTitle, TokenPanelHeader} from '../../../../left/designsystem/common';
import SpacePatternEditor from '../../../../left/designsystem/spacing/patterns/item';
import { StyleItemPopup, StyleGroup, StyleGroupTitle, OptionalStyleGroupHeader, ToggleButton, PopupArrow } from '../common';
import { AnimatePresence, motion} from 'framer-motion';
import { SpacePattern, GetSpacePatternItem } from '../../../../left/designsystem/spacing/patterns';
import ComponentDesignManager from '../../../../../component/manager';
import { GetTokenAliaseList } from '../../../../left/designsystem/aliases';

export default class PaddingMargins extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SidesWithValue = {};
        this.SelectSpace = this.SelectSpace.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.SetTokenBinding = this.SetTokenBinding.bind(this);
        
        this.ClosePopup = this.ClosePopup.bind(this);

        this.getPropName = this.props.isPadding ? Utils.BorderStyles.propPadding : Utils.BorderStyles.propMargin;

        this.LoadTokenProps(this.props);
    }
    RemoveTokenBinding() {        
        this.showEditor = false;
        let styleRemoved = false;
        Utils.ForEach(this.SidesWithValue, (value, key) => {
            if (value && value.TokenId) {
                this.props.onRemoveStyle(value.propName, this.props.Id, null, [], true);
                styleRemoved = true;
            }            
        });

        if (styleRemoved) {
            this.props.GetComponentManager().ResetItemStyle(this.props.Id);
            this.props.GetComponentManager().UpdateRenderers();
            this.props.onUpdate();
            setTimeout(() => {
                AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.ReloadItemDesigner(this.props.Id);
            }, 210);
        }        
        else {
            this.RCUpdate();
        }
    }
    SetTokenBinding(tokenId) {
        const currentTokenId = Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId');
        const propName = this.getPropName(this.ShowSpaceSelector.side);
        if (currentTokenId === tokenId) {
            this.props.onRemoveStyle(propName, this.props.Id, null, [], true);
            this.props.GetComponentManager().ResetItemStyle(this.props.Id);
            this.props.GetComponentManager().UpdateRenderers();
        }
        else {
            this.props.SetTokenBinding(propName, this.props.Id, tokenId, [], true);
            // this.ClosePopup();
        }
        setTimeout(() => {
            AppLayout.Refs.SelectionDesigner && AppLayout.Refs.SelectionDesigner.ReloadItemDesigner(this.props.Id);
        }, 210);
        this.props.onUpdate();
    }

    LoadTokenProps(props) {
        this.hasNoSpacing = true;        
        this.propNames = [];
        this.SidesWithValue = {};
        Utils.BorderStyles.Sides.map((side, i) => {            
            const propValue =  this.getPropName(side);
            this.propNames.push(propValue);
            const useSideName = Utils.UseNullOrEmpty(side, 'All');            
            this.SidesWithValue[useSideName] = Utils.JustGet(props, null, propValue, 'TokenId');

            if (this.SidesWithValue[useSideName]) {                
                this.hasNoSpacing = false;
                const tokenId = this.SidesWithValue[useSideName];
                const tokenModel = Globals.ProjectManager.Tokens.SpacePatterns.GetPattern(tokenId);
                this.SidesWithValue[useSideName] = {
                    propName : propValue,
                    TokenId : tokenId
                }
                
                if (tokenModel) {
                    this.SidesWithValue[useSideName].value = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceSize(tokenModel, null, null, this.props.StateArray);
                    this.SidesWithValue[useSideName].unit = Globals.ProjectManager.Tokens.SpacePatterns.GetSpaceUnit(tokenModel, null, this.props.StateArray);
                    this.SidesWithValue[useSideName].name = tokenModel.name;
                    this.SidesWithValue[useSideName].pattern = tokenModel;
                }    
            }
        });        
    }
    shouldComponentUpdate(nextProps, nextState) {      
          
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.showEditor = false;
            this.LoadTokenProps(nextProps);
            return true;
        }
        
        if (Utils.HasAnyChange(this.props, nextProps, ...this.propNames)) {
            this.LoadTokenProps(nextProps);
            return true;
        }            

        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'GlobalStateId', 'GlobalState', 'GlobalThemeId')) {
            if (!this.hasNoSpacing && this.props.GlobalThemeId !== nextProps.GlobalThemeId) {
                this.LoadTokenProps(nextProps);
            }
            return true;     
        }
               
        return super.ShouldUpdate(nextProps, nextState);
    }
    SelectSpace(side) {
        this.showEditor = true;
        if (!this.IsClosingPopup) {
            const title = side.toUpperCase() + ' ' + (this.props.isPadding ? 'PADDING' : 'MARGIN');
            if (this.ShowSpaceSelector) {
                if (this.ShowSpaceSelector.side === side) {
                    this.ClosePopup();
                }
                else {
                    this.ShowSpaceSelector.side = side;
                    this.ShowSpaceSelector.title = title;
                    this.ShowSpaceSelector.doNotClose = true;
                }                
            }
            else {
                const panelBounds = UIUtils.Utils.GetBounds(AppLayout.Refs.Inspectors.Main);
                const bounds = UIUtils.Utils.GetBounds(this);
                let maxHeight = bounds.top - panelBounds.top - 100;
                let toBottom = false;
                if ((panelBounds.bottom - bounds.bottom - 100) > maxHeight) {
                    maxHeight = panelBounds.bottom - bounds.top;
                    toBottom = true;
                }
                this.ShowSpaceSelector = {
                    side : side,
                    title : title,
                    maxHeight : maxHeight,
                    toBottom : toBottom
                };
            }            
            
            this.RCUpdate();
        }
            
        this.IsClosingPopup = false;
        
    }
    canCloseSelector(side) {
        if (this.ShowSpaceSelector && this.ShowSpaceSelector.doNotClose) {
            this.ShowSpaceSelector.doNotClose = false;
            return true;
        }            
        return false;
    }
    ClosePopup() {
        this.IsClosingPopup = true;
        delete this.ShowSpaceSelector;
        this.RCUpdate();
        setTimeout(() => {
            this.IsClosingPopup = false;                                
        }, 200); 
    }    
    renderCustom() {        
        return (
            <SpaceSelectorWidget 
                hasNoSpacing={this.hasNoSpacing}
                showEditor={this.showEditor}
                title={this.props.title}
                onRemove={this.RemoveTokenBinding}
                onSelectSpace={this.SelectSpace}
                hasTop={this.SidesWithValue.Top}
                hasLeft={this.SidesWithValue.Left}
                hasAll={this.SidesWithValue.All}
                hasRight={this.SidesWithValue.Right}
                hasBottom={this.SidesWithValue.Bottom}
                ShowSpaceSelector={this.ShowSpaceSelector}
                isOver={this.ShowSpaceSelector ? this.canCloseSelector.bind(this, this.ShowSpaceSelector.side) : null}
                onClose={this.ClosePopup}
                onSetTokenBinding={this.SetTokenBinding}
                onPreviewToken={this.props.onPreviewToken}
                tokenId={this.ShowSpaceSelector ? Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId') : null}
                getPropName={this.getPropName}
            />
        )        
    }
}

export class SpaceSelectorWidget extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    SelectSpace(side) {
        this.props.onSelectSpace(side);
    }
    render() {
        let header, content;
        if (this.props.hasNoSpacing && !this.props.showEditor) {
            header = (
                <OptionalStyleGroupHeader title={this.props.title} onClick={this.SelectSpace.bind(this, '')} />
            )
        }
        else {
            header = (
                <SC.FRow alc jsb style={this.props.styleHeader}>
                    <StyleGroupTitle>{this.props.title}</StyleGroupTitle>
                    <SC.FRow alc justifyEnd>
                        <SC.Icons.Icon_Button hasFill hasCursor  onClick={this.props.onRemove} style={{zIndex : 1, paddingLeft : '8px', minHeight : '20px'}}>
                            <SC.Icons.Icon_Delete size={16} />
                        </SC.Icons.Icon_Button>
                    </SC.FRow>
                </SC.FRow>
            )

            let buttons;

            if (this.props.singleSide) {
                buttons = (
                    <SpaceButton 
                        side='NONE'
                        spaceInfo={this.props.value}
                        onClick={this.SelectSpace.bind(this, 'single')}
                        active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === 'single'}
                    />
                )
            }
            else {
                buttons = (
                    <div style={{
                        display : 'grid',
                        gridTemplateColumns : '1fr 1fr 1fr',
                        gridTemplateRows : '1fr 1fr 1fr',
                        gridGap  :'4px',
                        marginTop : '-14px',
                        width : '240px',
                        alignSelf : 'center'
                    }}>
                        <div />                    
                        <SpaceButton 
                            side='Top'
                            spaceInfo={this.props.hasTop}
                            onClick={this.SelectSpace.bind(this, 'Top')}
                            active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === 'Top'}
                        />
                        <div />
                        <SpaceButton 
                            side='Left'
                            spaceInfo={this.props.hasLeft}
                            onClick={this.SelectSpace.bind(this, 'Left')}
                            active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === 'Left'}
                        />
                        <SpaceButton 
                            side='All'
                            spaceInfo={this.props.hasAll}
                            onClick={this.SelectSpace.bind(this, '')}
                            active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === ''}
                        />
                        <SpaceButton 
                            side='Right'
                            spaceInfo={this.props.hasRight}
                            onClick={this.SelectSpace.bind(this, 'Right')}
                            active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === 'Right'}
                        />
                        <div />
                        <SpaceButton 
                            side='Bottom'
                            spaceInfo={this.props.hasBottom}
                            onClick={this.SelectSpace.bind(this, 'Bottom')}
                            active={this.props.ShowSpaceSelector && this.props.ShowSpaceSelector.side === 'Bottom'}
                        />
                        <div />
                    </div>
                )
            }

            content = (
                <React.Fragment>
                    {buttons}
                    {
                        this.props.showEditor && this.props.ShowSpaceSelector &&
                        <SpaceSelectorPopup 
                            popupContent
                            Id={this.props.Id}
                            onClose={this.props.onClose}
                            side={this.props.ShowSpaceSelector.side}
                            propName={this.props.getPropName(this.props.ShowSpaceSelector.side)}
                            title={this.props.ShowSpaceSelector.title}
                            isOver={this.props.isOver}
                            maxHeight={this.props.ShowSpaceSelector.maxHeight}
                            toBottom={this.props.ShowSpaceSelector.toBottom}
                            onTokenSelected={this.props.onSetTokenBinding}
                            tokenId={this.props.tokenId}
                            onPreviewToken={this.props.onPreviewToken}
                        />
                    }
                    
                </React.Fragment>                
            )
        }

        return (
            <StyleGroup empty key={this.isPadding ? (this.hasNoSpacing ? 'noPadding' : 'padding') : this.hasNoSpacing ? 'noMargin' : 'margin'} style={this.props.style} >
                {header}
                {content}
            </StyleGroup>
        )
    }
}

class SpaceButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const spaceInfo = this.props.spaceInfo;
        const style = {
            flex : 1, position : 'relative',
            ...SC.Styles.FontStyles.Monospace, fontSize : '12px',
            ...SC.Styles.Flex.Cell
        };
        if (this.props.active) {
            style.border = SC.CurrentTheme.theme.border_brand;
        }
        return (  
            <ToggleButton hasCursor selected={spaceInfo !== null} style={style} onMouseDown={this.props.onClick}>
                {spaceInfo ? spaceInfo.value : 
                    this.props.side
                }
                <div style={{
                        position : 'absolute', pointerEvents : 'none', 
                        border : 'none',
                        ...SideStyles[this.props.side],
                        borderColor : SC.CurrentTheme.theme.color_brand
                    }} 
                />
            </ToggleButton>
        );
    }
}
 
const SideStyles = {    
    Top : {
        top : '1px',
        left : '50%',
        width : '16px',
        transform : 'translateX(-50%)',
        borderTopWidth : '1px',
        borderTopStyle : 'solid'
    },
    Bottom : {
        bottom : '1px',
        left : '50%',
        width : '16px',
        transform : 'translateX(-50%)',
        borderBottom : '1px solid'
    },
    Left : {
        left : '1px',
        top : '50%',
        height : '16px',
        transform : 'translateY(-50%)',
        borderLeft : '1px solid'
    },
    Right : {
        right : '1px',
        top : '50%',
        height : '16px',
        transform : 'translateY(-50%)',
        borderRight : '1px solid'
    }
}

export class SpacePatternList extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        const patterns = Globals.ProjectManager.Tokens.SpacePatterns.Get();
        this.patternItems = [];
        Utils.ForEach(patterns, (patternId, i) => {
            const pattern = GetSpacePatternItem(patternId);
            if (this.props.onlyPositive && pattern.spaceValue < 0) {

            }
            else {
                this.patternItems.push(pattern)
            }                       
        });

        this.patternItems = Utils.Reverse(Utils.Sort(this.patternItems, (item) => {return item.spaceValue}));

        if (!this.props.forAliase) {
            this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Spacings);
            Utils.ForEach(this.aliases, (aliase, i) => { 
                if (aliase.tokenId) {
                    aliase.tokenItem = Utils.Find(this.patternItems, (item) => {return item.id === aliase.tokenId});                
                }
            });
            
            this.aliases = Utils.Reverse(Utils.Sort(this.aliases, (item) => {return item.tokenItem.spaceValue}));
        }        
        

        this.AddBorderRadius = this.AddBorderRadius.bind(this);
        this.onSubmitAddToken = this.onSubmitAddToken.bind(this);
        this.onCancelAddToken = this.onCancelAddToken.bind(this);
        this.PreviewToken = this.PreviewToken.bind(this);
        this.PreviewNewToken = this.PreviewNewToken.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);
    }
    SelectToken(id) {
        this.props.onTokenSelected(id);
    }
    AddBorderRadius() {
        const Id = Utils.Id();
        const Model = Globals.ProjectManager.Tokens.SpacePatterns.NewPattern(Id);        
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.SpacePatterns,
            ...Model,
            id : Id
        });
        
        this.AddingNewToken = {
            Id : Id,
            Model : Model
        };

        this.PreviewToken(true, this.AddingNewToken.Id);
        this.RCUpdate();
    }
    componentWillUnmount() {
        this.onCancel();
    }
    onCancel() {
        if (this.AddingNewToken)  {
            Globals.ProjectManager.Tokens.DeletePreviewToken(this.AddingNewToken.Id);
            Globals.ProjectManager.Tokens.SpacePatterns.DeletePattern(this.AddingNewToken.Id);
            this.PreviewToken(false, this.AddingNewToken.Id);
            ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateItemPreviews(this.props.Id, false);
        }
    }
    onCancelAddToken() {
        this.onCancel();
        delete this.AddingNewToken;
        this.RCUpdate();
    }
    onSubmitAddToken() {
        const Id = this.AddingNewToken.Id
        delete this.AddingNewToken
        this.SelectToken(Id);        
        AppLayout.Refs.DesignSystem.Spacing && AppLayout.Refs.DesignSystem.Spacing.Reload();
    }
    PreviewToken(show, tokenId) {  
        if (!this.props.onPreviewToken)
            return;
        this.PreviewingTokenId = show ? tokenId : null;
        this.props.onPreviewToken({
            propName : this.props.propName,
            tokenId : tokenId,
            show : show,
            ParentNames : []
        }); 
            
    }
    PreviewNewToken(value) {        
        this.AddingNewToken && Globals.ProjectManager.Tokens.SetPreviewTokenValue(this.AddingNewToken.Id, value);
        this.PreviewingNewToken = this.AddingNewToken.Id;        
        ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.AddingNewToken.Id);
    }
    SaveEditingPattern() {

        this.RCUpdate();
    }
    renderCustom() {
        let content;
        if (this.AddingNewToken) {
            const spaceScale = Globals.ProjectManager.Tokens.SpaceScale.Get();

            content = (
                <SC.FCol f1 style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}>
                    <TokenPanelHeader title='NEW SPACE' 
                        hasAddCancel 
                        notBackClosable
                        onClose={this.onCancelAddToken} 
                        onCancel={this.onCancelAddToken} 
                        onAdd={this.onSubmitAddToken} 
                        onDelete={!this.IsNew && this.onDeleteBorder}
                        onLight
                    />
                    <SpacePatternEditor 
                        compact
                        editing
                        isNew
                        scales={this.AddingNewToken.scales}
                        pattern={this.AddingNewToken.Model}
                        id={this.AddingNewToken.Id}
                        baseSize={spaceScale.baseSize}
                        ratio={spaceScale.ratio}
                        GlobalStateId={this.props.GlobalStateId}
                        onSave={this.SaveEditingPattern}
                        onClose={this.onSubmitAddToken} 
                        onPreviewChange={this.PreviewNewToken}
                        StateArray={this.props.StateArray}
                    />
                </SC.FCol>
            )
        }
        else {
            const style_panel = {};
            const style_content = {padding : '8px', display : 'grid'};
            if (this.props.popupContent) {
                style_content.paddingTop = 0;
            }
            else {
                style_panel.backgroundColor = SC.CurrentTheme.theme.back;
            }
            content = (
                <SC.FCol f1>   
                    <GroupTitle 
                        closable
                        onClose={this.props.onClose}
                        style={{marginBottom : this.props.popupContent ? '8px' : 0, paddingRight : '8px'}}
                        titleStyle={{fontSize : '11px'}}
                        title={this.props.title} 
                        hasAdd={{onAdd : this.AddBorderRadius}}                         
                    />
                    <SC.FCol fw fh style={style_panel}>
                        <SC.CustomScrollbars
                            autoHeight
                            autoHeightMin={100}
                            autoHeightMax={this.props.maxHeight - 30}
                        >
                            {
                                this.aliases && this.aliases.length > 0 && 
                                <div style={{display : 'grid', padding : '8px', paddingTop : 0}}>
                                    <div style={{fontSize : '11px', marginBottom : '4px'}}>Aliases</div>
                                    {
                                        this.aliases.map((aliase) => {
                                            return (
                                                <SpacePattern 
                                                    key={aliase.id}
                                                    pattern={aliase.tokenItem.pattern}
                                                    spaceValue={aliase.tokenItem.spaceValue}
                                                    listView
                                                    onClick={this.SelectToken.bind(this, aliase.id)}
                                                    selected={aliase.id === this.props.tokenId}
                                                    name={aliase.name}
                                                    // onPreview={{
                                                    //     onMouseEnter : this.PreviewToken.bind(this, true, token.id, this.props.propName),
                                                    //     onMouseLeave : this.PreviewToken.bind(this, false, token.id, this.props.propName)
                                                    // }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            }   
                            <div style={style_content}>
                                {this.aliases && this.aliases.length > 0 && <div style={{fontSize : '11px', marginBottom : '4px'}}>Tokens</div>}
                                {
                                    this.patternItems.map((token) => {
                                        return (
                                            <SpacePattern 
                                                key={token.id}
                                                pattern={token.pattern}
                                                spaceValue={token.spaceValue}
                                                listView
                                                onClick={this.SelectToken.bind(this, token.id)}
                                                selected={token.id === this.props.tokenId}
                                                // onPreview={{
                                                //     onMouseEnter : this.PreviewToken.bind(this, true, token.id, this.props.propName),
                                                //     onMouseLeave : this.PreviewToken.bind(this, false, token.id, this.props.propName)
                                                // }}
                                            />
                                        )
                                    })
                                }
                            </div>
                        </SC.CustomScrollbars>
                    </SC.FCol>                    
                </SC.FCol>
            )
        }

        if (this.props.popupContent) {
            const style = {
                position : 'absolute', 
                bottom : '100%',
                right : '8px',
                left : '8px',
                marginBottom : '8px',
                backgroundColor : SC.CurrentTheme.theme.dropdown_back_dark,
                border : SC.CurrentTheme.theme.border_popup,
                borderRadius : '2px',
                boxShadow : SC.CurrentTheme.theme.popup_shadow,
                pointerEvents : 'all',
                zIndex : 10000
            };
            if (this.props.toBottom) {
                style.bottom = 'unset';
                style.top = '100%';
            }
            return (
                <motion.div
                    style={style}
                    initial={{y : 8}}
                    animate={{y : 0}}
                    exit={{y : 8, opacity : 0.7}}
                    transition={{duration : 0.2}}           
                    layoutTransition={{
                        duration : 0.2
                    }}
                >
                    <PopupArrow style={{transform : 'rotate(180deg) translateX(50%)'}} toTop />
                    {content}
                </motion.div>
            )
        }

        return content;
    }
}

export const SpaceSelectorPopup = UIUtils.OutSideClickHandler(SpacePatternList);