import Events from './Events';

const AppLayout = {
    TeamModeEnabled : true,
    AppSource : {
        Figma : false,
        DSM : true
    },
    Theme : {
        Current : 'Dark6_1',
        Light : 'Light',
        Dark : 'Dark6_1'
    },
    Panels : {
        leftBarClosed : false,
        leftToolBarClosed : true,
        rightBarClosed : false,
        leftPanel : {
            width : 280
        },
        rightPanel : {
            active : false,
            width : 280
        },
        rightPanelClosed : true,
        rightPanelExpanded : false,
        MegaMenuVisible : false,
        leftInnerPanelPinned : false,
        FullScreen : false
    },
    DOM : {

    },
    Refs : {
        Header : null,
        ProjectList : null,
        Inspectors : {
            Main : null,
            GridDesigner : null,
            PositionRefs : {
                
            }
        },
        SelectionDesigner : null,   
        SelectedGridDesigner : null,     
        Artboard : {
            
        },
        AbsoluteItemGuide : null,
        DropOverlay : null,
        DesignSystem : {
            Colors : null,
            ColorAliases : null,
            Shadows : null,
            ShadowAliases : null,            
            Typograpgy : null,
            TextPatterns : null,
            Spacing : null,
            SpaceAliases : null,
            Images : null,
            Icons : null,
            Borders : null,
            BordersAliases : null,
            RadiusAliases : null,
            TextContents : null,
            Booleans : null,
            MockupContents : null,
            Transform : null,
            Motion : null,
            Transition : null,
            EaseCurves : null,
            Durations : null,
            Filters : null,
            FigmaAutoLayouts : null,
            TokenType : null,
            CustomTokenList : null
        },
        DocumentDesigner : {
            Main : null
        },
        MainApp : null,
        Studio : {
            Main : null,
        },
        PanzoomCanvas : null,
        CanvasWizard : null,
        LeaderLines : {}
    },
    Designer : {
        ItemBorders_Visible : false,
        Canvas : {
            DeviceResizing : false
        },
        Zoom : 1,
        Scroll : {
            x : 0,
            y : 0,
            view_x : 0,
            view_y : 0
        },
        GetOffsets() {
            if (this.CallBack_GetDeviceOffset)
                return this.CallBack_GetDeviceOffset();
            return {
                x : 0, y : 0
            }
        }
    },
    Media : {
        Small : false
    }
};


[
    {name : 'ItemBorders'},
    {name : 'HiddenItems', visible : false}
].map((tag) => {
    AppLayout.Designer[tag.name] = {
        name : tag.name,
        Visible : tag.visible,
        Toggle(show) {
            this.Visible = show ? true : !this.Visible;
            Events.BCE(Events.DESIGNER.LAYOUT_CHANGED, this.name, this.Visible);
        }
    };

})

export default AppLayout;
