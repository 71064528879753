import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    MetaData,
    Globals
} from '../../../../../../importer';

import { ItemNameEditor, TokenItemBox } from '../common';
import SizeEditor from '../../../right/iteminspector/styleitems/size';

export default class BorderTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onChangeSize = this.onChangeSize.bind(this);

        this.Ref_Name = React.createRef();

        
        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
            this.EditingModel.name = 'Radius ' + Utils.Get(this.EditingModel, 4, 'value', 'value');
        }
        else {
           this.LoadToken();
        }
        const valueName = 'Radius ' + Utils.Get(this.EditingModel, 4, 'value', 'value');
        this.isValueName = this.EditingModel.name === valueName;
    }
    LoadToken(props = this.props) {
        this.token = Globals.ProjectManager.Tokens.Token(props.id);
        if (this.token) {
            this.EditingModel = {
                name : this.token.name,
                value : Utils.DeepClone(Utils.UseNullOrEmpty(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), {}))
            };
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!nextProps.newModel) {
                this.LoadToken(nextProps);
                if (!this.token) {
                    this.props.onClose();
                    return false;
                }
            }            
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.BorderRadiuses);
    }        
    
    SaveName(name) {
        this.isValueName = false; 
        this.EditingModel.name = name;    
        Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    onChangeSize(size) {
        if (!this.props.newModel && !this.ChangeLogged) {
            this.ChangeLogged = true;
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Border Radius'});
        }
        Utils.Set(this.EditingModel, size.value, 'value', 'value');
        Utils.Set(this.EditingModel, size.unit, 'value', 'Unit');
        if (!this.props.newModel) {
            
            Globals.ProjectManager.Tokens.SetValue({id : this.props.id, value : this.EditingModel.value, type : Globals.ProjectManager.Tokens.Types.BorderRadiuses});

            const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
            const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');

            if (this.props.isNew)
                this.props.onPreviewChange && this.props.onPreviewChange(Utils.px(width, unit));
            else 
            {
                const changingTokens =  [{
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
                    value : Utils.px(width, unit)
    
                }];

                if (this.token.aliases) {
                    this.token.aliases.map((aliaseId) => {
                        changingTokens.push({
                            Id : aliaseId,
                            Type : Globals.ProjectManager.Tokens.Types.BorderRadiuses,
                            value : Utils.px(width, unit)
                        })
                    })
                }

                Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changingTokens);
            }           
        }
        else {
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel.value);            
        }
        if (this.isValueName) {
            this.EditingModel.name = 'Radius ' + size.value;
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : this.EditingModel.name} );
        }
        this.RCUpdate();
    }      
    renderCustom() {
        
        const width = Utils.Get(this.EditingModel, 1, 'value', 'value');
        const unit = Utils.Get(this.EditingModel, Globals.ProjectManager.Units.Default(), 'value', 'Unit');
        

        return (
            <SC.FCol fw fh style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.EditingModel.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.EditingModel}
                />
                <SC.FRow alc jsb style={{marginTop : '8px'}}>
                    <div>Width</div>
                    <SizeEditor 
                        autoFocus
                        autoSelect
                        value={width}
                        unit={unit}
                        onChange={this.onChangeSize}
                        onChanging={this.onChangeSize}
                    />                        
                </SC.FRow>       
                <div style={{
                    border : '1px solid',
                    borderColor : SC.CurrentTheme.theme.color_brand,
                    borderRadius : Utils.px(width, unit),
                    height : '80px',
                    width : '100%',
                    marginTop : '24px',
                    boxSizing : 'border-box'
                }} /> 
            </SC.FCol>
        )
    }
}
