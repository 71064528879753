import React from 'react';
import ReactDOM from 'react-dom';

import ReactPlayer from 'react-player'

class Widget_Video extends React.Component
{
    constructor(props) {
        super(props);        
        this.state =  {
            Loaded : false
        };
    }
   
    render() {       
        return (
            <div style={{...this.props.style, overflow : 'hidden', width : '100%', height : '100%'}}>
                <ReactPlayer 
                    url={this.props.VideoUrl}
                    width='100%'
                    height='100%'
                />
            </div>
        )        
    }
}

export default Widget_Video;
