import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../../importer';

import { ItemNameEditor, TokenPanelHeader, TokenGalleryView, TokenItemBox } from '../../../common';
import { LeftScrollPanel } from '../../../../common';
import InputSlider from '../../../../../../../../components/editors/input_slider';
import { motion, AnimatePresence } from 'framer-motion';
import { PropertyLabel, Seperator } from '../../../../../right/iteminspector/styleitems/common';
import { EasePattern, TransitionCardItem } from '..';
import { PresetCurve } from '../../easing/bezierEditor/presetcurve';
import { GetEaseCurveTokenItem } from '../../easing';
import { DurationPattern, GetDurationPatternList, GetDurationTokenItem } from '../../patterns';
import { TransformListItem, GetTransformTokenItem } from '../../../transforms';
import { GetTransitionTokenItem } from '../';
import Switch from '../../../../../../../../components/editors/Switch';
import { InfoPanel } from '../../../../../../../../components/info';
import RadioGroup from '../../../../../../../../components/editors/radiogroup';

export default class EaseTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.SelectEaseCurve = this.SelectEaseCurve.bind(this);
        this.SelectDuration = this.SelectDuration.bind(this);
        this.SelectTransform = this.SelectTransform.bind(this);
        this.SelectFromVariation = this.SelectFromVariation.bind(this);
        
        this.onChangeOpacity = this.onChangeOpacity.bind(this);        
        this.onChangeEffect = this.onChangeEffect.bind(this);
        
        
        this.Ref_Name = React.createRef();
        

        this.Load(this.props);
    }
    Load(props) {
        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.transition = {
                ease : {
                    name : 'Select Ease Curve'
                },
                duration : {
                    pattern : {
                        name : 'Select Duration'
                    }
                },
                delay : {
                    pattern : {
                        name : 'Select Delay'
                    }
                },
                transform : {
                    name : 'Select Transform'
                }
            }
        }
        else {
            this.token = Globals.ProjectManager.Tokens.Token(this.props.id);
            this.transition = GetTransitionTokenItem(this.props.id);
        }
    }
    SelectFromVariation() {
        if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel();
        }
        Events.BCE(Events.DESIGNER.BOARD.TRANSITION.VARIATIONS, {
            id : this.props.id,
            transition : this.transition,            
            onSelectVariation : ({ease, duration}) => {
                this.transition.ease = ease;
                if (!this.props.isNew) {
                    Globals.ProjectManager.Tokens.SetValue({
                        id : this.props.id,
                        name : 'easeId',
                        value : ease.id,
                        type : Globals.ProjectManager.Tokens.Types.Transitions
                    });
                    Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
                }
                else {
                    this.token.easeId = ease.id;
                }

                this.transition.duration = duration;
                if (!this.props.isNew) {
                    Globals.ProjectManager.Tokens.SetValue({
                        id : this.props.id,
                        name : 'durationId',
                        value : duration.id,
                        type : Globals.ProjectManager.Tokens.Types.Transitions
                    })
                }
                else {
                    this.token.durationId = duration.id;
                }

                this.animationRenderId = Utils.Id();
                this.RCUpdate();
            },
            RegisterClose : (close) => {
                super.AddCloseCallback(close);
                this.closeDataEditor = close;
                // onAddCloseBack && onAddCloseBack(close);
                // registerClose && registerClose(close);                
            }                   
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.id !== this.props.id || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.Load(nextProps);
            if (!this.token) {
                this.props.onCancelAddPattern();
                return false;
            }
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Motion);
    }            
    
    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    SelectEaseCurve() {
        const onSelect = (tokenId) => {
            this.transition.ease = GetEaseCurveTokenItem(tokenId) || {
                name : 'Select Ease Curve'
            };                    
            if (!this.props.isNew) {
                Globals.ProjectManager.Tokens.SetValue({
                    id : this.props.id,
                    name : 'easeId',
                    value : tokenId,
                    type : Globals.ProjectManager.Tokens.Types.Transitions
                });
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
            }
            else {
                this.token.easeId = tokenId;
            }
            this.animationRenderId = Utils.Id();
            this.RCUpdate();
        };
        const onUpdate = () => {
            this.transition.ease = GetEaseCurveTokenItem(this.transition.ease.id);
            this.RCUpdate();
        }

        let onPreview, onClosed;

        if (this.props.onSelectEase) {
            this.props.onSelectEase(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.EASECURVE,
                title : 'EASE CURVE',
                easeId : this.transition.ease.id,
                onSelect : onSelect,
                onUpdate : onUpdate,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewToken : ({show, tokenId}) => {
                    
                },
                onClosed : onClosed
            })
        } 
    }
    SelectDuration(prop, propId) {
        const onSelect = (durationId) => {
            const durationPattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(durationId);
            if (durationPattern) {
                this.transition[prop] = {
                    id : durationId,
                    pattern : durationPattern,
                    timeValue : Globals.ProjectManager.Tokens.TimePatterns.GetTimeSize(durationPattern)
                }
            }
            if (!this.props.isNew) {
                Globals.ProjectManager.Tokens.SetValue({
                    id : this.props.id,
                    name : propId,
                    value : durationId,
                    type : Globals.ProjectManager.Tokens.Types.Transitions
                });
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
            }
            else {
                this.token[propId] = durationId;
            }
            this.animationRenderId = Utils.Id();
            this.RCUpdate();
        };
        const onUpdate = () => {
            const durationPattern = Globals.ProjectManager.Tokens.TimePatterns.GetPattern(this.transition[prop].id);
            this.transition[prop] = GetDurationTokenItem(durationPattern, this.transition[prop].id)
            this.RCUpdate();
        }
        let onPreview, onClosed;

        if (this.props.onSelectDuration) {
            this.props.onSelectDuration(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.DURATION,
                title : 'DURATION',
                durationId : this.transition[prop].id,
                onSelect : onSelect,
                onUpdate : onUpdate,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewToken : ({show, tokenId}) => {
                    
                },
                onClosed : onClosed
            })
        } 
    }
    SelectTransform() {
        const onSelect = (transformId) => {
            this.transition.transform = GetTransformTokenItem(transformId) || {
                name : 'Select Transform'
            };
            if (!this.props.isNew) {
                Globals.ProjectManager.Tokens.SetValue({
                    id : this.props.id,
                    name : 'transformId',
                    value : transformId,
                    type : Globals.ProjectManager.Tokens.Types.Transitions
                });
                Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
            }
            else {
                this.token.transformId = transformId;
            }
            this.animationRenderId = Utils.Id();
            this.RCUpdate();
        };
        let onPreview, onClosed;
        if (this.props.onSelectTransform) {
            this.props.onSelectTransform(onSelect, onPreview, onClosed);
        }
        else if (this.props.RefToolbar && this.props.RefToolbar.current) {
            this.props.RefToolbar.current.ShowPanel({
                type : AppState.ItemTypes.BOARD.TRANSFORM,
                title : 'TRANSFORM',
                transform : this.transition.transform.id,
                onSelect : onSelect,
                onRegisterClose : (callClose) => {
                    super.AddCloseCallback(callClose);
                },
                onPreviewToken : ({show, tokenId}) => {
                    
                },
                onUpdate : () => {
                    this.transition.transform = GetTransformTokenItem(this.transition.transform.id);
                    this.RCUpdate();
                },
                onClosed : onClosed
            })
        } 
    }
    onChangeOpacity(value) {
        this.transition.opacity = value;

        if (!this.props.isNew) {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                name : 'opacity',
                value : value,
                type : Globals.ProjectManager.Tokens.Types.Transitions
            })
        }
        else {
            this.token.opacity = value;
        }

        this.RCUpdate();
    }
    onChangeEffect(value) {
        this.transition.effectsFrom = value === 'From';

        if (!this.props.isNew) {
            Globals.ProjectManager.Tokens.UpdateProp({
                id : this.props.id,
                name : 'effectsFrom',
                value : this.transition.effectsFrom
            })
        }
        else {
            this.token.effectsFrom = this.transition.effectsFrom;
        }

        this.RCUpdate();
    }
    renderCustom() {
        const animateProps = {
            initial : {
                scaleX : 1,
                scaleY : 1,
                rotate : 0,
                x : 0,
                y : 0,
                opacity : 1
            },
            animate : {

            },
            transition : {
                flip: Infinity,
                repeatDelay : 0.4
            }
        }
        if (this.transition.ease && this.transition.ease.path) {
            animateProps.transition.ease = [
                Number(this.transition.ease.path.x1),
                Number(this.transition.ease.path.y1),
                Number(this.transition.ease.path.x2),
                Number(this.transition.ease.path.y2) 
            ];        
        }
        if (this.transition.duration && Utils.IsNotNullOrEmpty(this.transition.duration.timeValue)) {
            animateProps.transition.duration = this.transition.duration.timeValue / 1000;
        }
        if (this.transition.delay && Utils.IsNotNullOrEmpty(this.transition.delay.timeValue)) {
            animateProps.transition.delay = this.transition.delay.timeValue / 1000;
        }        
        let transformOrigin = 'center center';
        if (this.transition.transform && this.transition.transform.motionValue) {
            Utils.ForEach(this.transition.transform.motionValue, (value, prop) => {
                animateProps.animate[prop] = value;
                // slashMotion.rest[prop] = 0;
            });
            if (this.transition.transform.value && this.transition.transform.value.transformOrigin)
                transformOrigin = this.transition.transform.value.transformOrigin;
        }
        
        if (Utils.IsNotNullOrEmpty(this.transition.opacity)) {
            animateProps.animate.opacity = this.transition.opacity;
        }
        
        return (
            <SC.FCol fw fh >
                {
                    !this.ShowPresets && 
                    <React.Fragment>
                        {
                            !this.props.noHeader && 
                            <TokenPanelHeader title={this.props.newModel ? 'NEW TRANSITION' : 'EDIT TRANSITION'} 
                                hasAddCancel 
                                notBackClosable={this.props.newModel ? true : false}
                                onClose={this.props.onCancelAddPattern} 
                                onCancel={this.props.onCancelAddPattern} 
                                onAdd={this.props.onSubmitNewPattern} 
                                onDelete={this.props.onDeletePattern}
                                onClone={this.props.onClonePattern}
                            />
                        }                        
                        <LeftScrollPanel>
                            <SC.FCol fw fh f1 style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                                <SC.FCol style={{marginBottom : '16px'}}>
                                    <ItemNameEditor
                                        noMargin
                                        fontSize='12px'                        
                                        name={this.token.name}
                                        onSaveName={this.SaveName}
                                        onGetItems={this.GetItems}
                                        model={this.token}
                                    />
                                </SC.FCol>   
                                <SC.FRow onClick={this.SelectFromVariation} style={{cursor : 'pointer'}} justifyEnd>
                                    <SC.LinkText>
                                        Select from variations
                                    </SC.LinkText>
                                </SC.FRow>                                 
                                <div style={{
                                    display : 'grid',
                                    gridTemplateColumns : 'auto 1fr',
                                    gridGap : '8px',
                                    marginTop : '8px'
                                }}>
                                    <PropertyLabel>Easing</PropertyLabel>
                                    <TransitionEaseItem 
                                        ease={this.transition.ease}
                                        onSelect={this.SelectEaseCurve}
                                    />
                                    <PropertyLabel>Duration</PropertyLabel>
                                    <DurationPattern 
                                        pattern={this.transition.duration.pattern}
                                        timeValue={this.transition.duration.timeValue}
                                        onClick={this.SelectDuration.bind(this, 'duration', 'durationId')}
                                    />
                                    <PropertyLabel>Delay</PropertyLabel>
                                    <DurationPattern 
                                        pattern={this.transition.delay.pattern}
                                        timeValue={this.transition.delay.timeValue}
                                        onClick={this.SelectDuration.bind(this, 'delay', 'delayId')}
                                    />
                                </div>                                
                                <Seperator />
                                <SC.FRow alc jsb >
                                    <PropertyLabel>Transition Will Effect</PropertyLabel>    
                                    <RadioGroup 
                                        small
                                        value={this.transition.effectsFrom ? 'From' : 'To'}
                                        onSelect={this.onChangeEffect}
                                        items={[
                                            {id : 'From', label : 'From'},
                                            {id : 'To', label : 'To'},
                                        ]}
                                    />
                                </SC.FRow>     
                                
                                <PropertyLabel style={{marginTop : '8px'}}>Transform</PropertyLabel>
                                <TransformListItem 
                                    notDraggable
                                    transform={this.transition.transform}
                                    onSelect={this.SelectTransform}
                                />
                                <PropertyLabel style={{marginTop : '8px'}}>Fade</PropertyLabel>
                                <TokenItemBox style={{padding : '8px', paddingTop : '4px', paddingBottom : '4px', boxSizing : 'border-box', minHeight : '28px'}}>
                                    <InputSlider 
                                        horizontal 
                                        small 
                                        reverse 
                                        scaled
                                        postFix='%'
                                        value={this.transition.opacity}
                                        min={0}
                                        max={100}
                                        onChange={this.onChangeOpacity}
                                        inputStyle={{fontSize : '12px'}}
                                    />
                                </TokenItemBox>
                                <div
                                    style={{
                                        ...SC.GetGridBackgroundStyle(true, !SC.CurrentTheme.theme.isLight),
                                        cursor : 'pointer',
                                        backgroundColor : SC.CurrentTheme.theme.back,            
                                        width : '100%', minHeight : '200px', ...SC.Styles.Flex.Cell, overflow : 'hidden',
                                        marginTop : '16px'
                                    }}            
                                    // whileHover='hover'
                                >
                                    <motion.div style={{width : '100px', height : '100px', borderRadius : '3px', backgroundColor : 'rgba(184, 109, 240, 0.34)', position : 'relative', transformOrigin : 'center', transform : 'scale(0.7)'}}>
                                        <motion.div
                                            key={this.animationRenderId}
                                            style={{
                                                width : '100px', height : '100px', borderRadius : '4px', backgroundColor : 'rgba(0, 125, 255, 0.5)', ...SC.Styles.Absolute,
                                                transformOrigin : transformOrigin
                                            }} 
                                            {...animateProps}
                                        >
                                        </motion.div>                        
                                    </motion.div>     
                                </div>                              
                            </SC.FCol>                                    
                        </LeftScrollPanel>
                    </React.Fragment>
                }
            </SC.FCol>            
        )
    }
}

const TransitionEaseItem = ({ease, onSelect}) => {
    let name = 'Select Ease Curve';
    const style = {padding : '4px', ...SC.Styles.Flex.RowAlcJsb};
    if (ease) {        
        name = ease.name;
        style.padding = '8px';
    }
    else {
        style.paddingLeft = '6px';
    }
    return (
        <TokenItemBox onClick={onSelect} style={style}>
            <SC.FRow f1 alc overflowHidden>                
                <SC.TextDivAbbr style={{flex : 1}}>
                    {name}
                </SC.TextDivAbbr>
            </SC.FRow>                
            {ease && <PresetCurve path={ease.path} size={48} selected /> }
        </TokenItemBox>
    )
}