import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Events,
    Strings,
    Globals
} from '../../../../../../importer';

import {TokenGroup, GroupTitle} from '../common';
import Slider from '../../../../../../components/editors/slider';
import DropDownSelect from '../../../../../../components/editors/enum_dropdown';
import NumberInput  from '../../../../../../components/editors/input_number';
import TextPatterns from './patterns';
import FontTokens from './fonts';
import { FontLoader } from '../../../../../../toolabs-importer';
import { motion, AnimatePresence } from 'framer-motion';
import { LeftScrollPanel } from '../../common';
import FontSelectPanel from './fonts/selector';
import { GRANT_TYPES } from '../../../../manager';

export default class Typography extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onChangeBaseSize = this.onChangeBaseSize.bind(this);
        this.onChangingBaseSize = this.onChangingBaseSize.bind(this);
        this.onChangeLineHeight = this.onChangeLineHeight.bind(this);
        this.onChangingLineHeight = this.onChangingLineHeight.bind(this);
        this.SelectExactScale = this.SelectExactScale.bind(this);
        this.onChangeCustomScale = this.onChangeCustomScale.bind(this);
        this.onSelectFont = this.onSelectFont.bind(this);
        this.BroadcastThemeChange = this.BroadcastThemeChange.bind(this);

        AppLayout.Refs.DesignSystem.Typograpgy = this;

        this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.Typescale.Get());

        this.onToggleExpand = this.onToggleExpand.bind(this);
        this.expanded = this.props.singleView || Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'DesignSystem', 'Typography', 'Expanded');

        this.HasEditGrant = Globals.ProjectManager.CheckGrant(GRANT_TYPES.EDIT_TOKEN.ALL);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalState !== nextProps.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            this.model = Utils.DeepClone(Globals.ProjectManager.Tokens.Typescale.Get());
        }
        return true;
    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'Typography', 'Expanded');
        this.RCUpdate();
    }
    BroadcastThemeChange() {
        Globals.ProjectManager.UpdateTokenValues({textPatterns : true});
        const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
        const changes = [];
        Utils.ForEach(patterns, (patternId, i) => {
            changes.push({
                Id : patternId,
                Type : Globals.ProjectManager.Tokens.Types.Fonts
            })
        });    
        
        Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, changes);
    }
    onChangeBaseSize(value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeBaseSize(value);
    }
    onChangingBaseSize(value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Font Base Size'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeBaseSize(value);        
    }
    ChangeBaseSize(value) {
        const scaleModel = this.model;
        scaleModel.baseSize = value;
        Globals.ProjectManager.Tokens.Typescale.SetScale(scaleModel);
        this.RCUpdate();
        
        this.BroadcastThemeChange();
    }        
    onChangeLineHeight(value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeProp('lineHeightFactor', Number.parseFloat(((value / 100) + 1).toFixed(3)));
    }
    onChangingLineHeight(value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Line Height'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeProp('lineHeightFactor', Number.parseFloat(((value / 100) + 1).toFixed(3)));
    }
    onChangeLetterSpacing(scaled, value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeProp('letterSpaceFactor', scaled ? Number.parseFloat(((value / 100)).toFixed(3)) : value);
    }
    onChangingLetterSpacing(scaled, value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Line Height'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeProp('letterSpaceFactor', scaled ? Number.parseFloat(((value / 100)).toFixed(3)) : value);
    }
    onChangeWordSpacing(scaled, value) {
        this.ChangeLogged = false;
        Globals.ProjectManager.Offline = false;
        this.ChangeProp('wordSpacingFactor', scaled ? Number.parseFloat(((value / 100)).toFixed(3)) : value);
    }
    onChangingWordSpacing(scaled, value) {
        if (!this.ChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Line Height'});
            this.ChangeLogged = true;
        }
        Globals.ProjectManager.Offline = true;
        this.ChangeProp('wordSpacingFactor', scaled ? Number.parseFloat(((value / 100)).toFixed(3)) : value);
    }
    ChangeProp(prop, value) {
        this.model[prop] = value;
        Globals.ProjectManager.Tokens.Typescale.SetScale(this.model);
        this.RCUpdate();
        this.BroadcastThemeChange();
    }    
    SelectExactScale(id, item) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Type Scale'});
        this.model.scaleType = item.id;
        this.model.ratio = item.value;
        Globals.ProjectManager.Tokens.Typescale.SetScale(this.model);
        this.BroadcastThemeChange();
        this.RCUpdate();
    }
    onChangeCustomScale(value) {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Type Scale'});
        this.model.scaleType = 'custom';
        this.model.ratio = value;
        Globals.ProjectManager.Tokens.Typescale.SetScale(this.model);
        this.BroadcastThemeChange();
        this.RCUpdate();
    }
    onSelectFont(onSelected, tokenid, registerClose) {
        if (this.props.selectFontsInPanel) {
            this.props.onPanelOverlay({
                show : true,
                render : (props) => {
                    return (
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                            initial={{opacity : 0.7, x : -24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : -48}}
                            transition={{duration : 0.1}}
                        >
                            <FontSelectPanel 
                                onSelect={(font) => {
                                    this.props.onPanelOverlay({close : true});
                                    SetFontTypeFace(null, font);
                                }}                                
                                onClose={() => {
                                    this.props.onPanelOverlay({close : true});
                                }}
                            /> 
                        </motion.div>                   
                    )
                }
            })
        }
        else {
             onSelectTypeFace(onSelected, tokenid, registerClose, this.RCUpdate, super.AddCloseCallback);        
            this.BroadcastThemeChange();
        }
       
    }
    renderCustom() {

        let scaleIndex = Utils.FindIndex(Globals.ProjectManager.Tokens.Utils.ScalePresets, (preset) => {return preset.id === this.model.scaleType});        
        if (!Globals.ProjectManager.Tokens.Utils.ScalePresets[scaleIndex])
            scaleIndex = 0;
        
        const scalePreset = Globals.ProjectManager.Tokens.Utils.ScalePresets[scaleIndex];        

        let content = (
            <TokenGroup 
                title='TYPOGRAPHY' 
                emptyHeader
                hideTitle={this.props.singleView}
                last={this.props.last} 
                expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand }}
            >
                {
                    (this.expanded || this.props.singleView) && 
                    <motion.div
                        initial={{opacity : 0.5, y : -4}}
                        animate={{opacity : 1, y : 0}}
                        transition={{duration : 0.2}}
                        style={{paddingLeft : '2px', paddingRight : '2px'}}
                    >
                        <FontTokens 
                            onSelectFont={this.onSelectFont} 
                            onPanelOverlay={this.props.onPanelOverlay} 
                            onBroadCastChange={this.BroadcastThemeChange}
                            GlobalState={this.props.GlobalState}
                            GlobalStateId={this.props.GlobalStateId}
                            GlobalThemeId={this.props.GlobalThemeId}
                            hasEditGrant={this.HasEditGrant}
                        />
                        <SC.FCol style={{padding : '8px', paddingLeft : '12px', fontSize : '11px'}}>
                            <BaseSizeSlider 
                                title='Base Font Size'
                                baseSize={this.model.baseSize} 
                                onChangeBaseSize={this.onChangeBaseSize} 
                                onChangingBaseSize={this.onChangingBaseSize} 
                                readOnly={!this.HasEditGrant}
                            /> 
                            <SC.FCol style={{overflow : 'hidden', marginTop : '4px'}}>
                            <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px', paddingLeft : '4px'}}>
                                <SC.SmallText>Line Height Multiplier</SC.SmallText>
                                <SliderScaleInput 
                                    value={this.model.lineHeightFactor}
                                    onChange={this.onChangeLineHeight}
                                    onChanging={this.onChangingLineHeight}
                                    readOnly={!this.HasEditGrant}
                                />
                            </SC.FRow>
                            <Slider 
                                value={((this.model.lineHeightFactor || 1) * 100) - 100}
                                min={0}
                                max={150}
                                onChange={this.onChangeLineHeight}
                                onChanging={this.onChangingLineHeight}
                                themeId={AppLayout.ThemeId}
                                readOnly={!this.HasEditGrant}
                            />                  
                            </SC.FCol>
                            <SC.FCol style={{overflow : 'hidden', marginTop : '4px'}}>
                                <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px', paddingLeft : '4px'}}>
                                    <SC.SmallText>Letter Space</SC.SmallText>
                                    <SliderScaleInput 
                                        largeInput
                                        enableNegative
                                        value={this.model.letterSpaceFactor}
                                        onChange={this.onChangeLetterSpacing.bind(this, false)}
                                        onChanging={this.onChangingLetterSpacing.bind(this, false)}
                                        readOnly={!this.HasEditGrant}
                                        postfix={(
                                            <span style={{fontSize : '11px', alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px', ...SC.Styles.FontStyles.Monospace}}>EM</span>
                                        )}
                                    />
                                </SC.FRow>
                                <Slider 
                                    value={((this.model.letterSpaceFactor || 0.1) * 100)}
                                    min={-10}
                                    max={100}
                                    onChange={this.onChangeLetterSpacing.bind(this, true)}
                                    onChanging={this.onChangingLetterSpacing.bind(this, true)}
                                    themeId={AppLayout.ThemeId}
                                    readOnly={!this.HasEditGrant}
                                />                  
                            </SC.FCol>
                            <SC.FCol style={{overflow : 'hidden', marginTop : '4px'}}>
                            <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px', paddingLeft : '4px'}}>
                                    <SC.SmallText>Word Space</SC.SmallText>
                                    <SliderScaleInput 
                                        largeInput
                                        value={this.model.wordSpacingFactor}
                                        onChange={this.onChangeWordSpacing.bind(this, false)}
                                        onChanging={this.onChangingWordSpacing.bind(this, false)}
                                        readOnly={!this.HasEditGrant}
                                        postfix={(
                                            <span style={{fontSize : '11px', alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px', ...SC.Styles.FontStyles.Monospace}}>EM</span>
                                        )}
                                    />
                                </SC.FRow>
                                <Slider 
                                    value={((this.model.wordSpacingFactor || 0.1) * 100)}
                                    min={0}
                                    max={150}
                                    onChange={this.onChangeWordSpacing.bind(this, true)}
                                    onChanging={this.onChangingWordSpacing.bind(this, true)}
                                    readOnly={!this.HasEditGrant}
                                    themeId={AppLayout.ThemeId}
                                />                  
                            </SC.FCol>
                            <ScaleDropdown title='Typescale'
                                largeDropdown
                                label={scalePreset.label} 
                                value={this.model.scaleType}
                                scaleValue={this.model.ratio}
                                selectedIndex={scaleIndex-1}
                                readOnly={!this.HasEditGrant}
                                SelectPreset={this.SelectExactScale}
                                onChangeCustom={this.onChangeCustomScale}
                            />                                
                        </SC.FCol>                        
                        <TextPatterns 
                            onPanelOverlay={this.props.onPanelOverlay} 
                            onUpdate={this.props.onUpdate}
                            onSelectFont={this.onSelectFont} 
                            GlobalStateId={this.props.GlobalStateId}
                            GlobalThemeId={this.props.GlobalThemeId}
                            baseSize={this.model.baseSize}
                            ratio={this.model.ratio}
                            onSelectPattern={this.props.onSelectPattern}
                            onShowMenu={this.props.onShowMenu}
                            hasEditGrant={this.HasEditGrant}
                            filterText={this.props.filterText}
                        /> 
                    </motion.div>
                }                
            </TokenGroup>
        );
        if (this.props.singleView && !this.props.noScrolls) {
            content = (
                <LeftScrollPanel>
                    {content}
                </LeftScrollPanel>
            )
        }
        return (
            <React.Fragment>
                {this.props.children}
                {content}
            </React.Fragment>            
        )
    }
}

export const BaseSizeSlider = ({title, readOnly, largeInput, style, baseSize, min=9, max=25, onChangeBaseSize, onChangingBaseSize, ...props}) => {
    return (
        <SC.FCol style={{overflow : 'hidden', ...style, paddingTop : '4px'}}>
            <SC.FRow alc jsb style={{alignItems : 'baseline', marginBottom : '-2px', paddingLeft : '4px'}}>
                <SC.SmallText>{title}</SC.SmallText>
                <SliderScaleInput 
                    value={baseSize}
                    onChange={onChangeBaseSize}
                    onChanging={onChangingBaseSize}
                    largeInput={largeInput}
                    readOnly={readOnly}
                    postfix={(
                        <span style={{alignSelf : 'center', paddingRight : '4px', paddingLeft : '4px', ...SC.Styles.FontStyles.Monospace}}>{props.unit || 'px'}</span>
                    )}
                />                
            </SC.FRow>
            <Slider 
                value={baseSize}
                min={min}
                max={max}
                onChange={onChangeBaseSize}
                onChanging={onChangingBaseSize}
                themeId={AppLayout.ThemeId}
                readOnly={readOnly}
            />                  
        </SC.FCol>
    )
}

export const ScaleSlider = ({title, label, value, scaleValue, selectedIndex, ChangeScaleValue, ChangingScaleValue, SelectPreset}) => {
    return (
        <SC.FCol style={{marginTop : '8px'}}> 
            <SC.FRow style={{marginBottom : '4px', alignItems : 'baseline'}} jsb>
                <div>{title}</div>    
                <SC.FRow f1 style={{alignItems : 'baseline'}} justifyEnd >
                    <div>{label}</div>
                    <div style={{
                        fontSize : '13px', fontWeight : 500, marginLeft : '8px'
                    }}>{value}</div>
                </SC.FRow>                
            </SC.FRow>            
            <Slider
                style={{width : '100%'}}
                value={scaleValue}
                onChange={ChangeScaleValue}
                onChanging={ChangingScaleValue}
                fixedPoints={Globals.ProjectManager.Tokens.Utils.GetPredefinedTypeScales()}
                selectedIndex={selectedIndex}
                onSelectPreset={SelectPreset}
                bigThumb
                min={10}
                max={1000}
                fitToWidth
            />                                         
        </SC.FCol>
    )
}

const renderScaleItem = (item) => {    
    return (
        <SC.FRow f1 alc jsb style={{overflow : 'hiddden', fontSize : '11px'}}>
            <SC.TextDivAbbr>{item.label}</SC.TextDivAbbr>
            {item.id !== 'custom' && <div style={{fontSize : '12px', ...SC.Styles.FontStyles.Monospace, fontWeight : 500}}>{item.value}</div>}
        </SC.FRow>
    )
}

export const ScaleDropdown = ({title, label, readOnly, largeDropdown, largeInput, max, value, scaleValue, onChangeCustom, selectedIndex, SelectPreset}) => {
    const scaleline = (
        <SC.FRow alc jsb style={{marginTop : '8px', overflow : 'visible', paddingLeft : '4px'}}>
            <SC.SmallText style={{flex : 1}}>{title}</SC.SmallText>
            <DropDownSelect 
                hasBorder            
                autoHeight
                xsmall                
                left='unset'
                style={{flex : largeDropdown ? 2 : 1}}
                items={Globals.ProjectManager.Tokens.Utils.ScalePresets}
                renderLabel={renderScaleItem}
                renderItem={renderScaleItem}
                value={value || 'majorthird'}
                onChange={SelectPreset}
                readOnly={readOnly}
            />
            {
                value === 'custom' &&
                <SliderScaleInput 
                    value={scaleValue} 
                    largeInput={largeInput}
                    onChange={onChangeCustom}
                    readOnly={readOnly}
                />
            }
        </SC.FRow>
    )

    if (value === 'custom') {
        return (
            <SC.FCol>
                {scaleline}
                <Slider 
                    value={(scaleValue * 100) - 100}
                    min={0}
                    max={max || 100}
                    readOnly={readOnly}
                    onChange={(value) => {
                        onChangeCustom(Number.parseFloat(((value / 100) + 1).toFixed(3)))
                    }}
                    onChanging={(value) => {
                        onChangeCustom(Number.parseFloat(((value / 100) + 1).toFixed(3)))
                    }}
                    themeId={AppLayout.ThemeId}
                />
            </SC.FCol>
        )
    }
    return scaleline;
}

export const SliderScaleInput = ({value, onChange, onChanging, postfix, largeInput, enableNegative, readOnly}) => {
    return (
        <NumberInput 
            value={value} 
            onChange={onChange}
            onChanging={onChanging}
            numeralDecimalScale={3}
            numeralPositiveOnly={!enableNegative}
            postfix={postfix}
            readOnly={readOnly}
            boxStyle={{
                width : largeInput ? '58px' : '44px',
                height : '24px',
                marginLeft: '4px',
                backgroundColor : SC.CurrentTheme.theme.input_back,
                opacity : readOnly ? 0.5 : 1
            }}
            style={{                                    
                width : '100%',
                textAlign : postfix ? 'right' : 'center',
                fontSize : '12px',                
                padding : 0,
                ...SC.Styles.FontStyles.Monospace
            }}
        />
    )
}

export const TypeScalePreview = ({scales, scale, reversedScales, offset = 0}) => {
    const useScales = reversedScales.filter((scale) => {return scale.px < 320});
    return (
        <div
            style={{
                display : 'grid',
                gridTemplateColumns : `repeat(${useScales.length}, auto)`,        
                justifyItems : 'center',
                alignItems : 'baseline',
                marginTop : `${(scale > 1.3 ? (scale > 1.5 ? (-50 + offset) : (-20 + (offset/2))) : 0) + (offset/3)}px`,
                gridGap : '8px',
                gridRowGap : 0
            }}
        >
            {
                useScales.map((scale, i) => {
                    return (
                        <div key={i} style={{
                            fontSize : `${scale.px}px`,
                            marginRight : '8px',
                            fontWeight : 400,  
                            transition : 'font-size 0.4s ease'                          
                        }}>a</div>
                    )
                })
            }
            {
                useScales.map((scale, i) => {
                    return (
                        <div key={'dark'+i}style={{
                            fontSize : '14px',
                            marginRight : '8px',
                            fontWeight : 500
                        }}>{scale.px} <span style={{fontSize:'12px', fontWeight : 300}}>px</span></div>
                    )
                })
            }
        </div> 
    )
}

export const SetFontTypeFace = (tokenid, font, preview, doNotLoadFont) => {
    if (tokenid)
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Font'});

    let name = font.name || `${font.family} ${font.variant}`
    let finalId = tokenid;
    if (font.provider === Strings.FONT_WEBSAE) {
        name = `${font.name} ${font.weight || 'normal'}`;
    }
    if (!finalId) {
        finalId = Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.Fonts,
            name : name,
            value : font
        });
        if (font && !doNotLoadFont) {
            if (font.provider === Strings.FONT_GOOGLE)
                FontLoader.Load(font.family, font.variant, font.url);
            else if (font.provider === Strings.CUSTOM)
                FontLoader.LoadCustomFont(Utils.ToPascalCase(font.family) + Utils.UseNullOrEmpty(font.style, 'Regular'), font.base64);
        }
    }
    else {
        const token = Globals.ProjectManager.Tokens.Font(finalId);
                    
        if (token) {
            if (!Utils.IsNotNullOrEmpty(token.name)) {
                token.name = name;
            }
            else {
                const oldfont = Globals.ProjectManager.Tokens.ValueOf({model : token} ) || {};
                if (oldfont.provider === Strings.CUSTOM && oldfont.fontId) {
                    Globals.ProjectManager.Tokens.DeleteCustomFont(oldfont.fontId);
                }
                let oldname = `${oldfont.family} ${oldfont.variant}`;
                if (oldfont.provider === Strings.FONT_WEBSAE)
                    oldname = `${oldfont.name} ${oldfont.weight || 'normal'}`;
                if (oldfont && token.name === oldname)
                    token.name = name;
            }
            Globals.ProjectManager.Tokens.SetValue({id : finalId, value : font});
        }            
    }  
    if (!doNotLoadFont) {
        Globals.ProjectManager.UpdateTokenValues();
        if (finalId === 'DefaultFont' || finalId === 'SecondaryFont')
            Events.BroadcastThrottle_400(Events.GLOBAL.DEFAULT_TOKENS_CHANGED);
        
        Events.BroadcastThrottle_400(Events.GLOBAL.TOKEN_VALUE_CHANGING);

        if (Globals.ProjectManager.SessionManager) {
            Globals.ProjectManager.SessionManager.BroadcastMessage({
                custom : true,
                loadFont : true,
                fontId : finalId
            })
        }
    }    

    return finalId;
}

export const onSelectTypeFace = (onSelected, tokenid, registerClose, onUpdate, onAddCloseBack) => {
    Events.BCE(Events.DESIGNER.BOARD.ITEM.SELECT_FONT, {
        RegisterClose : (close) => {
            onAddCloseBack && onAddCloseBack(close);
            registerClose && registerClose(close);                
        },
        onSelect : (font) => {
            
            const finalId = SetFontTypeFace(tokenid, font);

            if (onSelected)
                onSelected(finalId);
            else
                onUpdate();
        }            
    });
}