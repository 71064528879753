import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Globals
} from '../../../importer';

import TransformItemEditor from './transformItemEditor';
import {SortableList, SortableListItem} from '../../SortableContainer';
import { motion } from 'framer-motion';
import DropDownSelect from '../enum_dropdown';
import { ImagePositionEditor } from '../../../views/project/panels/right/iteminspector/styleitems/image';
import { GroupTitle } from '../../../views/project/panels/left/designsystem/common';

export const TRANSFORMTYPEITEMS = [
    {id : 'scale', label : 'Scale'},    
    {id : 'rotate', label : 'Rotate'},
    {id : 'translate', label : 'Translate'},
    {id : 'skew', label : 'Skew'}
];


export default class TransformEditor extends ReactBaseComponent {
    constructor(props) {
        super(props);

        this.AddTransform = this.AddTransform.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onSortItems = this.onSortItems.bind(this);
        this.ResetAnimation = this.ResetAnimation.bind(this);
        this.SetTransformOrigins = this.SetTransformOrigins.bind(this);        

        this.AnimationKey = Utils.Id();
        this.Ref_PreviewBox = React.createRef();
    }
    ResetAnimation(update) {
        this.AnimationKey = Utils.Id();
        update && this.RCUpdate();
    }
    AddTransform() {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const {model} = this.props;
        const transforms = model.items;
        let type;
        Utils.ForEach(TRANSFORMTYPEITEMS, (transformType, ) => {
            if (Utils.FindIndex(transforms, (item) => {return item.type === transformType.id}) < 0) {
                type = transformType.id;
                return false;
            }
        });
        const transform = {
            id : Utils.Id(),
            type : type
        };
        if (type === 'scale')
            transform.locked = true;
        transforms.push(transform);
        this.AnimationKey = Utils.Id();
        this.UpdateTransforms(transforms);
    }
    Clone(transform) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const clone = Utils.DeepClone(transform);
        clone.id = Utils.Id();
        const {model} = this.props;
        model.items.push(clone);
        this.AnimationKey = Utils.Id();
        this.UpdateTransforms(model.items);
    }
    Delete(id) {
        this.props.onBeforeChange && this.props.onBeforeChange();
        const {model} = this.props;
        Utils.Remove(model.items, (item) => {return item.id === id} );
        this.AnimationKey = Utils.Id();
        this.UpdateTransforms(model.items);
    }    
    UpdateTransforms(transforms) {
        if (this.props.offline) {
            this.props.model.items = transforms;
        }
        else {
            Globals.ProjectManager.Tokens.SetSubValue({
                id : this.props.id,
                value : transforms,
                prop : 'items',
                type : Globals.ProjectManager.Tokens.Types.Transforms
            });
            this.props.model.items = transforms;

            Globals.ProjectManager.Tokens.UpdateCurrentTheme({
                id : this.props.id,
                value : this.props.model,
                type : Globals.ProjectManager.Tokens.Types.Transforms
            })
        }
              
        this.props.onUpdate();
        this.RCUpdate();
        setTimeout(() => {
            if (this.Ref_PreviewBox.current) {
                const DN = ReactDOM.findDOMNode(this.Ref_PreviewBox.current);
                const style = window.getComputedStyle(DN);
                if (style.transform) {
                    if (this.props.offline) {
                        this.props.model.matrix = style.transform;
                    }
                    else {
                        Globals.ProjectManager.Tokens.SetSubValue({
                            id : this.props.id,
                            value : transforms,
                            prop : 'items',
                            type : Globals.ProjectManager.Tokens.Types.Transforms
                        });
                        Globals.ProjectManager.Tokens.SetSubValue({
                            id : this.props.id,
                            value : style.transform,
                            prop : 'matrix',
                            type : Globals.ProjectManager.Tokens.Types.Transforms
                        });
                        this.props.model.items = transforms;
                    }
                }
            }
        }, 200);
    }
    onChangeTransform(i, transform) {
        const {model} = this.props;
        if (!model.items)
            model.items = [transform];
        else if (model.items.length === 0)
            model.items.push(transform);
        else if (i < model.items.length && i >= 0)
            model.items[i] = Utils.DeepClone(transform);
        this.UpdateTransforms(model.items);
    }
    onRefresh() {
        this.props.onUpdate();
    }        
    onSortItems(oldIndex, newIndex) {
        const {model} = this.props;
        Utils.ChangePlace(model.items, oldIndex, newIndex);
        this.props.onUpdate();
        this.RCUpdate();
    }
    SetTransformOrigins(changes) {
        Utils.ForEach(changes, (change, ) => {
            let useProp;
            if (change.PropertyName === 'positionX') {
                useProp = 'originX';
            }
            else if (change.PropertyName === 'positionY') {
                useProp = 'originY';
            }
            else if (change.PropertyName === 'customPositionX') {
                useProp = 'customOriginX';
            }
            else if (change.PropertyName === 'customPositionY') {
                useProp = 'customOriginY';
            }

            if (change.Delete) {
                delete this.props.model[useProp];
            }
            else {
                this.props.model[useProp] = change.Value;
            }            
        });

        if (this.props.offline) {
            
        }
        else {
            Globals.ProjectManager.Tokens.SetValue({
                id : this.props.id,
                value : {
                    items : this.props.model.items,
                    originX : Utils.Get(this.props.model, 'center', 'originX'),
                    originY : Utils.Get(this.props.model, 'center', 'originY'),
                    customOriginX : Utils.Get(this.props.model, 0, 'customOriginX'),
                    customOriginY : Utils.Get(this.props.model, 0, 'customOriginY'),
                },
                type : Globals.ProjectManager.Tokens.Types.Transforms
            });
        }
        
        this.props.onUpdate();
        this.RCUpdate();
    }
    renderCustom() {
        const {model} = this.props;
        const transforms = model.items;
        if (transforms.length === 0) {
            setTimeout(() => {
                this.AddTransform();
            }, 1);
        }
        const Buttons = [];
        

        const transformTypes = Utils.DeepClone(TRANSFORMTYPEITEMS);
        transforms.map((transform, i) => {
            if (transform.type) {
                Utils.Remove(transformTypes, (item) => {return item.id === transform.type});

            }
        });

        if (transforms.length < 4) {
            Buttons.push(
                <SC.Icons.Icon_Button hasFill key={2} >
                    <SC.Div_Flex_Cell cursorPointer onClick={this.AddTransform}>
                        <SC.Icons.Icon_Plus size={20} />
                    </SC.Div_Flex_Cell>
                </SC.Icons.Icon_Button>
            )
        }        
        
        const imageProps = {
            positionX : model.originX || 'center',
            positionY : model.originY || 'center',
            customXprops : {},
            customYprops : {}
        }
        let originX = 0, originY = 0;
        if (imageProps.positionX === 'custom') {
            imageProps.customXprops = {
                value : model.customOriginX || 0
            }
            originX = Utils.px(imageProps.customXprops.value, '%');
        }
        else {
            if (imageProps.positionX === 'left')
                originX = 0;
            else if (imageProps.positionX === 'right')
                originX = '100%';
            else 
                originX = '50%';
        }
        if (imageProps.positionY === 'custom') {
            imageProps.customYprops = {
                value : model.customOriginY || 0
            }
            originY = Utils.px(imageProps.customYprops.value, '%');
        }
        else {
            if (imageProps.positionY === 'top')
                originY = 0;
            else if (imageProps.positionY === 'bottom')
                originY = '100%';
            else 
                originY = '50%';
        }

        const transformStyle = Utils.GetTransformCssAll(transforms, originX, originY, true);
        const transformOrigin = Utils.GetTransformOrigin(model);

        return (
            <SC.FCol f1 style={this.props.style}>                      
                <SC.GridBackground dark={!SC.CurrentTheme.theme.isLight} style={{height : '180px', ...SC.Styles.Flex.Cell, marginBottom : '8px', pointerEvents : 'none'}}>
                    <div                        
                        style={{width : '60px', height : '60px', borderRadius : '4px', backgroundColor : 'rgba(184, 109, 240, 0.34)', position : 'relative'}}>
                        <motion.div
                            key={this.AnimationKey}
                            ref={this.Ref_PreviewBox} 
                            style={{
                                width : '60px', height : '60px', borderRadius : '4px', backgroundColor : 'rgba(0, 125, 255, 0.5)', ...SC.Styles.Absolute,
                                transformOrigin : transformOrigin
                            }} 
                            animate={{
                                transform : transformStyle.transform                                
                            }}
                            transition={{ 
                                duration : 0.3
                            }}
                        >
                        </motion.div>     
                        <div 
                            style={{position : 'absolute', borderRadius : '99px', width : '4px', height : '4px', backgroundColor : '#fb69db', top : originY, left : originX, transform : 'translate(-50%, -50%)'}}
                        />
                    </div>                    
                </SC.GridBackground>                   
                <ImagePositionEditor 
                    {...imageProps}
                    noVariables
                    notCondensed
                    onSetPropertyValues={this.SetTransformOrigins}
                    onUpdate={this.RCUpdate}
                    labelX='Origin X'
                    labelY='Origin Y'
                />                               
                <SC.FRow jsb alc style={{marginBottom : '6px', marginRight : '-4px', marginTop : '8px'}}>
                    <SC.HeaderTitle>TRANSFORMS</SC.HeaderTitle> 
                    {Buttons}
                </SC.FRow>
                <SC.FCol f1 style={{marginLeft : '-8px', marginRight : '-12px'}}>
                    <SC.CustomScrollbars>
                        <SortableList                            
                            onSort={this.onSortItems}
                        >
                            {
                                transforms.map((transform, i) => {
                                    return (
                                        <SortableListItem 
                                            key={transform.id}
                                            draggableId={transform.id}
                                            index={i}
                                            ItemBoxType={TransformItemEditor}
                                            BoxProps={{
                                                notFirst : i > 0,
                                                transform : transform,
                                                onChange : this.onChangeTransform.bind(this, i),
                                                onChanging : this.onRefresh,
                                                onClone : this.Clone.bind(this, transform),
                                                onDelete : this.Delete.bind(this, transform.id),
                                                ownerPosition : this.props.ownerPosition,
                                                onBeforeChange : this.props.onBeforeChange,
                                                transformTypes : transformTypes,
                                                onRefresh : this.RCUpdate,
                                                onReset : this.ResetAnimation
                                            }}
                                        />
                                    )
                                })
                            }
                        </SortableList>
                    </SC.CustomScrollbars>
                </SC.FCol>
            </SC.FCol>
        );	
    }
}