import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils
} from '../../../../../../importer';

import { motion, AnimatePresence } from 'framer-motion';
import styled, {css} from 'styled-components';

import Input_Name from '../../../../../../components/editors/input_name';
import Input_Note from '../../../../../../components/editors/input_note';
import TextValue from '../../../../../../components/editors/textvalue';


import AddCancelBar from '../../../../../../components/AddCancelBar';
import { LeftScrollPanel } from '../../common';
import DragSource from '../../../../../../components/dragdrop/HoCDragSource';
import NodeNameEditor from './nameeditor';

export const TokenGroup = (props) => {
    let header;
    if (props.justContent)
        return props.children;
    if (props.forSelect) {
        header = (
            <GroupTitle 
                title={props.forSelect.title} 
                onClose={props.onClose} 
                closable 
                hasGroup={props.hasGroup}
                hasAdd={props.hasAdd} 
                addOn={props.addOn} 
                addOnLast={props.addOnLast}
                expandable={props.expandable} 
                hasSearch={props.hasSearch}
                emptyHeader={props.emptyHeader}
                noTopPadding={props.noTopPadding}
            />
        )
    }
    else {
        header = (
            <GroupTitle 
                title={props.title} 
                hideTitle={props.hideTitle} 
                hasGroup={props.hasGroup}
                hasAdd={props.hasAdd} 
                addOn={props.addOn} 
                addOnLast={props.addOnLast}
                expandable={props.expandable} 
                hasSearch={props.hasSearch}
                emptyHeader={props.emptyHeader}
                noTopPadding={props.noTopPadding}
            />
        )
    }
    return (
        <SC.FCol style={{
            marginBottom : props.last ? 0 : (props.expandable && props.expandable.expanded ? '8px' : '8px'), ...props.style, paddingBottom : (props.expandable && props.expandable.expanded ? '16px' : '8px'),
            borderBottom : props.hideTitle ? 'none' : SC.CurrentTheme.theme.border_seperator
            }}>
            {header}
            {
                (!props.collapsed || props.hideTitle) && props.children
            }            
        </SC.FCol>
    )
}

export const WidgetGroup = (props) => {
    return (
        <SC.FCol style={{...props.style, marginBottom : '4px', paddingBottom : '4px', borderBottom : props.last ? 'none' : SC.CurrentTheme.theme.border_ondark}}>
            <GroupTitle title={props.title} hasAdd={props.hasAdd} addOn={props.addOn} expandable={props.expandable} />
            {
                !props.collapsed && 
                <div style={{
                    // backgroundColor : SC.CurrentTheme.theme.back_innerpanel,
                    // padding : '8px',
                    borderRadius : '2px'
                }}>
                    {props.children}
                </div>
            }            
        </SC.FCol>
    )
}

export class GroupTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            // searchVisible : this.props.searchVisible
        }
        this.ToggleSerach = this.ToggleSerach.bind(this);
    }    
    ToggleSerach() {
        const searchVisible = !this.state.searchVisible;
        this.setState({
            searchVisible : searchVisible,
            filter : ''
        });
        this.props.hasSearch.onSearch('');
        this.props.hasSearch.onToggleSearchMode && this.props.hasSearch.onToggleSearchMode(searchVisible);
    }
    onChangeFilter(value) {
        this.lastSentSearch = value;
        this.props.hasSearch.onSearch(this.lastSentSearch);
        this.setState({filter : value})
    }
    componentDidMount() {
        setTimeout(() => {
            if (this.props.searchVisible) {
                this.setState({searchVisible : true});
            }
        }, 200);
    }
    render() { 
        const props = this.props;
        let arrow;
        let style = {...props.style};
        let title = (
            <SC.GroupLabel subGroup={props.subGroup}>{props.title}</SC.GroupLabel>
        );
        let isCollapsed = props.expandable ? true : false;
        if (props.expandable && props.expandable.expanded)
            isCollapsed = false;
        if (props.closable) {
            style = {
                ...props.style,
                padding : '2px'
            };
            title = (
                <SC.BackClosableTitle title={props.title} onClose={props.onClose} style={{flex:1}} titleStyle={props.titleStyle} />
            )
        }
        else {
            if (!props.hideTitle) {
                style = Utils.Merge({
                    paddingRight : '9px',
                    paddingLeft : '8px',
                }, props.style);
            }
            else {
                if (props.hasGroup) {
                    style.padding = '6px';
                    style.paddingBottom = 0;
                }
            }
                        
            
            if (props.expandable && !props.hideTitle) {
                title = (
                    <SC.FRow f1 alc onClick={props.expandable.onExpand} style={{cursor : 'pointer', marginRight : '8px'}}>
                        <SC.Icons.Icon_Button hasFill hasCursor>
                            <SC.Icons.Icon_Arrow_Filled expanded={props.expandable.expanded} size={20} />
                        </SC.Icons.Icon_Button>
                        {title}
                    </SC.FRow>
                )   
                style.paddingLeft = props.noPadding ?  0 : '6px';
                if (!isCollapsed)
                    style.marginBottom = '4px';
            }
        }    

        if (props.noTopPadding)
            style.paddingTop = 0;

        let searchItem, searchItemTop;
        if (this.state.searchVisible && this.props.hasSearch) {
            const useSearchItem = (
                <motion.div
                    initial={{opacity : 0.7, y : this.props.hasSearch.toLeft ? 0 : -8, x : this.props.hasSearch.toLeft ? 8 : 0}}
                    animate={{opacity : 1, y : 0, x : 0}}
                    exit={{}}
                    transition={{duration : 0.2}}
                    // style={{flex : 1}}
                >
                    <TextValue 
                        autoFocus
                        style={{
                            fontSize : '11px',
                            lineHeight : '20px',
                            paddingLeft : '4px',
                            border : SC.CurrentTheme.theme.border_brand,
                            borderRadius : '2px',
                            marginBottom : '8px',
                            width : 'unset',
                            flex : 1,
                            ...this.props.hasSearch.style
                        }}
                        value={this.state.filter}
                        onCancel={this.ToggleSerach}
                        onChange={this.onChangeFilter.bind(this)}
                    />
                </motion.div>
            )
            if (this.props.hasSearch.toLeft) {
                searchItemTop = useSearchItem;
            }
            else {
                searchItem = useSearchItem;
            }
        }

        return (
            <React.Fragment>
                <SC.FRow alc jsb style={style}>
                    {searchItemTop || !props.hideTitle && title}
                    <SC.FRow alc justifyEnd f1={props.hideTitle && !searchItemTop} style={{minHeight : props.emptyHeader ? 0 : '20px'}}>
                        {
                            props.hasSearch && 
                            <SC.Icons.Icon_Button hasFill fill={this.state.searchVisible ? SC.CurrentTheme.theme.color_brand : null} onClick={this.ToggleSerach} style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '4px'}} title={'Toggle Search Box'} >
                                <SC.Icons.Icon_Search size={14} />
                            </SC.Icons.Icon_Button>
                        }
                        {
                            props.hasGroup && 
                            <SC.Icons.Icon_Button hasFill selected={props.hasGroup.selected ? SC.CurrentTheme.theme.color_brand : null} onClick={props.hasGroup.onToggle} style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : 0}} title={'Toggle Group View'} >
                                <SC.Icons.Icon_Group size={18} />
                            </SC.Icons.Icon_Button>
                        }
                        {
                            props.onClone && !isCollapsed &&
                            <SC.Icons.Icon_Button hasFill onClick={props.onClone} style={{cursor : 'pointer', paddingLeft : '4px', paddingRight : '2px'}} title={'Clone Group'} >
                                <SC.Icons.Icon_Clone size={18} />
                            </SC.Icons.Icon_Button>
                        }
                        {props.addOn}
                        {
                            props.hasAdd && !isCollapsed &&
                            <PanelHeaderAddButton onAdd={props.hasAdd.onAdd} title={props.hasAdd.title} groupView={props.hasAdd.groupView} />
                        }
                        {props.addOnLast}
                    </SC.FRow>            
                </SC.FRow>
                {searchItem}
            </React.Fragment>
            
        )
    }
}
 
export const PanelHeaderAddButton = ({onAdd, title, groupView, style}) => {
    return (
        <SC.Icons.Icon_Button hasFill onClick={onAdd} style={{cursor : 'pointer', marginLeft : '4px', paddingRight : '2px', ...style}} title={groupView ? 'Add Group' : (title || 'Add New Token')} >
            {groupView ? <SC.Icons.Icon_Group_Plus size={12} /> : <SC.Icons.Icon_Plus size={20} />}
        </SC.Icons.Icon_Button>
    )
}

export const PanelLabel = (props) => (
    <div style={{fontSize : '12px', color : '#8e8e8e', fontWeight : 400}}>{props.children}</div>
)

export class ItemNameEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onCancel = this.onCancel.bind(this);
        this.onChangeName = this.onChangeName.bind(this);
        this.ValidateName = this.ValidateName.bind(this);
        this.SaveName = this.SaveName.bind(this);
        this.Submit = this.Submit.bind(this);
        this.onFocused = this.onFocused.bind(this);
        this.onBlur = this.onBlur.bind(this);


        this.name = this.props.name;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.id !== nextProps.id) {
            this.IsFocused = false;
            delete this.items;
        }
        if (!this.IsFocused && this.name !== nextProps.name) {
            this.name = nextProps.name;
            return true;
        }
        if (nextState.ShouldUpdate !== this.state.ShouldUpdate)
            return true;
        if (this.props.themeId !== nextProps.themeId)
            return true;
        return false;
    }
    onFocused() {
        this.IsFocused = true;
    }
    onBlur() {
        this.IsFocused = false;
        this.SaveName();
    }
    onCancel(e) {
        e && e.stopPropagation();        
        this.name = this.props.name;
    }
    onChangeName(name) {
        this.name = name;
        this.props.onChange && this.props.onChange(name);
        this.RCUpdate();
    }
    ValidateName(name) {
        if (!this.items && this.props.onGetItems) {
            this.items = this.props.onGetItems();
        }
        let result = {

        };
        if (!Utils.CheckUnique(name, this.items, 'name', this.props.model)) {
            result.error = true;
            result.message = `Name must be unique`;
        }
        return result;
    }
    SaveName() {
        if (this.props.name === this.name)
            return;
        delete this.items;
        this.props.onSaveName && this.props.onSaveName(this.name);
    }
    Submit() {
        this.SaveName();
        this.props.onSubmit && this.props.onSubmit();
    }
    renderCustom() {
        const {hasNote} = this.props;
        let noteItem;
        if (hasNote) {
            noteItem = (
                <SC.FCol style={{marginBottom : '16px'}}>
                    <PanelLabel>DESCRIPTION</PanelLabel>
                    <Input_Note 
                        wrap
                        noIcon
                        value={Utils.Get(this.props.model, '', 'note')} 
                        onChange={this.props.onSaveNote}
                    />
                </SC.FCol>
                              
            )
        }
        let style = {

        };
        if (!this.props.InputType) {
            style = {
                marginBottom : this.props.noMargin ? 0 : '24px',
                fontSize : this.props.fontSize || '16px',
                paddingLeft : 0,
                paddingBottom : '4px'
            }
        }
        if (this.props.dark)
            style.backgroundColor = SC.CurrentTheme.theme.input_back;

        return (
            <SC.FCol style={this.props.style}>
                {this.props.noLabel ? null : <PanelLabel>{this.props.label || 'NAME'}</PanelLabel>}
                <Input_Name
                    ref={(r) => this.Ref_Name = r}
                    InputType={this.props.InputType || SC.Card_Input}
                    required
                    autoFocus={this.props.autoFocus}
                    value={this.name}
                    styleProps = {{
                        onBlur : this.onBlur,
                        onFocus : this.onFocused,
                        placeholder : 'Name',
                        style : style
                    }}
                    onValidate={this.ValidateName}
                    onCancel={this.onCancel}
                    onSubmit={this.Submit}
                    onChange={this.onChangeName}
                    showError={this.props.selected}
                />
                {noteItem}
            </SC.FCol>            
        )
    }
}

export const TokenPanelHeader = (props) => {
    return (
        <SC.FRow alc jsb style={{padding : '2px', paddingLeft : props.notBackClosable ? '8px' : '2px', borderBottom : SC.CurrentTheme.theme.border_ondark, ...props.style}}>
            {
                props.notBackClosable ? 
                <SC.HeaderTitle>{props.title}</SC.HeaderTitle> : 
                <SC.BackClosableTitle title={props.title} onClose={props.onClose} style={{flex:1}}/>
            }
            {
                props.hasAddCancel && 
                <AddCancelBar onCancel={props.onCancel} onAdd={props.onAdd} onClone={props.onClone} onDelete={props.onDelete} onLight={props.onLight} cancelAndDelete={props.cancelAndDelete} />
            }
            {
                props.children
            }
        </SC.FRow>
    )
}

export const TokenGalleryView = (props) => {
    return (
        <AnimatePresence>
            <motion.div
                initial={{opacity : 0.7, x : props.onRightPanel ? 24 : -24}}
                animate={{opacity : 1, x : 0}}
                exit={{opacity : 0, x : props.onRightPanel ? 24 : -24}}
                transition={{duration : 0.2}}
                style={{width : '100%', height : '100%', ...props.style}}
            >
                <TokenPanelHeader title={props.title} hasAddCancel onClose={props.onClose} style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}} />
                <LeftScrollPanel>
                    <SC.FCol fw fh style={{padding : '10px', boxSizing : 'border-box', backgroundColor : SC.CurrentTheme.theme.back}}>
                        {props.children}
                    </SC.FCol>                
                </LeftScrollPanel>
            </motion.div>
        </AnimatePresence>
    )
}

export const TokenItemBox = styled.div`
    background-color : ${props => props.theme.back_lighter};
    margin-bottom : 4px;
    padding : 8px;
    font-size : 11px;
    transition : all 0.3s ease;
    border-radius : 4px;
    overflow : hidden;
    cursor : pointer;
    display : flex;
    flex-direction : column;
    position : relative;
    color : ${props => props.theme.font_small};
    border : ${props => props.theme.isLight ? '1px solid #f0f0f000' : props.theme.border_seperator};
    &: hover{
        background-color : ${props => props.theme.back_lightest};
        color : ${props => props.theme.font_hover};
        border : ${props => props.theme.isLight ? props.theme.border_ondark : props.theme.border_seperator};
        ${props => props.hoverStyle};
    }
    ${
        props => {
            if (props.selected) {
                return css`
                    color : ${props => props.theme.font_hover};
                `;
            }
        }
    }
`;

export const TokenItemDragHandle = (props) => {
    return (
        <TokenItemBoxHovered onMouseDown={(e) => e.stopPropagation()} {...props}>
            <SC.Icons.DragIcon xsmall style={{cursor : 'inherit'}} />
        </TokenItemBoxHovered>
    )
}
    

export const TokenItemBoxHovered = styled.div`
    opacity : 0;    
    display : flex;
    justify-content : center;
    align-items : center;
    cursor : move;
    position : absolute;
    width : 20px;
    height : 20px;
    left : 0;
    transition : opacity 0.2s ease;
    transition-delay : 0.1s;
    ${TokenItemBox}:hover &{
        opacity : 1;
    }
`;
export const TokenItemBoxHoveredName = styled.div`
    transition : transform 0.2s ease;
    transition-delay : 0.1s;
    transform : translateX(0);
    overflow: hidden;
    display : flex;
    align-items : center;
    ${TokenItemBox}:hover &{
        transform : translateX(${props => props.translate || '14px'});
    }
`;

export const DraggableTokenItem = DragSource(TokenItemBox);


export const DropFileArea = ({active}) => {
    return (
        <DropAreaContainer
            style={{marginBottom : '4px', flex : 2, minHeight : '180px'}}
            active={active}
        >
            <SC.FCol alc>
                <SC.Icons.Icon_Button>
                    <SC.Div_Flex_Cell style={{marginBottom : '8px'}}>
                        <SC.Icons.Icon_Button hasFill noHover>
                            <SC.Icons.Upload size={48} />
                        </SC.Icons.Icon_Button>
                    </SC.Div_Flex_Cell>
                </SC.Icons.Icon_Button>
                <div>Drop Files Here</div>
                <div style={{margin : '4px', fontSize : '12px'}}>OR</div>
                <div>Click to Upload</div>
            </SC.FCol>
        </DropAreaContainer>
    )
}

export const DropAreaContainer = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    font-size : 12px;
    cursor : pointer;
    padding : 8px;
    border : 1px dashed ${props => props.active ? props.theme.color_brand : '#636469'};
    transition : all 0.2s ease;
    background-color : ${props => props.theme.back};
    text-align : center;
    border-radius : 2px;
    flex : 1;
    &:hover {
        border : 1px dashed #aeaeae;
    }
`;

export class EditableName extends React.Component {
    constructor(props) {
        super(props);
        this.onEdit = this.onEdit.bind(this);
        this.Delete = this.Delete.bind(this);
        this.state = { isEditing : this.props.willEdit }
    }
    onEdit() {
        this.setState({isEditing : true});
    }
    Delete(e) {
        this.isDeleted = true;
        e.stopPropagation();
        this.props.onDelete();
    }
    onNameChange(name) {
        if (!name) {
            console.log(`ASSERT(FALSE)`);
            return;
        }
        
        this.props.onSave(name);
    }
    onValidateName(newName) {
        return true;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.props.willEdit && nextProps.willEdit) {
            this.state.isEditing = true;
        }
        return true;
    }
    render() { 
        if (this.state.isEditing) {
            return (
                <SC.FRow alc f1>
                    <NodeNameEditor
                        onClose={() => {
                            setTimeout(() => {
                                if (!this.isDeleted)
                                    this.setState({isEditing : false})
                            }, 100);                            
                            this.props.onClose && this.props.onClose();
                        }}
                        onNameChange={this.onNameChange.bind(this)}
                        onValidate={this.onValidateName.bind(this)}
                        name={this.props.value}
                        style={this.props.style}
                    />
                    {
                        this.props.onDelete && 
                        <SC.Icons.Icon_Button hasFill hasCursor style={{marginLeft : '8px'}} onMouseDown={this.Delete}>
                            <SC.Icons.Icon_Delete size={16} />
                        </SC.Icons.Icon_Button>
                    }                    
                </SC.FRow>                
            )
        }
        return (  
            <SC.TextSpanAbbr style={{flex : 1, ...this.props.style}} onDoubleClick={this.props.noDoubleClick ? null : this.onEdit} title={this.props.noDoubleClick ? '' : 'Double click to edit'} >
                {this.props.value}
            </SC.TextSpanAbbr>
        );
    }
}