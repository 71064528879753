import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,    
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../importer';

import ChromaJs from 'chroma-js';
import { ItemNameEditor, GroupTitle } from '../../common';
import InputSlider from '../../../../../../../components/editors/input_slider';
import { Seperator } from '../../../../right/iteminspector/styleitems/common';
import { ColorTokenListItem, GetcolorTokenValue, ColorTokenCardItem } from '..';
import { ListCardView } from '../../../../../../../components/light_dark_bar';

export default class ColorVariants extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.AddVariant = this.AddVariant.bind(this);

        this.baseFactor = {
            light : Utils.Get(this.props.token, 50, 'variants', this.props.name, 'light'),
            saturation : Utils.Get(this.props.token, 50, 'variants', this.props.name, 'saturation')
        }

        this.state.isListView = Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'DesignSystem', this.props.name, 'ListView');        
    }
    AddVariant() {        
        const variants = Utils.Get(this.props.token, {}, 'variants');
        const ids = Utils.Get(variants, [], this.props.name, 'ids');

        let name;
        if (this.props.isShade) {
            name = this.props.token.name + ' ' + (600 + ids.length*100);
        }
        else {
            name = this.props.token.name + ' ' + (400 - ids.length*100);
        }

        const NewId = Globals.ProjectManager.Tokens.Colors.Add({name : name, groupId : this.props.groupId, value : this.props.mainColor, mainId : this.props.id});        
        
        ids.push(NewId);
        Globals.ProjectManager.Tokens.Colors.UpdateProp({id : this.props.id, value : variants, name : 'variants'});

        this.CalculateVariants();

        this.RCUpdate();
    }
    CalculateVariants() {
        const ids = Utils.JustGet(this.props.token, [], 'variants', this.props.name, 'ids');
        const result = { items : [] };
        Globals.ProjectManager.Tokens.Colors.CalculateVariants(ids, this.props.mainColor, this.baseFactor.light, this.baseFactor.saturation, this.props.isShade, result);

        if (!this.props.newModel) {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [{
                Id : this.props.id,
                Type : Globals.ProjectManager.Tokens.Types.COLOR,
                value : this.props.mainColor
            }, ...result.items]);
        }
    }
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'DesignSystem', this.props.name, 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    onChangeFactor(prop, value) {
        this.baseFactor[prop] = value;
        const variants = Utils.Get(this.props.token, {}, 'variants');
        variants[this.props.name][prop] = value;
        Globals.ProjectManager.Tokens.Colors.UpdateProp({id : this.props.id, name : 'variants', value : variants} );

        this.CalculateVariants();

        this.RCUpdate();
    }
    OpenVariant(id) {
        this.props.onLoadVariant && this.props.onLoadVariant(id);
    }
    renderCustom() {
        const ids = Utils.JustGet(this.props.token, [], 'variants', this.props.name, 'ids');
        const coloritems = [];
        ids.map((variantId) => {
            const token = Globals.ProjectManager.Tokens.Colors.Get(variantId);
            if (token) {
                const tokenItem = {
                    id : variantId,
                    name : token.name
                };
                GetcolorTokenValue(token, tokenItem, this.props.forText, this.props.StateArray);  
                const ColorType = this.state.isListView ? ColorTokenListItem : ColorTokenCardItem;
                coloritems.push(
                    <ColorType 
                        key={variantId}
                        color={tokenItem}     
                        gradient={tokenItem.gradient}                                        
                        onSelect={this.OpenVariant.bind(this, variantId)}
                    />
                )
            }         
            else {
                Utils.RemoveEquals(ids, variantId);
            }   
        });
        const style_items = {};
        if (!this.state.isListView) {
            style_items.flexDirection = 'row';
            style_items.flexWrap = 'wrap';
        }
        return (
            <React.Fragment>
                <Seperator style={{marginBottom : '4px'}} />
                <GroupTitle 
                    title={this.props.label} 
                    subGroup
                    hasAdd={{onAdd : this.AddVariant}}            
                    addOn={(
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            style={{marginRight : '4px'}}
                            compact
                        />
                    )}
                />
                {
                    ids.length > 0 && 
                    <VariantList>
                        <VariantSlider 
                            label={this.props.isShade ? 'Darkness' : 'Brightness'}
                            value={this.baseFactor.light}
                            min={1}
                            max={100}                        
                            onChange={this.onChangeFactor.bind(this, 'light')}
                            onChanging={this.onChangeFactor.bind(this, 'light')}
                        />
                        <VariantSlider 
                            label='Saturation'
                            value={this.baseFactor.saturation}
                            min={1}
                            max={100}                        
                            onChange={this.onChangeFactor.bind(this, 'saturation')}
                            onChanging={this.onChangeFactor.bind(this, 'saturation')}
                        />
                        <SC.FCol style={{gridColumnStart : 1, gridColumnEnd : 3, marginTop : '4px', marginLeft : '-4px', marginRight : '-4px', ...style_items}}>
                            {coloritems}
                        </SC.FCol>                        
                    </VariantList>
                }                
            </React.Fragment>            
        )
    }
}

const VariantSlider = ({label, ...props}) => {
    return (        
        <React.Fragment>
            <div>{label}</div>
            <InputSlider 
                horizontal
                small
                reverse
                postFix='%'
                inputStyle={{fontSize : '11px', marginLeft : '-8px'}}
                {...props}
            />
        </React.Fragment>
    )
}

const VariantList = (props) => (
    <div style={{
        display : 'grid',
        gridTemplateColumns : 'auto 1fr',
        gridRowGap : '4px',
        gridColumnGap : '8px',
        alignItems : 'center',
        marginTop : '4px',
        paddingLeft : '10px',
        paddingRight  :'10px',
        fontSize : '11px'
    }}>
        {props.children}
    </div>
)