import React from 'react';
import '../../App.css';
import { Route, Redirect, IndexRoute, Link, HashRouter, Switch } from 'react-router-dom';
import ShortId from 'shortid';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../importer';
  
import NotificationBar from '../../components/notifications';
import OverlayManager from '../../overlays';
import DemoDesignSystem from './designsystem';
// import DemoCanvas from './canvas';
import DemoData from './data/project.json';
import FigmaDemo from './figma';

Utils.Id = () => {
    return ShortId();
}
Utils.platform = Utils.Get(window.navigator, '', 'platform');

window.Utils = Utils;

const DemoVersionId = '-M9bRKB_F5D9PhdepBJl-2';

export default class App extends ReactBaseComponent {
    constructor(props) {
        super(props);
        AppLayout.ThemeId = Utils.Id();
        
        super.ListenEvents(
            Events.SESSION,
            Events.GLOBAL.THEME.CHANGED
        );
        AppLayout.Theme.Current = AppLayout.Theme.Dark;

        this.Ref_DesignSystem = React.createRef();
        this.Ref_CanvasDemo = React.createRef();
        this.Ref_FigmaDemo = React.createRef();


        this.Post_UpdateTheme = this.Post_UpdateTheme.bind(this);
        

        this.HandleMessage = this.HandleMessage.bind(this);            
        window.addEventListener('message', this.HandleMessage);
        this.state.loading = true;
        // this.WillLoadProjectId = 'EZ7ZWOOUP';

        this.onWheel = this.onWheel.bind(this);

        window.Globals = Globals;
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (!this.state.loaded && nextState.loaded) {
            // ['Colors','Shadows','Typography','Borders','Radius','Motion','TextContents','Spacing'].map((type) => {
            //     Globals.ProjectManager.Options.Set(true, 'LeftPanel', 'DesignSystem', type, 'Expanded');
            // });

            this.Post_UpdateTheme();
        }
        return true;
    }
    
    componentDidMount() {
        super.componentDidMount();                        

        if (AppState.Loaded) {
            this.LoadDemo();
        }

        document.addEventListener("mousewheel", this.onWheel, {passive: false});
        document.addEventListener("DOMMouseScroll", this.onWheel, {passive: false});

        window.parent.postMessage({
            toolabsMessage : {
                type : 'Loaded',
                tokens : true,
                data : {
                    type : 'Loaded'
                }
            }
        }, '*');
        window.parent.postMessage({
            toolabsMessage : {
                type : 'Loaded'
            }
        }, '*');
    }
    onWheel(e) {
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        Events.DropListenAll(this);
    }
    OnEvent(Event, Params) {
        if (Event === Events.SESSION) {
            [this.LoggedIn] = Params;

            this.LoadDemo();        
        }
        else if (Event === Events.GLOBAL.THEME.CHANGED) {        
            AppLayout.ThemeId = Utils.Id();            
            this.RCUpdate();
        }
    }   
    LoadDemo() {
        
        let DemoId;
        // DemoId = window.GetStorage().getItem('DemoProjectId');        
        if (!DemoId || DemoId !== DemoVersionId) {
            this.CreateDemoProject();
        }
        else {
            Globals.ProjectManager.Load({ProjectId : DemoId}).then((result) => {
                this.setState({loaded : true});
            });
        }                
    }
    CreateDemoProject() {
        AppState.GetDataApi().import_project(
            DemoData.Name,
            DemoData.Model,
            DemoData.Data,
            null, 
            DemoVersionId
        ).then((result) => {
            // window.GetStorage().setItem('DemoProjectId', result.id);
            Globals.ProjectManager.Load({ProjectId : result.id}).then((result) => {
                // InitializeNewProjectTokens();
                this.setState({loaded : true});
            });  
        });
        
    }
    HandleMessage(e) {
        if (e.data && this.state.loaded) {
            if (e.data.toolabstokens) {
                const {type} = e.data.toolabstokens;

                if (type === 'SetState') {
                    if (e.data.toolabstokens.state) {
                        Utils.ForEach(e.data.toolabstokens.state, (varId, stateId) => {
                            Globals.ProjectManager.States.SetSelectedVariation(stateId, varId );
                        });
                    }
                }
                else if (type === 'SelectModule') {
                    if (this.Ref_DesignSystem.current) {
                        this.Ref_DesignSystem.current.onChangeTokenType(e.data.toolabstokens.module);
                    }
                }
            }
            else if (e.data.toolabscanvas) {
                const {type} = e.data.toolabscanvas;

                if (type === 'PlayInitial') {
                    if (this.Ref_CanvasDemo.current && this.Ref_CanvasDemo.current.Ref_Canvas.current)
                        this.Ref_CanvasDemo.current.Ref_Canvas.current.BoardRef.RunInitialAnimation();
                }
                else if (type === 'SetState') {
                    if (e.data.toolabscanvas.state) {
                        Utils.ForEach(e.data.toolabscanvas.state, (varId, stateId) => {
                            Globals.ProjectManager.States.SetSelectedVariation(stateId, varId );
                        });
                    }
                }           
            }  
            else if (e.data.toolabsFigma) {
                if (this.Ref_FigmaDemo.current) {
                    this.Ref_FigmaDemo.current.HandleMessage(e.data.toolabsFigma);
                }
            }            
        }        
    }
    Post_UpdateTheme() {
        const typeScale = Globals.ProjectManager.Tokens.Typescale.Get();        
        let typescaleName;
        const typeScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === typeScale.scaleType});
        if (typeScaleItem)
            typescaleName = typeScaleItem.label;

        const timeScale = Globals.ProjectManager.Tokens.TimeScale.Get();        
        let timescaleName;
        const timeScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === timeScale.scaleType});
        if (timeScaleItem)
            timescaleName = timeScaleItem.label;

        const spaceScale = Globals.ProjectManager.Tokens.SpaceScale.Get();
        let spacescaleName;
        const spaceScaleItem = Utils.Find(Globals.ProjectManager.Tokens.Utils.ScalePresets, (item) => {return item.id === spaceScale.scaleType});
        if (spaceScaleItem)
            spacescaleName = spaceScaleItem.label;


            Globals.ProjectManager.CurrentTheme && window.parent.postMessage({
            toolabsMessage : {
                type : 'UpdateTheme',
                tokens : true,
                data : {
                    theme : Globals.ProjectManager.CurrentTheme.Theme[Globals.ProjectManager.CurrentState],
                    themeId : Globals.ProjectManager.CurrentTheme.ThemeId,
                    typeScale : {
                        ratio : typeScale.ratio,
                        baseSize : typeScale.baseSize,
                        name : typescaleName
                    },
                    timeScale : {
                        ratio : timeScale.ratio,
                        baseSize : timeScale.baseSize,
                        name : timescaleName
                    },
                    spaceScale : {
                        ratio : spaceScale.ratio,
                        baseSize : spaceScale.baseSize,
                        name : spacescaleName
                    },
                }
            }
        }, '*');
    }
    renderCustom() {

        if (!this.state.loaded)
            return (
                <SC.Div_Full_Center>
                    <SC.Loading_Icon />
                </SC.Div_Full_Center>
            );

        
        return (
            <HashRouter>
                <Switch>
                    <Route exact path="/" render={
                            props => {
                                return (
                                    <SC.AppTheme themeName={AppLayout.Theme.Current}>
                                        <SC.FCol fw fh style={{position : 'relative', maxWidth : '400px'}}>
                                            <DemoDesignSystem 
                                                onUpdateTheme={this.Post_UpdateTheme}
                                                ref={this.Ref_DesignSystem}
                                            />
                                            <NotificationBar compact />
                                            <OverlayManager ref={(om) => AppState.OverlayManager.Instance = om} compact />
                                        </SC.FCol>
                                    </SC.AppTheme>
                                )
                            }
                        }
                    />
                    {/* <Route exact path="/canvas" render={
                            props => {
                                return (
                                    <SC.AppTheme themeName={AppLayout.Theme.Current}>
                                        <SC.FCol fw fh style={{position : 'relative'}}>
                                            <DemoCanvas ref={this.Ref_CanvasDemo} />
                                            <NotificationBar compact />
                                            <OverlayManager ref={(om) => AppState.OverlayManager.Instance = om} compact />
                                        </SC.FCol>
                                    </SC.AppTheme>
                                )
                            }
                        }
                    /> */}
                    <Route exact path="/figma" render={
                            props => {
                                AppLayout.Theme.Current = 'Light';
                                return (
                                    <SC.AppTheme themeName='Light'>
                                        <SC.FCol fw fh style={{position : 'relative', maxWidth : '400px'}}>
                                            <FigmaDemo 
                                                ref={this.Ref_FigmaDemo} 
                                                onUpdateTheme={this.Post_UpdateTheme}
                                            />
                                            <NotificationBar compact />
                                            <OverlayManager ref={(om) => AppState.OverlayManager.Instance = om} compact />
                                        </SC.FCol>
                                    </SC.AppTheme>
                                )
                            }
                        }
                    />
                </Switch>
            </HashRouter>   
        )
    }
}
