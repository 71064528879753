import Platforms from '../Platforms';
import DataType from '../datatypes';

const Props = {
    FlexValues : {
        FLEX : 'flex',
        FLEXSTART : 'flex-start',
        FLEXEND : 'flex-end',
        FLEXCENTER : 'center',
        FLEXSPACEBETWEEN : 'space-between',
        FLEXSPACEAROUND : 'space-around',
        FLEXSPACEEVENLY : 'space-evenly',
        FLEXBASELINE : 'baseline',
        FLEXSTRETCH : 'stretch',
        FLEXJUSTIFY : 'justifyContent',
        FLEXALIGN : 'alignItems',
        FLEXALIGNSELF : 'alignSelf',
        FLEXALIGNCONTENT : 'alignContent',
        FLEXJUSTIFYSELF : 'justifySelf',
        FLEXWRAP : 'flexWrap',
        NOWRAP : 'nowrap',
        WRAP : 'wrap',
        WRAPREVERSE : 'wrap-reverse',
        ROW : 'row',
        ROWREVERSE : 'row-reverse',
        COLUMNREVERSE : 'column-reverse',
        COLUMN : 'column',
        FLEXDIRECTION : 'flexDirection'
    },
    FlexLabels : {
             
    },
    text : {
        name : 'text',
        type : DataType.string,
        label : 'Text'
    },
    truncate : {
        name : 'truncate',
        type : DataType.boolean,
        label : 'Truncate'
    },
    placeholder : {
        name : 'placeholder',
        type : DataType.string,
        label : 'Placeholder'
    },
    x : {
        name : 'x',
        type : DataType.size,
        label : 'x'
    },
    y : {
        name : 'y',
        type : DataType.size,
        label : 'y'
    },
    layoutIndex : {
        name : 'layoutIndex',
        label : 'Layout Index',
        type : DataType.number
    },
    layout : {
        name : 'layout',
        label : 'Layout',
        type : DataType.enum,
        values : {
            Default : {
                Id : 'Default',
                Label : 'Default'
            },
            Free : {
                Id : 'Free',
                Label : 'Free'
            },
            CssGrid : {
                Id : 'grid'
            },
            Flex : {
                Id : 'flex',
                Label : 'Flex',                
                valueMap : {
                    flexStart : 'flex-start',
                    flexEnd : 'flex-end',
                    flexCenter : 'center',
                    flexSpaceBetween : 'space-between',
                    flexSpaceAround :'space-around',
                    flexSpaceEvenly : 'space-evenly',
                    flexBaseLine : 'baseline',
                    flexStretch : 'stretch',
                    nowrap : 'nowrap',
                    wrap : 'wrap',
                    wrapReverse : 'wrap-reverse',
                    row : 'row',
                    rowReverse : 'row-reverse',
                    column : 'column',
                    columnReverse : 'column-reverse'
                },
                convertValue(value) {                    
                    return this.valueMap[value] || value;
                }
            }
        }
    },
    value : {
        name : 'value',
        label : 'Value',
        type : DataType.string
    },
    Component : {
        name : 'Component',
        label : 'Component',
        type : DataType.SubComponent
    },
    MetaSvg : {
        name : 'MetaSvg',
        label : 'Meta Svg',
        type : DataType.MetaSvg
    },
    hidden : {
        name : 'hidden',
        label : 'Hidden',
        type : DataType.boolean
    },
    Element : {
        name : 'Element',
        label : 'Element Template',
        type : DataType.ElementTemplate
    },
    StyleSets : {
        name : 'StyleSets',
        label : 'Style Sets',
        type : DataType.StyleSets
    },
    SizeLocked : {
        name : 'SizeLocked',
        label : 'Locked',
        type : DataType.boolean
    },
    AustoScale : {
        name : 'AustoScale',
        label : 'Auto Scale',
        type : DataType.boolean
    },
    FixedSize : {
        name : 'FixedSize',
        label : 'Fixed Size',
        type : DataType.boolean
    },
    InputType : {
        name : 'InputType',
        css : 'type',
        label : 'Type',
        type : DataType.enum,
        values : {
            Text : {
                Id : 'text',
                Label : 'Text'
            },
            TextArea : {
                Id : 'textarea',
                Label : 'Textarea'
            },
            Number : {
                Id : 'number',
                Label : 'Number'
            },
            Password : {
                Id : 'password',
                Label : 'Password'
            },
            Checkbox : {
                Id : 'checkbox',
                Label : 'Checkbox'
            },
            Radio : {
                Id : 'radio',
                Label : 'Radio'
            }
        }
    },
    MetaImage : {
        name : 'MetaImage',
        label : 'Meta Image',
        type : DataType.MetaImage,
        Url : {
            name : 'Url',
            label : 'Url',
            type : DataType.string
        }
    },
    backgroundImage : {
        name : 'backgroundImage',
        label : 'Meta Image',
        type : DataType.MetaImage,
        Url : {
            name : 'Url',
            label : 'Url',
            type : DataType.string
        }
    },
    MetaIcon : {
        name : 'MetaIcon',
        label : 'Meta Icon',
        type : DataType.MetaIcon        
    },
    Provider : {
        name : 'Provider',
        label : 'Provider',
        type : DataType.Provider
    },
    WidgetOptions : {
        name : 'WidgetOptions',
        label : 'Widget Options',
        type : DataType.WidgetOptions
    },
    TokenOptions : {
        name : 'TokenOptions',
        label : 'Token Widget Options',
        type : DataType.TokenOptions
    },
    GenericOptions : {
        name : 'GenericOptions',
        label : 'Generic Options',
        type : DataType.GenericOptions
    },
    SvgIcon : {
        name : 'SvgIcon',
        label : 'Svg Icon',
        type : DataType.SvgIcon
    },
    boxShadow : {
        name : 'boxShadow',
        label : 'Box Shadow',
        type : DataType.BoxShadow,
        subProps : {
            x : {
                name : 'x',
                type : DataType.size
            },
            y : {
                name : 'y',
                type : DataType.size
            },
            blur : {
                name : 'blur',
                type : DataType.size
            },
            spread : {
                name : 'spread',
                type : DataType.size
            },
            color : {
                name : 'color',
                type : DataType.color
            }
        }
    },
    textShadow : {
        name : 'textShadow',
        label : 'Text Shadow',
        type : DataType.TextShadow,
        subProps : {
            x : {
                name : 'x',
                type : DataType.size
            },
            y : {
                name : 'y',
                type : DataType.size
            },
            blur : {
                name : 'blur',
                type : DataType.size
            },
            color : {
                name : 'color',
                type : DataType.color
            }
        }
    },
    transform : {
        name : 'transform',
        label : 'Transform',
        type : DataType.Transform,
        propsList : ['translateX', 'translateY', 'skewX', 'skewY', 'rotateX', 'rotateY', 'rotateZ', 'scaleX', 'scaleY'],
        subProps : {
            transformOrigin : {
                name : 'transformOrigin',
                label : 'Origin',
                type : DataType.enum,                
            },
            translateX : {
                name : 'translateX',
                label  :'Translate X',
                type : DataType.size
            },
            translateY : {
                name : 'translateY',
                label  :'Translate Y',
                type : DataType.size
            },
            scaleX : {
                name : 'scaleX',
                label : 'Scale X',
                type : DataType.number
            },
            scaleY : {
                name : 'scaleY',
                label : 'Scale Y',
                type : DataType.number
            },
            rotateZ : {
                name : 'rotateZ',
                label : 'Rotate',
                type : DataType.angle
            },
            rotateX : {
                name : 'rotateX',
                label : 'Rotate X',
                type : DataType.angle
            },
            rotateY : {
                name : 'rotateY',
                label : 'Rotate Y',
                type : DataType.angle
            },
            skewX : {
                name : 'skewX',
                label : 'Skew X',
                type : DataType.angle
            },
            skewY : {
                name : 'skewY',
                label : 'Skew Y',
                type : DataType.angle
            }
        }
    },  
    filter : {
        name : 'filter',
        label : 'Filters',
        type : DataType.Filter,
        propsList : ['blur', 'opacity', 'grayscale', 'sepia', 'invert', 'hue-rotate', 'brightness', 'contrast', 'saturate'],
        subProps : {
            blur : {
                name : 'blur',
                label : 'Blur',
                type : DataType.size,                
            },
            opacity : {
                name : 'opacity',
                label  :'Opacity',
                type : DataType.number,
                percent : true
            },
            grayscale : {
                name : 'grayscale',
                label  :'Grayscale',
                type : DataType.number,
                percent : true
            },
            sepia : {
                name : 'sepia',
                label  :'Sepia',
                type : DataType.number,
                percent : true
            },
            invert : {
                name : 'invert',
                label  :'Invert',
                type : DataType.number,
                percent : true
            },
            'hue-rotate' : {
                name : 'hue-rotate',
                label  :'Hue Rotate',
                type : DataType.angle
            },
            brightness : {
                name : 'brightness',
                label  :'Brightness',
                type : DataType.number,
                percent : true
            },
            contrast : {
                name : 'contrast',
                label  :'Contrast',
                type : DataType.number,
                percent : true
            },
            saturate : {
                name : 'saturate',
                label  :'Saturate',
                type : DataType.number,
                percent : true
            },
        }
    },    
    count : {
        name : 'count',
        type : DataType.number
    },
    RepeatType : {
        name : 'RepeatType',
        type : DataType.string
    },
    Data : {
        name : 'Data',
        type : DataType.list
    },
    Widget : {
        Map : {
            latitude : {
                name : 'latitude',
                type : DataType.string
            },
            longitude : {
                name : 'longitude',
                type : DataType.string
            },
            zoom : {
                name : 'zoom',
                type : DataType.number
            }
        },
        Barcode : {
            barWidth : {
                name : 'barWidth',
                type : DataType.number
            }
        },
        Video : {
            Id : {
                name : 'Id',
                type : DataType.string
            }
        }
    }
};

Props.FlexLabels[Props.FlexValues.FLEXSTART] = 'Start';
Props.FlexLabels[Props.FlexValues.FLEXEND] = 'End';
Props.FlexLabels[Props.FlexValues.FLEXCENTER] = 'Center';
Props.FlexLabels[Props.FlexValues.FLEXSPACEBETWEEN] = 'Space Between';
Props.FlexLabels[Props.FlexValues.FLEXSPACEAROUND] = 'Space Around';
Props.FlexLabels[Props.FlexValues.FLEXSPACEEVENLY] = 'Space Evenly';
Props.FlexLabels[Props.FlexValues.FLEXBASELINE] = 'Baseline';
Props.FlexLabels[Props.FlexValues.FLEXSTRETCH] = 'Stretch';
Props.FlexLabels[Props.FlexValues.FLEXJUSTIFY] = 'Justify Content';
Props.FlexLabels[Props.FlexValues.FLEXALIGN] = 'Align Items';
Props.FlexLabels[Props.FlexValues.FLEXALIGNSELF] = 'Align Self';
Props.FlexLabels[Props.FlexValues.FLEXALIGNCONTENT] = 'Align Content';
Props.FlexLabels[Props.FlexValues.FLEXJUSTIFYSELF] = 'Justify Self';
Props.FlexLabels[Props.FlexValues.FLEXWRAP] = 'Wrap';
Props.FlexLabels[Props.FlexValues.NOWRAP] = 'No Wrap';
Props.FlexLabels[Props.FlexValues.WRAP] = 'Wrap';
Props.FlexLabels[Props.FlexValues.WRAPREVERSE] = 'Wrap Reverse';
Props.FlexLabels[Props.FlexValues.ROW] = 'Row';
Props.FlexLabels[Props.FlexValues.ROWREVERSE] = 'Row Reverse';
Props.FlexLabels[Props.FlexValues.COLUMNREVERSE] = 'Column Reverse';
Props.FlexLabels[Props.FlexValues.COLUMN] = 'Column';
Props.FlexLabels[Props.FlexValues.FLEXDIRECTION] = 'flexDirection';

export default Props;

export const CommonProperties = [
    Props.hidden.name,
    Props.Element.name,
    Props.transform.name,
    Props.StyleSets.name,
    Props.SizeLocked.name,
    Props.AustoScale.name,
    Props.FixedSize.name,
    Props.layoutIndex.name
];