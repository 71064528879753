import React from 'react';
import BaseItem, { BuildItemTransitions } from './BaseItem';
import {Utils} from '../importer';
import SubComponent from './SubComponent';
import { GetSubItems } from './Container';

export default class SubComponentItem extends React.Component
{
    constructor(props) {
        super(props);
        this.onChildEvent = this.onChildEvent.bind(this);
        this.onChildModelChange = this.onChildModelChange.bind(this);
        this.renderSlot = this.renderSlot.bind(this);
        this.renderCollectionItem = this.renderCollectionItem.bind(this);

        this.Load(this.props);
    }    
    BindToParentModel(ModelId, ParentModelId) {
        const bindings = Utils.Get(this, [], 'BoundToParentModels', ModelId);
        if (bindings.indexOf(ParentModelId) < 0)
            bindings.push(ParentModelId);
    }
    GetParentBoundModels(ModelId) {
        return Utils.Get(this, [], 'BoundToParentModels', ModelId);
    }
    Load(props) {
        const {MetaItem} = props;

        this.Statics = {
            style : {},
            props : {},
            tokens : {},
            modelProps : {},
            transitions : {}
        }
        this.Models = {};
        this.Variations = {};
        this.BoundToParentModles = {};

        Utils.ForEach(props.StyleState, (StyleState, i) => {
            this.Statics.style = Utils.Merge(this.Statics.style, Utils.JustGet(MetaItem, {}, 'Styles', 'Static', StyleState.Global, StyleState.Component));
            this.Statics.props = Utils.Merge(this.Statics.props, Utils.JustGet(MetaItem, {}, 'Props', 'Static', StyleState.Global, StyleState.Component));
    
            const Resets = Utils.JustGet(MetaItem, {}, 'Reset', StyleState.Global, StyleState.Component);
            Utils.ForEach(Resets, (isReset, propName) => {
                if (isReset) {
                    delete this.Statics.style[propName];
                    delete this.Statics.props[propName];
                }
            });
        });

        const HandledStyles = {};

        Utils.ForEach(props.ReverseStyleState, (StyleState, i) => {   
            const hidden_modelId = Utils.JustGet(MetaItem, null, 'Models', StyleState.Global, StyleState.Component, 'hidden');
            if (hidden_modelId && Utils.IsNotNull(props.Models[hidden_modelId])) {
                this.Statics.props.hidden = Utils.IsTrue(props.Models[hidden_modelId]);        
                this.Statics.modelProps.hidden = hidden_modelId;
            }

            const Id = Utils.JustGet(MetaItem, null, 'Props', 'Static', StyleState.Global, StyleState.Component, 'Id');
            if (!this.ComponentId) {
                this.ComponentId = Id;
            }
            else if (Id && Id !== this.ComponentId) {
                return false;        
            }
            const Models = Utils.JustGet(MetaItem, null, 'Props', 'Static', StyleState.Global, StyleState.Component, 'Models');
            const Variations = Utils.JustGet(MetaItem, null, 'Props', 'Static', StyleState.Global, StyleState.Component, 'Variations');
            this.Models = Utils.Merge(Models, this.Models);
            this.Variations = Utils.Merge(Variations, this.Variations);

            Utils.BorderStyles.Sides.map((side) => {
                const spacings = [Utils.BorderStyles.propPadding(side), Utils.BorderStyles.propMargin(side)];
                spacings.map((prop) => {
                    if (!HandledStyles[prop]) {
                        const tokenId = Utils.JustGet(MetaItem, null, 'Tokens', StyleState.Global, StyleState.Component, prop);
                        if (tokenId) {
                            const themeValue = Utils.JustGet(props.Theme, null, tokenId);
                            if (themeValue) {
                                this.Statics.style[prop] = themeValue;
                                HandledStyles[prop] = true;                                
                            }                            
                        }
                    }
                });
            });

            BuildItemTransitions({
                MetaItem : MetaItem,
                StyleState : StyleState,
                props : props,
                transitions : this.Statics.transitions
            })
        });
        this.ModelValues = {};
        Utils.ForEach(this.Models, (ModelData, ModelId) => {            
            this.UpdateModelValue(ModelId, props);                  
        });
    }
    UpdateModelValue(ModelId, props) {
        const ModelData = this.Models[ModelId];
        if (ModelData.Type === 'image') {
            let url;
            if (ModelData.ModelId && Utils.IsNotNull(props.Models[ModelData.ModelId])) {
                this.BindToParentModel(ModelId, ModelData.ModelId);
                url = Utils.JustGet(props.Models[ModelData.ModelId], null, 'url');
            }
            else if (ModelData.TokenId) {
                if (props.Theme[ModelData.TokenId]) {
                    url = props.Theme[ModelData.TokenId];                    
                }
            }
            else {
                url = Utils.JustGet(ModelData, null, 'value', 'url');
            }
            this.ModelValues[ModelId] = {
                url : url
            }
            if (ModelData.options) {                
                let positionX = Utils.JustGet(ModelData.options.positionX, null, 'value');
                let positionY = Utils.JustGet(ModelData.options.positionY, null, 'value');
                if (positionX === 'custom') {
                    const modelPositionX = Utils.JustGet(ModelData.options.customPositionX, null, 'ModelId');
                    if (modelPositionX && Utils.IsNotNullOrEmpty(props.Models[modelPositionX])) {
                        positionX = props.Models[modelPositionX];
                        this.BindToParentModel(ModelId, modelPositionX);
                    }
                    else
                        positionX = Utils.JustGet(ModelData.options.customPositionX, null, 'value');
                    if (Utils.IsNotNull(positionX) && positionX !== 'center' && Utils.IsNumber(positionX))
                        positionX += '%';
                }
                if (positionY === 'custom') {
                    const modelPositionY = Utils.JustGet(ModelData.options.customPositionY, null, 'ModelId');
                    if (modelPositionY && Utils.IsNotNullOrEmpty(props.Models[modelPositionY])) {
                        positionY = props.Models[modelPositionY];
                        this.BindToParentModel(ModelId, modelPositionY);
                    }
                    else
                        positionY = Utils.JustGet(ModelData.options.customPositionY, null, 'value');
                    if (Utils.IsNotNull(positionY) && positionY !== 'center' && Utils.IsNumber(positionY))
                        positionY += '%';
                }
                this.ModelValues[ModelId].backgroundPosition = `${positionX || 'center'} ${positionY || 'center'}`;

                const backgroundSize = Utils.JustGet(ModelData.options.backgroundSize, null, 'value');;
                if (backgroundSize) {
                    if (backgroundSize === 'custom') {
                        let backgroundWidth = Utils.JustGet(ModelData.options.backgroundWidth, null, 'value');
                        let backgroundHeight = Utils.JustGet(ModelData.options.backgroundHeight, null, 'value');

                        const modelIdWidth = Utils.JustGet(ModelData.options.backgroundWidth, null, 'ModelId');
                        if (modelIdWidth && Utils.IsNotNullOrEmpty(props.Models[modelIdWidth])) {
                            backgroundWidth = props.Models[modelIdWidth];
                            this.BindToParentModel(ModelId, modelIdWidth);
                        }
                        if (Utils.IsNotNull(backgroundWidth) && backgroundWidth !== 'auto' && Utils.IsNumber(backgroundWidth))
                            backgroundWidth += '%';

                        const modelIdHeight = Utils.JustGet(ModelData.options.backgroundHeight, null, 'ModelId');
                        if (modelIdHeight && Utils.IsNotNullOrEmpty(props.Models[modelIdHeight])) {
                            backgroundHeight = props.Models[modelIdHeight];
                            this.BindToParentModel(ModelId, modelIdHeight);
                        }
                        if (Utils.IsNotNull(backgroundHeight) && backgroundHeight !== 'auto' && Utils.IsNumber(backgroundHeight))
                            backgroundHeight += '%';
                          
                        this.ModelValues[ModelId].backgroundSize = (backgroundWidth || 'auto') + ' ' + (backgroundHeight || 'auto');
                    }
                    else
                        this.ModelValues[ModelId].backgroundSize = backgroundSize;
                } 
            } 
        }      
        else {
            if (ModelData.ModelId && Utils.IsNotNull(props.Models[ModelData.ModelId])) {
                this.ModelValues[ModelId] = props.Models[ModelData.ModelId];
                this.BindToParentModel(ModelId, ModelData.ModelId);
            }
            else if (ModelData.TokenId) {
                this.ModelValues[ModelId] = props.Theme[ModelData.TokenId];
            }              
            else 
                this.ModelValues[ModelId] = ModelData.value;
        }        
    }
    onChildModelChange(childmodelid, value) {        
        if (this.props.onSetModelValue) {
            const Parentmodels = this.GetParentBoundModels(childmodelid);
            Parentmodels.map((ParentModelId) => {
                this.props.onSetModelValue(ParentModelId, value);
            })
        }        
    }    
    onChildEvent(EventName, ActionId, PassParams, e) {        
        if (!this.StateStatics.events)
            return;
        if (this.StateStatics.events[EventName])
            this.StateStatics.events[EventName](PassParams, e);
        else if (ActionId) {
            const handlerName = `on${ActionId.charAt(0).toUpperCase() + ActionId.slice(1)}`;
            if (this.StateStatics.events[handlerName])
                this.StateStatics.events[handlerName](PassParams, e);
        }            
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.StateId !== nextProps.StateId || this.props.ThemeId !== nextProps.ThemeId) {
            this.Load(nextProps);
            return true;
        }
        if (!Utils.IsEqual(this.props.Models, nextProps.Models)) {
            
            Utils.ForEach(this.BoundToParentModels, (ParentModelIds, ModelId) => {
                if (this.Models[ModelId] && this.Models[ModelId].Type === 'image') {
                    this.UpdateModelValue(ModelId, nextProps);
                }
                else {
                    Utils.ForEach(ParentModelIds, (ParentModelId, ) => {
                        this.ModelValues[ModelId] = nextProps.Models[ParentModelId];
                    });
                }                                
            });
            Utils.ForEach(this.Statics.modelProps, (modelId, propName) => {
                this.Statics.props[propName] = nextProps.Models[modelId];
            });

            if (this.props.MetaItem.Slots) {
                this.ModelValues = Utils.Merge(this.ModelValues, nextProps.Models);
            }

            return true;
        }
        return true;
    }
    renderSlot({slotId, containerStyle}) {
        const SlotItemId = Utils.JustGet(this.props.MetaItem, null, 'Slots', slotId);
        if (SlotItemId) {
            const SlotItem = Utils.Find(this.props.MetaItem.SubItems, (item) => {return item.Id === SlotItemId});
            if (SlotItem) {
                return GetSubItems(
                    SlotItem.SubItems, this.props, {}, this.RootStateId
                );
            }
        }
        return (
            <div style={{color : 'green'}}>
                {slotId}
            </div>
        )
    }
    renderCollectionItem(slide) {
        if (slide.ItemId) {
            const SlideItem = Utils.Find(this.props.MetaItem.SubItems, (item) => {return item.Id === slide.ItemId});
            if (SlideItem) {
                return GetSubItems(
                    [SlideItem], this.props, {
                        OuterStyle : {
                            flex : 1,
                            alignSelf : 'stretch'
                        }
                    }, this.RootStateId
                );
            }
        }
        return (
            <div>
                Slider {slide.Id}
            </div>
        )
    }
    render() {        
        if (!this.ComponentId)
            return null;            

        if (Utils.IsTrue(Utils.Get(this.Statics, false, 'props', 'hidden'))) {
            return null;
        }

        const style = {};
        return (
            <SubComponent 
                ref={(r) => this.Ref_Instance = r}
                Id={this.ComponentId}
                GetComponentMetaData={this.props.GetComponentMetaData}
                GlobalVariations={this.props.GlobalVariations}
                Variations={this.Variations}                    
                Models={Utils.DeepClone(this.ModelValues)}
                ThemeId={this.props.ThemeId}
                Theme={this.props.Theme}                
                SystemStateArray={this.props.SystemStateArray}
                style={this.Statics.style}
                transitions={this.Statics.transitions}
                GetElementTemplate={this.props.GetElementTemplate}
                onEvent={this.onChildEvent}
                onChangeTheme={this.props.onChangeTheme}
                onModelChanged={this.onChildModelChange}
                IsChildComponent
                AutoScale={this.props.AutoScale}
                onGetModelValue={this.props.onGetModelValue}
                renderSlot={this.renderSlot}
                renderCollectionItem={this.renderCollectionItem}
                slides={this.props.MetaItem.Slides}
            />               
        );
    }
}