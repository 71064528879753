import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    Utils,
    UIUtils,
    AppState
} from '../../importer';
import styled, {css} from 'styled-components';

export default class TextInput extends React.Component
{
    constructor(props) {
        super(props);

        this.Ref_Input = React.createRef();
        this.onChange = this.onChange.bind(this);
        this.state = {
            value : Utils.UseNull(this.props.value, '')
        }

        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.OnKeyUp = this.OnKeyUp.bind(this);
    }
    componentDidMount() {
        if (this.props.autoFocus) {
            setTimeout(() => {
                this.Ref_Input.current && this.Ref_Input.current.focus();
                if (this.props.autoSelect)
                    this.Ref_Input.current && this.Ref_Input.current.select();
            }, 10);
        }
    }    
    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.autoFocus && this.props.autoFocus) {
            this.Ref_Input.current && this.Ref_Input.current.focus();
        }
    }
    onFocus(e) {
        this.isFocused = true;
        AppState.Designer.SetEditingText('textinput');;
        this.props.onFocus && this.props.onFocus(e);
    }
    onBlur(e) {
        AppState.Designer.SetEditingText(false);;
        this.isFocused = false;
        this.props.onChange && this.props.onChange(this.state.value);
    }
    OnKeyUp(e) {
        e.stopPropagation();
        if (this.props.onSubmit && (e.charCode === 13 || e.keyCode === 13)) {
            this.props.onSubmit(this.state.value);
        }
        else if (this.props.onCancel && e.keyCode === 27) {
            this.props.onCancel();
        }
        
        return true;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (!this.isFocused) {
            if (nextProps.value !== this.props.value) {
                this.state.value = nextProps.value;
                return true;
            }
        }
        if (this.state.value !== nextState.value)
            return true;

        if (this.props.RenderId !== nextProps.RenderId || this.props.themeId !== nextProps.themeId)
            return true;
            
        return false;
    }
    onChange(e) {        
        this.setState({value : e.target.value});
        if (this.props.onChanging)
            this.props.onChanging(e.target.value);
    }    
    
    render() {        
        const style = {...this.props.style};
        if (this.props.dark)
            style.backgroundColor = SC.CurrentTheme.theme.input_back;
        return (
            <SC.Form_Input 
                ref={this.Ref_Input}                 
                value={this.state.value} 
                placeholder={this.props.placeholder}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onMouseDown={(e) => {
                    e.stopPropagation();
                    return false;
                }}
                style={style}
                spellCheck={false}
                onKeyUp={this.OnKeyUp}
            />
        );
    }
}