import React from 'react';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import { renderEngineIcon } from '../../SvgIcon';
import { motion } from 'framer-motion';

class Generic_Carousel extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();     

        this.NavigateTo = this.NavigateTo.bind(this);

        if (this.props.slides) {
            this.slides = Utils.JustGet(this.props.slides, [], 'items');
            const activeId = Utils.JustGet(this.props.slides, null, 'activeSlide');
            const index = Math.max(0, Utils.FindIndex(this.slides, (slide) => {return slide.Id === activeId}));
            this.state.active = index || 0;
        }
        else {
            this.slides = [];
            this.state.active = 0;
        }        
        this.state.previous = this.GetPrevious(this.slides.length, this.state.active);
    }
    componentDidMount() {
        super.componentDidMount();
        this.loaded = true;
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.active !== nextState.active)
            return true;
        return super.shouldComponentUpdate(nextProps, nextState);
    }
    GetNext(count, current) {
        return (current + 1) % count;
    }
    GetPrevious(count, current) {
        let previous = (current + -1) % count;
        if (previous < 0)
            previous = count - 1;
        return previous;
    }
    MoveSlide(offset) {
        if (!this.slides)
            return;
            
        const active = offset > 0 ? this.GetNext(this.slides.length, this.state.active) : this.GetPrevious(this.slides.length, this.state.active);
        
        this.setState({
            active : active,
            previous : this.state.active,
            fromRight : offset > 0
        });
    }
    NavigateTo(index) {
        if (!this.slides)
            return;

        this.setState({
            active : index,
            previous : this.state.active,
            fromRight : index > this.state.active
        });
    }
    renderItem({style, props, events, rest}) {                   
        const { MetaItem } = this.props;

        const options = {};
        ['ArrowPrev', 'ArrowNext', 'Navigator'].map((prop) => {
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, prop);
            if (SubTypeId) {
                const MetaItemSubType = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === SubTypeId });
                if (MetaItemSubType) {
                    options[prop] = BuildItemStyles(MetaItemSubType, this.props) || {};
                }
            }
        })

        
		
        
        const prev = (this.state.previous > -1 && this.state.previous < this.slides.length) ? this.slides[this.state.previous] : null;
        const active = (this.state.active > -1 && this.state.active < this.slides.length) ? this.slides[this.state.active] : null;

        const transformTemplate = ({x}) => {
            const t = `translateX(${x})`;
            return t;
        };

        let content = (
            <div style={{display : 'flex', flex : 1,  alignItems : 'stretch'}}>
                {
                    prev && 
                    <motion.div
                        key={prev.Id + 'prev'}
                        initial={this.loaded ? {x : 0} : false}
                        animate={{x : this.state.fromRight ? '-100%' : '100%'}}
                        transition={{
                            duration : 0.4
                        }}
                        transformTemplate={transformTemplate}
                        style={{
                            position : 'absolute',
                            top : 0, left : 0, right : 0, bottom : 0,
                            display : 'flex',
                            alignItems : 'center',
                            justifyContent : 'center',
                        }}
                    >
                        {this.props.renderCollectionItem && this.props.renderCollectionItem(prev)}
                    </motion.div>     
                }
                {
                    active &&        
                    <motion.div
                        key={active.Id + 'active'}
                        initial={this.loaded ? {x : this.state.fromRight ? '100%' : '-100%'} : false}
                        animate={{x : 0}}
                        transition={{
                            duration : 0.4
                        }}
                        transformTemplate={transformTemplate}
                        style={{
                            position : 'absolute',
                            top : 0, left : 0, right : 0, bottom : 0,
                            display : 'flex',
                            alignItems : 'center',
                            justifyContent : 'center'
                        }}
                    >
                        {this.props.renderCollectionItem && this.props.renderCollectionItem(active)}
                    </motion.div>     
                }
            </div>
        )


        return (
            <div style={{
                ...style,
                position : 'relative',
                overflow : 'hidden'
            }}>
                {content}
                <NavigationArrow  
                    left                   
                    options={options.ArrowPrev}                                        
                    onMove={this.MoveSlide.bind(this, -1)}
                />
                <NavigationArrow
                    right 
                    options={options.ArrowNext}
                    onMove={this.MoveSlide.bind(this,  1)}
                />
                <NavigationBar 
                    options={options.Navigator}
                    count={this.slides.length}
                    index={this.state.active}
                    onNavigateTo={this.NavigateTo}
                />
            </div>
        )
    }
}

export default Generic_Carousel;

class NavigationArrow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        const {options} = this.props;
        if (options.props.hidden)
            return null;

        if (!options.props.icon) {
            options.props.icon = {
                paths : this.props.left ? ['M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'] : ['M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z']
            }
        }
        let icon;
        if (options.props.icon) {
            icon = renderEngineIcon({
                icon : options.props.icon,
                style : {
                    width : '40px',
                    height : '40px'
                }
            })      
        }
        const style = {            
            padding : '8px',
            backgroundColor : 'rgba(0,0,0,0.3)',
            cursor : 'pointer',
            ...options.style,
            position : 'absolute',
            top : 0,
            bottom : 0,
        };
        if (this.props.right) {
            style.right = 0;
        }
        else {
            style.left = 0;
        }
        return (  
            <div
                style={style}
                onClick={this.props.onMove}
            >
                {icon}
            </div>
        );
    }
}

class NavigationBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    onNavigateTo(index, e) {
        e.stopPropagation();
        this.props.onNavigateTo(index);
    }
    render() { 
        const buttons = [];
        for (let i=0; i<this.props.count; i++) {
            buttons.push(
                <div
                    key={i}
                    style={{
                        padding : '8px',
                        borderRadius : '99px',
                        margin : '8px',
                        backgroundColor : this.props.index === i ? 'rgba(0,0,200,0.3)' : 'rgba(0,0,0,0.3)',
                        cursor : 'pointer'
                    }}
                    onClick={this.onNavigateTo.bind(this, i)}
                >

                </div>
            )
        }
        return (  
            <div
                style={{
                    position : 'absolute',
                    bottom : 0,
                    left : '50%',
                    transform : 'translateX(-50%)',
                    display : 'flex',
                    justifyContent : 'center',
                    alignItems : 'center'
                }}
            >
                {buttons}
            </div>
        );
    }
}
