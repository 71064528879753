import React from 'react';
import { Route, Link as RouterLink } from 'react-router-dom';
import {Utils} from '../toolabs-importer';
import Strings from './Strings';
import AppState from './AppState';

const Links = {
    Main : () => '/',
    SignUp : () => '/signup',
    ResetPassword : () => '/resetPassword',
    Login : () => '/login',
    Authorize : () => '/authorize',
    AuthorizeFigma : () => '/authFigma',
    Admin : () => '/admin',
    Admin_Users : () => '/admin/users',
    Admin_UserInfo : (id) => `/admin/users/${id}`,
    Board_List : () => '/design/boards',
    Main_Account : () => '/account',
    Main_Support : () => '/help',
    Board_Settings : (Id) => `/settings/board/${Id}`,
    Board_Settings_Tags : (Id) => `/settings/board/${Id}/tags`,
    Board_Settings_States : (Id) => `/settings/board/${Id}/states`,
    Board_New : (admin) => admin ? `/debugadmin/new` : `/board/new`,
    Board_New_TeamProject : (teamId) => `/board/new/${teamId}`,    
    Board_NewWizard : (Id, admin) => admin ? `/debugadmin/new` : `/board/${Id}/new`,
    Board_DemoAnimation : (BoardId) => `/board/${BoardId}/demo/edit`,
    Board_DemoAnimationPreview : (BoardId) => `/board/${BoardId}/demo/preview`,    
    Board_Edit : (Id, admin) => admin ? `/debugadmin/${Id}` : `/board/${Id}`,
    Board_Artboards : (Id) => `/board/${Id}/artboards`,
    Board_Components : (Id) => `/board/${Id}/components`,
    Board_ComponentsCanvas : (Id) => `/board/${Id}/componentscanvas`,
    Board_ResponsiveComponentsCanvas : (BoardId, Id) => `/board/${BoardId}/responsivecomponentscanvas/${Id || 'Default'}/edit`,
    Board_ComponentsCanvas_Preview : (Id) => `/board/${Id}/componentscanvas/preview`,
    Board_ResponsiveComponentsCanvas_Preview : (BoardId, Id) => `/board/${BoardId}/responsivecomponentscanvas/${Id || 'Default'}/preview`,
    Board_Styles : (Id, Module) => `/board/${Id}/tokens${Module ? ('/'+Module):''}`,
    Board_StyleSets : (Id) => `/board/${Id}/stylesets`,
    Board_Styles_Content : (Id, ContentId) => `/board/${Id}/styles/content/${ContentId || Strings.DEFAULT}`,
    Board_Layouts : (Id, Module) => `/board/${Id}/layout${Module ? ('/'+Module) : ''}`,
    Layout_Edit_Grid : (BoardId, Id) => Id ? `/grid/${BoardId}/${Id}/edit` : null,
    Layout_Edit_Layout : (BoardId, Id) => Id ? `/layout/${BoardId}/${Id}/edit` : null,    
    Board_Prototypes : (Id) => `/board/${Id}/prototypes`,
    Board_Playground : (Id) => `/board/${Id}/playground`,
    Board_Publish : (Id) => `/board/${Id}/publish`,
    Board_ExportToFile : (Id) => `/board/${Id}/exporttofile`,
    Board_ImportExportTokens : (Id) => `/board/${Id}/importexporttokens`,
    Board_Release : (Id) => `/board/${Id}/release`,
    Board_API : (Id) => `/board/${Id}/api`,
    Board_RealContent : (Id) => `/board/${Id}/realcontent`,
    Board_RealContentEdit : (Id, DataId) => `/board/${Id}/realcontent/${DataId}`,
    Board_Documents : (Id) => `/board/${Id}/documents`,
    Board_Documents_Preview : (Id) => `/board/${Id}/documents/preview`,
    Board_Documents_Share : (Id) => `/board/${Id}/documents/share`,
    Board_SharedPreview : (Id) => `/sharedpreview/${Id}`,
    Artboard_Edit : (BoardId, Id) => Id ? `/board/${BoardId}/artboard/${Id}/edit` : null,
    Artboard_Component_Edit : (BoardId, Id, ComponentId) => Id ? `/board/${BoardId}/artboard/${Id}/component/${ComponentId}/edit` : null,
    Artboard_Preview : (BoardId, Id) => Id ? `/board/${BoardId}/artboard/${Id}/preview` : null,
    Component_Edit : (BoardId, Id) => Id ? `/board/${BoardId}/component/${Id}/edit` : null,
    Component_Edit_Canvas : (BoardId, Id) => Id ? `/board/${BoardId}/component/${Id}/edit/canvas` : null,
    Component_Preview : (BoardId, Id) => `/board/${BoardId}/component/${Id}/preview`,    
    Demo_Canvas : (BoardId, Id, ViewId) => Id ? `/board/${BoardId}/prototype/${Id}/view/${ViewId}/demo` : null,
    Prototype_Edit : (BoardId, Id) => Id ? `/board/${BoardId}/prototype/${Id}/edit` : null,    
    Documents_Template_Edit : (BoardId, Id, ViewId) => Id ? `/board/${BoardId}/document/${Id}/view/${ViewId}/edit` : null,
    Documents_Template_Preview : (BoardId, Id, ViewId) => Id ? `/board/${BoardId}/document/${Id}/view/${ViewId}/preview` : null,

    Prototype_Canvas_FixedDevice : (BoardId, Id) => Id ? `/board/${BoardId}/prototype/${Id}/edit/fixeddevice` : null,
    Prototype_Canvas_FixedDevice_Preview : (BoardId, Id) => Id ? `/board/${BoardId}/prototype/${Id}/preview/fixeddevice` : null,
    Prototype_Canvas_FreeDevice : (BoardId, Id) => Id ? `/board/${BoardId}/prototype/${Id}/edit/freedevice` : null,
    Prototype_Canvas_FreeDevice_Preview : (BoardId, Id) => Id ? `/board/${BoardId}/prototype/${Id}/preview/freedevice` : null,
    Prototype_NonCanvas : (BoardId, Id, ViewId) => Id ? `/board/${BoardId}/prototype/${Id}/view/${ViewId}/edit` : null,
    Prototype_Preview : (BoardId, Id, ViewId) => `/board/${BoardId}/prototype/${Id}/view/${ViewId}/preview`,
    Prototype_Preview_Share : (BoardId, Id, ViewId) => `/board/${BoardId}/preview/${Id}/view/${ViewId}`, //`/share/${Id}/view/${ViewId}`,

    Sketch_StyleSet_Preview : (Id,StyleSetId) => `/external/${Id}/styleset/previewItem/${StyleSetId}`,
    Sketch_Component_Preview : (Id,ComponentId) => `/external/${Id}/component/previewItem/${ComponentId}`,
    Sketch_Signup : () => `/external/sketch/signup/`,
    Sketch_Login: () => `/external/sketch/login/`,
    Debug_Board_Json : (Id) => `/board/${Id}/json`,
    Debug_Component_Json : (BoardId, Id) => `/board/${BoardId}/component/${Id}/json`,

    Link : function(props) {
        let onClickHandler = null;
        if (!props.To) {
            return (
                <div style={props.style}>
                    {props.children}
                </div>
            )
        }
        if (props.To === '/signin')
            onClickHandler = (e) => {
                e.preventDefault();
                AppState.Logout();
            }
        const {fs, style, To, Type, ...rest} = props;
        // return (
        //     <Route
        //         path={To}
        //         children={
        //             () => {
        //                 const ContainerType = Type || 'a';
        //                 return (
        //                     <ContainerType {...Rest} href={To}>
        //                         {children}
        //                     </ContainerType>
        //                 )
        //             }
        //         }
        //     />
        // )
        const style_link = {};
        if (props.fs) {
            style_link.width = '100%';
            style_link.height = '100%';
        }

        return (
            <RouterLink to={props.To} onClick={onClickHandler} {...rest} style={Utils.Merge(style_link, style)}>
                {props.children}
            </RouterLink>
        );
    }
}

export default Links;