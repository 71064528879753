import React from 'react';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import Radium from 'radium';

class Generic_Toggle extends BaseItem
{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);      
    }    
    onChange(e) {
        const isChecked = Utils.IsTrue(e.target.checked);
        this.props.onSetModelValue('Checked', isChecked);
    }
    onFocus(e) {
        if (window.AppState) {
            window.AppState.Designer.SetEditingText(true);;
        }
        this.props.onSetState({
            MergeWithCurrentState : true,
            Refersh : true,
            Variations : {
                System : 'active'
            }
        })
    }
    onBlur(e) {
        if (window.AppState) {
            window.AppState.Designer.EditingText = false;
        }
        this.props.onUnsetState({
            MergeWithCurrentState : true,
            Refersh : true,
            Variations : {
                System : 'active'
            }
        })
    }
    renderItem({style, props, events, rest}) {

        const isChecked = Utils.IsTrue(this.props.onGetModelValue('Checked'));

        let style_thumb;
        const {MetaItem} = this.props;
        const ThumbId = Utils.JustGet(MetaItem.Generic, null, 'ThumbId');
        if (ThumbId) {
            const MetaItemThumb = Utils.Find(MetaItem.SubItems, (Item) => {return Item.Id === ThumbId});
            if (MetaItemThumb) {
                const ThumbStlye = BuildItemStyles(MetaItemThumb, this.props);
                if (ThumbStlye) {
                    style_thumb = ThumbStlye.style;
                }
            }
        }

        return (
            <label key='label' style={{...style, display : 'inline-block', verticalAlign : 'middle', boxSizing : 'border-box', position : 'relative'}} {...events}>
                <input type='checkbox' 
                    style={{
                        border: 0,
                        clip: 'rect(0 0 0 0)',
                        clippath: 'inset(50%)',
                        height: '1px',
                        margin: '-1px',
                        overflow: 'hidden',
                        padding: 0,
                        position: 'absolute',
                        whiteSpace: 'nowrap',
                        width: '1px'
                    }} 
                    checked={isChecked} 
                    {...rest} 
                    onChange={this.onChange} 
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    disabled={this.props.IsStateActive('disabled', 'disabled')}
                    key='CheckInput'
                />
                <div key='thumb' style={{...style_thumb, boxSizing : 'border-box'}} />
            </label>
        )
    }
}

export default Radium(Generic_Toggle);

