import React from 'react';
import ReactGA from 'react-ga';
import '../../App.css';
import { Route, Redirect, IndexRoute, Link, HashRouter, Switch } from 'react-router-dom';
import ShortId from 'shortid';

import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    AppLayout,
    Globals
} from '../../importer';
  
import NotificationBar from '../../components/notifications';
import OverlayManager from '../../overlays';
import FigmaPlugin from './main';
import Loadable from 'react-loadable';

Utils.Id = () => {
    return ShortId();
}
Utils.platform = Utils.Get(window.navigator, '', 'platform');

window.Utils = Utils;

// const FigmaDemoDesigner = Loadable({
//     loader: () => import('./demodesigner'),
//     loading: () => <div />,
// });


export default class App extends ReactBaseComponent {
    constructor(props) {
        super(props);
        AppLayout.ThemeId = Utils.Id();        
        AppLayout.Theme.Current = AppLayout.Theme.Light;
        // AppLayout.isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

        this.onWheel = this.onWheel.bind(this);
    }


    
    componentDidMount() {
        super.componentDidMount();        
        
        document.addEventListener("mousewheel", this.onWheel, {passive: false});
        document.addEventListener("DOMMouseScroll", this.onWheel, {passive: false});
    }
    onWheel(e) {
        if (e.ctrlKey) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }        
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        Events.DropListenAll(this);
    }
   
    
    renderCustom() {
        return (
            <SC.AppTheme themeName={AppLayout.Theme.Current}>
                <HashRouter>
                    <Switch>
                        <Route exact path="/" render={
                                props => {
                                    return (
                                        <SC.FCol fw fh style={{position : 'relative', maxWidth : '500px'}}>
                                            <FigmaPlugin />
                                            <NotificationBar compact />
                                            <OverlayManager ref={(om) => AppState.OverlayManager.Instance = om} compact />
                                        </SC.FCol>
                                    )
                                }
                            }
                        />
                        {/* <Route exact path="/demodesigner" render={
                                props => {
                                    return (
                                        <SC.FCol fw fh style={{position : 'relative'}}>
                                            <FigmaDemoDesigner />
                                            <NotificationBar compact />
                                            <OverlayManager ref={(om) => AppState.OverlayManager.Instance = om} compact />
                                        </SC.FCol>
                                    )
                                }
                            }
                        /> */}
                    </Switch>
                </HashRouter>                
            </SC.AppTheme>
        )
    }
}


export const FigmaLogo = SC.Icons.Figma;