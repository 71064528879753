import Utils from '../../../../toolabs-utils';

export default (MetaItem, GenericType, Name) => {
    const RootId = Utils.Id();
    MetaItem.name = Name;

    const Component = {            
        RootItem : {
            Id : RootId
        },
        MetaItems : {},
        PreviewSize : {
            width : 120,
            height : 32
        },
        Default: {
            'Designboard': {
                'type': 'grid'
            },
            'height': {
                'value': 32
            },                
            'width': {
                'value': 120
            }
        }
            
    };
    Component.MetaItems[RootId] = MetaItem;

    Component.PseudoStates = {
        Order : ['disabled', 'hover'],        
        disabled : {
            SingleVariation : true,
            name : 'Disabled',
            previewable : true,
        },
        hover : {
            SingleVariation : true,
            pseudo : true,
            name : 'Hover'
        }
    };

    Component.Handlers = {
        
    };

    Component.Models = {
        List : {            
        },
        Order: []
    };        


    GenericTable_SubTypeIds.map((SubType) => {
        Component[SubType.Id] = Utils.Id();
    });    

    Utils.MergeTo(MetaItem, {        
        Generic : {
            Type : GenericType
        },
        Models : {
            Default: {
                Default: {
                }
            }            
        },
        Property: {
            Default: {
                Default: {
                    text: {
                        value: ''
                    },
                    color: {
                        value: 'unset'
                    },
                    fontSize: {
                        value: 14
                    }
                },
                hover: {
                },
                disabled: {
                    color: {
                        value: 'rgba(209, 209, 209, 1)'
                    }
                }
            }
        },
        SubItems : [            
        ]
    });

    GenericTable_SubTypeIds.map((SubType) => {
        MetaItem.Generic[SubType.Id] = Component[SubType.Id];
        MetaItem.SubItems.push({Id : Component[SubType.Id]});
        Component.MetaItems[Component[SubType.Id]] = {        
            Generic : {
                Type : GenericType,
                SubType : SubType.Id
            },
            ParentId: RootId,
            Property: {
                Default: {
                    Default: {                        
                    }
                }
            },
            Type: 'Div'
        };
    });

    return Component;
}

export const GenericTable_SubTypeIds = [
    {Id : 'Header', Label : 'Header'}, 
    {Id : 'HeaderColumn', Label : 'Header Columns'}, 
    {Id : 'Body', Label : 'Body'}, 
    {Id : 'Row', Label : 'Rows'}, 
    {Id : 'RowEvens', Label : 'Even Rows'}, 
    {Id : 'RowOdds', Label : 'Odd Rows'}, 
    {Id : 'Column', Label : 'Columns'}, 
    {Id : 'Footer', Label : 'Footer'}, 
    {Id : 'FooterColumn', Label : 'Footer Columns'}
];