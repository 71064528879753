import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Events,
    MetaData,
    Strings,
    Globals,
    AppLayout
} from '../../../../../../../importer';

import {TokenItemBox, GroupTitle, TokenPanelHeader, TokenItemDragHandle, TokenItemBoxHoveredName} from '../../common';
import DragSource from '../../../../../../../components/dragdrop/HoCDragSource';
import PatternEditor from './item';
import { LeftScrollPanel } from '../../../common';
import { ListCardView } from '../../../../../../../components/light_dark_bar';
import { SortableList, SortableListItem } from '../../../../../../../components/SortableContainer';
import { FontFamilyPreview } from '../fonts';
import { StatefulTokenMarker } from '../../colors';
import { GRANT_TYPES } from '../../../../../manager';


export default class TextPatterns extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.Ref_PatternEdit = React.createRef();

        this.dark = true;
        this.onSortPattern = this.onSortPattern.bind(this);
        this.AddPattern = this.AddPattern.bind(this);
        this.onEditPattern = this.onEditPattern.bind(this);
        this.EditPattern = this.EditPattern.bind(this);
        this.SelectFont = this.SelectFont.bind(this);
        this.onCancelAddPattern = this.onCancelAddPattern.bind(this);
        this.ClosePatternEditor = this.ClosePatternEditor.bind(this);        
        this.onSubmitNewPattern = this.onSubmitNewPattern.bind(this);
        this.onDeletePattern = this.onDeletePattern.bind(this);
        this.onClonePattern = this.onClonePattern.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);
        this.SearchToken = this.SearchToken.bind(this);        

        AppLayout.Refs.DesignSystem.TextPatterns = this;

        this.state.isListView = Globals.ProjectManager.Options.Get(false, 'LeftPanel', 'DesignSystem', 'Typography', 'ListView');        
        this.state.filterText = this.props.filterText;
    }    
    SetListMode(isListView) {        
        Globals.ProjectManager.Options.Set(isListView, 'LeftPanel', 'DesignSystem', 'Typography', 'ListView');
        this.setState({
            isListView : isListView
        })
    }
    onSortPattern(oldIndex, newIndex) {
        Globals.ProjectManager.Tokens.ChangeOrder(Globals.ProjectManager.Tokens.Types.TextPatterns, oldIndex, newIndex);
        this.RCUpdate();
    }
    AddPattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        this.IsNew = true;
        this.EditPatternId = Utils.Id();        
        this.EditPatternModel = Globals.ProjectManager.Tokens.TypePatterns.NewPattern(this.EditPatternId);
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
            ...this.EditPatternModel,
            id : this.EditPatternId
        });

        this.EditPattern(this.EditPatternId);
    }
    ClosePatternEditor() {
        delete this.IsNew;
        delete this.EditPatternModel;
        delete this.EditPatternId;

        this.props.onPanelOverlay({close : true});
    }
    onCancelAddPattern() {
        if (this.IsNew && this.EditPatternId) {
            Globals.ProjectManager.Tokens.TypePatterns.DeletePattern(this.EditPatternId);
        }
        this.ClosePatternEditor();
    }
    onSubmitNewPattern() {
        if (this.IsNew) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Add Text Pattern'});
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // patterns.push(this.EditPatternModel);
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
            this.WillScrollTo = this.EditPatternId;
            this.selectedId = this.EditPatternId;
            Globals.ProjectManager.UpdateTokenValues({});
        }        
        else {
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
        }
                
        this.ClosePatternEditor();
        Events.BroadcastThrottle_400(Events.GLOBAL.TOKEN_VALUE_CHANGING);
    }
    onDeletePattern() {
        if (!this.props.hasEditGrant) {
            return;
        }

        const result = Globals.ProjectManager.Tokens.TypePatterns.DeletePattern(this.EditPatternId);
        if (result) {
            this.ClosePatternEditor();
        }
    }
    onClonePattern() {
        const cloneId = Globals.ProjectManager.Tokens.Clone(this.EditPatternId, Globals.ProjectManager.Tokens.Types.TextPatterns);
        this.EditPattern(cloneId);
        this.props.onUpdate();
    }
    onEditPattern(patternId, e) {
        if (this.props.onSelectPattern && !this.IsNew) {
            this.props.onSelectPattern(patternId, e);
            return;
        }
        this.EditPattern(patternId);
    }
    EditPattern(patternId) {        
        if (!this.props.hasEditGrant) {
            return;
        }

        this.EditPatternId = patternId;        
        const pattern = Globals.ProjectManager.Tokens.Token(patternId);

        this.props.onPanelOverlay({
            show : true,
            render : (props) => {
                return (
                    <SC.FCol fw fh>
                        <TokenPanelHeader title={this.IsNew ? 'NEW PATTERN' : 'EDIT PATTERN'}
                            hasAddCancel 
                            notBackClosable={this.IsNew}
                            onClose={this.onSubmitNewPattern} 
                            onCancel={this.onCancelAddPattern} 
                            onAdd={this.onSubmitNewPattern} 
                            onDelete={!this.IsNew && this.onDeletePattern}
                            onClone={!this.IsNew && this.onClonePattern}
                            onLight
                        />
                        <LeftScrollPanel>
                            <PatternEditor 
                                compact
                                editing
                                isNew={this.IsNew}
                                pattern={pattern}
                                id={patternId}
                                GlobalState={props.GlobalState}
                                GlobalStateId={props.GlobalStateId}
                                GlobalThemeId={props.GlobalThemeId}
                                themeId={props.themeId}
                                onSave={this.SaveEditingPattern}
                                onSelectFont={this.SelectFont}
                                ref={this.Ref_PatternEdit}
                                onClose={this.onSubmitNewPattern} 
                                baseSize={this.props.baseSize}
                                ratio={this.props.ratio}
                            />
                        </LeftScrollPanel>                        
                    </SC.FCol>                    
                )
            }
        })
    }    
    SaveEditingPattern() {
        if (this.Ref_PatternEdit.current)
            this.Ref_PatternEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }
    SelectFont() {
        this.props.onSelectFont((fontId) => {
            if (this.Ref_PatternEdit.current)
                this.Ref_PatternEdit.current.SelectFont(fontId);
            else
                this.RCUpdate();
        });
    }
    SearchToken(filter) {
        this.setState({filterText : filter});
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.filterText !== nextProps.filterText) {
            setTimeout(() => {
                this.SearchToken(nextProps.filterText);
            }, 1);
            return false;
        }
        return true;
    }
    renderCustom() {       
        return (
            <SC.FCol style={{marginTop : '8px', paddingBottom : '4px'}}>
                <GroupTitle 
                    title='Patterns' 
                    subGroup
                    hasAdd={this.props.hasEditGrant ? {onAdd : this.AddPattern} : null} 
                    // hasSearch={{onSearch : this.SearchToken, style : {marginLeft : '10px', marginRight : '10px'}}}
                    style={{paddingLeft : '12px'}}
                    addOn={(
                        <ListCardView 
                            onListView={this.SetListMode.bind(this, true)}
                            onCardView={this.SetListMode.bind(this, false)}
                            isListView={this.state.isListView}
                            isCardView={!this.state.isListView}
                            compact
                        />
                    )}
                />
                
                <TextPatternList 
                    notDraggable={this.props.notDraggable}
                    ref={this.Ref_PatternList}
                    sortable
                    filter={this.state.filterText}
                    dark={this.dark}
                    isListView={this.state.isListView}
                    onSelect={this.onEditPattern}
                    onSort={this.onSortPattern}
                    onShowMenu={this.props.onShowMenu}
                    style={{paddingLeft : '8px', paddingRight : '8px', marginTop : '6px'}}
                />
            </SC.FCol>
        )
    }
}

export const TextPattern = ({DragItem, notDraggable, sortableProps, index, fontStyle, pattern, name, dark, listView, selected, StateArray,...rest}) => {
    const info = {};
    const style = fontStyle || Globals.ProjectManager.Tokens.TypePatterns.GetPatternStyleAndInfo({pattern : pattern, stateArray : StateArray, info : info}) || {};
    const fontSize = style.fontSize;
    const style_box = {
        
    };
    if (selected) {
        style_box.borderLeft = '1px solid';
        style_box.borderLeftColor = SC.CurrentTheme.theme.color_brand;
    }
    // if (Utils.IsNotNull(pattern.LightBackround) ? pattern.LightBackround : !dark) {
    //     style_box.backgroundColor = '#D1D1D1';
    //     style_box.color = '#1e1e1e';
    //     style_box.fontWeight = 500;
    // }    
    let content;
    if (listView) {
        style.fontSize = '14px';
        delete style.lineHeight;
        delete style.letterSpacing;
        delete style.wordSpacing;

        content = (
            <SC.FRow alc jsb style={{transition : 'all 0.2s ease'}}>
                <SC.FRow f1 alc overflowHidden>
                    {
                        sortableProps &&
                        <TokenItemDragHandle {...sortableProps.handleProps} />
                    }
                    <TokenItemBoxHoveredName style={notDraggable ? {transform : 'translateX(0)'} : {}}>
                        {info.ValueState && <StatefulTokenMarker {...info.ValueState} />}
                        <div style={{...style, fontSize : '16px'}}>Ag</div>
                        <SC.TextDivAbbr style={{flex : 1, marginLeft : '8px'}}>
                            {name || pattern.name}
                        </SC.TextDivAbbr>
                    </TokenItemBoxHoveredName>
                </SC.FRow>                
                <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}>{fontSize}</div>
            </SC.FRow> 
        )
        
    }
    else {
        const fontid = Globals.ProjectManager.Tokens.ValueOf({model : pattern, name : 'fontId', info : info});    
        const font = Globals.ProjectManager.Tokens.FontValue(fontid || 'DefaultFont');
        content = (
            <React.Fragment>
                <SC.FRow alc jsb style={{marginBottom : '4px', transition : 'all 0.3s ease'}}>                    
                    {
                        sortableProps &&
                        <TokenItemDragHandle {...sortableProps.handleProps} />
                    }
                    <SC.FRow f1 alc jsb>
                        <TokenItemBoxHoveredName style={sortableProps ? {} : {transform : 'translateX(0)'}}>
                            {info.ValueState && <StatefulTokenMarker {...info.ValueState} />}
                        </TokenItemBoxHoveredName>                        
                        <div style={{fontWeight : 'bold', ...SC.Styles.FontStyles.Monospace}}>{fontSize}</div>
                    </SC.FRow>                               
                </SC.FRow>        
                <FontFamilyPreview font={font} style={style} text={name || pattern.name} />     
            </React.Fragment>
        );
    }
    
    
    if (sortableProps) {
        return (
            <TokenItemBox 
                {...rest} 
                selected={selected} 
                style={{...style_box, paddingTop : '8px', paddingBottom : '8px'}}
                isDragging={sortableProps.isDragging}
            >
                {content}           
            </TokenItemBox>
        )
    }

    return (
        <TokenItemBox {...rest} selected={selected} style={{...style_box, paddingTop : notDraggable ? '8px' : '4px', paddingBottom : notDraggable ? '8px' : '4px'}}>
            {content}           
        </TokenItemBox>
    )
}

const DraggablePattern = DragSource(TextPattern);

export class TextPatternList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }        
    PreviewPattern(show, patternId, pattern) {
        if (show) {
            this.props.onPreview && this.props.onPreview(true, patternId, pattern);
            this.isShowingPreview = true;            
        }
        else {
            this.isShowingPreview = false;
            setTimeout(() => {
                if (!this.isShowingPreview)
                    this.props.onPreview && this.props.onPreview(false);
            }, 100);
        }
    }
    onSelectPattern(patternId, e) {
        this.props.onSelect(patternId, e);
    }
    onShowMenu(patternId, e) {
        this.props.onShowMenu && this.props.onShowMenu(patternId, e);
    }
    render() { 
        const {selectedId, notDraggable, dark, isListView} = this.props;

        const patterns = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TextPatterns);
        const patternItems = [];
        let ItemType = TextPattern;
        if (!notDraggable)
            ItemType = DraggablePattern;

        let filterText;
        if (Utils.IsNotNullOrEmpty(this.props.filter))
            filterText = this.props.filter.toUpperCase();        

        Utils.ForEach(patterns, (patternId, i) => {
            const pattern = Globals.ProjectManager.Tokens.Token(patternId);
            if (pattern) {
                let DragItem;            
                let filtered = false;

                if (filterText) {
                    if (pattern.name.toUpperCase().indexOf(filterText) < 0) {                    
                        filtered = true;
                    }               
                }
                
                if (!filtered) {
                    if (!notDraggable) {
                        DragItem = {
                            Type : Strings.TOOLBARITEM,
                            Item : {
                                MetaItem : {
                                    Type : MetaData.Components.Text.Type,                           
                                    Tokens : {
                                        Default : {
                                            Default : {
                                                textPattern : {
                                                    Id : patternId
                                                }
                                            }
                                        }
                                    }
                                }
                            }       
                        };
                    }
                    else {
        
                    }
                    
                    const BoxProps = {
                        pattern : pattern,
                        onClick : this.onSelectPattern.bind(this, patternId),
                        onContextMenu : this.onShowMenu.bind(this, patternId),
                        onMouseEnter : notDraggable ? this.PreviewPattern.bind(this, true, patternId, pattern) : null,
                        onMouseLeave : notDraggable ? this.PreviewPattern.bind(this, false, patternId, pattern) : null,
                        DragItem  : DragItem,
                        selected : selectedId === patternId,
                        dark : dark,
                        listView : isListView,
                        notDraggable : notDraggable,
                        StateArray : this.props.StateArray
                    };
                    if (this.props.sortable) {
                        patternItems.push(
                            <SortableListItem
                                key={patternId}
                                draggableId={patternId}
                                index={i}
                                ItemBoxType={ItemType}
                                style={notDraggable ? {cursor : 'pointer'} : {cursor : 'move'}}
                                BoxProps={BoxProps}                        
                            />                    
                        )
                    }
                    else {
                        patternItems.push(
                            <ItemType    
                                key={patternId} 
                                style={notDraggable ? {cursor : 'pointer'} : {cursor : 'move'}}
                                {...BoxProps}      
                                pattern={pattern}                        
                            />
                        )
                    }
                }  
            }                      
        })

        if (this.props.sortable) {
            return (
                <SortableList
                    onSort={this.props.onSort}
                    style={this.props.style}
                >
                    {patternItems}
                </SortableList>
            )
        }

        return patternItems;
    }
}

export const GetTextPatternList = () => {
    const patterns = Globals.ProjectManager.Tokens.Order(Globals.ProjectManager.Tokens.Types.TextPatterns);   
    return GetTextPatternListOfIds(patterns);
}
export const GetTextPatternListOfIds = (patterns) => {    
    const patternItems = [];
    Utils.ForEach(patterns, (patternId, i) => {
        const pattern = GetTextPatternTokenItem({id : patternId});
        if (pattern) {
            patternItems.push(pattern);
        }
    });
    return patternItems;
}

export const GetTextPatternTokenItem = ({id}) => {
    const token = Globals.ProjectManager.Tokens.Token(id);
    if (token) {
        return {
            id : id,
            name : token.name,
            pattern : token
        };
    }
}