import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    Strings,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../../importer';

import { ItemNameEditor, TokenPanelHeader } from '../../../common';
import WebsafeFonts from '../../../../../../selectors/font/websafeFonts';
import GoogleFonts from '../../../../../../selectors/font/googleFonts';
import CustomFont from '../../../../../../selectors/font/customfont';
import FontList from '../../../../../../selectors/font/fontlist';
import DropDownSelect from '../../../../../../../../components/editors/enum_dropdown';
import LightDarkBar from '../../../../../../../../components/light_dark_bar';
import SearchBar, { SearchInput } from '../../../../../../searchbar';
import { GoogleFontCategories } from '../../../../../../selectors/font/filterpanel';

const Options = {
    fontType : Strings.FONT_WEBSAE,
    scale : 0.3,
    sorting : 'alpha',
    fontWeight : 400,
    categories : {

    },
};

export default class FontSelectPanel extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
        this.SelectFont = this.SelectFont.bind(this);
        this.ToggleCategories = this.ToggleCategories.bind(this);
    }        
    componentDidMount() {
        super.componentDidMount();
        Options.fontType = Strings.FONT_WEBSAE;
    }
    SelectFont() {

    }
    ToggleCategories() {
        this.showCategories = !this.showCategories;
        this.RCUpdate();
    }
    renderCustom() {
        
        const dark = SC.CurrentTheme.theme.isLight ? !Options.IsDark : Options.IsDark;
        let filterItems;
        
        if (Options.fontType === Strings.CUSTOM) {

        }
        else {
            filterItems = [(
                <DropDownSelect 
                    hasBorder            
                    autoHeight
                    xsmall
                    toRight
                    fitWidth
                    items={[
                        {id : Strings.FONT_WEBSAE, label : 'Websafe Fonts'},
                        {id : Strings.FONT_GOOGLE, label : 'Google Fonts'},
                        {id : Strings.CUSTOM, label : 'Upload Font File'}
                    ]}
                    value={Options.fontType}
                    onChange={(type) => {
                        Options.fontType = type;
                        this.RCUpdate();
                    }}
                />
            )];

            if (Options.fontType === Strings.FONT_WEBSAE) {                
                filterItems[0] = (
                    <div style={{display : 'grid', gridTemplateColumns : '1fr 1fr', gridGap : '8px', alignItems : 'stretch'}}>
                        {filterItems[0]}
                        <FontWeightDropdown 
                            value={Options.fontWeight} 
                            onChange={(type) => {
                                Options.fontWeight = type;
                                this.RCUpdate();
                            }}
                        />                        
                    </div>
                )
            }
            else if (Options.fontType === Strings.FONT_GOOGLE) {
                filterItems[0] = (
                    <div style={{display : 'grid', gridTemplateColumns : '1fr 1fr', gridGap : '8px', alignItems : 'stretch'}}>
                        {filterItems[0]}
                        <SC.FRow f1>
                            <DropDownSelect 
                                hasBorder            
                                autoHeight
                                xsmall
                                toRight
                                fitWidth
                                items={[
                                    {id : 'alpha', label : 'Alphabetical'},
                                    {id : 'date', label : 'Date Added'},
                                    {id : 'popularity', label : 'Popular'},
                                    {id : 'style', label : 'Number of Styles'},
                                    {id : 'trending', label : 'Trending'}
                                ]}
                                value={Options.sorting}
                                onChange={(type) => {
                                    Options.sorting = type;
                                    this.RCUpdate();
                                }}
                                style={{flex : 1, marginRight : '6px'}}
                            />
                            <SC.Icons.Icon_Button hasFill hasCursor onClick={this.ToggleCategories} >
                                <SC.Icons.Icon_SliderSettings size={24} />
                            </SC.Icons.Icon_Button>
                        </SC.FRow>
                        
                    </div>
                )
            }

            if (this.showCategories) {
                filterItems.push(
                    <GoogleFontCategories 
                        categories={Options.categories}
                        onChangeCategories={this.RCUpdate}
                        compact
                    />
                );
            }

            filterItems.push(
                <SearchInput
                    FilterText={Options.filterText}
                    Search={(filter) => {
                        Options.filterText = filter;
                        this.RCUpdate();
                    }}
                    CancelSearch={() => {
                        Options.filterText = '';
                        this.RCUpdate();
                    }}
                    style={{
                        marginTop : '8px', width : '100%'
                    }}
                    short
                    small
                />
            )
        }

        
        return (
            <SC.FCol fw fh  style={{backgroundColor : SC.CurrentTheme.theme.back}}>
                <TokenPanelHeader
                    title='SELECT FONT' 
                    onClose={this.props.onClose} 
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                >
                    <LightDarkBar
                        medium
                        Dark={Options.IsDark}
                        onChange={() => {
                            Options.IsDark = !Options.IsDark;
                            this.RCUpdate();
                        }}
                    />
                </TokenPanelHeader>
                <SC.FCol style={{padding : '10px', backgroundColor : SC.CurrentTheme.theme.back}}>
                    {filterItems}
                </SC.FCol>
                <SC.FCol f1>
                    <SC.CustomScrollbars>
                        <div style={{
                            padding : '10px', boxSizing : 'border-box', paddingTop : 0,
                            width : '100%',
                            height : '100%',
                            backgroundColor : SC.CurrentTheme.theme.back
                            // display : 'grid',
                            // gridTemplateColumns : 'repeat(auto-fill, minmax(200px, 1fr))',
                            // gridGap : '4px',
                            // gridAutoRows : 'minmax(260px, auto)'
                        }}>
                            <FontList 
                                width={400}
                                Options={Options}
                                compact
                                onSelectFont={(font) => {
                                    this.props.onSelect(font);
                                }}
                                onPreview={this.props.onPreview}
                            />
                        </div>                        
                    </SC.CustomScrollbars>    
                </SC.FCol>
                
            </SC.FCol>
        )
    }
}

export const FontWeightDropdown = (props) => {
    const FontWeights = [100, 200, 300, 400, 500, 600, 700, 800, 900].map((w) => {
        return {
            id : w,
            label : `${w}${(w === 400  ? ' (Normal)' : '')}${(w === 700  ? ' (Bold)' : '')}`
        }
    })
    return (
        <DropDownSelect 
            hasBorder            
            autoHeight
            xsmall
            toRight
            fitWidth
            items={FontWeights}
            value={props.value}
            onChange={props.onChange}
            style={props.style}
        />
    )
}