import React from 'react';
import ReactDOM from 'react-dom';
import {
    ReactBaseComponent,
    SC,
    AppState,
    Utils,
    Events,
    Loading,
    UIUtils,
    MetaData,
    Strings
} from '../../../../importer';
import styled, {css} from 'styled-components';

import { SearchInput } from '../../searchbar';

class PanelHeader extends ReactBaseComponent
{
    constructor(props) {
        super(props);
    }
    renderCustom() {
        let TagItems = null;
        return (
            <SC.FCol style={this.props.style}>
                <SC.FRow alc>
                    <SearchInput
                        FilterText={this.props.Search}
                        Search={this.props.onSearch}
                        CancelSearch={this.props.onCancel}
                        small
                        full
                    />                    
                    {
                        this.props.noTags ? null : 
                        <SC.Div_Flex_Cell 
                            cursorPointer 
                            style={{paddingLeft : '6px', marginRight : '4px'}}
                            title='Filter by Tags'
                            onClick={() => {this.TagFilter = !this.TagFilter; this.FilteredTags = {}; this.RCUpdate()}} 
                        >
                            <SC.Icons.Icon_TagFilter selected={this.TagFilter} size={16}/>
                        </SC.Div_Flex_Cell>
                    }
                    {
                        this.props.noSort ? null : 
                        <React.Fragment>
                            <SC.Div_Flex_Cell title='Change sort order'cursorPointer onClick={this.props.SetSortMode} >
                                {
                                    this.props.SortMode ? (
                                        <SC.Icons.Icon_Sort_AZ size={16} selected SortUp={this.props.SortMode === 2}/>
                                    ) :
                                    <SC.Icons.Icon_Sort_AZ size={16}/>
                                }                        
                            </SC.Div_Flex_Cell>
                        </React.Fragment>
                    }                    
                </SC.FRow>
            </SC.FCol>
        )
    }
}

export default PanelHeader;

const SearchPanel = styled(SC.FRow) `
    flex : 1;
    align-items : center;
    padding-top : 4px;
    padding-bottom : 4px;
    border-bottom : ${props => props.theme.border_ondark};
    background-color : ${props => props.theme.back};
    margin-bottom : ${props => props.noBottomMargin ? 0 : 12}px;
    border-radius : 40px;    
    transition : 0.2s ease;
    transition-property : width, background-color;    
    &:hover {
        background-color : ${props => props.theme.back_lightest};
    }       
    ${
        props => {
            if (props.expanded)
                return css`
                    background-color : #3d3e42;
                `;
        }
        }
`;
const SearchInputType = styled.input`
    background-color : transparent;
    box-sizing : border-box;
    line-height : 18px;            
    border : none;            
    flex : 1;
    align-self : stretch;
    font-size : 14px;
    color : #a5a5a5;
    letter-spacing : 0.53px;
    ${SC.TransitionHoverOff}
    ${props => {
        if (!Utils.IsNotNullOrEmpty(props.value))
            return css`font-style : italic; font-size : 14px; color : #6c6d70; ${SC.TransitionHoverOn}`;
    }}
`;