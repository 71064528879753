import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import {GroupTitle, TokenPanelHeader} from '../../../../left/designsystem/common';
import DropDownSelect from '../../../../../../../components/editors/enum_dropdown';
import { StyleGroupTokenItem, StyleGroup, StyleGroupTitle, OptionalStyleGroupHeader, ToggleButton, PopupArrow } from '../common';
import { AnimatePresence, motion} from 'framer-motion';
import ComponentDesignManager from '../../../../../component/manager';
import { GetBorderStyleTokenList, BorderStyleTokenItem, GetBorderStyleTokenValue } from '../../../../left/designsystem/borders';
import { GetTokenAliaseList, GetGroupedTokenAliaseList } from '../../../../left/designsystem/aliases';
import BorderStyleEditor from '../../../../left/designsystem/borders/border';

export default class BorderStyles extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SidesWithValue = {};
        this.SelectBorderStyle = this.SelectBorderStyle.bind(this);
        this.RemoveTokenBinding = this.RemoveTokenBinding.bind(this);
        this.SetTokenBinding = this.SetTokenBinding.bind(this);
        
        this.ClosePopup = this.ClosePopup.bind(this);
        this.addBorder = this.addBorder.bind(this);        

        this.getPropName = Utils.BorderStyles.propStyle;
        this.getPropColor = Utils.BorderStyles.propColor;

        this.LoadTokenProps(this.props);
    }
    RemoveTokenBinding() {        
        this.showEditor = false;
        let styleRemoved = false;
        Utils.ForEach(this.SidesWithValue, (value, key) => {
            if (value) {
                if (value.TokenId) {
                    this.props.onRemoveStyle(value.propName, this.props.Id, null, [], true);
                    styleRemoved = true;
                }
                if (value.TokenIdColor) {
                    this.props.onRemoveStyle(value.propColor, this.props.Id, null, [], true);
                    styleRemoved = true;
                }
            }            
        });

        if (styleRemoved) {
            this.props.GetComponentManager().ResetItemStyle(this.props.Id);
            this.props.GetComponentManager().UpdateRenderers();
            this.props.onUpdate();
        }        
        else {
            this.RCUpdate();
        }
    }
    SetTokenBinding(side, tokenId) {
        const currentTokenId = Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(side, 'All'), 'TokenId');
        const propName = this.getPropName(side);
        if (currentTokenId === tokenId) {
            this.props.onRemoveStyle(propName, this.props.Id, null, [], true);
            this.props.GetComponentManager().ResetItemStyle(this.props.Id);
            this.props.GetComponentManager().UpdateRenderers();
        }
        else {
            this.props.SetTokenBinding(propName, this.props.Id, tokenId, [], true);
            this.ClosePopup();
        }
        this.props.onUpdate();
    }

    LoadTokenProps(props) {
        this.hasNoSpacing = true;        
        this.propNames = [];
        this.SidesWithValue = {};
        Utils.BorderStyles.Sides.map((side, i) => {            
            const propValue =  this.getPropName(side);
            const propColor =  this.getPropColor(side);

            this.propNames.push(propValue);
            this.propNames.push(propColor);

            const useSideName = Utils.UseNullOrEmpty(side, 'All');
            const tokenId = Utils.JustGet(props, null, propValue, 'TokenId');
            const tokenIdColor = Utils.JustGet(props, null, propColor, 'TokenId');

            if (tokenId || tokenIdColor) {                
                this.hasNoSpacing = false;
                
                this.SidesWithValue[useSideName] = {
                    propName : propValue,
                    propColor : propColor,
                    TokenId : tokenId,
                    TokenIdColor : tokenIdColor
                }

                if (tokenId) {
                    const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
                    if (tokenModel) {
                        const tokenItem = GetBorderStyleTokenValue(tokenModel, tokenId, props.StateArray);
                        this.SidesWithValue[useSideName].style = tokenItem.style;
                        this.SidesWithValue[useSideName].width = tokenItem.width;
                        this.SidesWithValue[useSideName].name = tokenModel.name;
                    }
                }
                if (tokenIdColor) {
                    const tokenModelColor = Globals.ProjectManager.Tokens.Token(tokenIdColor);
                    if (tokenModelColor) {
                        this.SidesWithValue[useSideName].colorName = tokenModelColor.name;
                        this.SidesWithValue[useSideName].color = Globals.ProjectManager.Tokens.GetStateValue({Id : tokenIdColor, StateArray : props.StateArray});
                    }
                }
            }
        });        
    }
    shouldComponentUpdate(nextProps, nextState) {      
          
        if (nextProps.Id !== this.props.Id || nextProps.ManagerId !== this.props.ManagerId) {
            this.showEditor = false;
            this.LoadTokenProps(nextProps);
            return true;
        }
        
        if (Utils.HasAnyChange(this.props, nextProps, ...this.propNames)) {
            this.LoadTokenProps(nextProps);
            return true;
        }            

        if (Utils.HasAnyChange(this.props, nextProps, 'RenderId', 'GlobalStateId', 'GlobalState', 'GlobalThemeId')) {
            if (!this.hasNoSpacing && this.props.GlobalThemeId !== nextProps.GlobalThemeId) {
                this.LoadTokenProps(nextProps);
            }
            return true;     
        }
               
        return super.ShouldUpdate(nextProps, nextState);
    }
    SelectColor(side, e) {
        e && e.stopPropagation();
        
        this.props.onSelectBorderColor(side, this.SidesWithValue[Utils.UseNullOrEmpty(side, 'All')].TokenIdColor);
    }
    SelectBorderStyle(side) {
        this.showEditor = true;
        if (!this.IsClosingPopup) {
            const title = side.toUpperCase() + ' BORDER';
            if (this.ShowSpaceSelector) {
                if (this.ShowSpaceSelector.side === side) {
                    this.ClosePopup();
                }
                else {
                    this.ShowSpaceSelector.side = side;
                    this.ShowSpaceSelector.title = title;
                    this.ShowSpaceSelector.doNotClose = true;
                }                
            }
            else {
                const panelBounds = UIUtils.Utils.GetBounds(AppLayout.Refs.Inspectors.Main);
                const bounds = UIUtils.Utils.GetBounds(this);
                const maxHeight = bounds.top - panelBounds.top - 100;
                this.ShowSpaceSelector = {
                    side : side,
                    title : title,
                    maxHeight : maxHeight
                };
            }            
            
            this.RCUpdate();
        }
            
        this.IsClosingPopup = false;
        
    }
    canCloseSelector(side) {
        if (this.ShowSpaceSelector && this.ShowSpaceSelector.doNotClose) {
            this.ShowSpaceSelector.doNotClose = false;
            return true;
        }            
        return false;
    }
    ClosePopup() {
        this.IsClosingPopup = true;
        delete this.ShowSpaceSelector;
        this.RCUpdate();
        setTimeout(() => {
            this.IsClosingPopup = false;                                
        }, 200); 
    }    
    addBorder() {
        Utils.ForEach(Utils.BorderStyles.Sides, (side, i) => {
            const useSideName = Utils.UseNullOrEmpty(side, 'All');
            if (!this.SidesWithValue[useSideName]) {
                this.SidesWithValue[useSideName] = {
                    propName : this.getPropName(side),
                    propColor : this.getPropColor(side)
                };
                this.hasNoSpacing = false;
                return false;
            }
        });
        this.RCUpdate();
    }
    ChangeBorderSide(old, newSide) {
        const oldSide = Utils.UseNullOrEmpty(old, 'All');
        const side = Utils.UseNullOrEmpty(newSide, 'All');
        this.SidesWithValue[side] = this.SidesWithValue[oldSide];
        delete this.SidesWithValue[oldSide];

        if (this.SidesWithValue[side]) {
            if (this.SidesWithValue[side].TokenId) {
                this.props.onRemoveStyle(this.getPropName(old), this.props.Id, null, [], true);            
                this.props.SetTokenBinding(this.getPropName(newSide), this.props.Id, this.SidesWithValue[side].TokenId, [], true);
            }
            if (this.SidesWithValue[side].TokenIdColor) {
                this.props.onRemoveStyle(this.getPropColor(old), this.props.Id, null, [], true);
                this.props.SetTokenBinding(this.getPropColor(newSide), this.props.Id, this.SidesWithValue[side].TokenIdColor, [], true);
            }
            
            if (this.SidesWithValue[side].TokenId || this.SidesWithValue[side].TokenIdColor) {
                this.props.GetComponentManager().ResetItemStyle(this.props.Id);
                this.props.GetComponentManager().UpdateRenderers();
                this.props.onUpdate();
            }
        }        

        this.RCUpdate();
    }
    DeleteBorder(side) {
        const useSideName = Utils.UseNullOrEmpty(side, 'All');
        if (this.SidesWithValue[useSideName] && (this.SidesWithValue[useSideName].TokenId || this.SidesWithValue[useSideName].TokenIdColor)) {
            this.props.onRemoveStyle(this.getPropName(side), this.props.Id, null, [], true);
            this.props.onRemoveStyle(this.getPropColor(side), this.props.Id, null, [], true);
            this.props.GetComponentManager().ResetItemStyle(this.props.Id);
            this.props.GetComponentManager().UpdateRenderers();
            this.props.onUpdate();
        }
        else {
            delete this.SidesWithValue[useSideName];
            this.RCUpdate();
        }
    }
    renderCustom() {        
        let header, content;
       
        const borders = [];
        Utils.ForEach(Utils.BorderStyles.Sides, (side, i) => {
            const useSideName = Utils.UseNullOrEmpty(side, 'All');
            if (this.SidesWithValue[useSideName]) {
                borders.push(
                    <BorderSide 
                        key={side}
                        side={side}
                        onSelectBorderStyle={this.SelectBorderStyle.bind(this, side)}
                        onSelectColor={this.SelectColor.bind(this, side)}
                        tokenId={this.SidesWithValue[useSideName].TokenId}
                        color={this.SidesWithValue[useSideName].color}
                        onChangeSide={this.ChangeBorderSide.bind(this, side)}
                        onDeleteBorder={this.DeleteBorder.bind(this, side)}
                        active={this.ShowSpaceSelector && this.ShowSpaceSelector.side === side}
                        SidesWithValue={this.SidesWithValue}
                    />
                )
            }
        });

        header = (
            <OptionalStyleGroupHeader title='BORDERS' onClick={borders.length > 0 ? null : this.addBorder} onAdd={borders.length < 5 ? this.addBorder : null} />
        )

        if (borders.length > 0) {
            content = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '70px 1fr 28px auto ',
                    gridGap  :'4px',
                    marginTop : '8px',
                    alignSelf : 'stretch'
                }}>
                    {borders}
                </div>
            )
        }        

        return (
            <StyleGroup empty key={this.hasNoSpacing ? 'noborder' : 'border'}>
                {header}
                {content}
                {
                    this.ShowSpaceSelector && 
                    <BorderStyleSelectorPopup 
                        Id={this.props.Id}
                        onClose={this.ClosePopup}
                        side={this.ShowSpaceSelector.side}
                        propName={this.getPropName(this.ShowSpaceSelector.side)}
                        title={this.ShowSpaceSelector.title}
                        isOver={this.canCloseSelector.bind(this, this.ShowSpaceSelector.side)}
                        maxHeight={this.ShowSpaceSelector.maxHeight}
                        onTokenSelected={this.SetTokenBinding.bind(this, this.ShowSpaceSelector.side)}
                        tokenId={Utils.JustGet(this.SidesWithValue, null, Utils.UseNullOrEmpty(this.ShowSpaceSelector.side, 'All'), 'TokenId')}
                        onPreviewToken={this.props.onPreviewToken}
                    />
                }
            </StyleGroup>
        )
    }
}

class BorderSide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

    }
    render() { 
        const items = [];
        Utils.ForEach(Utils.BorderStyles.Sides, (side, i) => {
            const useSideName = Utils.UseNullOrEmpty(side, 'All');
            if (!this.props.SidesWithValue[useSideName] || useSideName === this.props.side || useSideName === 'All')
                items.push({id : side, label : useSideName});
        });

        const style = {};
        if (this.props.active) {
            style.border = SC.CurrentTheme.theme.border_brand;
        }

        return (  
            <React.Fragment>
                <DropDownSelect 
                    hasBorder            
                    autoHeight
                    xsmall
                    toRight
                    fitWidth
                    style={{maxHeight : 'unset', height : 'unset', height : '100%', fontSize : '11px'}}
                    items={items}
                    value={this.props.side}
                    onChange={this.props.onChangeSide}
                />
                <StyleTokenBorderStyle
                    tokenId={this.props.tokenId}
                    onSelectBorderStyle={this.props.onSelectBorderStyle}
                    style={style}
                />
                <StyleGroupTokenItem onClick={this.props.onSelectColor} style={{display : 'flex', backgroundColor : 'unset'}} >
                    <SC.GridBackground 
                        small 
                        dark={!SC.CurrentTheme.theme.isLight} 
                        style={{hoverflow : 'hidden', cursor : 'pointer', margin : '2px', borderRadius : '2px', overflow : 'hidden', boxSizing : 'border-box', flex : 1, alignSelf : 'stretch'}}
                    >
                        <div
                            style={{backgroundColor : this.props.color, width : '100%', height : '100%', boxSizing : 'border-box'}} 
                        >                        
                        </div>
                    </SC.GridBackground>
                </StyleGroupTokenItem>                 
                <SC.Icons.Icon_Button hasFill hasCursor  onClick={this.props.onDeleteBorder} style={{zIndex : 1, marginRight : 0, minHeight : '20px'}}>
                    <SC.Icons.Icon_Delete size={16} />
                </SC.Icons.Icon_Button>
            </React.Fragment>
        );
    }
}

export const StyleTokenBorderStyle = ({onSelectBorderStyle, style, tokenId, StateArray}) => {
    const token = {

    };

    if (tokenId) {
        const tokenModel = Globals.ProjectManager.Tokens.Token(tokenId);
        if (tokenModel) {
            const tokenItem = GetBorderStyleTokenValue(tokenModel, tokenId, StateArray);
            token.style = tokenItem.style;
            token.width = tokenItem.width;
            token.name = tokenModel.name;            
        }            
    }
    
    return (
        <StyleGroupTokenItem onMouseDown={onSelectBorderStyle} style={style}>
            <SC.FRow>
                <SC.FCol f1 style={{justifyContent : 'space-evenly', padding : '6px', overflow : 'hidden', ...SC.Styles.FontStyles.Monospace}}>
                    <SC.TextDivAbbr style={{flex : 1}}>
                        {Utils.UseNullOrEmpty(token.width, 'NONE')}
                        {' ' + Utils.UseNullOrEmpty(token.style, '')}
                    </SC.TextDivAbbr>                                
                </SC.FCol>
                <div 
                    style={{
                        width : '52px',
                        marginRight : '8px',
                        alignSelf : 'center',
                        borderTopStyle : token.style,
                        borderTopWidth : token.width,
                        borderTopColor : SC.CurrentTheme.theme.color_brand
                    }} 
                />
            </SC.FRow>
        </StyleGroupTokenItem>
    )
}
 
class BorderStyleSelectorPopupClass extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.tokens = GetBorderStyleTokenList();
       
        this.aliases = GetTokenAliaseList(Globals.ProjectManager.Tokens.Types.Borders);
        this.aliaseGroups = GetGroupedTokenAliaseList(Globals.ProjectManager.Tokens.Types.Borders, this.aliases);    

        Utils.ForEach(this.aliases, (aliase, i) => { 
            if (aliase.tokenId) {
                aliase.tokenItem = Utils.Find(this.tokens, (item) => {return item.id === aliase.tokenId});                
            }
        });

        this.AddBorderRadius = this.AddBorderRadius.bind(this);
        this.onSubmitAddToken = this.onSubmitAddToken.bind(this);
        this.onCancelAddToken = this.onCancelAddToken.bind(this);
        this.PreviewToken = this.PreviewToken.bind(this);
        this.PreviewNewToken = this.PreviewNewToken.bind(this);
    }
    SelectToken(id) {
        this.props.onTokenSelected(id);
    }
    AddBorderRadius() {
        const Id = Utils.Id();
        this.AddingNewToken = {
            Id : Id,
            Model : {
                type : Globals.ProjectManager.Tokens.Types.Borders,
                name : 'New Border',                
                id : Id
            }
        };
        Globals.ProjectManager.Tokens.Add({...this.AddingNewToken.Model, doNotUpdateTheme : true});
        this.PreviewToken(true, this.AddingNewToken.Id);
        this.RCUpdate();
    }
    componentWillUnmount() {
        this.onCancel();
    }
    onCancel() {
        if (this.AddingNewToken)  {
            Globals.ProjectManager.Tokens.DeletePreviewToken(this.AddingNewToken.Id);
            Globals.ProjectManager.Tokens.Delete(Globals.ProjectManager.Tokens.Types.Borders, this.AddingNewToken.Id);
            this.PreviewToken(false, this.AddingNewToken.Id);
            ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateItemPreviews(this.props.Id, false);
        }
    }
    onCancelAddToken() {
        this.onCancel();
        delete this.AddingNewToken;
        this.RCUpdate();
    }
    onSubmitAddToken() {
        const Id = this.AddingNewToken.Id
        Globals.ProjectManager.Tokens.SetValue({id : this.AddingNewToken.Id, value : this.AddingNewToken.Model.value, type : Globals.ProjectManager.Tokens.Types.Borders});
        delete this.AddingNewToken        
        this.SelectToken(Id);        
        AppLayout.Refs.DesignSystem.Borders && AppLayout.Refs.DesignSystem.Borders.Reload();
    }
    PreviewToken(show, tokenId) {  
        this.PreviewingTokenId = show ? tokenId : null;
        this.props.onPreviewToken({
            propName : this.props.propName,
            tokenId : tokenId,
            show : show,
            ParentNames : []
        });             
    }
    PreviewNewToken(value) {        
        this.AddingNewToken && Globals.ProjectManager.Tokens.SetPreviewTokenValue(this.AddingNewToken.Id, value);
        this.PreviewingNewToken = this.AddingNewToken.Id;        
        ComponentDesignManager.Active().ComponentManager && ComponentDesignManager.Active().ComponentManager.UpdateRendererNewTokenPreview(this.props.Id, this.AddingNewToken.Id);
    }    
    renderCustom() {
        let header, content;
        if (this.AddingNewToken) {
            header = (
                <TokenPanelHeader title='NEW BORDER' 
                    hasAddCancel 
                    notBackClosable
                    onClose={this.onCancelAddToken} 
                    onCancel={this.onCancelAddToken} 
                    onAdd={this.onSubmitAddToken} 
                    onDelete={!this.IsNew && this.onDeleteBorder}
                    onLight
                    style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                />
            )
            content = (
                <BorderStyleEditor 
                    id={this.AddingNewToken.Id}
                    newModel={this.AddingNewToken.Model}  
                    onClose={this.onCancelAddToken}
                    onPreviewChange={this.PreviewNewToken}
                />
            )
        }
        else {
            header = (
                <GroupTitle 
                    closable
                    onClose={this.props.onClose}
                    style={{marginBottom : '8px', paddingRight : '8px'}}
                    titleStyle={{fontSize : '11px'}}
                    title={this.props.title} 
                    hasAdd={{onAdd : this.AddBorderRadius}}                         
                />
            )
            content = (
                <React.Fragment>
                    {
                        this.aliases.length > 0 && 
                        <SC.FCol style={{padding : '8px', paddingTop : 0}}>
                            <div style={{fontSize : '11px', marginBottom : '4px'}}>Aliases</div>
                            {
                                this.aliases.map((aliase) => {
                                    return (
                                        <BorderStyleTokenItem
                                            key={aliase.id}
                                            name={aliase.name}
                                            border={aliase.tokenItem}
                                            isListView
                                            onSelect={this.SelectToken.bind(this, aliase.id)}
                                            selected={aliase.id === this.props.tokenId}
                                            onPreview={{
                                                onMouseEnter : this.PreviewToken.bind(this, true, aliase.tokenId, this.props.propName),
                                                onMouseLeave : this.PreviewToken.bind(this, false, aliase.tokenId, this.props.propName)
                                            }}
                                        />   
                                    )
                                })
                            }
                        </SC.FCol>
                    }
                    <SC.FCol style={{padding : '8px', paddingTop : 0}}>
                        {this.aliases.length > 0 && <div style={{fontSize : '11px', marginBottom : '4px'}}>Tokens</div>}
                        {
                            this.tokens.map((border) => {
                                return (
                                    <BorderStyleTokenItem
                                        key={border.id}
                                        border={border}
                                        isListView
                                        onSelect={this.SelectToken.bind(this, border.id)}
                                        selected={border.id === this.props.tokenId}
                                        onPreview={{
                                            onMouseEnter : this.PreviewToken.bind(this, true, border.id, this.props.propName),
                                            onMouseLeave : this.PreviewToken.bind(this, false, border.id, this.props.propName)
                                        }}
                                    />   
                                )
                            })
                        }
                    </SC.FCol>
                </React.Fragment>                
            )
        }

        return (
            <motion.div
                style={{
                    position : 'absolute', 
                    bottom : '100%',
                    right : '8px',
                    left : '8px',
                    marginBottom : '8px',
                    backgroundColor : SC.CurrentTheme.theme.dropdown_back_dark,
                    border : SC.CurrentTheme.theme.border_popup,
                    borderRadius : '2px',
                    boxShadow : SC.CurrentTheme.theme.popup_shadow,
                    pointerEvents : 'all',
                    zIndex : 10000
                }}
                initial={{y : 8}}
                animate={{y : 0}}
                exit={{y : 8, opacity : 0.7}}
                transition={{duration : 0.2}}           
                layoutTransition={{
                    duration : 0.3
                }}
            >
                <PopupArrow style={{transform : 'rotate(180deg) translateX(50%)'}} toTop />
                {header}
                <SC.CustomScrollbars
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax={this.props.maxHeight - 30}
                >
                    {content}
                </SC.CustomScrollbars>                
            </motion.div>
        )
    }
}

export const BorderStyleSelectorPopup = UIUtils.OutSideClickHandler(BorderStyleSelectorPopupClass);
