import React from 'react';
import {
    SC,
    Globals,
    Utils,
} from '../../../../../../../importer';

import TextPatternEditor from '../../../../left/designsystem/typography/patterns/item';
import AppLayout from '../../../../../../../appstate/AppLayout';
import { TextPatternList } from '../../../../left/designsystem/typography/patterns';
import { onSelectTypeFace } from '../../../../left/designsystem/typography';
import BaseSelectToken from '../BaseSelectToken';

export default class SelectTokenTextPattern extends BaseSelectToken
{
    constructor(props) {
        super(props);
        this.state.isListView = false;
        this.titleNewToken = 'NEW TEXT PATTERN';
        this.moduleName = 'Typography';
        this.tokenType = Globals.ProjectManager.Tokens.Types.Fonts;        
        this.hasCustomTokenList = true;

        this.Ref_PatternEdit = React.createRef();

        this.SelectFont = this.SelectFont.bind(this);
        this.SaveEditingPattern = this.SaveEditingPattern.bind(this);

        this.Load(this.props);
    }
    IsTokenPropChanged(nextProps, nextState) {
        return this.props.textPattern !== nextProps.textPattern || this.props.GlobalState !== nextProps.GlobalState;
    }
    IsEmpty() {
        return false;
    }
    GetNewModel(Id) {
        const Model = Globals.ProjectManager.Tokens.TypePatterns.NewPattern(Id);        
        Globals.ProjectManager.Tokens.Add({
            type : Globals.ProjectManager.Tokens.Types.TextPatterns,
            id : Id,
            ...Model
        });
        this.FireSelectToken(Id);
        return Model;
    }
    PreEditToken() {
    }    
    onCloseTokenEditor() {
        if (this.EditingToken && this.EditingToken.IsNew) {
            delete this.EditingToken;            
            this.RCUpdate();
            return;
        }
        super.onCloseTokenEditor();
    }
    onCancelAddToken() {
        const willDeleteId = this.EditingToken.Id;
        this.FireSelectToken(this.LastBoundTokenId);
        delete this.LastBoundTokenId;
        super.onCancelAddToken();
        Globals.ProjectManager.Tokens.TypePatterns.DeletePattern(willDeleteId);
        this.UpdateDesignSystemPanel();
    }
    UpdateDesignSystemPanel() {
        AppLayout.Refs.DesignSystem.Typograpgy && AppLayout.Refs.DesignSystem.Typograpgy.RCUpdate();
    }
    SaveToken() {
        if (this.EditingToken.IsNew) {
            const pattern = Globals.ProjectManager.Tokens.Token(this.EditingToken.Id);
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
            Globals.ProjectManager.UpdateTokenValues({});
            this.UpdateDesignSystemPanel();
            return {
                id : this.EditingToken.Id
            }
        }        
        else {
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
        }                
        return {
            id : this.EditingToken.Id
        };
    }   
    SaveEditingPattern() {
         
        if (this.Ref_PatternEdit.current)
            this.Ref_PatternEdit.current.RCUpdate();
        else
            this.RCUpdate();
    }
    GetStyleName() {
        return 'textPattern';
    }
    SelectFont() {
        onSelectTypeFace((fontId) => {
            if (this.Ref_PatternEdit.current)
                this.Ref_PatternEdit.current.SelectFont(fontId);
            else
                this.RCUpdate();
        }, null, null, this.RCUpdate);
    }    
    PreviewToken(show, tokenId, pattern) { 
        super.PreviewToken(show, tokenId, 'textPattern');
    }
    SearchToken(filter) {
        this.FilterText = filter;
        this.RCThrottledUpdate_1();
    }
    renderTokenList() {
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <TextPatternList 
                    isListView={this.state.isListView}
                    dark
                    onSelect={this.SelectToken}
                    notDraggable
                    onPreview={this.PreviewToken}
                    selectedId={this.props.textPattern}                    
                    StateArray={this.props.StateArray}
                    filter={this.FilterText}
                />
            </SC.CustomScrollbars> 
        )
    }
    UpdateSelectedToken() {
        if (this.EditingToken.IsNew) {
            
        }        
        else {
            // const patterns = Globals.ProjectManager.Tokens.TypePatterns.Get();
            // Globals.ProjectManager.Tokens.TypePatterns.Set(patterns);
        }  
    }
    renderTokenEditor() {
        const typeScale = Globals.ProjectManager.Tokens.Typescale.Get();
        return (
            <SC.CustomScrollbars hideTracksWhenNotNeeded autoHide>
                <SC.FCol fw fh>
                    <TextPatternEditor 
                        compact
                        editing
                        isNew={this.EditingToken.IsNew}
                        pattern={this.EditingToken.Model}
                        id={this.EditingToken.Id}
                        GlobalStateId={this.props.GlobalStateId}
                        onSave={this.SaveEditingPattern}
                        onSelectFont={this.SelectFont}
                        ref={this.Ref_PatternEdit}
                        onClose={this.onSubmitAddToken} 
                        onPreviewChange={this.EditingToken.IsNew ? this.PreviewNewToken : null}
                        StateArray={this.props.StateArray}
                        baseSize={typeScale.baseSize}
                        ratio={typeScale.ratio}
                    />
                </SC.FCol>
            </SC.CustomScrollbars>
        )        
    }
    GetSelectedTokenId() {
        return this.props.textPattern;
    }
    renderSelectedToken() {
        if (this.props.textPattern) {
            return this.renderSelectedTokenHeader();
        }
    }
}