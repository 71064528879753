import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppLayout,
    Links,
    Events,
    MetaData,
    Strings,
    Globals
} from '../../../../../../importer';

import Containers from './container';
import { TokenGroup, GroupTitle } from '../common';
import { motion, AnimatePresence } from 'framer-motion';
import SelectToken from '../../../right/iteminspector/selectToken';

export default class DefaultStyles extends ReactBaseComponent
{
    constructor(props) {
        super(props);
        
        this.elementType = Globals.ProjectManager.Templates.Types.Container;

        this.onToggleExpand = this.onToggleExpand.bind(this);

        this.AddTempalte = this.AddTempalte.bind(this);
        this.CloseEditor = this.CloseEditor.bind(this);
        this.DeleteTemplate = this.DeleteTemplate.bind(this);
        this.SelectToken = this.SelectToken.bind(this);
    }
    LoadOptions() {
        this.expanded = Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'DesignSystem', 'Elements', this.elementType, 'Expanded');
    }
    Load() {

    }
    onToggleExpand() {
        this.expanded = !this.expanded;
        Globals.ProjectManager.Options.Set(this.expanded, 'LeftPanel', 'DesignSystem', 'Elements', this.elementType, 'Expanded');
        if (this.expanded)
            this.Load();
        this.RCUpdate();
    }
    SelectToken({templateId, type, props = {}, propName, title}) {
        this.props.RefToolbar.current.ShowPanel({
            type : type,
            ...props,
            title : title,
            onRegisterClose : (callClose) => {
                super.AddCloseCallback(callClose);
            },
            onSelect : (tokenId, isGradient) => {
                this.SetTokenBinding({PropertyName : propName, Id : templateId, TokenId : tokenId });
                this.RCUpdate();
            }
        })
    }
    SetTokenBinding({PropertyName, Id, TokenId}) {
        Globals.ProjectManager.Templates.SetValue({id : Id, value : TokenId, name : PropertyName, type : this.elementType});
        Events.BCE(Events.GLOBAL.TOKENS_CHANGED);
    }
    AddTempalte() {
        this.EditingTemplate = {
            Id : Utils.Id(),
            isNew : true
        };
        this.props.onShowOverlay({
            type : this.elementType,
            id : Utils.Id(),
            isNew : true,
        })
    }
    EditTemplate(id) {
        this.EditingTemplate = {
            Id : id
        };
        this.props.onShowOverlay({
            type : this.elementType,
            id : id
        })
    }
    CloseEditor() {
        delete this.EditingTemplate;
        this.props.onShowOverlay()
    }
    DeleteTemplate() {

    }
    renderContent() {
        return this.renderDefault();

        // TODO
        // return (
        //     <SC.FCol>
        //         {this.renderDefault()}
        //         <GroupTitle 
        //             title='Templates'
        //             hasAdd={{onAdd : this.AddTempalte}}
        //         />
        //         {this.renderTemplates()}
        //     </SC.FCol>
        // )
    }
    renderDefault() {

    }
    renderTemplates() {

    }    
    renderCustom() {
        return (
            <React.Fragment>                         
                <TokenGroup 
                    title={this.title} 
                    forSelect={this.props.forSelect}
                    expandable={{expanded : this.expanded,  onExpand : this.onToggleExpand}}
                    last={this.props.last}
                    style={{position : 'relative'}}
                >
                    {
                        this.expanded && 
                        <motion.div
                            initial={{opacity : 0.5, y : -4}}
                            animate={{opacity : 1, y : 0}}
                            transition={{duration  :0.2}}
                        >
                            {this.renderContent()}                        
                        </motion.div>
                    }                
                </TokenGroup>                               
            </React.Fragment>            
       )
    }
}