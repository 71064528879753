import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    Strings
} from '../../../../../../importer';
import { onConstructItem, onItemShouldUpdate, onMouseDownOnItem, onItemClick, onItemDidMount, onItemWillUnMount, onItemDidUpdate } from '../common';

import Video from './video';
import MapBox from './mapbox';
import Widget_Lottie from './lottie';
import Widget_ApexChart from './apexchart';
import Widget_eCharts from './echarts';

export default class Widget extends React.Component {
    render() {
        const MetaItem = this.props.GetComponentManager().GetMetaItem(this.props.Id);
        const WidgetType = Utils.Get(MetaItem, null, 'Widget', 'Type');
        if (WidgetType === MetaData.Components.Widget.WidgetTypes.Video.Type) {
            return (
                <Video 
                    {...this.props}
                />
            )
        }
        else if (WidgetType === MetaData.Components.Widget.WidgetTypes.MapBox.Type) {            
            return (
                <MapBox 
                    {...this.props}
                />
            )
        }
        else if (WidgetType === MetaData.Components.Widget.WidgetTypes.ApexCharts.Type) {
            return (
                <Widget_ApexChart {...this.props} />
            )
        }
        else if (WidgetType === MetaData.Components.Widget.WidgetTypes.eCharts.Type) {
            return (
                <Widget_eCharts {...this.props} />
            )
        }
        else if (WidgetType === MetaData.Components.Widget.WidgetTypes.Lottie.Type) {
            return (
                <Widget_Lottie {...this.props} />
            )
        }
        return ( 
            <div
            >
                'Widget'
            </div>
        );
    }
}
