import React from 'react';
import {
    ReactBaseComponent,
    SC,
    AppLayout,
    MetaData,
    Globals,
    Strings
} from '../../../../../../importer';

import styled from 'styled-components';
import ToolbarIcons from '../../../../../../styled/toolbar';

import Select_Widget from '../../../../selectors/widget';
import { WidgetGroup, TokenPanelHeader } from '../../designsystem/common';
import UIElementItem, {DraggableToolbarItem} from './element';
import Components from '../../components';
import NewComponentWizard from '../../components/wizard';
import { Seperator } from '../../../right/iteminspector/styleitems/common';
import { AnimatePresence, motion } from 'framer-motion';

export default class ToolbarUIElements extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.OnDragEnd = this.OnDragEnd.bind(this);
        this.AddNewComponent = this.AddNewComponent.bind(this);

        this.ExpandOptions = {
            Widgets : {
                expanded : Globals.ProjectManager.Options.Get(true, 'LeftPanel', 'ToolbarElements', 'Widgets', 'Expanded'),
                onExpand : this.onToggleGroupExpand.bind(this, 'Widgets')
            }
        }

        this.onToggleGroupExpand = this.onToggleGroupExpand.bind(this);
    }
    onToggleGroupExpand(group) {        
        this.ExpandOptions[group].expanded = !this.ExpandOptions[group].expanded;
        Globals.ProjectManager.Options.Set(this.ExpandOptions[group].expanded, 'LeftPanel', 'ToolbarElements', 'Widgets', 'Expanded');
        this.RCUpdate();
    }
    OnDragEnd() {
        setTimeout(() => {
            AppLayout.Refs.Studio.Main.onToggleDesignPanel();
        }, 50);        
    }
    AddNewComponent() {
        this.setState({newComponent : true});
    }
    renderCustom() {
        let wizardItem;

        if (this.state.newComponent) {
            wizardItem = (
                <AnimatePresence>
                    {
                        this.state.newComponent && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter, display : 'flex', flexDirection : 'column'}}
                            initial={{opacity : 0.7, x : 24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : 24}}
                            transition={{duration : 0.2}}
                        >                                                 
                            <NewComponentWizard 
                                onClose={() => {this.setState({newComponent : false})}}
                                onAdded={(Id) => {                                    
                                    this.setState({newComponent : false});
                                }}
                                OnDragEnd={this.OnDragEnd}
                            />
                        </motion.div>
                    }
                </AnimatePresence>
            )
        }
        

        return (
            <SC.FCol fw fh style={{position : 'relative'}}>
                <TokenPanelHeader title='ELEMENTS' onClose={this.props.onClose} style={{backgroundColor : SC.CurrentTheme.theme.back_lighter}}/>
                <SC.CustomScrollbars>
                    <UIElementGrid>
                        <DraggableToolbarItem 
                            title='Flex Box'
                            IconType={ToolbarIcons.Container}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Div.Type
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Grid Box'
                            IconType={ToolbarIcons.Layout}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Div.Type,
                                        GridLayout : true
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Text'
                            IconType={ToolbarIcons.Text}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Text.Type
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Image'
                            IconType={ToolbarIcons.Image}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Image.Type
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Icon'
                            IconType={ToolbarIcons.Icon}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.SvgIcon.Type
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Slot'
                            IconType={ToolbarIcons.ExternalContent}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Content.Type
                                    }
                                } 
                            }}
                        />
                        <DraggableToolbarItem 
                            title='Repeater'
                            IconType={ToolbarIcons.Repeater}
                            OnDragEnd={this.OnDragEnd}
                            DragItem={{
                                Type : Strings.TOOLBARITEM,
                                Item : {
                                    MetaItem : {
                                        Type : MetaData.Components.Repeater.Type
                                    }
                                } 
                            }}
                        />
                    </UIElementGrid>
                    <Seperator />
                    <Components 
                        justContent
                        expandable
                        GlobalState={this.props.GlobalState}
                        GlobalStateId={this.props.GlobalStateId}
                        themeId={this.props.themeId}
                        disableRender={this.props.disableRender}
                        onAdd={this.AddNewComponent}
                        onClose={this.props.onClose}
                    />
                    <Seperator />
                    <WidgetGroup 
                        title='WIDGETS' 
                        expandable={this.ExpandOptions.Widgets}
                    >
                        {
                            this.ExpandOptions.Widgets.expanded && 
                            <Select_Widget 
                                justContent
                            />
                        }                        
                    </WidgetGroup>               
                </SC.CustomScrollbars>          
                {wizardItem}      
            </SC.FCol>   
        )
    }
}



export const UIElementGrid = styled.div`
    display : grid;
    grid-template-columns : 1fr 1fr;
    grid-auto-rows : 60px;
    grid-gap : 8px;
    margin : 8px;
    margin-left : 10px;
    margin-right : 10px;
`;