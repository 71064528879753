const types = {
    any : 'any',
    list : 'list',
    object : 'object',
    string : 'string',    
    url : 'url',
    enum : 'enum',
    color : 'color',
    size : 'size',
    number : 'number',
    boolean : 'boolean',
    token : 'token',
    angle : 'angle',
    array : 'array',
    array_number : 'array-number',
    svg_length : 'svg_length',
    SubComponent : 'SubComponent',
    MetaSvg : 'MetaSvg',
    ElementTemplate : 'ElementTemplate',
    StyleSets : 'StyleSets',
    backgroundImage : 'backgroundImage',
    backgroundPosition : 'backgroundPosition',
    backgroundSize : 'backgroundSize',
    MetaImage : 'MetaImage',
    MetaIcon : 'MetaIcon',
    BoxShadow : 'BoxShadow',
    TextShadow : 'TextShadow',
    Transform : 'Transform',
    Filter : 'Filter',
    fontFamily : 'fontFamily',
    Provider : 'Provider',
    WidgetOptions : 'WidgetOptions',
    TokenOptions : 'TokenOptions',
    GenericOptions : 'GenericOptions',
    flex : 'flex',
    image : 'image',
    icon : 'icon'
};

types.ConvertibleFrom = {
    size : {
        'types' : [
            types.string,
            types.number
        ]
    },
    string : {
        'types' : [
            types.size,
            types.number,
            types.color,
            types.boolean
        ]
    }
} ;

export const IsDataTypeConvertibleFrom = (ToType, FromType) => {
    if (ToType === types.string) {
        return FromType === types.string || FromType === types.number;
    }
    return FromType === ToType;
}

types.AllValueSetTypes = {
    ToValue : {
        Id : 'ToValue',
        Label : 'To Value'
    },
    Reset : {
        Id : 'Reset',
        Label : 'Reset Value'
    },
    Switch : {
        Id : 'Switch',
        Label : 'Switch Values'
    },
    Toggle : {
        Id : 'Toggle',
        Label : 'Toggle Value'
    }
};

types.Labels = {    
    
};
types.Labels[types.color] = 'Color';
types.Labels[types.string] = 'Text';
types.Labels[types.size] = 'Size';
types.Labels[types.number] = 'Number';
types.Labels[types.boolean] = 'Boolean';
types.Labels[types.angle] = 'Angle';
types.Labels[types.object] = 'Object';
types.Labels[types.list] = 'List';
types.Labels[types.image] = 'Image';
types.Labels[types.icon] = 'Icon';

types.SetValueTypes = {
    all : [
        types.AllValueSetTypes.ToValue,
        types.AllValueSetTypes.Reset,
        types.AllValueSetTypes.Switch,
    ],
    bool : [        
        types.AllValueSetTypes.Toggle
    ]
}
export default types;

export const DataTypeList = [
    {id : types.string, label : types.Labels[types.string]},
    {id : types.boolean, label : types.Labels[types.boolean]},
    {id : types.number, label : types.Labels[types.number]},
    {id : types.image, label : types.Labels[types.image]},
    // {id : types.icon, label : types.Labels[types.icon]},
    // {id : types.color, label : types.Labels[types.color]},
    // {id : types.object, label : types.Labels[types.object]},
    // {id : types.list, label : types.Labels[types.list]}
]