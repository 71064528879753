import React, { useState } from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    AppLayout
} from '../../../importer';

import TextInput from '../../../components/editors/textinput';
import { Seperator } from '../../project/panels/right/iteminspector/styleitems/common';
import { DotMenu } from '../../project/panels/left/common';
import DropDownSelect from '../../../components/editors/enum_dropdown';
import { GetDataApi, GetUserId } from '../../../appstate/AppState';
import { MemberAvatar } from '../../project/panels/right/team/members';

export const TeamMemberTypeSelector = (props) => {
    return (
        <DropDownSelect 
            style={{backgroundColor : SC.CurrentTheme.theme.back, ...props.style}}
            hasBorder                                     
            noBackColor   
            autoHeight
            dark
            value={props.value}
            onChange={props.onChange}
            items={[
                {id : AppState.Team.EditorTypes.admin.id, label : AppState.Team.EditorTypes.admin.title},
                {id : AppState.Team.EditorTypes.edit.id, label : AppState.Team.EditorTypes.edit.title},
                {id : AppState.Team.EditorTypes.view.id, label : AppState.Team.EditorTypes.view.title},
            ]}
        />
    )
}

export class TeamEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.InviteMember = this.InviteMember.bind(this);

        this.team = {
            members : []
        };

        if (this.props.isNew) {

        }
        else {
            this.state.loading = true;
        }

    }
    componentDidMount() {
        super.componentDidMount();
        if (this.props.id) {
            this.LoadTeam(this.props.id, true);
        }
    }
    LoadTeam(id, willListen) {
        GetDataApi().get_team_data(id).then((result) => {
            this.team = result;
            this.setState({loading : false, loadingMembers : false, inviting : false});

            if (willListen) {
                this.RefListener = GetDataApi().api.get_ref(`/teams/${id}/members/`);
                let initialListen = true;
                this.RefListener.on("value",(snapshot) => {
                    if (initialListen) {
                        initialListen = false;
                        return;
                    }
                    this.setState({loadingMembers : true}, () => {
                        this.LoadTeam(id, false);
                    })
                });
            }
        })
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        if (this.RefListener) {
            this.RefListener.off('value');
        }        
    }
    SaveName(name) {
        this.team.name = name;        
        if (!this.props.isNew) {
            this.props.team.name = name;
            GetDataApi().api.set_path(`${GetDataApi().GetPaths().team_data(this.props.id)}/name/`, name);            
        }
        this.RCUpdate();
    }
    InviteMember(type, email) {
        if (this.props.isNew) {
            this.team.members.push({
                email : email,
                type : type,
                id : Utils.Id()
            });
            this.RCUpdate();
        }
        else {
            this.setState({inviting : true}, () => {
                GetDataApi().invite_team_member({
                    id : this.props.id,
                    email : email,
                    type : type
                }).then(() => {              
                    this.LoadTeam(this.props.id);
                })
            })            
        }
    }
    ChangeMemberType(member, type) {
        member.type = type;
        if (!this.props.isNew) {
            GetDataApi().api.set_path(`${GetDataApi().GetPaths().team_data(this.props.id)}/members/` + member.id + '/type', type);        
        }
        
        this.RCUpdate();
    }
    ReInvite(member) {
        GetDataApi().resend_invitation(member.email, this.team.name);
    }
    RemoveMember(member) {
        if (this.props.isNew) {

        }
        else {
            GetDataApi().api.delete_path(`${GetDataApi().GetPaths().team_data(this.props.id)}/members/` + member.id);
            if (member.invitationId) {
                GetDataApi().api.delete_path(`${GetDataApi().GetPaths().team_data('invites')}/` + member.invitationId);
            }
            if (member.uid) {
                GetDataApi().api.delete_path(`${GetDataApi().GetPaths().user_teams(member.uid)}/` + this.props.id);                
            }
        }
        
        Utils.Remove(this.team.members, (m) => {return m.id === member.id});
        if (member.uid === GetUserId()) {
            this.props.onClose();
        }
        else {
            this.RCUpdate();
        }        
    }
    CreateTeam() {
        GetDataApi().create_team({
            name : Utils.UseNullOrEmpty(this.team.name, AppState.User.displayName + '\'s Team')
        }).then(async (result) => {
            if (result.id) {
                const ps = [];
                Utils.ForEach(this.team.members, (member, i) => { 
                    ps.push(GetDataApi().invite_team_member({id : result.id, email : member.email, type : member.type}));
                });
                const rs = await Promise.all(ps);
                if (rs) {
                    this.props.onLoad();
                }
                this.props.onClose();
            }
        });
    }
    renderCustom() {
        return (
            <React.Fragment>
                <SC.FCol style={{
                    padding : '16px',
                    backgroundColor : SC.CurrentTheme.theme.back_lighter,
                    borderRadius : '4px',
                    border : SC.CurrentTheme.theme.border_seperator,
                    marginTop : '8px',
                    marginBottom : '24px'
                }}>
                    {
                        this.state.loading ? <SC.Div_Flex_Cell fw fh><SC.Loading_Icon small/></SC.Div_Flex_Cell> : 
                        <React.Fragment>
                            <div style={{
                                display : 'grid',
                                gridTemplateColumns : 'auto 1fr',
                                alignItems : 'center',
                                gridGap : '16px'
                            }}>
                                <div>Team Name</div>
                                <TextInput 
                                    value={this.team.name}
                                    autoFocus
                                    style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', padding : '4px', paddingLeft : '8px', height : 'auto'}}
                                    themeId={AppLayout.ThemeId}
                                    onChange={this.SaveName.bind(this)}
                                />
                            </div>
                            <div style={{
                                marginTop : '8px',
                                display : 'grid',
                                gridTemplateColumns : '24px auto auto auto 24px',
                                gridGap : '16px',
                                gridRowGap : '8px',
                                alignItems : 'center',
                                marginBottom : '8px',
                                userSelect : 'text'
                            }}>
                                <Seperator style={{gridColumnStart : 'span 5', marginBottom : '4px'}}/>
                                {
                                    !this.props.isNew && 
                                    <React.Fragment>
                                        <SC.FRow alc style={{gridColumnStart : 'span 5'}}>
                                            <SC.HeaderTitle style={{fontSize : '12px', marginRight : '8px'}}>OWNER</SC.HeaderTitle>
                                            {
                                                (this.state.loadingMembers) && <SC.Loading_Icon small/>
                                            }
                                        </SC.FRow>
                                        <MemberAvatar name={this.team.owner.name} noState />
                                        <SC.TextDivAbbr>{this.team.owner.name} </SC.TextDivAbbr>
                                        <SC.TextDivAbbr>{this.team.owner.email}</SC.TextDivAbbr>                                        
                                        <div></div>
                                        <div></div>
                                        <Seperator style={{marginTop : '4px', marginBottom : '4px', gridColumnStart : 'span 5'}}/>
                                    </React.Fragment>   
                                }                                                                                
                                <SC.FRow alc style={{gridColumnStart : 'span 5'}}>
                                    <SC.HeaderTitle style={{fontSize : '12px', marginRight : '8px'}}>MEMBERS ({this.team.members.length})</SC.HeaderTitle>
                                    {
                                        (this.state.loadingMembers) && <SC.Loading_Icon small/>
                                    }
                                </SC.FRow>
                                {
                                    this.team.members && this.team.members.map((member) => {
                                        return (
                                            <TeamMember 
                                                key={member.id} 
                                                member={member} 
                                                onChangeType={this.ChangeMemberType.bind(this, member)}
                                                onRemove={this.RemoveMember.bind(this, member)}
                                                onReinvite={this.ReInvite.bind(this, member)}
                                                isNew={this.props.isNew}
                                            />
                                        )
                                    })
                                }                            
                            </div>                            
                            
                            <Seperator />
                            <InviteMemberItem 
                                isNew={this.props.isNew}
                                onAdd={this.InviteMember}
                                inviting={this.state.inviting}
                            />
                        </React.Fragment>
                    }                    
                </SC.FCol>
                {
                    this.props.isNew && 
                    <SC.FRow justifyEnd>
                        <SC.Buttons.RoundButton small style={{width : '120px', marginRight : '16px'}} onClick={this.props.onClose}>Cancel</SC.Buttons.RoundButton>
                        <SC.Buttons.RoundButton small style={{width : '120px'}} primary onClick={this.CreateTeam.bind(this)}>Create</SC.Buttons.RoundButton>
                    </SC.FRow>
                }
                {/* <SC.Buttons.RoundButton small style={{width : '120px'}} primary onClick={() => {
                    this.team.members && this.team.members.length > 0 && 
                    this.team.members.map((member) => {
                        const ref_message = GetDataApi().api.getNotificationsDb().ref(`/users/${member.uid}/new/`).push();
                        ref_message.set({
                            from : 'admin',
                            message : 'Test message'
                        });

                    })
                }}>Message</SC.Buttons.RoundButton> */}
            </React.Fragment>
        )
    }
}

class TeamMember extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }

        this.ShowMenu = this.ShowMenu.bind(this);
    }
    ShowMenu() {
        this.setState({showMenu : !this.state.showMenu});
    }
    render() { 
        const {member} = this.props
        return (
            <React.Fragment >
                {member.active ? <MemberAvatar noState name={member.name} />  : <div />}
                {member.active && <div>{member.name}</div>}
                {!member.active && <div style={{color : SC.CurrentTheme.theme.color_brand}}>Pending Invitation</div>}
                <SC.TextDivAbbr>{member.email}</SC.TextDivAbbr>                
                <TeamMemberTypeSelector value={member.type} onChange={this.props.onChangeType} />
                <DotMenu onClick={this.ShowMenu} isOpen={this.state.showMenu} onClose={this.ShowMenu}>
                    {
                        this.state.showMenu &&
                        <SC.FCol style={{minWidth : '160px'}}>
                            <SC.PopupItem onClick={this.EditStates} onClick={this.props.onRemove}>
                                Remove from Team
                            </SC.PopupItem>
                            {
                                !member.active && !this.props.isNew &&
                                <SC.PopupItem onClick={() => {
                                    this.setState({showMenu : false}, () => {
                                        this.props.onReinvite();
                                    })
                                }}>
                                    Resend Invitation
                                </SC.PopupItem>
                            }                            
                        </SC.FCol>
                    }
                </DotMenu>  
            </React.Fragment>
        )
    }
}

export class InviteMemberItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  
            newMemberEmail : '',
            newMemberType : 'edit',
            autoFocus : false,
            valid : true
        }

        this.ValidateEmail = this.ValidateEmail.bind(this);        
    }
    ValidateEmail(email) {        
        const valid = !Utils.IsNotNullOrEmpty(email) || Utils.ValidateEmail(email);
        this.setState({valid : valid, newMemberEmail : email});
    }
    InviteMember() {
        this.props.onAdd(this.state.newMemberType, this.state.newMemberEmail);
        this.setState({newMemberEmail : '', autoFocus : true}, () => {
            this.state.autoFocus = false;
        });
    }
    render() { 
        return (  
            <React.Fragment>        
                {!this.props.compact && <SC.HeaderTitle style={{fontSize : this.props.compact ? '11px' : '12px', marginTop : '8px'}}>{this.props.isNew ? 'ADD' : 'INVITE'} MEMBER</SC.HeaderTitle>}
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : this.props.compact ? '1fr 100px' : '1fr 140px 120px',                    
                    alignItems : 'center',
                    gridGap : '16px',
                    marginTop : '16px'
                }}>
                    <SC.Div_Relative style={{gridColumnStart : this.props.compact ? 'span 2' : 'span 1'}}>
                        {SC.RenderValueError(!this.state.valid, 'Not Valid')}
                        <TextInput 
                            dark
                            value={this.state.newMemberEmail}
                            style={{backgroundColor : SC.CurrentTheme.theme.back, fontSize : '14px', lineHeight : '24px', paddingLeft : '8px', height : 'auto'}}
                            themeId={AppLayout.ThemeId}
                            onChange={this.ValidateEmail}
                            placeholder='Member Email'
                            autoFocus={this.state.autoFocus}
                        />
                    </SC.Div_Relative>
                    <TeamMemberTypeSelector 
                        style={{height : '34px', maxHeight : '34px'}} 
                        value={this.state.newMemberType}
                        onChange={(type) => this.setState({newMemberType : type})}
                    />
                    {
                        this.props.inviting ? <SC.Loading_Icon small style={{justifySelf : 'center'}} /> : 
                        <SC.Buttons.RoundButton small style={{width : 'auto'}} onClick={this.InviteMember.bind(this)}>
                            {this.props.isNew ? 'Add' : 'Send'}
                        </SC.Buttons.RoundButton>
                    }                                
                </div>
            </React.Fragment>
        );
    }
}
