import React from 'react';
import ReactBase from '../ReactBase';
import {Utils} from '../importer';
import SubComponent from '../items/SubComponent';

export default class ToolabsComponent extends ReactBase
{
    constructor(props) {
        super(props);

        this.GetComponentMetaData = this.GetComponentMetaData.bind(this);
        this.GetElementTemplate = this.GetElementTemplate.bind(this);
        this.onChangeTheme = this.onChangeTheme.bind(this);
        this.LoadGlobalState = this.LoadGlobalState.bind(this);

        this.Load(this.props);
    }
    GetComponentMetaData(Id) {
        let promise = Utils.Get(this, null, 'Promises_GetData', Id);
        if (promise)
            return promise;
        promise = new Promise((resolve) => {
            if (Id === this.props.Id) {
                resolve(this.RootComponentMetaData);
                Utils.UnSet(this, 'Promises_GetData', Id);
            }
            else {
                this.props.Store.GetComponentMetaData(Id).then((Result) => {
                    resolve(Result.MetaData);
                    Utils.UnSet(this, 'Promises_GetData', Id);
                })
            }
        });
        // Utils.Set(this, promise, 'Promises_GetData', Id);
        return promise;
    }
    Load(props) {
        if (!props.Store) {
            console.log(`ASSERT : Null Store`);
            return;
        }            

        props.Store.GetComponentMetaData(props.Id).then((Result) => {
            this.RootComponentMetaData = Result.MetaData;            

            this.BoardData = Result.BoardData;            
            this.CalculateScale(props);
            this.StateId = Utils.Id();
            super.RCSetStateValue('Loaded', true);
        });
    }
    LoadGlobalState() {
        
    }
    CalculateScale(props) {
        if (props.AutoScale && (props.width || props.height)) {            
            
            let width_design = Utils.Get(this.RootComponentMetaData, props.width, 'Preview', 'Size', 'width');
            let height_design = Utils.Get(this.RootComponentMetaData, props.width, 'Preview', 'Size', 'height');
            
            let scale = 1;
            let scale_width = 1;
            let scale_height = 1;
            if (props.width)
                scale_width = props.width / width_design;
            if (props.height)
                scale_height = props.height / height_design;

            scale = Math.min(scale_width, scale_height);
            if (scale > 1)
                scale = 1;
            

            this.scaled_style = {
                width : `${width_design * scale}px`,
                height : `${height_design * scale}px`,
                display : 'grid',
                alignContent : 'center',
                justifyContent : 'center',
                gridTemplateColumns : '1fr',
                gridTemplateRows : '1fr',
                alignItems : 'center',
                justifyItems : 'stretch'
            }            
            this.style_scaled = {
                transform : `scale(${scale})`,
                width : Utils.px(width_design),
                height : Utils.px(height_design),
                // transformOrigin : 'top left'
            };
            if (width_design === 1024 && scale !== 1) {
                this.scaled_style.width = Utils.px(props.width);
                this.style_scaled.width = Utils.px(props.width / scale);
            }
            if (height_design === 1024 && scale !== 1) {
                this.scaled_style.height = Utils.px(props.height);
                this.style_scaled.height = Utils.px(props.height / scale);
            }

            this.previewScale = scale;

        }
    }
    onChangeTheme(Variations) {
        if (this.props.onSystemStateChanged)
            this.props.onSystemStateChanged(Variations);
        else
            this.RCUpdate();
    }
    GetElementTemplate(ElementIds) {
        const template = {
            style : {},
            props : {}
        }
        if (ElementIds && Array.isArray(ElementIds)) {
            Utils.ForEach(ElementIds, (ElementId, i) => {
                const ElementTemplate = Utils.JustGet(this.BoardData, {}, 'Theme', 'Elements', ElementId);
                if (ElementTemplate) {
                    template.style = Utils.Merge(template.style, Utils.JustGet(ElementTemplate, {}, 'Styles', 'Static'));
                    template.props = Utils.Merge(template.props, Utils.JustGet(ElementTemplate, {}, 'Props', 'Static'));

                    const element_system_style = Utils.JustGet(ElementTemplate, {}, 'Styles', 'System');
                    if (element_system_style) {
                        Utils.ForEach(element_system_style, (styleId, styleName) => {
                            if (this.Theme[styleId]) {
                                if (styleName === 'fontFamily') {
                                    Utils.MergeTo(template.style, this.Theme[styleId]);
                                }
                                else
                                    template.style[styleName] = this.Theme[styleId];
                            }
                        });
                    }

                    const element_system_props = Utils.JustGet(ElementTemplate, {}, 'Props', 'System');
                    if (element_system_props) {
                        Utils.ForEach(element_system_props, (styleId, styleName) => {
                            if (this.Theme[styleId]) {
                                if (styleName === 'fontFamily') {
                                    Utils.MergeTo(template.props, this.Theme[styleId]);
                                }
                                else
                                    template.props[styleName] = this.Theme[styleId];
                            }
                        });
                    }

                    if (ElementTemplate.States) {
                        Utils.ForEach(ElementTemplate.States, (ItemState, ItemStateName) => {
                            const state_style_static = Utils.JustGet(ItemState, {}, 'Styles', 'Static', 'Default');
                            const state_style_system = Utils.JustGet(ItemState, {}, 'Styles', 'System', 'Default');
                            if (state_style_system) {
                                Utils.ForEach(state_style_system, (styleId, styleName) => {
                                    if (this.Theme[styleId]) {
                                        if (styleName === 'fontFamily') {
                                            Utils.MergeTo(state_style_static, this.Theme[styleId]);
                                        }
                                        else
                                            state_style_static[styleName] = this.Theme[styleId];
                                    }
                                });
                            }
                            Utils.Set(template, state_style_static, 'states', ItemStateName, 'style');
                        });
                    }
                }
            });
        };

        return template;
    }

    shouldComponentUpdate(nextProps, nextState) {
        let willUpdate = false;

        if (this.props.Id !== nextProps.Id) {
            this.Load(nextProps);
            return false;
        }
        if (this.props.ThemeId !== nextProps.ThemeId) {
            this.ThemeId = nextProps.ThemeId;
            willUpdate = true;
        }
        
        if (!Utils.IsEqual(nextProps.Variations, this.props.Variations)) {
            willUpdate = true;
        }
        if (!Utils.IsEqual(nextProps.Models, this.props.Models)) {
            willUpdate = true;
        }

        if (nextProps.width !== this.props.width || nextProps.height !== this.props.height) {
            this.CalculateScale(nextProps);
            willUpdate = true;
        }
        if (!willUpdate) {
            if (nextState.Loaded !== this.state.Loaded || nextState.ShouldUpdate !== this.state.ShouldUpdate)
                willUpdate = true;
        }
        if (!willUpdate) {
            if (this.props.Design) {
                if (this.props.Design.ComponentStateId !== nextProps.Design.ComponentStateId) {
                    this.state.Loaded = false;
                    this.Load(nextProps);
                }
                if (Utils.HasAnyChange(this.props, nextProps, 'Design')) {
                    this.ThemeId = Utils.Id();
                    willUpdate = true;
                }
            }
        }
        return willUpdate;
    }
    componentDidUpdate(prevProps, prevState) {
        if (!prevState.Loaded && this.state.Loaded)
            this.props.onLoaded && this.props.onLoaded();
    }
    renderCustom() {
        if (!this.state.Loaded)
            return null;

        const {style, onStateChanged, onModelChanged, onSystemStateChanged, Design, PublicProps, GetCustomContent, Store, options, ...RestProps} = this.props;
        const rootstyle = Utils.Merge(this.props.style, this.style_scaled);
        
        const item = (
            <SubComponent
                {...RestProps}
                key={this.props.Id}
                Id={this.props.Id}
                GetComponentMetaData={this.GetComponentMetaData}
                ThemeId={this.ThemeId}
                Theme={this.props.ActiveTheme.Theme}
                GlobalVariations={this.props.GlobalVariations}
                SystemStateArray={this.props.ActiveTheme.SystemStateArray}
                ReversedSystemStateArray={this.props.ActiveTheme.ReversedSystemStateArray}
                style={rootstyle}
                Variations={this.props.Variations || this.PublicVariations}
                StateId={this.StateId}
                previewScale={this.previewScale}
                AutoScale={this.props.AutoScale}
                Design={this.props.Design}
                onModelChanged={onModelChanged}
                onStateChanged={onStateChanged}
                Models={this.props.Models}
            />
        );

        if (this.scaled_style) {
            return (
                <div style={this.scaled_style}>
                    {item}
                </div>
            )
        }
        return item;
    }
}
