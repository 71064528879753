import React from 'react';
import BaseItem from '../../BaseItem';
import {Utils} from '../../../importer';
import { GetSubItems } from '../../Container';
import { motion } from 'framer-motion';

class Button extends React.Component {
    constructor(props) {
        super(props);

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);

        this.state = {
            value : this.props.value || ''
        }

        this.updateStyle(props);
    }
    updateStyle(props) {
        const style = props.style;        
        const classId = props.classId;

        

        const {
            ':hover' : styleobj_track_hover, 
            ':focus' : styleobj_track_focus, 
            ':active' : styleobj_track_active, 
            ...styleobj_track_rest} = style;


        const style_track = {
            width : '100%',
            fontFamily : 'unset',
            fontSize : 'unset',
            ...styleobj_track_rest,
        };

        let css_track = Utils.ConvertObjectToCss(style_track);
        let css_track_hover = Utils.ConvertObjectToCss(styleobj_track_hover);
        let css_track_focus = Utils.ConvertObjectToCss(styleobj_track_focus);
        let css_track_active = Utils.ConvertObjectToCss(styleobj_track_active);
      

        Utils.SetStyleSheet({
            Id : classId, 
            StyleDefinition : `
        .${classId} {
            ${css_track}
          }
          .${classId}:focus {
            ${css_track_focus}
          }
          .${classId}:active {
            ${css_track_active}
          }
          .${classId}:hover {
            ${css_track_hover}
          }
        `, TargetDocument : this.props.document})
    }
    componentWillUnmount() {
        Utils.RemoveStyleSheet(this.props.classId);
    }
    shouldComponentUpdate(nextProps, nextState) {        
        if (this.props.classId !== nextProps.classId || !Utils.IsEqual(this.props.style, nextProps.style)) {
            this.updateStyle(nextProps);
        }        
        return true;
    }
    onFocus(e) {        
        this.props.onFocus && this.props.onFocus(e);
        if (window.AppState) {
            window.AppState.Designer.SetEditingText(true);;
        }
    }
    onBlur(e) {
        this.props.onBlur && this.props.onBlur();
        if (window.AppState) {
            window.AppState.Designer.EditingText = false;
        }
    }
    render() {                        
        const {style, classId, document, transitions, onSwitchToEmptyState, ...rest} = this.props;
        
        if (transitions && transitions.hover) {        

            return (
                <motion.button
                    initial={{
                        opacity : Utils.UseNullOrEmpty(style.opacity, 1)
                    }}
                    whileHover={{...transitions.hover.changes}}
                    transition={{...transitions.hover.transition}}
                    // transformTemplate={transformTemplate}
                    {...rest}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    className={this.props.classId}     
                >
                    {this.props.children}
                </motion.button>
            );
        }

        return (
            <button
                {...rest}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                className={this.props.classId}                
            >
                {this.props.children}
            </button>
        )
    }
}


class Generic_Button extends BaseItem
{
    constructor(props) {
        super(props);
        this.InstanceId = Utils.Id();
    }    
    renderItem({style, props, events, transitions, rest}) {                
        let content;
        const childItems = GetSubItems(this.props.MetaItem.SubItems, this.props, {}, this.RootStateId);
        if (childItems) {
            if (childItems.length > 0) {
                content = childItems;
            }
        }
        const usestyle = {
            display : 'flex', alignItems : 'center', justifyContent : 'center',
            ...style
        };
        if (!content) {
            content = props.text;        
            if (props.truncate) {
                usestyle.textOverflow = 'ellipsis';
                usestyle.overflow = 'hidden';
                usestyle.whiteSpace = 'nowrap'; 

                delete usestyle.display;
                delete usestyle.flexDirection;
                delete usestyle.alignItems;
                delete usestyle.justifyContent;
            }
        }            

        let transitionHover = Utils.JustGet(transitions, null, 'hover');
        if (!transitionHover) {
            if (this.props.RootItem) {
                transitions.hover = Utils.JustGet(this.props.RootItem.transitions, null, 'hover');
            }
        }

        return (
            <Button
                ref={(r) => this.Ref_Instance = r}
                style={usestyle}
                {...events}
                {...rest}
                transitions={transitions}
                disabled={this.props.IsStateActive('disabled', 'disabled')}
                classId={`gcsstf-${this.InstanceId}`}
            >
                {content}
            </Button>
        )
    }
}

export default Generic_Button;