import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { ItemNameEditor } from '../../common';
import TransformEditor from '../../../../../../../components/editors/transform';

export default class TransformTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onTransformChanged = this.onTransformChanged.bind(this);
        this.LogTokenChange = this.LogTokenChange.bind(this);
        
        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
        }
        else {
            this.Load(this.props.id);            
        }
    }
    Load(id) {
        this.token = Globals.ProjectManager.Tokens.Transform(id);
        this.EditingModel = {
            items : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'items'),
            originX : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), 'center', 'originX'),
            originY : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), 'center', 'originY')
        };
        
        if (this.EditingModel.originX === 'custom') {
            this.EditingModel.customOriginX = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), '0', 'customOriginX');
        }
        if (this.EditingModel.originY === 'custom') {
            this.EditingModel.customOriginY = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), '0', 'customOriginY');
        };

        this.EditingModel = Utils.DeepClone(this.EditingModel);
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.GlobalState !== this.props.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!this.props.newModel) {
                this.token = Globals.ProjectManager.Tokens.Transform(nextProps.id);
                if (!this.token) {
                    this.props.onCancel();
                    return false;
                }
                this.EditingModel.items = Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'items');
            }
        }
        if (this.props.id !== nextProps.id)
            this.Load(nextProps.id);
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Transforms);
    }        
    onTransformChanged() {
        if (!this.TransformChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Transform'});
            this.TransformChangeLogged = true;
        }
        if (this.props.newModel)
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel);
        else {
            const transformStyle = Utils.GetTransformCssAll(Utils.Get(this.EditingModel, [], 'items'), this.EditingModel.originX, this.EditingModel.orignY, true);
            transformStyle.transformOrigin = Utils.GetTransformOrigin(this.EditingModel);
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Transforms,
                    value : transformStyle
                }
            ]);
        }
            
        this.RCThrottledUpdate_1();
    }
    LogTokenChange() {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Transform'});
    }

    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.ChangeTokenName(this.props.id, name);
        this.RCUpdate();
    }
    renderCustom() {            
        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <TransformEditor
                    id={this.props.id}
                    style={{marginTop : '8px'}}
                    model={this.EditingModel}
                    onUpdate={this.onTransformChanged}
                    offline={this.props.offline}
                    ownerPosition={'LeftPanel'}
                    onBeforeChange={this.LogTokenChange}
                /> 
            </SC.FCol>
        )
    }
}