import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    Strings,
    Globals
} from '../../../../../../../../importer';

import { ItemNameEditor, TokenPanelHeader } from '../../../common';
import FontPreview from './preview';
import FontSelectPanel from '../selector';
import { AnimatePresence, motion } from 'framer-motion';
import { SetFontTypeFace } from '../..';
import TextValue from '../../../../../../../../components/editors/textvalue';
import { Seperator } from '../../../../../right/iteminspector/styleitems/common';
import { InfoPanel } from '../../../../../../../../components/info';
import TextInput from '../../../../../../../../components/editors/textinput';
import { FontLoader } from '../../../../../../../../toolabs-importer';

export default class ShadowTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.onSelectFont = this.onSelectFont.bind(this);
        this.SaveName = this.SaveName.bind(this);
        this.SetFontStack = this.SetFontStack.bind(this);
        
        this.GetItems = this.GetItems.bind(this);
        this.CloseSelectFont = this.CloseSelectFont.bind(this);
        this.Ref_Name = React.createRef();
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.GlobalStateId !== nextProps.GlobalStateId) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id);
            if (!token) {
                this.props.onClose();
                return false;
            }
        }
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.Fonts();
    }        
    onSelectFont() {
        // this.props.onSelectFont(this.RCUpdate, this.props.id, this.CloseSelectFont);
        // return;
        this.showFontSelector = true;
        this.RCUpdate();
    }
    CloseSelectFont(close) {
        super.AddCloseCallback(close);
    }
    SaveName(name) {
        Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : name} );
        this.RCUpdate();
    }
    SetFontStack(fontStack) {
        Globals.ProjectManager.Tokens.SetValue({id : this.props.id, name : 'fontStack', value : fontStack} );
    }
    SetCustomFontFamily(family) {
        if (this.props.id) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
            const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont() : Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
            if (font.fontId) {
                Globals.ProjectManager.Tokens.SetSubValue({id : this.props.id, prop : 'family', value : family});
                Globals.ProjectManager.DataManager.Catched.Set(family, 'CustomFonts', font.fontId, 'family');
                let CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, font.fontId);
                if (CustomFont) {
                    CustomFont.family = family;
                    FontLoader.LoadCustomFont(Utils.ToPascalCase(CustomFont.family) + Utils.UseNullOrEmpty(CustomFont.style, 'Regular'), CustomFont.base64);                    
                }
                this.RCUpdate();
            }            
        }
    }
    SetCustomFontStyle(style) {
        if (this.props.id) {
            const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
            const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont() : Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
            if (font.fontId) {
                Globals.ProjectManager.Tokens.SetSubValue({id : this.props.id, prop : 'style', value : style});
                Globals.ProjectManager.DataManager.Catched.Set(style, 'CustomFonts', font.fontId, 'style');
                let CustomFont = AppState.CatchedData.Get(AppState.CatchedData.CUSTOMFONTS, font.fontId);
                if (CustomFont) {
                    CustomFont.style = style;
                    FontLoader.LoadCustomFont(Utils.ToPascalCase(CustomFont.family) + Utils.UseNullOrEmpty(CustomFont.style, 'Regular'), CustomFont.base64);                    
                }
                this.RCUpdate();
            }            
        }
    }
    renderCustom() {

        const token = Globals.ProjectManager.Tokens.Token(this.props.id) || { name : 'Default Font'};
        const font = this.props.id === 'DefaultFont' ? Globals.ProjectManager.Tokens.DefaultFont() : Globals.ProjectManager.Tokens.ValueOf({model : token}) || {};
        const fontStack = Globals.ProjectManager.Tokens.ValueOf({model : token, name : 'fontStack'});
        let nameEditor;
        // if (this.props.isDefaultFont) {
        //     nameEditor = (
        //         <SC.FRow>
        //             Default Font
        //         </SC.FRow>
        //     )
        // }
        // else {
        //     nameEditor = (
        //         <ItemNameEditor
        //             noMargin
        //             fontSize='12px'                        
        //             name={token.name}
        //             onSaveName={this.SaveName}
        //             onGetItems={this.GetItems}
        //             model={this.token}
        //         />
        //     )
        // }

        nameEditor = (
            <ItemNameEditor
                noMargin
                fontSize='12px'                        
                name={token.name}
                onSaveName={this.SaveName}
                onGetItems={this.GetItems}
                model={this.token}
            />
        )
        let editor;

        if (font.provider === Strings.CUSTOM) {
            editor = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 2fr',
                    gridTemplateRows : '1fr',
                    gridGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center'
                }}>
                    <div>Provider</div>
                    <div>{font.provider}</div>
                    <div>Name</div>
                    <div>{font.name}</div>
                    <div>Family</div>
                    <TextInput 
                        value={font.family}
                        onChange={this.SetCustomFontFamily.bind(this)}
                    />
                    <div>Style</div>
                    <TextInput 
                        value={font.style}
                        onChange={this.SetCustomFontStyle.bind(this)}
                    />
                </div>
            )
        }
        else {
            editor = (
                <div style={{
                    display : 'grid',
                    gridTemplateColumns : '1fr 2fr',
                    gridTemplateRows : '1fr',
                    gridGap : '8px',
                    marginTop : '16px',
                    alignItems : 'center'
                }}>
                    <div>Provider</div>
                    <div>{font.provider}</div>
                    <div>Name</div>
                    <div>{font.name}</div>
                    <div>Family</div>
                    <div>{font.family}</div>
                </div>
            )
        }


        return (
            <SC.FCol f1 fw fh style={{position : 'relative'}}>
                <TokenPanelHeader 
                    title='EDIT TYPEFACE' 
                    onClose={this.props.onClose} 
                    onCancel={this.props.onCancel} 
                    onAdd={this.props.onAdd} 
                    hasAddCancel
                    onDelete={this.props.onDelete} 
                />
                <SC.FCol f1 style={{padding : '10px', backgroundColor : SC.CurrentTheme.theme.back}}>
                    <SC.FCol style={{marginLeft : '4px', marginBottom : '8px'}}>
                        {nameEditor}
                    </SC.FCol>    
                    <FontPreview 
                        font={font}
                        height={120}
                        SelectFont={this.onSelectFont}
                    />
                    {editor}
                    <Seperator />
                    <div style={{marginBottom : '8px'}}>Font Stack</div>
                    <SC.FCol>
                        <TextValue 
                            dark
                            multiline 
                            value={fontStack}
                            onChange={this.SetFontStack}
                            style={{flex : 'unset'}}
                        />
                        <a href='https://www.cssfontstack.com/' target='_blank' style={{marginTop : '8px'}}>
                            <InfoPanel>
                                Explore complete collection of web safe CSS font stacks.
                            </InfoPanel>                        
                        </a>
                    </SC.FCol>                    
                </SC.FCol>          
                <AnimatePresence>
                    {
                        this.showFontSelector && 
                        <motion.div 
                            style={{...SC.Styles.Absolute, zIndex : 10000, backgroundColor : SC.CurrentTheme.theme.back_lighter}}
                            initial={{opacity : 0.7, x : -24}}
                            animate={{opacity : 1, x : 0}}
                            exit={{opacity : 0, x : -48}}
                            transition={{duration : 0.1}}
                        >
                            <FontSelectPanel 
                                onSelect={(font) => {
                                    SetFontTypeFace(this.props.id, font);
                                    
                                    this.props.onBroadCastChange();

                                    this.showFontSelector = false;
                                    this.RCUpdate();
                                }}
                                onPreview={(font) => {                                    
                                    if (font) {
                                        Globals.ProjectManager.SetThemePreviewTypeface(this.props.id, font, font ? true : false);
                                        this.willDeletePreviewFont = Globals.ProjectManager.PreviewFont.PreviewId;
                                        Globals.ProjectManager.UpdateTokenValues({});
                                        this.props.onBroadCastChange();

                                    }
                                    else {
                                        if (Globals.ProjectManager.PreviewFont) {
                                            this.willDeletePreviewFont = Globals.ProjectManager.PreviewFont.PreviewId;
                                            setTimeout(() => {
                                                if (this.willDeletePreviewFont) {
                                                    if (Globals.ProjectManager.PreviewFont && Globals.ProjectManager.PreviewFont.PreviewId !== this.willDeletePreviewFont) {
                                                        return;
                                                    }
                                                }
                                                delete this.willDeletePreviewFont;
                                                Globals.ProjectManager.SetThemePreviewTypeface(this.props.id);                                                
                                                Globals.ProjectManager.UpdateTokenValues({});
                                                this.props.onBroadCastChange();
                                            }, 300);
                                        }
                                    }                                    
                                }}
                                onClose={() => {
                                    this.showFontSelector = false;
                                    this.RCUpdate();
                                }}
                            /> 
                        </motion.div>
                    }
                </AnimatePresence>      
            </SC.FCol>
        )
    }
}
