import React from 'react';
import BaseItem, { BuildItemStyles } from '../../BaseItem';
import {Utils, MetaData} from '../../../importer';
import { renderEngineIcon } from '../../SvgIcon';
import { GenericTabs_SubTypeIds } from '../../../../toolabs-metaui/components/generic/initialize/tabs';

class Generic_Tabs extends BaseItem
{
    constructor(props) {
        super(props);

        this.InstanceId = Utils.Id();     

        if (this.props.slides) {
            this.slides = Utils.JustGet(this.props.slides, [], 'items');
            const activeId = Utils.JustGet(this.props.slides, null, 'activeSlide');
            const index = Math.max(0, Utils.FindIndex(this.slides.items, (slide) => {return slide.Id === activeId}));
            this.state.active = index || 0;
        }
        else {
            this.slides = [];
            this.state.active = 0;
        }        
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.active !== nextState.active)
            return true;
        return super.shouldComponentUpdate(nextProps, nextState);
    }    
    SelectTab(index) {
        if (!this.slides)
            return;            
        this.setState({
            active : index
        });
    }
    renderItem({style, props, events, rest}) {                   
        const { MetaItem } = this.props;

        const options = {};
        GenericTabs_SubTypeIds.map((SubType) => {
            options[SubType.Id] = {};
            const SubTypeId = Utils.JustGet(MetaItem.Generic, null, SubType.Id);
            if (SubTypeId) {
                const MetaItemSubType = Utils.Find(MetaItem.SubItems, (Item) => { return Item.Id === SubTypeId });
                if (MetaItemSubType) {
                    options[SubType.Id] = BuildItemStyles(MetaItemSubType, this.props) || {};
                }
            }
        })

        const style_item = {
            ...style,
            display : 'flex',
            alignItems : 'stretch',
            overflow : 'hidden'
        };
        const style_headerbar = {
            ...options.HeaderBar.style,
            display : 'flex'
        }
        const orientaion = Utils.JustGet(props, 'column', 'GenericOptions', 'orientation');

        style_item.flexDirection = orientaion;

        if (Utils.IsFlexRow(orientaion)) {
            style_headerbar.flexDirection = 'column';
        }
        else {
            style_headerbar.flexDirection = 'row';
        }
        let content;
        if (this.state.active > -1 && this.state.active < this.slides.length) {
            const selectedTab = this.slides[this.state.active];
            content = (
                 <TabContent 
                    slide={selectedTab}                       
                    renderSlide={this.props.renderCollectionItem}
                 />
            )
        }
        return (
            <div style={style_item}>
                <div style={style_headerbar}>
                    {
                        this.slides && this.slides.map((slide, i) => {
                            let selectedStyle;
                            if (this.state.active === i) {
                                selectedStyle = options.HeaderSelected.style;
                            }
                            return (
                                <div key={slide.Id} style={{...options.Header.style, ...selectedStyle}} onClick={this.SelectTab.bind(this, i)}>
                                    Header {i+1}
                                </div>
                            )
                        })
                    }
                </div>
                <div style={{...options.Content.style, display : 'flex'}}>
                    {content}
                </div>
            </div>
        )
    }
}

export default Generic_Tabs;


class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
        
    render() { 
        const style = {
            flex : 1,
            display : 'flex',
            alignItems : 'stretch',
            transition : 'transform 0.4s ease-in-out',
        };
        
      
        return (  
            <div
                style={style}
            >
                {this.props.renderSlide && this.props.renderSlide(this.props.slide)}
            </div>
        );
    }
}
