import React from 'react';
import {
    ReactBaseComponent,
    SC,
    Utils,
    AppState,
    Links,
    Events,
    Loading,
    UIUtils,
    Globals
} from '../../../../../../../importer';

import { ItemNameEditor } from '../../common';
import FilterEditor from '../../../../../../../components/editors/filter';

export default class FilterTokenEditor extends ReactBaseComponent
{
    constructor(props) {
        super(props);

        this.SaveName = this.SaveName.bind(this);
        this.GetItems = this.GetItems.bind(this);
        this.onFilterChanged = this.onFilterChanged.bind(this);
        this.LogTokenChange = this.LogTokenChange.bind(this);
        
        this.Ref_Name = React.createRef();

        if (this.props.newModel) {
            this.token = this.props.newModel;
            this.EditingModel = this.props.newModel;
        }
        else {
            this.Load(this.props.id);            
        }
    }
    Load(id) {
        this.token = Globals.ProjectManager.Tokens.Filter(id);
        this.EditingModel = Utils.DeepClone({
            items : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'items'),
            originX : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), 'center', 'originX'),
            originY : Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), 'center', 'originY')
        });
    }
    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.GlobalState !== this.props.GlobalState || this.props.GlobalStateId !== nextProps.GlobalStateId) {
            if (!this.props.newModel) {
                this.token = Globals.ProjectManager.Tokens.Filter(nextProps.id);
                if (!this.token) {
                    this.props.onCancel();
                    return false;
                }
                this.EditingModel.items = Utils.DeepClone(Utils.JustGet(Globals.ProjectManager.Tokens.ValueOf({model : this.token}), [], 'items'));
            }
        }
        if (this.props.id !== nextProps.id)
            this.Load(nextProps.id);
        return true;
    }
    GetItems() {
        return Globals.ProjectManager.Tokens.TokenList(Globals.ProjectManager.Tokens.Types.Filters);
    }        
    onFilterChanged(filterStyle) {
        if (!this.FilterChangeLogged) {
            Globals.ProjectManager.LogTokenChange({Desc : 'Change Filter'});
            this.FilterChangeLogged = true;
        }
        if (this.props.newModel)
            this.props.onPreviewChange && this.props.onPreviewChange(this.EditingModel);
        else {
            Events.BroadcastThrottle_50(Events.GLOBAL.TOKEN_VALUE_CHANGING, [
                {
                    Id : this.props.id,
                    Type : Globals.ProjectManager.Tokens.Types.Filters,
                    value : filterStyle
                }
            ]);
        }
            
        this.RCThrottledUpdate_1();
    }
    LogTokenChange() {
        Globals.ProjectManager.LogTokenChange({Desc : 'Change Filter'});
    }

    SaveName(name) {
        if (this.props.newModel)
            this.props.newModel.name = name;
        else
            Globals.ProjectManager.Tokens.UpdateProp({id : this.props.id, name : 'name', value : name} );
        this.RCUpdate();
    }
    renderCustom() {            
        return (
            <SC.FCol fw fh style={{padding : '10px', position : 'relative', backgroundColor : SC.CurrentTheme.theme.back, boxSizing : 'border-box'}}>
                <ItemNameEditor
                    noMargin
                    fontSize='12px'                        
                    name={this.token.name}
                    onSaveName={this.SaveName}
                    onGetItems={this.GetItems}
                    model={this.token}
                />
                <FilterEditor
                    id={this.props.id}
                    style={{marginTop : '8px'}}
                    model={this.EditingModel}
                    onUpdate={this.onFilterChanged}
                    offline={this.props.offline}
                    ownerPosition={'LeftPanel'}
                    onBeforeChange={this.LogTokenChange}
                    GlobalStateId={this.props.GlobalStateId}
                /> 
            </SC.FCol>
        )
    }
}