import React from 'react';
import ReactDOM from 'react-dom';

import {
    MetaData,
    Utils,
    UIUtils,
    SC,
    Strings,
    AppLayout
} from '../../../../../../../importer';

import BaseGeneric from '../base';

export default class Generic_Image extends BaseGeneric {
    constructor(props) {
        super(props);        
    }
    renderContent({style, props}) {        

        let textContent = Utils.JustGet(this.renderData.props, '', 'textContent');
        return (
            <div style={style} {...props}>
                {textContent}
            </div>
        )
    }
}